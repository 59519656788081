import React, { useContext } from 'react';
import { Pane, Table, Heading, Badge, Link } from 'evergreen-ui';
import phoneMask from './phoneMask';
import MultiUploadDoc from './MultiUploadDoc';
import ReturnDocumentsForView from './MultiViewDocs'

export default function FormPessoa({ pessoa, dataPessoa, context, indexPessoa, uploadDisable, refreshTable, pedidoProcesso }) {
  const token = localStorage.getItem('token');
  const elemento = pessoa?.[0]?.toUpperCase() + pessoa?.replace("es", "").substring(1);

  const {
    multiDocs,
  } = useContext(context);
  console.log('multiDocs: ', multiDocs);

  console.log('PESSOA: ', pessoa)

  const estadoCivil = (estadoCivilId) => {
    switch (estadoCivilId) {
      case '1':
        return 'Casado'
      case '2':
        return 'Solteiro'
      case '3':
        return 'Divorciado'
      case '4':
        return 'Viúvo'
      case '5':
        return 'Separado'
      default:
        return '---'
    }
  };

  const registroCasamento = (reg) => {
    switch (reg) {
      case '1':
        return ' - Separação total de bens'
      case '2':
        return ' - Separação parcial de bens'
      case '3':
        return ' - Separação legal de bens'
      case '4':
        return ' - Comunhão de bens'
      default:
        return null
    }
  };

  return (
    <Pane>
      {dataPessoa.tipo_pessoa === 1
        ?
        <Pane>
          <Badge color="green" className="badge-tipo-pesso" style={{ marginTop: '10px', marginBottom: '30px' }}>
            <span>Pessoa Jurídica</span>
          </Badge>
          <Heading className='mb10'>
            Dados da empresa
          </Heading>
          <Table.Row className="pb60 pt15 alignStart h65">
            <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">CNPJ</span><br />
              {dataPessoa.cpf_cnpj || "---"}
            </Table.TextCell>
            <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Telefone/Celular</span><br />
              {phoneMask(dataPessoa.telefone) || "---"}
            </Table.TextCell>
          </Table.Row>

          <Table.Row className="pb60 pt15 alignStart h65">
            <Table.TextCell flexBasis={600} flexShrink={0} flexGrow={0}>
              <span className="fontStatus">Razão Social</span><br />
              {dataPessoa.razao_social || "---"}
            </Table.TextCell>
          </Table.Row>

          <Table.Row className="pb60 pt15 alignStart h65">
            <Table.TextCell flexBasis={600} flexShrink={0} flexGrow={0}>
              <span className="fontStatus">Nome Fantasia</span><br />
              {dataPessoa.nome_fantasia || "---"}
            </Table.TextCell>
          </Table.Row>

          <Heading className='mt20 mb10'>
            Endereço da empresa
          </Heading>
          <Table.Row className="pb60 pt15 alignStart h65">
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">CEP</span><br />
              {dataPessoa.cep || "---"}
            </Table.TextCell>
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Bairro</span><br />
              {dataPessoa.bairro || "---"}
            </Table.TextCell>
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Logradouro</span><br />
              {dataPessoa.logradouro || "---"}
            </Table.TextCell>
          </Table.Row>

          <Table.Row className="pb60 pt15 alignStart h65">
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Número</span><br />
              {dataPessoa.numero || "---"}
            </Table.TextCell>
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Unidade</span><br />
              {dataPessoa.unidade || "---"}
            </Table.TextCell>
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Complemento</span><br />
              {dataPessoa.complemento || "---"}
            </Table.TextCell>
          </Table.Row>

          <Table.Row className="pb60 pt15 alignStart h65">
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Cidade</span><br />
              {dataPessoa.cidade || "---"}
            </Table.TextCell>
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Estado</span><br />
              {dataPessoa.estado || "---"}
            </Table.TextCell>
          </Table.Row>

          <Heading className='mt20 mb10'>
            Documentos da empresa
            <Badge margin={8} color="blue">
              {uploadDisable
                ? dataPessoa.documentos.data.filter((docs) => docs.identifica_documento.includes("juridica")).length
                : multiDocs[pessoa][indexPessoa].juridica.length
              }
            </Badge >
          </Heading>

          {uploadDisable
            ? dataPessoa.documentos.data.filter((docs) => docs.identifica_documento.includes("juridica")).map((doc, index) => (
              <ReturnDocumentsForView doc={doc} />
            ))
            : <MultiUploadDoc pessoa={pessoa} type="juridica" context={context} refreshProcess={refreshTable} indexPessoa={indexPessoa} idDonoDocumento={dataPessoa.id} pedidoProcesso={pedidoProcesso} />
          }

          {dataPessoa.representante_socios.data.map((representante, index_Representante) => (
            <Pane>
              <Heading className='mt20'>
                Sócio e/ou Representantes Legais
              </Heading>
              {representante.pj_representante === 1 &&
                <Badge className="badge-tipo-pesso mt20 mr10 mb10 ">
                  REPRESENTANTE LEGAL
                </Badge>}
              {representante.pj_socio === 0 &&
                <Badge className="badge-tipo-pesso mt20 mb10">
                  SÓCIO
                </Badge>}

              <Table.Row className="pt15 alignStart h65">
                <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
                  <span className="fontStatus">Nome completo</span><br />
                  {representante.name || "---"}
                </Table.TextCell>
                <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
                  <span className="fontStatus">CPF</span><br />
                  {representante.cpf_cnpj || "---"}
                </Table.TextCell>
                <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
                  <span className="fontStatus">Gênero</span><br />
                  {representante.genero || "---"}
                </Table.TextCell>
              </Table.Row>

              <Table.Row>
                <Table.TextCell flexBasis={600} flexShrink={0} flexGrow={0}>
                  <span className="fontStatus">E-mail</span><br />
                  {representante.email || "---"}
                </Table.TextCell>
              </Table.Row>

              <Table.Row className="pt15 alignStart h65">
                <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
                  <span className="fontStatus">R.G</span><br />
                  {representante.rg || "---"}
                </Table.TextCell>
                <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
                  <span className="fontStatus">Expedita por</span><br />
                  {representante.rg_expedido || "---"}
                </Table.TextCell>
                <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
                  <span className="fontStatus">Data de expedição</span><br />
                  {representante.data_rg_expedido || "---"}
                </Table.TextCell>
              </Table.Row>

              <Table.Row>
                <Table.TextCell flexBasis={600} flexShrink={0} flexGrow={0}>
                  <span className="fontStatus">Profissão</span><br />
                  {representante.profissao || "---"}
                </Table.TextCell>
              </Table.Row>

              <Table.Row>
                <Table.TextCell flexBasis={600} flexShrink={0} flexGrow={0}>
                  <span className="fontStatus">Estado Civil</span><br />
                  {estadoCivil(representante.estado_civil)}
                  {representante.uniao_estavel && "- União Estável"}
                  {registroCasamento(representante.registro_casamento)}
                </Table.TextCell>
              </Table.Row>

              <Heading className='mt20'>
                Documentos do Representante ({representante.name})
                <Badge margin={8} color="blue">
                  {uploadDisable
                    ? representante.documentos.data.filter((docs) => docs.identifica_documento.includes("fisica")).length
                    : multiDocs[pessoa][indexPessoa].representante_socios[index_Representante].length
                  }
                </Badge >
              </Heading>

              {uploadDisable
                ? representante.documentos.data.filter((docs) => docs.identifica_documento.includes("fisica")).map((doc, index) => (
                  <ReturnDocumentsForView doc={doc} />
                ))
                : <MultiUploadDoc pessoa={pessoa} type="fisica" context={context} refreshProcess={refreshTable} indexPessoa={indexPessoa} indexRepresentante={index_Representante} idDonoDocumento={representante.id} pedidoProcesso={pedidoProcesso} />
              }

            </Pane>
          ))}
        </Pane>

        :
        <Pane>
          <Badge color="green" className="badge-tipo-pesso" style={{ marginTop: '10px', marginBottom: '30px' }}>
            <span>Pessoa Física</span>
          </Badge>
          <Heading className='mb10'>
            {"Dados do " + elemento}
          </Heading>
          <Table.Row className="pb60 pt15 alignStart h65">
            <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">CPF</span><br />
              {dataPessoa.cpf_cnpj || "---"}
            </Table.TextCell>
            <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Telefone/Celular</span><br />
              {phoneMask(dataPessoa.telefone) || "---"}
            </Table.TextCell>
          </Table.Row>

          <Table.Row className="pb60 pt15 alignStart h65">
            <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Nome completo</span><br />
              {dataPessoa.name || "---"}
            </Table.TextCell>
            <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Gênero</span><br />
              {dataPessoa.genero || "---"}
            </Table.TextCell>
          </Table.Row>

          <Table.Row className="pb60 pt15 alignStart h65">
            <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Data de nascimento</span><br />
              {dataPessoa.data_nascimento || "---"}
            </Table.TextCell>
            <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Nacionalidade</span><br />
              {dataPessoa.nacionalidade || "---"}
            </Table.TextCell>
          </Table.Row>

          <Table.Row className="pb60 pt15 alignStart h65">
            <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Nome completo da mãe</span><br />
              {dataPessoa.nome_mae || "---"}
            </Table.TextCell>
            <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Nome do completo do pai</span><br />
              {dataPessoa.nome_pai || "---"}
            </Table.TextCell>
          </Table.Row>

          <Table.Row className="pt15 alignStart h65">
            <Table.TextCell flexBasis={600} flexShrink={0} flexGrow={0}>
              <span className="fontStatus">Estado Civil</span><br />
              {estadoCivil(dataPessoa.estado_civil)}
              {dataPessoa.uniao_estavel ? "- União Estável" : ""}
              {registroCasamento(dataPessoa.registro_casamento)}
            </Table.TextCell>
          </Table.Row>

          <Table.Row className="pt15 alignStart h65">
            <Table.TextCell flexBasis={600} flexShrink={0} flexGrow={0}>
              <span className="fontStatus">Profissão</span><br />
              {dataPessoa.profissao || "---"}
            </Table.TextCell>
          </Table.Row>

          <Table.Row className="pb60 pt15 alignStart h65">
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">R.G</span><br />
              {dataPessoa.rg || "---"}
            </Table.TextCell>
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Expedita por</span><br />
              {dataPessoa.rg_expedido || "---"}
            </Table.TextCell>
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Data de expedição</span><br />
              {dataPessoa.data_rg_expedido || "---"}
            </Table.TextCell>
          </Table.Row>

          <Table.Row className="pt15 alignStart h65">
            <Table.TextCell flexBasis={600} flexShrink={0} flexGrow={0}>
              <span className="fontStatus">E-mail</span><br />
              {dataPessoa.email || "---"}
            </Table.TextCell>
          </Table.Row>

          <Heading className='mt20 mb10'>
            Procurador
          </Heading>
          {dataPessoa.procurador ?
            <Table.Row>
              <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} className='wsNormal'>
                <span className="fontStatus">Nome completo</span><br />
                {dataPessoa.procurador.nome || "---"}
              </Table.TextCell>
              <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} className='wsNormal'>
                <span className="fontStatus">Telefone/Celular</span><br />
                {phoneMask(dataPessoa.procurador.telefone) || "---"}
              </Table.TextCell>
            </Table.Row>
            :
            <Table.Row className="pb60 pt15 alignStart h65">
              <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} className='wsNormal'>
                <span className="fontStatus">Nenhum procurador cadastrado</span><br />
              </Table.TextCell>
            </Table.Row>
          }

          <Heading className='mt20 mb10'>
            Endereço
          </Heading>
          <Table.Row className="pb60 pt15 alignStart h65">
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">CEP</span><br />
              {dataPessoa.cep || "---"}
            </Table.TextCell>
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Bairro</span><br />
              {dataPessoa.bairro || "---"}
            </Table.TextCell>
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Logradouro</span><br />
              {dataPessoa.Logradouro || "---"}
            </Table.TextCell>
          </Table.Row>

          <Table.Row className="pb60 pt15 alignStart h65">
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Número</span><br />
              {dataPessoa.numero || "---"}
            </Table.TextCell>
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Unidade</span><br />
              {dataPessoa.unidade || "---"}
            </Table.TextCell>
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Complemento</span><br />
              {dataPessoa.complemento || "---"}
            </Table.TextCell>
          </Table.Row>

          <Table.Row className="pb60 pt15 alignStart h65">
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Cidade</span><br />
              {dataPessoa.cidade || "---"}
            </Table.TextCell>
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Estado</span><br />
              {dataPessoa.estado || "---"}
            </Table.TextCell>
          </Table.Row>

          <Heading className='mt20 mb10'>
            Documentos do {elemento}
            <Badge margin={8} color="blue">
              {uploadDisable
                ? dataPessoa.documentos.data.filter((docs) => docs.identifica_documento.includes("fisica")).length
                : multiDocs[pessoa][indexPessoa].fisica.length
              }
            </Badge >
          </Heading>

          {uploadDisable
            ? dataPessoa.documentos.data.filter((docs) => docs.identifica_documento.includes("fisica")).map((doc, index) => (
              <ReturnDocumentsForView doc={doc} />
            ))
            :
            <MultiUploadDoc pessoa={pessoa} type="fisica" context={context} refreshProcess={refreshTable} indexPessoa={indexPessoa} idDonoDocumento={dataPessoa.id} pedidoProcesso={pedidoProcesso} />
            // <UploadDoc pessoa={pessoa} type="fisica" context={context} indexPessoa={indexPessoa} />
          }

        </Pane>
      }
    </Pane>
  )
}