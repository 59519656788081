import React, { useContext, useState } from 'react';
import { Pane, Table, Heading, Badge, Button, StatusIndicator, SelectField, TextInputField, TextInput, Textarea, ShareIcon, Label, FilePicker } from 'evergreen-ui';
import NucleoContext from '../../../../context/NucleoContext';
import dateMask from '../../../../components/dateMask'
import Axios from 'axios'
import Serve from '../../../../app/serve';
import { Link, useHistory } from 'react-router-dom';
import ShowDocument from '../../../../app/showDocument';

export default function Servico({ refreshTable }) {

    const history = useHistory();
    const app = Serve();
    const [token] = useState(localStorage.getItem('token'));
    const usuario_id = localStorage.getItem('usuario_id');

    const {
        processSelect,
        statusList, setLoading,
    } = useContext(NucleoContext);

    const [solicitacoes, setSolicitacoes] = useState({
        'dataCriacao': processSelect.data_criacao,
        'servicoDetalhado': { nome: processSelect.servico_detalhado.nome },
        // 'valorServicoDetalhado': {nome: processSelect.valor_servico_detalhado.nome} || processSelect.valor_servico_detalhado,
        'statusId': processSelect.status_solicitação.data[0]?.id,
        'status': processSelect.status_solicitação.data[0]?.status,
        'posVenda': processSelect.responsavel_requisicao?.name,
        'documentos': '',
        'observacaoNucleo': processSelect.status_solicitação.data[0]?.mensagem,
        'observacoesPosVenda': processSelect.observacao,
        dataPrevisao: processSelect?.status_solicitação?.data[0]?.data_previsao || "",
        comprovante: processSelect?.documentos.data.find((doc) => doc.tipo_documento.nome === "Comprovante pagamento") || '',
        boleto: processSelect?.documentos.data.find((doc) => doc.tipo_documento.nome === "Cobrança de pagamento") || '',
        documento_id: processSelect?.documentos.data.find((doc) => doc.tipo_documento.nome === "Cobrança de pagamento")?.id || ''
    });

    console.log("Service data: ", processSelect);

    const handleChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        console.log(value, name);
        if (name === 'dataPrevisao') solicitacoes[name] = dateMask(value);
        else solicitacoes[name] = value;
        setSolicitacoes({ ...solicitacoes })
    };

    const handleAvancar = () => {
        setLoading(true);
        console.log(solicitacoes);

        let solicitacao = new FormData();
        solicitacao.append('responsavel_alteracao_id', usuario_id);
        solicitacao.append('solicitacao_id', processSelect.id);
        solicitacao.append('processo_id', processSelect.processo.id);
        solicitacao.append('status_solicitacao_id', solicitacoes.statusId);
        solicitacao.append('mensagem', solicitacoes.observacaoNucleo || "");
        solicitacao.append('previsao', solicitacoes.dataPrevisao || "");

        if (solicitacoes.boleto !== '') {
            solicitacao.append('boleto', solicitacoes.boleto);
            solicitacao.append('documento_id', solicitacoes.documento_id);
        };

        Axios.post(app + 'salvar_historico_solicitacao', solicitacao, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {
            console.log(res);
            if (res.data.status && res.data.status === (401 || 498)) {
                localStorage.clear();
                history.push('/');
            } else {
                refreshTable();
            }
        }).catch(function (error) {
            console.log(error);
        }).then(() => {

        })
    };

    return (
        <Pane padding={10}>
            <Heading size={600}>
                Qual é o status do serviço?
            </Heading>
            <Pane display="flex" alignItems="center" marginTop={20}>
                <SelectField
                    label="Status"
                    width="33%"
                    name='statusId'
                    className='bgWhite'
                    marginRight={10}
                    value={solicitacoes.statusId}
                    onChange={(e) => handleChange(e)}
                >
                    <option value="" hidden selected >Entrada</option>
                    {statusList.map((cargo) => (
                        <option value={cargo.id} >{cargo.status}</option>
                    ))}
                </SelectField>

                {Number(solicitacoes.statusId) !== 4 &&
                    <TextInputField
                        label={Number(solicitacoes.statusId) === 5 ? "Data de Validade" : "Previsão de entrega"}
                        placeholder='DD/MM/AAAA'
                        width="33%"
                        name='dataPrevisao'
                        value={solicitacoes.dataPrevisao}
                        onChange={(e) => handleChange(e)}
                    />}
            </Pane>

            <Heading size={600} className="mt10">
                Detalhes
            </Heading>
            <Table.Row className="pb60 pt15 alignStart h65" style={{height: 'auto', paddingBottom: '10px'}}>
                <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
                    <span className="fontStatus f14">Solicitação</span><br />
                    {solicitacoes.dataCriacao || "---"}
                </Table.TextCell>
                <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
                    <span className="fontStatus f14">Serviço</span><br />
                    {solicitacoes.servicoDetalhado.nome || "---"}<br />
                    {processSelect?.valor_servico_detalhado?.nome || processSelect?.valor_servico_detalhado ||  ''}
                </Table.TextCell>
                <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
                    <span className="fontStatus f14">Pós-venda</span><br />
                    {solicitacoes.posVenda || "---"}
                </Table.TextCell>
            </Table.Row>
            <Table.Row height="auto">
                <Table.TextCell flexBasis={600} flexShrink={0} flexGrow={0} className='wsNormal mt10 mb10'>
                    <span className="fontStatus f14">Observações do pós-venda</span><br />
                    <div className="lh19 mt5">{solicitacoes.observacoesPosVenda || "---"}</div>
                </Table.TextCell>
            </Table.Row>

            {Number(solicitacoes.statusId) === 5 &&
                <Pane>
                    <Heading size={600} className="mt20">
                        Boleto
                    </Heading>
                    <Pane className='mt10'>
                        <div>Realize o upload do boleto para a equipe de pós-venda..</div>
                        <div className='flex mt10'>
                            <FilePicker
                                placeholder={solicitacoes.boleto?.arquivo ? solicitacoes.boleto.arquivo : "Selecione o arquivo"}
                                type="file"
                                accept=".pdf, .doc, .docx"
                                onChange={(e) => solicitacoes.boleto = e[0]}
                                disabled={solicitacoes.comprovante ? true : false}
                            />
                            <Button
                                marginLeft={5}
                                appearance="primary"
                                disabled={!solicitacoes.boleto?.id}
                                onClick={(btn) => [ShowDocument((solicitacoes.boleto?.id), 'documento', token), btn.preventDefault()]}
                            >Ver</Button>
                        </div>
                    </Pane>

                    {solicitacoes.comprovante &&
                        <Pane className='mt20'>
                            <div className='mb5'>Comprovante de Pagamento</div>
                            <Link className='content-click font12 mt' onClick={(e) => [ShowDocument(solicitacoes.comprovante.id, 'documento', token), e.preventDefault()]}>{solicitacoes.comprovante.arquivo}</Link>
                        </Pane>}
                </Pane>}

            <Heading size={600} className="mt20">
                Observações
            </Heading>
            <div className='mt10'>Se necessário, insira observações para que o Pós-venda fique atento.</div>
            <Textarea
                placeholder='Exemplo: Comprovante de pagamento sendo enviado..'
                width="100%"
                name="observacaoNucleo"
                className='mt10'
                value={solicitacoes.observacaoNucleo}
                onChange={(e) => [solicitacoes.observacaoNucleo = e.target.value, setSolicitacoes({ ...solicitacoes })]}
            />
            <Pane className='alignRight'>
                <div></div>
                <Button className='mt50' appearance='primary' onClick={() => handleAvancar()}>✔ Salvar alterações</Button>
            </Pane>
        </Pane>


    )
}