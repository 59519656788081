import React, {useContext, useState} from 'react';
import { Pane, Dialog, TextInputField, FilePicker, CloudUploadIcon, Button, PlusIcon, ChevronLeftIcon, ChevronRightIcon, DeleteIcon, SelectField } from 'evergreen-ui';

//Context
import PosNegociacaoContext from '../../../../../../context/PosNegociacaoContext';

//Components
import Axios from 'axios';
import Serve from '../../../../../../app/serve';
import { useHistory } from 'react-router-dom';
import Steps from './Steps';
//import cepMask from '../../../../../../components/cepMask';
//import somenteNumero from '../../../../../../components/somenteNumero';
//import dateMask from '../../../../../../components/dateMask';
import cpfMask from '../../../../../../components/cpfMask';
import phoneMask from '../../../../../../components/phoneMask';

export default function Vendedor() {

    const app = Serve();
    const history = useHistory();
    const [token] = useState(localStorage.getItem('token'));
    //const idProcesso = props.idProcesso;

    const {
        isShowFormEspelhoVenda, setIsShowFormEspelhoVenda,
        posicaoEspelhoVenda, setPosicaoEspelhoVenda,
        tipoDocumentos, setTipoDocumentos,
        validateInputFile, setValidateInputFile,
        dadosEspelhoVendedor,setDadosEspelhoVendedor,
    } = useContext(PosNegociacaoContext);

    console.log(posicaoEspelhoVenda);

    const [validateCpfVendedor, setValidateCpfVendedor] = useState(false);
    const [validateNomeVendedor, setValidateNomeVendedor] = useState(false);
    //const [validateCodigoVendedor, setValidateCodigoVendedor] = useState(false);
    const [validateTelefoneVendedor, setValidateTelefoneVendedor] = useState(false);
    //const [validateEmailVendedor, setValidateEmailVendedor] = useState(false);
    //const [validateCepVendedor, setValidateCepVendedor] = useState(false);
    const [validateBairroVendedor, setValidateBairroVendedor] = useState(false);
    const [validateLogradouroVendedor, setValidateLogradouroVendedor] = useState(false);
    //const [validateConjugeVendedor, setValidateConjugeVendedor] = useState(false);
    //const [validateProcuradorVendedor, setValidateProcuradorVendedor] = useState(false);
    //const [validateTelefoneProcuradorVendedor, setValidateTelefoneProcuradorVendedor] = useState(false);
    //const [validateFieldDocumentos, setValidateFieldDocumentos] = useState(false);
    const [validateDocObrigatorio, setValidateDocObrigatorio] = useState(false);
    const [formValues, setFormValues] = useState([{ tipoDocumento: "", documento: "" }])

    const closeEspelhoVenda = (e) => {
        setIsShowFormEspelhoVenda(false);
        setPosicaoEspelhoVenda(1);

        setDadosEspelhoVendedor([]);
    }

    function handleCpf(e) {
        const attrForm = e.target.getAttribute('data-cpf');

        //console.log("attrForm = ", attrForm);
        let cpf = document.querySelector('.espelho-venda-cpf-vendedor-' + attrForm);

        if (cpf.value.length) {
            cpfMask(cpf.value);
            cpf.value = cpfMask(cpf.value);

            if (cpf.value.length === 14) {
                setValidateCpfVendedor(true);
            }
            else {
                setValidateCpfVendedor(false);
            }
        }

        Axios.post(app + 'verificar_usuario_cpf', {
            "cpf": cpf.value
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                }

                console.log(res.data.usuario);
                let usuario = res.data.usuario;
                if (usuario) {
                    //console.log("usuario existe");
                    //console.log(usuario.id);
                    
                    //setIdVendedor(usuario.id);
                    
                    //document.querySelector("#formGroup-vendedor-" + attrForm + " .cpf-vendedor-" + attrForm).value = "";
                    //document.querySelector("#vendedor_id-" + attrForm).value = usuario.id;
                    document.querySelector("#formGroup .espelho-venda-nome-vendedor-" + attrForm).value = usuario.name;
                    document.querySelector("#formGroup .espelho-venda-telefone-vendedor-" + attrForm).value = usuario.telefone;
                    document.querySelector("#formGroup .espelho-venda-email-vendedor-" + attrForm).value = usuario.email;
                    document.querySelector("#formGroup .espelho-venda-logradouro-vendedor-" + attrForm).value = usuario.endereco;

                    //setValidateCpfVendedor(true);
                    setValidateNomeVendedor(true);
                    setValidateTelefoneVendedor(true);
                    setValidateLogradouroVendedor(true);

                } else {
                    
                    //setIdVendedor("");
                    
                    //console.log("usuario não existe");
                    //document.querySelector("#vendedor_id-" + attrForm).value = usuario.id;
                    document.querySelector("#formGroup .espelho-venda-nome-vendedor-" + attrForm).value = "";
                    document.querySelector("#formGroup .espelho-venda-telefone-vendedor-" + attrForm).value = "";
                    document.querySelector("#formGroup .espelho-venda-email-vendedor-" + attrForm).value = "";
                    document.querySelector("#formGroup .espelho-venda-logradouro-vendedor-" + attrForm).value = "";

                    setValidateNomeVendedor(false);
                    setValidateTelefoneVendedor(false);
                    setValidateLogradouroVendedor(false);
                }
            })
    }

    const handleNome = () => {
        let nome = document.querySelector('.espelho-venda-nome-vendedor-1');
        if (nome.value.length > 0) {
            setValidateNomeVendedor(true);
        }
        else {
            setValidateNomeVendedor(false);
        }
    }

    /*const handleCodigo = () => {
        let codigo = document.querySelector('.espelho-venda-codigo-vendedor-1');
        if (codigo.value.length > 0) {
            setValidateCodigoVendedor(true);
        }
        else {
            setValidateCodigoVendedor(false);
        }
    }*/

    const handleTelefone = () => {
        let telefone = document.querySelector('.espelho-venda-telefone-vendedor-1');
        if (telefone.value.length) {
            phoneMask(telefone.value);
            telefone.value = phoneMask(telefone.value);

            if (telefone.value.length >= 14) {
                setValidateTelefoneVendedor(true);
            }
        }
        else {
            setValidateTelefoneVendedor(false);
        }
    }

    /*const handleEmail = () => {
        let telefone = document.querySelector('.espelho-venda-telefone-vendedor-1');
        if (telefone.value.length > 0) {
            setValidateEmailVendedor(true);
        }
        else {
            setValidateEmailVendedor(false);
        }
    }*/

    const handleBairro = () => {
        let bairro = document.querySelector('.espelho-venda-bairro-vendedor-1');
        if (bairro.value.length > 0) {
            setValidateBairroVendedor(true);
        }
        else {
            setValidateBairroVendedor(false);
        }
    }

    const handleLogradouro = () => {
        let logradouro = document.querySelector('.espelho-venda-logradouro-vendedor-1');
        if (logradouro.value.length > 0) {
            setValidateLogradouroVendedor(true);
        }
        else {
            setValidateLogradouroVendedor(false);
        }
    }

    /*const handleNomeConjuge = () => {
        let conjuge = document.querySelector('.espelho-venda-conjugue-vendedor-1');
        if (conjuge.value.length > 0) {
            setValidateConjugeVendedor(true);
        }
        else {
            setValidateConjugeVendedor(false);
        }
    }*/

    /*const handleNomeProcurador = () => {
        let procurador = document.querySelector('.espelho-venda-procurador-vendedor-1');
        if (procurador.value.length > 0) {
            setValidateProcuradorVendedor(true);
        }
        else {
            setValidateProcuradorVendedor(false);
        }
    }*/

    const handleTelefoneProcurador = () => {
        let procuradorTelefone = document.querySelector('.espelho-venda-telefone-procurador-vendedor-1');
        if (procuradorTelefone.value.length) {
            phoneMask(procuradorTelefone.value);
            procuradorTelefone.value = phoneMask(procuradorTelefone.value);
            
            if (procuradorTelefone.value.length >= 14) {
                //setValidateTelefoneProcuradorVendedor(true);
            }
        }
        else {
            //setValidateTelefoneProcuradorVendedor(false);
        }
    }

    //Adcionando e removendo campos de Documento
    /*const handleChangeFormField = (i, e) => {
        const file = document.querySelector('.upload-vendedor-' + i + ' input[type="file"]').files[0];
        let newFormValues = [...formValues];
        newFormValues[i]['documento'] = file.name;
        setFormValues(newFormValues);
        console.log(formValues);

        if (formValues.length > 0) {
            setValidateFieldDocumentos(true);
        }
        else {
            setValidateFieldDocumentos(false);
        }
    }*/

    const handleDocObrigatorio = (e) => {
        let file = document.querySelector('.documento-obrigatorio input[type="file"]').files[0];
        console.log(file);

        setValidateDocObrigatorio(true);
    }

    //Adcionando e removendo campos de Documento
    const handleChangeTipoDocumento = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i]['tipoDocumento'] = e.target.value;
        setFormValues(newFormValues);
        console.log(formValues);

        /*if (formValues.length > 0) {
            setValidateInputFile(true);   
        }
        else {
            setValidateInputFile(false);
        }*/

    }

    const handleChangeDocumento = (i, e) => {
        let file = document.querySelector('.upload-' + i + ' input[type="file"]').files[0];

        let newFormValues = [...formValues];
        newFormValues[i]['documento'] = file.name;
        setFormValues(newFormValues);
        console.log(formValues);

        /*if (formValues.length > 0) {
            setValidateInputFile(true);   
        }
        else {
            setValidateInputFile(false);
        }*/
    }

    const addFormFields = (e) => {
        e.preventDefault();
        setFormValues([...formValues, { documento: "" }])
    }

    const removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
        console.log(formValues);
    }

    /*const handleSubmit = (event) => {
        event.preventDefault();
        alert(JSON.stringify(formValues));
    }*/

    function validarSubmit() {
        if (validateCpfVendedor === true &&
            validateNomeVendedor === true &&
            validateTelefoneVendedor === true &&
            validateBairroVendedor === true &&
            validateLogradouroVendedor === true &&
            validateDocObrigatorio === true
            //validateInputFile === true*/
            //validateCodigoVendedor === true &&
            //validateEmailVendedor === true &&
            //validateCepVendedor === true &&
            //validateConjugeVendedor === true &&
            //validateProcuradorVendedor === true &&
            //validateTelefoneProcuradorVendedor === true
            ) {
            return 'enabled';
        }
        else {
            return 'disabled';
        }
    }
    validarSubmit();

    const handleSalvarContinuar = (e) => {
        e.preventDefault();
        console.log('Salvar e Continuar');
    }

    const handleBtnPularEtapa = (e) => {
        e.preventDefault();
        console.log('Pular Etapa');
    }

    const handleBtnAnterior = (e) => {
        e.preventDefault();
        console.log('Anterior');

        setPosicaoEspelhoVenda(1);
    }

    const handleBtnAvancar = (e) => {
        e.preventDefault();
        console.log('Avançar');

        const arrayVendedor = {
            cpf: document.querySelector('.espelho-venda-cpf-vendedor-1').value,
            nome: document.querySelector('.espelho-venda-nome-vendedor-1').value,
            codigo: document.querySelector('.espelho-venda-codigo-vendedor-1').value,
            telefone: document.querySelector('.espelho-venda-telefone-vendedor-1').value,
            email: document.querySelector('.espelho-venda-email-vendedor-1').value,
            bairro: document.querySelector('.espelho-venda-bairro-vendedor-1').value,
            logradouro: document.querySelector('.espelho-venda-logradouro-vendedor-1').value,
            conjuge: document.querySelector('.espelho-venda-conjuge-vendedor-1').value,
            procurador: document.querySelector('.espelho-venda-procurador-vendedor-1').value,
            telefoneProcurador: document.querySelector('.espelho-venda-telefone-procurador-vendedor-1').value,
            arquivos: formValues,
        }
        console.log(arrayVendedor);

        setDadosEspelhoVendedor(arrayVendedor);

        setPosicaoEspelhoVenda(3);
    }

    return (
        <Pane>
            <Dialog
                width={808}
                isShown={isShowFormEspelhoVenda}
                title=''
                onCloseComplete={(e) => closeEspelhoVenda(e)}
                hasFooter={false}
                preventBodyScrolling
                shouldCloseOnOverlayClick={false}
                containerProps={{
                    className: 'form-dialog-props'
                }}
            >
                <Steps />
            
                <div className="form-dialog">
                    <h4 style={{ marginTop: '15px' }}>Dados do Vendedor</h4>
                    <p className="descritption" style={{ marginTop: '15px' }}>Preencha os dados do vendedor responsável.</p>

                    <div className="formGroup formAdd">
                        <form id="formGroup" encType="multipart/form-data">
                            <Pane>
                                <div className="rowForm">
                                    <TextInputField
                                        width={125}
                                        label="CPF"
                                        description=""
                                        placeholder="000.000.000-00"
                                        defaultValue={dadosEspelhoVendedor === [] ? '' : dadosEspelhoVendedor.cpf}
                                        id={"espelho-venda-cpf-vendedor-1"}
                                        className={"espelho-venda-cpf-vendedor-1"}
                                        data-cpf="1"
                                        onChange={(e) => handleCpf(e)}
                                        maxlength={14}
                                        required
                                    />

                                    <TextInputField
                                        width={430}
                                        label="Nome Completo"
                                        description=""
                                        placeholder="José Maria da Silva"
                                        defaultValue={dadosEspelhoVendedor === [] ? '' : dadosEspelhoVendedor.nome}
                                        id={"espelho-venda-nome-vendedor-1"}
                                        className={"espelho-venda-nome-vendedor-1"}
                                        onChange={(e) => handleNome(e)}
                                        required
                                    />

                                    <TextInputField
                                        width={100}
                                        label="Cód"
                                        description=""
                                        placeholder="Ex: 11111"
                                        defaultValue={dadosEspelhoVendedor === [] ? '' : dadosEspelhoVendedor.codigo}
                                        id={"espelho-venda-codigo-vendedor-1"}
                                        className={"espelho-venda-codigo-vendedor-1"}
                                        onChange={(e) => (e)}
                                        style={{ marginLeft: '20px'}}
                                    />
                                </div>

                                <div className="rowForm">
                                    <TextInputField
                                        width={216}
                                        label="Telefone/Celular"
                                        description=""
                                        placeholder="(21) 9999-9999"
                                        defaultValue={dadosEspelhoVendedor === [] ? '' : dadosEspelhoVendedor.telefone}
                                        id={"espelho-venda-telefone-vendedor-1"}
                                        className={"espelho-venda-telefone-vendedor-1"}
                                        onChange={(e) => handleTelefone(e)}
                                        maxLength={15}
                                        required
                                    />

                                    <TextInputField
                                        width={463}
                                        label="E-mail"
                                        description=""
                                        placeholder="gerente@gmail.com"
                                        defaultValue={dadosEspelhoVendedor === [] ? '' : dadosEspelhoVendedor.email}
                                        id={"espelho-venda-email-vendedor-1"}
                                        className={"espelho-venda-email-vendedor-1"}
                                        onChange={(e) => (e)}
                                    />
                                </div>

                                <div className="rowForm">
                                    <TextInputField
                                        width={216}
                                        label="Bairro"
                                        description=""
                                        placeholder="Botafogo"
                                        defaultValue={dadosEspelhoVendedor === [] ? '' : dadosEspelhoVendedor.bairro}
                                        id={"espelho-venda-bairro-vendedor-1"}
                                        className={"espelho-venda-bairro-vendedor-1"}
                                        onChange={(e) => handleBairro(e)}
                                        required
                                    />

                                    <TextInputField
                                        width={463}
                                        label="Logradouro"
                                        description=""
                                        placeholder="Rua Voluntários da Pátria"
                                        defaultValue={dadosEspelhoVendedor === [] ? '' : dadosEspelhoVendedor.logradouro}
                                        id={"espelho-venda-logradouro-vendedor-1"}
                                        className={"espelho-venda-logradouro-vendedor-1"}
                                        onChange={(e) => handleLogradouro(e)}
                                        required
                                    />
                                </div>

                                <div className="rowForm">
                                    <TextInputField
                                        width={463}
                                        label="Nome completo do Cônjugue/Companheiro(a)"
                                        description=""
                                        placeholder="José Maria da Silva"
                                        defaultValue={dadosEspelhoVendedor === [] ? '' : dadosEspelhoVendedor.conjuge}
                                        id={"espelho-venda-conjuge-vendedor-1"}
                                        className={"espelho-venda-conjuge-vendedor-1"}
                                        onChange={(e) => (e)}
                                    />
                                </div>

                                <div className="row-recibo">
                                    <div className="subtitle">Dados do procurador</div>
                                    <div className="rowForm">
                                        <TextInputField
                                            width={440}
                                            label="Nome completo - Procurador"
                                            description=""
                                            placeholder="Josér Maria da Silva"
                                            defaultValue={dadosEspelhoVendedor === [] ? '' : dadosEspelhoVendedor.nomeProcurador}
                                            id={"espelho-venda-procurador-vendedor-1"}
                                            className={"espelho-venda-procurador-vendedor-1"}
                                            onChange={(e) => (e)}
                                        />

                                        <TextInputField
                                            width={225}
                                            label="Telefone/Celular - Procurador"
                                            description=""
                                            placeholder="(21) 9999-9999"
                                            defaultValue={dadosEspelhoVendedor === [] ? '' : dadosEspelhoVendedor.telefoneProcurador}
                                            id={"espelho-venda-telefone-procurador-vendedor-1"}
                                            className={"espelho-venda-telefone-procurador-vendedor-1"}
                                            onChange={(e) => handleTelefoneProcurador(e)}
                                            maxLength={15}
                                        />
                                    </div>
                                </div>

                                <div className="row-recibo">
                                    <div className="subtitle">
                                        <CloudUploadIcon className="icon-subtitle" />
                                        Upload de documentos do vendedor
                                    </div>

                                    <p className="descritption description-upload">Atenção: subir arquivos no formato .doc ou .pdf. <br /> Para aprender a converter uma foto para pdf., <a href="https://www.tecmundo.com.br/apps/72485-apps-converter-documentos-para-pdf-celulares-tablets.htm" target="_blank" rel="noreferrer" title="Converter foto para PDF">clique aqui</a>.</p>

                                    <div className={"rowForm upload-espelho-venda upload-obrigatorio"}>
                                        <TextInputField
                                            width={215}
                                            marginBottom={0}
                                            marginLeft={0}
                                            label=""
                                            description=""
                                            placeholder="RG*"
                                            defaultValue="RG*"
                                            id={"espelho-venda-docs-1 input-obrigatorio"}
                                            className={"espelho-venda-doc-1 input-obrigatorio"}
                                            onChange={(e) => (e)}
                                            readOnly
                                        />
                                        <input type="hidden" className="doc-hidden-vendedor" id="doc-hidden-vendedor" name="" value="20" />
                                        <FilePicker
                                            width={250}
                                            placeholder="nome.arquivo"
                                            type="file"
                                            name="documento-obrigatorio"
                                            value={''}
                                            onChange={e => handleDocObrigatorio(e)}
                                            className={"file-input documento-obrigatorio"}
                                        />
                                    </div>

                                    {formValues.map((e, index) => (
                                        <div className={"rowForm upload-espelho-venda upload-" + index} key={index}>
                                            <SelectField
                                                value={e.tipoDocumento || ""}
                                                onChange={e => handleChangeTipoDocumento(index, e)}
                                                name="tipoDocumento"
                                                className={'documento-imovel espelho-venda-vendedor-tipo-documento espelho-venda-documento-vendedor-novo-' + index}
                                                style={{ marginBottom: 0 }}
                                                id={'espelho-venda-documento-vendedor-novo-' + index}
                                                data-documento={'tipo-documento-novo-' + index}
                                                width={216}
                                                marginBottom={0}
                                                padding={0}
                                            >
                                                <option value="0" hidden>Selecione o tipo</option>
                                                {
                                                    tipoDocumentos.filter(tipoDocumento => tipoDocumento.tipo === "pessoa fisica")
                                                        .map(tipoDocumento => <option value={tipoDocumento.id}>{tipoDocumento.nome}</option>)
                                                }
                                            </SelectField>

                                            <FilePicker
                                                width={250}
                                                placeholder="nome.arquivo"
                                                type="file"
                                                name="documento"
                                                value={e.documento || ""}
                                                onChange={e => handleChangeDocumento(index, e)}
                                                className={"file-input documento-" + index}
                                            />
                                            {
                                                index ?
                                                    <Button
                                                        marginRight={16}
                                                        appearance="primary"
                                                        intent="danger"
                                                        className="button remove"
                                                        style={{ left: '75px' }}
                                                        onClick={() => removeFormFields(index)}
                                                        type="button"
                                                    >
                                                        <DeleteIcon
                                                            size={10}
                                                            style={{ 'marginRight': '10px', 'fill': '#fff' }}
                                                        /> Excluir
                                                    </Button>
                                                    : null
                                            }
                                        </div>
                                    ))}

                                    <Button
                                        className="btn-add-documento"
                                        onClick={(e) => addFormFields(e)}
                                    >
                                        <PlusIcon className="icon-label" />
                                        Adicionar mais documentos
                                    </Button>
                                </div>

                                <div className="mensagem-campos msg-obrigatorio">*Campos obrigatórios</div>

                                <div className="footer-form">
                                    <div>
                                        <Button className="btn-minimal btn-minimal-disabled" appearance="default" disabled onClick={(e) => handleSalvarContinuar(e)}>
                                            Salvar e continuar depois
                                        </Button>
                                    </div>

                                    <div>
                                        <Button className="btn-minimal" appearance="default" onClick={(e) => handleBtnPularEtapa(e)}>
                                            Pular esta etapa
                                        </Button>

                                        <Button appearance="default" marginLeft={15} onClick={(e) => handleBtnAnterior(e)}>
                                            <ChevronLeftIcon className="icon-label" style={{ margin: '0 5px 0 0' }} />
                                            Anterior
                                        </Button>

                                        <Button
                                            appearance="primary"
                                            disabled={validarSubmit() === 'disabled' ? true : false}
                                            marginLeft={15}
                                            onClick={(e) => handleBtnAvancar(e)}
                                        >
                                            Avançar
                                            <ChevronRightIcon className="icon-save" />
                                        </Button>
                                    </div>
                                </div>
                            </Pane>
                        </form>
                    </div>
                </div>
            </Dialog>
        </Pane>
    )
}