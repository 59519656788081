import React, { useContext, useState, useEffect } from 'react';
import {
    Pane,
    Dialog,
    TextInputField,
    Spinner,
    FilePicker,
    CloudUploadIcon,
    Button,
    PlusIcon,
    ChevronRightIcon,
    SelectField,
    EditIcon,
    Textarea,
    Label,
    DeleteIcon,
    LockIcon,
    ErrorIcon,
    toaster
} from 'evergreen-ui';

//Context
import VendasContext from '../../../../../../context/VendasContext';

//Components
import Axios from 'axios';
import { useForm } from "react-hook-form";
import Steps from './Steps';
import formatoMoeda from '../../../../../../components/formatoMoeda';
import Serve from '../../../../../../app/serve';
import { useHistory } from 'react-router-dom';
import RevisaoPosVenda from './components/RevisaoPosVenda';
import MultiUploadDoc from '../../../../../../components/MultiUploadDoc';

export default function Imovel(props) {
    const app = Serve();
    const history = useHistory();
    const [token] = useState(localStorage.getItem('token'));
    const [gerente_id] = useState(localStorage.getItem('usuario_id'));

    const [alteracaoRevisao, setAlteracaoRevisao] = useState(false);
    const modeDev = process.env.REACT_APP_DEV === 'true';

    const {
        loading,
        setCountMenu,
        setItemAdicionado, setLoading,
        dadosVenda, setDadosVenda,
        isShowFormBoletimDeVenda, setIsShowFormBoletimDeVenda,
        setPosicaoBoletimDeVenda,
        tipoDocumentos,
        setDadosBoletimDeVendaImovel,
        setIsShowCornerDialog,
        modelCornerDialog, setModelCornerDialog,
        multiDocs
    } = useContext(VendasContext);

    //Cantinho do hook Form
    const { register, handleSubmit, setValue, watch, unregister, clearErrors, reset, formState: { errors } } = useForm({
        defaultValues: {
            valor_venda: dadosVenda.informacao.valor_venda,
            valor_anunciado: dadosVenda.informacao.valor_anunciado || '',
            observacao: dadosVenda.informacao.observacao || '',
        }
    });

    const errorFileInput = "Selecione o arquivo para avançar";
    const errorSelectInput = "Selecione o tipo de arquivo para avançar";

    if (modeDev) {
        console.log(watch());
        console.log("errors: ", errors);
    };

    const inputGreen = {
        borderColor: '#429777',
        background: '#F5FBF8'
    };
    const inputDefault = {
        borderColor: '#ADC2FF',
        background: '#fff'
    };

    const inputError = {
        borderColor: '#FF0000',
        background: '#fff'
    };

    const closeBoletimDeVenda = (e) => {
        setIsShowFormBoletimDeVenda(false);
        reset();
        setPosicaoBoletimDeVenda(1);
        setDadosBoletimDeVendaImovel([]);
        setAlteracaoRevisao(false);
    };

    const handleValorVenda = (e) => {
        const valorVenda = e.target.value;
        const name = e.target.name;
        if (valorVenda.length > 0) {
            setValue(name, formatoMoeda(valorVenda));
        };
    };

    //Verificando se os campos foram alterados para Revisão
    const handleVerificarRevisao = (e) => {
        if (e.target !== undefined) {

            setAlteracaoRevisao(true);
        }
        else {
            toaster.danger('Realize os ajustes solicitados pelo pós-venda para avançar.');
        }
    }

    const handleBtnAvancar = (e) => {
        setLoading(false);
        modeDev && console.log(e);
        //setCountMenu(2);

        const data = new FormData();
        data.append('valorVenda', e.valor_venda)
        data.append('processo_id', dadosVenda.processo_id);
        data.append('usuario_id_logado', gerente_id);
        data.append('imovel_id', dadosVenda.id);
        data.append('status_processo', dadosVenda.status_processo);
        data.append('informacao_imovel_id', dadosVenda.informacao.id);
        data.append('valorEstimado', e.valor_anunciado);

        multiDocs.imovel?.forEach((doc, index_Doc) => {
            if(doc.file) {
                data.append(`documentos_ids[${index_Doc}]`, doc.id)
                doc.item.forEach((item, index_Item) => {
                    console.log(item);
                    data.append(`multiplo_documento_id[${index_Doc}][${index_Item}]`, item.id ? item.id : "");
                    data.append(`tipo_documento_ids[${index_Doc}][${index_Item}]`, item.value || '');
                    data.append(`data_emissao[${index_Doc}][${index_Item}]`, item.emissao || '');  // DATA INICIAL
                    data.append(`validade_dias[${index_Doc}][${index_Item}]`, item.validade || ''); // DIAS
                    data.append(`data_vencimento[${index_Doc}][${index_Item}]`, item.vencimento || '');  // DATA FINAL
                })
            }
        });

        data.append('observacao', e.observacao);
        if (dadosVenda.status_processo === 22) {
            data.append('revisao', 1);
            data.append('revisao_id', dadosVenda.revisao.id)
        }

        Axios.post(app + 'salvar_documentos_imovel', data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    let mensagem = res.data.mensagem_processo;
                    if (res.data.mensagem_documento !== '') {
                        mensagem += '\n' + res.data.mensagem_documento;
                    }
                    console.log("mensagem Erro: ", mensagem);
                    recarregarVenda(dadosVenda.processo_id, 'avançar');
                    alteracaoRevisao(false);
                }
            })
            .catch((error) => { console.log(error) })
    }

    async function recarregarVenda(idProcessoNovo, avancar) {
        await Axios.post(app + 'retorno_processos_painel_gerentes', {
            "usuario_id": gerente_id,
            'tipo_processos': 'adicionados',
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }).then(res => {
            if (res.data.status && res.data.status === (401 || 498)) {
                localStorage.clear();
                history.push('/');
            } else {
                const processos = res.data.processos.data;
                setItemAdicionado(processos);
                processos.forEach(element => {
                    if (Number(idProcessoNovo) === element.processo_id) {
                        setDadosVenda(element);
                        reset();
                        //setPosicaoBoletimDeVenda(2);
                        if (avancar) {
                            if (dadosVenda.status_processo === 22) {
                                if (dadosVenda.revisao.vendedores === 1) {
                                    setCountMenu(2);
                                    setPosicaoBoletimDeVenda(2);
                                }
                                else if (dadosVenda.revisao.compradores === 1) {
                                    setCountMenu(3);
                                    setPosicaoBoletimDeVenda(3);
                                }
                                else if (dadosVenda.revisao.comissao === 1) {
                                    setCountMenu(4);
                                    setPosicaoBoletimDeVenda(4);
                                }
                                else {
                                    setIsShowCornerDialog(true);
                                    //setModelCornerDialog(3);
                                    setModelCornerDialog(6);
                                    setIsShowFormBoletimDeVenda(false);
                                }
                            }
                            else {
                                setPosicaoBoletimDeVenda(2);
                            }
                        }
                    }
                });
            }

        })
        setLoading(true);
    }

    return (
        <Pane>
            <Dialog
                width={dadosVenda.status_processo === 22 ? 950 : 808}
                isShown={isShowFormBoletimDeVenda}
                title=''
                onCloseComplete={(e) => closeBoletimDeVenda(e)}
                hasFooter={false}
                shouldCloseOnOverlayClick={false}
                containerProps={{
                    className: 'form-dialog-props'
                }}
            >
                <div className="form-dialog-fixed-no-tabs">
                    <Steps />
                </div>

                {
                    loading === true &&
                        dadosVenda.status_processo === 22
                        ? dadosVenda.revisao.valores_documentos_imovel === 1
                            ? <RevisaoPosVenda
                                tab="imovel"
                                situacao={dadosVenda.revisao.valores_documentos_imovel_verific_save}
                                mensagem={dadosVenda.revisao.valores_documentos_imovel_observacao !== null ? dadosVenda.revisao.valores_documentos_imovel_observacao : ''}
                            />
                            : ''
                        : ''
                }

                {loading === false
                    ?
                    <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                        <Spinner size={80} />
                    </Pane>
                    :
                    <div className="form-dialog">
                        <h4 style={dadosVenda.status_processo === 22 ? { marginTop: '15px' } : { marginTop: '50px' }}>Sobre valores e documentos do Imóvel</h4>
                        <p className="descritption" style={{ marginTop: '15px' }}>Preencha com os dados do imóvel e valores.</p>

                        <div className="formGroup formAdd">
                            <form id="formGroup" className="formGroup-dialog formGroup-no-tabs" encType="multipart/form-data" onChange={e => dadosVenda.status_processo === 22 ? handleVerificarRevisao(e) : (e)} >
                                <Pane>
                                    <div className="row-recibo">
                                        <div className="subtitle">Valores</div>
                                        <div className="rowForm">
                                            <div>
                                                <TextInputField
                                                    width={138}
                                                    label="Valor de venda*"
                                                    description=""
                                                    defaultValue={dadosVenda.informacao.valor_venda}
                                                    placeholder="Ex: R$ 500.000,00"
                                                    {...register("valor_venda", {
                                                        required: true,
                                                        onChange: (e) => handleValorVenda(e)
                                                    })}
                                                    style={watch('valor_venda') ? inputGreen : errors.valor_venda ? inputError : inputDefault}
                                                    validationMessage={errors.valor_venda && "Campo obrigatório."}
                                                />
                                            </div>

                                            <TextInputField
                                                width={138}
                                                label="Valor anunciado"
                                                description=""
                                                placeholder="Ex: R$ 500.000,00"
                                                style={dadosVenda.length === 0 ? inputDefault : dadosVenda.informacao.valor_anunciado !== null ? inputGreen : inputDefault}
                                                {...register("valor_anunciado", {
                                                    required: false,
                                                    onChange: (e) => handleValorVenda(e)
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row-recibo" style={{ marginTop: '35px' }}>
                                        <div className="subtitle">
                                            <CloudUploadIcon className="icon-subtitle" />
                                            Upload de documentos do imóvel
                                        </div>

                                        <p className="descritption description-upload" style={{ marginBottom: '20px' }}>Atenção: subir arquivos no formato .doc, .docx ou .pdf. <br /> Para aprender a converter uma foto para pdf., <a href="https://www.tecmundo.com.br/apps/72485-apps-converter-documentos-para-pdf-celulares-tablets.htm" target="_blank" rel="noreferrer" title="Converter foto para PDF">clique aqui</a>.</p>

                                        <p className="descritption description-upload" style={{ color: '#8F95B2' }}>Exemplo de documentos: nome1, nome2, nome3 etc...</p>

                                        <div className='mb5 fw400'>Escolha o tipo de arquivo*</div>


                                        <MultiUploadDoc pessoa={'imovel'} type="imóvel" context={VendasContext} refreshProcess={recarregarVenda} idDonoDocumento={dadosVenda.id} />
                                    </div>

                                    <div className="row-recibo" style={{ marginTop: '35px' }}>
                                        <div className="subtitle">
                                            <EditIcon className="icon-subtitle" />
                                            Observações
                                        </div>

                                        <Label htmlFor="textarea-2" marginBottom={12} display="block">
                                            Caso necessário, insira observações para que a Equipe de Pós-Venda fique ciente.
                                        </Label>

                                        <Textarea
                                            name="textarea-1"
                                            placeholder="Exemplo: O imóvel se encontra em inventário e o comprador só está disponível para contato de 13h às 18h. "
                                            style={dadosVenda.length === 0 ? inputDefault : dadosVenda.informacao.observacao !== null ? inputGreen : inputDefault}
                                            height={110}
                                            {...register('observacao', { required: false })}
                                        />
                                    </div>

                                    <div className="mensagem-campos msg-obrigatorio">*Campos obrigatórios</div>

                                    <div className="footer-form">
                                        <div></div>
                                        <div>
                                            <Button
                                                type="submit"
                                                appearance="primary"
                                                marginLeft={15}
                                                onClick={
                                                    handleSubmit(e => dadosVenda.status_processo !== 22
                                                        ? handleBtnAvancar(e)
                                                        : alteracaoRevisao === true ? handleBtnAvancar(e) : handleVerificarRevisao(e))
                                                }
                                            >
                                                {dadosVenda.status_processo === 22
                                                    ? dadosVenda.revisao.vendedores === 1
                                                        ? 'Próxima correção'
                                                        : dadosVenda.revisao.compradores === 1
                                                            ? 'Próxima correção'
                                                            : dadosVenda.revisao.comissao === 1
                                                                ? 'Próxima correção'
                                                                : 'Salvar correções'

                                                    : 'Avançar'
                                                }
                                                <ChevronRightIcon className="icon-save" />
                                            </Button>
                                        </div>
                                    </div>
                                </Pane>
                            </form>
                        </div>
                    </div>
                }
            </Dialog>
        </Pane>
    )
}