import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
    Pane,
    Dialog,
    TextInputField,
    Button,
    ChevronRightIcon,
    Checkbox,
    SelectField,
    DeleteIcon,
    Spinner,
    Label,
    Textarea,
    PlusIcon,
    InlineAlert,
    toaster,
} from 'evergreen-ui';

//Context
import VendasContext from '../../../../../../context/VendasContext';

//Components
import Axios from 'axios';
//import { useForm } from "react-hook-form";
import Steps from './Steps';
import cepMask from '../../../../../../components/cepMask';
import somenteNumero from '../../../../../../components/somenteNumero';
import dateMask from '../../../../../../components/dateMask';
//import horaMask from '../../../../../../components/horaMask';
import formatoMoeda from '../../../../../../components/formatoMoeda';
import ripLaudemioMask from '../../../../../../components/ripLaudemioMask';

import Serve from '../../../../../../app/serve';
import { useHistory } from 'react-router-dom';
import getMidas from '../../../../../../apis/getMidas';
import getHaveLaudemio from '../../../../../../apis/getHaveLaudemio';
import getProcessosGerente from '../../../../../../apis/getProcessosGerente';

const inputGreen = { borderColor: '#429777', background: '#F5FBF8' };
const inputDefault = { borderColor: '#ADC2FF', background: '#fff' };
const inputError = { borderColor: '#FF0000', background: '#fff' };
const msgErrorInvalid = 'Campo obrigatório';
const disableCSS = { cursor: 'not-allowed', backgroundColor: '#F4F5F9', color: '#696f8c' };
const inicialValueLaudemio = { tipo_laudemio: "", valor_laudemio: "" };
const formasDePagamentos = [
    { label: "À vista", value: "1", path: "formaPagamento.avista" },
    { label: "Financiamento", value: "2", path: "formaPagamento.financiamento" },
    { label: "FGTS", value: "3", path: "formaPagamento.fgts"},
    { label: "Consórcio", value: "4", path: "formaPagamento.consorcio" },
    { label: "Parcelamento", value: "5", path: "formaPagamento.parcelamento" },
    //{ label: "PIX", value: "6", path: "formaPagamento.pix" },
]

const InputSimples = ({ label, placeholder, register, watch, nameCampo, maxLength }) => {
    const mascara = (e) => {
        if (placeholder.includes('R$')) return formatoMoeda(e)
        else if (placeholder.includes('DD/MM/AAAA')) return dateMask(e)
        else return e
    };

    return (
        <TextInputField
            maxLength={maxLength || ""}
            width={280}
            label={label}
            description=""
            value={watch(nameCampo)}
            placeholder={placeholder}
            {...register(nameCampo, {
                setValueAs: e => mascara(e)
            })}
            style={watch(nameCampo) ? inputGreen : inputDefault}
        />
    )
};

const InputRequired = ({ label, placeholder, errors, register, watch, nameCampo, maxLength }) => {
    const mascara = (e) => {
        if (placeholder.includes('R$')) return formatoMoeda(e)
        else return e
    };

    return (
        <TextInputField
            width={280}
            label={label}
            description=""
            value={watch(nameCampo)}
            maxLength={maxLength || ""}
            placeholder={placeholder}
            {...register(nameCampo, {
                required: true,
                setValueAs: e => mascara(e)
            })}
            style={watch(nameCampo) ? inputGreen : errors[nameCampo] ? inputError : inputDefault}
            validationMessage={errors[nameCampo] && msgErrorInvalid}
            required
        />
    )
};

const SelectsLaudemios = ({ label, nameCampo, errors, index, element, watch, register, setValue }) => {
    const {
        tipoLaudemio,
    } = useContext(VendasContext);

    const laudemioIgreja = tipoLaudemio.filter(item => item.tipo_laudemio_id === 4);
    const laudemioFamilia = tipoLaudemio.filter(item => item.tipo_laudemio_id === 3);

    const laudemios = [
        { nome: 'União', id: '1', path: "Laudemio.uniao" },
        { nome: 'Prefeitura', id: '2', path: "Laudemio.prefeitura" },
        { nome: 'Familia do imóvel', id: '3', path: "Laudemio.familia" },
        { nome: 'Igreja', id: '4', path: "Laudemio.igreja" },
    ];

    const options = () => {
        switch (label) {
            case 'Nome da Família *':
                return laudemioFamilia
            case 'Nome da Igreja *':
                return laudemioIgreja
            default:
                return laudemios
        }
    };

    const handleChange = (e) => {
        const selectedValue = e.target.value;
        setValue('laudemio.' + index + '.' + nameCampo, selectedValue);

        if (selectedValue === '1' && nameCampo === 'tipo_laudemio') {
            setValue("prazo", 30)
        }

        if ((selectedValue === '2' || selectedValue === '3' || selectedValue === '4') && nameCampo === 'tipo_laudemio') {
            setValue("prazo", 45)
        }

        if (watch('formaPagamento.financiamento') || watch('formaPagamento.fgts')) {
            setValue('prazo', 45);
        }
    };

    return (
        <SelectField
            width={216}
            label={label}
            name={nameCampo}
            value={element[nameCampo]}
            className={errors?.laudemio?.[index]?.[nameCampo]
                ? 'inputErrorSelect'
                : (element[nameCampo] ? 'inputGreenSelect' : 'inputDefaultSelect')
            }
            {...register('laudemio.' + index + '.' + nameCampo, {
                required: true,
                onChange: handleChange,
            })}
            validationMessage={errors?.laudemio?.[index]?.[nameCampo] && msgErrorInvalid}
        >
            <option value="" hidden>Selecione</option>
            {options().map(laudemio => (
                <option value={laudemio.id} key={index}>{laudemio.nome}</option>
            ))}
        </SelectField>
    )
};

const CheckFormasPagamentos = ({ register, watch, value, label, path, setValue, disabled, index }) => {
    const formaPagamento = (e) => {
        setValue(path, e.target.checked)

        console.log('path: ', path)

        let laudemiosPrazos = watch('laudemio');

        let tipoLaudemioUniao = 1;
        const tipoLaudemioEncontradouniao = laudemiosPrazos?.some(item => item.tipo_laudemio == tipoLaudemioUniao) || false;

        let tipoLaudemioPrefeitura = 2;
        const tipoLaudemioEncontradoPrefeitura = laudemiosPrazos?.some(item => item.tipo_laudemio == tipoLaudemioPrefeitura) || false;

        let tipoLaudemioFamilia = 3;
        const tipoLaudemioEncontradoFamilia = laudemiosPrazos?.some(item => item.tipo_laudemio == tipoLaudemioFamilia) || false;

        let tipoLaudemioIgreja = 4;
        const tipoLaudemioEncontradoIgreja = laudemiosPrazos?.some(item => item.tipo_laudemio == tipoLaudemioIgreja) || false;

        const idPagamento = [];
        if (watch('formaPagamento.avista')) {            
            if (tipoLaudemioEncontradoPrefeitura || tipoLaudemioEncontradoFamilia || tipoLaudemioEncontradoIgreja) {
                setValue("prazo", 45)
                console.log('foi')
            } else {
                console.log('nop')
                setValue("prazo", 30)
            }
            idPagamento.push(1);
        }
        if (watch('formaPagamento.financiamento')) {
            setValue("prazo", 45)
            idPagamento.push(2);
        }
        if (watch('formaPagamento.fgts')) {
            // formasPagamento.push("FGTS");
            setValue("prazo", 45)
            idPagamento.push(3);
        }

        if (watch('formaPagamento.consorcio')) {
            idPagamento.push(4);
        }
        if (watch('formaPagamento.parcelamento')) {
            // formasPagamento.push("Parcelamento");
            idPagamento.push(5);
        }
        if (watch('formaPagamento.pix')) {
            // formasPagamento.push("PIX");
            idPagamento.push(6);
        }
        if (idPagamento.length === 0) {
            idPagamento.push(value);

        }

        setValue('forma_pagamento', idPagamento.toString())
        console.log(watch('formaPagamento'));
        

        // // Quando clicar em Financiamento bloqueia Consórcio e vice e versa
        // console.log('FORMAS PAGAMENTO###############')
        // console.log(formasDePagamentos)
        // console.log(watch('formaPagamento'))
        // console.log(watch('formaPagamento.avista'))
        // console.log(watch('formaPagamento.financiamento'))
        // console.log(watch('formaPagamento.consorcio'))
        // console.log('FORMAS PAGAMENTO###############')
        
        if(path === 'formaPagamento.avista'){
            console.log('AQUI 1')

            if(watch('formaPagamento.avista') === true){
                formasDePagamentos[1].disabled = true;
                formasDePagamentos[3].disabled = true;
            }
            else{
                formasDePagamentos[1].disabled = false;
                formasDePagamentos[3].disabled = false;
            }
        }

        if(path === 'formaPagamento.financiamento'){
            if(watch('formaPagamento.financiamento') === true){
                formasDePagamentos[0].disabled = true;
                formasDePagamentos[3].disabled = true;
            }
            else{
                formasDePagamentos[0].disabled = false;
                formasDePagamentos[3].disabled = false;
            }
        }

        if(path === 'formaPagamento.consorcio'){
            if(watch('formaPagamento.consorcio') === true){
                formasDePagamentos[0].disabled = true;
                formasDePagamentos[1].disabled = true;
            }
            else{
                formasDePagamentos[0].disabled = false;
                formasDePagamentos[1].disabled = false;
            }
        }
        else{
            console.log('AQUI 2')
        }

        if (watch('formaPagamento.avista') === false && watch('formaPagamento.financiamento') === false && watch('formaPagamento.fgts') === false) {
            if (tipoLaudemioEncontradoPrefeitura || tipoLaudemioEncontradoFamilia || tipoLaudemioEncontradoIgreja) {
                setValue("prazo", 45)
            } else {
                setValue("prazo", 30)
            }
        }
    };
    
    console.log(watch(path))

    return (
        <Checkbox
            marginTop={3}
            appearance="default"
            className="checkbox"
            label={label}
            value={value}
            disabled={disabled}
            indeterminate={disabled}
            {...register(path, {
                onChange: (e) => formaPagamento(e),
            })}
            checked={watch(path)}
        />
    )
};

export default function Imovel() {
    //Estados Laudemio e Formas de Pagamento estão sendo executados em VendasAdicionadas em formVenda;

    const {
        tipoLaudemio,
        listEscrituras,
        loading,
        setCountMenu, countMenu,
        setItemAdicionado, setLoading,
        dadosVenda, setDadosVenda,
        isShowFormMinuta, setIsShowFormMinuta,
        setPosicaoMinuta,
        setTotalItensAdicionados,
        filtro, posicaoMinuta,
        setTotalPaginasAdicionados,
    } = useContext(VendasContext);
    const { informacao } = dadosVenda;
    //const informacao = dadosVenda;
    const vindoMidas = dadosVenda;
    console.log('dadosVenda ', dadosVenda)

    const app = Serve();
    const history = useHistory();
    const [token] = useState(localStorage.getItem('token'));
    const [gerente_id] = useState(localStorage.getItem('usuario_id'));
    const [arrayOpcionista, setArrayOpcionista] = useState([]);
    const processoId = dadosVenda.processo_id;
    const laudemioIgreja = tipoLaudemio.filter(item => item.tipo_laudemio_id === 4);
    const laudemioFamilia = tipoLaudemio.filter(item => item.tipo_laudemio_id === 3);

    const defultValues = {
        //INFO QUE SERÁ SALVA
        codImovel: dadosVenda.codigo || "",
        cep: dadosVenda.cep || "",
        bairro: dadosVenda.bairro || "",
        logradouro: dadosVenda.logradouro || "",
        cidade: dadosVenda.cidade || "",
        estado: dadosVenda.estado || dadosVenda.uf || "",
        numero: dadosVenda.numero || "",
        unidade: dadosVenda.unidade || "",
        complemento: dadosVenda.complemento || "",
        //vagas: informacao?.vagas || "",
        vagas: vindoMidas?.vagas || dadosVenda.informacao?.vaga || "",
        escritura: informacao?.escritura || "",
        lavrada: informacao?.lavrada || "",
        livro: informacao?.livro || "",
        ato: informacao?.ato || "",
        folha: informacao?.folha || "",
        cartorio: informacao?.cartorio || "",
        rgi: informacao?.rgi || "",
        matricula: informacao?.matricula || "",
        inscricaoMunicipal: informacao?.inscricaoMunicipal || "",
        laudemio: dadosVenda.laudemios || [],
        valorVenda: informacao?.valor_venda || "",
        valorSinal: informacao?.valorSinal || "",
        //valorEstimado: informacao?.valor_anunciado || "",
        //valorEstimado: vindoMidas?.valor_venda || "",
        valorEstimado: dadosVenda.valorEstimado || "",
        prazo: informacao?.prazo || "",
        valoMulta: informacao?.valoMulta || "",
        forma_pagamento: informacao?.forma_pagamento || "", //ID COM AS FORMMAS DE PAGAMENTOS SALVAS
        observacaoPagamento: informacao?.observacao_pagamento || "",
        excecoes: informacao?.excecoes || "",
        bensMoveis: informacao?.bens_moveis || "",
    };

    const formPagamento = () => {
        
        // VALORES PARA OS CHECKS TRUE E FALSE
        return ({
            avista: informacao?.forma_pagamento?.includes('1'),
            financiamento: informacao?.forma_pagamento?.includes('2'),
            fgts: informacao?.forma_pagamento?.includes('3'),
            consorcio: informacao?.forma_pagamento?.includes('4'),
            parcelamento: informacao?.forma_pagamento?.includes('5'),
            pix: informacao?.forma_pagamento?.includes('6')
        })
    };

    const { register, watch, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        defaultValues: {
            ...defultValues,
            formaPagamento: formPagamento()
        }
    });

    console.log('dadosVenda: ', dadosVenda);
    console.log("Form: ", watch())
    console.log("Errors", errors);

    useEffect(() => {        
        reset({
            ...defultValues,
            formaPagamento: formPagamento()
        })
        
        if(watch('formaPagamento.avista') === true || watch('formaPagamento.avista') === '1'){
            formasDePagamentos[1].disabled = true;
            formasDePagamentos[3].disabled = true;
        }

        if(watch('formaPagamento.financiamento') === true || watch('formaPagamento.financiamento') === '2'){
            formasDePagamentos[0].disabled = true;
            formasDePagamentos[3].disabled = true;
        }

        if(watch('formaPagamento.consorcio') === true || watch('formaPagamento.consorcio') === '4'){
            formasDePagamentos[0].disabled = true;
            formasDePagamentos[1].disabled = true;
        }

        if(watch('formaPagamento.avista') === undefined || watch('formaPagamento.financiamento') === undefined || watch('formaPagamento.financiamento') === undefined){
            formasDePagamentos[0].disabled = false;
            formasDePagamentos[1].disabled = false;
            formasDePagamentos[3].disabled = false;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dadosVenda])



    const closeMinuta = () => {
        setIsShowFormMinuta(false);
        setPosicaoMinuta(1);
        setDadosVenda([]);
        setArrayOpcionista([]);
    };

    const inicialDataEndereco = {
        bairro: true,
        logradouro: true,
        cidade: true,
        estado: true,
    };
    const [disableCamposEndereco, setDisableCamposEndereco] = useState(inicialDataEndereco);

    const handleCep = async (cep) => {
        if (cep.replace(/\D/g, '').length === 8) {
            await Axios.get("https://viacep.com.br/ws/" + cep + "/json/")
                .then(res => {
                    if (!res.data.erro) {
                        setValue('bairro', res.data?.bairro);
                        disableCamposEndereco.bairro = res.data?.bairro ? true : false;

                        setValue('logradouro', res.data?.logradouro);
                        disableCamposEndereco.logradouro = res.data?.logradouro ? true : false;

                        setValue('cidade', res.data?.localidade);
                        disableCamposEndereco.cidade = res.data?.localidade ? true : false;

                        setValue('estado', res.data?.uf);
                        disableCamposEndereco.estado = res.data?.uf ? true : false;
                    } else {
                        setValue('bairro', "")
                        disableCamposEndereco.bairro = false;
                        setValue('logradouro', "")
                        disableCamposEndereco.logradouro = false;
                        setValue('cidade', "")
                        disableCamposEndereco.cidade = false;
                        setValue('estado', "")
                        disableCamposEndereco.estado = false;
                        setDisableCamposEndereco({ ...disableCamposEndereco });
                    }
                })
            setValue('cep', cepMask(cep));
        };
    };

    const handleCodImovel = async (cod) => {
        let count = 1;
        const opcionistas = [];
        if (cod.length >= 9) {
            const data = await getMidas(cod);
            if (data) {
                if (data?.cep) handleCep(data.cep);
                setValue('cep', cepMask(data.cep));
                setValue('numero', data.numero);
                setValue('unidade', data.unidade);
                setValue('complemento', data.complemento);
                setValue('vagas', data.qtde_vagas);
                setValue('inscricaoMunicipal', data.inscricao_municipal);
                setValue('valorEstimado', formatoMoeda(data.valor_venda));
                console.log(formatoMoeda(data.valor_venda));
                setValue('laudo_opcionista', data.tipo_opcao);
                for (let prop in data) {
                    if (prop.includes('nome_opcionista') && data[prop] !== "") {
                        opcionistas.push({
                            id: data['opcionista' + count],
                            nome: data[prop],
                            percentual: data['percentual_opcao' + count]
                        });
                        count++;
                    }
                }
                setValue('opcionistas', opcionistas);
            }
        }
    };

    const labelLaudemio = (type) => {
        switch (type) {
            case '1':
                return { label: 'RIP nº *', placeholder: 'Ex: 9999.9999.999-9' }
            case '3':
                return { label: 'Nome da Família *', placeholder: 'Ex: Ramo de Petrópolis' }
            case '4':
                return { label: 'Nome da Igreja *', placeholder: 'Ex: Nome da Igreja Católica' }
            default:
                return ''
        }
    };

    function removeLaudemio(index) {
        const laudemios = watch('laudemio');
        laudemios.splice(index, 1);

        setValue('laudemio', laudemios);
        let laudemiosPrazos = watch('laudemio');

        let tipoLaudemioUniao = 1;
        const tipoLaudemioEncontradouniao = laudemiosPrazos?.some(item => item.tipo_laudemio == tipoLaudemioUniao) || false;

        let tipoLaudemioPrefeitura = 2;
        const tipoLaudemioEncontradoPrefeitura = laudemiosPrazos?.some(item => item.tipo_laudemio == tipoLaudemioPrefeitura) || false;

        let tipoLaudemioFamilia = 3;
        const tipoLaudemioEncontradoFamilia = laudemiosPrazos?.some(item => item.tipo_laudemio == tipoLaudemioFamilia) || false;

        let tipoLaudemioIgreja = 4;
        const tipoLaudemioEncontradoIgreja = laudemiosPrazos?.some(item => item.tipo_laudemio == tipoLaudemioIgreja) || false;

        if (tipoLaudemioEncontradouniao || watch('formaPagamento.avista') === "1") {
            setValue("prazo", 30)
        }

        if (tipoLaudemioEncontradoPrefeitura || tipoLaudemioEncontradoFamilia || tipoLaudemioEncontradoIgreja || watch('formaPagamento.financiamento') === "2" || watch('formaPagamento.fgts') === "3") {
            setValue("prazo", 45)
        }
        //console.log(watch('formaPagamento'))
    };

    function addLaudemio() {
        const laudemios = [...watch('laudemio'), inicialValueLaudemio];
        setValue('laudemio', laudemios);
    };

    async function handleBtnAvancar(e) {
        setLoading(false);
        console.log("data: ", e);
        const pathApi = dadosVenda.id ? 'atualizar_imovel/' + dadosVenda.id : 'salvar_imovel';
        const arrayVenda = {
            ...e,
            informacao_imovel_id: dadosVenda?.informacao?.id,
            origem: dadosVenda.id ? 'editar_venda' : 'minuta',
            usuario_id: gerente_id,
            processo_id: processoId,
            status_processo: dadosVenda.id ? "" : 9,
            qtd_vendedores_fis: dadosVenda.qtd_vendedores_fis,
            qtd_vendedores_jur: dadosVenda.qtd_vendedores_jur,
            qtd_compradores_fis: dadosVenda.qtd_compradores_fis,
            qtd_compradores_jur: dadosVenda.qtd_compradores_jur,
            checkPularComprador: dadosVenda.checkComprador,
            valorEstimado: e.valorEstimado ? e.valorEstimado : dadosVenda.informacao?.valor_anunciado,
            opcionistas: dadosVenda.opcionistas
        };

        const data = await Axios.post(app + pathApi, arrayVenda, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                } else return res.data
            })
            .catch(function (error) {
                console.log(error);
            })
        if (data) {
            await refreshProcess(data.processo_id);
            reset();
            setPosicaoMinuta(2);
            setCountMenu(2);
        } else {
            toaster.notify("Algo não saiu como esperado.")
        }
        setLoading(true);
    };

    const refreshProcess = async (id) => {
        const adicionados = await getProcessosGerente(filtro, 1, 'adicionados');
        if (adicionados.processos) {
            setItemAdicionado(adicionados.processos.data);
            setTotalItensAdicionados(adicionados.total_processos);
            setTotalPaginasAdicionados(adicionados.total_pagina);
            setDadosVenda(adicionados.processos.data.find((element) => Number(id) === element.processo_id));
        } else {
            toaster.danger("Erro ao recarregar os processos, relogue e tente novamente.");
        }
    };

    const onError = (errors) => {
        const divId = Object.keys(errors)[0];
        const divSelect = document.getElementById(divId);
        if (divSelect) divSelect.focus();
    };

    return (
        <Pane>
            <Dialog
                width={808}
                isShown={isShowFormMinuta}
                title=''
                onCloseComplete={() => closeMinuta()}
                hasFooter={false}
                preventBodyScrolling={false}
                shouldCloseOnOverlayClick={false}
                containerProps={{
                    className: 'form-dialog-props'
                }}
            >
                <div className="form-dialog-fixed-no-tabs">
                    <Steps />
                </div>
                {loading === false
                    ?
                    <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                        <Spinner size={80} />
                    </Pane>
                    :
                    <div className="form-dialog">
                        <h4 style={{ marginTop: '35px' }}>De qual imóvel estamos falando?</h4>
                        <p className="descritption" style={{ marginTop: '15px' }}>Preencha com os dados do imóvel e valores.</p>

                        <div className="formGroup formAdd">
                            <form id="formGroup formAdd" className="formGroup-dialog formGroup-no-tabs" encType="multipart/form-data">
                                <Pane>
                                    <div className="rowForm">
                                        <TextInputField
                                            width={160}
                                            label="Código do Imóvel"
                                            description=""
                                            placeholder="Ex: DNA123456"
                                            style={watch('codImovel') ? inputGreen : inputDefault}
                                            {...register("codImovel", {
                                                required: false,
                                                onChange: (e) => handleCodImovel(e.target.value)
                                            })}
                                        />
                                    </div>

                                    <div className="rowForm">

                                        <TextInputField
                                            label="CEP"
                                            description=""
                                            placeholder="Ex: 00000-000"
                                            maxLength="9"
                                            required
                                            style={watch('cep') ? inputGreen : errors.cep ? inputError : inputDefault}
                                            validationMessage={errors.cep && msgErrorInvalid}
                                            {...register("cep", {
                                                required: true,
                                                onChange: (e) => handleCep(e.target.value)
                                            })}
                                        />

                                        <TextInputField
                                            width={215}
                                            label="Bairro"
                                            description=""
                                            value={watch('bairro')}
                                            placeholder={watch('cep') && !watch('bairro') ? 'Preencha com o bairro' : '---'}
                                            disabled={disableCamposEndereco.bairro}
                                            {...register('bairro', {
                                                required: watch('cep') && !watch('bairro') ? "Campo obrigatório" : false,
                                                // onChange: (e) => handleBairroLogradouro(e, index, 'bairro'),
                                            })}
                                            validationMessage={errors?.bairro && errors?.bairro.message}
                                            style={disableCamposEndereco.bairro ? undefined : watch('bairro') ? inputGreen : errors?.bairro ? inputError : inputDefault}
                                        />

                                        <TextInputField
                                            width={250}
                                            marginBottom={0}
                                            marginLeft={20}
                                            label="Logradouro"
                                            description=""
                                            value={watch('logradouro')}
                                            placeholder={watch('cep') && !watch('logradouro') ? 'Preencha com o logradouro' : '---'}
                                            disabled={disableCamposEndereco.logradouro}
                                            {...register('logradouro', {
                                                required: watch('cep') && !watch('logradouro') ? "Campo obrigatório" : false,
                                                // onChange: (e) => handleBairroLogradouro(e, index, 'logradouro'),
                                            })}
                                            validationMessage={errors?.logradouro && errors?.logradouro.message}
                                            style={disableCamposEndereco.logradouro ? undefined : watch('logradouro') ? inputGreen : errors?.logradouro ? inputError : inputDefault}
                                        />
                                    </div>

                                    <div className="rowForm">
                                        <TextInputField
                                            width={140}
                                            label="Número*"
                                            type='number'
                                            className='inputContador'
                                            description=""
                                            placeholder="Ex: 456"
                                            style={errors.numero ? inputError : watch('numero') ? inputGreen : inputDefault}
                                            {...register("numero", {
                                                required: msgErrorInvalid,
                                                // onChange: (e) => handleSomenteNumero(e), 
                                                onBlur: () => getHaveLaudemio(watch('logradouro'), watch('numero'))
                                            })}
                                            validationMessage={errors.numero && errors.numero.message}
                                        />

                                        <TextInputField
                                            width={180}
                                            label="Unidade"
                                            description=""
                                            placeholder="Número do apartamento"
                                            {...register("unidade")}
                                            style={watch('unidade') ? inputGreen : inputDefault}
                                        />

                                        <TextInputField
                                            width={310}
                                            marginBottom={0}
                                            marginLeft={20}
                                            label="Complemento"
                                            description=""
                                            placeholder="Bloco, lote ou condomínio"
                                            {...register("complemento")}
                                            style={watch('complemento') ? inputGreen : inputDefault}
                                        />
                                    </div>

                                    <div className="rowForm">
                                        <TextInputField
                                            width={215}
                                            label="Cidade"
                                            description=""
                                            value={watch('cidade')}
                                            placeholder={watch('cep') && !watch('cidade') ? 'Preencha com a cidade' : '---'}
                                            disabled={disableCamposEndereco.cidade}
                                            {...register('cidade', {
                                                required: watch('cep') && !watch('cidade') ? "Campo obrigatório" : false,
                                                // onChange: (e) => 
                                            })}
                                            validationMessage={errors?.cidade && errors?.cidade.message}
                                            style={disableCamposEndereco.cidade ? undefined : watch('cidade') ? inputGreen : errors?.cidade ? inputError : inputDefault}
                                        />

                                        <TextInputField
                                            width={250}
                                            marginBottom={0}
                                            marginLeft={20}
                                            label="Estado"
                                            description=""
                                            value={watch('estado')}
                                            placeholder={!disableCamposEndereco.estado ? 'Preencha com a estado' : '---'}
                                            disabled={disableCamposEndereco.estado}
                                            {...register('estado', {
                                                required: watch('cep') && !watch('estado') ? "Campo obrigatório" : false,
                                                // onChange: (e) => 
                                            })}
                                            validationMessage={errors?.estado && errors?.estado.message}
                                            // style={inputDefault}
                                            style={disableCamposEndereco.estado ? undefined : watch('estado') ? inputGreen : errors?.estado ? inputError : inputDefault}
                                        />
                                    </div>

                                    <div className="rowForm">
                                        <TextInputField
                                            width={160}
                                            label="Vagas na garagem (escrituradas)"
                                            description=""
                                            placeholder="Ex: 1"
                                            {...register("vagas")}
                                            style={watch('vagas') ? inputGreen : inputDefault}
                                        />
                                    </div>

                                    <div className="row-recibo">
                                        <div className="subtitle">Registro e Escritura*</div>

                                        <div className="rowForm" id='escritura' tabIndex={0}>
                                            <SelectField
                                                className={watch('escritura') ? 'inputGreenSelect' : errors.escritura ? 'inputErrorSelect' : 'inputDefaultSelect'}
                                                label="Escritura*"
                                                value={watch('escritura')}
                                                {...register("escritura", {
                                                    required: true,
                                                    onChange: (e) => setValue('escritura', e.target.value),
                                                })}
                                                validationMessage={errors.escritura && msgErrorInvalid}
                                            >
                                                <option value="" hidden>Selecione</option>
                                                {listEscrituras.map((value) => (
                                                    <option key={value.id} value={value.id} >{value.escritura}</option>
                                                ))}

                                            </SelectField>
                                        </div>

                                        <div className="rowForm">
                                            <InputSimples
                                                label="Lavrada em"
                                                placeholder="Ex: DD/MM/AAAA"
                                                nameCampo="lavrada"
                                                maxLength={10}
                                                register={register}
                                                watch={watch}
                                            />

                                            <InputSimples
                                                label="Livro"
                                                placeholder="Ex: SC-345"
                                                nameCampo="livro"
                                                register={register}
                                                watch={watch}
                                            />
                                        </div>

                                        <div className="rowForm">
                                            <InputSimples
                                                label="Folha"
                                                placeholder="Ex: 25"
                                                nameCampo="folha"
                                                maxLength={10}
                                                register={register}
                                                watch={watch}
                                            />

                                            <InputSimples
                                                label="Ato"
                                                placeholder="Ex: 695433"
                                                nameCampo="ato"
                                                register={register}
                                                watch={watch}
                                            />
                                        </div>

                                        <div className="rowForm">
                                            <InputSimples
                                                label="Cartório"
                                                placeholder="Ex: 17"
                                                nameCampo="cartorio"
                                                register={register}
                                                watch={watch}
                                            />

                                            <InputSimples
                                                label="Registrado no RGI"
                                                placeholder="Ex: 25"
                                                nameCampo="rgi"
                                                register={register}
                                                watch={watch}
                                            />
                                        </div>

                                        <div className="rowForm">
                                            <InputRequired
                                                label="Matrícula nº"
                                                placeholder="Ex: 17655566"
                                                nameCampo="matricula"
                                                errors={errors}
                                                register={register}
                                                watch={watch}
                                            />

                                            <InputRequired
                                                label="Inscrição Municipal"
                                                placeholder="Ex: 12345678"
                                                nameCampo="inscricaoMunicipal"
                                                errors={errors}
                                                register={register}
                                                watch={watch}
                                            />
                                        </div>

                                        <div className="row-laudemio">
                                            <div className="subtitle mb15">Laudêmio</div>

                                            {watch('laudemio') && watch('laudemio').map((element, index) => (
                                                <div className="rowForm" key={index}>
                                                    <SelectsLaudemios
                                                        label="Tipo de Laudêmio*"
                                                        nameCampo="tipo_laudemio"
                                                        element={element}
                                                        index={index}
                                                        register={register}
                                                        watch={watch}
                                                        errors={errors}
                                                        setValue={setValue}
                                                    />

                                                    {(element.tipo_laudemio === '3' || element.tipo_laudemio === '4') &&
                                                        <SelectsLaudemios
                                                            label={labelLaudemio(element.tipo_laudemio).label}
                                                            nameCampo="valor_laudemio"
                                                            element={element}
                                                            index={index}
                                                            register={register}
                                                            watch={watch}
                                                            errors={errors}
                                                            setValue={setValue}
                                                        />}
                                                    {element.tipo_laudemio === '1' &&
                                                        <TextInputField
                                                            width={237}
                                                            label={labelLaudemio(element.tipo_laudemio).label}
                                                            placeholder={labelLaudemio(element.tipo_laudemio).placeholder}
                                                            marginBottom={5}
                                                            style={
                                                                errors?.laudemio?.[index]?.valor_laudemio
                                                                    ? inputError
                                                                    : element.valor_laudemio !== '' ? inputGreen : inputDefault
                                                            }
                                                            validationMessage={errors?.laudemio?.[index]?.valor_laudemio && msgErrorInvalid}
                                                            {...register('laudemio.' + index + '.valor_laudemio', {
                                                                required: true,
                                                            })}
                                                        />}

                                                    <Button
                                                        marginLeft={16}
                                                        marginTop={26}
                                                        appearance="primary"
                                                        intent="danger"
                                                        onClick={() => removeLaudemio(index)}
                                                        type="button"
                                                    >
                                                        <DeleteIcon
                                                            size={10}
                                                            color='white'
                                                            marginRight={10}
                                                        /> Excluir
                                                    </Button>
                                                </div>
                                            ))
                                            }

                                            <Button
                                                disabled={watch('laudemio') && watch('laudemio').length > 3}
                                                marginRight={16}
                                                className={watch('laudemio') && watch('laudemio').length > 3 ? "btn-adicionar button add btn-add-laudemio-disabled" : "btn-adicionar button add"}
                                                onClick={() => addLaudemio()}
                                                type="button"
                                            >
                                                <PlusIcon
                                                    width={10}
                                                    color="#8F95B2"
                                                    marginRight={10}
                                                />
                                                Adicionar Laudemio
                                            </Button>
                                        </div>
                                    </div>

                                    <div className="row-recibo">
                                        <div className="subtitle">Formas de pagamento*</div>

                                        <div className="line-checkbox mb0" id='forma_pagamento' tabIndex={1}>
                                            {formasDePagamentos.map(({ label, value, path, disabled }, index) => (
                                                <CheckFormasPagamentos
                                                    label={label}
                                                    value={value}
                                                    path={path}
                                                    key={index}
                                                    disabled={disabled}
                                                    watch={watch}
                                                    register={register}
                                                    setValue={setValue}
                                                />
                                            ))}

                                            <input
                                                type="hidden"
                                                {...register("forma_pagamento", {
                                                    required: true,
                                                })}
                                            />
                                        </div>
                                        {errors.forma_pagamento &&
                                            <InlineAlert intent='danger' className="inline-alert">
                                                <span className='error-input f12 mb0'>
                                                    {msgErrorInvalid}, selecione pelo menos uma forma de pagamento
                                                </span>
                                            </InlineAlert>
                                        }

                                        <div>
                                            <Label style={{ margin: '15px 0 15px 0', display: 'block' }}>
                                                Caso necessário, insira observações sobre o pagamento.
                                            </Label>
                                            <Textarea
                                                placeholder="Exemplo: A primeira parte do pagamento será à vista. O restante financiado."
                                                {...register("observacaoPagamento")}
                                                style={watch('observacaoPagamento') ? inputGreen : inputDefault}
                                            />
                                        </div>
                                    </div>

                                    <div className="row-recibo">
                                        <div className="subtitle">Valores</div>
                                        <div className="rowForm">
                                            <InputRequired
                                                label="Valor de venda"
                                                placeholder="Ex: R$ 500.000,00"
                                                nameCampo='valorVenda'
                                                register={register}
                                                watch={watch}
                                                errors={errors}
                                            />

                                            <InputRequired
                                                label="Valor do sinal"
                                                placeholder="Ex: R$ 500.000,00"
                                                nameCampo='valorSinal'
                                                register={register}
                                                watch={watch}
                                                errors={errors}
                                            />
                                        </div>

                                        <div className="rowForm">
                                            <InputRequired
                                                label="Prazo para escritura"
                                                placeholder="Ex: 30"
                                                nameCampo='prazo'
                                                register={register}
                                                watch={watch}
                                                errors={errors}
                                                maxLength={4}
                                                {...register("prazo")}
                                            />
                                        </div>
                                    </div>

                                    <div className="row-recibo">
                                        <div className="subtitle" style={{ marginBottom: '15px' }}>Multa</div>
                                        <p className="descritption" style={{ margin: '0 0 20px 0', lineHeight: '20px' }}>Se necessário, informe o valor de multa diária para o vendedor.<br /> Esse valor está relacionado ao prazo limite de entrega das chaves.</p>

                                        <div className="rowForm">
                                            <InputSimples
                                                label="Valor da multa diária"
                                                placeholder="Ex: R$ 500.000,00"
                                                nameCampo='valoMulta'
                                                register={register}
                                                watch={watch}
                                                errors={errors}
                                            />
                                        </div>

                                        <div className="row-recibo">
                                            <div className="subtitle">Exceções (cláusula segunda)</div>

                                            <Label className="label">
                                                Informe as exceções que devem ser inclusas na Cláusula segunda do ato, conforme pode ser lida no recibo de sinal "respondendo, ainda, pela evicção de direito, exceto:"
                                            </Label>
                                            <Textarea
                                                placeholder="Exemplo: havendo dolo do adquirente."
                                                style={watch('excecoes') ? inputGreen : inputDefault}
                                                {...register("excecoes", { required: false })}
                                            />
                                        </div>

                                        <div className="row-recibo">
                                            <div className="subtitle">Bens móveis (cláusula décima)</div>

                                            <Label className="label">
                                                Se acordado entre as partes que o imóvel será entregue vazio, pule esta etapa. Caso o imóvel seja entregue com outros bens, faça a listagem dos bens móveis seguindo o exemplo abaixo:
                                            </Label>
                                            <Textarea
                                                placeholder="Exemplo: O imóvel será entregue com uma televisão do tipo Smart TV de 50 polegadas da marca LG."
                                                style={watch('bensMoveis') ? inputGreen : inputDefault}
                                                {...register("bensMoveis", { required: false })}
                                            />
                                        </div>
                                    </div>

                                    <div className="mensagem-campos msg-obrigatorio">*Campos obrigatórios</div>

                                    <div className="footer-form">
                                        <div>
                                        </div>

                                        <div>
                                            <Button
                                                type="submit"
                                                appearance="primary"
                                                marginLeft={15}
                                                onClick={handleSubmit((e) => handleBtnAvancar(e), onError)}
                                            >
                                                Avançar
                                                <ChevronRightIcon className="icon-save" />
                                            </Button>
                                        </div>
                                    </div>
                                </Pane>
                            </form>
                        </div>
                    </div>
                }
            </Dialog>
        </Pane>
    )
}