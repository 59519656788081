import Axios from 'axios';
import Serve from '../app/serve';

async function removeItemChecklist(parcela_id) {
  const token = localStorage.getItem('token');
  const app = Serve();
  let data;

  await Axios.post(app + 'excluir_pagamento', {
    parcela_id,
  }, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then(res => {
      console.log(res);
      if (res.data) data = res.data;
    })
    .catch(function (error) {
      console.log(error);
    })

  return data;
};

export default removeItemChecklist;