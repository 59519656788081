import React, {useContext} from 'react'
import {Pane} from 'evergreen-ui';
import VendasContext from '../../../../context/VendasContext';
import DialogDevolucaoRevisao from './components/Dialog/DialogDevolucaoRevisao';
import DialogRankGerente from './components/Dialog/DialogRankGerente';

export default function DialogEntregarVenda({recarregarVendas}) {

    const {
        dadosVenda,
    } = useContext(VendasContext);

    return (
        <Pane>
            {dadosVenda.status_processo === 22 
                ?
                <DialogDevolucaoRevisao recarregarVendas={recarregarVendas}/>
                :
                <DialogRankGerente recarregarVendas={recarregarVendas}/>
            }
        </Pane>
    )
}