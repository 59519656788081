import React, { useContext, useState } from 'react';
import { Badge, Pane, Table, Button, DownloadIcon, LockIcon, Tooltip } from 'evergreen-ui';
import ShowDocument from '../../../../../../app/showDocument';
import DirecaoContext from '../../../../../../context/DirecaoContext';
import PlanilhaComissaoPDF from '../../../../../../pdf/PlanilhaDeComissao';
import Mei from '../../../../../../doc/Mei';
import getProcessById from '../../../../../../apis/getProcessById';

function ColTable(props) {
    return (
        <Table.TextCell maxWidth={props.width}>
            <div className={`mb7 color101840 `}>{props.title}</div>

            <span className='fw400 color696F8C'>
                {props.subtitle}
            </span>
        </Table.TextCell>
    )
};

function ColMapPorcentagens({ users }) {
    return (
        <Table.Row height={"auto"}>
            <Table.TextCell className="p10">
                <div className='flex mb10'>
                    <div className='mr17 w195'>Nome completo </div>
                    <div className="mr42 w97">Porcentagem</div>
                    <div className="mr42 w68">Desconto</div>
                    <div className='w97'>Valor</div>
                </div>

                {users?.map((user) => (
                    <div className='flex mb5'>
                        <div className='wsNormal mr17 w195 fw400'>
                            {user.name}
                        </div>
                        <div className="mr42 w97 fw400">
                            {user.porcentagem_comissao} %
                        </div>
                        <div className="mr42 w68 fw400">
                            {user.desconto || "---"}
                        </div>
                        <div className="fw400 w97 wsNormal">
                            R$ {user.valor_real}
                        </div>
                    </div>
                ))}
            </Table.TextCell>
        </Table.Row>
    )
};

function ColMapParcelas({ parcelas, integral }) {
    const [listaPeriodo] = useState([
        { label: 'Na assinatura do Recibo de Sinal', value: '1' },
        { label: 'Na retirada das certidões', value: '2' },
        { label: 'No ato da escritura do Imóvel', value: '3' },
        { label: 'Na entrega das chaves', value: '4' },
        { label: 'Na transferência de registros', value: '5' },
    ]);

    const labelPeriodo = (value) => {
        if(!value) return ""
        return listaPeriodo.find((item) => item.value === value).label
    };

    return (
        <Table.Row height={"auto"}>
            <Table.TextCell className="p10">
                <div className='flex mb10'>
                    <div hidden={integral} className='mr35 w60'>Parcelas </div>
                    <div hidden={integral} className="mr44 w97">Valor</div>
                    <div className="mr42 w229">Período do pagamento</div>
                </div>
                {parcelas.length === 0 && <div className='mb5'>---</div>}
                {parcelas?.map((parcela, index) => (
                    <div className='flex mb5'>
                        <div hidden={integral} className='wsNormal mr35 w60 fw400'>
                            {index + 1}
                        </div>
                        <div hidden={integral} className="mr44 w97 fw400">
                            {parcela.valor_parcela}
                        </div>
                        <div className="mr42 w229 fw400">
                            {labelPeriodo(parcela.periodo_pagamento)}
                        </div>
                    </div>
                ))}
            </Table.TextCell>
        </Table.Row>
    )
};

export default function Comissao() {
    const [token] = useState(localStorage.getItem('token'));
    const msgCampo = '----';

    const {
        dadosVenda,
        tipoLaudemio,
    } = useContext(DirecaoContext);

    const { comissao } = dadosVenda;
    console.log(comissao);
    const gerentesGerais = dadosVenda.comissao_gerente_gerais;
    const gerentes = dadosVenda.comissao_gerentes;
    const corretores = comissao?.corretores_vendedores_comissao.map((user) => ({
        ...user, 'porcentagem_comissao': user.porcentagem_comissao_corretor
    }));
    const opcionistas = comissao?.corretores_opicionistas_comissao.map((user) => ({
        ...user, 'porcentagem_comissao': user.porcentagem_comissao_opcionista
    }));
    const planilha = comissao?.documentos.data.filter((documento) => documento.identifica_documento === "comissão");

    const percentualOpcionistas = (type) => {
        switch (opcionistas[0]?.tipo_laudo_opcionista) {
            case "simples":
                return type === "total" ? "16%" : 'Simples';
            case "com_chave":
                return type === "total" ? "18%" : 'Com chave';
            case "exclusividade":
                return type === "total" ? "20%" : 'Exclusividade';
            case "lançamento":
                return type === "total" ? "8%" : 'Lançamento';
            default:
                return "";
        }
    };

    //lê os laudemios retornados em dadosVenda
    const laudemiosEnviados = dadosVenda.laudemios.map(laudemio => parseInt(laudemio.valor_laudemio));

    //Comparar dadosVenda a uma lista de todos os laudemios "tipoLaudemio" e retorna o id e nomes para exibir
    const laudemioValorToName = tipoLaudemio.filter((laudemio) => laudemiosEnviados.includes(laudemio.id));
    const laudemiosSemNome = dadosVenda.laudemios.filter((laudemio) => laudemio.tipo_laudemio === "2" || laudemio.tipo_laudemio === "1");
    laudemioValorToName.push(...laudemiosSemNome);

    const capitalize = (text) => {
        if(!text) return ""
        const words = text.split(" ").map((word) => (
            word[0].toUpperCase() + word.substring(1)
        ))
        return words.join(" ");
    };

    const handleMei = async (processoId) => {
        const processo = await getProcessById(processoId);
        console.log(processo);

        Mei(processo);
    }

    return (
        <div className="detadelhes-sidesheet">
            {comissao === null
                ?
                <Table.Row>
                    <Table.TextCell className="historicoStatus" width={150}>
                        <Table.TextCell className="fontStatus">
                            <span>Nenhuma Comissão foi cadastrada no momento.</span>
                        </Table.TextCell>
                    </Table.TextCell>
                </Table.Row>

                :
                <>
                    <Pane>
                        <React.Fragment>
                            <h2 className="subtitle subtitle-table">Download</h2>
                        </React.Fragment>
                        {planilha.length > 0
                            ?
                            <div>
                                <h2 className="f18 fw600 mb16">Planilha de Comissão</h2>
                                <p className='fw400 f12 mb16'>Faça aqui o download da Planilha inserida no sistema através do seu upload do arquivo .</p>
                                {planilha.map((documento) =>
                                    <div>
                                        <Button className='mb10 w250' onClick={(e) => [ShowDocument(documento.id, 'documento', token), e.preventDefault()]}>
                                            <DownloadIcon size={12} color="#474D66" marginRight={10} /> Download {documento.tipo_documento.nome.toLowerCase()}
                                        </Button>
                                    </div>
                                )}
                            </div>
                            :
                            comissao.verificar_enviar_planilha === 1
                                ? 
                                    <div style={{display: 'flex', marginBottom: '30px', gap: '20px'}}>
                                        <div>
                                            <Button appearance="primary" intent="primary" onClick={(e) => PlanilhaComissaoPDF(dadosVenda)}><DownloadIcon size={12} color="#fff" marginRight={10} /> Planilha de Comissão</Button>
                                        </div>
                                        
                                        <div>
                                            <Button appearance="primary" intent="primary" onClick={(e) => handleMei(comissao.processo_id)}><DownloadIcon size={12} color="#fff" marginRight={10} />Recibo de MEI</Button>
                                        </div>
                                    </div>
                                :
                                <div style={{marginBottom: '30px'}}>
                                    <Tooltip content="O download da Planilha será liberado após revisão da Equipe de Apoio à vendas.">
                                        <Button appearance="primary" intent="primary" className='btnDisable'><LockIcon size={12} color="#fff" marginRight={10} /> Planilha de Comissão</Button>
                                    </Tooltip>

                                    <Tooltip content="O download do Recibo de MEI será liberado após revisão da Equipe de Apoio à vendas.">
                                        <Button appearance="primary" intent="primary" className='btnDisable'><LockIcon size={12} color="#fff" marginRight={10} />Recibo de MEI</Button>
                                    </Tooltip>
                                </div>

                        }

                        <React.Fragment>
                            <h2 className="subtitle subtitle-table">Total</h2>
                        </React.Fragment>
                    </Pane>

                    <Table.Row>
                        <ColTable title={"Tipo"} width="95px" subtitle={comissao.comissao === 'integral' ? "Integral" : "Parcelada"} />
                        <ColTable title={"Comissão total"} width="140px" subtitle={comissao?.valor_comissao_total || ''} />
                        <ColTable title={"Deduções"} subtitle={comissao.deducao !== null ? comissao.deducao : msgCampo} />
                    </Table.Row>

                    <ColMapParcelas parcelas={comissao.parcelas_empresa} integral={comissao.comissao === "integral"} />

                    <Pane>
                        <React.Fragment>
                            <h2 className="subtitle subtitle-table">Líquida</h2>
                        </React.Fragment>
                    </Pane>

                    <Table.Row>
                        <ColTable title={"Forma de Pagamento"} subtitle={capitalize(comissao?.liquida) || msgCampo} />
                        <ColTable title={"Comissão líquida"} subtitle={comissao?.valor_comissao_liquida || msgCampo} />
                    </Table.Row>

                    {(!planilha || planilha.length === 0) &&
                        <Pane>
                            <Pane /*hidden={gerentesGerais.length <= 0}*/>
                                <h2 className="subtitle subtitle-table">Comissão e porcentagens</h2>
                            </Pane>

                            <Pane className='mt16'>
                                <React.Fragment>
                                    <spam className="mr20">Gerentes Gerais</spam> {gerentesGerais.length > 0 && <Badge color="blue">{`Total de ${comissao?.valor_comissao_gg}%`}</Badge>}
                                </React.Fragment>
                                {dadosVenda.loja.gerente_geral_id === null &&
                                    <div className='color101840 fw400 mt15'>
                                        A loja {dadosVenda.loja.nome} não possui nenhum GG no momento, mas podem ter sido registrados um ou mais profissionais para receber a comissão equivalente.
                                    </div>}
                            </Pane>

                            {/*gerentesGerais.length > 0 ?
                                <Pane className='mt16'>
                                    <ColMapPorcentagens users={gerentesGerais} />
                                </Pane>
                                : <div className="fw400 f12 mt10">A loja {dadosVenda.loja.nome} não possui nenhum GG no momento, mas podem ter sido registrados um ou mais profissionais para receber a comissão equivalente.</div>
                            */}

                            {gerentes.length > 0 &&
                                <Pane>
                                    <Pane className={"mt40"}>
                                        <React.Fragment>
                                            <spam className="mr20">Gerentes</spam> <Badge color="blue">Total de {comissao?.valor_comissao_gerente}%</Badge>
                                        </React.Fragment>
                                    </Pane>

                                    <Pane className='mt16'>
                                        <ColMapPorcentagens users={gerentes} />
                                    </Pane>
                                </Pane>
                            }

                            {corretores.length > 0 &&
                                <Pane>
                                    <Pane className={
                                        gerentesGerais.length > 0 || gerentes.length > 0
                                            ? 'mt40'
                                            : 'mt16'
                                    }
                                    >
                                        <React.Fragment>
                                            <spam className="mr20">Corretores vendedores</spam> <Badge color="blue">Total de {corretores[0]?.total_comissao_corretor}%</Badge>
                                        </React.Fragment>
                                    </Pane>

                                    <Pane className='mt16'>
                                        <ColMapPorcentagens users={corretores} />
                                    </Pane>
                                </Pane>
                            }

                            {opcionistas.length > 0 &&
                                <Pane>
                                    <Pane className='mt40'>
                                        <React.Fragment>
                                            <spam className="mr20">Opcionistas</spam> <Badge color="blue">Total de {percentualOpcionistas("total")}</Badge>
                                        </React.Fragment>
                                    </Pane>
                                    <Table.Row className='mt16'>
                                        <ColTable title={"Tipo de Laudo"} subtitle={percentualOpcionistas("tipo")} />
                                    </Table.Row>

                                    <Pane className='mt5'>
                                        <ColMapPorcentagens users={opcionistas} />
                                    </Pane>

                                </Pane>
                            }
                        </Pane>
                    }
                    <Pane>
                        <React.Fragment>
                            <h2 className="subtitle subtitle-table">Observações</h2>
                        </React.Fragment>
                    </Pane>
                    <Table.Row className='hAuto'>
                        <Table.TextCell className="wsNormal hAuto">
                            <div className='fw400 pb5 pt5'>
                                {comissao.observacoes ? comissao.observacoes : msgCampo}
                            </div>
                        </Table.TextCell>
                    </Table.Row>
                </>
            }
        </div>
    )
}