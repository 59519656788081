import React, { useState, useContext, useEffect } from 'react';
import { Button, Dialog, FilePicker, Label, SelectField, TextareaField, TextInputField } from 'evergreen-ui';
import NegociacaoContext from '../../../../context/NucleoContext';
import dateMask from '../../../../components/dateMask'
import Axios from 'axios'
import Serve from '../../../../app/serve';
import { useHistory } from 'react-router-dom';
import ShowDocument from '../../../../app/showDocument';

export default function DialogShare({ refreshTable }) {

  const history = useHistory();
  const app = Serve();
  const [token] = useState(localStorage.getItem('token'));
  const usuario_id = localStorage.getItem('usuario_id');

  const {
    isShowStatusChange,
    setIsShowStatusChange,
    setIsShowSharePosVenda,
    processSelect,
    statusList,
    setServiceId,
    loading, setLoading,
    contador, setContador,
    setIdStatusSolicitacaoCancelar
  } = useContext(NegociacaoContext);
  
  const endereco = processSelect?.processo?.imovel?.logradouro + ', ' + processSelect?.processo?.imovel?.numero;
  const inicialData = {
    observacao: processSelect?.status_solicitação.data[0]?.mensagem,
    previsao: processSelect?.status_solicitação.data[0]?.data_previsao || '',
    status: processSelect?.status_solicitação.data[0]?.id || '',
    comprovante: processSelect?.documentos.data.find((doc) => doc.tipo_documento.nome === "Comprovante pagamento") || '',
    boleto: processSelect?.documentos?.data?.find((doc) => doc.tipo_documento.nome === "Cobrança de pagamento") || '',
    documento_id: processSelect?.documentos?.data?.find((doc) => doc.tipo_documento.nome === "Cobrança de pagamento")?.id || ''
  };
  const [formData, setFormData] = useState(inicialData);

  useEffect(() => {
    setFormData(inicialData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processSelect]);

  function handleChange(e, name) {
    let value = e.target.value
    if (name === 'previsao') value = dateMask(value);
    formData[name] = value;
    if (name !== 'observacao') setFormData({ ...formData })
  };

  const handleConfirm = (close) => {
    setLoading(true)

    let solicitacao = new FormData();
    solicitacao.append('responsavel_alteracao_id', usuario_id);
    solicitacao.append('solicitacao_id', processSelect.id);
    solicitacao.append('processo_id', processSelect.processo.id);
    solicitacao.append('status_solicitacao_id', formData.status);
    solicitacao.append('mensagem', formData.observacao || "");
    solicitacao.append('previsao', formData.previsao || "");

    if(formData.boleto !== '') {
      solicitacao.append('boleto', formData.boleto);
      solicitacao.append('documento_id', formData.documento_id);
    }

    Axios.post(app + 'salvar_historico_solicitacao', solicitacao, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(res => {
      if (res.data.status && res.data.status === (401 || 498)) {
        localStorage.clear();
        history.push('/');
      } else {
        refreshTable();
        close();
        setIdStatusSolicitacaoCancelar(res.data.status_relacionamento_solicitacao)
        let timeleft = 5;
        const downloadTimer = setInterval(function () {
          if (timeleft < 1) {
            clearInterval(downloadTimer);
            setContador(0)
          } else {
            setContador(timeleft)
          }
          timeleft -= 1;
        }, 1000);

        setServiceId(processSelect.id);
        setIsShowSharePosVenda(true);
      }
    }).catch(function (error) {
      console.log(error);
      setLoading(false);
    })
  };

  const onClose = () => {
    setIsShowStatusChange(false);
    setFormData(inicialData);
  };

  return (
    <Dialog
      isShown={isShowStatusChange}
      title="Atualize o pós-venda sobre a solicitação"
      onCloseComplete={() => onClose()}
      confirmLabel="Compartilhar Status ➜"
      cancelLabel="Voltar"
      onConfirm={close => handleConfirm(close)}
      isConfirmLoading={loading}
    >
      <div className="dialog-content dialog-content-rua">{endereco}</div>
      <div className="dialog-content fw400">
        Compartilhe as atualizações do serviço com o Pós-venda. Desta forma, o responsável pela solicitação saberá as previsões de entrega.
      </div>
      <div className='fw600 mt20'>Observação Pós Venda:</div>
      <div className='mb20 fw400'>{processSelect?.observacao || "Nenhuma observação foi cadastrada."}</div>

      <div className="form-dialog">
        <div className='flex'>
          <SelectField
            width={240}
            marginBottom={14}
            label='Selecione o Status do serviço'
            defaultValue={processSelect?.status_solicitação.data[0]?.id || ''}
            onChange={(e) => handleChange(e, "status")}
            disabled={loading}
            marginRight={10}
          >
            {Object.keys(statusList).map(key =>
              <option value={statusList[key].id}>{statusList[key].status}</option>
            )}
          </SelectField>          
          {Number(formData.status) !== 4 &&
            <TextInputField
              name="textarea-1"
              label={Number(formData.status) === 5 ? "Data de Vencimento" : "Previsão de entrega"}
              marginBottom={14}
              width="50%"
              maxLength={10}
              placeholder="DD/MM/AAAA"
              value={formData.previsao}
              onChange={(e) => handleChange(e, "previsao")}
              disabled={loading}
            />}
        </div>       
        
        {Number(formData.status) === 5 &&
        <div className='mb12'>
            <Label>Envie boletos em pdf.</Label>
          <div className='flex mt5'>
            <FilePicker
              className='w250'
              placeholder={formData.boleto?.arquivo ? formData.boleto?.arquivo : "Selecione o arquivo"}              
              type="file"
              disabled={formData.comprovante ? true : false}
              accept=".pdf, .doc, .docx"
              onChange={(e) => formData.boleto = e[0]}
            />
          <Button
            marginLeft={25}
            appearance="primary"
            disabled={!formData.boleto?.id}
            onClick={(btn) => [ShowDocument((formData.boleto?.id), 'documento', token), btn.preventDefault()]}
          >Ver</Button>
          </div>
        </div>}

        <TextareaField
          name="textarea-1"
          label="Observações"
          defaultValue={formData.observacao}
          placeholder="Exemplo: Além do Kit de Casal, vamos precisar também de uma averbação."
          onChange={(e) => handleChange(e, "observacao")}
          disabled={loading}
        />
      </div>
    </Dialog>
  )
}
