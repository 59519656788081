import React, { useContext, useState } from 'react';
import { Pane, Avatar, Tablist, Button, Tab, LogOutIcon, Tooltip } from 'evergreen-ui';
import PainelAdminContext from '../../../context/PainelAdminContext'
import Logout from '../../../apis/logout';

export default function SideMenu() {
  const userName = localStorage.getItem('nome_usuario');
  const [tabs] = useState(['Dashboard', 'Usuários', 'Upload de Corretores', 'Pós-Venda'])

  const {
    selectedIndex, setSelectedIndex,
  } = useContext(PainelAdminContext)

  return (
    <Pane className='sideMenu'>
      <div className="painel">
        <div className='ml20 flex-center headerSideMenu'>
          <Avatar name={userName} size={40} marginRight={10} />
          <Tooltip content={userName}>
            <h1 className="titleSideMenu">{userName}</h1>
          </Tooltip>
        </div>
        <div className='painel'>
          <Tablist marginBottom={16} flexBasis={240}>
            {tabs.map((tab, index) => (
              <Tab
                key={tab}
                id={tab}
                direction="vertical"
                appearance="primary"
                onSelect={() => setSelectedIndex(index)}
                isSelected={index === selectedIndex}
                aria-controls={`panel-${tab}`}
                marginTop={10}
              >
                {tab}
              </Tab>
            ))}
          </Tablist>
        </div>
        <div className='rodapePainel painel'>
          <Button appearance="minimal" onClick={() => Logout()}>
            <LogOutIcon marginRight={16} /> Sair
          </Button>
        </div>
      </div>
    </Pane>
  )
}