import React, { useContext, useState, useEffect } from 'react';
import { Pane, Autocomplete, Pagination, TextInput, Table, Button, Text, Select, Tooltip, StatusIndicator, ShareIcon, AddIcon, Badge, FilterIcon, toaster } from 'evergreen-ui';
import NucleoContext from '../../../context/NucleoContext'
import Lottie from 'react-lottie';
import animationData from '../../../images/Animations/loadingDocs.json';
import SideSheetView from './SideSheetView';
import StatusChange from './Dialogs/StatusChange';
// import ConfirmDel from './Dialogs/ConfirmDel';
import CornerSharePosvenda from './Corners/CornerSharePosvenda';
import Axios from 'axios'
import Serve from '../../../app/serve';
import getServicosById from '../../../apis/getServicosById';
import getResumoServicos from '../../../apis/getResumoServicos';

export default function TableNucleo({ getLists }) {
  const app = Serve();

  const [tableListService, setTableListService] = useState();
  const [quantFiltro] = useState([]);

  //Pagination
  const [paginaAnterior, setPaginaAnterior] = useState([]);
  const [paginaPosterior, setPaginaPosterior] = useState([]);
  const [loadingProximas, setLoadingProximas] = useState(false);

  const {
    loading, setLoading,
    nucleoTableValue, setNucleoTableValue,
    processoId, setProcessoId,
    serviceId, setServiceId,
    statusList, adressList,
    setIsShownSide,
    setSelectedIndex,
    processSelect, setProcessSelect,
    setIsShowStatusChange,
    filtros, setFiltros,
    pageNumber, setPageNumber,
    totalPages, setTotalPages,
    totalProcessFilter, totalProcessNumber,
    setTotalProcessFilter, setTotalProcessNumber,
    isShownSide, isShowStatusChange,
    setIsShowSharePosVenda,
  } = useContext(NucleoContext);  
  console.log("Table List: ", nucleoTableValue);
  console.log("Filters: ", filtros);

  useEffect(() => {
    refreshTable();
    quantidadeFiltros();
    proximasPaginas(pageNumber, "First")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refreshTable = async (filtro) => {
    const data = { filtros, pageNumber };
    if (serviceId) {
      const processSelect = await getServicosById(serviceId);
      setProcessSelect(processSelect);
    }
    if (filtro) proximasPaginas(pageNumber, "First");
    else noFilter();
    await getLists(data);
  };

  useEffect(() => {
    if (isShownSide || isShowStatusChange) {
      if (processSelect?.status_visualizacao_atual === 0) {
        async function statusNewChange() {
          await Axios.post(app + 'atualizar_status_visualizacao_nucleo', {
            'id': processSelect?.status_solicitação.data[0]?.id_relacao_status
          }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
          })
            .then(res => {
              if (res !== undefined) {
                if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                  localStorage.clear();
                } else {
                  nucleoTableValue.forEach((service) => {
                    if (service.id === serviceId) service.status_solicitação.data[0].status_visualizacao = 1;
                  })
                  setNucleoTableValue([...nucleoTableValue]);
                }
              }
            })
        };
        statusNewChange();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShownSide, isShowStatusChange, serviceId]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const quantidadeFiltros = async () => {
    let filtrosSelecionados = 0;
    if (filtros.status !== '') filtrosSelecionados++;
    if (filtros.endereco !== '') filtrosSelecionados++;
    quantFiltro[0] = filtrosSelecionados;
  };

  const noFilter = async (remove) => {
    const data = { filtros: { "status": '', "endereco": '' }, pageNumber: 1 };
    const noFilterServices = await getResumoServicos(data);
    setTableListService(noFilterServices);
    if (remove) {
      setNucleoTableValue([...noFilterServices.solicitacoes.data]);
      setTotalPages(noFilterServices.total_pagina);
      setTotalProcessFilter(noFilterServices.total_solicitacoes_filtradas);
      setTotalProcessNumber(noFilterServices.total_solicitacoes);
    }
  };

  const removeFilters = async () => {
    setLoading(true);
    if (tableListService) {
      await setNucleoTableValue([...tableListService.solicitacoes.data]);
      setTotalPages(tableListService.total_pagina);
      setTotalProcessFilter(tableListService.total_solicitacoes_filtradas);
      setTotalProcessNumber(tableListService.total_solicitacoes);
    } else {
      await noFilter("remove")
    }
    setFiltros({ "status": '', "endereco": '' })
    quantFiltro[0] = 0;
    setLoading(false);
  };

  const colorStatus = (status) => {
    if (status === 1) return '#3366FF'
    else if (status === 2) return 'warning'
    else if (status === 3) return 'danger'
    else return 'success'
  };

  const sideSheetOpen = async (e, id) => {
    setServiceId(id);
    setLoading(true);
    setSelectedIndex(e);
    setIsShownSide(true);
    if(!processSelect || id !== processSelect.id) {
      const service = await getServicosById(id);
      if (!service) return toaster.notify("Algo não saiu como esperado, tente novamente mais tarde.");
      setProcessSelect(service);
      setProcessoId(service.processo.id);
    }
    setIsShowSharePosVenda(false);
    setLoading(false);
  };

  const dialogOpen = async (infoService) => {
    const {id} = infoService;
    setServiceId(id);
    if(!processSelect || id !== processSelect.id) {
      const service = await getServicosById(id);
      if (!service) return toaster.notify("Algo não saiu como esperado, tente novamente mais tarde.");
      setProcessSelect(service);
    }
    setIsShowSharePosVenda(false);
    setIsShowStatusChange(true);
  };

  async function proximasPaginas(page, first) {
    setPaginaAnterior([]);
    setPaginaPosterior([]);
    setLoadingProximas(true);
    if (page < totalPages || first) {
      const params = { filtros, pageNumber: page + 1 }
      const data = await getResumoServicos(params);
      console.log("Página Posterior: ", data);
      setPaginaPosterior([...data.solicitacoes.data])
    }
    if (page > 1) {
      const params = { filtros, pageNumber: page - 1 }
      const data = await getResumoServicos(params);
      console.log("Página Anterior: ", data);
      setPaginaAnterior([...data.solicitacoes.data])
    }
    setLoadingProximas(false);
  };

  const handleFilters = async (e) => {
    setLoading(true);
    setPageNumber(1);
    localStorage.setItem('page_number', 1);
    if (e === '') {
      setFiltros({ ...filtros })
    }
    if (e.target) {
      const name = e.target.name;
      const value = e.target.value;
      filtros[name] = Number(value);
    } else {
      filtros.endereco = e;
    }
    quantidadeFiltros();
    setFiltros({ ...filtros })    
    await getLists({ filtros, pageNumber })
  };

  const listItemsPerPage = async (page) => {
    setLoading(true);
    localStorage.setItem('page_number', page);
    setPageNumber(page);
    if (page === pageNumber) {
      setLoading(false);
      return;
    };
    if (page === pageNumber + 1 && !loadingProximas && paginaPosterior.length > 0) {
      await setNucleoTableValue([...paginaPosterior]);
      proximasPaginas(page);
      setLoading(false);
      return;
    };
    if (page === pageNumber - 1 && !loadingProximas && paginaAnterior.length > 0) {
      await setNucleoTableValue([...paginaAnterior]);
      proximasPaginas(page);
      setLoading(false);
      return;
    } else {
      quantidadeFiltros();
      const params = { filtros, pageNumber: page }
      await getLists(params);
      proximasPaginas(page);
    }
  };

  
  const widthPage = (text) => {
    const { innerWidth: width } = window;
    const numberLetters = text.split("").length
    if (width > 1600 && numberLetters > 40) {
        return ""
    } else if (width < 1600 && numberLetters > 34) {
      return ""
    } else {
      return false
    }

  };

  return (
    <Pane className=''>
      {/* AREA DE FILTROS */}
      <Pane className='mt20'>
        <Pane className='flex-center justifyBetween mb20'>
          <div className='flex-center'>
            <Text>Filtrar por:</Text>
            <div className='ml20'>
              <Autocomplete
                onChange={(e) => (handleFilters(e))}
                items={adressList}
                selectedItem={filtros.endereco}
              >
                {props => {
                  const { getInputProps, getRef, inputValue } = props
                  return (
                    <TextInput
                      placeholder="🔍︎ Endereço"
                      value={inputValue || undefined}
                      ref={getRef}
                      {...getInputProps()}
                    />
                  )
                }}
              </Autocomplete>
            </div>
            <div className='ml20'>
              <Select
                name='status'
                width={240}
                background="white"
                onChange={(e) => handleFilters(e)}
                value={filtros.status}
              >
                <option value="" hidden selected >🔍︎ Status</option>
                {statusList.map((cargo) => (
                  <option value={cargo.id} >{cargo.status}</option>
                ))}
              </Select>
            </div>
          </div>
          <div>
            <Pagination
              page={pageNumber}
              totalPages={totalPages}
              onPageChange={(e) => !loading ? listItemsPerPage(e) : ''}
              onNextPage={() => listItemsPerPage(pageNumber + 1)}
              onPreviousPage={() => listItemsPerPage(pageNumber - 1)}
            />
          </div>
        </Pane>
        {quantFiltro[0] > 0 &&
          <div className="filter-info">
            <p>
              <FilterIcon className="icon-filter" />
              {quantFiltro[0]} {quantFiltro[0] === 1 ? 'filtro aplicado. ' : 'filtros aplicados. '}
              {totalProcessFilter} / {totalProcessNumber} {totalProcessFilter === 1 ? 'processo visível' : 'processos visíveis'}.
              <span onClick={(e) => removeFilters()}> Limpar filtro</span>
            </p>
          </div>
        }
      </Pane>
      {/* TABELA DE SERVIÇOS */}
      <Pane>
        <div className='Rectangle35 Blue'></div>
        <Table>
          <Table.Head>
            <Table.TextHeaderCell className="table minW46_18vw" >Endereço</Table.TextHeaderCell>
            <Table.TextHeaderCell className="table minW94">PÓS-VENDA</Table.TextHeaderCell>
            <Table.TextHeaderCell className="table minW94 textCenter">Solicitação</Table.TextHeaderCell>
            <Table.TextHeaderCell className="table minW66_6vw bgF3F6FF textCenter">Previsão</Table.TextHeaderCell>
            <Table.TextHeaderCell className="table minW46_16vw">Serviço</Table.TextHeaderCell>
            <Table.TextHeaderCell className="table minW60_8vw">Status</Table.TextHeaderCell>
            <Table.TextHeaderCell className="table minW70">{/* Tag Novo */}</Table.TextHeaderCell>
            <Table.TextHeaderCell className="table minW60_8vw">{/* Btn Ações */}</Table.TextHeaderCell>
          </Table.Head>

          {loading ? <div className='backgroundLoadingDocs'><Lottie options={defaultOptions} height={200} width={400} /></div> :
            <Table.Body>
              {nucleoTableValue.map((service) => (
                <Table.Row key={service.id}>
                  {/* CAMPO ENDEREÇO */}
                  <Table.TextCell
                    className={"table minW46_18vw content-click " + (service.status_visualizacao_atual === 0 && 'fw700')}
                    onClick={(() => sideSheetOpen(0, service.id))}
                  >                    
                    {service.logradouro}, {service.numero} {service.unidade && ("/ " + service.unidade)} {service.complemento && ("- " + service.complemento)}
                  </Table.TextCell>

                  {/* CAMPO RESPONSAVEL */}
                  <Table.TextCell className={"table minW94 " + (service.status_visualizacao_atual === 0 && 'fw700')} >
                    {service.nome_responsavel}
                  </Table.TextCell>

                  {/* CAMPO DATA SOLICITAÇÃO */}
                  <Table.TextCell className={"table minW94 textCenter " + (service.status_visualizacao_atual === 0 && 'fw700')}>
                    {service.data_solicitacao}
                  </Table.TextCell>

                  {/* CAMPO DATA PREVISÃO */}
                  <Table.TextCell className="table minW66_6vw textCenter bgF3F6FF">{
                    service.data_previsao ? service.data_previsao :
                      <Button onClick={() => sideSheetOpen(0, service.id)}>
                        <AddIcon marginRight={10} />Adicionar
                      </Button>
                  }</Table.TextCell>

                  {/* CAMPO SERVIÇO DETALHADO */}
                  <Table.TextCell className="table minW46_16vw">
                    <Tooltip content={service.nome} isShown={widthPage(service.nome || "")}>
                      <Button className='btnflex474 maxW46_16v' onClick={() => sideSheetOpen(0, service.id)}>
                        <span>{service.nome}</span>
                      </Button>
                    </Tooltip>
                  </Table.TextCell>

                  {/* CAMPO STATUS */}
                  <Table.TextCell className="table minW60_8vw">
                    <Button className='tagBtn' onClick={() => dialogOpen(service)}>
                      <StatusIndicator color={colorStatus(service.status_atual)}>{service.nome_status}</StatusIndicator>
                    </Button>
                  </Table.TextCell>

                  {/* CAMPO TAG NOVO */}
                  <Table.Cell className="table minW70">
                    {service.status_visualizacao_atual === 0 ? <Badge color="green">Novo</Badge> : ""}
                  </Table.Cell>

                  {/* CAMPO BOTÃO DE AÇÕES */}
                  <Table.Cell className="table minW60_8vw">
                    <Button appearance="primary" onClick={() => dialogOpen(service)}>
                      <ShareIcon color="white" marginRight={10} />Compartilhar
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>}
        </Table>
      </Pane>

      <SideSheetView refreshTable={refreshTable} />
      <StatusChange refreshTable={refreshTable} />
      {/* <ConfirmDel refreshTable={refreshTable} /> */}
      <CornerSharePosvenda refreshTable={refreshTable} />
    </Pane>
  )
}