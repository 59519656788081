import React, { useContext, useState } from 'react';
import 'react-phone-input-2/lib/bootstrap.css'
import { Pane, Tab, Tablist, Table, Badge } from 'evergreen-ui';
import ShowDocument from '../../../../../../app/showDocument';
//Context
import DirecaoContext from '../../../../../../context/DirecaoContext';

// MSG QUANDO CAMPO VAZIO
const msgCampo = '----';

function ColTable(props) {
    return (
        <Table.TextCell maxWidth={props.width}>
            {props.title && <div className={`mb7 color101840 `}>{props.title}</div>}

            <span className='fw400 color696F8C'>
                {props.subtitle || msgCampo}
            </span>
        </Table.TextCell>
    )
};

export default function Vendedor({ type }) {
    const {
        loading,
        dadosVenda,
    } = useContext(DirecaoContext);
    const [token] = useState(localStorage.getItem('token'));

    const [selectedIndex, setSelectedIndex] = useState(0);
    const userData = (type === 'Vendedor' ? dadosVenda?.vendedores.data : dadosVenda?.compradores.data) || [];

    return (
        <Pane>
            {
                userData.length === 0
                    ?
                    <Table.Row>
                        <ColTable subtitle={`Nenhum ${type} foi cadastrado no momento.`} />
                    </Table.Row>

                    :
                    <>
                        <div className="form-dialog">
                            <Tablist marginTop={16} flexBasis={240} marginRight={24} className="tab-form">
                                {userData.map((tabCadastro, index) => (
                                    <Tab
                                        key={tabCadastro}
                                        id={"titulo_top_user" + index}
                                        onSelect={() => setSelectedIndex(index)}
                                        isSelected={index === selectedIndex}
                                        aria-controls={`panel-${tabCadastro}`}
                                        data-tab={tabCadastro}
                                    // onClick={() => handleOpenTab(index)}
                                    >

                                        {index < userData.length
                                            ? (userData[index].tipo_pessoa === 1)
                                                ? userData[index].nome_fantasia.slice(0, 10) + '...'
                                                : userData[index].name.slice(0, 10) + '...'
                                            : 'Vendedor ' + tabCadastro}
                                    </Tab>
                                ))}
                            </Tablist>
                        </div>
                        <div className="form-detalhes">
                            <div className="formGroup formAdd">
                                {userData.map((tabCadastro, index) => (
                                    <Pane
                                        key={tabCadastro}
                                        id={`panel-${tabCadastro}`}
                                        role="tabpanel"
                                        aria-labelledby={tabCadastro}
                                        aria-hidden={index !== selectedIndex}
                                        display={index === selectedIndex ? 'block' : 'none'}
                                        className="detadelhes-sidesheet"
                                    >
                                        <Pane>
                                            <Badge color="green" className="badge-tipo-pesso">
                                                <span>
                                                    {userData[index].tipo_pessoa === 1 ? 'Pessoa Jurídica' : 'Pessoa Física'}
                                                </span>
                                            </Badge>

                                            <React.Fragment>
                                                <h2 className="subtitle subtitle-table">
                                                    {userData[index].tipo_pessoa === 1 ? 'Dados da Empresa' : `Dados do ${type}`}
                                                </h2>
                                            </React.Fragment>

                                            <Table.Row>
                                                <ColTable title={userData[index]?.tipo_pessoa === 1 ? 'CNPJ' : 'CPF'} width="180px" subtitle={userData[index]?.cpf_cnpj} />
                                                <ColTable title={"Telefone/Celular"} subtitle={userData[index]?.telefone} />
                                            </Table.Row>

                                            {userData[index].tipo_pessoa === 0
                                                ?
                                                <>
                                                    {/*PF*/}
                                                    <Table.Row>
                                                        <ColTable title={"Nome completo"} width="180px" subtitle={userData[index]?.name} />
                                                        <ColTable title={"Gênero"} subtitle={userData[index]?.genero === 'M' ? 'Masculino' : 'Feminino'} />
                                                    </Table.Row>

                                                    <Table.Row>
                                                        <ColTable title={"Data de nascimento"} width="180px" subtitle={userData[index]?.data_nascimento} />
                                                        <ColTable title={"Nacionalidade"} subtitle={userData[index]?.nacionalidade} />
                                                    </Table.Row>

                                                    <Table.Row>
                                                        <ColTable title={"Nome completo da mãe"} width="180px" subtitle={userData[index]?.nome_mae} />
                                                        <ColTable title={"Nome completo do pai"} subtitle={userData[index]?.nome_pai} />
                                                    </Table.Row>

                                                    <Table.Row>
                                                        <ColTable
                                                            width={userData[index].registro_casamento ? "240px" : '180px'}
                                                            title={"Estado Civil"}
                                                            subtitle={
                                                                <span>
                                                                    {
                                                                        index < userData.length ?
                                                                            userData[index].estado_civil === '1' ? 'Casado' :
                                                                                userData[index].estado_civil === '2' ? 'Solteiro' :
                                                                                    userData[index].estado_civil === '3' ? 'Divorciado' :
                                                                                        userData[index].estado_civil === '4' ? 'Viúvo' : ''
                                                                            : ''
                                                                    }
                                                                    {
                                                                        index < userData.length ?
                                                                            userData[index].registro_casamento === '1' ? ' - Separação total de bens' :
                                                                                userData[index].registro_casamento === '2' ? ' - Separação parcial de bens' :
                                                                                    userData[index].registro_casamento === '3' ? ' - Separação legal de bens' :
                                                                                        userData[index].registro_casamento === '4' ? ' - Comunhão de bens' : ''
                                                                            : ''
                                                                    }
                                                                </span>
                                                            }
                                                        />
                                                        <ColTable
                                                            width="120px"
                                                            subtitle={
                                                                <span>
                                                                    {userData[index]?.uniao_estavel === null ? '' : <div className="titulo" style={{ margin: '5px 0' }}>União Estável</div>}
                                                                    {userData[index]?.uniao_estavel !== null ? 'Sim' : ''}
                                                                </span>
                                                            }
                                                        />
                                                    </Table.Row>

                                                    {userData[index]?.conjuge ?
                                                        <Table.Row>
                                                            <ColTable
                                                                title="Nome completo do Cônjuge/Companheiro(a)"
                                                                subtitle={userData[index]?.conjuge}
                                                            />
                                                        </Table.Row>
                                                        : ""
                                                    }

                                                    <Table.Row>
                                                        <ColTable title={"Profissão"} subtitle={userData[index]?.profissao} />
                                                    </Table.Row>

                                                    <Table.Row>
                                                        <ColTable title={"RG"} width="180px" subtitle={userData[index]?.rg} />
                                                        <ColTable title={"Expedita por"} width="180px" subtitle={userData[index]?.rg_expedido} />
                                                        <ColTable title={"Data expedição"} subtitle={userData[index]?.data_rg_expedido} />
                                                    </Table.Row>

                                                    <Table.Row>
                                                        <ColTable title={"E-mail"} subtitle={userData[index]?.email} />
                                                    </Table.Row>

                                                    <React.Fragment>
                                                        <h2 className="subtitle subtitle-table">Procurador</h2>
                                                    </React.Fragment>

                                                    <Table.Row>
                                                        <ColTable title={"Nome completo"} width="200px" subtitle={userData[index]?.procurador?.nome} />
                                                        <ColTable title={"Telefone/Celular"} subtitle={userData[index]?.procurador?.telefone} />
                                                    </Table.Row>

                                                </>

                                                :
                                                <>
                                                    {/*PJ*/}
                                                    <Table.Row>
                                                        <ColTable title={"Razão Social"} subtitle={userData[index]?.razao_social} />
                                                    </Table.Row>

                                                    <Table.Row>
                                                        <ColTable title={"Nome Fantasia"} subtitle={userData[index]?.nome_fantasia} />
                                                    </Table.Row>
                                                </>
                                            }

                                            <React.Fragment>
                                                <h2 className="subtitle subtitle-table">
                                                    {userData[index].tipo_pessoa === 0 ? 'Endereço' : 'Endereço da empresa'}
                                                </h2>
                                            </React.Fragment>

                                            <Table.Row>
                                                <ColTable title={"CEP"} width="100px" subtitle={userData[index]?.cep} />
                                                <ColTable title={"Bairro"} width="100px" subtitle={userData[index]?.bairro} />
                                                <ColTable title={"Logradouro"} subtitle={userData[index]?.logradouro} />
                                            </Table.Row>

                                            <Table.Row>
                                                <ColTable title={"Número"} width="100px" subtitle={userData[index]?.numero} />
                                                <ColTable title={"Unidade"} width="100px" subtitle={userData[index]?.unidade} />
                                                <ColTable title={"Complemento"} subtitle={userData[index]?.complemento} />
                                            </Table.Row>

                                            <Table.Row>
                                                <ColTable title={"Estado"} width="100px" subtitle={userData[index]?.estado} />
                                                <ColTable title={"Cidade"} subtitle={userData[index]?.cidade} />
                                            </Table.Row>

                                            {userData[index].tipo_pessoa === 1
                                                ?
                                                <>
                                                    <React.Fragment>
                                                        <h2 className="subtitle subtitle-table">
                                                            Sócio e/ou Representantes Legais
                                                        </h2>
                                                    </React.Fragment>
                                                    {userData[index].representante_socios.data.map((representante, index_representante) =>
                                                        <>
                                                            <Table.Row height={40}>
                                                                {representante.pj_representante === 1
                                                                    && <ColTable subtitle={
                                                                        <Badge color="green">
                                                                            <span>
                                                                                REPRESENTANTE LEGAL
                                                                            </span>
                                                                        </Badge>
                                                                    } />
                                                                }
                                                                {representante.pj_socio === 1
                                                                    && <ColTable subtitle={
                                                                        <Badge color="green">
                                                                            <span>
                                                                                SÓCIO
                                                                            </span>
                                                                        </Badge>
                                                                    } />
                                                                }
                                                            </Table.Row>

                                                            <Table.Row>
                                                                <ColTable title={"Nome completo"} width="150px" subtitle={representante.name} />
                                                                <ColTable title={"CPF"} width="150px" subtitle={representante.cpf_cnpj} />
                                                                <ColTable
                                                                    title={"Gênero"}
                                                                    subtitle={
                                                                        representante.genero === 'M' ? 'Masculino' :
                                                                            representante.genero === 'F' ? 'Feminino' : ""
                                                                    } />
                                                            </Table.Row>

                                                            <Table.Row>
                                                                <ColTable title={"E-mail"} subtitle={representante.email} />
                                                            </Table.Row>

                                                            <Table.Row>
                                                                <ColTable title={"RG"} width="150px" subtitle={representante.rg} />
                                                                <ColTable title={"Expedita por"} width="150px" subtitle={representante.rg_expedido} />
                                                                <ColTable title={"Data de expedição"} width="150px" subtitle={representante.data_rg_expedido} />
                                                            </Table.Row>

                                                            <Table.Row>
                                                                <ColTable title={"Profissão"} subtitle={representante.profissao} />
                                                            </Table.Row>

                                                            <Table.Row >
                                                                <ColTable
                                                                    width={representante.remime_casamento ? "240px" : '180px'}
                                                                    title={"Estado Civil"}
                                                                    subtitle={
                                                                        <span>
                                                                            {
                                                                                representante.estado_civil !== null ?
                                                                                    representante.estado_civil === '1' ? 'Casado' :
                                                                                        representante.estado_civil === '2' ? 'Solteiro' :
                                                                                            representante.estado_civil === '3' ? 'Divorciado' :
                                                                                                representante.estado_civil === '4' ? 'Viúvo' : ''
                                                                                    : ''
                                                                            }
                                                                            {
                                                                                representante.remime_casamento !== null ?
                                                                                    representante.remime_casamento === '1' ? ' - Separação total de bens' :
                                                                                        representante.remime_casamento === '2' ? ' - Separação parcial de bens' :
                                                                                            representante.remime_casamento === '3' ? ' - Separação legal de bens' :
                                                                                                representante.remime_casamento === '4' ? ' - Comunhão de bens' : ''
                                                                                    : ''
                                                                            }
                                                                        </span>
                                                                    }
                                                                />
                                                                <ColTable
                                                                    width="120px"
                                                                    subtitle={
                                                                        <span>
                                                                            {!representante.uniao_estavel ? '' : <div className="titulo" style={{ margin: '5px 0' }}>União Estável</div>}
                                                                            {!representante.uniao_estavel ? '' : 'Sim'}
                                                                        </span>
                                                                    }
                                                                />
                                                            </Table.Row>

                                                            {representante.conjuge ?
                                                                <Table.Row>
                                                                    <ColTable
                                                                        title="Nome completo do Cônjuge/Companheiro(a)"
                                                                        subtitle={representante.conjuge}
                                                                    />
                                                                </Table.Row>
                                                                : ""
                                                            }
                                                           
                                                        </>
                                                    )
                                                    }
                                                </>

                                                : ''
                                            }
                                        </Pane>
                                    </Pane>
                                ))}
                            </div>
                        </div>
                    </>
            }
        </Pane>
    )
}