import Axios from 'axios';
import Serve from '../app/serve';

async function updatePagamentoComissao(data) {
  const token = localStorage.getItem('token');
  const app = Serve();
  let result;

  await Axios.post(app + 'salvar_pagamento', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
    .then(res => {
      if (res.data.status && res.data.status === (401 || 498)) {
        localStorage.clear();
      } else {        
        result = res.data;
      }
    })
    .catch(function (error) {
      console.log(error);
      result = false;
    })

  return result;
};

export default updatePagamentoComissao;