import Axios from 'axios';
import Serve from '../app/serve';

async function updateSalvarMultiploUsuarios(data) {
  const token = localStorage.getItem('token');
  const app = Serve();
  let result;

  await Axios.post(app + 'salvar_multiplos_usuarios', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }).then(res => {
    if (!res || (res.data.status && res.data.status === (401 || 498))) {
      result = false
    } else {
      result = res
    }
  }).catch(function (error) {
    console.log("ERROR: ", error);
  })

  return result;
};

export default updateSalvarMultiploUsuarios;