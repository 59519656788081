import Axios from 'axios';
import Serve from '../app/serve';

async function getResponsaveis() {
  const token = localStorage.getItem('token');
  const app = Serve();
  let data;

  await Axios.post(app + 'listar_responsaveis', {
    'token': token
  }, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
  })
    .then(res => {
      if (res !== undefined) {
        if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
          localStorage.clear();
        } else {
          const lista_responsaveis = res.data.data;
          data = lista_responsaveis.map(usuario => ({ "id": usuario.id, "name": usuario.name, 'a_vista': usuario.a_vista, "financiado": usuario.financiado, "ordenacao": usuario.ordenacao }));
        }
      }
    })

  return data;
};

export default getResponsaveis;