import React, { useState, useEffect } from 'react';
import {
    Pane,
} from 'evergreen-ui';

//Context
import PosNegociacaoContext from '../../../context/PosNegociacaoContext';

import '../../../index.css';

//Components
import Axios from 'axios';
import Serve from '../../../app/serve';
import { useHistory } from 'react-router-dom';
import SelecionarForm from '../component/Form/SelecionarForm';
import FormRecibo from '../component/Form/FormRecibo';
import FormEspelhoVenda from '../component/Form/FormEspelhoVenda';
import SendToBackOffice from './SendToBackOffice';
import FeedBack from './SendToBackOffice/FeedBack';
import RecibosAdicionados from './Table/RecibosAdicionados';
import RecibosEnviados from './Table/RecibosEnviados';
//import NumeroExtenso from '../../../components/NumeroExtenso';

export default function TablePanel() {

    const app = Serve();
    const history = useHistory();
    const [token] = useState(localStorage.getItem('token'));

    //Table
    const [loading, setLoading] = useState(false);
    const [itemAdicionado, setItemAdicionado] = useState([]);
    const [itemEnviado, setItemEnviado] = useState([]);
    const [dadosReciboEnviado, setDadosReciboEnviado] = useState([]);
    //const [item, setItems] = useState([]);
    //const [historicoStatus, setHistoricoStatus] = useState([]);

    //Dialog seleção tipo de Venda
    const [isShownTipoVenda, setIsShownTipoVenda] = useState(false);
    const [isShownFormRecibo, setIsShownFormRecibo] = useState(false)
    const [isShowFormEspelhoVenda, setIsShowFormEspelhoVenda] = useState(false)

    //Cadastro ou Edição do Recibo de Sinal
    const [estadoRecibo, setEstadoRecibo] = useState('');

    //Cadastro Espelho de Venda
    const [posicaoEspelhoVenda, setPosicaoEspelhoVenda] = useState(1);
    const [dadosEspelhoImovel, setDadosEspelhoImovel] = useState([]);
    const [dadosEspelhoVendedor, setDadosEspelhoVendedor] = useState([]);
    const [dadosEspelhoComprador, setDadosEspelhoComprador] = useState([]);
    const [dadosEspelhoComissao, setDadosEspelhoComissao] = useState([]);

    //Notificações
    const [isShowCornerDialog, setIsShowCornerDialog] = useState(false);
    const [isShowSendBackOffice, setIsShowSendBackOffice] = useState(false);

    //Validação Form Recibo e Espelho
    const [verificaRgi, setVerificaRgi] = useState(false);
    const [validateFieldRgi, setValidateFieldRgi] = useState(false);
    const [validateFieldCep, setValidateFieldCep] = useState(false);
    const [validateFieldDataAssinatura, setValidateFieldDataAssinatura] = useState(false);
    const [validateFieldFormaPagamento, setValidateFieldFormaPagamento] = useState(false);
    const [validateInputFile, setValidateInputFile] = useState(false);
    const [validateCepMessage, setValidateCepMessage] = useState(true);
    const [validateAdress, setValidateAdress] = useState();
    const [validateFieldCodImovel, setValidateFieldCodImovel] = useState(false);
    const [validateFieldValorVenda, setValidateFieldValorVenda] = useState(false);
    const [validateFieldDataVenda, setValidateFieldDataVenda] = useState(false);

    //Checked Form Recibo
    const [checkedAVista, setCheckedAVista] = useState(false);
    const [checkedFinanciamento, setCheckedFinanciamento] = useState(false);
    const [checkedFgts, setCheckedFgts] = useState(false);
    const [aVista, setAvista] = useState('');
    const [financiamento, setFinanciamento] = useState('');
    const [fgts, setFgts] = useState('');

    //Tipos de Documento
    const [tipoDocumentos, setTipoDocumentos] = useState([]);
    const [tipoDocumentosTipos, setTipoDocumentosTipos] = useState([]);

    //SideSheet
    const [isShownSide, setIsShownSide] = useState(false);
    const [selectedIndexEnviados, setSelectedIndexEnviados] = useState(0)

    //Dados do Recibo
    const [dadosRecibo, setDadosRecibo] = useState([]);

    //FeedBack
    const [isShowFeedBack, setIsShowFeedBack] = useState(false);

    console.log(loading);

    // Context
    const conxtextStatus = {
        loading, setLoading,
        itemAdicionado, setItemAdicionado,
        itemEnviado, setItemEnviado,
        dadosReciboEnviado, setDadosReciboEnviado,
        isShowCornerDialog, setIsShowCornerDialog,
        isShowSendBackOffice, setIsShowSendBackOffice,
        dadosRecibo, setDadosRecibo,
        verificaRgi, setVerificaRgi,
        validateFieldRgi, setValidateFieldRgi,
        validateFieldCep, setValidateFieldCep,
        validateFieldDataAssinatura, setValidateFieldDataAssinatura,
        validateFieldFormaPagamento, setValidateFieldFormaPagamento,
        validateInputFile, setValidateInputFile,
        validateCepMessage, setValidateCepMessage,
        validateAdress, setValidateAdress,
        checkedAVista, setCheckedAVista,
        checkedFinanciamento, setCheckedFinanciamento,
        checkedFgts, setCheckedFgts,
        isShowFeedBack, setIsShowFeedBack,
        estadoRecibo, setEstadoRecibo,
        isShownSide, setIsShownSide,
        selectedIndexEnviados, setSelectedIndexEnviados,
        aVista, setAvista,
        financiamento, setFinanciamento,
        fgts, setFgts,
        validateFieldCodImovel, setValidateFieldCodImovel,
        validateFieldValorVenda, setValidateFieldValorVenda,
        validateFieldDataVenda, setValidateFieldDataVenda,
        isShownTipoVenda, setIsShownTipoVenda,
        isShownFormRecibo, setIsShownFormRecibo,
        isShowFormEspelhoVenda, setIsShowFormEspelhoVenda,
        posicaoEspelhoVenda, setPosicaoEspelhoVenda,
        tipoDocumentos, setTipoDocumentos,
        tipoDocumentosTipos, setTipoDocumentosTipos,
        dadosEspelhoImovel, setDadosEspelhoImovel,
        dadosEspelhoVendedor, setDadosEspelhoVendedor,
        dadosEspelhoComprador, setDadosEspelhoComprador,
        dadosEspelhoComissao, setDadosEspelhoComissao,
    }

    useEffect(() => {
        const listaTipoDocumentos = async () => {

            await Axios.get(app + 'tipodocumentos', {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
                .then(res => {
                    if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                        localStorage.clear();
                        history.push('/');
                    } else {
                        const tipodocumentos = res.data.data;
                        setTipoDocumentos(tipodocumentos);
                        console.log(tipodocumentos);
                    }
                })
        }
        listaTipoDocumentos();
    }, [app, token])

    return (
        <Pane>
            <PosNegociacaoContext.Provider value={conxtextStatus}>
                <SelecionarForm />
                <RecibosAdicionados />
                <RecibosEnviados />
                <FormRecibo />
                <FormEspelhoVenda/>
                <SendToBackOffice />
                <FeedBack/>
            </PosNegociacaoContext.Provider>            
        </Pane>
    )
}