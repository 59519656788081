import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Dialog, Checkbox, Pane, toaster, TextareaField } from 'evergreen-ui';
import Axios from 'axios'
import Serve from '../../../../app/serve';
import NegociacaoContext from '../../../../context/NegociacaoContext';

export default function DialogRevisao({refreshTable}) {
  const app = Serve();
  const [token] = useState(localStorage.getItem('token'));
  const usuario_id = localStorage.getItem('usuario_id');
  const history = useHistory();

  const {
    loading,
    setLoading,
    pageNumber,
    isShownRevisao,
    setIsShownRevisao,
    processoId,
    setIsShownRevisaoFeedBack,
  } = useContext(NegociacaoContext);

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    defaultValues: {
      imovelObs: "",
      vendedorObs: "",
      compradorObs: "",
      comissaoObs: "",
    }
  });

  const msgErrorText = "Informe uma justificativa"
  // console.log("watch: ", watch());
  // console.log("errors: ", errors);

  const inputError = {
    borderColor: '#FF0000',
    background: '#fff'
  }

  const [reciboChecked, setReciboChecked] = useState(false);
  const [imoveisChecked, setImoveisChecked] = useState(false);
  const [vendedoresChecked, setVendedoresChecked] = useState(false);
  const [comissãoChecked, setComissãoChecked] = useState(false);
  const [compradoresChecked, setCompradoresChecked] = useState(false);

  const handleConfirm = async (e, close) => {
    if (
      !reciboChecked &&
      !imoveisChecked &&
      !vendedoresChecked &&
      !compradoresChecked &&
      !comissãoChecked
    ) toaster.danger("Selecione quais campos precisam de revisão ou correção");
    else {
      setLoading(true);
      const dados = {
        user_id: usuario_id,

        recibo_sinal: reciboChecked,
        recibo_sinal_observacao: e.reciboObs,

        valores_documentos_imovel: imoveisChecked,
        valores_documentos_imovel_observacao: e.imovelObs,

        vendedores: vendedoresChecked,
        vendedores_observacao: e.vendedorObs,

        compradores: compradoresChecked,
        compradores_observacao: e.compradorObs,

        comissao: comissãoChecked,
        comissao_observacao: e.comissaoObs,

        processo_id: processoId
      };

      await Axios.post(app + 'salvar_devolucao', dados, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then(res => {
        // console.log("Res: ", res);
        if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
          localStorage.clear();
          history.push('/');
        }
        refreshTable(pageNumber);
        setIsShownRevisaoFeedBack(true);
        close();
      }).catch(err => {
        console.log(err);
      });
      setLoading(false);
    }
  };

  return (
    <Dialog
      isShown={isShownRevisao}
      title={<h3 className='revisao'>Deseja devolver a venda e solicitar correções para o gerente?</h3>}
      onCloseComplete={() => setIsShownRevisao(false)}
      confirmLabel="Enviar pedido de correção"
      cancelLabel="Voltar"
      width={592}
      isConfirmLoading={loading}
      onConfirm={handleSubmit((e, close) => handleConfirm(e, close))}
    >
      <div className="dialog-content dialog-content-info">
        <p className='dialogSubTitle'>
          Informe os campos que precisam de revisão ou correção.<br />
          A justificativa é importante para agilizar o trabalho dos envolvidos.
        </p>
      </div>
      <div className="dialog-content-rua">Selecione os tópicos que precisam de correção:</div>
      <div className="form-dialog">

        {/* CAMPO RECIBO DE SINAL */}
        <Pane marginBottom={25}>
          <Checkbox
            className='checkbox'
            label="Recibo de Sinal e/ou data de assinatura"
            checked={reciboChecked}
            onChange={e => setReciboChecked(e.target.checked)}
            disabled={loading}
          />
          <TextareaField
            name='imovel'
            disabled={!reciboChecked}
            placeholder='Exemplo: O arquivo final do recibo de sinal assinado, possui alguns erros.'
            {...register("reciboObs", {
              required: reciboChecked,
            })}
            validationMessage={reciboChecked && errors.reciboObs && msgErrorText}
            style={reciboChecked ? errors.reciboObs && inputError : {}}
          />
        </Pane>

        {/* CAMPO IMÓVEL */}
        <Pane marginBottom={25}>
          <Checkbox
            className='checkbox'
            label="Valores e/ou Documentos do Imóvel"
            checked={imoveisChecked}
            onChange={e => setImoveisChecked(e.target.checked)}
            disabled={loading}
          />
          <TextareaField
            name='imovel'
            disabled={!imoveisChecked}
            placeholder='Exemplo: Os documentos do imóvel estão errados e não condizem com o imóvel vendido.'
            {...register("imovelObs", {
              required: imoveisChecked,
            })}
            validationMessage={imoveisChecked && errors.imovelObs && msgErrorText}
            style={imoveisChecked ? errors.imovelObs && inputError : {}}
          />
        </Pane>

        {/* COMPO VENDEDORES */}
        <Pane marginBottom={25}>
          <Checkbox
            className='checkbox'
            label="Vendedores"
            checked={vendedoresChecked}
            onChange={e => setVendedoresChecked(e.target.checked)}
            disabled={loading}
          />
          <TextareaField
            name='vendedores'
            disabled={!vendedoresChecked}
            placeholder='Exemplo: As informações do vendedor não condizem com os documentos anexados.'
            {...register("vendedorObs", {
              required: vendedoresChecked,
            })}
            validationMessage={vendedoresChecked && errors.vendedorObs && msgErrorText}
            style={vendedoresChecked ? errors.vendedorObs && inputError : {}}
          />
        </Pane>

        {/* CAMPO COMPRADORES  */}
        <Pane marginBottom={25}>
          <Checkbox
            className='checkbox'
            label="Compradores"
            checked={compradoresChecked}
            onChange={e => setCompradoresChecked(e.target.checked)}
            disabled={loading}
          />
          <TextareaField
            name='compradores'
            disabled={!compradoresChecked}
            placeholder='Exemplo: A identidade do comprador não condiz com os dados apresentados.'
            {...register("compradorObs", {
              required: compradoresChecked,
            })}
            validationMessage={compradoresChecked && errors.compradorObs && msgErrorText}
            style={compradoresChecked ? errors.compradorObs && inputError : {}}
          />
        </Pane>

        {/* CAMPO COMISSÃO */}
        <Pane marginBottom={25}>
          <Checkbox
            className='checkbox'
            label="Comissão"
            checked={comissãoChecked}
            onChange={e => setComissãoChecked(e.target.checked)}
            disabled={loading}
          />
          <TextareaField
            name='comissão'
            disabled={!comissãoChecked}
            placeholder='Exemplo: A primeira parte do pagamento do imóvel será à vista. O restante financiado.'
            {...register("comissaoObs", {
              required: comissãoChecked,
            })}
            validationMessage={comissãoChecked && errors.comissaoObs && msgErrorText}
            style={comissãoChecked ? errors.comissaoObs && inputError : {}}
          />
        </Pane>
      </div>

    </Dialog>
  )
}