import React, {useState, useContext, useEffect} from 'react';
import Image from '../../../../../../../../../images/undraw_feedback_re_urmj.svg';
import {Pane, Radio, Textarea, Button, ArrowRightIcon, TextInputField, FilePicker, toaster} from 'evergreen-ui';
import VendasContext from '../../../../../../../../../context/VendasContext';
import getGerenteConfirmarEscritura from '../../../../../../../../../apis/getGerenteConfirmarEscritura';
import { useHistory } from 'react-router-dom';
//import getGerentePerguntasEscritura from '../../../../../../../../../apis/getGerentePerguntasEscritura';
import Axios from 'axios';
import Serve from '../../../../../../../../../app/serve';
import dateMask from '../../../../../../../../../components/dateMask';
import horaMask from '../../../../../../../../../components/horaMask';
import somenteNumero from '../../../../../../../../../components/somenteNumero'
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import { useForm } from 'react-hook-form';
import getProcessosGerente from '../../../../../../../../../apis/getProcessosGerente';

export default function Processo() {

    const {
        modalEscritura, setModalEscritura,
        showListaEscritura, setShowListaEscritura,
        listaEscritura, setListaEscritura,
        selectProcessoEscritura, setSelectProcessoEscritura,
        setIsShowFeedbackEscritura,
        perguntasEscritura, setPerguntasEscritura,

        setItemAdicionado,
        setTotalItensAdicionados,
        setTotalPaginasAdicionados,
        setItemEnviado,
        setTotalItensEntregues,
        setTotalPaginasEntregues,
        setTotalProcessosFiltradosEnviados,
        setItemsArquivados,
        setLoading,
        filtro,
    } = useContext(VendasContext)

    // useEffect(() => {
    //     const exibirProcesso = async () => {

    //         Processo
    //         if(listaEscritura.length > 0){
    //             console.log('Lista não esta vazia');
    //             setSelectProcessoEscritura(listaEscritura[0])
    //         } else {
    //             console.log('Lista esta vazia');
    //         }

    //         console.log('Select Processo Escritura' , selectProcessoEscritura);
    //     }
    //     exibirProcesso();
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    console.log('selectProcessoEscritura: ' , selectProcessoEscritura);
    console.log('Processo id: ' + selectProcessoEscritura?.processo_id)
    console.log('listaEscritura: ' , listaEscritura);

    const { register, setValue, watch, formState: { errors } } = useForm({});
    //console.log('Watch: ' , watch());
    //console.log('Error: ', errors);

    const token = localStorage.getItem('token');
    const app = Serve();
    const history = useHistory();
    const processo_id = selectProcessoEscritura?.processo_id;

    const usuario = localStorage.getItem('nome_usuario');
    const usuario_id = localStorage.getItem('usuario_id');
    const qtdEscrituras = listaEscritura.length;
    const [radio, setRadio] = useState('');
    const [radioSelect, setRadioSelect] = useState();
    const [pergunta, setPergunta] = useState('');
    //const [motivo, setMotivo] = useState('');
    const [loadingBtn, setLoadingBtn] = useState(false);
    const pagamentoEscritura = selectProcessoEscritura?.pg_na_escritura === 0 ? false : true; // true - no momento da escritura / false - outro momento

    // Radio Pagamento
    const [radioPagamento, setRadioPagamento] = useState('');
    //const [novaData, setNovaData] = useState('');
    const [file, setFile] = useState('');
    const [progress, setProgress] = useState(0);
    const [statusUpload, setStatusUpload] = useState('');

    // Radio Agendamento
    const [radioAgendamento, setRadioAgendamento] = useState('');

    const [feedBack, setFeedBack] = useState(
        [
            {
                processo_id: processo_id,
                usuario_id: usuario_id,
                resposta_id: '',
                pergunta_id: '',
            }
        ]
    )

    const inputGreen = {
        borderColor: '#429777',
        background: '#F5FBF8'
    };

    const inputError = {
        borderColor: '#FF0000',
        background: '#fff'
    };

    const inputDefault = {
        borderColor: '#D8DAE5',
        background: '#fff'
    };

    const borderSelect = {
        borderColor: '#36F',
    }

    const borderDefault = {
        borderColor: '#D8DAE5',
    }

    useEffect(() => {
        const exibirProcesso = async () => {

            // Processo
            if(selectProcessoEscritura?.length === 0){
                setSelectProcessoEscritura(listaEscritura[0])
            }
        }
        exibirProcesso();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    ////////////////////////
    //SIM ou NÂO
    ////////////////////////
    const handleChecked = (e, index_pergunta, index_resposta, id, pergunta_id) => {
        console.log('index pergunta: ' + index_pergunta);
        console.log('index resposta: ' + index_resposta);
        console.log('id: ' + pergunta_id);
        console.log('resposta_id: ' + id);
        
        setRadioSelect(index_resposta);
        setRadio(id);
        setPergunta(pergunta_id); 

        console.log(id);

        if(index_pergunta === 0){
            feedBack[index_pergunta].processo_id = selectProcessoEscritura?.processo_id;
            feedBack[index_pergunta].resposta_id = id;
            feedBack[index_pergunta].pergunta_id = pergunta_id;
            feedBack[index_pergunta].motivo = '';
        }

        feedBack.splice(1)
        
        setRadioPagamento('');
        setRadioAgendamento('');
        setStatusUpload('')
        setValue('motivo', '');
        setValue('novaData', '');
        console.log('FeedBack', feedBack);
    }

    ////////////////////////
    //SIM
    ////////////////////////
    const handleRadioPagamento = (e, index_pergunta, index_resposta, id, pergunta_id) => {
        setRadioPagamento(id);

        // if(!feedBack[index_pergunta]){
        //     feedBack.push({
        //         resposta_id: id,
        //         pergunta_id: pergunta_id,
        //         motivo: '',
        //         nova_data: '',
        //         file: '',
        //     })
        // }
        // else{
        //     feedBack[index_pergunta].file = '';
        //     feedBack[index_pergunta].resposta_id = id;
        //     feedBack[index_pergunta].nova_data = '';
        //     feedBack[index_pergunta].motivo = '';
        //     feedBack[index_pergunta].resposta_id = id;
    
        //     setFile('');
        //     setProgress(0);
        //     setValue('novaData.' + index_pergunta, '');
        //     setValue('motivo.' + index_pergunta, '');
        // }

        feedBack.splice(1)

        feedBack.push({
            resposta_id: id,
            pergunta_id: pergunta_id,
            motivo: '',
            nova_data: '',
            file: '',
        })

        setFile('');
        setProgress(0);
        setValue('novaData', '');
        setValue('motivo', '');
        setStatusUpload('')
        console.log('FeedBack', feedBack);
    }

    const handleFile = (files, index) => {
        //console.log('Files: ' , files);

        //Zera a barra de upload
        setStatusUpload('')
        setProgress(0);

        setFile(files[0].name);
        console.log(files);

        console.log('File index: ' + index)
        feedBack[index].file = files[0].name;
        console.log('FeedBack', feedBack);

        const data = new FormData();
        data.append('arquivo', files[0]);
        data.append('processo_id', selectProcessoEscritura?.processo_id);
        data.append('tipo_documento_id', 63);

        Axios.post(app + 'salvar_comprovante_pagamento_comissao', data,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: (progressEvent) => {
                const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                setProgress(percentage);
              },
        }).then(res => {
            if (res.data.status && res.data.status === (401 || 498)) {
                localStorage.clear();
                history.push('/');
            } else {
                /* let mensagem = res.data.mensagem_status_processo;
                arrayMensagemServidor.push(mensagem);
                if (arrayMensagemServidor.length === quantVendedores) {
                    recarregarVenda(dadosVenda.processo_id);
                    alteracaoRevisao(false);
                } */
                console.log("ab");
                console.log(res.data);
                const retornoUpload = res.data;
                setStatusUpload(retornoUpload.status);
                setLoadingBtn(false); 
            }
        }).catch(function (error) {
            // handle error
            if (error.response) {
                setProgress(99);
                setStatusUpload(false);
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                let resposta = error.response.data;
                let erros = '';
                if (resposta.errors) {
                    for (let [value] of Object.entries(resposta.errors)) {
                        erros += value + "\n";
                    }
                }
                if (resposta.error) {
                    erros = resposta.error;
                }
                if (resposta.message) {
                    erros = resposta.message;
                }
                console.log(erros);
                console.log(resposta);
                //setLoading(true);
            } else if (error.request) {
                setProgress(99);
                setStatusUpload(false);
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
            } else {
                setProgress(99);
                setStatusUpload(false);
                // Something happened in setting up the request that triggered an Error
            }
        })
    }

    ////////////////////////
    // NÂO
    ////////////////////////
    const handleRadioAgendamento = (e, index_pergunta, index_resposta, id, pergunta_id) => {
        console.log('Index Pergunta: ' + index_pergunta);
        console.log('Index Resposta: ' + index_resposta);
        console.log('Resposta: ' + id);
        console.log('Pergunta: ' + pergunta_id);

        setRadioAgendamento(id);
        console.log(radioAgendamento)

        // if(!feedBack[index_pergunta]){
        // //if(!feedBack[1]){
        //     feedBack.push({
        //         resposta_id: id,
        //         pergunta_id: pergunta_id,
        //         motivo: '',
        //         nova_data: '',
        //         horario: '',
        //         local: ''
        //     })
        // }
        // else{
        //     feedBack[index_pergunta].motivo = '';
        //     feedBack[index_pergunta].resposta_id = id;
        //     feedBack[index_pergunta].nova_data = '';
        //     feedBack[index_pergunta].horario = '';
        //     feedBack[index_pergunta].local = '';
        //     feedBack[index_pergunta].resposta_id = id;
        // }

        feedBack.splice(1)

        if(!feedBack[1]){
            feedBack.push({
                resposta_id: id,
                pergunta_id: pergunta_id,
                motivo: '',
                nova_data: '',
                horario: '',
                endereco_escritura: '',
                numero_escritura: '',
                unidade_escritura: '',
                complemento_escritura: ''
            })
        }

        setValue('novaData', '');
        setValue('horario', '');
        setValue('local', '');
        setValue('motivo', '');
        setValue('numero', '');
        setValue('unidade', '');
        setValue('complemento', '');

        setStatusUpload('')
        console.log('FeedBack', feedBack);
    }

    //const handleFieldAgendamento = (e, index, type) => {
    const handleField = (e, index, type) => {
        console.log('Valor index: ' + index);
        let valor = e.target.value;
        //const i = 1;
        //console.log(valor);

        switch (type) {
            case 'novaData':
                const dataValor = dateMask(valor)
                valor = dataValor;
                feedBack[index].nova_data = valor;
                setValue('novaData', valor);
                break;

            case 'motivo':
                feedBack[index].motivo = valor;
                setValue('motivo', valor);
                break;

            case 'horario':
                const dataHora = horaMask(valor)
                valor = dataHora;
                feedBack[index].horario = valor;
                setValue('horario', valor);
                break;

            case 'local':
                feedBack[index].endereco_escritura = valor;
                setValue('local', valor);
                break;

            case 'numero':
                const aceitaNumero = somenteNumero(valor);
                feedBack[index].numero_escritura = aceitaNumero;
                setValue('numero', aceitaNumero);
                break;

            case 'unidade':
                feedBack[index].unidade_escritura = valor;
                setValue('unidade', valor);
                break;

            case 'complemento':
                feedBack[index].complemento_escritura = valor;
                setValue('complemento', valor);
                break;
        
            default:
                break;
        }
        console.log(valor);
        console.log('FeedBack', feedBack);
    }

    const recarregarVendas = async (type) => {
        setLoading(false);

        const adicionados = await getProcessosGerente(filtro, 1, 'adicionados');
        if (adicionados.processos) {
            console.log("Processos adicionados: ", adicionados);
            setItemAdicionado(adicionados.processos.data);
            setTotalItensAdicionados(adicionados.total_processos);
            setTotalPaginasAdicionados(adicionados.total_pagina);
        } else {
            toaster.danger("Erro ao recarregar os processos, relogue e tente novamente.")
        }

        if (!type) {
            const enviados = await getProcessosGerente(filtro, 1, 'entregues');
            if (enviados.processos) {
                console.log("Processos enviados: ", enviados);
                setItemEnviado(enviados.processos.data);
                setTotalItensEntregues(enviados.total_processos);
                setTotalPaginasEntregues(enviados.total_pagina);
                setTotalProcessosFiltradosEnviados(enviados.total_processos_filtrados);
            } else {
                toaster.danger("Erro ao recarregar os processos, relogue e tente novamente.")
            }
        }

        if (type) {
            const arquivados = await getProcessosGerente(filtro, 1, 'arquivados');
            if (arquivados.processos) {
                setItemsArquivados(arquivados.processos.data);
                console.log("Processos arquivados: ", arquivados);
            } else {
                toaster.danger("Erro ao recarregar os processos, relogue e tente novamente.")
            }
        }

        setLoading(true);
    };

    ////////////////////////
    // SALVAR
    ////////////////////////
    const handleEnviar = async (e) => {
        e.preventDefault();

        setLoadingBtn(true);

        console.log('Salvar FeedBack: ' , feedBack);

        await Axios.post(app + 'salvar_respostas_gerente', feedBack,
        {
            headers: {
              Authorization: `Bearer ${token}`,
          }
        })
        .then(res => {
            console.log(res);
        }).catch(error => {
            console.log('Erro')
        });

        console.log('Quantidade escrituras: ' + qtdEscrituras);

        //Recarrega a API
        const escrituras = await getGerenteConfirmarEscritura();
        console.log(escrituras);
        if(qtdEscrituras > 1){
            console.log('Primeiro')
            setLoadingBtn(false);
            setShowListaEscritura(true);
            setListaEscritura(escrituras);
        }
        else{
            setModalEscritura(false);
            setIsShowFeedbackEscritura(true);
            recarregarVendas();
        }
    }

    return (
        <Pane aria-label="Radio Group Label 16" role="group">
            <img src={Image} alt="undraw_feedback_re_urmj" />

            <p className="dialogSubTitle" style={{fontWeight: '600', marginBottom: '12px'}}>{usuario}, {perguntasEscritura[0]?.pergunta}</p>

            <div className="row">
                
                {
                    perguntasEscritura.map((pergunta, index_pergunta) =>
                        perguntasEscritura[0].respostas.data.map((resposta, index_resposta) => 
                            <div className="coll" style={radioSelect === index_resposta ? borderSelect : borderDefault}>
                                {
                                    <>
                                        <Radio className="radio" size={16} name="group2" onChange={(e) => handleChecked(e, index_pergunta, index_resposta, resposta.id, resposta.pergunta_id)} label={resposta.resposta} />

                                        {
                                            resposta.id === 1
                                            ?
                                            <>
                                                {/*SIM*/}
                                                {
                                                    radio === 1 && pagamentoEscritura === true 
                                                    ?
                                                        <p className="dialogSubTitle" style={{marginTop: '20px', marginBottom: '5px'}}>                                        
                                                            O pagamento da comissão foi realizado no dia da Escritura?
                                                        </p>
                                                    :
                                                        pagamentoEscritura === false &&
                                                        <p className="dialogSubTitle" style={{marginTop: '20px', marginBottom: '5px'}}>                                        
                                                            Podemos prosseguir para a fase de Registro.
                                                        </p>
                                                }

                                                {/*Próxima pergunta*/}
                                                {
                                                    radio === 1 &&
                                                    <div className='sub-questions teste'>
                                                        {
                                                            resposta.proxima_pergunta.respostas.data.map((respostas_pp, index_respostas_pp) => 
                                                                <div style={{marginRight: '20px'}}>
                                                                    {
                                                                        pagamentoEscritura === true && radio === 1 
                                                                        ?
                                                                            <>
                                                                                {/*SIM*/}
                                                                                <div>
                                                                                    <Radio className="radio sub-radio" size={16} name="group3" onChange={(e) => handleRadioPagamento(e, index_resposta, index_respostas_pp, respostas_pp.id, respostas_pp.pergunta_id) /*handleRadioPagamento(e, index2, index_resposta2, resposta2.id, resposta2.pergunta_id)*/} label={respostas_pp.resposta} />
                                                                                </div>
                                                                            </>
                                                                        :
                                                                        ''
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                }
                                                

                                                <div style={{marginLeft: '25px', marginTop: '10px'}}>
                                                    {
                                                        radioPagamento === 3
                                                        ?
                                                        <>
                                                            <div>
                                                                {/*Nova Data e Motivo*/}
                                                                <TextInputField
                                                                    label="Nova data*"
                                                                    width={120}
                                                                    maxLength={10}
                                                                    placeholder="dd/mm/aaaa"
                                                                    //defaultValue={feedBack[resposta.id].nova_data}
                                                                    {...register('novaData', {
                                                                        onChange: e => handleField(e, resposta.id, 'novaData'),
                                                                        maxLength: 10
                                                                    })}
                                                                    //onChange={(e) => handleField(e, resposta.id, 'novaData')}
                                                                    style={watch('novaData').length === 10 ? inputGreen : inputDefault}
                                                                />
                                                            </div>

                                                            <div>
                                                                <p className="dialogSubTitle" style={{paddingLeft: '0', fontSize: '14px'}}>
                                                                    Por que o pagamento da comissão não foi realizado no dia da Escritura?*
                                                                </p>
                                                                <Textarea 
                                                                    className="textarea" 
                                                                    placeholder="Exemplo: Houve desistência entre uma das partes.*" 
                                                                    {...register('motivo', {
                                                                        onChange: e => handleField(e, resposta.id, 'motivo')
                                                                    })}
                                                                    style={watch('motivo') ? inputGreen : inputDefault}
                                                                />
                                                            </div>
                                                        </>

                                                        : 
                                                        radioPagamento === 4 && 
                                                        <>
                                                            {/*Upload do recibo*/}
                                                            <div>
                                                                <p className="dialogSubTitle" style={{paddingLeft: '0', marginBottom: '10px', fontSize: '14px'}}>
                                                                    Insira o comprovante de pagamento* 
                                                                </p>
                                                                <FilePicker 
                                                                    multiple width={250} 
                                                                    //onChange={(files) => console.log(files)} 
                                                                    onChange={(files) => handleFile(files, index_resposta)}
                                                                    placeholder={file || "nome.arquivo"} 
                                                                    className={
                                                                        file !== '' || statusUpload === true 
                                                                            ? 'inputGreenSelect' 
                                                                            : statusUpload === false 
                                                                                ? 'inputError' : ''
                                                                    }
                                                                />
                                                            </div>
                                                            
                                                            <Progress 
                                                                percent={statusUpload === true || statusUpload === '' ? progress : 99} 
                                                                status={statusUpload === true ? "success" : statusUpload === false ? 'error' : ''} 
                                                                theme={{
                                                                    default: {
                                                                    color: '#3366FF'
                                                                    }
                                                                }}
                                                            />

                                                            { statusUpload === false  && <p className="uploadError">Ops, deu algo errado no upload. Tente novamente.</p> }
                                                        </>
                                                    }
                                                </div>
                                            </>

                                            :
                                            <>
                                                {/*NÃO*/}               
                                                {
                                                    resposta.id === 2 
                                                    ?
                                                    <>
                                                        <p className="dialogSubTitle">
                                                            {
                                                                radio === 1 || radio === ''
                                                                    ? 'A data da Escritura foi alterada ou a venda caiu.'
                                                                    : 'Por qual motivo?'
                                                            }
                                                        </p>

                                                        {
                                                            radio === 2 &&
                                                            <div className='sub-questions'>
                                                                {
                                                                    resposta.proxima_pergunta.respostas.data.map((respostas_pp, index_respostas_pp) => 
                                                                        <div style={{marginRight: '20px'}}>
                                                                            {
                                                                                radio === 2
                                                                                &&
                                                                                    <>
                                                                                        {/*SIM*/}
                                                                                        <div>
                                                                                            <Radio className="radio sub-radio" size={16} name="group3" onChange={(e) => handleRadioAgendamento(e, index_resposta, index_respostas_pp, respostas_pp.id, respostas_pp.pergunta_id)} label={respostas_pp.resposta} />
                                                                                        </div>
                                                                                    </>
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        }
                                                        

                                                        <div style={{marginLeft: '25px', marginTop: '10px'}}>
                                                        {
                                                            radioAgendamento === 5 ?
                                                                <>
                                                                    {/*Motivo da venda cair*/}
                                                                    <p className="dialogSubTitle" style={{paddingLeft: '0', fontSize: '14px'}}>
                                                                        Por que a venda caiu?*
                                                                    </p>
                                                                    <Textarea 
                                                                        className="textarea" 
                                                                        name="textarea-1" 
                                                                        placeholder="Exemplo: Houve desistência entre uma das partes." 
                                                                        {...register('motivo', {
                                                                            onChange: e => handleField(e, index_resposta, 'motivo')
                                                                        })}
                                                                        style={watch('motivo') ? inputGreen : inputDefault}
                                                                    /> 
                                                                </>

                                                                :
                                                                    radioAgendamento === 6 
                                                                    &&
                                                                    <>
                                                                        <div className="sub-questions" style={{marginLeft: '0'}}>
                                                                            {/*Reagendar Escritura*/}
                                                                            <div className="fields">
                                                                                <TextInputField
                                                                                    label="Data*"
                                                                                    width={120}
                                                                                    maxLength={10}
                                                                                    placeholder="dd/mm/aaaa"
                                                                                    //defaultValue={feedBack[resposta.id].nova_data}
                                                                                    {...register('novaData', {
                                                                                        onChange: e => handleField(e, index_resposta, 'novaData'),
                                                                                        maxLength: 10
                                                                                    })}
                                                                                    //onChange={(e) => handleField(e, resposta.id, 'novaData')}
                                                                                    style={watch('novaData')?.length === 10 ? inputGreen : inputDefault}
                                                                                />
                                                                            </div>

                                                                            <div className="fields">
                                                                                <TextInputField
                                                                                    label="Hora*"
                                                                                    width={120}
                                                                                    maxLength={5}
                                                                                    placeholder="16:30"
                                                                                    //defaultValue={feedBack[index_resposta]?.horario}
                                                                                    {...register('horario', {
                                                                                        onChange: e => handleField(e, index_resposta, 'horario'),
                                                                                        maxLength: 5
                                                                                    })}
                                                                                    style={watch('horario').length === 5 ? inputGreen : inputDefault}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="sub-questions" style={{marginLeft: '0'}}>
                                                                            <div className="fields">
                                                                                <TextInputField
                                                                                    label="Endereço*"
                                                                                    width={300}
                                                                                    placeholder="Ex: Rua Voluntários da Pátria"
                                                                                    className={"local"}
                                                                                    {...register('local', {
                                                                                        onChange: e => handleField(e, index_resposta, 'local'),
                                                                                    })}
                                                                                    style={watch('local') ? inputGreen : inputDefault}
                                                                                />
                                                                            </div>

                                                                            <div className="fields">
                                                                                <TextInputField
                                                                                    label="Número*"
                                                                                    width={150}
                                                                                    placeholder="Ex: 1139"
                                                                                    {...register('numero', {
                                                                                        onChange: e => handleField(e, index_resposta, 'numero'),
                                                                                    })}
                                                                                    style={watch('numero') ? inputGreen : inputDefault}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="sub-questions" style={{marginLeft: '0'}}>
                                                                            <div className="fields">
                                                                                <TextInputField
                                                                                    label="Unidade"
                                                                                    width={150}
                                                                                    placeholder="Ex: 1001"
                                                                                    {...register('unidade', {
                                                                                        onChange: e => handleField(e, index_resposta, 'unidade'),
                                                                                    })}
                                                                                    style={watch('unidade') ? inputGreen : inputDefault}
                                                                                />
                                                                            </div>

                                                                            <div className="fields">
                                                                                <TextInputField
                                                                                    label="Complemento"
                                                                                    placeholder="Ex: Bloco A"
                                                                                    width={150}
                                                                                    {...register('complemento', {
                                                                                        onChange: e => handleField(e, index_resposta, 'complemento'),
                                                                                    })}
                                                                                    style={watch('complemento') ? inputGreen : inputDefault}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                            }
                                                        </div>
                                                    </>

                                                    :
                                                    ''
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        )
                    )
                }
            </div>

            <div className='dialog-footer'>
                <div className="p20">
                    <Button 
                        className="btn-minimal" 
                        appearance="minimal" 
                        onClick={(e) => setShowListaEscritura(true)}
                        disabled={qtdEscrituras > 1 ? false : true}
                    >
                        Voltar
                    </Button>

                    <Button 
                        isLoading={loadingBtn}
                        appearance="primary" 
                        disabled={
                            loadingBtn === true
                                ? false 
                                // Sim - Resposta com data e motivo
                                : (radio === 1 && radioPagamento === 3) && (feedBack[1].nova_data.length === 10 && feedBack[1].motivo !== '') 
                                    ? false
                                    // Sim - Resposta com arquivo
                                    : (radio === 1 && radioPagamento === 4) && statusUpload === true
                                        ? false
                                        // Não - Resposta com motivo
                                        : (radio === 2 && radioAgendamento === 5) && feedBack[1].motivo !== '' 
                                            ? false
                                            // Não - Resposta com dia, hora e local
                                            : (radio === 2 && radioAgendamento === 6) && (feedBack[1].nova_data.length === 10 && feedBack[1].horario.length === 5) && (feedBack[1].endereco_escritura !== '' && feedBack[1].numero_escritura !== '')
                                                ? false
                                                // Sim - Quando não tem pagamento de comissão
                                                : pagamentoEscritura === false && radio === 1
                                                    ? false
                                                    : true
                        }
                        intent={'success'}
                        onClick={(e) => handleEnviar(e)}
                    >
                        Enviar <ArrowRightIcon size={12} color={'#fff'} marginLeft={5} />
                    </Button>
                </div>
            </div>
        </Pane>
    )
}
