import React, { useContext, useState, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'
import { Pane, TextInputField, Button, SelectField, Checkbox, DeleteIcon, ClipboardIcon, Label, Radio, Switch, PlusIcon, ErrorIcon, Badge } from 'evergreen-ui';

//Context
import VendasContext from '../../../../../../../context/VendasContext';

//Components
import Axios from 'axios';
import Serve from '../../../../../../../app/serve';
import { useHistory } from 'react-router-dom';
import cepMask from '../../../../../../../components/cepMask';
import dateMask from '../../../../../../../components/dateMask';
import cpfMask from '../../../../../../../components/cpfMask';
import cnpjMask from '../../../../../../../components/cnpjMask';
import validarCPF from '../../../../../../../components/validarCPF';
import validarCNPJ from '../../../../../../../components/validarCNPJ';

export default function PessoaJuridica({ type, register, setValue, unregister, watch, clearErrors, errors, userData, index }) {
  const app = Serve();
  const history = useHistory();
  const [token] = useState(localStorage.getItem('token'));

  const {
    origemComprador,
    dadosVenda,
    perfilUsuario, setPerfilUsuario
  } = useContext(VendasContext);

  console.log('userData Pessoa Jurídica: ', userData);
  console.log("watch Pessoa Jurídica: ", watch());
  console.log("Errors Pessoa Jurídica:", errors);

  useEffect(() => {
    console.log(watch(index + ".representante_socios.data"));
    if (watch(index + ".tipo_pessoa") && watch(index + ".representante_socios.data").length <= 1) {
      unregister(index + ".estado_civil")
      setValue(index + ".representante_socios.data.0.pj_representante", '1');
      setValue(index + ".representante_socios.data.0.pj_socio", '1');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch(index + ".tipo_pessoa")])

  const msgObrigatorio = "Campo obrigatório";

  const inputDisabled = {
    borderColor: '#d8dae5',
    background: '#F4F5F9',
    color: '#696f8c'
  };

  const inputGreen = {
    borderColor: '#429777',
    background: '#F5FBF8'
  };

  const inputDefault = {
    borderColor: '#ADC2FF',
    background: '#fff'
  };

  const inputError = {
    borderColor: '#FF0000',
    background: '#fff'
  };

  const patchRepresentantes = (index, indexRepresentantes, variavel) => {
    return index + '.representante_socios.data.' + indexRepresentantes + '.' + variavel;
  };

  const [disableCamposEndereco, setDisableCamposEndereco] = useState({
    bairro: true,
    logradouro: true,
    cidade: true,
    estado: true,
  });

  async function handleCep(cep, index) {
    if (cep.replace(/\D/g, '').length === 8) {
      await Axios.get("https://viacep.com.br/ws/" + cep + "/json/")
        .then(res => {
          if (!res.data.erro) {
            setValue(index + '.bairro', res.data?.bairro);
            disableCamposEndereco.bairro = res.data?.bairro ? true : false;

            setValue(index + '.logradouro', res.data?.logradouro);
            disableCamposEndereco.logradouro = res.data?.logradouro ? true : false;

            setValue(index + '.cidade', res.data?.localidade);
            disableCamposEndereco.cidade = res.data?.localidade ? true : false;

            setValue(index + '.estado', res.data?.uf);
            disableCamposEndereco.estado = res.data?.uf ? true : false;
          } else {
            setValue(index + '.bairro', "")
            disableCamposEndereco.bairro = false;
            setValue(index + '.logradouro', "")
            disableCamposEndereco.logradouro = false;
            setValue(index + '.cidade', "")
            disableCamposEndereco.cidade = false;
            setValue(index + '.estado', "")
            disableCamposEndereco.estado = false;
            setDisableCamposEndereco({ ...disableCamposEndereco });
          }
        })
      setValue(index + '.cep', cepMask(cep));
    };
  };

  function copyEnderecoImovel(index) {
    if(dadosVenda.cep) {
      setValue(index + '.cep', dadosVenda.cep);
      clearErrors(index + '.cep');
    }
    if(dadosVenda.bairro) {
      setValue(index + '.bairro', dadosVenda.bairro);
      clearErrors(index + '.bairro');
    }
    if(dadosVenda.logradouro) {
      setValue(index + '.logradouro', dadosVenda.logradouro);
      clearErrors(index + '.logradouro');
    }
    if(dadosVenda.cidade) {
      setValue(index + '.cidade', dadosVenda.cidade);
      clearErrors(index + '.cidade');
    }
    if(dadosVenda.uf) {
      setValue(index + '.estado', dadosVenda.uf);
      clearErrors(index + '.estado');
    }
    if(dadosVenda.numero) {
      setValue(index + ".numero", dadosVenda.numero);
      clearErrors(index + '.numero');
    }
    if(dadosVenda.unidade) {
      setValue(index + ".unidade", dadosVenda.unidade);
      clearErrors(index + '.unidade');
    }
    if(dadosVenda.complemento){
      setValue(index + ".complemento", dadosVenda.complemento);
      clearErrors(index + '.complemento');
    }
  };

  function addRepresentante(index) {
    const data = watch(index + ".representante_socios.data");
    data.push({
      conjuge: "",
      cpf_cnpj: "",
      email: "",
      estado_civil: "",
      genero: "",
      nacionalidade: "",
      name: "",
      pj_representante: "1",
      pj_socio: "0",
      profissao: "",
      perfil: "",
      rg: ""
    });
    console.log(data);
    setValue(index + ".representante_socios.data", data);
  };

  async function removeRepresentante(index, indexRepresentante) {
    const data = watch(index + ".representante_socios.data");
    if (data[indexRepresentante].id) {
      await Axios.post(app + 'deletar_relacao_representante_socio_pj', {
        "pj_usuario_id": watch(index + '.id'),
        'representando_socio_id': data[indexRepresentante].id,
        'processo_id': dadosVenda.processo_id
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        if (res.data.status && res.data.status === (401 || 498)) {
          localStorage.clear();
          history.push('/');
        } else {
          // recarregarVendaExcluir(dadosVenda.processo_id);
        }
      }).catch(function (error) {
        return console.log(error);
      })
    }
    data.splice(indexRepresentante, 1);
    setValue(index + ".representante_socios.data", data);
    unregister(index + ".representante_socios.data." + indexRepresentante);
  };

  async function handleCpf(cpf, index, indexRepresentante) {    
    if (cpf.length === 18 || (indexRepresentante >= 0 && cpf.length === 14)) {
      await Axios.post(app + 'verificar_usuario_cpf', { cpf }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        if (res?.data.usuario) {
          const { usuario } = res.data;
          if (indexRepresentante >= 0) {
            const { pj_representante, pj_socio } = watch(index + ".representante_socios.data." + indexRepresentante)
            setValue(index + ".representante_socios.data." + indexRepresentante, {
              ...usuario,
              pj_representante: indexRepresentante === 0 ? '1' : pj_representante,
              pj_socio: indexRepresentante === 0 ? '1' : pj_socio,
            });
            setPerfilUsuario(usuario.perfil);
            setValue(patchRepresentantes(index, indexRepresentante, 'perfil'), usuario.perfil)
          }
          else setValue(index.toString(), { ...usuario });
          clearErrors();
        } else {
          setValue(index + '.id', "")
        }
      })
    }
  };

  return (
    <Pane>
      <form id="formGroup" className="formGroup-dialog" encType="multipart/form-data">
        {!userData
          ? <div className="rowForm mt35">
            <Switch
              marginBottom={16}
              checked={watch(index + '.tipo_pessoa') === 1}
              onChange={() => setValue(index + '.tipo_pessoa', watch(index + '.tipo_pessoa') === 1 ? 0 : 1)}
            />
            <label className='ml10'>Pessoa Jurídica</label>
          </div>
          : <Badge color="green" className="badge-tipo-pesso">
            <span>Pessoa Jurídica</span>
          </Badge>
        }

        <h4 className='mt15'>Quais os dados da empresa?</h4>
        <p className="descritption mb30 mt15" >Preencha com dados da empresa referentes ao CNPJ.</p>

        <Pane>
          {/* LINHA CNPJ E TELEFONE  */}
          <div className="rowForm">
            <TextInputField
              width={160}
              label='CNPJ *'
              description=""
              placeholder='00.000.000/0000-00'
              maxLength={18}
              value={watch(index + '.cpf_cnpj')}
              {...register(index + '.cpf_cnpj', {
                required: msgObrigatorio,
                validate: (value) => validarCNPJ(value) ? true : "CNPJ inválido",
                onChange: (e) => handleCpf(e.target.value, index),
                setValueAs: e => cnpjMask(e)
              })}
              validationMessage={errors?.[index]?.cpf_cnpj && errors?.[index]?.cpf_cnpj.message}
              style={watch(index + '.cpf_cnpj') ? inputGreen :
                errors?.[index]?.cpf_cnpj ? inputError : inputDefault
              }
            />

            <Pane display="flex" flexDirection="column" marginLeft={16}>
              <label className='mb12'>Celular*</label>
              <PhoneInput
                country={"br"}
                onChange={(e, country) => {
                  setValue(index + '.telefone', e.replace(country.dialCode, ''));
                  setValue(index + '.ddi', country.dialCode);
                  clearErrors(index + '.telefone');
                }}
                value={watch(index + ".telefone") ? watch(index + ".ddi") + watch(index + ".telefone") : '55'}
                preferredCountries={['br', 'us']}
                inputStyle={watch(index + ".telefone")?.length >= 10 ? inputGreen : errors?.[index]?.telefone ? inputError : inputDefault}
                inputClass="PhoneInput"
                isValid={(inputNumber, country) => {
                  if (country.countryCode === '55') {
                    return inputNumber.length < 12 ? false : true;
                  } else {
                    return inputNumber.length < 6 ? false : true;
                  }
                }}
              />
              <input
                type="hidden"
                {...register(index + '.telefone', {
                  required: 'Campo obrigatório',
                  validate: (value) => {
                    if (value.length < 10) {
                      return "Telefone inválido";
                    }
                  },
                })}
              />
              <spam className="error-input mt8">
                {errors?.[index]?.telefone ? <ErrorIcon /> : ''}
                <span>{errors?.[index]?.telefone && errors?.[index]?.telefone.message}</span>
              </spam>
            </Pane>
          </div>

          {/* CAMPO RAZÃO SOCIAL */}
          <div className="rowForm">
            <TextInputField
              width={463}
              label="Razão Social"
              placeholder="Ex: MAGIS-IM LOCAÇÃO DE IMÓVEIS PRÓPRIOS LTDA"
              description=""
              validationMessage={errors?.[index]?.razao_social && errors?.[index]?.razao_social.message}
              style={watch(index + '.razao_social') ? inputGreen : errors?.[index]?.razao_social ? inputError : inputDefault}
              {...register(index + '.razao_social', {
                required: msgObrigatorio,
                // onChange: (e) => 
              })}
            />
          </div>

          {/* CAMPO NOME FANTASIA */}
          <div className="rowForm mb10">
            <TextInputField
              width={463}
              label="Nome Fantasia"
              description=""
              placeholder="Fulano da Silva LTDA"
              {...register(index + '.nome_fantasia', {
                required: msgObrigatorio,
                // onChange: (e) => 
              })}
              validationMessage={errors?.[index]?.nome_fantasia && errors?.[index]?.nome_fantasia.message}
              style={watch(index + '.nome_fantasia') ? inputGreen : errors?.[index]?.nome_fantasia ? inputError : inputDefault}
            />
          </div>

          {/* CAMPO ORIGEM DO CLIENTE */}
          {type === 'compradores' && index === 0 ?
            <div className="row-recibo mb30">
              <div className="subtitle">
                Sobre a origem do seu cliente
              </div>

              <div className="rowForm origem-pj" >
                <SelectField
                  label="Por qual meio ele veio *"
                  description=""
                  tabIndex={-1}
                  id={index + ".meio_entrada"}
                  width={250}
                  value={watch(index + '.meio_entrada')}
                  className={watch(index + '.meio_entrada') ? 'inputGreenSelect' : errors?.[index]?.meio_entrada ? 'inputErrorSelect' : ''}
                  {...register(index + '.meio_entrada', {
                    required: (type === 'compradores' && index === 0) ? msgObrigatorio : false,
                    onChange: (e) => setValue(index + '.meio_entrada', e.target.value)
                  })}
                  marginBottom={0}
                  validationMessage={errors?.[index]?.meio_entrada && errors?.[index]?.meio_entrada.message}
                >
                  <option value="" hidden>Selecionar</option>
                  {
                    origemComprador.lista_origem.filter(origem => origem.id !== 11).map(origem => <option value={origem.id}>{origem.nome}</option>)
                  }
                </SelectField>

                <SelectField
                  label="Forma de contato *"
                  description=""
                  width={250}
                  tabIndex={-1}
                  id={index + ".forma_contato"}
                  value={watch(index + '.forma_contato')}
                  className={watch(index + '.forma_contato') ? 'inputGreenSelect' : errors?.[index]?.forma_contato ? 'inputErrorSelect' : ''}
                  {...register(index + '.forma_contato', {
                    required: 'Campo obrigatório',
                    onChange: (e) => setValue(index + '.forma_contato', e.target.value)
                  })}
                  marginBottom={0}
                  validationMessage={errors?.[index]?.forma_contato && errors?.[index]?.forma_contato.message}
                >
                  <option value="" hidden>Selecionar</option>
                  {
                    origemComprador.lista_contato.map(contato => <option value={contato.id}>{contato.nome}</option>)
                  }
                </SelectField>

                <TextInputField
                  label="Data de entrada *"
                  description=""
                  placeholder="Ex: DD/MM/AAAA"
                  width={170}
                  maxLength={10}
                  marginLeft={20}
                  marginBottom={0}
                  style={watch(index + '.data_entrada_origem') ? inputGreen : errors?.[index]?.data_entrada_origem ? inputError : inputDefault}
                  {...register(index + '.data_entrada_origem', {
                    required: msgObrigatorio,
                    validate: (value) => {
                      if (value.length >= 1 && value.length < 10) {
                        return "Formato de data inválido";
                      }
                      if (value.length === 0) {
                        return "Campo obrigatório";
                      }
                    },
                    //onChange: (e) => handleDataEntradaOrigem(e, index)
                  })}
                  validationMessage={errors?.[index]?.data_entrada_origem && errors?.[index]?.data_entrada_origem.message}
                />
              </div>
            </div>
            :
            ''
          }

          {/* CAMPOS DE ENDEREÇO */}
          <div className="row-recibo">
            <div className="subtitle">Endereço da empresa</div>

            {type === "vendedores" &&
              <Button
                appearance="default"
                iconBefore={<ClipboardIcon />}
                onClick={() => copyEnderecoImovel(index)}
                className="btn-default mb15"
                type='reset'
              >
                Copiar endereço do imóvel vendido
              </Button>
            }

            {/* CAMPOS CEP BAIRRO E LOGRADOURO */}
            <div className="rowForm">
              <TextInputField
                label="CEP *"
                description=""
                placeholder="Ex: 00000-000"
                maxLength={9}
                value={watch(index + '.cep')}
                validationMessage={errors?.[index]?.cep && errors?.[index]?.cep.message}
                style={watch(index + '.cep') ? inputGreen : errors?.[index]?.cep ? inputError : inputDefault}
                {...register(index + '.cep', {
                  required: "Campo obrigatório",
                  onChange: (e) => handleCep(e.target.value, index),
                })}
              />

              <TextInputField
                width={215}
                label="Bairro"
                description=""
                value={watch(index + '.bairro')}
                placeholder={watch(index + '.cep') && !watch(index + '.bairro') ? 'Preencha com o bairro' : '---'}
                disabled={disableCamposEndereco.bairro}
                {...register(index + '.bairro', {
                  required: watch(index + '.cep') && !watch(index + '.bairro') ? "Campo obrigatório" : false,
                })}
                validationMessage={errors?.[index]?.bairro && errors?.[index]?.bairro.message}
                style={disableCamposEndereco.bairro ? undefined : watch(index + '.bairro') ? inputGreen : errors?.[index]?.bairro ? inputError : inputDefault}
              />

              <TextInputField
                width={250}
                label="Logradouro"
                description=""
                marginBottom={0}
                marginLeft={20}
                value={watch(index + '.logradouro')}
                placeholder={watch(index + '.cep') && !watch(index + '.logradouro') ? 'Preencha com o logradouro' : '---'}
                disabled={disableCamposEndereco.logradouro}
                {...register(index + '.logradouro', {
                  required: watch(index + '.cep') && !watch(index + '.logradouro') ? "Campo obrigatório" : false,
                })}
                validationMessage={errors?.[index]?.logradouro && errors?.[index]?.logradouro.message}
                style={disableCamposEndereco.logradouro ? undefined : watch(index + '.logradouro') ? inputGreen : errors?.[index]?.logradouro ? inputError : inputDefault}
              />
            </div>

            {/* CAMPOS NUMERO UNIDADE E COMPLEMENTO */}
            <div className="rowForm">
              <TextInputField
                width={130}
                label="Número *"
                description=""
                placeholder="Ex: 456"
                value={watch(index + '.numero')}
                validationMessage={errors?.[index]?.numero && errors?.[index]?.numero.message}
                style={watch(index + '.numero') ? inputGreen : errors?.[index]?.numero ? inputError : inputDefault}
                {...register(index + '.numero', {
                  required: "Campo obrigatório",
                })}
              />

              <TextInputField
                width={180}
                label="Unidade"
                description=""
                placeholder="Número do apartamento"
                value={watch(index + '.unidade')}
                style={watch(index + '.unidade') ? inputGreen : inputDefault}
                {...register(index + '.unidade', {
                })}
              />

              <TextInputField
                width={360}
                label="Complemento"
                placeholder="Bloco, lote ou condomínio"
                description=""
                marginBottom={0}
                marginLeft={20}
                value={watch(index + '.complemento')}
                style={watch(index + '.complemento') ? inputGreen : inputDefault}
                {...register(index + '.complemento', {
                })}
              />
            </div>

            {/* CAMPOS CIDADE E ESTADO */}
            <div className="rowForm">
              <TextInputField
                width={250}
                label="Cidade"
                description=""
                value={watch(index + '.cidade')}
                placeholder={watch(index + '.cep') && !watch(index + '.cidade') ? 'Preencha com a cidade' : '---'}
                disabled={disableCamposEndereco.cidade}
                {...register(index + '.cidade', {
                  required: watch(index + '.cep') && !watch(index + '.cidade') ? "Campo obrigatório" : false,
                })}
                validationMessage={errors?.[index]?.cidade && errors?.[index]?.cidade.message}
                style={disableCamposEndereco.cidade ? undefined : watch(index + '.cidade') ? inputGreen : errors?.[index]?.cidade ? inputError : inputDefault}
              />

              <TextInputField
                width={250}
                label="Estado"
                description=""
                value={watch(index + '.estado')}
                placeholder={watch(index + '.cep') && !watch(index + '.estado') ? 'Preencha com o estado' : '---'}
                disabled={disableCamposEndereco.estado}
                {...register(index + '.estado', {
                  required: watch(index + '.cep') && !watch(index + '.estado') ? "Campo obrigatório" : false,
                  // onChange: (e) => 
                })}
                validationMessage={errors?.[index]?.estado && errors?.[index]?.estado.message}
                style={disableCamposEndereco.estado ? undefined : watch(index + '.estado') ? inputGreen : errors?.[index]?.estado ? inputError : inputDefault}
              />
            </div>
          </div>

          {/* CAMPOS DE REPRESENTANTES */}
          <div className="row-recibo">
            <div className="subtitle"> Quem está representando a empresa? </div>

            <p className="descritption mt15 mb10">
              Preencha com dados de Sócios e/ou Representantes Legais da empresa, ou seja, quem representa a empresa diante da Receita Federal.
            </p>

            {(watch(index + '.representante_socios.data') || [""]).map((representante, indexRepresentante) => (
              <div className="rowForm-responsavel-pj" key={indexRepresentante}>
                <input type="hidden" {...register(patchRepresentantes(index, indexRepresentante, 'perfil'))} />
                {/* CAMPOS CHECK REPRESENTANTE E/OU SOCIO */}
                <div className="mb30">
                  <div className="line-checkbox">
                    <Checkbox
                      label="É representante legal da empresa."
                      disabled={indexRepresentante === 0}
                      checked={representante?.pj_representante?.toString() === '1'}
                      value={'1'}
                      className={indexRepresentante === 0 ? "checkbox checkbox-disabled" : 'checkbox'}
                      {...register(patchRepresentantes(index, indexRepresentante, 'pj_representante'), {
                        onChange: () => watch(patchRepresentantes(index, indexRepresentante, 'pj_representante')) === '1' ? false : '1',
                      })}
                    />

                    <Checkbox
                      label="Sócio"
                      value={'1'}
                      disabled={indexRepresentante === 0}
                      checked={representante?.pj_socio?.toString() === '1'}
                      className={indexRepresentante === 0 ? "checkbox checkbox-disabled" : 'checkbox'}
                      {...register(patchRepresentantes(index, indexRepresentante, 'pj_socio'), {
                        onChange: () => watch(patchRepresentantes(index, indexRepresentante, 'pj_socio')) === '1' ? false : '1',
                      })}
                    />
                  </div>

                  <span className="error-input mb0">
                    {errors?.[index]?.representante_socios?.data?.[index]?.pj_representante ? <ErrorIcon /> : ''}
                    <span>
                      {errors?.[index]?.representante_socios?.data?.[index]?.pj_representante && errors?.[index]?.representante_socios?.data?.[index]?.pj_representante.message}
                    </span>
                  </span>
                </div>

                {/* CAMPOS DE GENERO */}
                <div className="row-genero displayBlock">
                  <div className="mb10 line-radius" tabIndex={-1} id={index + ".representante_socios.data." + indexRepresentante + '.genero'}>
                    <Label>Gênero*</Label>
                    <Pane aria-label="radio-group-genero" role="group" className="radio radius">
                      <Radio
                        size={16}
                        className="radio-M"
                        value="M"
                        label="Masculino"
                        checked={watch(patchRepresentantes(index, indexRepresentante, 'genero')) === 'M'}
                        onChange={() => setValue(patchRepresentantes(index, indexRepresentante, 'genero'), "M")}
                      />
                      <Radio
                        size={16}
                        value="F"
                        label="Feminino"
                        className="radio-F"
                        checked={watch(patchRepresentantes(index, indexRepresentante, 'genero')) === 'F'}
                        onChange={() => setValue(patchRepresentantes(index, indexRepresentante, 'genero'), "F")}
                      />
                      <input
                        type="hidden"
                        {...register(patchRepresentantes(index, indexRepresentante, 'genero'), {
                          required: msgObrigatorio,
                        })}
                      />
                    </Pane>
                  </div>
                  {errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.genero &&
                    <div className="error-input mt10 f12 flex">
                      <ErrorIcon color='#D14343' /> {msgObrigatorio}
                    </div>
                  }
                </div>

                {/* CAMPO CPF E NOME */}
                <div className="rowForm">
                  <TextInputField
                    width={140}
                    label="CPF *"
                    placeholder="000.000.000-00"
                    description=""
                    maxLength={14}
                    value={representante.cpf_cnpj}
                    validationMessage={errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.cpf_cnpj && errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.cpf_cnpj.message}
                    style={watch(patchRepresentantes(index, indexRepresentante, 'cpf_cnpj')) ? inputGreen : errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.cpf_cnpj ? inputError : inputDefault}
                    {...register(patchRepresentantes(index, indexRepresentante, 'cpf_cnpj'), {
                      required: msgObrigatorio,
                      maxLength: '14',
                      validate: (value) => {
                        if (!validarCPF(value)) return "CPF inválido";
                      },
                      onChange: (e) => handleCpf(e.target.value, index, indexRepresentante),
                      setValueAs: e => cpfMask(e),
                    })}
                  />

                  <TextInputField
                    width={463}
                    label="Nome Completo *"
                    placeholder="Ex: José Maria da Silva"
                    description=""
                    value={representante.name}
                    disabled={!!watch(patchRepresentantes(index, indexRepresentante, 'perfil'))}
                    validationMessage={errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.name && errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.name.message}
                    //style={watch(patchRepresentantes(index, indexRepresentante, 'name')) ? inputGreen : errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.name ? inputError : inputDefault}
                    style={
                      watch(patchRepresentantes(index, indexRepresentante, 'name')) 
                        ? !!watch(patchRepresentantes(index, indexRepresentante, 'perfil')) === false
                          ? inputGreen 
                          : inputDisabled
                        : errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.name ? inputError : inputDefault
                    }
                    {...register(patchRepresentantes(index, indexRepresentante, 'name'), {
                      required: msgObrigatorio,
                      onChange: (e) => setValue(patchRepresentantes(index, indexRepresentante, 'name'), e.target.value)
                    })}
                  />
                </div>

                {/* CAMPO EMAIL */}
                <div className="rowForm">
                  <TextInputField
                    width={463}
                    label="E-mail *"
                    description=""
                    placeholder="Ex: teste@gmail.com"
                    value={representante.email}
                    disabled={!!watch(patchRepresentantes(index, indexRepresentante, 'perfil'))}
                    validationMessage={errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.email && errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.email.message}
                    //style={watch(patchRepresentantes(index, indexRepresentante, 'email')) ? inputGreen : errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.email ? inputError : inputDefault}
                    style={
                      watch(patchRepresentantes(index, indexRepresentante, 'email')) 
                      ? !!watch(patchRepresentantes(index, indexRepresentante, 'perfil')) === false
                        ? inputGreen 
                        : inputDisabled
                      : errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.email ? inputError : inputDefault
                    }
                    {...register(patchRepresentantes(index, indexRepresentante, 'email'), {
                      required: msgObrigatorio,
                      onChange: (e) => setValue(patchRepresentantes(index, indexRepresentante, 'email'), e.target.value)
                    })}
                  />
                </div>                
                {(representante?.pj_representante?.toString() === '1' || indexRepresentante === 0) &&
                  // CAMPOS OBRIGATÓRIOS PARA REPRESENTANTES
                  <Pane>
                    {/* CAMPO RG E SEUS DEPENDENTES */}
                    <div className="rowForm">
                      <TextInputField
                        width={216}
                        label="RG *"
                        description=""
                        placeholder="Ex: 12.345.678-9"
                        value={representante.rg}
                        //disabled={!!watch(patchRepresentantes(index, indexRepresentante, 'perfil'))}
                        validationMessage={errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.rg && errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.rg.message}
                        //style={watch(patchRepresentantes(index, indexRepresentante, 'rg')) ? inputGreen : errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.rg ? inputError : inputDefault}
                        style={
                          watch(patchRepresentantes(index, indexRepresentante, 'rg')) 
                          ? inputGreen 
                          : errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.rg ? inputError : inputDefault
                        }
                        {...register(patchRepresentantes(index, indexRepresentante, 'rg'), {
                          required: (representante?.pj_representante?.toString() === '1' || indexRepresentante === 0) && msgObrigatorio,
                          onChange: (e) => setValue(patchRepresentantes(index, indexRepresentante, 'rg'), e.target.value)
                        })}
                      />

                      {watch(patchRepresentantes(index, indexRepresentante, 'rg'))?.length > 0
                        ?
                        <>
                          <TextInputField
                            width={216}
                            label="Expedida por: *"
                            description=""
                            placeholder="Ex: Detran-RJ"
                            value={representante.rg_expedido}
                            //disabled={!!watch(patchRepresentantes(index, indexRepresentante, 'perfil'))}
                            validationMessage={errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.rg_expedido && errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.rg_expedido.message}
                            //style={watch(patchRepresentantes(index, indexRepresentante, 'rg_expedido')) ? inputGreen : errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.rg_expedido ? inputError : inputDefault}
                            style={
                              watch(patchRepresentantes(index, indexRepresentante, 'rg_expedido')) 
                                ? inputGreen 
                                : errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.rg_expedido ? inputError : inputDefault
                            }
                            {...register(patchRepresentantes(index, indexRepresentante, 'rg_expedido'), {
                              required: watch(patchRepresentantes(index, indexRepresentante, 'rg')).length > 0 && msgObrigatorio,
                              onChange: (e) => setValue(patchRepresentantes(index, indexRepresentante, 'rg_expedido'), e.target.value)
                            })}
                          />

                          <TextInputField
                            width={216}
                            marginBottom={0}
                            marginLeft={20}
                            label="Data de expedição: *"
                            description=""
                            placeholder="Ex: DD/MM/AAAA"
                            value={representante.data_rg_expedido}
                            //disabled={!!watch(patchRepresentantes(index, indexRepresentante, 'perfil'))}
                            validationMessage={errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.data_rg_expedido && errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.data_rg_expedido.message}
                            //style={watch(patchRepresentantes(index, indexRepresentante, 'data_rg_expedido')) ? inputGreen : errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.data_rg_expedido ? inputError : inputDefault}
                            style={
                              watch(patchRepresentantes(index, indexRepresentante, 'data_rg_expedido')) 
                              ? inputGreen 
                              : errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.data_rg_expedido ? inputError : inputDefault
                            }
                            {...register(patchRepresentantes(index, indexRepresentante, 'data_rg_expedido'), {
                              required: watch(patchRepresentantes(index, indexRepresentante, 'rg')).length > 0 && msgObrigatorio,
                              onChange: (e) => setValue(patchRepresentantes(index, indexRepresentante, 'data_rg_expedido'), e.target.value),
                              setValueAs: (e) => dateMask(e)
                            })}
                          />
                        </>
                        : ""}
                    </div>

                    {/* CAMPOS PROFISSÃO E NACIONALIDADE */}
                    <div className="rowForm">
                      <TextInputField
                        width={463}
                        label="Profissão"
                        description=""
                        placeholder="Ex: Corretor"
                        value={representante.profissao}
                        validationMessage={errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.profissao && errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.profissao.message}
                        style={watch(patchRepresentantes(index, indexRepresentante, 'profissao')) ? inputGreen : errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.profissao ? inputError : inputDefault}
                        {...register(patchRepresentantes(index, indexRepresentante, 'profissao'), {
                          required: false,
                          onChange: (e) => setValue(patchRepresentantes(index, indexRepresentante, 'profissao'), e.target.value)
                        })}
                      />

                      <TextInputField
                        width={180}
                        marginLeft={20}
                        label="Nacionalidade"
                        description=""
                        placeholder="Ex: Brasileiro"
                        value={representante.nacionalidade}
                        validationMessage={errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.nacionalidade && errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.nacionalidade.message}
                        style={watch(patchRepresentantes(index, indexRepresentante, 'nacionalidade')) ? inputGreen : errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.nacionalidade ? inputError : inputDefault}
                        {...register(patchRepresentantes(index, indexRepresentante, 'nacionalidade'), {
                          required: false,
                          onChange: (e) => setValue(patchRepresentantes(index, indexRepresentante, 'nacionalidade'), e.target.value)
                        })}
                      />
                    </div>

                    {/* CAMPOS ESTADO CIVIL, REGIME CASAMENTO E UNIÃO ESTAVEL 1.representante_socios.data.0.estado_civil */}
                    <div className="rowForm">                      
                      <div tabIndex={-1} id={index + ".representante_socios.data." + indexRepresentante + '.estado_civil'}>
                        <SelectField
                          label="Estado Civil *"
                          description=""
                          value={representante.estado_civil}
                          validationMessage={errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.estado_civil && msgObrigatorio}
                          className={watch(patchRepresentantes(index, indexRepresentante, 'estado_civil')) ? 'inputGreenSelect' : errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.estado_civil ? 'inputErrorSelect' : ""}
                          {...register(patchRepresentantes(index, indexRepresentante, 'estado_civil'), {
                            required: indexRepresentante === 0,
                            onChange: (e) => [
                              setValue(patchRepresentantes(index, indexRepresentante, 'estado_civil'), e.target.value),
                              e.target.value === "2" && setValue(patchRepresentantes(index, indexRepresentante, 'registro_casamento'), "")
                            ]
                          })}
                        >
                          <option value="0" selected hidden>Selecionar</option>
                          <option value="1">Casado</option>
                          <option value="2">Solteiro</option>
                          <option value="3">Divorciado</option>
                          <option value="4">Viúvo</option>
                          <option value="5">Separado</option>
                        </SelectField>
                      </div>

                      {watch(patchRepresentantes(index, indexRepresentante, 'estado_civil')) === '1' &&
                        <div tabIndex={-1} id={index + ".representante_socios.data." + indexRepresentante + '.registro_casamento'}>
                          <SelectField
                            label="Regime de casamento *"
                            description=""
                            value={representante.registro_casamento}

                            className={watch(patchRepresentantes(index, indexRepresentante, 'registro_casamento')) ? 'inputGreenSelect' : errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.registro_casamento ? 'inputErrorSelect' : ""}
                            validationMessage={errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.registro_casamento && errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.registro_casamento.message}
                            {...register(patchRepresentantes(index, indexRepresentante, 'registro_casamento'), {
                              required: (representante && watch(patchRepresentantes(index, indexRepresentante, 'estado_civil'))) === '1' ? msgObrigatorio : false,
                              onChange: (e) => setValue(patchRepresentantes(index, indexRepresentante, 'registro_casamento'), e.target.value)
                            })}
                          >
                            <option value="0" selected hidden>Selecionar</option>
                            <option value="1">Separação total de bens</option>
                            <option value="2">Separação parcial de bens</option>
                            <option value="3">Separação legal de bens</option>
                            <option value="4">Comunhão de bens</option>
                            <option value="5">Comunhão parcial de bens</option>
                          </SelectField>
                        </div>

                      }

                      {watch(patchRepresentantes(index, indexRepresentante, 'estado_civil')) > '1' &&
                        <div className="line-checkbox">
                          <Checkbox
                            marginTop={38}
                            className="checkbox"
                            label="União Estável"
                            appearance="default"
                            value="S"
                            checked={watch(patchRepresentantes(index, indexRepresentante, 'uniao_estavel')) === "S"}
                            {...register(patchRepresentantes(index, indexRepresentante, 'uniao_estavel'), {
                              onChange: () => watch(patchRepresentantes(index, indexRepresentante, 'uniao_estavel')) === "S" ? "" : "S"
                            })}
                          />
                        </div>
                      }
                    </div>

                    {/* CAMPO NOME DO CONJUGUE */}
                    <div className="rowForm">
                      {
                        (watch(patchRepresentantes(index, indexRepresentante, 'estado_civil')) === '1' || watch(patchRepresentantes(index, indexRepresentante, 'uniao_estavel')))
                          ?
                          <TextInputField
                            width={463}
                            label="Nome completo do Cônjuge/Compaheiro(a) *"
                            description=""
                            placeholder="Ex: José Maria da Silva"
                            value={representante.conjuge}
                            style={watch(patchRepresentantes(index, indexRepresentante, 'conjuge')) ? inputGreen : errors?.[index]?.representante_socios?.data?.[indexRepresentante]?.conjuge ? inputError : inputDefault}
                            {...register(patchRepresentantes(index, indexRepresentante, 'conjuge'), {
                              required: (watch(patchRepresentantes(index, indexRepresentante, 'estado_civil')) === '1' || watch(patchRepresentantes(index, indexRepresentante, 'uniao_estavel'))) && msgObrigatorio,
                              onChange: (e) => setValue(patchRepresentantes(index, indexRepresentante, 'conjuge'), e.target.value)
                            })}
                          />
                          : ""
                      }
                    </div>
                  </Pane>
                }

                {indexRepresentante > 0 &&
                  <Button
                    marginRight={16}
                    appearance="primary"
                    intent="danger"
                    className="button remove"
                    onClick={() => removeRepresentante(index, indexRepresentante)}
                    type="button"
                    iconBefore={<DeleteIcon color='white' />}
                  >
                    Excluir
                  </Button>
                }
              </div>
            ))}

            <Button
              className="btn-add-documento"
              onClick={() => addRepresentante(index)}
              iconBefore={<PlusIcon />}
              type='reset'
            >
              Adicionar sócio e/ou representante
            </Button>

          </div>

        </Pane>
      </form>
    </Pane >
  )
}