import React, { useContext, useEffect, useRef, useState } from 'react';
import { Pane, Button, Badge, Textarea, AddIcon, TextareaField, InlineAlert, toaster, SmallTickIcon } from 'evergreen-ui';
import NegociacaoContext from '../../../../../context/NegociacaoContext';
import PhoneMask from '../../../../../components/phoneMask';
import Checklist from './componentes/Checklist'
import animationData from '../../../../../images/Animations/loading_dna.json';
import Lottie from 'react-lottie';
import InfoEscritura from '../../../../../components/InfoEscritura';
import updateSalvarChecklist from '../../../../../apis/updateSalvarChecklist';

export default function Alertas({ setSelectedTabIndex, refreshProcess }) {

  const {
    processoId,
    processSelect,
    loading,
    text, setText,
    list, setList,
    listPadrao, setListPadrao,
    listaLaudemios,
    setLoading, isShownSide,
  } = useContext(NegociacaoContext);

  const { aditamento, averbacao, laudemio, registro, validade_doc, recomendacao_data_certidao } = processSelect?.imovel?.pontos_atencao_posvenda;

  const mapAlerts = [
    { value: aditamento, label: 'Aditamento' },
    { value: averbacao, label: 'Averbação' },
    { value: laudemio, label: 'Laudemio' },
    { value: registro, label: 'Registro' },
    { value: validade_doc, label: 'Validade Documento' },
    { value: recomendacao_data_certidao, label: 'Recomendação para emissão de certidão' },
  ];

  const handleClick = (type) => {
    setSelectedTabIndex(type)
  };

  const returnSub = (type) => {
    switch (type) {
      case 'Aditamento':
        return "Identificamos a possibilidade desta venda passar pelo processo de aditamento."
      case 'Averbação':
        return "Identificamos a possibilidade desta venda estar com averbação pendente."
      case 'Laudemio':
        return "Identificamos a possibilidade desta venda precisar de laudêmio."
      case 'Registro':
        return "Identificamos a necessidade de uma maior atenção ao Registro desta venda."
      case 'Validade Documento':
        return validade_doc;
      case 'Recomendação para emissão de certidão':
        const linhas = recomendacao_data_certidao.split('\n')
        return <div>
                    {linhas.map((linha, index) => (
                        <p key={index}>{linha}</p>
                    ))}
                </div>;
      default:
        return ""
    }
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const [addItemList, setAddItemList] = useState(false);
  const [itemVazio, setItemVazio] = useState();

  const { imovel, loja, gerente } = processSelect;
  const vendedores = imovel?.vendedores || "";
  const compradores = imovel?.compradores || "";
  const comissao = imovel?.comissao || "";
  const laudemios = imovel?.laudemios || [];
  const dataPrazoEscritura = imovel?.data_prazo_escritura || "---";
  const textArea = useRef(null);

  useEffect(() => {
    console.log('CARREGOU')
    setListPadrao(listPadrao)
    textArea.current.value = processSelect.imovel?.resumo_posvenda?.observacao_resumo?.observacao_resumo || "";
    const newObs = {
      itemList: "",
      textArea: processSelect.imovel?.resumo_posvenda?.observacao_resumo?.observacao_resumo || "",
    }
    setText({ ...newObs });

    console.log(processSelect)
    console.log(processSelect.imovel?.resumo_posvenda?.dados_checklist);
    console.log(listPadrao);

    if (processSelect.imovel?.resumo_posvenda?.dados_checklist.length > 0) {
      const newList = processSelect.imovel?.resumo_posvenda?.dados_checklist?.map((item) => (
        { id: item.id, processoId: item.processo_id, key: item.checklist_id, text: item.nome, checked: (item.check_situacao === "1") }
      ))
      setList([...newList]);
    } else {
      setList(listPadrao);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processSelect])

  useEffect(() => {
    setAddItemList(false);
    setItemVazio();
  }, [isShownSide]);

  const endereço =
    imovel?.logradouro +
    (imovel?.numero ? (", " + imovel.numero) : "") +
    (imovel?.unidade ? (" / " + imovel.unidade) : "") +
    (imovel?.cidade ? (" - " + imovel.cidade) : "") +
    (imovel?.uf ? (" - " + imovel.uf) : "");

  const handleText = (e, patch) => {
    if (e.split("").length === 1) setItemVazio();
    text[patch] = e;
    // setText({...text});
  };

  const handleAddItem = () => {
    if (text.itemList) {
      list.push({ id: "", key: list.lenght + 1, text: text.itemList, checked: false });
      setList([...list]);
      text.itemList = "";
      setAddItemList(false);
      setItemVazio()
    } else {
      //ALERTA DE CAMPO VAZIO
      setItemVazio("Não pode adicionar um item vazio a cheklist.")
    }
  };

  const laudemioTipo = (tipo) => {
    switch (tipo) {
      case 1:
        return "União"
      case 2:
        return "Prefeitura"
      case 3:
        return "Familia"
      case 4:
        return "Igreja"
      default:
        return "--"
    }
  };

  const laudemioValor = (tipo, valor) => {
    switch (tipo) {
      case 1:
        return "RIP nº " + valor
      case 3:
        const familia = listaLaudemios.find((laudemio) => laudemio.id === Number(valor))?.nome
        return familia
      case 4:
        const igreja = listaLaudemios.find((laudemio) => laudemio.id === Number(valor))?.nome
        return igreja
      default:
        return valor
    }
  };

  function capitalize(str) {
    if (!str) return "---"
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleSave = async () => {
    setLoading(true);

    const checklist = list.map((item, index) => ({
      check_situacao: item.checked ? '1' : '0',
      nome_checklist: item.text,
      checklist_id: item.id,
      // index: index
    }))
    const data = {
      'processo_id': processSelect.id,
      'observacao_resumo': text.textArea,
      checklist
    };

    const result = await updateSalvarChecklist(data);
    if (result?.data?.checklist === "Dados de checklist salvos") {
      toaster.success("Salvo com sucesso!")
      refreshProcess(processoId);
    }
    else toaster.warning("Algo não saiu como esperado. Tente novamente mais tarde.")
    setLoading(false);
  };


  return (
    <div className="formGroup formAdd">
      {console.log(mapAlerts)}
      {mapAlerts.some(e => e.value) &&
        <Pane className='mb20'>
          <h2 className="f18 fw600 mb16">Pontos de alerta da venda:</h2>
          <p className='fw400 f12 mb16'>Os pontos de alerta indicam possíveis pontos de atenção que podem vir a acontecer durante a pós-negociação.</p>
          {mapAlerts.filter(e => e.value).map((alerts, index) => (
            <div className='mb20' key={index}>
              <InlineAlert className='content-click' marginBottom={5} intent="warning" onClick={() => handleClick(1)}>
                {alerts.label}
              </InlineAlert>
              {returnSub(alerts.label)}
            </div>
          ))}
        </Pane>
      }

      <Pane className={(mapAlerts.some(e => e.value) && 'border-top-Blue ') + 'f16 mt29'}>
        <h2 className="f18 fw600 mb16 mt29">Resumo do processo</h2>
        <div className='f16 fw400 lw24 mt5'>
          Aqui você tem acesso a um resumo geral das informações do imóvel e da venda,
          além de saber quais etapas faltam para sua conclusão.
        </div>

        {loading ? <Lottie options={defaultOptions} height={200} width={400} /> :
          <Pane>

            <Pane className='mt29'>
              <div className='fw600 mt29 f18'>
                Sobre o Imóvel
              </div>

              <div className='fw600 mt29'>
                Endereço
              </div>
              <p className='mt6 fw400'>
                {endereço}
              </p>

              <Pane className='flex'>
                <div className='fw600 mt29 w237'>
                  Inscrição Municipal
                  <p className='fw400 mt6'>
                    {imovel?.informacao?.inscricaoMunicipal}
                  </p>
                </div>

                <div className='fw600 mt29 w237'>
                  Laudêmio
                  {laudemios[0] ? laudemios.map((laudemio, index) => (
                    <div className='mt6' key={index}>
                      <p className='fw400'>
                        {laudemioTipo(Number(laudemio.tipo_laudemio))}
                      </p>
                      <p className='fw400 mt6 mb16'>
                        {laudemioValor(Number(laudemio.tipo_laudemio), (laudemio.valor_laudemio))}
                      </p>
                    </div>
                  )) : <div className='mt6 fw400'>---</div>}
                </div>

                <div className='fw600 mt29 w237'>
                  Prazo para escritura
                  <p className='fw400 mt6'>
                    {dataPrazoEscritura}
                  </p>
                </div>
              </Pane>
            </Pane>

            <Pane className='border-top-Blue mt40' style={{ display: 'block' }}>
              {/*Informações sobre a Escritura*/}
              <InfoEscritura processo={processSelect.imovel} prazoAssinatura={processSelect.imovel?.verificar_processo_escritura?.processo?.prazo_escritura} perfil="posVenda" />
            </Pane>

            <Pane className='border-top-Blue mt40 flex'>
              {/* CAMPO VENDEDOR */}
              <div className='mt29 w237'>
                <p className='fw600 f18'>Vendedor</p>
                {vendedores?.data?.[0]?.tipo_pessoa ?
                  // CAMPO VENDEDOR PESSOA JURÍDICA
                  <div>
                    <Badge color="green" className="mt20">
                      Pessoa Jurídica
                    </Badge>
                    <p className='fw400 mt15'>{vendedores?.data?.[0]?.razao_social}</p>
                    <p className='fw400 mt5'>{PhoneMask(vendedores?.data?.[0]?.telefone)}</p>

                    <div hidden={!vendedores?.data?.[0]?.representante_socios}>
                      <p className='fw400 mt20'>Representante:</p>
                      <p className='fw400 mt5'>{vendedores?.data?.[0]?.representante_socios?.data[0]?.name}</p>
                      <p className='fw400 mt5'>{vendedores?.data?.[0]?.representante_socios?.data[0]?.telefone}</p>
                    </div>
                  </div>
                  :
                  // CAMPO VENDEDOR PESSOA FÍSICA
                  <div>
                    <Badge color="blue" className="mt20">
                      Pessoa Física
                    </Badge>
                    <p className='fw400 mt15'>{vendedores?.data?.[0]?.name}</p>
                    <p className='fw400 mt5'>{PhoneMask(vendedores?.data?.[0]?.telefone)}</p>

                    <div hidden={!vendedores?.data?.[0]?.procurador}>
                      <p className='fw400 mt20'>Procurador:</p>
                      <p className='fw400 mt5'>{vendedores?.data?.[0]?.procurador?.nome}</p>
                      <p className='fw400 mt5'>{vendedores?.data?.[0]?.procurador?.telefone}</p>
                    </div>
                  </div>
                }

              </div>

              {/* CAMPO COMPRADOR */}
              <div className='mt29 w237'>
                <p className='fw600 f18'>Comprador</p>
                {compradores?.data?.[0]?.tipo_pessoa ?
                  // CAMPO COMPRADOR PESSOA JURÍDICA
                  <div>
                    <Badge color="green" className="mt20">
                      Pessoa Jurídica
                    </Badge>
                    <p className='fw400 mt15'>{compradores?.data?.[0]?.razao_social}</p>
                    <p className='fw400 mt5'>{PhoneMask(compradores?.data?.[0]?.telefone)}</p>

                    <div hidden={!compradores?.data?.[0]?.representante_socios}>
                      <p className='fw400 mt20'>Representante:</p>
                      <p className='fw400 mt5'>{compradores?.data?.[0]?.representante_socios?.data[0]?.name}</p>
                      <p className='fw400 mt5'>{compradores?.data?.[0]?.representante_socios?.data[0]?.telefone}</p>
                    </div>
                  </div>
                  :
                  // CAMPO COMPRADOR PESSOA FISICA
                  <div>
                    <Badge color="blue" className="mt20">
                      Pessoa Física
                    </Badge>
                    <p className='fw400 mt15'>{compradores?.data?.[0]?.name}</p>
                    <p className='fw400 mt5'>{PhoneMask(compradores?.data?.[0]?.telefone)}</p>

                    <div hidden={!compradores?.data?.[0]?.procurador}>
                      <p className='fw400 mt20'>Procurador:</p>
                      <p className='fw400 mt5'>{compradores?.data?.[0]?.procurador?.name}</p>
                      <p className='fw400 mt5'>{compradores?.data?.[0]?.procurador?.telefone}</p>
                    </div>
                  </div>
                }
              </div>
            </Pane>

            <Pane className='border-top-Blue mt29 pb20'>
              {/* CAMPO COMISSÃO TOTAL */}
              <p className='fw600 mt40 f18'>Comissão total</p>

              <div className='flex mt29'>
                <div className='w237'>
                  <p className='fw600'>Forma de pagamento</p>
                  <p className='fw400 mt5'>{capitalize(comissao?.liquida) || '---'}</p>
                </div>

                <div>
                  <p className='fw600'>Valor de Comissão</p>
                  <p className='fw400 mt5'>{comissao?.valor_comissao_liquida || '---'}</p>
                </div>
              </div>

              <div className='flex mt20'>
                <div className='w237'>
                  <p className='fw600'>Gerente</p>
                  <p className='fw400 mt5'>{gerente?.data?.[0]?.name || '---'}</p>
                </div>

                <div>
                  <p className='fw600'>Loja</p>
                  <p className='fw400 mt5'>{loja?.nome || '---'}</p>
                </div>
              </div>
            </Pane>

            <Pane className='border-top-Blue mt29 pb20'>

              {/* CAMPO CHECKLIST */}
              <p className='fw600 mt40 f18'>Checklist do Pós-Venda</p>
              <Checklist refreshProcess={refreshProcess} />

              {addItemList ?
                <Pane>
                  <TextareaField
                    className='mt20'
                    placeholder='Adicionar um item'
                    defaultValue={text.itemList}
                    // value={text.itemList}
                    onChange={(e) => handleText(e.target.value, 'itemList')}
                    validationMessage={itemVazio}
                  />
                  <div className='flex mt5' >
                    <Button className='mr10' appearance='primary' onClick={() => handleAddItem()}> Adicionar </Button>
                    <Button onClick={() => [setAddItemList(false), setItemVazio()]}> Cancelar </Button>
                  </div>
                </Pane>
                :
                <Button className='mt20' iconBefore={AddIcon} onClick={() => setAddItemList(true)}>Adicionar um item</Button>
              }
            </Pane>

            <Pane className='border-top-Blue mt29 pb29'>
              {/* CAMPO CHECKLIST */}
              <p className='fw600 mt29 f18'>Observações gerais sobre a venda</p>
              <p className='fw400 mt20'>Caso necessário, insira observações adicionais.</p>

              <Textarea
                className='mt20'
                minHeight={167}
                ref={textArea}
                onChange={(e) => handleText(e.target.value, 'textArea')}
                placeholder='Exemplo: A venda foi realizada por dois corretores; ambos receberão parte da comissão.'
              />
            </Pane>

          </Pane>}
      </Pane>

      <Pane className='flex justify-end'>
        <Button
          type="submit"
          appearance="primary"
          width={153}
          onClick={() => handleSave()}
          isLoading={loading}
        >
          <SmallTickIcon size={12} color="#fff" marginRight={10} /> Salvar correções
        </Button>
      </Pane>
    </div>
  )
}