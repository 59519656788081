import React, { useState, useEffect, useContext } from 'react';
import {
  Pane,
  Table,
  Button,
  ShareIcon,
  LockIcon,
  Badge,
  Avatar,
  Select,
  FilterIcon,
  Autocomplete,
  TextInput,
  Tooltip,
  InfoSignIcon,
  Combobox,
  Pagination,
  DotIcon,
  Menu,
  Popover,
  Position,
  DocumentOpenIcon,
  ChangesIcon,
  ListDetailViewIcon,
  ChevronUpIcon,
  ChevronDownIcon
} from 'evergreen-ui';
import Axios from 'axios';
import Serve from '../../../app/serve';
import Lottie from 'react-lottie';
import animationData from '../../../images/Animations/loadingDocs.json';
// import pagination from '../../../components/pagination';

//Context
import NegociacaoContext from '../../../context/NegociacaoContext';

//Components
import DialogShare from './Modals/DialogShare';
import DialogFeedBack from './Modals/DialogFeedBack';
import DialogRevisao from './Modals/DialogRevisao';
import DialogRevisaoFeedBack from './Modals/DialogRevisaoFeedBack';
import DialogSolicitarNucleo from './Modals/DialogSolicitarNucleo';
import DialogNucleoFeedBack from './Modals/DialogNucleoFeedBack';
import DialogResponsavel from './Modals/DialogResponsavel';
import SideSheetView from './SideSheetView';
import ChangeLog from '../../components/ChangeLog';
import getProcessById from '../../../apis/getProcessById';

const listMonths = [
  { value: 0, label: "Todos" },
  { value: 1, label: "Janeiro" },
  { value: 2, label: "Fevereiro" },
  { value: 3, label: "Março" },
  { value: 4, label: "Abril" },
  { value: 5, label: "Maio" },
  { value: 6, label: "Junho" },
  { value: 7, label: "Julho" },
  { value: 8, label: "Agosto" },
  { value: 9, label: "Setembro" },
  { value: 10, label: "Outubro" },
  { value: 11, label: "Novembro" },
  { value: 12, label: "Dezembro" },
];

export default function TablePanel({ getPaged, getListTableCoordenador }) {
  const app = Serve();
  const [token] = useState(localStorage.getItem('token'));
  const [classNovo, setClassNovo] = useState(['fw400']);
  const [collapseTableCoord, setCollapseTableCoord] = useState(true);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const {
    loading,
    setLoading,
    setProcessoId,
    processoId,
    userId,

    // CORDENAÇÃO DE PÓS VENDA
    tableDataCoord,

    //MODAIS    
    setIsShownStatus,
    setIsShownRevisao,
    setIsShownNucleo,
    setLoadingModal,
    setIsShowChangeResponsavel,

    //Table Data
    tablePaged,

    //SIDESHEET
    setIsShownSide, setProcessSelect,
    processSelect, setSelectedIndex,

    //Filtros
    filtro, quantFiltro, setQuantFiltro,
    listaResponsaveis, listaGerente, listaClientes,
    listaStatusProcesso, listaEndereco,

    //Ordenação
    ordenacao,

    //Paginação
    pageNumber, setPageNumber,
    totalPaginas,
    totalProcessos,
    totalProcessosFiltrados,

    dadosProcesso, setDadosProcesso,
    setShowFieldsEscritura,
    setShowFieldsTaxas,

    setLoadingChecklist
  } = useContext(NegociacaoContext);

  useEffect(() => {
    quantidadeFiltros();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    tablePaged.forEach((processo, index) => {
      processo.status_visualizacao_atual ? classNovo[index] = "fw400" : classNovo[index] = "fw600"
    })
    setClassNovo([...classNovo]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePaged])

  async function openModal(processo, revisao) {
    setLoadingModal(true);
    if (revisao) setIsShownRevisao(true);
    else setIsShownStatus(true);

    setDadosProcesso(processo);

    if (dadosProcesso.status_processo_atual === 5) {
      setShowFieldsEscritura(true);
      setShowFieldsTaxas(false);
    }
    else if(dadosProcesso.status_processo_atual === 4 || dadosProcesso.status_processo_atual === 3){
      setShowFieldsTaxas(true);
      setShowFieldsEscritura(false);
    }
    else {
      setShowFieldsEscritura(false)
      setShowFieldsTaxas(false);
    }

    if (processo.id !== processSelect.id) {
      setProcessoId(processo.id);
      const dataProcess = await getProcessById(processo.id);
      setProcessSelect(dataProcess);
    }
    setProcessoId(processo.id);
    setLoadingModal(false);

    // REMOVO TAG NOVO E FW600 DOS CAMPOS DA TABLE
    if (!processo.status_visualizacao_atual && processo.responsavel_atual === userId) {
      await atualizarStatusVisualizacaoGerente(processo.progress_status_progresses_id);
      tablePaged.forEach((descobrirIndex, index) => {
        if (descobrirIndex.id === processo.id) classNovo[index] = "fw400"
      })
    }
  };

  const openModalNucleo = async (processo) => {
    setProcessoId(processo.id);
    setIsShownNucleo(true);

    // REMOVO TAG NOVO E FW600 DOS CAMPOS DA TABLE
    if (!processo.status_visualizacao_atual && processo.responsavel_atual === userId) {
      await atualizarStatusVisualizacaoGerente(processo.progress_status_progresses_id);
      tablePaged.forEach((descobrirIndex, index) => {
        if (descobrirIndex.id === processo.id) classNovo[index] = "fw400"
      })
    }
  };

  const openModalResponsavel = async (processo) => {
    setDadosProcesso(processo);
    if (processo.id !== processSelect.id) {
      setProcessoId(processo.id);
      const dataProcess = await getProcessById(processo.id);
      setProcessSelect(dataProcess);
    }
    setIsShowChangeResponsavel(true);
  };

  const sideFunction = async (id, index, tabIndex) => {
    if (!processoId || id !== processSelect.id || !(index >= 0)) {
      setProcessoId(id);
      if (tabIndex) setSelectedIndex(tabIndex);
      else setSelectedIndex(0);

      const dataProcess = await getProcessById(id);
      setProcessSelect(dataProcess);
    }
    setIsShownSide(true);

    // REMOVO TAG NOVO E FW600 DOS CAMPOS DA TABLE
    tablePaged.forEach(async (processo, index) => {
      if (!processo.status_visualizacao_atual && processo.responsavel_atual === Number(userId)) {
        if (processo.id === processoId) {
          await atualizarStatusVisualizacaoGerente(processo.progress_status_progresses_id);
          classNovo[index] = "fw400"
        }
      }
    })
  };

  const atualizarStatusVisualizacaoGerente = async (id_status_processo) => {
    Axios.post(app + 'atualizar_status_visualizacao_gerente', {
      'id': id_status_processo
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(res => {
        if (res.data.status && res.data.status === (401 || 498)) {
          // localStorage.clear();
          // history.push('/');
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  };

  const handleClearFilter = async (att) => {
    filtro.responsavel = '';
    filtro.status = '';
    filtro.gerente = '';
    filtro.cliente = '';
    filtro.endereco = '';
    filtro.prazo = '';
    filtro.forma_pagamento = '';
    //ordenacao.valor = '1';
    localStorage.removeItem('filtro_status');
    localStorage.removeItem('filtro_responsavel');
    localStorage.removeItem('filtro_gerente');
    localStorage.removeItem('filtro_cliente');
    localStorage.removeItem('filtro_endereco');
    localStorage.removeItem('filtro_prazo');
    localStorage.removeItem('filtro_forma_pagamento');
    //localStorage.removeItem('ordenacao');
    setQuantFiltro(0);
    if (att) handleFilter(1);
  };

  const handleFilter = async (page) => {
    setPageNumber(page);
    quantidadeFiltros();
    localStorage.setItem('page_number', page);
    getPaged(page);
  };

  const quantidadeFiltros = async () => {
    let filtrosSelecionados = 0;

    if (filtro.responsavel !== '') {
      filtrosSelecionados++;
    }
    if (filtro.status !== '') {
      filtrosSelecionados++;
    }
    if (filtro.gerente !== '') {
      filtrosSelecionados++;
    }
    if (filtro.cliente !== '') {
      filtrosSelecionados++;
    }
    if (filtro.endereco !== '') {
      filtrosSelecionados++;
    }
    if (filtro.forma_pagamento !== '') {
      filtrosSelecionados++;
    }
    if (filtro.prazo !== '') {
      filtrosSelecionados++;
    }
    setQuantFiltro(filtrosSelecionados);
  };

  //Filtro de logradouro
  function handleFilterEndereco(e) {
    filtro.endereco = e;
    localStorage.setItem('filtro_endereco', e);
    handleFilter(1);
  };
  // Filtro de Responsavel e de Status
  const handleSelect = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    localStorage.setItem('filtro_' + name, value);
    filtro[name] = value;
    if (name === 'mes_coordenacao') getListTableCoordenador(value);
    else handleFilter(1);
  };
  // Filtro de Gerente
  const handleCombobox = (e) => {
    if (e) {
      filtro.gerente = e;
      localStorage.setItem('filtro_gerente', JSON.stringify({ 'id': e.id, 'name': e.name }));
      handleFilter(1);
    }
  };

  // Filtro de Cliente
  const handleComboboxCliente = (e) => {
    if (e) {
      filtro.cliente = e;
      localStorage.setItem('filtro_cliente', JSON.stringify({ 'id': e.id, 'name': e.name }));
      handleFilter(1);
    }
  };

  const selectOrdenacao = [
    {
      value: '1',
      name: 'Todos'
    },
    {
      value: '2',
      name: 'Prazo: maior (tempo sobrando)'
    },
    {
      value: '3',
      name: 'Prazo: menor (tempo sobrando)'
    },
    {
      value: '8',
      name: 'Data de entrada: Crescente'
    },
    {
      value: '9',
      name: 'Data de entrada: Decrescente'
    },
  ];

  const handleSelectOrdenacao = (e) => {
    const value = e.target.value;
    localStorage.setItem('ordenacao', value);
    ordenacao.valor = value;
    handleFilter(1);
  };

  const coordColumns = [
    { id: "", label: "RESPONSÁVEL", name: "responsavel", minWidth: '126px', textAlign: 'center' },
    { id: "", label: "TOTAL", name: "total", minWidth: '93px', textAlign: 'center' },
    { id: 1, label: "À VISTA", name: "a_vista", minWidth: '93px', textAlign: 'center' },
    { id: 2, label: "FINANCIAMENTO", name: "financiado", minWidth: '115px', textAlign: 'center' },
    { id: 3, label: "FGTS", name: "fgts", minWidth: '93px', textAlign: 'center' },
    { id: 4, label: "CONSÓRCIO", name: "consorcio", minWidth: '93px', textAlign: 'center' },
    { id: 6, label: "EM DIA", name: "em_dia", minWidth: '93px', textAlign: 'center' },
    { id: 6, label: "ALERTA", name: "alerta", minWidth: '93px', textAlign: 'center' },
    { id: 6, label: "ATRASADOS", name: "atrasado", minWidth: '93px', textAlign: 'center' },
  ]; // IDS SERVEM APENAS PARA INFORMAR A FUNÇÃO DE FILTRO

  const collapse = {
    close: {
      overflow: 'hidden',
      maxHeight: '0px',
      transition: 'max-height 1s cubic-bezier(.4,0,.2,1) 0ms'
    },
    open: {
      overflow: 'hidden',
      maxHeight: 55 + 32 + 19 + 42 * tableDataCoord.length + 'px',
      transition: 'max-height 1.5s cubic-bezier(.4,0,.2,1) 0ms'
    }
  }; // 55 = HEADER DA TABELA, 32 TAMANHO DO FILTRO MES, 19 MARGIN DO FILTRO MES + LINHA, 42 CADA LINHA DA TABELA

  const listFormasPagamento = [
    { label: "À vista", value: "1" },
    { label: "Financiamento", value: "2" },
    { label: "FGTS", value: "3" },
    { label: "Consórcio", value: "4" },
    { label: "Parcelamento", value: "5" },
    { label: "PIX", value: "6" },
  ];

  const handleFilterByTableCoord = (resp, column) => {
    handleClearFilter(false);
    filtro.responsavel = resp.id;
    localStorage.setItem('filtro_filtro_responsavel', resp.id);
    if (column.id < 5) {
      filtro.forma_pagamento = column.id;
      localStorage.setItem('filtro_forma_pagamento', column.id);
    } else if (column.id === 6) {
      filtro.prazo = column.name;
      localStorage.setItem('filtro_prazo', column.name);
    }
    handleFilter(1)
  };

  return (
    <Pane>
      <div>
        <div className='flex itemsCenter gap13 mt33 mb22'>
          <h2 className="fw600" >Gestão dos processos</h2>
          <Button appearance="minimal" iconAfter={collapseTableCoord ? <ChevronDownIcon /> : <ChevronUpIcon />} onClick={() => setCollapseTableCoord((old) => !old)}>Recolher</Button>
        </div>
        <Pane style={collapseTableCoord ? collapse.open : collapse.close} >
          <div className="label-align-filter-order mb17">
            <FilterIcon color={'#8F95B2'} /> <span className="label-filter-order"> Filtrar por: </span>
            <div className='filter-field ml10'>
              <Select
                name='mes_coordenacao'
                value={filtro.mes_coordenacao}
                onChange={(e) => handleSelect(e)}
                id="filtro-mes_coordenacao"
                className={"filtro-select bgWhite"}
              >
                <option value="" selected hidden>Mês</option>
                {listMonths.map((mes) =>
                  <option key={mes.value} value={mes.value}>{mes.label}</option>
                )}
              </Select>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ maxWidth: '13vw' }}>
              <div className='Rectangle35 Green'></div>
              <Table.Body>
                <Table.Head className="table-coordpos">
                  <Table.TextCell textAlign='center'>
                    EM ANDAMENTO
                  </Table.TextCell>
                </Table.Head>
                {
                  //Exibe os dados carregados
                  // !loading &&
                  //Table de processos em andamento
                  <Table.Body>
                    <Table.Row height={42 * tableDataCoord.length} className="coordpos-andamento" >
                      <Table.TextCell>
                        {tableDataCoord[0]?.total_processos}
                      </Table.TextCell>
                    </Table.Row>

                  </Table.Body>
                }

              </Table.Body>
            </div>
            <div>
              <div className='Rectangle35 Green'></div>
              <Table.Body style={collapseTableCoord ? collapse.open : collapse.close} >
                <Table.Head paddingRight={0} >
                  {coordColumns.map((colum, index) => (
                    <Table.TextCell key={index} minWidth={colum.minWidth} className={`coordpos header ${colum.name}`} >
                      {colum.label}
                    </Table.TextCell>
                  ))}
                </Table.Head>
                {
                  //Exibe os dados carregados
                  // !loading &&
                  //Table de processos em andamento
                  <Table.Body>
                    {/* MAP DE LINHAS */}
                    {tableDataCoord.map((responsavel, index) =>
                      <Table.Row key={index} height={42}>
                        {/* MAP DE COLUNAS */}
                        {coordColumns.map((colum, index) => (
                          <Table.TextCell onClick={() => handleFilterByTableCoord(responsavel, colum)} key={colum.name} minWidth={colum.minWidth} className={`coordpos body ${colum.name}`} textAlign={colum.textAlign}>
                            <div >
                              {index === 0 && <Avatar className="avatar" name={responsavel[colum.name]} size={24} marginRight={16} />}
                              {responsavel[colum.name]}
                            </div>
                          </Table.TextCell>
                        ))}

                      </Table.Row>
                    )}
                  </Table.Body>
                }

              </Table.Body>
            </div>
          </div>
        </Pane>
      </div>

      <h2 className="subtitle subtitle-table" style={{ padding: "0px" }}>
        Processos {
          totalProcessos > 0 &&
          <Tooltip
            content="Numero total de processos"
          >
            <Badge color="blue" marginLeft={10}>
              {totalProcessos}
            </Badge>
          </Tooltip>
        }
      </h2>

      <div className='filter-field filter-position'>
        <Autocomplete
          onChange={(e) => (handleFilterEndereco(e))}
          items={listaEndereco}
          selectedItem={filtro.endereco}
          allowOtherValues={true}
          width={558}
        >
          {props => {
            const { getInputProps, getRef, inputValue, openMenu } = props
            return (
              <TextInput
                placeholder="🔍︎ Endereço"
                value={inputValue}
                ref={getRef}
                width={558}
                {...getInputProps({
                  onClick: () => {
                    openMenu()
                  }
                })}
              />
            )
          }}
        </Autocomplete>
      </div>
      <div className='filter-table space-between'>
        <div className="flex end">
          <div>
            <div className="label-align-filter-order mw140 mt18 mb10">
              <FilterIcon color={'#8F95B2'} /> <span className="label-filter-order"> Filtrar por: </span>
            </div>
            <div className='filter-field'>
              <Select
                label=""
                name='responsavel'
                value={filtro.responsavel}
                onChange={(e) => handleSelect(e)}
                id="filtro-responsavel"
                className={filtro.responsavel === 0 ? "filtro-select color-select-filter-hidden" : "filtro-select color-select-filter"}
              >
                <option value="" selected hidden>Responsável</option>
                {listaResponsaveis.map((responsavel) =>
                  <option key={responsavel.id} value={responsavel.id}>{responsavel.name}</option>
                )}
              </Select>
            </div>
          </div>

          <div className='filter-field filter-position'>
            <Select
              marginTop={0}
              marginLeft={10}
              label=""
              name='status'
              value={filtro.status}
              minWidth={135}
              onChange={(e) => handleSelect(e)}
              id="filtro-status"
              className={filtro.status === "" ? "filtro-select color-select-filter-hidden" : "filtro-select color-select-filter"}
            >
              <option value="" hidden selected >Status</option>
              {listaStatusProcesso.map((valor) =>
                <option key={valor.id} value={valor.id}>{valor.status}</option>
              )}
            </Select>
          </div>
          <div className='filter-field filter-position'>
            <Combobox
              openOnFocus
              name='gerente'
              items={listaGerente}
              selectedItem={filtro.gerente}
              itemToString={item => (item ? item.name : '')}
              placeholder="🔍︎ Gerente"
              onChange={(e) => handleCombobox(e)}
              style={{ marginLeft: '0' }}
              id="filtro-gerente"
            // className={filtro.gerente === "" ? "filtro-select color-select-filter-hidden" : "filtro-select color-select-filter"}
            />
          </div>
          <div className='filter-field filter-position'>
            <Select
              label=""
              marginRight={10}
              name='forma_pagamento'
              value={filtro.forma_pagamento}
              minWidth={169}
              onChange={(e) => handleSelect(e)}
              id="forma_pagamento"
              style={{ marginLeft: '0' }}
              className={filtro.forma_pagamento === "" ? "filtro-select color-select-filter-hidden" : "filtro-select color-select-filter"}
            >
              <option value="" hidden selected >Formas de pagamento</option>
              {listFormasPagamento.map((valor) =>
                <option key={Number(valor.value)} value={valor.value}>{valor.label}</option>
              )}
            </Select>
          </div>
          <div className='filter-field filter-position'>
            <Combobox
              openOnFocus
              name='cliente'
              items={listaClientes}
              selectedItem={filtro.cliente}
              itemToString={item => (item ? item.name : '')}
              placeholder="🔍︎ Clientes"
              onChange={(e) => handleComboboxCliente(e)}
              id="filtro-cliente"
            // className={filtro.gerente === "" ? "filtro-select color-select-filter-hidden" : "filtro-select color-select-filter"}
            />
          </div>


        </div>
        <div >
          <div className="label-align-filter-order">
            <ChangesIcon color={'#8F95B2'} /> <span className="label-filter-order"> Ordenar por: </span>
          </div>
          <div className='filter-field'>
            {/*Ordenação*/}
            <Select
              width={230}
              label=""
              name='ordenacao'
              value={ordenacao.valor}
              onChange={(e) => handleSelectOrdenacao(e)}
              id="ordenacao"
              className={ordenacao === "" ? "filtro-select color-select-filter-hidden" : "filtro-select color-select-filter"}
            >
              {selectOrdenacao.map((ordenar, index) => <option key={index} value={ordenar.value}>{ordenar.name}</option>)}
            </Select>
          </div>
        </div>

      </div>

      <div className="filter-info">
        <div>
          {quantFiltro > 0 &&
            <p>
              <FilterIcon className="icon-filter" />
              {quantFiltro} {quantFiltro === 1 ? 'filtro aplicado. ' : 'filtros aplicados. '}
              {tablePaged.length} / {totalProcessosFiltrados} {totalProcessosFiltrados === 1 ? 'processo visível' : 'processos visíveis'}.
              <span onClick={() => handleClearFilter(true)}> Limpar filtro</span>
            </p>
          }
        </div>
        <div className='filter-table filter-table-nav'>
          <Pagination
            marginTop={10}
            marginBottom={10}
            page={pageNumber}
            totalPages={totalPaginas}
            onPageChange={(e) => !loading ? handleFilter(e) : ''}
            onNextPage={() => handleFilter(pageNumber + 1)}
            onPreviousPage={() => handleFilter(pageNumber - 1)}
          />
        </div>
      </div>
      <div className='Rectangle35 Blue'></div>
      <Table.Body className="table">
        <Table.Head className="f10">
          <Table.TextCell flexBasis={70} className="table">
            Responsável
          </Table.TextCell>
          <Table.TextCell className="table minW70_30vw">
            Endereço
          </Table.TextCell>

          <Table.TextCell className="table bg-table-blue" flexBasis={150}>
            Status
          </Table.TextCell>

          <Table.TextCell className="table bg-table-blue pl0" flexBasis={90}>
            Prazo<br /> do Status
          </Table.TextCell>

          <Table.TextCell className="table" flexBasis={140}>
            Gerente
          </Table.TextCell>

          <Table.TextCell className="table" flexBasis={105}>
            Data de<br />  Entrada
          </Table.TextCell>

          <Table.TextCell className="table pr17" flexBasis={70}>
            Hora de<br /> Entrada
          </Table.TextCell>

          <Table.TextCell className="table pr17" paddingLeft={0}  flexBasis={130}>
            Tipo de<br /> Pagamento
          </Table.TextCell>

          <Table.TextCell className="table" flexBasis={114}>
            {/* COLUNA NOVO */}
          </Table.TextCell>

        </Table.Head>
        {
          //Enquanto não carregar os dados exibe um Spinner
          loading &&
          <div className='backgroundLoadingDocs'><Lottie options={defaultOptions} height={200} width={400} /></div>
        }
        {
          //Exibe os dados carregados
          !loading &&
          //Table de processos em andamento
          <>
            <Table.Body>
              {tablePaged.map((processo, index) =>
                <Table.Row key={index} isSelectable height="auto" minHeight="64px">
                  <Table.TextCell flexBasis={70} className="table" onClick={() => sideFunction(processo.id, index)}>
                    <Avatar name={processo.responsavel_atual_nome} size={40} marginRight={16} />
                  </Table.TextCell>

                  <Table.TextCell className="table table-cell minW70_30vw" onClick={() => sideFunction(processo.id, index)}>
                    <div
                      className={classNovo[index]}
                    >
                      <Tooltip
                        content={processo.logradouro + ', ' + processo.numero + (processo?.unidade ? ' / ' + processo.unidade : '')}
                        position={Position.BOTTOM}
                        isShown={processo.logradouro.length < 38 ? false : ""}
                      >
                        <div className="dot-text">{processo.logradouro}, {processo.numero} {processo.unidade ? '/ ' + processo.unidade : ''}</div>
                      </Tooltip>
                    </div>
                  </Table.TextCell>

                  <Table.TextCell flexBasis={150} className="table table-cell bg-table-blue">
                    <div
                      className="content-tag"
                      style={{ cursor: !processo.gerente_atual_nome ? 'default' : 'pointer', background: '#fff' }}
                      onClick={(e) => !processo.gerente_atual_nome ? (e) : openModal(processo)}
                    >
                      <span className="circleIcon"></span> {processo.status_nome}
                    </div>
                  </Table.TextCell>

                  <Table.TextCell flexBasis={90} className="table table-cell bg-table-blue pl0">
                    {processo.data_expiracao_status}
                  </Table.TextCell>

                  {/* EXIBE CAMBO NOME DO GERENTE */}
                  <Table.TextCell className="table table-cell" flexBasis={140} onClick={() => sideFunction(processo.id, index)} >
                    <div>
                      {processo.gerente_atual_nome
                        ? <div className={classNovo[index]}>
                          <Tooltip content={processo.gerente_atual_nome} isShown={processo.gerente_atual_nome.length < 15 ? false : ""} position={Position.BOTTOM}>
                            <div className="dot-text">{processo.gerente_atual_nome}</div>
                          </Tooltip>
                        </div>
                        : <Button
                          // onClick={() => setSelectedIndex(1)}
                          marginRight={16}
                          className="btn-adicionar"
                        >
                          + Adicionar
                        </Button>
                      }
                    </div>
                  </Table.TextCell>

                  {/* EXIBE CAMBO DATA DE CRIAÇÃO */}
                  <Table.TextCell className="table table-cell" flexBasis={105} onClick={() => sideFunction(processo.id, index)}>
                    <div
                      className={classNovo[index]}
                    >
                      {processo.data?.split(' ')[0]}
                    </div>
                  </Table.TextCell>

                  {/* EXIBE CAMBO HORA DE CRIAÇÃO */}
                  <Table.TextCell className="table table-cell pr17" flexBasis={70} onClick={() => sideFunction(processo.id, index)}>
                    <div
                      className={classNovo[index]}
                    >
                      {processo.data?.split(' ')[1]}
                    </div>
                  </Table.TextCell>

                  {/* EXIBE CAMBO DE TIPO PAGAMENTO */}
                  <Table.TextCell flexBasis={130} paddingRight={0} paddingLeft={0} className="table pt5" onClick={() => sideFunction(processo.id, index)}>
                    <Pane>
                      {processo.forma_pagamento.map(value =>
                        <div key={value} className='pb5'>
                          <Badge color='blue' >{value || "SEM CADASTRO"}</Badge>
                        </div>)
                      }
                    </Pane>
                  </Table.TextCell>

                  {/* EXIBE CAMBO DE TAGS */}
                  <Table.TextCell flexBasis={114} className="table" onClick={() => sideFunction(processo.id, index)}>
                    <Pane>
                      {classNovo[index] === "fw600"
                        ? processo.status_anterior === 22
                          ? <Badge color="red" style={{ marginLeft: /*'40px'*/ '0' }}>DEVOLUÇÃO</Badge>
                          : <Badge color="green" style={{ marginLeft: /*'40px'*/ '0' }}>NOVO</Badge>
                        : processo.status_processo_atual === 7
                          ? <Badge color="neutral" style={{ marginLeft: /*'15px'*/ '0' }}>Finalizados</Badge>
                          : ''
                      }
                    </Pane>
                  </Table.TextCell>

                  {/* EXIBE CAMBO DE OPTIONS */}
                  <Table.TextCell flexBasis={135} className='pl0'>
                    <Popover
                      position={Position.BOTTOM_RIGHT}
                      content={
                        <Menu>
                          <Menu.Group title={<span style={{ fontSize: '10px' }}>OPÇÕES</span>}>
                            <Menu.Item
                              icon={!processo.gerente_atual_nome ? LockIcon : ShareIcon}
                              onSelect={() => openModal(processo)}
                              disabled={!processo.gerente_atual_nome}
                              intent={!processo.gerente_atual_nome ? "danger" : "blue400"}
                            >
                              <span>Compartilhar status</span>
                            </Menu.Item>

                            <Menu.Item
                              icon={InfoSignIcon}
                              onSelect={() => openModal(processo, true)}
                              intent="blue400"
                            >
                              <span>Devolver venda</span>
                            </Menu.Item>

                            <Menu.Item
                              icon={DocumentOpenIcon}
                              onSelect={(e) => openModalNucleo(processo)}
                              intent="blue400"
                            >
                              <span>Solicitar núcleo</span>
                            </Menu.Item>

                            <Menu.Item
                              icon={ChangesIcon}
                              onSelect={(e) => openModalResponsavel(processo)}
                              intent="blue400"
                            >
                              <span>Trocar responsável</span>
                            </Menu.Item>
                          </Menu.Group>
                        </Menu>
                      }
                    >
                      <Button appearance="primary" marginRight={11}>
                        <DotIcon color='white' size={10} marginRight={-5} />
                        <DotIcon color='white' size={10} marginRight={-5} />
                        <DotIcon color='white' size={10} />
                        Opções
                      </Button>
                    </Popover>
                  </Table.TextCell>
                </Table.Row>
              )}
            </Table.Body>
            {/*Table de processos finalizados*/}
          </>
        }
      </Table.Body>

      <DialogShare refreshTable={getPaged} />
      <DialogRevisao refreshTable={getPaged} />
      <DialogSolicitarNucleo refreshTable={getPaged} />
      <DialogNucleoFeedBack openSideSheet={sideFunction} />
      <DialogRevisaoFeedBack />
      <DialogFeedBack />
      <DialogResponsavel refreshTable={getPaged} />
      <SideSheetView refreshProcess={sideFunction} />
      <ChangeLog data="PósVenda" />

    </Pane>
  )
}