import Routes from './routes';
//Components
import Axios from 'axios';
import { toaster } from 'evergreen-ui';

//Produção
import { hotjar } from 'react-hotjar';
hotjar.initialize(2869539, 6);

function defineInterceptor() {

  Axios.interceptors.response.use(response => {
    // console.log('ok', response);
    if (response !== undefined && response?.data.status === 200 && response?.data.success === false) {
      const originalReq = response.config;
      // console.log("novo token = ", response?.data.token);
      localStorage.setItem('token', response?.data.token);
      originalReq._retry = true;
      originalReq.headers["Authorization"] = `Bearer ${response?.data.token}`;

      return Axios(originalReq);
    }
    return response
  }, err => {
    
    if (!err.response) return console.log("ERROR NA API");
    else if (err.response && err.response?.status === 500 && err.response?.data?.message === "Token has expired and can no longer be refreshed") {
      //localStorage.clear();
      console.log("jogando para entrada");
      return window.location.href = '/';
    } else {
      //
      console.log("algo esta errado");
      if (err.response && err.response?.data?.message && err.response?.data?.message !== "The token has been blacklisted" && (err.response?.data?.message !== "Imovel nao encontrado!")) {
        console.log(err.response?.data.message);
        toaster.danger(err.response?.data.message, {
          duration: 10,
        });
      }
      else if (err.response?.data.error) {
        toaster.danger(err.response?.data.error, {
          duration: 10,
        });
      }
    }
  })
}

function App() {
  defineInterceptor();

  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
