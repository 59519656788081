import React, { useState, useContext, useRef, useEffect } from 'react';
import { Dialog, Select, Textarea, TextInputField, Label, Pane, SelectField, toaster, Spinner } from 'evergreen-ui';
import Axios from 'axios'
import Serve from '../../../../app/serve';
import NegociacaoContext from '../../../../context/NegociacaoContext';
import { useHistory } from 'react-router-dom';
import dateMask from '../../../../components/dateMask';
import horaMask from '../../../../components/horaMask';
import PrevisaoComissao from '../components/Previsao_Comissao';
import { useForm } from 'react-hook-form';
import updatePagamentoComissao from '../../../../apis/updatePagamentoComissao';

const errorTextInput = "Obrigatório.";

const inputGreen = {
    borderColor: '#429777',
    background: '#F5FBF8'
};

const inputDefault = {
    borderColor: '#ADC2FF',
    background: '#fff'
};

const inputError = {
    borderColor: '#FF0000',
    background: '#fff'
};

export default function DialogShare({ refreshTable }) {
    const history = useHistory();
    const app = Serve();
    const [token] = useState(localStorage.getItem('token'));

    const {
        userId,
        loading,
        setLoading,
        pageNumber,
        processSelect,
        listaStatusProcesso,
        isShownStatus, setIsShownStatus,
        setIsShownFeedBack,
        tablePaged,
        showFieldsEscritura, setShowFieldsEscritura,
        showFieldsTaxas, setShowFieldsTaxas,
        dadosProcesso,
        setStatusCompartilhado,
        loadingModal,
    } = useContext(NegociacaoContext);

    // console.log('Processo: ', processSelect);
    // console.log('tablePaged: ', tablePaged);
    // console.log('Dados Processo: ', dadosProcesso);
    const parcelasEmpresa = processSelect?.imovel?.comissao?.parcelas_empresa || [];
    const comissao = processSelect?.imovel?.comissao;
    const [componentVendedorResp, setComponentVendedorResp] = useState(false);
    const idStatusProcessoModal = dadosProcesso?.status_processo_atual;
    const processoModal = dadosProcesso?.logradouro + ', ' + dadosProcesso?.numero + ' ' + (dadosProcesso?.unidade || '') + '- ' + dadosProcesso?.bairro;
    const [listSelectStatus, setListSelectStatus] = useState([]);
    const [loadingResponsaveis, setLoadingResponsaveis] = useState(false);
    const [responsaveis, setResponsaveis] = useState();
    
    const messageRef = useRef();
    const statusRef = useRef();

    // Cantinho do React Hook Form
    const { register, handleSubmit, setValue, watch, clearErrors, reset, unregister, formState: { errors } } = useForm({
        defaultValues: {
            responsaveis: { vendedor: '', comprador: '' },
            tipo_pagamento: 'integral',
            arrayPayers: [{ id: '', parcela: 1, user_id: '', porcentagem: '', valor: '' }],
            observacoes_pagamento_comissao: '',
            mensagem: ''
        }
    });

    function resetDataVendedorResp() {
        setValue('tipo_pagamento', comissao?.comissao || '')
        setValue('observacoes_pagamento_comissao', comissao?.observacao_responsavel_pagamento || '')

        if (comissao?.vendedor_responsavel_pagamento?.[0]) {
            setValue('arrayPayers', (comissao?.vendedor_responsavel_pagamento?.map((vendedor, index) => ({
                id: vendedor.id,
                parcela: vendedor.numero_parcela,
                user_id: vendedor.usuario_id,
                porcentagem: '', // PORCENTAGEM DESATIVADA
                valor: vendedor.parcela_valor
            }))))
        } else {
            if (watch('tipo_pagamento') === 'partes') {
                setValue('arrayPayers', comissao?.parcelas_empresa?.map((parcela, index) => ({
                    id: '',
                    parcela: index + 1,
                    user_id: '',
                    porcentagem: '', // PORCENTAGEM DESATIVADA
                    valor: parcela.valor_parcela
                })))
            } else {
                setValue('arrayPayers', [{
                    id: '',
                    parcela: 1,
                    user_id: '',
                    porcentagem: '', // PORCENTAGEM DESATIVADA
                    valor: comissao?.valor_comissao_liquida
                }])
            }
        }
    };

    useEffect(() => {
        if (processSelect) {
            resetDataVendedorResp();
            setListSelectStatus(processSelect.lista_status);
        } else {
            setListSelectStatus(listaStatusProcesso);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processSelect]);


    useEffect(() => {
        setResponsaveis({
            vendedores: processSelect?.imovel?.vendedores?.data?.map((user) => ({ id: user.id, name: user.name || user.nome_fantasia, value: '' })),
            compradores: processSelect?.imovel?.compradores?.data?.map((user) => ({ id: user.id, name: user.name || user.nome_fantasia, value: '' })),
        })
    }, [processSelect])

    useEffect(() => {
        const carregarEscritura = (e) => {
            statusRef.current = dadosProcesso?.status_processo_atual;

            if (dadosProcesso?.status_processo_atual === 5) {
                setShowFieldsEscritura(true);
                setShowFieldsTaxas(false);
                setValue('dataEscritura', dadosProcesso?.dado_escritura[0]?.data_escritura);
                setValue('horaEscritura', dadosProcesso?.dado_escritura[0]?.hora_escritura);
                setValue('enderecoEscritura', dadosProcesso?.dado_escritura[0]?.logradouro);
                setValue('numeroEscritura', dadosProcesso?.dado_escritura[0]?.numero);
                setValue('unidadeEscritura', dadosProcesso?.dado_escritura[0]?.unidade);
                setValue('complementoEscritura', dadosProcesso?.dado_escritura[0]?.complemento);
            }
            else if(dadosProcesso?.status_processo_atual === 4 || dadosProcesso?.status_processo_atual === 3){
                setShowFieldsTaxas(true);
                setShowFieldsEscritura(false);
                setValue('dataEscritura', dadosProcesso?.data_expiracao_status);
            }
            else {
                setShowFieldsEscritura(false);
                setShowFieldsTaxas(false);
            }
            // else {
            //     setShowFieldsEscritura(false);
            // }
        }
        carregarEscritura();
    }, [dadosProcesso?.status_processo_atual, setShowFieldsEscritura, setShowFieldsTaxas, setValue, dadosProcesso?.dado_escritura]);

    const handleSaveVendedorResp = async (e) => {
        const data = new FormData();
        data.append('responsavel_id', localStorage.getItem('usuario_id'));
        data.append('observacoes', localStorage.getItem('usuario_id'));

        e?.arrayPayers?.forEach(payers => {
            data.append('processo_id[]', processSelect.id);
            data.append('parcela_id[]', payers.id);
            data.append('usuario_id[]', payers.user_id);
            data.append('parcela_valor[]', payers.valor);
            data.append('numero_parcela[]', payers.parcela);
        })

        const res = await updatePagamentoComissao(data);
        if (res.save) toaster.success(res.mensagem_pagamento);
        else toaster.danger(res.mensagem_pagamento);
    };

    function handleSelectStatus(e) {
        const value = e.target.value;
        // CRIEI UM ARRAY PARA CONVERTER O PERIDO DE PAGAMENTO NO MESMO NUMERO DO STATUS 
        const arrayConvertPeriodoxStatus = [
            { periodo: '2', status: '3' }, // CERTIDAO
            { periodo: '3', status: '5' }, // ESCRITURA
            { periodo: '5', status: '6' }  // REGISTRO
        ];
        setComponentVendedorResp(parcelasEmpresa.some(parcela =>
            arrayConvertPeriodoxStatus.find(array => parcela.periodo_pagamento === array.periodo)?.status === value
        ));

        statusRef.current = value;
        if (value > 2 && idStatusProcessoModal === 2) setLoadingResponsaveis(true);
        else setLoadingResponsaveis(false);

        setStatusCompartilhado(value);

        if (value === '5') {
            setShowFieldsTaxas(false);
            setShowFieldsEscritura(true)
            setValue('dataEscritura', dadosProcesso?.dado_escritura[0]?.data_escritura);
            setValue('horaEscritura', dadosProcesso?.dado_escritura[0]?.hora_escritura);
            setValue('enderecoEscritura', dadosProcesso?.dado_escritura[0]?.logradouro);
            setValue('numeroEscritura', dadosProcesso?.dado_escritura[0]?.numero);
            setValue('unidadeEscritura', dadosProcesso?.dado_escritura[0]?.unidade);
            setValue('complementoEscritura', dadosProcesso?.dado_escritura[0]?.complemento);
        }
        else if(value === '4' || value === '3'){
            setShowFieldsTaxas(true);
            setShowFieldsEscritura(false);
            //setValue('dataEscritura', dadosProcesso?.data_expiracao_status);
        }
        else {
            setShowFieldsTaxas(false);
            setShowFieldsEscritura(false)
        }

        //Limpando campos
        clearErrors('dataEscritura');
        clearErrors('horaEscritura');
        clearErrors('enderecoEscritura');
        clearErrors('numeroEscritura');
        clearErrors('unidadeEscritura');
        clearErrors('complementoEscritura');
    };

    // console.log(showFieldsEscritura)

    const handleFieldEscritura = (e, type) => {
        // console.log(e.target.value);

        if (type === 'data') {
            let dataEscritura = document.querySelector('.data-escritura');
            dateMask(dataEscritura.value);
            dataEscritura.value = dateMask(dataEscritura.value);
            // console.log(dataEscritura.value.length)

            if (dataEscritura.value.length === 10) {
                dataEscritura.style.cssText = 'border-color: #429777; background: #F5FBF8';
            }
            else {
                if (errors.dataEscritura) {
                    dataEscritura.style.cssText = 'border-color: #ff0000; background: #fff';
                }
                else {
                    dataEscritura.style.cssText = 'border-color: #ADC2FF; background: #fff';
                }
            }
        }
        else if (type === 'hora') {
            let horaEscritura = document.querySelector('.hora-escritura');
            horaMask(horaEscritura.value);
            horaEscritura.value = horaMask(horaEscritura.value);
            // console.log(horaEscritura.value.length);

            if (horaEscritura.value.length === 5) {
                horaEscritura.style.cssText = 'border-color: #429777; background: #F5FBF8';
            }
            else {
                horaEscritura.style.cssText = 'border-color: #ADC2FF; background: #fff';
            }
        }

        else if (type === 'endereco') {
            let enderecoEscritura = document.querySelector('.endereco-escritura');

            if (enderecoEscritura.value.length > 0) {
                enderecoEscritura.style.cssText = 'border-color: #429777; background: #F5FBF8';
            }
            else {
                enderecoEscritura.style.cssText = 'border-color: #ADC2FF; background: #fff';
            }
        }

        else if (type === 'numero') {
            let numeroEscritura = document.querySelector('.numero-escritura');

            if (numeroEscritura.value.length > 0) {
                numeroEscritura.style.cssText = 'border-color: #429777; background: #F5FBF8';
            }
            else {
                numeroEscritura.style.cssText = 'border-color: #ADC2FF; background: #fff';
            }
        }

        else if (type === 'unidade') {
            let unidadeEscritura = document.querySelector('.unidade-escritura');

            if (unidadeEscritura.value.length > 0) {
                unidadeEscritura.style.cssText = 'border-color: #429777; background: #F5FBF8';
            }
            else {
                unidadeEscritura.style.cssText = 'border-color: #ADC2FF; background: #fff';
            }
        }

        else if (type === 'complemento') {
            let complementoEscritura = document.querySelector('.complemento-escritura');

            if (complementoEscritura.value.length > 0) {
                complementoEscritura.style.cssText = 'border-color: #429777; background: #F5FBF8';
            }
            else {
                complementoEscritura.style.cssText = 'border-color: #ADC2FF; background: #fff';
            }
        }
    }

    function handleMessage(e) {
        e.preventDefault();
        messageRef.current = e.target.value;

        const field = document.querySelector('.observacao');

        field.value.length === 0
            ? field.style.cssText = 'border-color: #ADC2FF; background: #fff'
            : field.style.cssText = 'border-color: #429777; background: #F5FBF8';
    };

    const handleConfirm = async (e, close) => {
        setLoading(true);
        // console.log(typeof (statusRef.current));        
        if (componentVendedorResp) handleSaveVendedorResp(e);

        await Axios.post(app + 'salvar_historico_processo', {
            "responsavel_alteracao_id": userId,
            "status_processo_id": statusRef.current,
            "processo_id": dadosProcesso.id,
            "processo_nome": processoModal,
            "data_escritura": e.dataEscritura, //dataEscrituraRef.current,
            "hora_escritura": e.horaEscritura, //horaEscrituraRef.current,
            "endereco_escritura": e.enderecoEscritura, //enderecoEscrituraRef.current,
            "numero_escritura": e.numeroEscritura, //numeroEscrituraRef.current,
            "unidade_escritura": e.unidadeEscritura, //unidadeEscrituraRef.current,
            "complemento_escritura": e.complementoEscritura, //complementoEscrituraRef.current,
            "mensagem": e.mensagem,
            "responsavel_vendedor": e.responsaveis.vendedor,
            "responsavel_comprador": e.responsaveis.comprador,
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {
            if (res.data.status && res.data.status === (401 || 498)) {
                localStorage.clear();
                history.push('/');
            }
            close();
            setStatusCompartilhado(statusRef.current.toString());
            setIsShownFeedBack(true);
            refreshTable(pageNumber);
        }).catch(function (error) {
            console.log(error);
        }).then(() => {
            setLoading(false);
        })

        setShowFieldsEscritura(false);
    };

    const handleClose = (e) => {
        clearErrors('dataEscritura');
        clearErrors('horaEscritura');
        clearErrors('enderecoEscritura');
        clearErrors('numeroEscritura');
        clearErrors('unidadeEscritura');
        clearErrors('complementoEscritura');
        setShowFieldsTaxas(false);
        setShowFieldsEscritura(false);
        setIsShownStatus(false);
        setLoadingResponsaveis(false);
        setComponentVendedorResp(false);
        setIsShownStatus(false);
        reset();
    };

    return (
        <Dialog
            isShown={isShownStatus}
            //title={}
            onCloseComplete={(e) => handleClose(e)}
            confirmLabel="Compartilhar"
            cancelLabel="Voltar"
            //onConfirm={close => handleConfirm(close)}
            onConfirm={handleSubmit((e, close) =>
                handleConfirm(e, close)
            )}
            isConfirmLoading={loading}
            isCancelLoading={loading}
        >
            <h2 className="title-dialog">Compartilhar status<br /> da negociação</h2>
            <div className="dialog-content dialog-content-rua">{processoModal}</div>
            <div className="dialog-content dialog-content-info">Selecione o novo status da negociação abaixo e, se necessário, escreva uma mensagem para o gerente responsável...</div>

            {loadingModal
                ? <Pane height={100} className='backgroundLoadingDocs'><Spinner size={40} /></Pane>

                :
                <div className="form-dialog">
                    <Label htmlFor="textarea-2" className="label-dialog" marginBottom={10} display="block">
                        Tipo de Status
                    </Label>
                    <Select
                        width={240}
                        defaultValue={idStatusProcessoModal}
                        preventBodyScrolling={true}
                        onChange={(e) => handleSelectStatus(e)}
                        disabled={loading}
                        className={"select-status inputGreenSelect"}
                    >
                        {listSelectStatus.map(option =>
                            <option key={option.id} value={option.id}>{option.status}</option>
                        )}
                    </Select>
                    {showFieldsTaxas === true
                        ?
                        <div style={{ marginTop: '20px' }}>
                            <div className="rowForm">
                                <TextInputField
                                    width={112}
                                    label="Data Vencimento"
                                    description=""
                                    placeholder="DD/MM/AAAA"
                                    className={"data-escritura"}
                                    maxLength={10}
                                    required
                                    defaultValue={dadosProcesso?.data_expiracao_status}
                                    {...register('dataEscritura', {
                                        required: errorTextInput,
                                        validate: (value) => {
                                            if (value.length < 10) {
                                                return "Data inválida";
                                            }
                                        },
                                        onChange: (e) => handleFieldEscritura(e, 'data')
                                    })}
                                    validationMessage={
                                        errors.dataEscritura && errors.dataEscritura.message
                                    }
                                    //onChange={(e) => handleFieldEscritura(e, 'data')}
                                    style={errors.dataEscritura && errors.dataEscritura.message ? inputError : !dadosProcesso?.data_expiracao_status ? inputDefault : dadosProcesso?.data_expiracao_status ? inputGreen : inputDefault}
                                />
                            </div>
                        </div>
                        :
                        ''
                    }

                    {loadingResponsaveis &&
                        <Pane>
                            <div className="dialog-content dialog-content-rua mt20">Selecione o vendedor e comprador responsável</div>
                            <div className="dialog-content dialog-content-info">A equipe de pós-venda precisa dessa informação para realizar cobranças. </div>
                            <div className='flex'>
                                <SelectField
                                    label='Vendedor'
                                    width={200}
                                    value={watch('responsaveis.vendedor')}
                                    onChange={(e) => setValue('responsaveis.vendedor', e.target.value)}
                                    marginRight={15}
                                    className={`select-status ${watch('responsaveis.vendedor') && "inputGreenSelect"}`}
                                >
                                    <option value="" hidden>Selecione</option>
                                    {responsaveis.vendedores?.map(option =>
                                        <option value={option.id}>{option.name}</option>
                                    )}
                                </SelectField>

                                <SelectField
                                    label='Comprador'
                                    width={200}
                                    value={watch('responsaveis.comprador')}
                                    onChange={(e) => setValue('responsaveis.comprador', e.target.value)}
                                    className={`select-status ${watch('responsaveis.comprador') && "inputGreenSelect"}`}
                                >
                                    <option value="" hidden>Selecione</option>
                                    {responsaveis.compradores?.map(option =>
                                        <option value={option.id}>{option.name}</option>
                                    )}
                                </SelectField>
                            </div>

                        </Pane>
                    }

                    {showFieldsEscritura === true
                        ?
                        <div style={{ marginTop: '20px' }}>
                            <div class="dialog-content dialog-content-rua">Vamos agendar a escritura entre as partes?</div>
                            <div className="dialog-content dialog-content-info" style={{ marginBottom: '20px' }}>As partes receberão notificações com as informações abaixo.</div>

                            <div className="form-status-escritura">
                                <div className="rowForm">
                                    <TextInputField
                                        width={112}
                                        label="Data"
                                        description=""
                                        placeholder="DD/MM/AAAA"
                                        className={"data-escritura"}
                                        maxLength={10}
                                        required
                                        {...register('dataEscritura', {
                                            required: errorTextInput,
                                            validate: (value) => {
                                                if (value.length < 10) {
                                                    return "Data inválida";
                                                }
                                            },
                                            onChange: (e) => handleFieldEscritura(e, 'data')
                                        })}
                                        validationMessage={
                                            errors.dataEscritura && errors.dataEscritura.message
                                        }
                                        //onChange={(e) => handleFieldEscritura(e, 'data')}
                                        style={errors.dataEscritura && errors.dataEscritura.message ? inputError : !dadosProcesso?.dado_escritura[0]?.data_escritura ? inputDefault : dadosProcesso?.dado_escritura[0]?.data_escritura ? inputGreen : inputDefault}
                                    />

                                    <TextInputField
                                        width={112}
                                        label="Hora"
                                        description=""
                                        placeholder="10:00"
                                        className={"hora-escritura"}
                                        maxLength={5}
                                        required
                                        {...register('horaEscritura', {
                                            required: errorTextInput,
                                            onChange: (e) => handleFieldEscritura(e, 'hora')
                                        })}
                                        validationMessage={
                                            errors.horaEscritura && errors.horaEscritura.message
                                        }
                                        style={errors.horaEscritura ? inputError : !dadosProcesso?.dado_escritura[0]?.hora_escritura ? inputDefault : dadosProcesso?.dado_escritura[0]?.hora_escritura ? inputGreen : inputDefault}
                                    //onChange={(e) => handleFieldEscritura(e, 'hora')}
                                    />
                                </div>

                                <div className="rowForm">
                                    <TextInputField
                                        width={310}
                                        label="Endereço"
                                        description=""
                                        placeholder="Ex: Rua Voluntários da Pátria"
                                        className={"endereco-escritura"}
                                        required
                                        {...register('enderecoEscritura', {
                                            required: errorTextInput,
                                            onChange: (e) => handleFieldEscritura(e, 'endereco')
                                        })}
                                        validationMessage={
                                            errors.enderecoEscritura && errors.enderecoEscritura.message
                                        }
                                        style={errors.enderecoEscritura ? inputError : !dadosProcesso?.dado_escritura[0]?.logradouro ? inputDefault : dadosProcesso?.dado_escritura[0]?.logradouro ? inputGreen : inputDefault}
                                    //onChange={(e) => handleFieldEscritura(e, 'data')}
                                    />

                                    <TextInputField
                                        width={165}
                                        label="Número"
                                        description=""
                                        placeholder="Ex: 1139"
                                        className={"numero-escritura"}
                                        //defaultValue={dadosProcesso?.dado_escritura[0]?.numero}
                                        defaultValue={dadosProcesso.dado_escritura[0]?.complemento || ''}
                                        required
                                        {...register('numeroEscritura', {
                                            required: errorTextInput,
                                            onChange: (e) => handleFieldEscritura(e, 'numero'),
                                        })}
                                        validationMessage={
                                            errors.numeroEscritura && errors.numeroEscritura.message
                                        }
                                        //onChange={(e) => handleFieldEscritura(e, 'hora')}
                                        style={errors.numeroEscritura ? inputError : !dadosProcesso?.dado_escritura[0]?.numero ? inputDefault : dadosProcesso?.dado_escritura[0]?.numero ? inputGreen : inputDefault}
                                    />
                                </div>

                                <div className="rowForm">
                                    <TextInputField
                                        width={150}
                                        label="Unidade"
                                        description=""
                                        placeholder="Ex: 1001"
                                        className={"unidade-escritura"}
                                        {...register('unidadeEscritura', {
                                            required: false,
                                            onChange: (e) => handleFieldEscritura(e, 'unidade'),
                                        })}
                                        validationMessage={
                                            errors.unidadeEscritura && errors.unidadeEscritura.message
                                        }
                                        style={errors.unidadeEscritura ? inputError : !dadosProcesso?.dado_escritura[0]?.unidade ? inputDefault : dadosProcesso?.dado_escritura[0]?.unidade ? inputGreen : inputDefault}
                                    //onChange={(e) => handleFieldEscritura(e, 'unidade')}
                                    />

                                    <TextInputField
                                        width={150}
                                        label="Complemento"
                                        description=""
                                        placeholder="Ex: Bloco A"
                                        //defaultValue={dadosProcesso.dado_escritura[0]?.complemento || ''}
                                        className={"complemento-escritura"}
                                        {...register('complementoEscritura', {
                                            required: false,
                                            onChange: (e) => handleFieldEscritura(e, 'complemento')
                                        })}
                                        validationMessage={
                                            errors.complementoEscritura && errors.complementoEscritura.message
                                        }
                                        style={errors.complementoEscritura ? inputError : !dadosProcesso?.dado_escritura[0]?.complemento ? inputDefault : dadosProcesso?.dado_escritura[0]?.complemento ? inputGreen : inputDefault}
                                    //onChange={(e) => handleFieldEscritura(e, 'complemento')}
                                    />
                                </div>
                            </div>
                        </div>

                        :
                        ''
                    }

                    <Label htmlFor="textarea-2" className="label-dialog" marginTop={25} marginBottom={10} display="block">
                        Escreva sua mensagem:
                    </Label>
                    <Textarea
                        name="textarea-1"
                        placeholder="Ex: Caso seja necessário, podem pedir um novo local para assinar a escritura..."
                        // onChange={(e) => handleMessage(e)}
                        disabled={loading}
                        className="observacao"
                        {...register('mensagem', { })}
                        style={watch('mensagem') ? inputGreen : inputDefault}
                        
                    //style={!messageRef ? inputDefault : messageRef ? inputGreen : inputDefault}
                    />

                    {componentVendedorResp ?
                        <Pane>
                            <p className='mb8 mt24'>Previsão de pagamento da comissão</p>
                            <p className='fw400 mb24 lw20'>Insira previsão de pagamento e dados das partes envolvidas no pagamento. Dessa forma, o financeiro pode visualizar as informações através da plataforma.</p>
                            <PrevisaoComissao watch={watch} setValue={setValue} errors={errors} register={register} unregister={unregister} active={componentVendedorResp} />
                        </Pane>
                        : ''
                    }
                </div>
            }


        </Dialog>
    )
}