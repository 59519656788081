import Axios from 'axios';
import Serve from '../app/serve';

export default function Logoff() {
	const token = localStorage.getItem('token');	
	const app = Serve();

	const logoff = async (token) => {
		await Axios.post(app + 'logout', {
			"token": token,
		}, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		}).then(() => {			
			window.location.pathname = '/'
		})
	};
	return logoff(token);
};