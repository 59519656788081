import React, {useContext, useState} from 'react'
// import {Badge} from 'evergreen-ui';
import AcessoTemporarioContext from '../../../../../context/AcessoTemporarioContext';
import { Button, Pane, TextInputField, SelectField, TickIcon, Checkbox, ErrorIcon, Alert } from 'evergreen-ui';
import PhoneInput from 'react-phone-input-2';
// import { useForm } from 'react-hook-form';
import 'react-phone-input-2/lib/bootstrap.css'
// import validarCNPJ from '../../../../../components/validarCNPJ';
import validarCPF from '../../../../../components/validarCPF';
// import updateUsuarios from '../../../../../apis/updateUsuarios';
// import getCEP from '../../../../../apis/getCEP';
import cepMask from '../../../../../components/cepMask';
import dateMask from '../../../../../components/dateMask';
import cpfMask from '../../../../../components/cpfMask';
// import cnpjMask from '../../../../../components/cnpjMask';
import Axios from 'axios';
// import Serve from '../../../../../app/serve';
// import fimFeedbackImg from '../../../../../images/end_feedback.png';

export default function DadosPessoais({processoId, register, handleSubmit, setValue, watch, errors, clearErrors, handleEdit}) {
    const {
        usuario,
        // imovel,
        isShowFeedbackDados, /*setIsShowFeedbackdados*/
        // setLoading,
    } = useContext(AcessoTemporarioContext);

    console.log('USUARIO: ' , usuario);
    // console.log('ERROR: ' , errors)
    // console.log('WATCH: ' , watch())

    const msgObrigatorio = "Campo obrigatório";

    const inputDisabled = {
        borderColor: '#d8dae5',
        background: '#F4F5F9',
        color: '#696f8c'
    };

    const inputGreen = {
        borderColor: '#429777',
        background: '#F5FBF8'
    };

    const inputDefault = {
        borderColor: '#ADC2FF',
        background: '#fff'
    };

    const inputError = {
        borderColor: '#FF0000',
        background: '#fff'
    };
    
    const [disableCamposEndereco, setDisableCamposEndereco] = useState({
        bairro: true,
        logradouro: true,
        cidade: true,
        estado: true,
    });
    
    async function handleCep(cep) {
        if (cep.replace(/\D/g, '').length === 8) {
          await Axios.get("https://viacep.com.br/ws/" + cep + "/json/")
            .then(res => {
              if (!res?.data?.erro) {
                setValue('bairro', res.data?.bairro);
                disableCamposEndereco.bairro = res.data?.bairro ? true : false;
    
                setValue('logradouro', res.data?.logradouro);
                disableCamposEndereco.logradouro = res.data?.logradouro ? true : false;
    
                setValue('cidade', res.data?.localidade);
                disableCamposEndereco.cidade = res.data?.localidade ? true : false;
    
                setValue('estado', res.data?.uf);
                disableCamposEndereco.estado = res.data?.uf ? true : false;
              } else {
                setValue('bairro', "")
                disableCamposEndereco.bairro = false;
                setValue('logradouro', "")
                disableCamposEndereco.logradouro = false;
                setValue('cidade', "")
                disableCamposEndereco.cidade = false;
                setValue('estado', "")
                disableCamposEndereco.estado = false;
                setDisableCamposEndereco({ ...disableCamposEndereco });
              }
            })
          setValue('cep', cepMask(cep));
        };
    };

    const handleSelect = (e) => {
        setValue('estado_civil', e.target.value);
        if (e.target.value !== '1') setValue('registro_casamento', '')
        else setValue("uniao_estavel", false)
    };

    async function handleCpf(cpf) {
        if (cpf.length === 14) {
        //   await Axios.post(app + 'verificar_usuario_cpf', { cpf }, {
        //     headers: { Authorization: `Bearer ${token}` }
        //   }).then(res => {
        //     if (res?.data.usuario) {
        //       const { usuario } = res.data;
        //       console.log(index, usuario);
        //       setValue(index.toString(), { ...usuario });
        //       // setPerfilUsuario(usuario.perfil);
        //       clearErrors();
        //     }
        //   })

          clearErrors();
        }
    };

    return (
        <>
            {/*DAODS PESSOAIS*/}
            <div className="row-3">
                <h2>Insira seus dados pessoais:</h2>
                
                <div className="rowForm">
                    <TextInputField
                        width={'100%'}
                        maxWidth={'350px'}
                        label="Nome Completo*"
                        description=""
                        name={'name'}
                        placeholder="Ex: José Maria da Silva"
                        {...register('name', {
                            required: msgObrigatorio,
                        })}
                        validationMessage={errors?.name && errors?.name?.message}
                        style={
                            watch('name')
                            ? !!watch('perfil') === false
                                ? inputGreen
                                : inputDisabled
                            : errors?.name 
                                ? inputError : inputDefault
                        }
                    />
                </div>

                <div className="rowForm">
                    <TextInputField
                    width={'100%'}
                    maxWidth={'350px'}
                    label="E-mail*"
                    description=""
                    placeholder={"email@gmail.com"}
                    value={watch('email')}
                    disabled={!!watch('perfil')}
                    style={
                        watch('email') ? 
                        !!watch('perfil') === false 
                            ? inputGreen 
                            : inputDisabled
                        : errors?.email 
                        ? inputError : inputDefault
                    }
                    {...register('email', {
                        required: msgObrigatorio,
                        validate: (value) => value === '' || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || "Formato de e-mail inválido",
                    })}
                    validationMessage={errors.email && errors.email && errors.email.message}     
                    inputmode="email"         
                    />
                </div>

                <div className="rowForm">
                    <Pane display="flex" flexDirection="column" marginLeft={0}>
                        <label className='mb12'>Telefone/Celular*</label>
                        <PhoneInput
                            width={'100%'}
                            maxWidth={'350px'}
                            country={"br"}
                            onChange={(e, country) => {
                            setValue('telefone', e.replace(country.dialCode, ''));
                            setValue('ddi', country.dialCode);
                            clearErrors('telefone');
                            }}
                            value={watch("telefone") ? watch("ddi") + watch("telefone") : '55'}
                            disabled={!!watch('perfil')}
                            preferredCountries={['br', 'us']}
                            // inputStyle={watch(index + ".telefone")?.length >= 10 ? inputGreen : errors?.[index]?.telefone ? inputError : inputDefault}
                            inputStyle={
                            watch("telefone")?.length >= 10
                            ? !!watch('perfil') === false
                                ? inputGreen
                                : inputDisabled
                            : errors?.telefone 
                                ? inputError : inputDefault
                            }
                            inputClass="PhoneInput"
                            isValid={(inputNumber, country) => {
                            if (country.countryCode === '55') {
                                return inputNumber.length < 12 ? false : true;
                            } else {
                                return inputNumber.length < 6 ? false : true;
                            }
                            }}
                            inputmode="numeric"
                        />
                        <input
                            type="hidden"
                            {...register('telefone', {
                            required: 'Campo obrigatório',
                            validate: (value) => {
                                if (value.length < 10) {
                                return "Telefone inválido";
                                }
                            },
                            })}
                        />
                        <span className="error-input mt8">
                            {errors?.telefone ? <ErrorIcon /> : ''}
                            <span>{errors?.telefone && errors?.telefone.message}</span>
                        </span>
                    </Pane>
                </div>

                <div className="rowForm">
                    <TextInputField
                        width={'100%'}
                        maxWidth={'150px'}
                        label="Data de nascimento*"
                        description=""
                        placeholder="Ex: DD/MM/AAAA"
                        maxLength={10}
                        value={watch('data_nascimento')}
                        style={watch('data_nascimento') ? inputGreen : errors?.data_nascimento ? inputError : inputDefault}
                        {...register('data_nascimento', {
                            setValueAs: e => dateMask(e),
                            required: msgObrigatorio,
                            validate: (value) => value === '' || value.length === 10 || "Formato de e-mail inválido",
                            // validate: (value) => {
                            //     if (value.length < 10 || value.length > 0) {
                            //         return "Formato de data inválida";
                            //     }
                            // },
                        }
                        )}
                        validationMessage={errors?.data_nascimento && errors?.data_nascimento.message}
                        inputmode="numeric"
                    />

                    <TextInputField
                        width={'100%'}
                        maxWidth={'200px'}
                        // marginLeft={20}
                        label="Nacionalidade*"
                        description=""
                        placeholder="Ex: Brasileiro"
                        value={watch('nacionalidade')}
                        {...register('nacionalidade', {
                            required: "Campo obrigatório",
                            // onChange: (e) => handleNacionalidade(e, index),
                        })}
                        validationMessage={errors?.nacionalidade && errors?.nacionalidade.message}
                        style={watch('nacionalidade') ? inputGreen : errors?.nacionalidade ? inputError : inputDefault}
                    />
                </div>

                <div className="rowForm" tabIndex={-1} id={"estado_civil"} width={'100%'}>
                    <SelectField
                        label="Estado Civil*"
                        description=""
                        name={'estado_civil'}
                        validationMessage={errors?.estado_civil && errors?.estado_civil.message}
                        className={watch('estado_civil') ? 'inputGreenSelect' : errors?.estado_civil ? 'inputErrorSelect' : ""}
                        value={watch('estado_civil')}
                        {...register('estado_civil', {
                            required: msgObrigatorio,
                            onChange: (e) => handleSelect(e),
                        })}
                    >
                        <option value="" hidden>Selecionar</option>
                        <option value="1">Casado</option>
                        <option value="2">Solteiro</option>
                        <option value="3">Divorciado</option>
                        <option value="4">Viúvo</option>
                        <option value="5">Separado</option>
                    </SelectField>

                    {watch('estado_civil') === '1' &&
                        <div tabIndex={-1} id={"registro_casamento"} width={'100%'}>
                            <SelectField
                                label="Regime de casamento*"
                                description=""
                                name={'registro_casamento'}
                                validationMessage={errors?.registro_casamento && errors?.registro_casamento.message}
                                className={watch('registro_casamento') ? 'inputGreenSelect' : errors?.registro_casamento ? 'inputErrorSelect' : ""}
                                value={watch('registro_casamento')}
                                {...register('registro_casamento', {
                                    required: false,
                                    validate: value => { if (!value && watch('estado_civil') === '1') return msgObrigatorio },
                                    onChange: (e) => setValue('registro_casamento', e.target.value)
                                })}
                            >
                                <option value="0" hidden>Selecionar</option>
                                <option value="1">Separação total de bens</option>
                                <option value="2">Separação parcial de bens</option>
                                <option value="3">Separação legal de bens</option>
                                <option value="4">Comunhão de bens</option>
                                <option value="5">Comunhão parcial de bens</option>
                            </SelectField>
                        </div>
                    }

                    {watch('estado_civil') > '1' &&
                    <div className="row-recibo position-check">
                        <div className="line-checkbox">
                        <Checkbox
                            width={'100%'}
                            // marginTop={38}
                            className="checkbox"
                            label="União Estável"
                            appearance="default"
                            value="S"
                            {...register("uniao_estavel", {
                                required: false,
                                onChange: () => watch("uniao_estavel") === "S" ? "" : "S"
                            })}
                            checked={watch("uniao_estavel") === "S"}
                        />
                        </div>
                    </div>
                    }
                </div>

                {/* CAMPO NOME CONJUGUE */}
                <div className="rowForm">
                    {
                    watch('registro_casamento') || watch("uniao_estavel") === "S"
                        ?
                        <TextInputField
                            width={'100%'}
                            maxWidth={'350px'}
                            label="Nome completo do Cônjuge/Compaheiro(a)*"
                            description=""
                            placeholder="Ex: José Maria da Silva"
                            value={watch('conjuge')}
                            validationMessage={errors?.conjuge && errors?.conjuge.message}
                            style={watch('conjuge') ? inputGreen : errors?.conjuge ? inputError : inputDefault}
                            {...register('conjuge', {
                                required: (watch('registro_casamento') !== '0' || watch("uniao_estavel") === 'S') ? 'Campo obrigatório' : false,
                                // onChange: (e) => handleChangeConjuge(e, index)
                            })}
                        />
                        : ""
                    }
                </div>
            </div>


            {/*NUMERO DE ITENTIFICAÇÂO*/}
            <div className="row-3">
                <h2>Sobre a sua documentação:</h2>

                <div className="rowForm">
                    <input type="hidden" {...register('perfil')} />
                    <TextInputField
                        width={'100%'}
                        maxWidth={'193px'}
                        label='CPF*'
                        description=""
                        placeholder='000.000.000-00'
                        maxLength={14}
                        value={watch('cpf_cnpj')}
                        {...register('cpf_cnpj', {
                            required: msgObrigatorio,
                            validate: (value) => validarCPF(value) ? true : "CPF inválido",
                            onChange: (e) => handleCpf(e.target.value),
                            setValueAs: e => cpfMask(e)
                        })}
                        validationMessage={errors?.cpf_cnpj && errors?.cpf_cnpj.message}
                        style={watch('cpf_cnpj') ? inputGreen :
                            errors?.cpf_cnpj ? inputError : inputDefault
                        }
                        inputmode="numeric"
                    />
                </div>

                <div className="rowForm">
                    <TextInputField
                        width={'100%'}
                        maxWidth={'193px'}
                        label="RG"
                        description=""
                        value={watch('rg')}
                        placeholder="Ex: 12.345.678-9"
                        style={
                            watch('rg') 
                            ? inputGreen
                            : inputDefault 
                        }
                        {...register('rg', {
                            // onChange: (e) => handleRg(e, index)
                        })}
                    />

                    {watch('rg')?.length > 0
                        ?
                        <>
                            <TextInputField
                                width={'100%'}
                                label="Expedida por*"
                                //disabled={!watch(index + '.rg') || watch(index + '.perfil') === true}
                                description=""
                                placeholder="Ex: Detran-RJ"
                                value={watch('rg_expedido')}
                                validationMessage={errors?.rg_expedido && "Campo obrigatório"}
                                style={
                                    watch('rg_expedido')
                                    ? inputGreen
                                    : errors?.rg_expedido 
                                    ? inputError : inputDefault
                                }
                                {...register('rg_expedido', {
                                    required: watch('rg')?.length > 0 ? msgObrigatorio : false,
                                })}
                            />

                            <TextInputField
                                width={'100%'}
                                label="Data de expedição*"
                                description=""
                                placeholder="Ex: DD/MM/AAAA"
                                marginBottom={0}
                                marginLeft={0}
                                maxLength={10}
                                value={watch('data_rg_expedido')}
                                style={
                                    watch('data_rg_expedido')
                                    ? inputGreen
                                    : errors?.data_rg_expedido 
                                    ? inputError : inputDefault
                                }        
                                {...register('data_rg_expedido', {
                                    required: watch('rg')?.length > 0 ? msgObrigatorio : false,
                                    // onChange: (e) => handleDataExpedicao(e, index),
                                    setValueAs: e => dateMask(e),
                                    validate: (value) => value === '' || value.length === 10 || "Formato de e-mail inválido",
                                })}
                                validationMessage={errors?.data_rg_expedido && "Campo obrigatório"}
                                inputmode="numeric"
                            />
                        </>
                        : ''
                    }
                </div>
            </div>

            {/*ENDEREÇO*/}
            <div className="row-3">
                <h2>Onde você mora?</h2>
                <div className="rowForm">
                    <TextInputField
                        label="CEP*"
                        description=""
                        placeholder="Ex: 00000-000"
                        maxLength={9}
                        width={'100%'}
                        value={watch('cep')}
                        validationMessage={errors?.cep && errors?.cep.message}
                        style={watch('cep') ? inputGreen : errors?.cep ? inputError : inputDefault}
                        {...register('cep', {
                        required: "Campo obrigatório",
                        onChange: (e) => handleCep(e.target.value),
                        // setValueAs: e => cepMask(e)
                        })}
                        inputmode="numeric"
                    />

                    <TextInputField
                        width={'100%'}
                        label="Bairro"
                        description=""
                        value={watch('bairro')}
                        placeholder={watch('cep') && !watch('bairro') ? 'Preencha com o bairro' : '---'}
                        disabled={disableCamposEndereco.bairro}
                        {...register('bairro', {
                        required: watch('cep') && !watch('bairro') ? "Campo obrigatório" : false,
                        // onChange: (e) => handleBairroLogradouro(e, index, 'bairro'),
                        })}
                        validationMessage={errors?.bairro && errors?.bairro.message}
                        style={disableCamposEndereco.bairro ? undefined : watch('bairro') ? inputGreen : errors?.bairro ? inputError : inputDefault}
                    />

                    <TextInputField
                        width={'100%'}
                        label="Logradouro"
                        description=""
                        marginBottom={0}
                        marginLeft={0}
                        value={watch('logradouro')}
                        placeholder={watch('cep') && !watch('logradouro') ? 'Preencha com o logradouro' : '---'}
                        disabled={disableCamposEndereco.logradouro}
                        {...register('logradouro', {
                        required: watch('cep') && !watch('logradouro') ? "Campo obrigatório" : false,
                        // onChange: (e) => handleBairroLogradouro(e, index, 'logradouro'),
                        })}
                        validationMessage={errors?.logradouro && errors?.logradouro.message}
                        style={disableCamposEndereco.logradouro ? undefined : watch('logradouro') ? inputGreen : errors?.logradouro ? inputError : inputDefault}
                    />
                </div>

                {/* CAMPOS NUMERO UNIDADE E COMPLEMENTO */}
                <div className="rowForm">
                    <TextInputField
                        width={'100%'}
                        label="Número*"
                        description=""
                        placeholder="Ex: 456"
                        value={watch('numero')}
                        validationMessage={errors?.numero && errors?.numero.message}
                        style={watch('numero') ? inputGreen : errors?.numero ? inputError : inputDefault}
                        {...register('numero', {
                        required: "Campo obrigatório",
                        // onChange: (e) => handleSomenteNumero(e, index),
                        })}
                    />

                    <TextInputField
                        width={'100%'}
                        label="Unidade"
                        description=""
                        placeholder="Número do apartamento"
                        value={watch('unidade')}
                        style={watch('unidade') ? inputGreen : inputDefault}
                        {...register('unidade', {
                        // onChange: (e) => 
                        })}
                    />

                    <TextInputField
                        width={'100%'}
                        label="Complemento"
                        placeholder="Bloco, lote ou condomínio"
                        description=""
                        marginBottom={0}
                        marginLeft={0}
                        value={watch('complemento')}
                        style={watch('complemento') ? inputGreen : inputDefault}
                        {...register('complemento', {
                        // onChange: (e) => 
                        })}
                    />
                </div>

                {/* CAMPOS CIDADE E ESTADO */}
                <div className="rowForm">
                    <TextInputField
                        width={'100%'}
                        maxWidth={'193px'}
                        label="Cidade"
                        description=""
                        value={watch('cidade')}
                        placeholder={watch('cep') && !watch('cidade') ? 'Preencha com a cidade' : '---'}
                        disabled={disableCamposEndereco.cidade}
                        {...register('cidade', {
                        required: watch('cep') && !watch('cidade') ? "Campo obrigatório" : false,
                        // onChange: (e) => 
                        })}
                        validationMessage={errors?.cidade && errors?.cidade.message}
                        style={disableCamposEndereco.cidade ? undefined : watch('cidade') ? inputGreen : errors?.cidade ? inputError : inputDefault}
                    />

                    <TextInputField
                        width={'100%'}
                        maxWidth={'193px'}
                        label="Estado"
                        description=""
                        value={watch('estado')}
                        placeholder={watch('cep') && !watch('estado') ? 'Preencha com o estado' : '---'}
                        disabled={disableCamposEndereco.estado}
                        {...register('estado', {
                        required: watch('cep') && !watch('estado') ? "Campo obrigatório" : false,
                        // onChange: (e) => 
                        })}
                        validationMessage={errors?.estado && errors?.estado.message}
                        style={disableCamposEndereco.estado ? undefined : watch('estado') ? inputGreen : errors?.estado ? inputError : inputDefault}
                    />
                </div>
            </div>

            <div className="row-3">
                <h2>Demais dados importantes:</h2>
                
                <div className="rowForm">
                    <TextInputField
                        width={'100%'}
                        maxWidth={'350px'}
                        label="Nome completo da mãe*"
                        description=""
                        placeholder="Ex: Maria da Silva"
                        value={watch('nome_mae')}
                        //disabled={!!watch(index + '.perfil')}
                        {...register('nome_mae', {
                            required: "Campo obrigatório",
                            // onChange: (e) => handleNomeMae(e, index),
                        })}
                        validationMessage={errors?.nome_mae && errors?.nome_mae.message}
                        // style={watch(index + '.nome_mae') ? inputGreen : errors?.[index]?.nome_mae ? inputError : inputDefault}
                        style={
                            watch('nome_mae')
                                ? inputGreen
                                : errors?.nome_mae 
                                ? inputError 
                                : inputDefault
                        }
                    />
                </div>

                {/* CAMPO NOME DO PAI */}
                <div className="rowForm">
                    <TextInputField
                        width={'100%'}
                        maxWidth={'350px'}
                        label="Nome completo do pai"
                        description=""
                        placeholder="Ex: José Maria da Silva"
                        value={watch('nome_pai')}
                        //disabled={!!watch(index + '.perfil')}
                        //style={watch(index + '.nome_pai') ? inputGreen : inputDefault}
                        style={
                            watch('nome_pai')
                            ? inputGreen
                            : errors?.nome_pai 
                                ? inputError 
                                : inputDefault
                        }
                        {...register('nome_pai', {
                            // onChange: (e) => verificaInput(e, index) 
                        })}
                    />
                </div>

                {/* CAMPO PROFISSAO */}
                <div className="rowForm">
                    <TextInputField
                        width={'100%'}
                        maxWidth={'350px'}
                        label="Profissão*"
                        description=""
                        placeholder="Ex: Corretor"
                        value={watch('profissao')}
                        {...register('profissao', {
                            required: "Campo obrigatório",
                            // onChange: (e) => verificaInput(e, index),
                        })}
                        validationMessage={errors?.profissao && errors?.profissao.message}
                        style={watch('profissao') ? inputGreen : errors?.profissao ? inputError : inputDefault}
                    />
                </div>
            </div>

            <div className="row-3">
                <h2>Você tem procurador?</h2>
                
                <div className="rowForm">
                    <TextInputField
                        width={'100%'}
                        maxWidth={'350px'}
                        label="Se sim, diga o nome completo"
                        description=""
                        name={'nome_procurador'}
                        placeholder="Ex: José Maria da Silva"
                        {...register('nome_procurador', {
                            // required: msgObrigatorio,
                        })}
                        validationMessage={errors?.nome_procurador && errors?.nome_procurador?.message}
                        style={
                            watch('nome_procurador')
                            ? !!watch('perfil') === false
                                ? inputGreen
                                : inputDisabled
                            : errors?.nome_procurador 
                                ? inputError : inputDefault
                        }
                    />
                </div>

                <div className="rowForm">
                    <Pane display="flex" flexDirection="column" marginLeft={0}>
                        <label className='mb12'>Telefone/Celular</label>
                        <PhoneInput
                            width={'100%'}
                            maxWidth={'350px'}
                            country={"br"}
                            onChange={(e, country) => {
                                setValue('telefone_procurador', e.replace(country.dialCode, ''));
                                setValue('ddi', country.dialCode);
                                clearErrors('telefone_procurador');
                            }}
                            value={watch("telefone_procurador") ? watch("ddi") + watch("telefone_procurador") : '55'}
                            disabled={!!watch('perfil')}
                            preferredCountries={['br', 'us']}
                            inputStyle={
                            watch("telefone_procurador")?.length >= 10
                            ? !!watch('perfil') === false
                                ? inputGreen
                                : inputDisabled
                            : errors?.telefone_procurador 
                                ? inputError : inputDefault
                            }
                            inputClass="PhoneInput"
                            isValid={(inputNumber, country) => {
                            if (country.countryCode === '55') {
                                return inputNumber.length < 12 ? false : true;
                            } else {
                                return inputNumber.length < 6 ? false : true;
                            }
                            }}
                            inputmode="numeric"
                        />
                        <input
                            type="hidden"
                            {...register('telefone_procurador', {
                                required: false,
                                validate: (value) => value === "" || /^\(?\d{2}\)?\s?\d{4,5}-?\d{4}$/.test(value) || "Formato de telefone inválido",
                                // required: 'Campo obrigatório',
                                // validate: (value) => {
                                //         if (value.length < 10) {
                                //         return "Telefone inválido";
                                //     }
                                // },
                            })}
                        />
                        <span className="error-input mt8">
                            {errors?.telefone_procurador ? <ErrorIcon /> : ''}
                            <span>{errors?.telefone_procurador && errors?.telefone_procurador.message}</span>
                        </span>
                    </Pane>
                </div>
            </div>

            <div className="btn-update-user">
                <div style={{maxWidth: '720px', width: '100%', display: 'block', margin: '0 auto'}}>
                    {
                        isShowFeedbackDados && 
                            <Alert
                            intent="success"
                            title="Seus dados foram atualizados :)"
                            marginBottom={32}
                            style={{maxWidth: '720px', width: '100%', marginBottom: '10px'}}
                        />
                    }
                    <Button 
                        className="btn-contato btn-dna-green"
                        onClick={handleSubmit((e) => handleEdit(e)/*, onError*/)}
                        style={{maxWidth: '720px', width: '100%', display: 'flex', justifyContent: 'center'}}
                    >
                        <TickIcon color={'#fff'} marginRight={10} />  Salvar edição
                    </Button>
                </div>
            </div>
        </>
    )
}