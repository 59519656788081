import React, {useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom';
import {Pane, MapMarkerIcon, Tab, Tablist} from 'evergreen-ui';

import '../styles.css';
import Header from './components/Header';
import Principal from './components/Principal';
import Status from './components/Status';
import Historico from './components/Historico';
import Ajuda from './components/Ajuda';
import MobileAjuda from './components/Ajuda/MobileAjuda';
import DadosPessoais from './components/DadosPessoais';
import NavMobile from './components/NavMobile';
import Serve from '../../../app/serve_login';
import Axios from 'axios';
import ConteudoFaq from '../faq.json';
// import ListarDocumentos from '../../../apis/getDocumentsList';
import { useForm } from 'react-hook-form';
import updateUsuarios from '../../../apis/updateUsuarios';

import AcessoTemporarioContext from '../../../context/AcessoTemporarioContext';

export default function AcompanharStatus() {
    const app = Serve();
    const history = useHistory();
    const [resolucao, setResolucao] = useState();

    const [usuarioId, setUsuarioId] = useState();

    const [navMenu, setNavMenu] = useState('principal');
    const [logradouro, setLogradouro] = useState('');
    const [statusProgresso, setStatusProgresso] = useState([]);
    const [statusHistorico, setStatusHistorico] = useState([]);
    const [avaliacao, setAvaliacao] = useState([]);

    /*const [arrayStatus, setArrayStatus] = useState([]);
    const [inicioProcesso, setInicioProcesso] = useState('');
    const [finalProcesso, setFinalProcesso] = useState('');*/

    const [loading, setLoading] = useState(false);
    const [isShown, setIsShown] = useState(false);
    const [isShowFeedback, setIsShowFeedback] = useState(false);
    const [fimFeedback, setFimFeedback] = useState(false);
    const [faq] = useState(ConteudoFaq);
    const [responsavelProcesso, setResponsavelProcesso] = useState([]);
    const [gerenteProcesso, setGerenteProcesso] = useState([]);
    const [vendedorProcesso, setVendedorProcesso] = useState([]);
    const [opcionistaProcesso, setOpcionistaProcesso] = useState([]);
    const [isShowFeedbackDados, setIsShowFeedbackdados] = useState(false);

    //Usuário
    const [usuario, setUsuario] = useState([]);
    console.log(usuario)

    //Imóvel
    const [imovel, setImovel] = useState([]);

    //Dados Pessoais
    const [isShowDadosPessoais, setIsShowDadosPessoais] = useState(false);

    //Documentos
    const [isShownDocuments, setIsShownDocuments] = useState(false);
    const [tipoDocumentos, setTipoDocumentos] = useState([]);
    const [documentosPessoais, setDocumentosPessoais] = useState([]);
    const [documentosImovel, setDocumentosImovel] = useState([]);

    //Tabs
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [tabs] = React.useState(['Principal', 'Histórico', 'Meus dados']);

    //Rating Star
    const [ratingValue, setRatingValue] = useState(0);

    //FeedBack
    const [perguntasOpcionista, setPerguntasOpcionistas] = useState([]);
    const [perguntasVendedor, setPerguntasVendedor] = useState([]);
    const [perguntasGerente, setPerguntasGerente] = useState([]);
    const [perguntasPosNegociacao, setPerguntasPosNegociacao] = useState([]);

    // Multiplos Documentos
    const [documentsList, setDocumentsList] = useState([]);
    const [listaDocumentos, setListaDocumentos] = useState([]);
    const [processSelect, setProcessSelect] = useState({});
    const [processoId, setProcessoId] = useState();  
    const [dadosVenda, setDadosVenda] = useState([]);
    const [isShowConfirmDel, setIsShowConfirmDel] = useState(false);
    const [itemToRemove, setItemToRemove] = useState("");
    const [multiDocs, setMultiDocs] = useState({
        'imovel': [{item: [], file: '', id: ''}],
        'vendedores': [
          [{item: [], file: '', id: ''}]
        ],
        'compradores': [      
          [{item: [], file: '', id: '' }]
        ]
    });

    const context = {
        loading, setLoading,
        usuarioId, setUsuarioId,
        navMenu, setNavMenu,
        statusProgresso, statusHistorico,
        isShown, setIsShown,
        isShowFeedback, setIsShowFeedback,
        fimFeedback, setFimFeedback,
        avaliacao, setAvaliacao,
        faq,
        responsavelProcesso, setResponsavelProcesso,
        gerenteProcesso, setGerenteProcesso,
        vendedorProcesso, setVendedorProcesso,
        opcionistaProcesso, setOpcionistaProcesso,
        isShowDadosPessoais, setIsShowDadosPessoais,
        isShownDocuments, setIsShownDocuments,
        tipoDocumentos, setTipoDocumentos,
        documentosPessoais, setDocumentosPessoais,
        documentosImovel, setDocumentosImovel,
        usuario, setUsuario,
        imovel, setImovel,
        selectedIndex, setSelectedIndex,
        tabs,
        logradouro, setLogradouro,
        resolucao, setResolucao,
        ratingValue, setRatingValue,
        perguntasOpcionista, setPerguntasOpcionistas,
        perguntasVendedor, setPerguntasVendedor,
        perguntasGerente, setPerguntasGerente,
        perguntasPosNegociacao, setPerguntasPosNegociacao,
        isShowFeedbackDados, setIsShowFeedbackdados,

        //Documentos
        documentsList, setDocumentsList,
        listaDocumentos, setListaDocumentos,
        processSelect, setProcessSelect,
        processoId, setProcessoId,
        dadosVenda, setDadosVenda,
        isShowConfirmDel, setIsShowConfirmDel,
        itemToRemove, setItemToRemove,
        multiDocs, setMultiDocs,
    }

    const [processo_id] = useState(localStorage.getItem('processo_id'));
    // const [codigo_acesso] = useState(localStorage.getItem('codigo_acesso'));
    const [cpf] = useState(localStorage.getItem('cpf'));

    if(!processo_id || processo_id === '') {
        history.push('/acesso');
    }

    const refresh = async () => {        
        setProcessoId(processo_id);

        await Axios.post(app + 'dados_processos_vendedor_comprador', {
         'processo_id': processo_id,
         'usuario_id': usuarioId,
         'cpf': cpf
        }, {
            /* headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            } */
        }).then(res => {
            if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                localStorage.clear();
                history.push('/acesso');
            } else {
                const dadosUser = res?.data?.usuario;
                setUsuarioId(res.data.usuario.id);
                setAvaliacao(res.data.avaliacao);
                // const lista_status = res.data.status_processos.status.data;
                const responsavel = res.data.status_processos.responsavel;
                setResponsavelProcesso(responsavel)
                setDocumentosPessoais(res.data.documentos);
                setDocumentosImovel(res.data.documentos_imovel);
                setUsuario(res.data.usuario);
                setImovel(res.data.logradouro);
                setGerenteProcesso(res.data.gerente);
                setVendedorProcesso(res.data.corretor_vendedor);
                setOpcionistaProcesso(res.data.opcionista)
                setMultiDocs(multiDocs);

                setProcessSelect(res.data);
                setDadosVenda(res.data.imovel);
                returnDadosUsuario(dadosUser);
            }
        })
    }

    const returnDadosUsuario = async (usuario) => {
        setValue('tipo_pessoa', usuario.tipo_pessoa);
        setValue('tipo', usuario.perfil_id === 2 ? 'comprador' : 'vendedor');
        setValue('id', usuario.id);
        setValue('name', usuario.nome || '');
        setValue('email', usuario.email || '');
        setValue('telefone', usuario.telefone || '');
        setValue('ddi', usuario.ddi || '55');
        setValue('data_nascimento', usuario.data_nascimento || '');
        setValue('nacionalidade', usuario.nacionalidade || '');
        setValue('estado_civil', usuario.estado_civil || '');
        setValue('registro_casamento', usuario.registro_casamento || '');
        setValue('uniao_estavel', usuario.uniao_estavel || '');
        setValue('conjuge', usuario.conjuge || '');
        setValue('cpf_cnpj', usuario.cpf_cnpj || '');
        setValue('rg', usuario.rg || '');
        setValue('rg_expedido', usuario.rg_expedido || '');
        setValue('data_rg_expedido', usuario.data_rg_expedido || '');
        setValue('cep', usuario.cep || '');
        setValue('bairro', usuario.bairro || '');
        setValue('logradouro', usuario.logradouro || '');
        setValue('numero', usuario.numero || '');
        setValue('unidade', usuario.unidade || '');
        setValue('complemento', usuario.complemento || '');
        setValue('cidade', usuario.cidade || '');
        setValue('estado', usuario.estado || '');
        setValue('nome_mae', usuario.nome_mae || '');
        setValue('nome_pai', usuario.nome_pai || '');
        setValue('profissao', usuario.profissao || '');
        setValue('nome_procurador', usuario.nome_procurador || '');
        setValue('telefone_procurador', usuario.telefone_preocurador || '');
    }

    useEffect(() => {
        const returnaPerguntas = async () => {
            Axios.get(app + 'tipo_avaliacoes')
            .then(res => {
                const perguntas = res.data.data;

                const opcionistas = perguntas.find(pergunta => pergunta.nome === "Corretor Opcionista");
                setPerguntasOpcionistas(opcionistas);

                const vendedor = perguntas.find(pergunta => pergunta.nome === "Corretor Vendedor");
                setPerguntasVendedor(vendedor);

                const gerente = perguntas.find(pergunta => pergunta.nome === "Gerente");
                setPerguntasGerente(gerente);

                const posNegociacao = perguntas.find(pergunta => pergunta.nome === "Pós Negociação");
                setPerguntasPosNegociacao(posNegociacao);
            })
            .catch(error => {
                console.log(error)
            })
        }
        returnaPerguntas()

        const sizeOfThings = (e) =>{
            var windowWidth = window.innerWidth;
            setResolucao(windowWidth)
        };
        
        window.addEventListener('resize', function(){
            sizeOfThings();
        });
        sizeOfThings();
        
        // Dados do Processo
        const exibirDadosAcessoProcesso = async () => {
            setLoading(false);
            
            setProcessoId(processo_id);
    
            await Axios.post(app + 'dados_processos_vendedor_comprador', {
             'processo_id': processo_id,
             'usuario_id': usuarioId,
             'cpf': cpf
            }, {
                /* headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                } */
            }).then(res => {
                if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                    localStorage.clear();
                    history.push('/acesso');
                } else {
                    const dadosUser = res?.data?.usuario;
                    setUsuario(dadosUser);
                    returnDadosUsuario(dadosUser);

                    setUsuarioId(res.data.usuario.id);
                    setAvaliacao(res.data.avaliacao);
                    let endereco  =  res.data.logradouro;
                    let endereco_completo = organizar_endereco(endereco);
                    setLogradouro(endereco_completo);
                    const lista_status = res.data.status_processos.status.data;
                    const statusProgresso = res.data.status_processos.status_progresso.data;
                    const responsavel = res.data.status_processos.responsavel;
                    setStatusProgresso(statusProgresso);
                    setStatusHistorico(lista_status);
                    setResponsavelProcesso(responsavel)
                    setDocumentosPessoais(res.data.documentos);
                    setDocumentosImovel(res.data.documentos_imovel);
                    setImovel(res.data.logradouro);
                    setGerenteProcesso(res.data.gerente);
                    setVendedorProcesso(res.data.corretor_vendedor);
                    setOpcionistaProcesso(res.data.opcionista)
    
                    setProcessSelect(res.data);
                    setDadosVenda(res.data.imovel);
    
                    // returnDadosUsuario(dadosUser);
                    // setLoading(true);
                }
            })
            setLoading(true);
        };
        exibirDadosAcessoProcesso();

        // Retorna os dados de usuário
        // returnDadosUsuario();

        const listaTipoDocumentos = async () => {

            await Axios.get(app + 'tipodocumentos_acesso', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }).then(res => {
                if (res !== undefined) {
                    if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                        localStorage.clear();
                        history.push('/');
                    } else {
                        ////console.log(res.data.data);
                        const tipodocumentos = res.data.data;
                        setTipoDocumentos(tipodocumentos);
                        setDocumentsList(tipodocumentos)
                        setListaDocumentos(tipodocumentos);
                    }
                }
            })
        };
        listaTipoDocumentos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { register, handleSubmit, setValue, watch, clearErrors, formState: { errors } } = useForm({});

    function organizar_endereco(endereco) {
        let endereco_completo = '';
        endereco_completo = endereco.logradouro;
        endereco_completo += ',  ' + endereco.numero;
        endereco_completo += endereco.complemento ?  ',  ' + endereco.complemento : '';
        endereco_completo += endereco.unidade ?  ',  ' + endereco.unidade : '';
        endereco_completo += endereco.bairro ?  ' - ' + endereco.bairro : '';
        endereco_completo += ' - ' + endereco.cidade;
        endereco_completo += ' - ' + endereco.uf;
        return endereco_completo;
    }

    const handleEdit = async () => {
        // setLoading(false);
    
        let arrayData = new FormData();
        arrayData.append('cpf_cnpj', watch('cpf_cnpj'));
        arrayData.append('ddi', watch('ddi') || '55');
        arrayData.append('telefone', watch('telefone') || '');
        arrayData.append('name', watch('name') || '');
        arrayData.append('nacionalidade', watch('nacionalidade') || '');
        arrayData.append('nomeMae', watch('nome_mae') || '');
        arrayData.append('nomePai', watch('nome_pai') || '');
        arrayData.append('profissao', watch('profissao') || '');
        arrayData.append('rg', watch('rg') || '');
        arrayData.append('rgExpedicao', watch('rg_expedido') || '');
        arrayData.append('dataExpedicao', watch('data_rg_expedido') || '');
        arrayData.append('email', watch('email') || '');
        arrayData.append('data_nascimento', watch('data_nascimento') || '');
        arrayData.append('cep', watch('cep') || '');
        arrayData.append('bairro', watch('bairro') || '');
        arrayData.append('logradouro', watch('logradouro') || '');
        arrayData.append('numero', watch('numero') || '');
        arrayData.append('unidade', watch('unidade') || '');
        arrayData.append('complemento', watch('complemento') || '');
        arrayData.append('cidade', watch('cidade') || '');
        arrayData.append('estado', watch('estado') || '');
        arrayData.append('genero', watch('genero') || '');
        arrayData.append('tipo_pessoa', watch('tipo_pessoa') || '0');
        arrayData.append('usuario_id', watch('id') || '');
        arrayData.append('tipo', watch('tipo'));
        arrayData.append('estado_civil', watch('estado_civil'));
        arrayData.append('registro_casamento', watch('registro_casamento'));
        arrayData.append('uniao_estavel', watch('uniao_estavel'));
        arrayData.append('valor_conjuge', watch('conjuge'));
        arrayData.append('nome_procurador', watch('nome_procurador'))
        arrayData.append('telefone_preocurador', watch('telefone_procurador') || '');
    
        arrayData.append('imovel_id', imovel?.imovel_id || '');
        arrayData.append('processo_id', processoId || '');
    
        const result = await updateUsuarios(watch('id'), arrayData, 'temporario');
    
        if (result) {
            refresh();
            setIsShowFeedbackdados(true);
            setTimeout(() => {
                setIsShowFeedbackdados(false);
            }, 3000);
        }
    };

    return (
        <>
            <AcessoTemporarioContext.Provider value={context}>
                <Header />
                <Pane className="row-acp">
                    {
                        loading === true && 
                        <>
                            <div className="container-info">
                                {
                                    resolucao > 600
                                    ?
                                    <>
                                        <Tablist marginBottom={16} flexBasis={240} style={{width: '100%', maxWidth:'720px', margin: '0 auto', padding: '16px'}} className="tab-nav">
                                            {tabs.map((tab, index) => (
                                                <Tab
                                                    aria-controls={`panel-${tab}`}
                                                    isSelected={index === selectedIndex}
                                                    key={tab}
                                                    onSelect={() => [setSelectedIndex(index), index === 2 ? setIsShowFeedbackdados(false) : '']}
                                                    appearance="primary"
                                                >
                                                    {tab}
                                                </Tab>
                                            ))}
                                        </Tablist>

                                        <Pane padding={16} background="" flex="1">
                                            <div className="coll">
                                                <div className="info">
                                                    <div className="imovel">
                                                        <MapMarkerIcon className="icon-imovel-map"/> {logradouro}
                                                    </div>
                                                </div>
                                            </div>

                                            {/*Com exibição das tabs*/}
                                            {tabs.map((tab, index) => (
                                                <Pane
                                                    aria-labelledby={tab}
                                                    aria-hidden={index !== selectedIndex}
                                                    display={index === selectedIndex ? 'block' : 'none'}
                                                    key={tab}
                                                    role="tabpanel"
                                                >

                                                        {index === 0
                                                        ?
                                                        <div className="row-2"><Principal/></div>

                                                        :
                                                        index === 1
                                                        ?
                                                        <>
                                                            <div className="row" style={{marginTop: '25px'}}><Status/></div>
                                                            <div className="row"><Historico/></div>
                                                            <div className="row"><Ajuda/></div>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                usuario &&
                                                                <div style={{marginTop: '20px'}}>
                                                                    <DadosPessoais 
                                                                        // returnDadosUsuario={refresh} 
                                                                        register={register} 
                                                                        handleSubmit={handleSubmit} 
                                                                        setValue={setValue} 
                                                                        watch={watch} 
                                                                        errors={errors} 
                                                                        clearErrors={clearErrors} 
                                                                        processoId={processoId}
                                                                        handleEdit={handleEdit}
                                                                    />
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </Pane>
                                            ))}

                                            {/*Sem a exibição das Tabs*/}
                                            {/* <Pane>
                                                <>
                                                    <div className="row"><Status/></div>
                                                    <div className="row row-historico"><Historico/></div>
                                                    <div className="row"><Ajuda/></div>
                                                </>
                                            </Pane> */}
                                        </Pane>
                                    </>

                                    :
                                    <>
                                        
                                        {navMenu === 'principal' && 
                                            <>
                                                <Principal/>
                                            </>
                                        } 
                                       
                                        {navMenu === 'historico' && <>
                                                <div className="row"><Status/></div>
                                                <div className="row"><Historico/></div>
                                            </>
                                        }

                                        {navMenu === 'dados-pessoais'  && <>
                                                <div style={{marginTop: '20px'}}>
                                                    <DadosPessoais 
                                                        // returnDadosUsuario={refresh} 
                                                        register={register} 
                                                        handleSubmit={handleSubmit} 
                                                        setValue={setValue} 
                                                        watch={watch} 
                                                        errors={errors} 
                                                        clearErrors={clearErrors} 
                                                        processoId={processoId}
                                                        handleEdit={handleEdit}
                                                    />
                                                 </div>
                                            </>
                                        }

                                        {
                                            navMenu === 'ajuda' &&
                                            <>
                                                <div className="row"><Ajuda/></div>
                                                <div><MobileAjuda/></div>
                                            </>
                                            
                                        }
                                    </>
                                }
                            </div>
                        
                            <NavMobile 
                                // returnDadosUsuario={refresh} 
                                register={register} 
                                handleSubmit={handleSubmit} 
                                setValue={setValue} 
                                watch={watch} 
                                errors={errors} 
                                clearErrors={clearErrors} 
                                processoId={processoId}
                                handleEdit={handleEdit}
                            />
                        </>
                    }
                    
                </Pane>
            </AcessoTemporarioContext.Provider>
        </>
    )
}
