import React, { useContext, useEffect, useState } from 'react';
import { Pane, Button, Badge, DownloadIcon, LockIcon, Table, Tooltip, SmallTickIcon, toaster } from 'evergreen-ui';
import NegociacaoContext from '../../../../../context/NegociacaoContext';
import PrevisaoComissao from '../../components/Previsao_Comissao'
import { useForm } from 'react-hook-form';
import updatePagamentoComissao from '../../../../../apis/updatePagamentoComissao';

export default function Comissao({ refreshProcess }) {
  const {
    processSelect,
    processoId,
    loading,
  } = useContext(NegociacaoContext);

  const comissao = processSelect?.imovel?.comissao || ''

  const { register, handleSubmit, setValue, watch, unregister, formState: { errors } } = useForm({
    defaultValues: {
      tipo_pagamento: 'integral',
      arrayPayers: [{ id: '', parcela: 1, user_id: '', porcentagem: '', valor: '' }],
      observacoes_pagamento_comissao: ''
    }
  });

  useEffect(() => {
    if (processSelect) {
      setValue('tipo_pagamento', comissao?.comissao || '')
      setValue('observacoes_pagamento_comissao', comissao?.observacao_responsavel_pagamento || '')

      if (comissao?.vendedor_responsavel_pagamento?.[0]) {        
          setValue('arrayPayers', (comissao?.vendedor_responsavel_pagamento.map((vendedor, index) => ({
            id: vendedor.id,
            parcela: vendedor.numero_parcela,
            user_id: vendedor.usuario_id,
            porcentagem: '', // PORCENTAGEM DESATIVADA
            valor: vendedor.parcela_valor
          })))) 
      } else {
        if (watch('tipo_pagamento') === 'partes') {
          setValue('arrayPayers', comissao?.parcelas_empresa.map((parcela, index) => ({
            id: '',
            parcela: index + 1,
            user_id: '',
            porcentagem: '', // PORCENTAGEM DESATIVADA
            valor: parcela.valor_parcela
          })))
        } else {
          setValue('arrayPayers', [{
            id: '',
            parcela: 1,
            user_id: '',
            porcentagem: '', // PORCENTAGEM DESATIVADA
            valor: comissao?.valor_comissao_liquida
          }])
        }
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSave = async (e) => {
    const data = new FormData();
    data.append('responsavel_id', localStorage.getItem('usuario_id'));    
    data.append('observacoes', e.observacoes_pagamento_comissao);

    e.arrayPayers.forEach(payers => {
      data.append('processo_id[]', processSelect.id);
      data.append('parcela_id[]', payers.id);
      data.append('usuario_id[]', payers.user_id);
      data.append('parcela_valor[]', payers.valor);
      data.append('numero_parcela[]', payers.parcela);
    })

    const res = await updatePagamentoComissao(data);
    if(res.save) { 
      toaster.success(res.mensagem_pagamento);
      refreshProcess(processoId)
    }
    else toaster.danger(res.mensagem_pagamento);
  };

  const { innerHeight: height } = window;

  return (
    <Pane height={height - 187} className="formGroup formAdd flexColl space-between gap24">
      <Pane>
        <h2 className="f18 fw600 mb24">Selecione o vendedor (ou vendedores) responsável pela comissão e valores do pagamento.</h2>
        {/* <p className='fw400 f12 mb16'>Faça aqui o download da Planilha inserida no sistema através do seu upload do arquivo .</p> */}

        <PrevisaoComissao refreshProcess={refreshProcess} watch={watch} setValue={setValue} errors={errors} register={register} unregister={unregister} active={true} />
      </Pane>

      <Pane className='flex justify-end'>
        <Button
          type="submit"
          appearance="primary"
          width={153}
          onClick={handleSubmit(e => handleSave(e))}
          isLoading={loading}
        >
          <SmallTickIcon size={12} color="#fff" marginRight={10} /> Salvar correções
        </Button>
      </Pane>
    </Pane>
  )
}