import React, { useState, useContext} from 'react';
import { Pane, Heading, Tablist, Tab, Card, SideSheet} from 'evergreen-ui';

import PosNegociacaoContext from '../../../context/PosNegociacaoContext';

import FormImovel from './FormsSideSheet/Imovel';
import FormVenda from './FormsSideSheet/Venda';
import Historico from './FormsSideSheet/Historico';

import '../../../index.css';

export default function SideSheetView() {

    const {
        //sideId, sideProcesso,
        isShownSide, setIsShownSide,
        tabsVisualization,
        dadosReciboEnviado,
        selectedIndexEnviados, setSelectedIndexEnviados
    } = useContext(PosNegociacaoContext);

    const [tabs] = useState(['Imóvel', 'Venda', 'Histórico']);

    return (
        <SideSheet
            isShown={isShownSide}
            onCloseComplete={() => setIsShownSide(false)}
            containerProps={{
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
                className: 'side-sheet-box'
            }}
            preventBodyScrolling
        >
            <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
                <Pane padding={16} borderBottom="muted">
                    <Heading size={600}>
                        {dadosReciboEnviado !== [] ? dadosReciboEnviado.logradouro + ', ' + dadosReciboEnviado.numero + (dadosReciboEnviado.unidade !== null ? dadosReciboEnviado.unidade : '' ) : ''}
                    </Heading>
                </Pane>
                <Pane display="flex" padding={8}>
                    <Tablist>
                        {tabs.map((tab, index) => (
                            <Tab
                                key={tab}
                                isSelected={selectedIndexEnviados === index}
                                onSelect={() => setSelectedIndexEnviados(index)}
                                aria-controls={`panel-${index}`}
                                aria-selected={selectedIndexEnviados === index ? true : false}
                                className={
                                    tabsVisualization === false
                                        ? index > 0 ? 'tab-disable' : 'tab-enable'
                                        : 'tab-enable'
                                }
                                style={selectedIndexEnviados === index ? { boxShadow: '0 0 0 2px #d6e0ff' } : { boxShadow: 'none' }}
                            >
                                {tab}
                            </Tab>
                        ))}
                    </Tablist>
                </Pane>
            </Pane>
            <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
                {tabs.map((tab, index) => (
                    <Card
                        key={tab}
                        id={`panel-${tab}`}
                        role="tabpanel"
                        aria-labelledby={tab}
                        aria-hidden={index !== selectedIndexEnviados}
                        display={index === selectedIndexEnviados ? 'block' : 'none'}
                    >
                        {/*Id do processo - sideId*/}
                        {tab === 'Imóvel' && <FormImovel />}
                        {tab === 'Venda' && <FormVenda />}
                        {tab === 'Histórico' && <Historico />}
                    </Card>
                ))}
            </Pane>
        </SideSheet>
    )
}