import React, { useContext, useState, useEffect } from 'react'
import { Pane, Button, StarIcon, HelpIcon, Badge, UploadIcon, Image, UpdatedIcon, DownloadIcon, PersonIcon } from 'evergreen-ui';
//import '../../../styles.css';
import AcessoTemporarioContext from '../../../../../context/AcessoTemporarioContext';
import Documentos from './Documentos';
// import fileSign from '../../../../../images/file-sign.svg';
import Axios from 'axios';
import Serve from '../../../../../app/serve_login';

export default function Principal({recibo}) {

    const app = Serve();
    const [processo_id] = useState(localStorage.getItem('processo_id'));

    const {
        isShownDocuments, setIsShownDocuments,
        isShowDadosPessoais, setIsShowDadosPessoais
    } = useContext(AcessoTemporarioContext);

    const handleOpenDocuments = (e) => {
        e.preventDefault();
        //console.log('clicou no botão')
        setIsShownDocuments(true);
        //console.log(isShownDocuments);
    }

    const showDocument = (id, tipo) => {
        //console.log(id);
        //console.log(tipo);
        Axios.post(app + 'exibir_arquivo_acesso', {
            "id": id,
            "tipo": tipo
        }, {
            responseType: 'blob',
        }).then(res => {
            //console.log(res);
            let type_arquivo = res.data.type;
            if (type_arquivo !== 'text/html') {
                const file = new Blob(
                    [res.data],
                    { type: type_arquivo }
                );
                //console.log(file);
                if (type_arquivo === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || type_arquivo === 'application/msword') {
                    let finalTypeArquivo = 'docx';
                    if (type_arquivo === 'application/msword') {
                        finalTypeArquivo = 'doc';
                    }
                    let url = window.URL.createObjectURL(file);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = "arquivo_DNA." + finalTypeArquivo;
                    a.click();
                } else {
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }
    
            } else {
                //console.log("não teve retorno satisfatorio");
            }
        })  
    }

    const handleShowDadosPeossoais = (e) => {
        e.preventDefault();
        setIsShowDadosPessoais(true);
    }

    return (
        <Pane>
            <div className='mb20 fw900 span'>
                {/* <span className="title-badge">Pendências</span>
                <Badge color='#EDEFF5' marginLeft={8}>
                    2
                </Badge> */}
            </div>

            <div className="coll-status cardAcessoVendedor mb20" style={{padding: '20px 0'}}>
                {/* <Badge color='#E7F4D7' className='mt19 badge'><span style={{color: '#6B9539'}}>PARA FAZER</span></Badge> */}

                <div className="mt11 f24 fontGreen" style={{margin: '10px 0'}}>
                    Recibo de Sinal
                </div>
                <div className="mt3 fw400" style={{marginBottom: '20px'}}>
                    Aqui você pode visualizar o recibo de sinal
                </div>
                
                <Button 
                    className="btn-default btn-dna-green mt22" 
                    height={48}
                    width={312}
                    iconBefore={<DownloadIcon size={14} color="white"/>}
                    onClick={(e) => showDocument(processo_id, 'recibo')}
                >
                    Visualizar documento
                </Button>
                
            </div>
            
            <div className="coll-status cardAcessoVendedor mb20" style={{padding: '20px 0'}}>
                {/* <Badge color='#E7F4D7' className='mt19 badge'><span style={{color: '#6B9539'}}>PARA FAZER</span></Badge> */}

                <div className="mt11 f24 fontGreen" style={{margin: '10px 0'}}>
                    Documentos necessários
                </div>
                <div className="mt3 fw400" style={{marginBottom: '20px'}}>
                    Agilize sua negociação e envie seus documentos
                </div>
                
                <Button 
                    className="btn-default btn-dna-green mt22" 
                    height={48}
                    width={312}
                    iconBefore={<UploadIcon size={14} color='white' />}
                    onClick={(e) => handleOpenDocuments(e)}
                >
                    Enviar documentos
                </Button>

                <Documentos />
            </div>
        </Pane>        
    )
}
