import React, { useContext, useState } from 'react';
import Axios from 'axios';
import { CornerDialog, InfoSignIcon } from 'evergreen-ui';
import NegociacaoContext from '../../../../context/NegociacaoContext';
import Serve from '../../../../app/serve';
import { useEffect } from 'react';

export default function DialogNucleoFeedBack({openSideSheet}) {

    const token = localStorage.getItem('token');
    const app = Serve();
    const [pos_id] = (localStorage.getItem('usuario_id'));

    const {
        setLoading,
        idSolicitacaoNucleo,
        processoId,
        setSelectedIndex,
        isShownFeedBackNucleo, setIsShownFeedBackNucleo,
    } = useContext(NegociacaoContext);
    const [regressivaEntregarVenda, setRegressivaEntregarVenda] = useState(0);

    useEffect(() => {
        var timeleft = 5;
        var downloadTimer = setInterval(function () {
            if (timeleft <= 0) {
                clearInterval(downloadTimer);
                setRegressivaEntregarVenda(0)
            } else {
                setRegressivaEntregarVenda(timeleft)
            }
            timeleft -= 1;
        }, 1000);
    }, [isShownFeedBackNucleo])

    const handleCloseVendaEntregue = (e) => {        
        setIsShownFeedBackNucleo(false);
        setRegressivaEntregarVenda(5);
        //Se estiver no tempo precisa editar o estatus de Entragar Venda
    };

    const handleCancelarEnvio = async () => {
        if (regressivaEntregarVenda !== 0) {
            setLoading(true);
            setIsShownFeedBackNucleo(false);

            await Axios.post(app + 'deletar_status_solicitacao', {
                "solicitacao_id": idSolicitacaoNucleo,
                "responsavel_alteracao_id": pos_id
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                } else {
                    // console.log(res);
                }
            }).catch(function (error) {
                console.log(error);
            }).then(() => {
                setLoading(false);
            })
        }
        else {
            setIsShownFeedBackNucleo(false);
        }
    };

    const handleDetalhes = (close) => {
        setSelectedIndex(5);
        openSideSheet(processoId)
        close();
    };


    return (
        <CornerDialog
            title="Solicitação enviada!"
            
            isShown={isShownFeedBackNucleo}
            onCloseComplete={(e) => handleCloseVendaEntregue(e)}
            confirmLabel={<div className='btnAlign'><InfoSignIcon /><span>Ver Detalhes</span></div>}
            onCancel={(e) => handleCancelarEnvio()}
            onConfirm={(close) => handleDetalhes(close)}
            cancelLabel={regressivaEntregarVenda !== 0 ? "Cancelar solicitação (" + regressivaEntregarVenda + ")" : "Fechar"}
            width={512}
        >
            Sua soliticação de Serviço ao Núcleo de Certidões foi enviada. <br />
            Agora é só aguardar o upload dos documentos na plataforma.
        </CornerDialog >
    )
}