import React, { useContext, useState } from 'react';
import { Pane, Table } from 'evergreen-ui';
import ShowDocument from '../../../../../../app/showDocument';
import DirecaoContext from '../../../../../../context/DirecaoContext';
import ReturnDocumentsForView from '../../../../../../components/MultiViewDocs';

// MSG QUANDO CAMPO VAZIO
const msgCampo = '----';

function ColTable(props) {
    return (
        <Table.TextCell maxWidth={props.width}>
            {props.title && <div className={`mb7 color101840 `}>{props.title}</div>}

            <span className='fw400 color696F8C'>
                {props.subtitle || msgCampo}
            </span>
        </Table.TextCell>
    )
};

export default function Imovel(props) {
    const [token] = useState(localStorage.getItem('token'));

    const msgCampo = "----";

    const {
        dadosVenda,
        tipoLaudemio,
    } = useContext(DirecaoContext);

    //lê os laudemios retornados em dadosVenda
    const laudemiosEnviados = dadosVenda.laudemios.map(laudemio => parseInt(laudemio.valor_laudemio));
    //Comparar dadosVenda a uma lista de todos os laudemios "tipoLaudemio" e retorna o id e nomes para exibir
    const laudemioValorToName = tipoLaudemio.filter((laudemio) => laudemiosEnviados.includes(laudemio.id));
    const laudemiosSemNome = dadosVenda.laudemios.filter((laudemio) => laudemio.tipo_laudemio === "2" || laudemio.tipo_laudemio === "1");
    laudemioValorToName.push(...laudemiosSemNome);

    return (
        <div className="detadelhes-sidesheet">
            {
                dadosVenda.length === 0
                    ?
                    <Table.Row>
                        <Table.TextCell className="historicoStatus" width={150}>
                            <Table.TextCell className="fontStatus">
                                <span>{msgCampo}</span>
                            </Table.TextCell>
                        </Table.TextCell>
                    </Table.Row>

                    :
                    <>
                        <Pane>
                            <React.Fragment>
                                <h2 className="subtitle subtitle-table">Valores</h2>
                            </React.Fragment>
                        </Pane>
                        <Table.Row>
                            <ColTable title={"Valor de venda"} width="130px" subtitle={dadosVenda.informacao?.valor_venda} />
                            <ColTable title={"Valor anunciado"} subtitle={dadosVenda.informacao?.valor_anunciado} />
                        </Table.Row>

                        <Pane>
                            <React.Fragment>
                                <h2 className="subtitle subtitle-table">Documentos do imóvel</h2>
                            </React.Fragment>
                        </Pane>
                        <ReturnDocumentsForView docs={dadosVenda.documentos.data.filter(e => e.identifica_documento === "imóvel")} />
                        {/* {dadosVenda.documentos.data.length === 0 ? <span>Não há documentos cadastrados.</span>
                            : dadosVenda.documentos.data.filter(e => e.identifica_documento === "imóvel").map(doc =>
                                <ReturnDocumentsForView doc={doc} />
                            )} */}

                        <Pane>
                            <React.Fragment>
                                <h2 className="subtitle subtitle-table">Observações</h2>
                            </React.Fragment>
                        </Pane>
                        <Table.Row>
                            <ColTable subtitle={dadosVenda.informacao?.observacao} />
                        </Table.Row>
                    </>
            }
        </div>
    )
}