import React, { useState } from 'react';
import './index.css';
import { Pane } from 'evergreen-ui';

import TablePanel from './component/TablePanel';
import { useHistory } from 'react-router-dom';

export default function Negociacoes() {
    const [perfil_login] = useState(localStorage.getItem('perfil_login'));
    const history = useHistory();

    if(perfil_login !== "Gerente"){
        history.push('/');
    }

    return (

        <Pane>
            <div className="painel">
                <h1 className="title">Histórico de processos cadastrados</h1>
                <TablePanel />
            </div>
        </Pane>
    )
}