import React, { useContext, useState, useEffect } from 'react';
import 'react-phone-input-2/lib/bootstrap.css'
import { Pane, Spinner, Tab, Tablist, Table, Badge } from 'evergreen-ui';
import ReturnDocumentsForView from '../../../../../../components/MultiViewDocs'

//Context
import VendasContext from '../../../../../../context/VendasContext';

export default function Comprador() {

    const [idComprador, setIdComprador] = useState('');
    const [token] = useState(localStorage.getItem('token'));

    const {
        loading,
        dadosReciboEnviado,
        setValidateMinutaCompradorGenero,
        setValidateMinutaCompradorEstadoCivil,
        setValidateMinutaCompradorRegimeCasamento,
        setCheckedMinutaCompradorUniaoEstavel,
        setMinutaCompradorEstadoCivil,
        setMinutaCompradorRegimeCasamento,
        setMinutaCompradorUniaoEstavel,
        setValidateMinutaCompradorConjuge,
    } = useContext(VendasContext);

    //Tabs e PF/PF
    const idProcesso = dadosReciboEnviado.processo_id;
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [tabsCadastro, setTabsCadastro] = useState([])
    const [posicaoTabs, setPosicaoTabs] = useState([]);
    const [displayForm, setDisplayForm] = useState(false);
    const [checkedPessoaJuridica, setCheckedPessoaJuridica] = useState(false);
    const [tipoPessoaValues, setTipoPessoaValues] = useState([]);

    const [validarPF, setValidarPf] = useState([]);
    const [validarPJ, setValidarPJ] = useState([]);

    const [compradoresArray, setCompradoresArray] = useState([]);
    const [estadoCivilValues, setEstadoCivilValues] = useState([]);
    const [idsCompradores, setIdsCompradores] = useState([]);
    const [generoUsuario, setGeneroUsuario] = useState([]);
    const [valorGenero, setValorGenero] = useState('');
    const [telNumberValue, setTelNumberValue] = useState([]);
    const [countryCode, setCountryCode] = useState([]);
    const [sinalizarValidacao, setSinalizarValidacao] = useState([]);
    const [disableButton, setDisableButton] = useState([]);
    //array de endereço
    const [arrayEndereco, setArrayEndereco] = useState([]);

    useEffect(() => {
        const returnComprador = async () => {

            if (dadosReciboEnviado.compradores['data'].length > 0) {
                setDisplayForm(true);
                setPosicaoTabs([...posicaoTabs, 1]);
                //setPrimeiroDocumento(true);
                const tipoPessoa = dadosReciboEnviado.compradores['data'].map(usuario => ({ "usuario_id": usuario.id, "tipo_pessoa": usuario.tipo_pessoa == 1 ? true : false }));
                setTipoPessoaValues(tipoPessoa);

                const estadoCivilSalvo = dadosReciboEnviado.compradores['data'].map(usuario => ({ "estado_civil": usuario.estado_civil, "uniao_estavel": usuario.uniao_estavel, "regime_casamento": usuario.registro_casamento, "conjuge": usuario.conjuge }));
                setEstadoCivilValues(estadoCivilSalvo);

                const IdsUsuarios = dadosReciboEnviado.compradores['data'].map(usuario => ({ "usuario_id": usuario.id }));
                setIdsCompradores(IdsUsuarios);

                const generoSalvo = dadosReciboEnviado.compradores['data'].map(usuario => ({ "genero": usuario.genero }));
                setGeneroUsuario(generoSalvo);
                let validarGenero = generoSalvo.filter(usuario => usuario.genero === generoSalvo);
                dadosReciboEnviado.compradores['data'][0].genero !== null ? setValidateMinutaCompradorGenero(true) : setValidateMinutaCompradorGenero(false);
                setValorGenero(dadosReciboEnviado.compradores['data'][0].genero);

                const responsaveisPj = dadosReciboEnviado.compradores['data'].map(responsavel => ({ nome: responsavel.name, email: responsavel.email, cpf: responsavel.cpf_cnpj }));


                const telefoneSalvo = await dadosReciboEnviado.compradores['data'].map(usuario => ({ "telefone": usuario.telefone }));
                setTelNumberValue(telefoneSalvo);

                const countryCodeDDI = await dadosReciboEnviado.compradores['data'].map(usuario => ({ "ddi": usuario.ddi }));
                setCountryCode(countryCodeDDI);

                /*dadosReciboEnviado.compradores.data.map((comprador, index) => {
                    rgNumber.current[index] = comprador.rg;
                    rgExpedicao.current[index] = comprador.rg_expedido;
                    rgDataExpedicao.current[index] = comprador.data_rg_expedido;
                    comprador.rg !== '' && comprador.rg
                        ? disableButton[index] = false
                        : disableButton[index] = true;
                });*/

                var arrayFinal = [];
                let arrayValidarSInalizacao = [];
                //let arrayForm = [];

                dadosReciboEnviado.compradores['data'].forEach((element, key_compradores) => {

                    arrayFinal.push(key_compradores);
                    arrayValidarSInalizacao.push('ok');
                    //let arrayRepresentantes = [];
                    //pessoa juridica

                    //endereco
                    let addArrayEndereco = { cep: element.cep, bairro: element.bairro, logradouro: element.logradouro, numero: element.numero, unidade: element.unidade, complemento: element.complemento, cidade: element.cidade, estado: element.uf };
                    arrayEndereco.push(addArrayEndereco);
                    setArrayEndereco([...arrayEndereco]);


                });
                setTabsCadastro(arrayFinal);
                setSinalizarValidacao(arrayValidarSInalizacao);

                //verificarTipoComprador(0);
            }
            else {
                setDisplayForm(false);

                setTabsCadastro([1]);
                setDisableButton([true]);
                setTipoPessoaValues([...tipoPessoaValues, { usuario_id: "", tipo_pessoa: false }])
                setEstadoCivilValues([...estadoCivilValues, { estado_civil: "", uniao_estavel: "", regime_casamento: '0', conjuge: "" }])
                setIdsCompradores([...idsCompradores, { usuario_id: '' }])
                setGeneroUsuario([...generoUsuario, { genero: '' }]);
                setTelNumberValue([...telNumberValue, { telefone: '' }]);
                setCountryCode([...countryCode, { ddi: '' }]);

                setArrayEndereco([...arrayEndereco, { cep: '', bairro: '', logradouro: '', numero: '', unidade: '', complemento: '', cidade: '', estado: '' }])


                //Pessoa Física e Júridica
                if (checkedPessoaJuridica === false) {
                    setCompradoresArray([...compradoresArray, { tipo_pessoa: 0, cpf_cnpj: '' }]);

                    setValidarPf([...validarPF, {
                        cpf: false,
                        telefone: false,
                        genero: false,
                        nome: false,
                        nacionalidade: false,
                        nomeMae: false,
                        estadoCivil: false,
                        regimeCasamento: false,
                        uniaoEstavel: false,
                        conjuge: false,
                        cep: false,
                        numero: false,
                    }])

                    setValidarPJ([...validarPJ, {}]);
                }
                else {
                    setCompradoresArray([...compradoresArray, { tipo_pessoa: 1, cpf_cnpj: '' }]);

                    setValidarPJ([...validarPJ, {
                        cnpj: false,
                        telefone: false,
                        razaoSocial: false,
                        nomeFantasia: false,
                        cep: false,
                        numero: false,
                    }])

                    setValidarPf([...validarPF, {}]);

                    //Representante PJ                    
                }
            }
        }
        returnComprador();
    }, []);

    const verificaGenero = async () => {
        let validarGenero = generoUsuario.filter(usuario => usuario.genero === '');
        validarGenero.length === 0 ? setValidateMinutaCompradorGenero(true) : setValidateMinutaCompradorGenero(false);
    }

    const validarEtapasEstadoCivil = async (e, index) => {
        setValidateMinutaCompradorEstadoCivil(true);
        setValidateMinutaCompradorRegimeCasamento(true);
        setValidateMinutaCompradorConjuge(true);

        let constValidarEstadoCivil = 0;
        let constValidarRegimeCasamento = 0;
        let constValidarConjuge = 0;

        estadoCivilValues.forEach((element, index_estado) => {
            if (element.estado_civil === '' || element.estado_civil === null || element.estado_civil === 0) {
                constValidarEstadoCivil += 1;
            }

            if (element.estado_civil === '1' && element.regime_casamento === '0') {
                constValidarRegimeCasamento += 1;
            }

            if ((element.estado_civil === '1' && element.conjuge === '') || (element.estado_civil === '1' && element.conjuge === null)) {
                constValidarConjuge += 1;
            }

            if ((element.estado_civil !== '1' && element.uniao_estavel === 'S' && element.conjuge === '') ||
                element.estado_civil !== '1' && element.uniao_estavel === 'S' && element.conjuge === null) {
                constValidarConjuge += 1;
            }
        });

        if (constValidarEstadoCivil > 0) {
            setValidateMinutaCompradorEstadoCivil(false);
        }

        if (constValidarRegimeCasamento > 0) {
            setValidateMinutaCompradorRegimeCasamento(false);
        }

        if (constValidarConjuge > 0) {
            setValidateMinutaCompradorConjuge(false);
        }
    }

    const handleOpenTab = async (index) => {

        verificaGenero();
        setValorGenero(generoUsuario[index].genero);

        setCheckedPessoaJuridica(tipoPessoaValues[index].tipo_pessoa);
        if (dadosReciboEnviado.compradores.data[index] !== undefined) {
            setIdComprador(dadosReciboEnviado.compradores.data[index].id);
        } else {
            setIdComprador('');
        }

        //retornar dados estado civil
        const valorEstadoCivil = estadoCivilValues[index]['estado_civil'];
        const valorUniaoEstavel = (estadoCivilValues[index]['uniao_estavel'] === 'S') ? true : false;

        setMinutaCompradorEstadoCivil(valorEstadoCivil);

        if (valorEstadoCivil === '1') {
            //Zera o checkbox de União Estável
            setCheckedMinutaCompradorUniaoEstavel(false);
            setMinutaCompradorUniaoEstavel('');
            setMinutaCompradorRegimeCasamento(estadoCivilValues[index]['regime_casamento']);
        } else {
            setMinutaCompradorRegimeCasamento('0');
            setCheckedMinutaCompradorUniaoEstavel(valorUniaoEstavel);
        }
        validarEtapasEstadoCivil();
    }

    return (
        <Pane>
            {
                dadosReciboEnviado.compradores.data.length === 0
                    ?
                    <Table.Row height='auto'>
                        <Table.TextCell padding={12}>
                            <span>Nenhum Comprador foi cadastrado no momento.</span>
                        </Table.TextCell>
                    </Table.Row>

                    :
                    <>
                        <div className="form-dialog">
                            <Tablist marginBottom={16} flexBasis={240} marginRight={24} className="tab-form">
                                {tabsCadastro.map((tabCadastro, index) => (
                                    <Tab
                                        key={tabCadastro}
                                        id={"titulo_top_comprador_" + index}
                                        onSelect={() => setSelectedIndex(index)}
                                        isSelected={index === selectedIndex}
                                        aria-controls={`panel-${tabCadastro}`}
                                        data-tab={tabCadastro}
                                        onClick={() => handleOpenTab(index)}
                                    >

                                        {index < dadosReciboEnviado.compradores.data.length
                                            ? (dadosReciboEnviado.compradores.data[index].tipo_pessoa === 1)
                                                ? dadosReciboEnviado.compradores.data[index].nome_fantasia.slice(0, 10) + '...'
                                                : dadosReciboEnviado.compradores.data[index].name.slice(0, 10) + '...'
                                            : 'Comprador ' + tabCadastro}
                                    </Tab>
                                ))}
                            </Tablist>
                        </div>
                        <div className="form-detalhes">
                            <div className="formGroup formAdd">
                                {tabsCadastro.map((tabCadastro, index) => (
                                    <Pane
                                        key={tabCadastro}
                                        id={`panel-${tabCadastro}`}
                                        role="tabpanel"
                                        aria-labelledby={tabCadastro}
                                        aria-hidden={index !== selectedIndex}
                                        display={index === selectedIndex ? 'block' : 'none'}
                                        className="detadelhes-sidesheet"
                                    >
                                        <Pane>
                                            <Badge color="green" className="badge-tipo-pesso">
                                                <span>
                                                    {dadosReciboEnviado.compradores.data[index].tipo_pessoa === 1 ? 'Pessoa Jurídica' : 'Pessoa Física'}
                                                </span>
                                            </Badge>

                                            {dadosReciboEnviado.compradores.data[index].tipo_pessoa === 0 &&
                                                dadosReciboEnviado.compradores.data[index].procurador !== null ?
                                                <>
                                                    <React.Fragment>
                                                        <h2 className="subtitle subtitle-table">Procurador</h2>
                                                    </React.Fragment>

                                                    <Table.Row height='auto'>
                                                        <Table.TextCell padding={12}>
                                                            <p className='mb10'>Nome completo</p>
                                                            <span className="fw400">
                                                                {
                                                                    index < dadosReciboEnviado.compradores.data.length
                                                                        ? dadosReciboEnviado.compradores.data[index].procurador !== null ? dadosReciboEnviado.compradores.data[index].procurador.nome : ''
                                                                        : ''
                                                                }
                                                            </span>
                                                        </Table.TextCell>
                                                    </Table.Row>

                                                    <Table.Row height='auto'>
                                                        <Table.TextCell padding={12}>
                                                            <p className='mb10'>Telefone/Celular</p>
                                                            <span className="fw400">
                                                                {
                                                                    index < dadosReciboEnviado.compradores.data.length
                                                                        ? dadosReciboEnviado.compradores.data[index].procurador !== null ? dadosReciboEnviado.compradores.data[index].procurador.telefone : ''
                                                                        : ''
                                                                }
                                                            </span>
                                                        </Table.TextCell>
                                                    </Table.Row>
                                                </>

                                                :
                                                ''
                                            }

                                            <React.Fragment>
                                                <h2 className="subtitle subtitle-table">
                                                    {dadosReciboEnviado.compradores.data[index].tipo_pessoa === 0 ? 'Documentos do Comprador' : 'Documentos da Empresa'}
                                                </h2>
                                            </React.Fragment>
                                            {console.log(dadosReciboEnviado.compradores.data[index].documentos.data)}
                                            <ReturnDocumentsForView docs={dadosReciboEnviado.compradores.data[index]?.documentos?.data} />                                            

                                            {
                                                dadosReciboEnviado.compradores.data[index].tipo_pessoa === 1
                                                    ?
                                                    <>
                                                        <React.Fragment>
                                                            <h2 className="subtitle subtitle-table">
                                                                Sócio e/ou Representantes Legais
                                                            </h2>
                                                        </React.Fragment>
                                                        {
                                                            dadosReciboEnviado.compradores.data[index].representante_socios.data.map((representante, index_representante) =>

                                                                <>
                                                                    <Table.Row height='auto'>
                                                                        {
                                                                            representante.pj_representante === 1
                                                                                ?
                                                                                <Table.TextCell padding={12}>
                                                                                    <Badge color="green" className="badge-tipo-pesso">
                                                                                        <span>
                                                                                            REPRESENTANTE LEGAL
                                                                                        </span>
                                                                                    </Badge>
                                                                                </Table.TextCell>

                                                                                :
                                                                                ''
                                                                        }


                                                                        {
                                                                            representante.pj_socio === 1
                                                                                ?
                                                                                <Table.TextCell padding={12}>
                                                                                    <Badge color="green" className="badge-tipo-pesso">
                                                                                        <span>
                                                                                            SÓCIO
                                                                                        </span>
                                                                                    </Badge>
                                                                                </Table.TextCell>
                                                                                :
                                                                                ''
                                                                        }
                                                                    </Table.Row>

                                                                    <Table.Row height='auto'>
                                                                        <Table.TextCell padding={12}>
                                                                            <p className='mb10'>Nome completo</p>
                                                                            <span className="fw400">
                                                                                {representante.name !== null ? representante.name : ''}
                                                                            </span>
                                                                        </Table.TextCell>
                                                                    </Table.Row>

                                                                    <ReturnDocumentsForView docs={representante.documentos.data} />                                                                    
                                                                </>
                                                            )
                                                        }
                                                    </>

                                                    : ''
                                            }
                                        </Pane>
                                    </Pane>
                                ))}
                            </div>
                        </div>
                    </>
            }
        </Pane>
    )
}