import React, { useState, useEffect } from 'react';
import { Pane } from 'evergreen-ui';
import ApoioContext from '../../context/ApoioContext'
import Navbar from '../../components/Navbar';
import TablePainel from './components/TablePainel';
import getOpcionistas from '../../apis/getOpcionistas';
import getLaudemiosLis from '../../apis/getLaudemiosList';
import getResponsaveis from '../../apis/getResponsaveis';
import getGerenteList from '../../apis/getGerenteList';
import getStatusList from '../../apis/getStatusListProcessos';
import getEnderecos from '../../apis/getEnderecos';
import ChangeLog from '../components/ChangeLog';
import ConfirmaEnvioPlanilha from './components/CornerDialog/ConfirmaEnvioPlanilha';
import getBankList from '../../apis/getBankList';
import getListEmpresas from '../../apis/getListEmpresas';
import getBanesh from '../../apis/getBanesh';
import getProcessTableApoio from '../../apis/getProcessTableApoio';
import getListEscrituras from '../../apis/getListEscrituras';

export default function PainelApoio() {
  const [loading, setLoading] = useState();
  const [tipoDocumentos, setTipoDocumentos] = useState([]);
  const [baneshBank, setBaneshBank] = useState([]);
  const [totalProcessos, setTotalProcessos] = useState(0);
  const [colunaNovo, setColunaNovo] = useState([]);
  const [totalProcessosFiltrados, setTotalProcessosFiltrados] = useState(0);
  const [totalPaginas, setTotalPaginas] = useState(1);

  // LISTAS
  const [listaResponsaveis, setListaResponsaveis] = useState([]);
  const [listaEndereco, setListaEndereco] = useState([]);
  const [listaStatus, setListaStatus] = useState([]);
  const [listaGerente, setListaGerente] = useState([]);
  const [listaProcessos, setListaProcessos] = useState([]);
  const [listLaudemios, setListLaudemios] = useState([]);
  const [listBank, setListBank] = useState([]);
  const [listEmpresas, setListEmpresas] = useState([]);
  const [listEscrituras, setListEscrituras] = useState([]);

  //SIDESHEET
  const [isShownSide, setIsShownSide] = useState(false);
  const [selectProcess, setSelectProcess] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [idProcess, setIdProcess] = useState(0);

  //CORNER DIALOG
  const [isShownConfirmaPlanilha, setIsShownConfirmaPlanilha] = useState(false);

  //COMISSAO
  const [listOpcionistas, setListOpcionistas] = useState([]);
  const [isShownPlanilha, setIsShownPlanilha] = React.useState(false);
  const [listaPeriodo] = useState([
    { label: 'Na assinatura do Recibo de Sinal', value: '1' },
    { label: 'Na retirada das certidões', value: '2' },
    { label: 'No ato da escritura do Imóvel', value: '3' },
    { label: 'Na entrega das chaves', value: '4' },
    { label: 'Na transferência de registros', value: '5' },
  ])

  //Filtros
  const [filtro] = useState({
    'responsavel': Number(localStorage.getItem('filtro_responsavel')) || '',
    'status': Number(localStorage.getItem('filtro_status')) || '',
    'gerente': JSON.parse(localStorage.getItem('filtro_gerente')) || '',
    'endereco': localStorage.getItem('filtro_endereco') || '',
  });

  const [pageNumber, setPageNumber] = useState(Number(localStorage.getItem('page_number')) || 1);

  const conxtextStatus = {
    loading, setLoading,

    //LISTAS
    listaEndereco, tipoDocumentos,
    listaResponsaveis, listaStatus,
    listaGerente, listaProcessos,
    colunaNovo, listLaudemios,
    listBank, listEmpresas,
    baneshBank,
    listEscrituras,

    //SIDESHEET
    isShownSide, setIsShownSide,
    selectProcess, setSelectProcess,
    selectedIndex, setSelectedIndex,
    idProcess, setIdProcess,

    //CORNER DIALOG
    isShownConfirmaPlanilha, setIsShownConfirmaPlanilha,

    //COMISSAO
    listOpcionistas,
    isShownPlanilha, setIsShownPlanilha,
    listaPeriodo,

    filtro,
    totalProcessos, setTotalProcessos,
    setListaProcessos,
    pageNumber, setPageNumber,
    totalPaginas, setTotalPaginas,
    totalProcessosFiltrados, setTotalProcessosFiltrados,
  };

  async function getFiltersList() {
    const responsaveis = await getResponsaveis();
    const gerentes = await getGerenteList();
    const status = await getStatusList();
    const enderecos = await getEnderecos();
    const banks = await getBankList();
    const empresas = await getListEmpresas();
    const banesh = await getBanesh();
    const tiposEscrituras = await getListEscrituras();

    setListaResponsaveis(responsaveis);
    setListaGerente(gerentes);
    setListaStatus(status);
    setListaEndereco(enderecos);
    setListBank(banks);
    setListEmpresas(empresas);
    setBaneshBank(banesh);
    setListEscrituras(tiposEscrituras);
  };

  useEffect(() => {
    getFiltersList();
  }, [])

  async function getList() {
    setLoading(true);
    // const data = await getProcessos(filtro, pageNumber);
    const dataResume = await getProcessTableApoio(filtro, pageNumber);
    const opcionistas = await getOpcionistas();
    const laudemios = await getLaudemiosLis();
    // data?.processos?.data.map((processo) => (
    //   processo.imovel?.planilha_antiga ?
    //     processo.imovel.comissao.status_visualizacao_apoio = 1 : ''
    // ))
    setListaProcessos(dataResume?.solicitacoes?.data);
    setListOpcionistas(opcionistas);
    setListLaudemios(laudemios);
    setTotalPaginas(dataResume.total_pagina);
    setTotalProcessos(dataResume.total_solicitacoes);
    setTotalProcessosFiltrados(dataResume.total_solicitacoes_filtradas);

    setLoading(false);
  };

  return (
    <Pane>
      <ApoioContext.Provider value={conxtextStatus}>
      <Navbar Context={ApoioContext} />
        <Pane className="painel mt68">
          <div class="flex-container">
            <div>
              <h1 className="title">
                Histórico de Processos
              </h1>
              <h2 className="subtitle mt10">
                {'Olá, ' + localStorage.getItem('nome_usuario') + '! Aqui você pode acompanhar todos os processos solicitados pelo Gerente.'}
              </h2>
            </div>
          </div>

          <TablePainel getList={getList} />
          <ChangeLog data="Apoio" />
          <ConfirmaEnvioPlanilha />

        </Pane>
      </ApoioContext.Provider>
    </Pane>
  )
}