import React, { useContext, useState } from 'react';
import { Pane, Table, Heading, Button, Link, Text } from 'evergreen-ui';
import NucleoContext from '../../../../context/NucleoContext';
import ShowDocument from '../../../../app/showDocument';

export default function Venda() {
  const {
    processSelect,
  } = useContext(NucleoContext);
  const [vendaProcesso] = useState(processSelect.processo.imovel.informacao);
  const [token] = useState(localStorage.getItem('token'));
  console.log(vendaProcesso);

  return (
    <Pane padding={10}>
      <Heading size={600}>
        Recibo de Sinal assinado
      </Heading>
      <Pane>
        <Table.Row className="h65">
          <Table.TextCell flexBasis={600} flexShrink={0} flexGrow={0}>
            <span className="fontStatus">Data de assinatura</span><br />
            {vendaProcesso.data_assinatura || '---'}
          </Table.TextCell>
        </Table.Row>
        <Table.Row className="h65">
          <Table.TextCell flexBasis={600} flexShrink={0} flexGrow={0}>
            <span className="fontStatus mr10">Arquivo do Recibo de Sinal</span>
            <Link className='content-click font12' onClick={() => ShowDocument(processSelect.processo.imovel.id, 'recibo', token)}>{vendaProcesso.recibo}</Link>
          </Table.TextCell>
        </Table.Row>

        <Heading size={600} className='mt20'>
          Formas de pagamentos
        </Heading>
        <Table.Row className="h65">
          <Table.TextCell flexBasis={600} flexShrink={0} flexGrow={0}>
            {vendaProcesso.forma_pagamento_nome || '---'}
          </Table.TextCell>
        </Table.Row>
        <Table.Row className="h65">
          <Table.TextCell flexBasis={600} flexShrink={0} flexGrow={0}>
            <span className="fontStatus mr10">Observações</span><br />
            {vendaProcesso.observacao_pagamento || '---'}
          </Table.TextCell>
        </Table.Row>

        <Heading size={600} className='mt20'>
          Valor de venda
        </Heading>
        <Table.Row className="h65">
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0}>
            <span className="fontStatus">Valor de venda</span><br />
            {vendaProcesso.valor_venda || '---'}
          </Table.TextCell>
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0}>
            <span className="fontStatus">Valor anunciado</span><br />
            {vendaProcesso.valor_anunciado || '---'}
          </Table.TextCell>
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0}>
            <span className="fontStatus">Valor do sinal</span><br />
            {vendaProcesso.valorSinal || '---'}
          </Table.TextCell>
        </Table.Row>

        <Table.Row className="h65">
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0}>
            <span className="fontStatus">Data da venda</span><br />
            {vendaProcesso.data_assinatura || '---'}
          </Table.TextCell>
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0}>
            <span className="fontStatus">Prazo da escritura</span><br />
            {vendaProcesso.prazo || '---'}
          </Table.TextCell>
        </Table.Row>

        <Heading size={600} className='mt20'>
          Multa
        </Heading>
        <Text>Este valor está relacionado ao prazo limite de entrega das chaves.</Text>
        <Table.Row className="h65 mt10">
          <Table.TextCell flexBasis={600} flexShrink={0} flexGrow={0}>
            <span className="fontStatus">Valor de multa diária</span><br />
            {vendaProcesso.valoMulta || '---'}
          </Table.TextCell>
        </Table.Row>        

      </Pane>
    </Pane>
  )
}