import Axios from 'axios';
import Serve from '../app/serve';

async function getBankList() {  
  const app = Serve();
  let data;

  await Axios.get(app + 'listbancos', {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
  })
    .then(res => {
      if (res?.data.status && (res.data.status === 498 || res.data.status === 401)) {
        localStorage.clear();
      } else {                       
        data = (res.data.data);
      }
    })
  return data;
}

export default getBankList;