import React, { useContext, useState } from 'react';
import { Pane, Table, Heading, Badge, Link } from 'evergreen-ui';
import ApoioContext from '../../../../context/ApoioContext';
import ShowDocument from '../../../../app/showDocument';
import ReturnDocumentsForView from '../../../../../src/components/MultiViewDocs';

export default function Imovel() {
  const [token] = useState(localStorage.getItem('token'));

  const {
    selectProcess,   
    listLaudemios,
    listEscrituras,
  } = useContext(ApoioContext);  
  const [imovelProcesso] = useState(selectProcess.imovel);

  const typeLaudemios = [
    { id: '1', nome: 'União' },
    { id: '2', nome: 'Prefeitura' },
    { id: '3', nome: 'Familia do imóvel' },
    { id: '4', nome: 'Igreja' }
  ];

  const escritura = () => {
    return listEscrituras.find(value => Number(imovelProcesso.informacao.escritura) === value.id)?.escritura;
  };

  return (
    <Pane padding={10}>
      <Heading size={600} className="mb10">
        Dados do Imóvel
      </Heading>
      <Pane>
        <Table.Row className="pb60 pt15 alignStart h65">
          <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Código</span><br />
            {imovelProcesso.codigo || "---"}
          </Table.TextCell>
        </Table.Row>

        <Table.Row className="pb60 pt15 alignStart h65">
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">CEP</span><br />
            {imovelProcesso.cep || "---"}
          </Table.TextCell>
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Bairro</span><br />
            {imovelProcesso.bairro || "---"}
          </Table.TextCell>
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Logradouro</span><br />
            {imovelProcesso.logradouro || "---"}
          </Table.TextCell>
        </Table.Row>

        <Table.Row className="pb60 pt15 alignStart h65">
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Número</span><br />
            {imovelProcesso.numero || "---"}
          </Table.TextCell>
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Unidade</span><br />
            {imovelProcesso.unidade || "---"}
          </Table.TextCell>
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Complemento</span><br />
            {imovelProcesso.complemento || "---"}
          </Table.TextCell>
        </Table.Row>

        <Table.Row className="pb60 pt15 alignStart h65">
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Cidade</span><br />
            {imovelProcesso.cidade || "---"}
          </Table.TextCell>
          <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Estado</span><br />
            {imovelProcesso.uf || "---"}
          </Table.TextCell>
        </Table.Row>

        <Table.Row className="pb60 pt15 alignStart h65">
          <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0}>
            <span className="fontStatus">Vagas na garagem (escrituradas)</span><br />
            {imovelProcesso.informacao.vaga || "---"}
          </Table.TextCell>
        </Table.Row>
      </Pane>

      <Heading size={600} className="mt20 mb10">
        Registro e Escritura
      </Heading>
      <Pane>
        <Table.Row className="pb60 pt15 alignStart h65">
          <Table.TextCell flexBasis={500} flexShrink={0} flexGrow={0}>
            <span className="fontStatus">Tipo</span><br />
            {escritura()}
          </Table.TextCell>
        </Table.Row>

        <Table.Row className="pb60 pt15 alignStart h65">
          <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Lavrada em</span><br />
            {imovelProcesso.informacao.lavrada || '---'}
          </Table.TextCell>
          <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Livro</span><br />
            {imovelProcesso.informacao.livro || '---'}
          </Table.TextCell>
          <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Folhas</span><br />
            {imovelProcesso.informacao.folha || '---'}
          </Table.TextCell>
          <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Ato</span><br />
            {imovelProcesso.informacao.ato || '---'}
          </Table.TextCell>
          <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Cartório</span><br />
            {imovelProcesso.informacao.cartorio || '---'}
          </Table.TextCell>
        </Table.Row>

        <Table.Row className="pb60 pt15 alignStart h65">
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Registrado no RGI</span><br />
            {imovelProcesso.informacao.rgi || '---'}
          </Table.TextCell>
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Matrícula nº</span><br />
            {imovelProcesso.informacao.matricula}
          </Table.TextCell>
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0}>
            <span className="fontStatus">Inscrição Municipal nº</span><br />
            {imovelProcesso.informacao.inscricaoMunicipal}
          </Table.TextCell>
        </Table.Row>
      </Pane>

      <Heading size={600} className="mt20 mb10">
        Laudêmio
      </Heading>
      <Pane>
        {imovelProcesso.laudemios?.map((laudemio) => (
          <Table.Row>
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Tipo</span><br />
              {typeLaudemios.find((type) => laudemio.tipo_laudemio === type.id).nome}
            </Table.TextCell>
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">
                {laudemio.tipo_laudemio === '1' ? 'RIP nº' : laudemio.tipo_laudemio === '3' ? 'Nome da Família' : laudemio.tipo_laudemio === '4' ? 'Nome da Igreja' : ''}
              </span><br />
              {
                laudemio.tipo_laudemio === '1' ? laudemio.valor_laudemio :
                  laudemio.tipo_laudemio === '3' || laudemio.tipo_laudemio === '4' ? listLaudemios.find((list) => list.id === Number(laudemio.valor_laudemio)).nome : ''
              }
            </Table.TextCell>
          </Table.Row>
        ))}
        {!imovelProcesso.laudemios.length &&
          <Table.Row >
            <Table.TextCell flexBasis={400} flexShrink={0} flexGrow={0}>
              Não há laudemios cadastrados
            </Table.TextCell>
          </Table.Row>
        }
      </Pane>

      <Heading size={600} className="mt20 mb10">
        Documentos do imóvel <Badge>{imovelProcesso.documentos.data.length}</Badge>
      </Heading>
      <ReturnDocumentsForView docs={imovelProcesso.documentos.data} />      
    </Pane>
  )
}