import React, { useState, useEffect, useContext } from 'react';
import {
  Pane,
  Table,
  Button,
  ShareIcon,
  LockIcon,
  Badge,
  Avatar,
  Select,
  FilterIcon,
  Autocomplete,
  TextInput,
  Tooltip,
  InfoSignIcon,
  Combobox,
  Pagination,
  DotIcon,
  Menu,
  Popover,
  Position,
  DocumentOpenIcon,
  ChangesIcon,
  ListDetailViewIcon,
} from 'evergreen-ui';
import Axios from 'axios';
import Serve from '../../../app/serve';
import Lottie from 'react-lottie';
import animationData from '../../../images/Animations/loadingDocs.json';
// import pagination from '../../../components/pagination';

//Context
import NegociacaoContext from '../../../context/NegociacaoContext';

//Components
import DialogShare from './Modals/DialogShare';
import DialogFeedBack from './Modals/DialogFeedBack';
import DialogRevisao from './Modals/DialogRevisao';
import DialogRevisaoFeedBack from './Modals/DialogRevisaoFeedBack';
import DialogSolicitarNucleo from './Modals/DialogSolicitarNucleo';
import DialogNucleoFeedBack from './Modals/DialogNucleoFeedBack';
import SideSheetView from './SideSheetView';
import ChangeLog from '../../components/ChangeLog';
import getProcessById from '../../../apis/getProcessById';

export default function TablePanel({ getPaged }) {
  const app = Serve();
  const [token] = useState(localStorage.getItem('token'));
  const [classNovo, setClassNovo] = useState(['fw400']);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const {
    loading, setLoading,
    setProcessoId,
    processoId,
    userId,

    //MODAIS    
    setIsShownStatus,
    setIsShownRevisao,
    setIsShownNucleo,
    setLoadingModal,

    //Table Data
    tablePaged,

    //SIDESHEET
    setIsShownSide, setProcessSelect,
    processSelect, setSelectedIndex,

    //Filtros
    filtro, quantFiltro, setQuantFiltro,
    listaResponsaveis, listaGerente, listaClientes,
    listaStatusProcesso, listaEndereco,

    //Ordenação
    ordenacao,

    //Paginação
    pageNumber, setPageNumber,
    totalPaginas,
    totalProcessos,
    totalProcessosFiltrados,

    dadosProcesso, setDadosProcesso,
    setShowFieldsEscritura,
    setShowFieldsTaxas,
  } = useContext(NegociacaoContext);

  useEffect(() => {
    quantidadeFiltros();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    tablePaged.forEach((processo, index) => {
      processo.status_visualizacao_atual ? classNovo[index] = "fw400" : classNovo[index] = "fw600"
    })
    setClassNovo([...classNovo]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePaged])

  async function openModal(processo, revisao) {
    setLoadingModal(true);
    if (revisao) setIsShownRevisao(true);
    else setIsShownStatus(true);

    setDadosProcesso(processo);

    if (dadosProcesso.status_processo_atual === 5) {
      setShowFieldsEscritura(true);
      setShowFieldsTaxas(false);
    }
    else if(dadosProcesso.status_processo_atual === 4 || dadosProcesso.status_processo_atual === 3){
      setShowFieldsTaxas(true);
      setShowFieldsEscritura(false);
    }
    else {
      setShowFieldsEscritura(false);
      setShowFieldsTaxas(false);
    }

    if (processo.id !== processSelect.id) {
      setProcessoId(processo.id);
      const dataProcess = await getProcessById(processo.id);
      setProcessSelect(dataProcess);
    }
    setProcessoId(processo.id);
    setLoadingModal(false);

    // REMOVO TAG NOVO E FW600 DOS CAMPOS DA TABLE
    if (!processo.status_visualizacao_atual && processo.responsavel_atual === userId) {
      await atualizarStatusVisualizacaoGerente(processo.progress_status_progresses_id);
      tablePaged.forEach((descobrirIndex, index) => {
        if (descobrirIndex.id === processo.id) classNovo[index] = "fw400";
      })
    }

  };

  const openModalNucleo = async (processo) => {
    setProcessoId(processo.id);
    setIsShownNucleo(true);

    // REMOVO TAG NOVO E FW600 DOS CAMPOS DA TABLE
    if (!processo.status_visualizacao_atual && processo.responsavel_atual === userId) {
      await atualizarStatusVisualizacaoGerente(processo.progress_status_progresses_id);
      tablePaged.forEach((descobrirIndex, index) => {
        if (descobrirIndex.id === processo.id) classNovo[index] = "fw400"
      })
    }
  };

  const sideFunction = async (id, index, tabIndex) => {
    if (!processoId || id !== processSelect.id || !(index >= 0)) {
      setProcessoId(id);
      if (tabIndex) setSelectedIndex(tabIndex);
      else setSelectedIndex(0);

      const dataProcess = await getProcessById(id);
      setProcessSelect(dataProcess);
    }
    setIsShownSide(true);

    // REMOVO TAG NOVO E FW600 DOS CAMPOS DA TABLE
    tablePaged.forEach(async (processo, index) => {
      if (!processo.status_visualizacao_atual && processo.responsavel_atual === Number(userId)) {
        if (processo.id === processoId) {
          await atualizarStatusVisualizacaoGerente(processo.progress_status_progresses_id);
          classNovo[index] = "fw400"
        }
      }
    })
  };

  const atualizarStatusVisualizacaoGerente = async (id_status_processo) => {
    Axios.post(app + 'atualizar_status_visualizacao_gerente', {
      'id': id_status_processo
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(res => {
        if (res.data.status && res.data.status === (401 || 498)) {
          // localStorage.clear();
          // history.push('/');
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  };

  const handleClearFilter = async () => {
    filtro.responsavel = '';
    filtro.status = '';
    filtro.gerente = '';
    filtro.cliente = '';
    filtro.endereco = '';
    //ordenacao.valor = '1';
    localStorage.removeItem('filtro_status');
    localStorage.removeItem('filtro_responsavel');
    localStorage.removeItem('filtro_gerente');
    localStorage.removeItem('filtro_cliente');
    localStorage.removeItem('filtro_endereco');
    //localStorage.removeItem('ordenacao');
    setQuantFiltro(0);
    handleFilter(1);
  };

  const handleFilter = async (page) => {
    setPageNumber(page);
    quantidadeFiltros();
    localStorage.setItem('page_number', page);
    getPaged(page);
  };

  const quantidadeFiltros = async () => {
    let filtrosSelecionados = 0;
    if (filtro.responsavel !== '') {
      filtrosSelecionados++;
    }
    if (filtro.status !== '') {
      filtrosSelecionados++;
    }
    if (filtro.gerente !== '') {
      filtrosSelecionados++;
    }
    if (filtro.cliente !== '') {
      filtrosSelecionados++;
    }
    if (filtro.endereco !== '') {
      filtrosSelecionados++;
    }
    setQuantFiltro(filtrosSelecionados);
  };

  //Filtro de logradouro
  function handleFilterEndereco(e) {
    filtro.endereco = e;
    localStorage.setItem('filtro_endereco', e);
    handleFilter(1);
  };
  // Filtro de Responsavel e de Status
  const handleSelect = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    localStorage.setItem('filtro_' + name, value);
    filtro[name] = value;
    handleFilter(1);
  };
  // Filtro de Gerente
  const handleCombobox = (e) => {
    if (e) {
      filtro.gerente = e;
      localStorage.setItem('filtro_gerente', JSON.stringify({ 'id': e.id, 'name': e.name }));
      handleFilter(1);
    }
  };

  // Filtro de Cliente
  const handleComboboxCliente = (e) => {
    if (e) {
      filtro.cliente = e;
      localStorage.setItem('filtro_cliente', JSON.stringify({ 'id': e.id, 'name': e.name }));
      handleFilter(1);
    }
  };

  const selectOrdenacao = [
    {
      value: '1',
      name: 'Todos'
    },
    {
      value: '2',
      name: 'Prazo: maior (tempo sobrando)'
    },
    {
      value: '3',
      name: 'Prazo: menor (tempo sobrando)'
    },
    {
      value: '8',
      name: 'Data de entrada: Crescente'
    },
    {
      value: '9',
      name: 'Data de entrada: Decrescente'
    },
  ];

  const handleSelectOrdenacao = (e) => {
    const value = e.target.value;
    localStorage.setItem('ordenacao', value);
    ordenacao.valor = value;
    handleFilter(1);
  };

  return (
    <Pane>
      <h2 className="subtitle subtitle-table" style={{ padding: "0px" }}>
        Processos {
          totalProcessos > 0 &&
          <Tooltip
            content="Numero total de processos"
          >
            <Badge color="blue" marginLeft={10}>
              {totalProcessos}
            </Badge>
          </Tooltip>
        }
      </h2>

      <div className='filter-field filter-position'>
        <Autocomplete
          onChange={(e) => (handleFilterEndereco(e))}
          items={listaEndereco}
          selectedItem={filtro.endereco}
          allowOtherValues={true}
          width={558}
        >
          {props => {
            const { getInputProps, getRef, inputValue, openMenu } = props
            return (
              <TextInput
                placeholder="🔍︎ Endereço"
                value={inputValue}
                ref={getRef}
                width={558}
                {...getInputProps({
                  onClick: () => {
                    openMenu()
                  }
                })}
              />
            )
          }}
        </Autocomplete>
      </div>

      <div className='filter-table space-between'>
        <div className="flex end">
          <div>
            <div className="label-align-filter-order mw140 mt18 mb10">
              <FilterIcon color={'#8F95B2'} /> <span className="label-filter-order"> Filtrar por: </span>
            </div>
            <div className='filter-field'>
              <Select
                label=""
                name='responsavel'
                value={filtro.responsavel}
                onChange={(e) => handleSelect(e)}
                id="filtro-responsavel"
                className={filtro.responsavel === 0 ? "filtro-select color-select-filter-hidden" : "filtro-select color-select-filter"}
              >
                <option value="" selected hidden>Responsável</option>
                {listaResponsaveis.map((responsavel) =>
                  <option key={responsavel.id} value={responsavel.id}>{responsavel.name}</option>
                )}
              </Select>
            </div>
          </div>

          <div className='filter-field filter-position'>
            <Select
              marginTop={0}
              marginLeft={10}
              width={160}
              label=""
              name='status'
              value={filtro.status}
              onChange={(e) => handleSelect(e)}
              id="filtro-status"
              className={filtro.status === "" ? "filtro-select color-select-filter-hidden" : "filtro-select color-select-filter"}
            >
              <option value="" hidden selected >Status</option>
              {listaStatusProcesso.map((valor) =>
                <option key={valor.id} value={valor.id}>{valor.status}</option>
              )}
            </Select>
          </div>
          <div className='filter-field filter-position'>
            <Combobox
              openOnFocus
              name='gerente'
              width={240}
              items={listaGerente}
              selectedItem={filtro.gerente}
              itemToString={item => (item ? item.name : '')}
              placeholder="🔍︎ Gerente"
              onChange={(e) => handleCombobox(e)}
              style={{ marginLeft: '0' }}
              id="filtro-gerente"
            // className={filtro.gerente === "" ? "filtro-select color-select-filter-hidden" : "filtro-select color-select-filter"}
            />
          </div>
          <div className='filter-field filter-position'>
            <Combobox
              openOnFocus
              width={240}
              name='cliente'
              items={listaClientes}
              selectedItem={filtro.cliente}
              itemToString={item => (item ? item.name : '')}
              placeholder="🔍︎ Clientes"
              onChange={(e) => handleComboboxCliente(e)}
              style={{ marginLeft: '0' }}
              id="filtro-cliente"
            // className={filtro.gerente === "" ? "filtro-select color-select-filter-hidden" : "filtro-select color-select-filter"}
            />
          </div>


        </div>
        <div>
          <div className="label-align-filter-order">
            <ChangesIcon color={'#8F95B2'} /> <span className="label-filter-order"> Ordenar por: </span>
          </div>
          <div className='filter-field'>
            {/*Ordenação*/}
            <Select
              width={230}
              label=""
              name='ordenacao'
              value={ordenacao.valor}
              onChange={(e) => handleSelectOrdenacao(e)}
              id="ordenacao"
              className={ordenacao === "" ? "filtro-select color-select-filter-hidden" : "filtro-select color-select-filter"}
            >
              {selectOrdenacao.map((ordenar, index) => <option key={index} value={ordenar.value}>{ordenar.name}</option>)}
            </Select>
          </div>
        </div>

      </div>

      <div className="filter-info">
        <div>
          {quantFiltro > 0 &&
            <p>
              <FilterIcon className="icon-filter" />
              {quantFiltro} {quantFiltro === 1 ? 'filtro aplicado. ' : 'filtros aplicados. '}
              {tablePaged.length} / {totalProcessosFiltrados} {totalProcessosFiltrados === 1 ? 'processo visível' : 'processos visíveis'}.
              <span onClick={() => handleClearFilter(true)}> Limpar filtro</span>
            </p>
          }
        </div>
        <div className='filter-table filter-table-nav'>
          <Pagination
            marginTop={10}
            marginBottom={10}
            page={pageNumber}
            totalPages={totalPaginas}
            onPageChange={(e) => !loading ? handleFilter(e) : ''}
            onNextPage={() => handleFilter(pageNumber + 1)}
            onPreviousPage={() => handleFilter(pageNumber - 1)}
          />
        </div>
      </div>
      <div className='Rectangle35 Blue'></div>
      <Table.Body className="table">
        <Table.Head className="f10">
          <Table.TextCell flexBasis={70} className="table">
            Responsável
          </Table.TextCell>
          <Table.TextCell className="table minW70_30vw">
            Endereço
          </Table.TextCell>

          <Table.TextCell className="table bg-table-blue" flexBasis={150}>
            Status
          </Table.TextCell>

          <Table.TextCell className="table bg-table-blue pl0" flexBasis={90}>
            Prazo<br /> do Status
          </Table.TextCell>

          <Table.TextCell className="table" flexBasis={140}>
            Gerente
          </Table.TextCell>

          <Table.TextCell className="table" flexBasis={105}>
            Data de<br />  Entrada
          </Table.TextCell>

          <Table.TextCell className="table pr17" flexBasis={80}>
            Hora de<br /> Entrada
          </Table.TextCell>

          <Table.TextCell className="table" flexBasis={110}>
            {/* COLUNA NOVO */}
          </Table.TextCell>

        </Table.Head>
        {
          //Enquanto não carregar os dados exibe um Spinner
          loading &&
          <div className='backgroundLoadingDocs'><Lottie options={defaultOptions} height={200} width={400} /></div>
        }
        {
          //Exibe os dados carregados
          !loading &&
          //Table de processos em andamento
          <>
            <Table.Body>
              {tablePaged.map((processo, index) =>
                <Table.Row key={index} isSelectable>
                  <Table.TextCell flexBasis={70} className="table" onClick={() => sideFunction(processo.id, index)}>
                    <Avatar name={processo.responsavel_atual_nome} size={40} marginRight={16} />
                  </Table.TextCell>

                  <Table.TextCell className="table table-cell minW70_30vw" onClick={() => sideFunction(processo.id, index)}>
                    <div
                      className={classNovo[index]}
                    >
                      <Tooltip
                        content={processo.logradouro + ', ' + processo.numero + (processo?.unidade ? ' / ' + processo.unidade : '')}
                        position={Position.BOTTOM}
                        isShown={processo.logradouro.length < 38 ? false : ""}
                      >
                        <div className="dot-text">{processo.logradouro}, {processo.numero} {processo.unidade ? '/ ' + processo.unidade : ''}</div>
                      </Tooltip>
                    </div>
                  </Table.TextCell>

                  <Table.TextCell flexBasis={150} className="table table-cell bg-table-blue">
                    <div
                      className="content-tag"
                      style={{ cursor: !processo.gerente_atual_nome ? 'default' : 'pointer', background: '#fff' }}
                      onClick={(e) => !processo.gerente_atual_nome ? (e) : openModal(processo)}
                    >
                      <span className="circleIcon"></span> {processo.status_nome}
                    </div>
                  </Table.TextCell>

                  <Table.TextCell flexBasis={90} className="table table-cell bg-table-blue pl0">
                    {processo.data_expiracao_status}
                  </Table.TextCell>

                  {/* EXIBE CAMBO NOME DO GERENTE */}
                  <Table.TextCell className="table table-cell" flexBasis={140} onClick={() => sideFunction(processo.id, index)} >
                    <div>
                      {processo.gerente_atual_nome
                        ? <div className={classNovo[index]}>
                          <Tooltip content={processo.gerente_atual_nome} isShown={processo.gerente_atual_nome.length < 15 ? false : ""} position={Position.BOTTOM}>
                            <div className="dot-text">{processo.gerente_atual_nome}</div>
                          </Tooltip>
                        </div>
                        : <Button
                          // onClick={() => setSelectedIndex(1)}
                          marginRight={16}
                          className="btn-adicionar"
                        >
                          + Adicionar
                        </Button>
                      }
                    </div>
                  </Table.TextCell>

                  {/* EXIBE CAMBO DATA DE CRIAÇÃO */}
                  <Table.TextCell className="table table-cell" flexBasis={105} onClick={() => sideFunction(processo.id, index)}>
                    <div
                      className={classNovo[index]}
                    >
                      {processo.data?.split(' ')[0]}
                    </div>
                  </Table.TextCell>

                  {/* EXIBE CAMBO HORA DE CRIAÇÃO */}
                  <Table.TextCell className="table table-cell pr17" flexBasis={70} onClick={() => sideFunction(processo.id, index)}>
                    <div
                      className={classNovo[index]}
                    >
                      {processo.data?.split(' ')[1]}
                    </div>
                  </Table.TextCell>

                  {/* EXIBE CAMBO DE TAGS */}
                  <Table.TextCell flexBasis={120} className="table" onClick={() => sideFunction(processo.id, index)}>
                    <Pane>
                      {classNovo[index] === "fw600"
                        ? processo.status_anterior === 22
                          ? <Badge color="red" style={{ marginLeft: /*'40px'*/ '0' }}>DEVOLUÇÃO</Badge>
                          : <Badge color="green" style={{ marginLeft: /*'40px'*/ '0' }}>NOVO</Badge>
                        : processo.status_processo_atual === 7
                          ? <Badge color="neutral" style={{ marginLeft: /*'15px'*/ '0' }}>Finalizados</Badge>
                          : ''
                      }
                    </Pane>
                  </Table.TextCell>
                  <Table.TextCell flexBasis={135} className='pl0'>
                    <Popover
                      position={Position.BOTTOM_RIGHT}
                      content={
                        <Menu>
                          <Menu.Group title={<span style={{ fontSize: '10px' }}>OPÇÕES</span>}>
                            <Menu.Item
                              icon={!processo.gerente_atual_nome ? LockIcon : ShareIcon}
                              onSelect={() => openModal(processo)}
                              disabled={!processo.gerente_atual_nome}
                              intent={!processo.gerente_atual_nome ? "danger" : "blue400"}
                            >
                              <span>Compartilhar status</span>
                            </Menu.Item>

                            <Menu.Item
                              icon={InfoSignIcon}
                              onSelect={() => openModal(processo, true)}
                              intent="blue400"
                            >
                              <span>Devolver venda</span>
                            </Menu.Item>

                            <Menu.Item
                              icon={DocumentOpenIcon}
                              onSelect={(e) => openModalNucleo(processo)}
                              intent="blue400"
                            >
                              <span>Solicitar núcleo</span>
                            </Menu.Item>

                          </Menu.Group>
                        </Menu>
                      }
                    >
                      <Button appearance="primary" marginRight={11}>
                        <DotIcon color='white' size={10} marginRight={-5} />
                        <DotIcon color='white' size={10} marginRight={-5} />
                        <DotIcon color='white' size={10} />
                        Opções
                      </Button>
                    </Popover>
                  </Table.TextCell>
                </Table.Row>
              )}
            </Table.Body>
            {/*Table de processos finalizados*/}
          </>
        }
      </Table.Body>

      <DialogShare refreshTable={getPaged} />
      <DialogRevisao refreshTable={getPaged} />
      <DialogSolicitarNucleo refreshTable={getPaged} />
      <DialogNucleoFeedBack openSideSheet={sideFunction} />
      <DialogRevisaoFeedBack />
      <DialogFeedBack />
      <SideSheetView refreshProcess={sideFunction} />
      <ChangeLog data="PósVenda" />

    </Pane>
  )
}