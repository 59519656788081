import '../../../index.css';
import React, { useState, useContext } from 'react';
import { Pane, Heading, Tablist, Tab, Card, SideSheet } from 'evergreen-ui';
import NucleoContext from '../../../context/NucleoContext';
import Servico from './FormSideSheet/Servico';
import Imovel from './FormSideSheet/Imovel';
import Gerente from './FormSideSheet/Gerente';
import Vendedores from './FormSideSheet/Vendedores';
import Compradores from './FormSideSheet/Compradores';
import Venda from './FormSideSheet/Venda';
import Historico from './FormSideSheet/Historico';
import { useEffect } from 'react';
import getServicosNucleo from '../../../apis/getServicos';
import Lottie from 'react-lottie';
import animationData from '../../../images/Animations/loadingDocs.json';
import getResumoServicos from '../../../apis/getResumoServicos';
import getServicosById from '../../../apis/getServicosById';

export default function SideSheetView({ refreshTable }) {
    const {
        isShownSide, setIsShownSide,
        selectedIndex, setSelectedIndex,
        setFormValues,
        setMultiDocs,
        processSelect,
        serviceId,
        loading,
    } = useContext(NucleoContext);

    console.log("serviceId: ", serviceId);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const refreshProcessoSideSheet = async () => {
        // const processSelect = await getServicosById(serviceId)
        // setProcessoSideSheet(processSelect)
        await refreshTable()
    };

    const refreshFormValues = () => {
        //Setar valor para formValues que será usado na Aba Imovel, Vendedores e Compradores        
        if (processSelect) {
            const compradores = processSelect.processo.imovel.compradores.data;
            const vendedores = processSelect.processo.imovel.vendedores.data;
            const imovel = processSelect.processo.imovel.documentos.data;
            console.log(vendedores);
            
            const returnPessoa = (data_pessoa, type) => {
                const pessoa = data_pessoa.documentos.data.filter(
                    (doc) => doc.identifica_documento === type).map(
                        (doc) => ({
                            'file': doc.arquivo,
                            'id': doc.id,
                            'item': doc.tipos_multiplos_documentos.map((tipo) => ({ 
                                id: tipo.id, 
                                value: tipo.tipo_documento_id, 
                                emissao: tipo.data_emissao, 
                                validade: tipo.validade_dias,  
                                vencimento: tipo.data_vencimento,                                
                                have_validade: !!tipo.validade_dias
                            }))
                            // 'item': doc.tipos_multiplos_documentos.map((tipo) => ({ 'id': tipo.id, 'value': tipo.tipo_documento_id }))
                        }))
                return pessoa;
            };

            setMultiDocs({
                'imovel': imovel.filter((doc) => (doc.identifica_documento === "imovel" || doc.identifica_documento === "imóvel")).map((doc) => ({ 
                    'file': doc.arquivo, 
                    'id': doc.id,
                    'item': doc.tipos_multiplos_documentos.map((tipo) => ({ 
                        id: tipo.id, 
                        value: tipo.tipo_documento_id, 
                        emissao: tipo.data_emissao, 
                        validade: tipo.validade_dias,  
                        vencimento: tipo.data_vencimento,
                        have_validade: !!tipo.validade_dias
                    }))
                    //'item': doc.tipos_multiplos_documentos.map((tipo) => ({ 'id': tipo.id, 'value': tipo.tipo_documento_id }))
                })),
                'vendedores': vendedores.map((vendedor) => {
                    if (vendedor.tipo_pessoa === 0) {
                        const fisica = returnPessoa(vendedor, "pessoa_fisica");
                        return { fisica };

                    } else {
                        const juridica = returnPessoa(vendedor, "pessoa_juridica");
                        const representante_socios = vendedor.representante_socios.data.map(
                            representante => returnPessoa(representante, "pessoa_fisica")
                        );
                        return { juridica, representante_socios };
                    }
                }),
                'compradores': compradores.map((comprador) => {
                    if (comprador.tipo_pessoa === 0) {
                        const fisica = returnPessoa(comprador, "pessoa_fisica");
                        return { fisica };

                    } else {
                        const juridica = returnPessoa(comprador, "pessoa_juridica");
                        const representante_socios = comprador.representante_socios.data.map(
                            representante => returnPessoa(representante, "pessoa_fisica")
                        );
                        return { juridica, representante_socios };
                    }
                }),
            });
        };
    };

    useEffect(() => {
        refreshFormValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processSelect])

    const endereco = processSelect?.processo?.imovel?.logradouro + ', ' + processSelect?.processo?.imovel?.numero;
    const [tabs] = useState(['Serviço', 'Imóvel', 'Gerente', 'Vendedores', 'Compradores', 'Venda', 'Histórico']);

    return (
        <SideSheet
            isShown={isShownSide}
            onCloseComplete={() => setIsShownSide(false)}
            containerProps={{
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
                className: 'side-sheet-box'
            }}
            preventBodyScrolling
            width={700}
        >
            <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
                <Pane padding={16} borderBottom="muted">
                    <Heading size={600}>
                        {endereco === 'undefined, undefined' ? 'Sem endereço...' : endereco}
                    </Heading>
                </Pane>
                <Pane display="flex" padding={8}>
                    <Tablist>
                        {tabs.map((tab, index) => (
                            <Tab
                                key={tab}
                                isSelected={selectedIndex === index}
                                onSelect={() => setSelectedIndex(index)}
                                aria-controls={`panel-${index}`}
                                aria-selected={selectedIndex === index ? true : false}
                                style={selectedIndex === index ? { boxShadow: '0 0 0 2px #d6e0ff' } : { boxShadow: 'none' }}
                            >
                                {tab}
                            </Tab>
                        ))}
                    </Tablist>
                </Pane>
            </Pane>
            <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
                {loading ? <div className='backgroundLoadingDocs'><Lottie options={defaultOptions} height={200} width={400} /></div> :
                    tabs.map((tab, index) => (
                        <Card
                            key={tab}
                            id={`panel-${tab}`}
                            role="tabpanel"
                            aria-labelledby={tab}
                            aria-hidden={index !== selectedIndex}
                            display={index === selectedIndex ? 'block' : 'none'}
                        >
                            {tab === 'Serviço' && index === selectedIndex && <Servico refreshTable={refreshProcessoSideSheet} />}
                            {tab === 'Imóvel' && index === selectedIndex && <Imovel refreshTable={refreshProcessoSideSheet} />}
                            {tab === 'Gerente' && index === selectedIndex && <Gerente />}
                            {tab === 'Vendedores' && index === selectedIndex && <Vendedores refreshTable={refreshProcessoSideSheet} />}
                            {tab === 'Compradores' && index === selectedIndex && <Compradores refreshTable={refreshProcessoSideSheet} />}
                            {tab === 'Venda' && index === selectedIndex && <Venda />}
                            {tab === 'Histórico' && index === selectedIndex && <Historico />}
                        </Card >
                    ))
                }
            </Pane >
        </SideSheet >
    )
}