import React, {useContext, useState, useEffect} from 'react'
import {Pane, Button, StarIcon} from 'evergreen-ui';
//import '../../../styles.css';
import AcessoTemporarioContext from '../../../../../context/AcessoTemporarioContext';
import Feedback from './Feedback';
import { Rating } from 'react-simple-star-rating'
import ImgWide from '../../../../../images/wide.png';
import Axios from 'axios';
import Serve from '../../../../../app/serve_login';

export default function Status() {
    const app = Serve();

    const {
        usuarioId,
        avaliacao,
        statusProgresso,
        statusHistorico,
        setIsShowFeedback, 
        fimFeedback, setFimFeedback,
        ratingValue, setRatingValue
    } = useContext(AcessoTemporarioContext);

    const [descricaoProgresso, setDescricaoProgresso] = useState('');
    const [progresso, setProgresso] = useState('');

    useEffect(()=>{
        const conteudoDescricaoProgresso = (e) => {
            setDescricaoProgresso(statusHistorico[0].mensagem_vendedor_comprador)
        }
        conteudoDescricaoProgresso();

        const inicioProcesso = (e) => {
            let inicial = statusProgresso.filter(inicio => inicio.id !== 1 && inicio.id !== 2 && inicio.id !== 22);
            //setStatusEmProgresso(inicial);
            if(inicial.length > 0){
                setProgresso('iniciou');
            }
            else{
                setProgresso('analise');
            }
        }   
        inicioProcesso();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRating = (rate) => {
        setRatingValue(rate)

        if(rate < 5){
            setIsShowFeedback(true)
        }
        else{
            setIsShowFeedback(false)
        }
    }

    const handleSalvarFeedback = (e) => {  
        Axios.post(app + 'salvar_feedback', {
            'usuario_id': usuarioId,
            'processo_id': parseInt(localStorage.getItem('processo_id')),
            'experiencia': ratingValue,
            'avaliacao': [],
            'observacao': '',
            //'nota': e.notas,
        })
        .then(res => {
            setFimFeedback(true);
        })
        .catch(error => {
            console.log('erro');
            setFimFeedback(false);
        })
    }

    return (
        <Pane className="coll-status">
            <div className='header'> 
                
                {/* {
                    statusHistorico[0].status === 'Finalizado' &&
                    <img src={ImgWide} alt="wide" style={{marginBottom: '15px'}} />
                } */}

                <div className="title">
                    {
                        //'Análise'
                        progresso === 'analise' ? 'Análise' : statusHistorico[0].id === 22 || (statusHistorico[0].id === 1 || statusHistorico[0].id === 2) ? 'Análise' : statusHistorico[0].status 
                    }
                </div>
                <div className="content">
                    {
                        //'Alguma coisa'
                        progresso === 'analise' ? 'Analisando a documentação para dar início ao processo.' : descricaoProgresso
                    }
                </div>

                {
                    statusHistorico[0].status === 'Finalizado' 
                    ?
                        avaliacao === null
                        ?
                            fimFeedback === false
                            ?
                            
                            <>
                                <div
                                    className="rating-star"
                                    >
                                    
                                    <div className="rating-title">Como foi a sua experiência?</div>
                                    
                                    <Rating
                                        allowTitleTag={false}
                                        onClick={handleRating}
                                        customIcons={[
                                            { icon: <StarIcon size={33} /> },
                                            { icon: <StarIcon size={33} /> },
                                            { icon: <StarIcon size={33} /> },
                                            { icon: <StarIcon size={33} /> },
                                            { icon: <StarIcon size={33} /> },                                            
                                        ]}
                                    />

                                    <div className="rating-legenda">
                                        <span>Muito ruim</span>
                                        <span>Excelente</span>
                                    </div>
                                </div>

                                <Button className={`btn-feedback ${ratingValue > 0 ? 'btn-feedback-enable' : 'btn-feedback-disable'}`} onClick={(e) => handleSalvarFeedback(e)}>
                                    Enviar
                                </Button>
                            </>
                            :
                            <>
                                <div className="text-obr">Obrigada!</div>
                                <div className="text-aval">Sua avaliação nos ajuda<br/> a continuar evoluindo.</div>
                            </>
                            
                            // <Button className="btn-feedback" disabled style={{cursor: 'not-allowed', pointerEvents: 'none', maxWidth: '245px', color: '#c1c4d6', borderColor: '#E6E8F0'}}>
                            //     <div>Obrigada!</div>
                            //     <div>Sua avaliação nos ajuda a continuar evoluindo.</div>
                            //     {/* <StarIcon className="icon-feedback" /> Já recebemos seu feedback :) */}
                            // </Button>

                        :
                        // <Button className="btn-feedback" disabled style={{cursor: 'not-allowed', pointerEvents: 'none', maxWidth: '245px', color: '#c1c4d6', borderColor: '#E6E8F0' }}>
                        //     <StarIcon className="icon-feedback" /> Já recebemos seu feedback :)
                        // </Button>
                        <>
                            <div className="text-obr">Obrigada!</div>
                            <div className="text-aval">Sua avaliação nos ajuda<br/> a continuar evoluindo.</div>
                        </>
                    : ''
                }
                
            </div>

            <Feedback/>
        </Pane>
    )
}
