import React, { useContext, useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import Serve from '../../../app/serve';
import PhoneMask from '../../../components/phoneMask';
import { Pane, Avatar, Switch, Table, Spinner, Button, PlusIcon, Text, Select, Checkbox, toaster, Tooltip } from 'evergreen-ui';
import PainelAdminContext from '../../../context/PainelAdminContext'
import Lottie from 'react-lottie';
import animationData from '../../../images/Animations/loading.json';
import getUsers from '../../../apis/getUsers';

export default function Users({ userLoading }) {
  const app = Serve();
  const [token] = useState(localStorage.getItem('token'));
  const {
    setShowAddUser,
    setUserSelected,
    cargoList,
    shopsList,
    users,
    loading,
    setLoading
  } = useContext(PainelAdminContext);

  const [checked, setChecked] = useState([]);
  const [loadingCheck, setLoadingCheck] = useState([])
  const [selectValue, setSelectValue] = useState({ "lojaId": '', "cargoId": '', "name": '' });
  const [usersTable, setUsersTable] = useState([]);

  useEffect(() => {
    userLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUsersTable(users);
    setLoadingCheck(users.map((user) => user && false));
    setChecked(users.map((user) => user.activated));
  }, [users])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleSort = (e) => {
    console.log(e);
    console.log(usersTable);
    if (e === "UNIDADE" || e === "FUNÇÃO") {
      const query = e === "UNIDADE" ? 'loja' : 'cargo';
      usersTable.sort((a, b) => {
        const x = a[query]?.toLowerCase() || "z";
        const y = b[query]?.toLowerCase() || "z";
        return x < y ? -1 : x > y ? 1 : 0;
      })
    } else {
      usersTable.sort((a, b) => {
        const x = a.name.toLowerCase();
        const y = b.name.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      })
    }
    setUsersTable([...usersTable])
  };

  const cardUser = (user) => {
    setUserSelected(user);
    setShowAddUser(true);
  };

  const handleChangeStatus = (usuario_id,cargo_id, index) => {
    loadingCheck[index] = true;
    setLoadingCheck([...loadingCheck])
    Axios.post(app + 'situacao_usuario', {
      usuario_id,
      cargo_id
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(res => {
      console.log(res);
      if (res.data) {
        toaster.success(res.data.mensagem_usuario);
        checked[index] = !checked[index]
        setChecked([...checked])
      }
    }).then(() => {
      loadingCheck[index] = false;
      setLoadingCheck([...loadingCheck])
    })
  };

  const handleFilters = (e) => {
    console.log(usersTable);
    console.log('name', e.target.name, 'value', e.target.value);
    let filter = users;
    if (e.target) {
      const name = e.target.name;
      const value = e.target.value;
      selectValue[name] = Number(value);
    } else {
      selectValue.name = e.toUpperCase();
    }

    console.log('selectValue', selectValue);
    if (selectValue.cargoId) filter = filter.filter((user) => user.cargoId === selectValue.cargoId);
    if (selectValue.lojaId) filter = filter.filter((u) => u.lojaId === selectValue.lojaId);
    if (selectValue.name) filter = filter.filter((user) => user.name.toUpperCase().includes(selectValue.name));

    console.log(filter);
    //setLoadingCheck(users.map((user) => user && true));
    setLoading(true);
    setTimeout(() => {
      setUsersTable(filter);
      setLoading(false);
    }, 500);
  };

  const removeFilters = async () => {
    const getusers = await getUsers();
    setUsersTable([...getusers]);
    setSelectValue({ "lojaId": '', "cargoId": '', "name": '' })
  };

  return (
    <Pane className='painel ml202'>
      <Button appearance='primary' onClick={() => setShowAddUser(true)}>
        <PlusIcon marginRight={8} color="white" /> Novo Usuário
      </Button>
      {/* AREA DE FILTROS */}
      <Pane className='filters'>
        <div className='flex-center justifyBetween'>
          <div className='flex-center'>
            <div >
              <Select
                name='lojaId'
                width={240}
                background="white"
                value={selectValue.lojaId}
                onChange={(e) => handleFilters(e)}
              >
                <option value="" hidden selected >🔍︎ Loja</option>
                {shopsList.map((loja) => (
                  loja &&
                  <option value={loja.id} >{loja.nome}</option>
                ))}
              </Select>
            </div>
            <div className='ml20'>
              <Select
                name='cargoId'
                width={240}
                background="white"
                onChange={(e) => handleFilters(e)}
                value={selectValue.cargoId}
              >
                <option value="" hidden selected >🔍︎ Função</option>
                {cargoList.map((cargo) => (
                  <option value={cargo.id} >{cargo.nome}</option>
                ))}
              </Select>
            </div>
            <div className='ml20'>
              <Button onClick={() => removeFilters()}>Remover Filtros</Button>
            </div>
          </div>
        </div>
      </Pane>
      {/* TABELA DE USUARIOS */}
      <Pane>
        <Table>
          <Table.Head>
            <Table.SearchHeaderCell className="table filter" value={selectValue.name} onChange={(e) => handleFilters(e)} />
            <Table.TextHeaderCell className="table flexBasic215" onClick={(e) => handleSort(e.target.innerText)} >Telefone</Table.TextHeaderCell>
            <Table.TextHeaderCell className="table flexBasic215" onClick={(e) => handleSort(e.target.innerText)} >Função</Table.TextHeaderCell>
            <Table.TextHeaderCell className="table flexBasic215" onClick={(e) => handleSort(e.target.innerText)} >Unidade</Table.TextHeaderCell>
            <Table.TextHeaderCell className="table flexBasic215">Atividade</Table.TextHeaderCell>
            <Table.TextHeaderCell className="table flexBasic215">Opções</Table.TextHeaderCell>
          </Table.Head>
          {loading ? <div className='mt30vh'><Lottie options={defaultOptions} height={80} width={200} /></div> :
            <Table.Body>
              {usersTable.map((profile, index) => (
                <Table.Row key={index}>
                  <Table.TextCell className="table name" onClick={() => cardUser(profile)}>
                    <div className='flex-center'>
                      <Avatar src={profile.avatar} name={profile.name} className={!profile.activated && "img-pb"} />
                      <Tooltip isShown={profile?.name?.length > 21 ? undefined : false} content={profile.name}>
                        <span className='ml20'>{profile.name}</span>
                      </Tooltip>
                    </div>
                  </Table.TextCell>
                  <Table.TextCell className="table flexBasic215">{profile.tel ? PhoneMask(profile.tel) : "---"}</Table.TextCell>
                  <Table.TextCell className="table flexBasic215">{profile.cargo || "---"}</Table.TextCell>
                  <Table.TextCell className="table flexBasic215">{profile.loja || "---"}</Table.TextCell>
                  <Table.TextCell className="table flexBasic215">{profile.lastActivity || "---"}</Table.TextCell>
                  <Table.Cell className="table flexBasic215 switchStatus">                    
                    {<Switch disabled={loadingCheck[index]} size={50} marginRight={20} checked={checked[index]} onChange={(e) => handleChangeStatus(profile.id,profile.cargoId, index)} />}
                    {loadingCheck[index] && <Spinner />}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>}
        </Table>
      </Pane>
    </Pane>
  )
}