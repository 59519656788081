import React, {useContext, useState} from 'react'
import { Pane, Link } from 'evergreen-ui';
import VendasContext from '../../../../../../../../../../context/VendasContext';
import ShowDocument from '../../../../../../../../../../app/showDocument';

export default function ReciboSinal() {
    const {dadosVenda, listLaudemios} = useContext(VendasContext);
    //const [token] = useState(localStorage.getItem('token'));

    return (
        <Pane className="container">
            <div className="title">Recibo de sinal</div>
            
            <div className="content">
                <p>Confira se as informações cadastradas <span style={{fontWeight: '600'}}>estão condizentes<br/> com os dados do Recibo de Sinal Assinado</span>.</p>
            </div>

            <ul className="row" style={{display: 'inline-block'}}>
                <li>
                    <div className="field">Data de assinatura</div>
                    <div className="value">{dadosVenda.informacao.data_assinatura}</div>
                </li>

                <li style={{display: 'flex'}}>
                    <div className="field" style={{marginRight: '15px'}}>Recibo de Sinal</div>
                    <div className="value">
                        <Link className='content-click font12' onClick={() => ShowDocument(dadosVenda.id, 'recibo', localStorage.getItem('token'))}>{dadosVenda.informacao.recibo}</Link>
                    </div>
                </li>
            </ul>
        </Pane>
    )
}
