import React, { useContext, useState } from 'react';
import { Pane, Table, Heading, Badge, Button, StatusIndicator, Link, FilePicker } from 'evergreen-ui';
import NegociacaoContext from '../../../../context/NegociacaoContext';
import ShowDocument from '../../../../app/showDocument';
import Axios from 'axios'
import Serve from '../../../../app/serve';
import { useHistory } from 'react-router-dom';

export default function Nucleo() {
    const {
        processSelect, setProcessoId
    } = useContext(NegociacaoContext);

    const history = useHistory();
    const app = Serve();
    const [token] = useState(localStorage.getItem('token'));
    const [solicitacoes, setSolicitacoes] = useState(processSelect?.solicitacoesNucleo || []);

    const docsImovel = processSelect?.imovel.documentos.data.filter((doc) => doc.solicitacao_id);
    const docsVendedores = [];
    processSelect?.imovel.vendedores.data.forEach((vendedor) => docsVendedores.push(...vendedor.documentos.data.filter((doc) => doc.solicitacao_id)));
    const docsCompradores = [];
    processSelect?.imovel.compradores.data.forEach((comprador) => docsCompradores.push(...comprador.documentos.data.filter((doc) => doc.solicitacao_id)));
    const docs = [...docsImovel, ...docsVendedores, ...docsCompradores]
    console.log('docs: ', docs);
    // console.log('solicitacoes', solicitacoes);

    const colorStatus = (status) => {
        if (status === 1) return '#3366FF'
        else if (status === 2) return 'warning'
        else if (status === 3) return 'danger'
        else return 'success'
    };

    const handleBoleto = (solicitacao, type) => {
        if (solicitacao.Documentos.data){
            if(type === 'boleto') return solicitacao.Documentos?.data?.find((doc) => doc.tipo_documento.nome === "Cobrança de pagamento");
            else if (type === 'comprovante') return solicitacao.Documentos?.data?.find((doc) => doc.tipo_documento.nome === "Comprovante pagamento");
            else return "";
        } else {
            return "";
        }
    };

    const saveComprovante = async (comprovante, solicitacao, index) => {
        const idDoc = handleBoleto(solicitacao, 'comprovante')?.id || "";       

        let documento = new FormData();
        documento.append('arquivo', comprovante);
        documento.append('tipo', 'boleto');
        //parametro referente ao id do imovel, usuario, comissao, boleto
        documento.append('id', solicitacao.id);
        documento.append('processo_id', processSelect.id);
        documento.append('documento_id', idDoc);
        documento.append('tipo_documento_id', 62);
        await Axios.post(app + 'salvar_comprovante_pagamento', documento, 
        {
            headers: {Authorization: `Bearer ${token}`}
        }).then(res => {
            // console.log(res);
            if (res.data.status && res.data.status === (401 || 498)) {
                localStorage.clear();
                history.push('/');
            } else {
                if(!idDoc) solicitacoes[index].Documentos.data.push(res.data.documento);                
                setSolicitacoes([...solicitacoes])
            }
        }).catch(function (error) {
            console.log(error);
        })
    };

    return (
        <Pane padding={10}>
            <Heading size={600}>
                Solicitações de serviços ao Núcleo de Certidões
            </Heading>
            <Pane className="tab-nucleo">
                {solicitacoes.length > 0
                    ? solicitacoes.map((solicitacao, index) => (
                        <Pane className='table-nucleo' key={index}>
                            <div className="fontStatus mt27 fw600">
                                Dia {solicitacao.data_criacao}
                            </div>
                            <div>
                                <Badge
                                    color="blue"
                                    marginBottom={15}
                                    marginLeft={0}
                                >
                                    Serviço {index + 1}
                                </Badge>
                            </div>

                            <Table.Row className="pb60 pt15 alignStart h65">
                                <Table.TextCell flexBasis={300} flexShrink={0} flexGrow={0} paddingLeft={0}>
                                    <span className="fontStatus">Serviço detalhado</span>
                                    <span className="mensagem wsNormal fw400">
                                        {solicitacao.servico_detalhado !== null ? solicitacao.servico_detalhado.nome : '---'}
                                    </span>
                                </Table.TextCell>

                                <Table.TextCell>
                                    <span className="fontStatus" >Status do Núcleo</span>
                                    <Button className='tagBtn' size='small' style={{ position: 'relative', top: '0', cursor: 'default' }}>
                                        <StatusIndicator color={colorStatus(solicitacao.status_solicitação.data[0]?.id)}> {solicitacao.status_solicitação.data[0]?.status || ''}</StatusIndicator>
                                    </Button>
                                </Table.TextCell>

                                <Table.TextCell>
                                    <span className="fontStatus">{solicitacao.status_solicitação.data[0]?.status === 'Cobrança' ? "Data de validade" : "Previsão de entrega"}</span>
                                    <span className="mensagem fw400">{solicitacao.status_solicitação.data[0]?.data_previsao || '---'}</span>
                                </Table.TextCell>
                            </Table.Row>

                            <Table.Row className="pb60 pt15 alignStart h65" paddingY={12} height="auto">
                                <Table.TextCell flexBasis={600} flexShrink={0} flexGrow={0} paddingLeft={0}>
                                    <span className="fontStatus">Documentos enviados do núcleo</span>
                                    {docs.map((doc) => doc.solicitacao_id === solicitacao.id
                                        ? <Pane>
                                            <span className="mensagem fw400">
                                                {doc.tipo_documento?.nome
                                                    ? doc.tipo_documento.nome + ' - ' 
                                                    : doc.tipos_multiplos_documentos.map((e) => e.nome_tipo).join(', ') + ' - '
                                                }
                                                <Link className='content-click font12' onClick={() => ShowDocument(doc.id, 'documento', token)}>
                                                    {doc.arquivo}
                                                </Link>
                                            </span>
                                        </Pane>
                                        : ""
                                    )}
                                </Table.TextCell>
                            </Table.Row>

                            <Table.Row className="pb60 pt15 alignStart h65" paddingY={12} height="auto">
                                <Table.TextCell flexBasis={600} flexShrink={0} flexGrow={0} paddingLeft={0}>
                                    <span className="fontStatus">Observações do núcleo</span>
                                    {solicitacao.observacoes ? solicitacao.observacoes :
                                        <span className="mensagem wsNormal fw400">
                                            {solicitacao.status_solicitação.data[0]?.mensagem || '---'}
                                        </span>}
                                </Table.TextCell>
                            </Table.Row>

                            <Table.Row className="pb60 pt15 alignStart h65" paddingY={12} height="auto">
                                <Table.TextCell flexBasis={600} flexShrink={0} flexGrow={0} paddingLeft={0}>
                                    <span className="fontStatus">Observações do pós-venda</span>
                                    {solicitacao.observacoes ? solicitacao.observacoes :
                                        <span className="mensagem wsNormal fw400">
                                            {solicitacao.observacao !== null ? solicitacao.observacao : '---'}
                                        </span>}
                                </Table.TextCell>
                            </Table.Row>

                            {solicitacao.status_solicitação.data[0]?.status === 'Cobrança' &&
                                <Table.Row className="pb60 pt15 alignStart h65" paddingY={12} height="auto">
                                    <Table.TextCell flexBasis={600} flexShrink={0} flexGrow={0} paddingLeft={0}>
                                        <span className="fontStatus">Cobrança</span>

                                        <span className="mensagem wsNormal fw400">Boleto</span>
                                        <Link className='content-click' onClick={(e) => [ShowDocument((handleBoleto(solicitacao, 'boleto')?.id), 'documento', token), e.preventDefault()]} >
                                            {handleBoleto(solicitacao, 'boleto')?.arquivo || "---"}
                                        </Link>

                                        <span className="mensagem wsNormal fw400 mt10">Comprovante de Pagamento</span>
                                        <div className='flex mt10 mb10'>
                                            <FilePicker
                                                placeholder={handleBoleto(solicitacao, 'comprovante')?.arquivo ? handleBoleto(solicitacao, 'comprovante').arquivo : "Selecione o arquivo"}                                                
                                                type="file"
                                                accept=".pdf, .doc, .docx"
                                                onChange={(e) => saveComprovante(e[0], solicitacao, index)}
                                            />
                                            <Button
                                                marginLeft={5}
                                                appearance="primary"
                                                disabled={!handleBoleto(solicitacao, 'comprovante')?.id}
                                                onClick={(btn) => [ShowDocument(handleBoleto(solicitacao, 'comprovante').id, 'documento', token), btn.preventDefault()]}
                                            >Ver</Button>
                                        </div>
                                    </Table.TextCell>
                                </Table.Row>}
                        </Pane>
                    ))
                    : <span>Nenhuma solicitação para o núcleo no momento</span>
                }
            </Pane>
        </Pane>
    )
}