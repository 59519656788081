import React, { useContext, useState } from 'react';
import { Pane, Table, Badge, Button, StatusIndicator, DownloadIcon } from 'evergreen-ui';
import ApoioContext from '../../../../context/ApoioContext';
import ShowDocument from '../../../../app/showDocument';
import { useEffect } from 'react';

export default function Venda() {
  const {
    selectProcess,
  } = useContext(ApoioContext);
  const [historicoStatus, setHistoricoStatus] = useState(selectProcess.imovel.historico_acoes);
  const [token] = useState(localStorage.getItem('token'));  
  const datas = [...new Set(historicoStatus.map(historico => historico.data_historico))];
  const modeDev = process.env.REACT_APP_DEV === 'true';

  useEffect(() => {
    setHistoricoStatus(selectProcess.imovel.historico_acoes.filter((history) => history.mensagem || history.identifica_documento || history.status))
  }, [selectProcess])

  if (modeDev) {
    console.log('processoSideSheet: ', selectProcess);
    console.log('historicoProcesso: ', historicoStatus);
  };

  const vendedores = [];
  const representantes = [];
  selectProcess.imovel.vendedores.data.forEach((vendedor) =>
    vendedor.documentos.data.map((doc) => vendedores.push({ 'id': doc.id, 'name': vendedor.name ? vendedor.name : vendedor.razao_social, 'tipo': 'Vendedor' })) &&
    vendedor.representante_socios.data.forEach((representante) =>
      representante.documentos.data.forEach(doc => representantes.push({ 'id': doc.id, 'name': representante.name, 'tipo': 'Vendedor', 'empresa': vendedor.razao_social, 'representante': representante.pj_representante === 1, 'socio': representante.pj_socio === 1 })))
  );
  const compradores = [];
  selectProcess.imovel.compradores.data.forEach((comprador) =>
    comprador.documentos.data.map((doc) => compradores.push({ 'id': doc.id, 'name': comprador.name ? comprador.name : comprador.razao_social, 'tipo': 'Comprador' })) &&
    comprador.representante_socios.data.forEach((representante) =>
      representante.documentos.data.forEach(doc => representantes.push({ 'id': doc.id, 'name': representante.name, 'tipo': 'Comprador', 'empresa': comprador.razao_social, 'representante': representante.pj_representante === 1, 'socio': representante.pj_socio === 1 })))
  );

  const listaDocsNomes = [];
  listaDocsNomes.push(...vendedores)
  listaDocsNomes.push(...compradores)
  listaDocsNomes.push(...representantes)

  const capitalize = str => {
    if (typeof str !== 'string') {
      return '';
    }
    return str.charAt(0).toUpperCase() + str.substr(1);
  };

  const colorStatus = (status_solicitacao) => {
    if (status_solicitacao === 'Entrada') return '#3366FF'
    else if (status_solicitacao === 'Andamento') return 'warning'
    else if (status_solicitacao === 'Parado') return 'danger'
    else return 'success'
  };

  return (
    <Pane>
      {datas.map(date =>
        <div style={{ paddingBottom: "0" }}>
          {historicoStatus.filter(filter => filter.data_historico === date).map(historico =>
            <Table.Row className="row-historico" style={{ height: 'auto' }}>
              <Table.TextCell className="historicoStatus">
                <div className="fontStatus">
                  <Badge
                    color={
                      historico.status_solicitacao === null
                        ? historico.identifica_documento === "pessoa_fisica" || historico.identifica_documento === 'pessoa_juridica'
                          ? "green"
                          : "yellow"

                        // Solcitação Núcleo
                        : historico.status_solicitacao === 'Entrada'
                          ? 'blue'
                          : 'yellow'
                    }
                    marginBottom={14}
                    marginRight={17}
                  >
                    {
                      historico.identifica_documento
                        ? historico.identifica_documento.replace("_", " ")

                        // Solcitação Núcleo
                        : historico.status_solicitacao === null
                          ? 'STATUS'
                          : historico.status_solicitacao === 'Entrada'
                            ? 'SERVIÇO SOLICITADO'
                            : 'ATUALIZAÇÃO DE SERVIÇO'
                      //: "STATUS"
                    }
                  </Badge>

                  {listaDocsNomes.filter(doc => doc.id === historico.documento_id)[0]?.representante &&
                    <Badge color="neutral" marginBottom={14} marginRight={17}>
                      REPRESENTANTE LEGAL
                    </Badge>
                  }

                  {listaDocsNomes.filter(doc => doc.id === historico.documento_id)[0]?.socio &&
                    <Badge color="neutral" marginBottom={14}>
                      SÓCIO
                    </Badge>
                  }

                </div>
                <div className="fontStatus">
                  {historico.status !== null
                    ?
                    <>
                      <span className="boldStatus"> {historico.data_historico} </span> - {historico.mensagem === 'Gerente realizou correções solicitadas.' ? 'Venda corrigida' : historico.status}
                    </>

                    :
                    <>
                      {
                        historico.status_solicitacao !== null
                          // Solcitação Núcleo
                          ?
                          <>
                            <span className="boldStatus"> {historico.data_historico}</span> - {historico.nome_solicitacao}
                            {
                              <>
                                <div>
                                  {historico.status_solicitacao !== 'Entrada' &&
                                    <div className="historico-status-solicitacao">
                                      <StatusIndicator
                                        color={colorStatus(historico.status_solicitacao)}
                                        width={10}
                                      /> {historico.status_solicitacao === 'Andamento'
                                        ? 'Em andamento'
                                        : historico.status_solicitacao
                                      }
                                    </div>
                                  }
                                  <span className="historico-mensagem-d-solicitacao">{
                                    historico.status_solicitacao === 'Entrada'
                                    && 'Solicitação de serviço ao Núcleo de Certidões pelo Pós-venda.'
                                  }</span>
                                </div>

                                {
                                  // Descomentar quando tiver na API

                                  historico.data_previsao !== null && historico.status_solicitacao !== 'Entrada'
                                    ?
                                    <div style={{ marginBottom: '15px', marginTop: '15px' }}>
                                      <span className="boldStatus">Previsão de entrega:</span>
                                      <div className="historico-mensagem-solicitacao">
                                        {historico.data_previsao}
                                      </div>
                                    </div>

                                    :
                                    ''
                                }

                                {
                                  historico.mensagem_solicitacao !== '' &&
                                  <div style={{ marginTop: '15px' }}>
                                    <span className="boldStatus">
                                      {historico.status_solicitacao === 'Entrada'
                                        ? 'Observações do Pós-venda:' : 'Observações do Núcleo de Certidões:'
                                      }
                                    </span>
                                    <div className="historico-mensagem-solicitacao" style={{ fontWeight: '400' }}>
                                      {historico.mensagem_solicitacao || "Nenhuma observação cadastrada."}
                                    </div>
                                  </div>
                                }
                              </>
                            }
                          </>

                          : historico.identifica_documento === 'pessoa_fisica' || historico.identifica_documento === 'pessoa_juridica'
                            ?
                            <>
                              <span className="boldStatus"> {historico.data_historico} - </span>
                              {listaDocsNomes.filter(doc => doc.id === historico.documento_id).map(doc =>
                                doc.empresa
                                  ? doc.tipo + ' > ' + doc.empresa + ' > ' + doc.name
                                  : doc.tipo + ' > ' + doc.name
                              ) + ' > ' + historico.tipos_multiplos_documentos.map((e, index) => " " + e.nome_tipo )}

                            </>
                            :
                            <>
                              <span className="boldStatus">{historico.data_historico}</span> - {capitalize(historico.identifica_documento) + ' > '} {historico.nome}
                            </>
                      }
                    </>
                  }
                </div>
                <Table.TextCell paddingLeft={0}>
                  <span className="mensagem">{historico.mensagem || ""}</span>
                  {historico.link === null
                    ? ''
                    :
                    <div>
                      <p className="color696F8C f12"> O upload de documento foi realizado pelo Núcleo de Certidões.</p>
                      <Button
                        marginTop={14}
                        onClick={(e) => ShowDocument(historico.documento_id, "documento", token)}
                      >
                        <DownloadIcon className="iconLink" width={17} marginRight={5} /><span> Baixar documento </span>
                      </Button>
                    </div>
                  }
                </Table.TextCell>
              </Table.TextCell>
            </Table.Row>

          )}
        </div>
      )}
    </Pane>
  )
}