import Axios from 'axios';
import Serve from '../app/serve';

async function getServicosNucleo() {
  const token = localStorage.getItem('token');
  const app = Serve();
  let servicos;

  await Axios.get(app + 'servicos_detalhados', {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
    .then(res => {
      if (res !== undefined) {
        if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
          localStorage.clear();
        } else {
          servicos = res.data.data;
        }
      }
    })
  return servicos;
};
export default getServicosNucleo;