import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Pane,
  RadioGroup,
  ErrorIcon,
  Badge,
  TextInputField,
  SelectField,
  Button,
  PlusIcon,
  CrossIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  toaster,
  Radio,
  Spinner,
} from 'evergreen-ui';

//Context
import VendasContext from '../../../../../../../context/VendasContext';

//Components
import Axios from 'axios';
import Serve from '../../../../../../../app/serve';
import { useHistory } from 'react-router-dom';
import formatoMoeda from '../../../../../../../components/formatoMoeda';
import dateMask from '../../../../../../../components/dateMask';

export default function Empresa({ setSelectedIndex, recarregarVenda }) {
  const {
    loading,
    total,
    liquida,
    setPosicaoBoletimDeVenda,
    dadosVenda,
    setLoading,
    listaPeriodo,
    listaPeriodo_gerente,
  } = useContext(VendasContext);

  const errorTextInput = "Campo obrigatório.";
  const msgError = "Algo não saiu como esperado. Tente novamente mais tarde.";
  const app = Serve();
  const [alteracaoRevisao, setAlteracaoRevisao] = useState(false);
  const { comissao } = dadosVenda;
  const parcela = { 'id': '', 'valor': '', 'periodo': '' };
  const inicialDataParcela = comissao?.parcelas_empresa.length > 0 ? comissao.parcelas_empresa.map((parcela) => ({
    id: parcela.id,
    valor: parcela.valor_parcela,
    periodo: parcela.periodo_pagamento,
  })) : [parcela];

  const valorVenda = dadosVenda?.informacao?.valor_venda?.replace(/[R$.]+/g, '');

  console.log(dadosVenda);

  const { register, unregister, handleSubmit, clearErrors, setValue, watch, reset, formState: { errors } } = useForm({
    defaultValues: {
      deducao: comissao?.deducao || '',
      liquida: comissao?.liquida || '',
      parcelas: inicialDataParcela,
      radioTipoComissao: comissao?.comissao || 'integral',
      valor_comissao_liquida: comissao?.valor_comissao_liquida ? comissao?.valor_comissao_liquida : comissao?.valor_comissao_total || formatoMoeda((valorVenda.replace(",", ".") * 0.05).toFixed(2)),
      valor_comissao_total: comissao?.valor_comissao_total || formatoMoeda((valorVenda.replace(",", ".") * 0.05).toFixed(2))
    }
  });
  console.log("Watch Empresa: ", watch());
  console.log("Errors Empresa: ", errors);

  //CSS inputs
  const inputGreen = {
    borderColor: '#429777',
    background: '#F5FBF8'
  };

  const inputDefault = {
    borderColor: '#ADC2FF',
    background: '#fff'
  };

  const inputError = {
    borderColor: '#FF0000',
    background: '#fff'
  };

  // FUNÇÃO DO RADIO TOTAL OU EM PARTES
  const handleTotalComissao = (e) => {
    const tipoComissao = e.target.value;
    setValue('radioTipoComissao', tipoComissao);
    const parcelada = watch('parcelas').length <= 1 ? inicialDataParcela : [...watch('parcelas'), parcela];
    clearErrors();
    if (tipoComissao === 'partes') setValue('parcelas', parcelada);
    else if (tipoComissao === 'integral') setValue('parcelas', [watch('parcelas.0')]);
  };

  function addParcela() {
    const parcelasAdd = [...watch("parcelas"), parcela];
    calculoValorTotal(parcelasAdd);
  };

  async function removeParcela(index) {
    const parcelasAdd = watch("parcelas");
    if (parcelasAdd[index].id) {

      await Axios.post(app + 'excluir_parcela', {
        id_parcela: parcelasAdd[index].id,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          // "Content-Type": "multipart/form-data"
        }
      })
        .then(res => {
          if (res?.data?.mensagem_parcela === "Parcela excluída") {
            parcelasAdd.splice(index, 1);
            setValue('parcelas', parcelasAdd);
            unregister('parcelas.' + index);
            clearErrors('parcelas.' + index);
            recarregarVenda();
            calculoValorTotal(watch("parcelas"));
          } else {
            toaster.notify(res?.data?.mensagem_parcela || msgError)
          }
        })
        .catch(error => {
          console.log(error);
        })
    } else {
      parcelasAdd.splice(index, 1);
      setValue('parcelas', parcelasAdd);
      unregister('parcelas.' + index);
      clearErrors('parcelas.' + index);
      calculoValorTotal(watch("parcelas"));
    }
  };

  const handleBtnAnterior = (e) => {
    e.preventDefault();
    if (dadosVenda.status_processo === 22) {
      if (dadosVenda.revisao.compradores === 1) {
        setPosicaoBoletimDeVenda(3);
      }
      else if (dadosVenda.revisao.vendedores === 1) {
        setPosicaoBoletimDeVenda(2);
      }
      else if (dadosVenda.revisao.valores_documentos_imovel === 1) {
        setPosicaoBoletimDeVenda(1);
      }
    }
    else {
      setPosicaoBoletimDeVenda(3);
    }
  };

  const handleBtnAvancar = async (e) => {
    setLoading(false);

    const data = new FormData();
    data.append('comissao_id', dadosVenda.comissao?.id || "");
    data.append('processo_id', dadosVenda.processo_id);
    data.append('usuario_id_logado', localStorage.getItem('usuario_id'));
    data.append('total', e.radioTipoComissao);
    data.append('comissaoTotal', e.valor_comissao_total);
    data.append('deducoes', e.deducao);
    data.append('pagamento', e.liquida);
    data.append('comissaoLiquida', e.valor_comissao_liquida);
    data.append('condicaostatus', 1);
    data.append('status_processo', dadosVenda.status_processo)

    e.parcelas.forEach((parcela) => {
      data.append('id_parcela[]', parcela.id)
      data.append('valor_parcela[]', e.radioTipoComissao === 'partes' ? parcela.valor : "");
      data.append('periodo_pagamento[]', parcela.periodo);
    })

    await Axios.post(app + 'salvar_comissao_empresa', data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          "Content-Type": "multipart/form-data"
        }
      })
      .then(res => {
        if (res?.data?.mensagem_comissao === "Comissão salva") {
          setSelectedIndex(1);
        } else {
          toaster.notify(msgError);
        }
      })
      .catch(error => {
        console.log(error);
      })

    await recarregarVenda();
    setLoading(true);
  };

  function calculoValorTotal(campo) {
    const valorLiquido = (watch('valor_comissao_liquida').replace(/[R$.]+/g, '')).replace(",", ".");
    let count = 0
    campo.forEach((parcela, index) => {
      let value = (valorLiquido / campo.length).toFixed(2);
      if (count !== valorLiquido.replace(",", ".") && index === campo.length - 1) {
        value = Number(valorLiquido - count).toFixed(2);
      }
      count += Number(value)
      parcela.valor = formatoMoeda(value);
    });
    setValue('parcelas', campo);
  };

  function calculoDeducao() {
    const deducao = watch('deducao').replace(/[R$.]+/g, '');
    const bruto = watch('valor_comissao_total').replace(/[R$.]+/g, '');
    const valorliquida = (bruto.replace(",", ".") - deducao.replace(",", ".")).toFixed(2);
    if (valorliquida) {
      setValue('valor_comissao_liquida', formatoMoeda(valorliquida));
      calculoValorTotal(watch('parcelas'));
    }
  };

  const limitValidate = () => {
    let value = 0;
    const parcelas = watch('parcelas');
    const totalLiquido = watch('valor_comissao_liquida').replace(/[R$.]+/g, '');
    parcelas.forEach((parcela) => {
      const valor = parcela.valor.replace(/[R$.]+/g, '')
      value = value + Number(valor.replace(",", "."));
    })
    if (value > totalLiquido.replace(",", ".")) return "Valor inválido"
    else return true
  };

  const porcentagemComissaoTotal = () => {
    if(watch('valor_comissao_total')) {
      const bruto = watch('valor_comissao_total').replace(/[R$.]+/g, '');    
      const porcentagem = (bruto.replace(",", ".") / valorVenda.replace(",", ".")) * 100
      return porcentagem.toFixed(2)
    }
    else return 0;
  };

  return (
    <Pane>
      {!loading ?
        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
          <Spinner size={80} />
        </Pane> :
        <Pane>
          <div>
            <p className='f24 fw600'>Previsão de comissionamento da DNA Imóveis</p>
            <p className='fw400 mt16'>Preencha com dados complementares sobre a comissão da empresa.</p>
          </div>

          {/* CAMPO DE TOTAL E PARCELAS */}
          <div className='mt36'>
            <div className="fw600">Total</div>

            <div className="rowForm line-radius radius-select">
              <RadioGroup
                label=""
                size={16}
                defaultValue={watch('radioTipoComissao')}
                options={total}
                className="radius mr0"
                onChange={(e) => handleTotalComissao(e)}
              />
              <input
                type="hidden"
                className="hidden-comissao"
                {...register("radioTipoComissao", {
                  required: true,
                })}
              />
              {errors.radioTipoComissao &&
                <div className="error-genero">
                  <span> <ErrorIcon /> {errorTextInput} </span>
                </div>
              }
              {watch('radioTipoComissao') === 'partes' && <Badge color="blue" className='mt15'>{(watch("parcelas").length) + "x Parcelas"}</Badge>}
            </div>

            <div className='mt20 flex divChildMr20'>
              <TextInputField
                width={200}
                label={<>
                  Comissão total * <Badge marginBottom={-1} color="blue">{porcentagemComissaoTotal()}%</Badge>
                </>}
                placeholder="Ex: R$ 500.000,00"
                value={watch('valor_comissao_total')}
                style={watch('valor_comissao_total') ? inputGreen : errors.valor_comissao_total ? inputError : inputDefault}
                validationMessage={errors.valor_comissao_total && errorTextInput}
                {...register("valor_comissao_total", {
                  required: true,
                  setValueAs: e => formatoMoeda(e),
                  onBlur: () => calculoDeducao()
                })}
              />
              <TextInputField
                width={150}
                label="Deduções"
                placeholder="Ex: R$ 500.000,00"
                value={watch('deducao')}
                style={watch('deducao') ? inputGreen : errors.deducao ? inputError : inputDefault}
                {...register("deducao", {
                  required: false,
                  setValueAs: e => formatoMoeda(e),
                  onBlur: () => calculoDeducao()
                })}
              />
            </div>

            <div className='divChildMr20'>
              {watch("parcelas").map((parcela, index) => (
                <div className='flex'>
                  <div hidden={watch('radioTipoComissao') === 'integral'} className="mr0">
                    <TextInputField
                      width={150}
                      label={(index + 1) + "º Parcela* "}
                      name={'parcela.' + index + '.valor'}
                      placeholder="Ex: R$ 500.000,00"
                      value={parcela.valor}
                      style={parcela.valor ? inputGreen : errors.parcelas?.[index]?.valor ? inputError : inputDefault}
                      validationMessage={errors.parcelas?.[index]?.valor && errors.parcelas?.[index]?.valor.message}
                      {...register('parcelas.' + index + '.valor', {
                        required: watch('radioTipoComissao') === 'partes' && errorTextInput,
                        setValueAs: e => formatoMoeda(e),
                        validate: () => limitValidate(),
                      })}
                    />
                  </div>
                  <SelectField
                    width={250}
                    label="Período do pagamento*"
                    value={parcela.periodo}
                    className={parcela.periodo ? 'inputGreenSelect'
                      : errors.parcelas?.[index]?.periodo ? 'inputRedSelect' : ''
                    }
                    {...register('parcelas.' + index + '.periodo', {
                      required: true,
                      onChange: e => setValue('parcelas.' + index + '.periodo', e.target.value)
                    })}
                    validationMessage={errors.parcelas?.[index]?.periodo && errorTextInput}
                  >
                    <option value="" hidden>Selecione o período</option>
                    {listaPeriodo_gerente.map((item) => (
                      <option value={item.value}>{item.label}</option>
                    ))}
                  </SelectField>

                  {(index > 0 && index === watch('parcelas').length - 1) &&
                    <Button className='mt26' appearance='primary' intent='danger' onClick={() => removeParcela(index)}><CrossIcon color='white' /></Button>
                  }
                </div>
              ))}
            </div>
            {watch('radioTipoComissao') === 'partes' &&
              <Button iconBefore={PlusIcon} onClick={() => addParcela()}>Adicionar parcela</Button>}
          </div>

          <div className='mt40'>
            <div className="fw600">Líquida *</div>
            <div className="rowForm line-radius mt10 flex">
              <Pane className='flex' aria-label="Radio Group Liquida" role="group">
                {liquida.map((option) => (
                  <Radio
                    className='mr20 radius'
                    size={16}
                    onClick={() => [setValue('liquida', option.value), clearErrors('liquida')]}
                    checked={watch('liquida') === option.value}
                    name="group1"
                    label={option.label} />
                ))}
              </Pane>
            </div>
            <input type="hidden" {...register("liquida", { required: true })} />
            {errors.liquida &&
              <div className="error-genero">
                <span> <ErrorIcon /> {errorTextInput} </span>
              </div>
            }

            <div className="mt20">
              <TextInputField
                width={150}
                label="Comissão líquida*"
                placeholder="Ex: R$ 100.000,00"
                value={watch('valor_comissao_liquida')}
                style={watch('valor_comissao_liquida') ? inputGreen : errors.valor_comissao_liquida ? inputError : inputDefault}
                validationMessage={errors.valor_comissao_liquida && errorTextInput}
                {...register("valor_comissao_liquida", {
                  required: true,
                  setValueAs: e => formatoMoeda(e)
                })}
              />
            </div>
          </div>

        </Pane>}

      <div className="flex flexEnd">
        <div>
        </div>
        <Button iconBefore={<ChevronLeftIcon />} disabled={!loading} appearance="default" marginLeft={15} onClick={(e) => handleBtnAnterior(e)}>
          Anterior
        </Button>
        <Button
          type="submit"
          appearance="primary"
          marginLeft={15}
          color="white"
          disabled={!loading}
          iconAfter={<ChevronRightIcon color='white' />}
          onClick={
            handleSubmit(e => dadosVenda.status_processo !== 22
              ? handleBtnAvancar(e)
              : alteracaoRevisao === true ? handleBtnAvancar(e) : toaster.danger('Realize os ajustes solicitados pelo pós-venda para avançar.'))
          }
        >
          Avançar
        </Button>
      </div>
    </Pane>
  )
}