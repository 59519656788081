import React, { useContext } from 'react';
import {
    Pane,
    Table,
    Button,
    TickCircleIcon,
    ErrorIcon,
    Avatar,
    InfoSignIcon,
    Combobox,
    Select,
    Pagination,
    FilterIcon,
} from 'evergreen-ui';

import Lottie from 'react-lottie';
import SideSheetRecibo from '../SideSheetView';

//Context
import DirecaoContext from '../../../../context/DirecaoContext';

export default function VendasAdicionadas() {

    const {
        loading,
        defaultOptions,
        usuario_id,

        // SIDESHEET
        setIsShowSide,
        setDadosVenda,        

        // FILTRO
        filtro,
        quantFiltro,

        // LISTAS SELECT
        listGerentes,
        listLojas,
        listEnderecos,
        tipoLaudemio,

        // REFRESH API TABLE
        getProcessosRascunho,
        handleClearFilter,

        // ITEMS TABLE
        processosRascunho,

    } = useContext(DirecaoContext);

    // Filtro de Combo
    const handleCombobox = (e, campo) => {
        if (e) {
            localStorage.setItem('filtro_' + campo, JSON.stringify(e));
            filtro[campo] = e;
            getProcessosRascunho(1);
        }
    };

    const handleSelect = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        localStorage.setItem('filtro_' + name, value);
        filtro[name] = value;
        getProcessosRascunho(1);
    };

    function verificarRevisao(venda) {
        if (venda.revisao && venda.revisao.valores_documentos_imovel === venda.revisao.valores_documentos_imovel_verific_save &&
            venda.revisao.vendedores === venda.revisao.vendedores_verific_save &&
            venda.revisao.compradores === venda.revisao.compradores_verific_save &&
            venda.revisao.comissao === venda.revisao.comissao_verific_save) {
            return 'revisado';
        }
        else return 'revisar';
    };

    const handleSideSheet = (e, recibo) => {
        console.log(recibo);
        setDadosVenda(recibo);
        // setDadosReciboEnviado(recibo);
        setIsShowSide(true);
    };

    return (
        <Pane>
            <Pane>
                <div className='flex space-between pb10'>
                    <div className="flex gap20 end">
                        <span>Filtrar por: </span>
                        <div>
                            <Combobox
                                openOnFocus
                                name='endereco_rascunho'
                                items={listEnderecos.rascunhos}
                                selectedItem={filtro.endereco_rascunho}
                                itemToString={item => (item ? item.endereco : '')}
                                placeholder="🔍︎ Endereço"
                                onChange={(e) => handleCombobox(e, 'endereco_rascunho')}
                                width={200}
                                id="filtro-endereco"
                                className="filtro-select color-select-filter"
                            />
                        </div>
                            <div>
                                <Combobox
                                    openOnFocus
                                    name='gerente_rascunho'
                                    items={listGerentes}
                                    selectedItem={filtro.gerente_rascunho}
                                    itemToString={item => (item ? item.name : '')}
                                    placeholder="🔍︎ Gerente"
                                    onChange={(e) => handleCombobox(e, 'gerente_rascunho')}
                                    width={180}
                                    id="filtro-gerente"
                                    className="filtro-select color-select-filter"
                                />
                            </div>
                            <div>
                                <Select
                                    label=""
                                    name='loja_rascunho'
                                    value={filtro.loja_rascunho}
                                    onChange={(e) => handleSelect(e)}
                                    id="filtro-loja_rascunho"
                                    width={160}
                                    className={filtro.loja_rascunho === "" ? "filtro-select bgWhite color-select-filter-hidden" : "filtro-select bgWhite color-select-filter"}
                                >
                                    <option value="" hidden>Loja</option>
                                    {listLojas.map(valor =>
                                        <option key={valor.id} value={valor.id}>{valor.nome}</option>
                                    )}
                                </Select>
                            </div>
                            <div>
                                <Select
                                    label=""
                                    name='mes_cadastro_rascunho'
                                    value={filtro.mes_cadastro_rascunho}
                                    onChange={(e) => handleSelect(e)}
                                    id="filtro-mes-cadastro"
                                    className={filtro.mes_cadastro_rascunho === "" ? "filtro-select bgWhite color-select-filter-hidden" : "filtro-select bgWhite color-select-filter"}
                                >
                                    <option value="" hidden selected >Mês de cadastro</option>
                                    <option value="1">Janeiro</option>
                                    <option value="2">Fevereiro</option>
                                    <option value="3">Março</option>
                                    <option value="4">Abril</option>
                                    <option value="5">Maio</option>
                                    <option value="6">Junho</option>
                                    <option value="7">Julho</option>
                                    <option value="8">Agosto</option>
                                    <option value="9">Setembro</option>
                                    <option value="10">Outubro</option>
                                    <option value="11">Novembro</option>
                                    <option value="12">Dezembro</option>
                                </Select>
                            </div>
                        </div>
                        <div>
                            <Pagination
                                page={processosRascunho.page}
                                totalPages={processosRascunho.totalPaginas}
                                onPageChange={(e) => !loading.rascunho ? getProcessosRascunho(e) : ''}
                                onNextPage={() => getProcessosRascunho(processosRascunho.page + 1)}
                                onPreviousPage={() => getProcessosRascunho(processosRascunho.page - 1)}
                            />
                        </div>
                    </div>
                    {quantFiltro.rascunho >= 1 &&
                        <div className="filter-info">
                            <p>
                                <FilterIcon className="icon-filter" />
                                {quantFiltro.rascunho} {quantFiltro.rascunho === 1 ? 'filtro aplicado. ' : 'filtros aplicados. '}
                                {processosRascunho.items.length} / {processosRascunho.totalItemsFilter} {processosRascunho.totalItemsFilter === 1 ? 'processo visível' : 'processos visíveis'}.
                                <span onClick={(e) => handleClearFilter('rascunho')}> Limpar filtro</span>
                            </p>
                        </div>}
            </Pane>

            <div className="Rectangle35 Blue"></div>
            <Table.Body className="table" style={{ overflow: 'hidden' }}>
                <Table.Head>
                    {<Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0} className="header-title">
                        Gerente
                    </Table.TextCell>}
                    <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0} className="header-title">
                        Loja
                    </Table.TextCell>
                    <Table.TextCell flexBasis={300} flexShrink={0} flexGrow={0} className="header-title">
                        Endereço
                    </Table.TextCell>
                    <Table.TextCell flexBasis={155} flexShrink={0} flexGrow={0} className="header-title">
                        Data de cadastro
                    </Table.TextCell>
                    <Table.TextCell flexBasis={300} flexShrink={0} flexGrow={0} className="header-title">
                        Etapa
                    </Table.TextCell>
                    <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="header-title">
                        {/*Forma de pagamento*/}
                    </Table.TextCell>
                    <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} className="header-title">
                        {/*Arquivo do venda*/}
                    </Table.TextCell>
                    <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className="header-title">
                        {/*Arquivo do venda*/}
                    </Table.TextCell>
                </Table.Head>
                {loading.rascunho ?
                    <div className='backgroundLoadingDocs'>
                        <Lottie options={defaultOptions} height={200} width={400} />
                    </div>
                    :
                    <Table.Body style={{ backgroundColor: 'white' }}>
                        {processosRascunho.items.length > 0 ?
                            <div>
                                {processosRascunho.items.map(venda =>
                                    <Table.Row key={venda.id}>
                                        <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0} className="content">
                                            <Avatar hashValue={venda.nome_criador_processo} name={venda.nome_criador_processo} size={40} marginRight={10} />
                                        </Table.TextCell>

                                        <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0} className="content">
                                            {venda.loja !== null ? venda.loja.nome : 'Sem loja'}
                                        </Table.TextCell>

                                        <Table.TextCell
                                            flexBasis={300}
                                            flexShrink={0}
                                            flexGrow={0}
                                            className="content-endereco content-click"
                                            style={parseInt(usuario_id) !== venda.id_usuario_criador_processo ? { cursor: 'default' } : { cursor: 'pointer' }}
                                        // onClick={(e) => parseInt(usuario_id) !== venda.id_usuario_criador_processo ? (e) : formVenda(e, venda)}
                                        >
                                            {venda.logradouro + ', ' + venda.numero + (venda.unidade !== null ? " / " + venda.unidade : "")}
                                        </Table.TextCell>

                                        <Table.TextCell flexBasis={155} flexShrink={0} flexGrow={0} className="content">
                                            {venda.data_criacao_processo}
                                        </Table.TextCell>

                                        <Table.TextCell
                                            flexBasis={300}
                                            flexShrink={0}
                                            flexGrow={0}
                                            className="content-endereco"
                                        >
                                            <div className="steps steps-lista">
                                                {venda.status_processo === 22
                                                    ?
                                                    <div className="step-item">
                                                        <span className="default">
                                                            {verificarRevisao(venda) === 'revisado' ? "Revisão da devolução" : "Ajustes na devolução "}
                                                            {
                                                                verificarRevisao(venda) === 'revisado'
                                                                    ?
                                                                    <TickCircleIcon style={{ height: '16px' }} size="16" className='icon-tick-revisao' />
                                                                    :
                                                                    <ErrorIcon style={{ height: '16px' }} size="16" className="icon-error-revisao" />
                                                            }
                                                        </span>
                                                    </div>

                                                    :
                                                    <>
                                                        {
                                                            (venda.status_processo === 11 && venda.status_processo !== 24)
                                                                || (venda.status_processo === 15 && venda.status_processo !== 24)
                                                                || (venda.status_processo === 9 && venda.status_processo !== 24)
                                                                || (venda.status_processo === 10 && venda.status_processo !== 24)
                                                                || (venda.status_processo === 24 && venda.status_anterior_processo_id === 10)
                                                                || (venda.status_processo === 10 && venda.status_anterior_processo_id === 24)
                                                                || (venda.status_processo === 24 && venda.status_anterior_processo_id === 23)
                                                                || (venda.status_processo === 11 && venda.status_anterior_processo_id === 24)
                                                                || (venda.status_processo === 15 && venda.status_anterior_processo_id === 24)
                                                                || (venda.status_processo === 11 && venda.status_anterior_processo_id === 23)
                                                                || (venda.status_processo === 15 && venda.status_anterior_processo_id === 23)
                                                                ?
                                                                <div className="step-item" style={{ marginRight: '70px' }}>
                                                                    <span className="default">Dados e rascunho</span>
                                                                </div>

                                                                : //venda.status_processo === 16px
                                                                venda.status_processo >= 16 && venda.status_processo !== 24
                                                                    ?
                                                                    <div className="step-item" style={{ marginRight: '30px' }}>
                                                                        <span className="default">Upload do Recibo de Sinal</span>
                                                                    </div>

                                                                    : venda.status_processo >= 17 && venda.status_processo <= 20
                                                                        ?
                                                                        <div className="step-item" style={{ marginRight: '70px' }}>
                                                                            <span className="default">Boletim de venda</span>
                                                                        </div>
                                                                        :
                                                                        ''
                                                        }

                                                        <div className="step-item">
                                                            <div className="progress-line">
                                                                <div className={
                                                                    (venda.status_processo >= 15 && venda.status_processo !== 24)
                                                                        || (venda.status_processo === 11 && venda.status_anterior_processo_id === 24)
                                                                        || (venda.status_processo === 11 && venda.status_anterior_processo_id === 23)
                                                                        || (venda.status_processo === 15 && venda.status_anterior_processo_id === 23)
                                                                        ? "total-progress progress-step-" + venda.status_processo : "total-progress progress-step-10"
                                                                }
                                                                ></div>
                                                            </div>
                                                        </div>

                                                        <div className="step-item">
                                                            <span className="etapa">
                                                                {
                                                                    (venda.status_processo === 15 && venda.status_processo !== 24)
                                                                        || (venda.status_processo === 11 && venda.status_anterior_processo_id === 24)
                                                                        || (venda.status_processo === 11 && venda.status_anterior_processo_id === 23)
                                                                        || (venda.status_processo === 15 && venda.status_anterior_processo_id === 23)
                                                                        ? 1
                                                                        : venda.status_processo >= 16 && venda.status_processo < 20
                                                                            ? 2
                                                                            : venda.status_processo === 20
                                                                                ? <TickCircleIcon className="step-check" />
                                                                                : 0
                                                                }
                                                            </span>{venda.status_processo === 20 ? '' : '/3'}
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </Table.TextCell>

                                        <Table.TextCell
                                            flexBasis={
                                                venda.status_processo === 22
                                                    ? venda.id_usuario_criador_processo !== parseInt(usuario_id) ? 155 : 130
                                                    : 155
                                            }
                                            flexShrink={0}
                                            flexGrow={0}>

                                            <Button
                                                marginY={8}
                                                marginRight={12}
                                                appearance="primary"
                                                onClick={(e) => handleSideSheet(e, venda)}
                                            >
                                                <InfoSignIcon
                                                    color="#fff"
                                                    style={{ width: 11, height: 11, marginRight: 10 }}
                                                />
                                                Ver detalhes
                                            </Button>

                                        </Table.TextCell>
                                    </Table.Row>
                                )}
                            </div>

                            :
                            // Se não tiver nenhum documento exibe esse bloco
                            !loading.rascunho &&
                            <Table.Row key={1}>
                                <div className="mensagem-processo">
                                    Acompanhe por aqui todas as vendas cadastradas.
                                </div>
                            </Table.Row>
                        }
                    </Table.Body>
                }
            </Table.Body>
            <SideSheetRecibo />
        </Pane>
    )
}