import Axios from 'axios';
import Serve from '../app/serve';
import ServeLogin from '../app/serve_login';
import { createCancelToken } from './cancelRequests';
import { toaster } from 'evergreen-ui';

async function SaveDocument(idProcesso, file, idDonoDocumento, papel, index_Doc, setProgressBar, progressBar, acessoTemporario, pedidoProcesso) {
  const { token, cancel } = createCancelToken();  
  const app = acessoTemporario ? ServeLogin() : Serve();
  const api = acessoTemporario ? 'salvar_documento_unico_acesso' : 'salvar_documento_unico';
  
  let result;
  let arrayData = new FormData();
  console.log(pedidoProcesso);

  if (file?.file && file?.item[0]) {
    if (typeof (file.file) === 'string' || file.file instanceof String) {
      let arquivoVazio = new File(["foo"], "nao_teve_alteracao_foo.txt", {
        type: "text/plain",
      });
      arrayData.append(`arquivo`, arquivoVazio);
    } else {
      arrayData.append(`arquivo`, file.file);
    }
    if (file) {
      file.item.forEach((tipo, index_tipo) => {
        arrayData.append(`tipo_documento_ids[${index_tipo}]`, tipo.value);
        arrayData.append(`multiplo_documento_id[${index_tipo}]`, tipo.id ? tipo.id : "");
        arrayData.append(`data_emissao[${index_tipo}]`, tipo.emissao || '');  // DATA INICIAL
        arrayData.append(`validade_dias[${index_tipo}]`, tipo.validade || ''); // DIAS A CONTAR
        arrayData.append(`data_vencimento[${index_tipo}]`, tipo.vencimento || '');  // DATA FINAL
      })
    }

    arrayData.append(`processo_id`, idProcesso);
    arrayData.append(`documentos_id`, file.id ? file.id : "");
    arrayData.append(papel === 'imovel' ? `imovel_id` : `idDonoDocumento`, idDonoDocumento);
    
    if(localStorage.getItem('perfil_login') === 'Núcleo'){
      arrayData.append('solicitacao_id', pedidoProcesso?.id || '');
    }

    arrayData.append(`papel`, papel);
  };

  await Axios.post(app + api, arrayData, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    onUploadProgress: (progressEvent) => {
      const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      progressBar[index_Doc] = { percent: percentage, status: 'active', error: '' };
      setProgressBar([...progressBar]);
    },
    // cancelToken: token,
  })
    .then(res => {
      result = res;
      console.log(res);
      progressBar[index_Doc] = { percent: 100, status: 'success', error: '' };
      setProgressBar([...progressBar]);
    })
    .catch(function (error) {
      console.log(error);
      progressBar[index_Doc] = { percent: 0, status: 'error', error: '' };
      setProgressBar([...progressBar]);
    })

  return result;
};

export default SaveDocument;