import React, { useState, useContext, useEffect } from 'react';
import { Pane, Heading, Tablist, Tab, Card, SideSheet, Spinner } from 'evergreen-ui';
import NegociacaoContext from '../../../context/NegociacaoContext';
import FormImovel from './FormsSideSheet/Imovel';
import FormGerentes from './FormsSideSheet/Gerentes';
import FormCompradores from './FormsSideSheet/Comprador_pf_pj';
import FormVendedores from './FormsSideSheet/Vendedor_pf_pj';
import FormVenda from './FormsSideSheet/Venda'
import Nucleo from './FormsSideSheet/Nucleo';
import Historico from './FormsSideSheet/Historico';
import '../../../index.css';
import getChecklistPadrao from '../../../apis/getChecklistPadrao';

export default function SideSheetView({ refreshProcess }) {

    const {
        processSelect,
        isShownSide, setIsShownSide,
        selectedIndex, setSelectedIndex,
        setMultiDocs,
        loading,
        listPadrao, setListPadrao
    } = useContext(NegociacaoContext);
    const [tabs] = useState(['Venda', 'Imóvel', 'Gerente', 'Vendedores', 'Compradores', 'Núcleo', 'Histórico']);
    const info = processSelect?.imovel;
    const sideProcesso = info?.logradouro + ', ' + info?.numero + (info?.unidade ? ' / ' + info.unidade : '') + ' - ' + info?.bairro;


    const refreshFormValues = () => {
        //Setar valor para formValues e o MultiDocs que será usado na Aba Imovel, Vendedores e Compradores        
        if (processSelect.imovel) {
            const compradores = processSelect.imovel.compradores.data;
            const vendedores = processSelect.imovel.vendedores.data;
            const imovel = processSelect.imovel.documentos.data;

            const returnPessoa = (data_pessoa, type) => {
                const pessoa = data_pessoa.documentos.data.filter(
                    (filter) => filter.identifica_documento === type).map(
                        (doc) => ({
                            'file': doc.arquivo,
                            'id': doc.id,
                            'item': doc.tipos_multiplos_documentos.map((tipo) => ({ 
                                id: tipo.id, 
                                value: tipo.tipo_documento_id, 
                                emissao: tipo.data_emissao, 
                                validade: tipo.validade_dias,  
                                vencimento: tipo.data_vencimento,                                
                                have_validade: !!tipo.validade_dias
                            }))
                        }))
                return pessoa;
            };

            setMultiDocs({
                'imovel': imovel.filter((filter) => (filter.identifica_documento === "imovel" || filter.identifica_documento === "imóvel")).map((doc) => ({ 
                    'file': doc.arquivo,
                    'id': doc.id,
                    'item': doc.tipos_multiplos_documentos.map((tipo) => ({ 
                        id: tipo.id, 
                        value: tipo.tipo_documento_id, 
                        emissao: tipo.data_emissao, 
                        validade: tipo.validade_dias,  
                        vencimento: tipo.data_vencimento,
                        have_validade: !!tipo.validade_dias
                    }))
                })),
                'vendedores': vendedores.map((vendedor) => {
                    if (vendedor.tipo_pessoa === 0) {
                        const fisica = returnPessoa(vendedor, "pessoa_fisica");
                        return { fisica };

                    } else {
                        const juridica = returnPessoa(vendedor, "pessoa_juridica");
                        const representante_socios = vendedor.representante_socios.data.map(
                            representante => returnPessoa(representante, "pessoa_fisica")
                        );
                        return { juridica, representante_socios };
                    }
                }),
                'compradores': compradores.map((comprador) => {
                    if (comprador.tipo_pessoa === 0) {
                        const fisica = returnPessoa(comprador, "pessoa_fisica");
                        return { fisica };

                    } else {
                        const juridica = returnPessoa(comprador, "pessoa_juridica");
                        const representante_socios = comprador.representante_socios.data.map(
                            representante => returnPessoa(representante, "pessoa_fisica")
                        );
                        return { juridica, representante_socios };
                    }
                }),
            });

        };
    };

    useEffect(() => {
        refreshFormValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processSelect]);

    const handleClose = async() => {
        const listPadrao = await getChecklistPadrao();
        setListPadrao(listPadrao);
        setIsShownSide(false)
    }

    return (
        <SideSheet
            isShown={isShownSide}
            onCloseComplete={(e) => handleClose()}
            containerProps={{
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
                className: 'side-sheet-box'
            }}
            preventBodyScrolling
            width={700}
        >
            <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
                <Pane padding={16} borderBottom="muted">
                    <Heading size={600}>
                        {sideProcesso === '' ? 'Sem endereço...' : sideProcesso}
                    </Heading>
                </Pane>
                <Pane display="flex" padding={8}>
                    <Tablist>
                        {tabs.map((tab, index) => (
                            <Tab
                                key={tab}
                                isSelected={selectedIndex === index}
                                onSelect={() => setSelectedIndex(index)}
                                aria-controls={`panel-${index}`}
                                aria-selected={selectedIndex === index ? true : false}
                                style={selectedIndex === index ? { boxShadow: '0 0 0 2px #d6e0ff' } : { boxShadow: 'none' }}
                            >
                                {tab}
                            </Tab>
                        ))}
                    </Tablist>
                </Pane>
            </Pane>
            <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
                {tabs.map((tab, index) => (
                    <Card
                        key={tab}
                        id={`panel-${tab}`}
                        role="tabpanel"
                        aria-labelledby={tab}
                        aria-hidden={index !== selectedIndex}
                        display={index === selectedIndex ? 'block' : 'none'}
                    >
                        {/*Id do processo - sideId*/}
                        {// Enquanto não carregar os dados exibe um Spinner
                            loading && <div className='backgroundLoadingDocs'><Spinner size={50} /></div>
                        }
                        {tab === 'Venda' && index === selectedIndex && !loading && <FormVenda refreshProcess={refreshProcess} />}
                        {tab === 'Imóvel' && index === selectedIndex && !loading && <FormImovel refreshProcess={refreshProcess} />}
                        {tab === 'Gerente' && index === selectedIndex && !loading && <FormGerentes refreshProcess={refreshProcess} />}
                        {tab === 'Vendedores' && index === selectedIndex && !loading && <FormVendedores refreshProcess={refreshProcess} />}
                        {tab === 'Compradores' && index === selectedIndex && !loading && <FormCompradores refreshProcess={refreshProcess} />}
                        {tab === 'Núcleo' && index === selectedIndex && !loading && <Nucleo refreshProcess={refreshProcess} />}
                        {tab === 'Histórico' && index === selectedIndex && !loading && <Historico refreshProcess={refreshProcess} />}
                    </Card>
                ))}
            </Pane>
        </SideSheet>
    )
}