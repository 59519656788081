import React, { useState } from 'react';
import { Pane } from 'evergreen-ui';
import { useHistory } from 'react-router-dom';
import SideMenu from './components/SideMenu';
import Administradores from './components/Administradores';
import Users from './components/Users';
import PainelAdminContext from '../../context/PainelAdminContext'
import AddUser from './components/Dialogs/AddUser'
import './styles.css';
import getUsers from '../../apis/getUsers';
import getLojas from '../../apis/getLojas';
import getPerfis from '../../apis/getPerfis';
import getResponsaveis from '../../apis/getResponsaveis';

import UploadCorretores from './components/UploadCorretores';
import PosVenda from './components/PosVenda';

export default function PainelAdmin() {
  const [perfil_login] = useState(localStorage.getItem('perfil_login'));
  const history = useHistory();
  if (perfil_login !== "Admin") history.push('/');

  //Context
  const [selectedIndex, setSelectedIndex] = useState(1); // indice de menus laterais    
  const [showAddUser, setShowAddUser] = useState(false); // Modal de Add user
  const [userSelected, setUserSelected] = useState({}); // informações de usuario para modal de Adduser
  const [users, setUsers] = useState([]);
  const [shopsList, setShopsList] = useState([]);
  const [cargoList, setCargoList] = useState([]);
  const [responsaveis, setResponsaveis] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const conxtextStatus = {
    userSelected, setUserSelected,
    selectedIndex, setSelectedIndex,
    showAddUser, setShowAddUser,
    cargoList,
    shopsList,
    users,
    adminUsers,
    setUsers,
    loading,
    setLoading,
    responsaveis
  };

  async function userLoading() {
    setLoading(true);

    const getlojas = await getLojas();
    setShopsList(getlojas);

    const getusers = await getUsers();
    setUsers(getusers);

    const getperfis = await getPerfis();
    setCargoList(getperfis);

    const getresponsaveis = await getResponsaveis();
    setResponsaveis(getresponsaveis);

    setAdminUsers(getusers.filter((user) => user.cargoId === 1));
    setLoading(false);
  };

  return (
    <Pane>
      <PainelAdminContext.Provider value={conxtextStatus}>
        <div>
          <SideMenu />
          <AddUser userLoading={userLoading} />
          {selectedIndex === 0 && <Administradores userLoading={userLoading} />}
          {selectedIndex === 1 && <Users userLoading={userLoading} />}
          {selectedIndex === 2 && <UploadCorretores userLoading={userLoading} />}
          {selectedIndex === 3 && <PosVenda userLoading={userLoading} />}
        </div>
      </PainelAdminContext.Provider>
    </Pane>
  )
}