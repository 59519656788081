import React, { useContext, useState } from 'react';
import { Pane, Tablist, Tab, Button } from 'evergreen-ui';
import NucleoContext from '../../../../context/NucleoContext';
import FormPessoa from '../../../../components/FormPessoa';
import Axios from 'axios'
import Serve from '../../../../app/serve';
import { useHistory } from 'react-router-dom';

export default function Compradores({ refreshTable, refreshProcessoSideSheet }) {
  const {
    setLoading,
    processSelect,
    multiDocs,
  } = useContext(NucleoContext);
  const [compradoresProcesso] = useState(processSelect.processo.imovel.compradores.data);
  console.log(multiDocs);

  const history = useHistory();
  const app = Serve();
  const [token] = useState(localStorage.getItem('token'));
  const usuario_id = localStorage.getItem('usuario_id');

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tabs] = useState(compradoresProcesso?.map((comprador) => comprador.name || comprador.nome_fantasia));
  const [processo] = useState(processSelect.processo);
  const [gerente_id] = useState(localStorage.getItem('usuario_id'));
  const pedidoProcesso = processSelect;

  const handleSalvarDocumentos = async (index_pessoa) => {
    setLoading(true);

    const arrayData = new FormData();

    const saveMultiDocs = (e, index_documento, id_usuario) => {
      if (e.file && e.item[0]) {
        if (typeof (e.file) === 'string' || e.file instanceof String) {
          let arquivoVazio = new File(["foo"], "nao_teve_alteracao_foo.txt", {
            type: "text/plain",
          });
          arrayData.append(`arquivos[${index_documento}]`, arquivoVazio);
        } else {
          arrayData.append(`arquivos[${index_documento}]`, e.file);
        }
        if (e) {
          e.item.forEach((tipo, index_tipo) => {
            arrayData.append(`tipo_documento_ids[${index_documento}][${index_tipo}]`, tipo.value);
            arrayData.append(`multiplo_documento_id[${index_documento}][${index_tipo}]`, tipo.id ? tipo.id : "");
            arrayData.append(`data_emissao[${index_documento}][${index_tipo}]`, tipo.emissao || '');  // DATA INICIAL
            arrayData.append(`validade_dias[${index_documento}][${index_tipo}]`, tipo.validade || ''); // DIAS
            arrayData.append(`data_vencimento[${index_documento}][${index_tipo}]`, tipo.vencimento || ''); // DATA FINAL
          })
        }
        arrayData.append(`documentos_ids[${index_documento}]`, e.id ? e.id : "");
        arrayData.append(`idDonoDocumento[${index_documento}]`, id_usuario);
        arrayData.append(`papel[${index_documento}]`, 'comprador');
      }
    };

    arrayData.append('processo_id', processSelect.processo.id);
    arrayData.append('solicitacao_id', processSelect.id);

    // MULTIPLOS DOCUMENTOS EM 1 ARQUIVO PESSOA FISICA      
    multiDocs.compradores[index_pessoa].fisica?.forEach((docs, index_documento) => {
      saveMultiDocs(docs, index_documento, compradoresProcesso[index_pessoa].id);
    });

    // MULTIPLOS DOCUMENTOS EM 1 ARQUIVO PESSOA JURIDICA
    multiDocs.compradores[index_pessoa].juridica?.forEach((docs, index_documento) => {
      saveMultiDocs(docs, index_documento, compradoresProcesso[index_pessoa].id);
    });

    // MULTIPLOS DOCUMENTOS EM 1 ARQUIVO REPRESENTANTE
    multiDocs.compradores[index_pessoa].representante_socios?.forEach((representante, index_rp) => {
      representante.forEach((rep, index_documento_rep) => {
        if (rep.file && rep.item[0]) {
          if (typeof (rep.file) === 'string' || rep.file instanceof String) {
            let arquivoVazioRepresentante = new File(["foo"], "nao_teve_alteracao_foo.txt", {
              type: "text/plain",
            });
            arrayData.append(`rp_arquivos[${index_rp}][${index_documento_rep}]`, arquivoVazioRepresentante);
          } else {
            arrayData.append(`rp_arquivos[${index_rp}][${index_documento_rep}]`, rep.file);
          }
          if (rep) {
            rep.item.forEach((tipo, index_tipo) => {
              arrayData.append(`rp_tipo_documento_ids[${index_rp}][${index_documento_rep}][${index_tipo}]`, tipo.value);
              arrayData.append(`rp_multiplo_documento_id[${index_rp}][${index_documento_rep}][${index_tipo}]`, tipo.id ? tipo.id : "");
              arrayData.append(`rp_data_emissao[${index_rp}][${index_documento_rep}][${index_tipo}]`, tipo.emissao || '');  // DATA INICIAL
              arrayData.append(`rp_validade_dias[${index_rp}][${index_documento_rep}][${index_tipo}]`, tipo.validade || ''); // DIAS
              arrayData.append(`rp_data_vencimento[${index_rp}][${index_documento_rep}][${index_tipo}]`, tipo.vencimento || ''); // DATA FINAL
            })
          }
          arrayData.append(`rp_documentos_ids[${index_rp}][${index_documento_rep}]`, rep.id ? rep.id : "");
          arrayData.append(`rp_idDonoDocumento[${index_rp}][${index_documento_rep}]`, compradoresProcesso[index_pessoa].representante_socios.data[index_rp].id);
          arrayData.append(`rp_papel[${index_rp}][${index_documento_rep}]`, 'Representante');
        }
      })

    });

    Axios.post(app + 'salvar_documentos_usuario_nucleo', arrayData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      }
    }).then(res => {
      if (res.data.status && res.data.status === (401 || 498)) {
        localStorage.clear();
        history.push('/');
      } else {
        let mensagem = res.data.mensagem_processo;
        if (res.data.mensagem_documento !== '') {
          mensagem += '\n' + res.data.mensagem_documento;
        }
        console.log("mensagem Erro: ", mensagem);
        refreshTable();
        refreshProcessoSideSheet();
      }
    }).catch(function (error) {
      console.log(error);
      setLoading(false);
    })
  };

  return (
    <Pane padding={10}>
      <Pane>
        <Tablist marginBottom={16} flexBasis={650} marginRight={24}>
          {tabs.map((tab, index) => (
            <Tab
              key={tab}
              onSelect={() => setSelectedIndex(index)}
              isSelected={index === selectedIndex}
              aria-controls={`panel-${tab}`}
              data-tab={tab}
              className='tabList'
            >
              {tab.slice(0, 10) + '...'}
            </Tab>
          ))}
        </Tablist>

        {tabs.map((tab, index) => (
          <Pane
            key={tab}
            id={`panel-${tab}`}
            role="tabpanel"
            aria-labelledby={tab}
            aria-hidden={index !== selectedIndex}
            display={index === selectedIndex ? 'block' : 'none'}
          >
            <FormPessoa pessoa="compradores" dataPessoa={compradoresProcesso[index]} context={NucleoContext} indexPessoa={index} refreshTable={refreshTable} pedidoProcesso={pedidoProcesso} />

            <Pane className='alignRight'>
              <div></div>
              <div className='mt50'>
                <spam className='fw400 f10 mr10'>
                  Ao salvar alterações, os documentos serão compartilhados com o pós-venda
                </spam>
                <Button appearance='primary' onClick={() => handleSalvarDocumentos(index)}>✔ Salvar alterações</Button>
              </div>
            </Pane>
          </Pane>
        ))}
      </Pane>
    </Pane>
  )
}