import React, { useState, useContext, useRef, useEffect } from 'react';
import { Pane, Dialog, Table, Button, ArrowRightIcon, DownloadIcon } from 'evergreen-ui';
import ApoioContext from '../../../../../../context/ApoioContext';
//import formatoMoeda from '../../../../../../components/formatoMoeda';
import formatoMoedaSemCifrao from '../../../../../../components/formatoMoedaSemCifracao';
import PlanilhaComissaoPDF from '../../../../../../pdf/PlanilhaDeComissao';
import Axios from 'axios';
import Serve from '../../../../../../app/serve';
import animationData from '../../../../../../images/Animations/loading_dna.json';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';

export default function VisualizarPlanilha(props) {
    const [token] = useState(localStorage.getItem('token'));
    const gerente_id = localStorage.getItem('usuario_id')
    const app = Serve();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const {
        isShownPlanilha, setIsShownPlanilha,
        setIsShownSide,
        selectProcess,
        setIsShownConfirmaPlanilha,
    } = useContext(ApoioContext);

    const processo = selectProcess;
    const processoComissao = selectProcess.imovel.comissao;
    const processoGerenteGG = selectProcess.imovel;    
    const diretorData = selectProcess.imovel.dados_diretor_comercial;
    const valorEmpresaRef = useRef();
    const tipoLoja = processo.imovel.empresa_receber.verificar_franquia; //0 - loja própria / 1 - franquia

    console.log('Processo Planilha: ', processo);

    const enderecoImovel = processo.imovel.logradouro + ", " + processo.imovel.numero + "" + (processo.imovel.unidade !== null ? (', ' + processo.imovel.unidade) : '') + (processo.imovel.complemento !== null ? (', ' + processo.imovel.complemento) : '') + ", " + processo.imovel.bairro + ", " + processo.imovel.cidade + " - " + processo.imovel.uf;
    const valorComissaoLiquida = processo?.imovel?.comissao?.valor_comissao_liquida?.replace(/[R$.]+/g, '');
    const valorComissaoDiretor = ((Number(valorComissaoLiquida.replace(",", ".") * 3)) / 100).toLocaleString('pt-br', { minimumFractionDigits: 2 });

    useEffect(() => {
        const valorTotalEmpresaReceber = (e) => {
            let corretor = processoComissao.corretores_vendedores_comissao.map(corretor => parseFloat(corretor.valor_real));
            let opcionista = processoComissao.corretores_opicionistas_comissao.map(opicionista => parseFloat(opicionista.valor_real));
            let gerente = processoGerenteGG.comissao_gerentes.map(gerente => parseFloat(gerente.valor_real));
            let gg = processoGerenteGG.comissao_gerente_gerais.map(gg => parseFloat(gg.valor_real));
            let diretor = parseFloat(diretorData?.valor_real || 0);

            let somaCorretor = 0;
            let somaOpcionista = 0;
            let somaGerente = 0;
            let somaGG = 0;

            for (var i = 0; i < corretor.length; i++) {
                somaCorretor += corretor[i];
            }

            for (var i2 = 0; i2 < opcionista.length; i2++) {
                somaOpcionista += opcionista[i2];
            }

            for (var i3 = 0; i3 < gerente.length; i3++) {
                somaGerente += gerente[i3];
            }

            for (var i4 = 0; i4 < gg.length; i4++) {
                somaGG += gg[i4];
            }

            const valorTotalComissoes = (somaCorretor + somaOpcionista) + (somaGerente + somaGG) + diretor;
            const valorTotalEmpresa = parseFloat(valorComissaoLiquida.replace(",", ".")) - valorTotalComissoes;
            valorEmpresaRef.current = valorTotalEmpresa.toLocaleString('pt-br', { minimumFractionDigits: 2 });
        }
        valorTotalEmpresaReceber();
    }, [])

    const d = new Date();
    const semana = ["domingo", "segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado"];
    const mes = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"]
    const datas = d.getDate();
    const ano = d.getFullYear();

    const handleEnviarPlanilha = (e) => {
        setLoading(true);
        Axios.post(app + 'enviar_planilha_comissao', {
            //"id_corretor": percentual[type][i].id,
            //'type': type
            'comissao_id': processoComissao.id,
            'id_apoio': gerente_id,
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {
            if (res.data.status && res.data.status === (401 || 498)) {
                localStorage.clear();
                history.push('/');
            } else {
                setIsShownPlanilha(false);
                setIsShownSide(false);
                setIsShownConfirmaPlanilha(true);
            }
        }).catch(function (error) {
            console.log(error);
        }).then(() => {
            setLoading(false);
        })
    };

    return (
        <Pane>
            <Dialog
                width={1350}
                isShown={isShownPlanilha}
                title={<h1>Preview da Planilha de Comissão</h1>}
                onCloseComplete={() => setIsShownPlanilha(false)}
                confirmLabel="Enviar planilha de comissão"
                containerProps={{
                    className: 'planilha-comissao'
                }}
                isConfirmLoading={loading}
                hasFooter={false}
            >
                {loading
                    ?
                    <Pane height={400} display="flex" justifyContent="center" alignItems="center">
                        <Lottie options={defaultOptions} height={200} width={200} />
                    </Pane>
                    :
                    <Pane height={750}>

                        <div className="subtitle">Revise cada informação na Planilha com cautela.</div>
                        <div className="subtitle" style={{ fontSize: '12px' }}>Rio de Janeiro, {semana[d.getDay()]}, {datas} de  {mes[d.getMonth()]} de {ano}</div>

                        <div className="content">
                            <ul className="loja" style={{marginBottom: '25px'}}>
                                <li><span>Loja:</span> {processo.loja.nome}</li>
                                <li><span>Data da Venda:</span> {processo.imovel.informacao.data_assinatura}</li>
                                <li><span>Referência:</span> {processo.imovel.codigo}</li>
                                <li><span>Imóvel:</span> {enderecoImovel}</li>
                            </ul>

                            {/* <div className="conta">
                                <ul>
                                    <li><span>Nome da Empresa:</span> {processo.imovel.empresa_receber.nome_empresarial}</li>
                                    <li><span>Banco:</span> {processo.imovel.empresa_receber.dados_bancarios.data[0].nome_banco} - Agência: {processo.imovel.empresa_receber.dados_bancarios.data[0].agencia} - C/c: {processo.imovel.empresa_receber.dados_bancarios.data[0].numero_conta}</li>
                                    <li><span>Chave Pix:</span> {processo.imovel.empresa_receber.dados_bancarios.data[0].pix}</li>
                                    <li><span>Creci:</span> {processo.imovel.empresa_receber.creci}</li>
                                </ul>
                            </div> */}

                            <div className="valores">
                                <ul style={{ display: 'flex' }}>
                                    <li><span>Valor de comisssão:</span> {processo.imovel.comissao.valor_comissao_liquida}</li>
                                    {/* <li style={{ marginLeft: '15px' }}><span>Total Comissão Empresa:</span> R${processo.imovel.comissao.valor_receber_empresa_opcao}</li> */}
                                </ul>
                            </div>

                            <div className="table">
                                <Table.Body>
                                    <Table.Head>
                                        <Table.TextCell flexBasis={134} flexShrink={0} flexGrow={0} className="right"></Table.TextCell>
                                        <Table.TextCell flexBasis={130} flexShrink={0} flexGrow={0} className="center">Porcentagem</Table.TextCell>
                                        <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className="center">Descontos</Table.TextCell>
                                        <Table.TextCell flexBasis={115} flexShrink={0} flexGrow={0} className="right">A receber</Table.TextCell>
                                        <Table.TextCell flexBasis={210} flexShrink={0} flexGrow={0} className="center">Nome empresarial</Table.TextCell>
                                        <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} className="center">CNPJ (MEI) / CPF</Table.TextCell>
                                        <Table.TextCell flexBasis={110} flexShrink={0} flexGrow={0} className="center">CRECI</Table.TextCell>
                                        <Table.TextCell flexBasis={280} flexShrink={0} flexGrow={0} className="center">Dados Bancário</Table.TextCell>
                                    </Table.Head>

                                    {/*Vendedor*/}
                                    {
                                        processoComissao.corretores_vendedores_comissao.map((corretor, index) => (
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.TextCell flexBasis={134} flexShrink={0} flexGrow={0} className="right">{index + 1}º Vendedor</Table.TextCell>
                                                    <Table.TextCell flexBasis={130} flexShrink={0} flexGrow={0} className="center">{corretor.porcentagem_real}%</Table.TextCell>
                                                    <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className="right">{corretor.desconto === null || corretor.desconto === '0' || corretor.desconto === '' ? '' : corretor.desconto}</Table.TextCell>
                                                    <Table.TextCell flexBasis={115} flexShrink={0} flexGrow={0} className="right">{corretor.valor_real === null ? '0,00' : corretor.valor_real}</Table.TextCell>
                                                    <Table.TextCell flexBasis={210} flexShrink={0} flexGrow={0} className="center">{corretor.tipo_pessoa === 'PF' ? corretor.name : corretor.nome_empresarial}</Table.TextCell>
                                                    <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} className="center">{corretor.tipo_pessoa === 'PF' ? corretor.cpf : corretor.cnpj}</Table.TextCell>
                                                    <Table.TextCell flexBasis={110} flexShrink={0} flexGrow={0} className="center">{corretor.creci}</Table.TextCell>
                                                    <Table.TextCell flexBasis={280} flexShrink={0} flexGrow={0} className="center">
                                                        {
                                                            corretor.tipo_pagamento === 'pix'
                                                                ? 'PIX: ' + corretor.pix
                                                                : 'Banco: ' + corretor.banco_nome + ' Agência: ' + corretor.agencia + ' C/C: ' + corretor.numero_conta
                                                        }
                                                    </Table.TextCell>
                                                </Table.Row>
                                            </Table.Body>
                                        ))
                                    }

                                    {/*Opcionista*/}
                                    {

                                        processoComissao.corretores_opicionistas_comissao.map((opcionista, index) => (
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.TextCell flexBasis={134} flexShrink={0} flexGrow={0} className="right">{index + 1}º Opcionista</Table.TextCell>
                                                    <Table.TextCell flexBasis={130} flexShrink={0} flexGrow={0} className="center">{opcionista.porcentagem_real}%</Table.TextCell>
                                                    <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className="right">{opcionista.desconto === null || opcionista.desconto === '0' || opcionista.desconto === '' ? '' : opcionista.desconto}</Table.TextCell>
                                                    <Table.TextCell flexBasis={115} flexShrink={0} flexGrow={0} className="right">{opcionista.valor_real === null ? '0,00' : opcionista.valor_real}</Table.TextCell>
                                                    <Table.TextCell flexBasis={210} flexShrink={0} flexGrow={0} className="center">{opcionista.tipo_pessoa === 'PF' ? opcionista.name : opcionista.nome_empresarial}</Table.TextCell>
                                                    <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} className="center">{opcionista.tipo_pessoa === 'PF' ? opcionista.cpf : opcionista.cnpj}</Table.TextCell>
                                                    <Table.TextCell flexBasis={110} flexShrink={0} flexGrow={0} className="center">{opcionista.creci}</Table.TextCell>
                                                    <Table.TextCell flexBasis={280} flexShrink={0} flexGrow={0} className="center">
                                                        {
                                                            opcionista.tipo_pagamento === 'pix'
                                                                ? 'PIX: ' + opcionista.pix
                                                                : 'Banco: ' + opcionista.banco_nome + ' Agência: ' + opcionista.agencia + ' C/C: ' + opcionista.numero_conta
                                                        }
                                                    </Table.TextCell>
                                                </Table.Row>
                                            </Table.Body>
                                        ))
                                    }

                                    {/*Gerente*/}
                                    {

                                        processoGerenteGG.comissao_gerentes.map((gerente, index) => (
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.TextCell flexBasis={134} flexShrink={0} flexGrow={0} className="right">{index + 1}º Gerente</Table.TextCell>
                                                    <Table.TextCell flexBasis={130} flexShrink={0} flexGrow={0} className="center">{gerente.porcentagem_real}%</Table.TextCell>
                                                    <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className="right">{gerente.desconto === null || gerente.desconto === '0' || gerente.desconto === '' ? '' : gerente.desconto}</Table.TextCell>
                                                    <Table.TextCell flexBasis={115} flexShrink={0} flexGrow={0} className="right">{gerente.valor_real === null ? '0,00' : gerente.valor_real}</Table.TextCell>
                                                    <Table.TextCell flexBasis={210} flexShrink={0} flexGrow={0} className="center">{gerente.tipo_pessoa === 'PF' ? gerente.name : gerente.nome_empresarial}</Table.TextCell>
                                                    <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} className="center">{gerente.tipo_pessoa === 'PF' ? gerente.cpf : gerente.cnpj}</Table.TextCell>
                                                    <Table.TextCell flexBasis={110} flexShrink={0} flexGrow={0} className="center">{gerente.creci}</Table.TextCell>
                                                    <Table.TextCell flexBasis={280} flexShrink={0} flexGrow={0} className="center">
                                                        {
                                                            gerente.tipo_pagamento === 'pix'
                                                                ? 'PIX: ' + gerente.pix
                                                                : 'Banco: ' + gerente.banco_nome + ' Agência: ' + gerente.agencia + ' C/C: ' + gerente.numero_conta
                                                        }
                                                    </Table.TextCell>
                                                </Table.Row>
                                            </Table.Body>
                                        ))
                                    }

                                    {/*Gerente Geral*/}
                                    {
                                        processoGerenteGG.comissao_gerente_gerais.map((gg, index) => (
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.TextCell flexBasis={134} flexShrink={0} flexGrow={0} className="right">Gerente Geral</Table.TextCell>
                                                    <Table.TextCell flexBasis={130} flexShrink={0} flexGrow={0} className="center">{gg.porcentagem_real}%</Table.TextCell>
                                                    <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className="right">{gg.desconto === null || gg.desconto === '0' || gg.desconto === '' ? '' : gg.desconto}</Table.TextCell>
                                                    <Table.TextCell flexBasis={115} flexShrink={0} flexGrow={0} className="right">{gg.valor_real === null ? '0,00' : gg.valor_real}</Table.TextCell>
                                                    <Table.TextCell flexBasis={210} flexShrink={0} flexGrow={0} className="center">{gg.tipo_pessoa === 'PF' ? gg.name : gg.nome_empresarial}</Table.TextCell>
                                                    <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} className="center">{gg.tipo_pessoa === 'PF' ? gg.cpf : gg.cnpj}</Table.TextCell>
                                                    <Table.TextCell flexBasis={110} flexShrink={0} flexGrow={0} className="center">{gg.creci}</Table.TextCell>
                                                    <Table.TextCell flexBasis={280} flexShrink={0} flexGrow={0} className="center">
                                                        {
                                                            //gg.dados_bancarios

                                                            gg.tipo_pagamento === 'pix'
                                                                ? 'PIX: ' + gg.pix
                                                                : 'Banco: ' + gg.banco_nome + ' Agência: ' + gg.agencia + ' C/C: ' + gg.numero_conta
                                                        }
                                                    </Table.TextCell>
                                                </Table.Row>
                                            </Table.Body>
                                        ))
                                    }

                                    {/*Diretor*/}
                                    {
                                        processo.imovel.comissao_empresas.some((empresa) => empresa.nome_empresarial.includes("DNA Imóveis")) && diretorData
                                            ?
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.TextCell flexBasis={134} flexShrink={0} flexGrow={0} className="right">Diretor</Table.TextCell>
                                                    <Table.TextCell flexBasis={130} flexShrink={0} flexGrow={0} className="center">{processoGerenteGG?.dados_diretor_comercial?.porcetagem_comissao}%</Table.TextCell>
                                                    <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className="right"></Table.TextCell>
                                                    <Table.TextCell flexBasis={115} flexShrink={0} flexGrow={0} className="right">R$ {diretorData?.valor_real}</Table.TextCell>
                                                    <Table.TextCell flexBasis={210} flexShrink={0} flexGrow={0} className="center">{processoGerenteGG?.dados_diretor_comercial?.name}</Table.TextCell>
                                                    <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} className="center">{processoGerenteGG?.dados_diretor_comercial?.cpf_cnpj}</Table.TextCell>
                                                    <Table.TextCell flexBasis={110} flexShrink={0} flexGrow={0} className="center">{processoGerenteGG?.dados_diretor_comercial?.creci}</Table.TextCell>
                                                    {/*<Table.TextCell flexBasis={280} flexShrink={0} flexGrow={0} className="center">Banco: {processoGerenteGG?.dados_diretor_comercial?.nome_banco} AG: {processoGerenteGG?.dados_diretor_comercial?.agencia} C/C: {processoGerenteGG?.dados_diretor_comercial?.numero_conta}</Table.TextCell>*/}
                                                    <Table.TextCell flexBasis={280} flexShrink={0} flexGrow={0} className="center">PIX: {processoGerenteGG?.dados_diretor_comercial?.pix}</Table.TextCell>
                                                </Table.Row>
                                            </Table.Body>
                                            :
                                            ''
                                    }

                                    {
                                        /*tipoLoja === 1
                                            ?*/
                                            <>
                                                {/*Franquias*/}
                                                {/*Repasse Franquia*/}
                                                {(processoGerenteGG.dados_repasse && processoComissao?.verificar_repasse === 'true') &&
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.TextCell flexBasis={134} flexShrink={0} flexGrow={0} className="right">Repasse Franquia</Table.TextCell>
                                                            <Table.TextCell flexBasis={130} flexShrink={0} flexGrow={0} className="center">{processoGerenteGG.dados_repasse?.porcentagem}%</Table.TextCell>
                                                            <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className="right">{processoGerenteGG.dados_repasse?.desconto}</Table.TextCell>
                                                            <Table.TextCell flexBasis={115} flexShrink={0} flexGrow={0} className="right">R${processoGerenteGG.dados_repasse?.valor_real}</Table.TextCell>
                                                            <Table.TextCell flexBasis={210} flexShrink={0} flexGrow={0} className="center">{processoGerenteGG.dados_repasse?.nome_empresarial}</Table.TextCell>
                                                            <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} className="center">{processoGerenteGG.dados_repasse?.empresa_cnpj}</Table.TextCell>
                                                            <Table.TextCell flexBasis={110} flexShrink={0} flexGrow={0} className="center">{processoGerenteGG.dados_repasse?.creci_empresa}</Table.TextCell>
                                                            <Table.TextCell flexBasis={280} flexShrink={0} flexGrow={0} className="center">
                                                                {
                                                                    processoGerenteGG.dados_repasse?.tipo_pagamento === 'pix'
                                                                        ? 'PIX: ' + processoGerenteGG.dados_repasse?.pix
                                                                        : 'Banco: ' + processoGerenteGG.dados_repasse?.nome_banco + ' Agência: ' + processoGerenteGG.dados_repasse?.agencia + ' C/C: ' + processoGerenteGG.dados_repasse?.numero_conta
                                                                }
                                                            </Table.TextCell>
                                                        </Table.Row>
                                                    </Table.Body>}

                                                {/*Royalties*/}
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.TextCell flexBasis={134} flexShrink={0} flexGrow={0} className="right">Royalties</Table.TextCell>
                                                        <Table.TextCell flexBasis={130} flexShrink={0} flexGrow={0} className="center">{processoGerenteGG.dados_royalties?.porcentagem}%</Table.TextCell>
                                                        <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className="right">{processoGerenteGG.dados_royalties?.desconto}</Table.TextCell>
                                                        <Table.TextCell flexBasis={115} flexShrink={0} flexGrow={0} className="right">R${processoGerenteGG.dados_royalties?.valor_real}</Table.TextCell>
                                                        <Table.TextCell flexBasis={210} flexShrink={0} flexGrow={0} className="center">{processoGerenteGG.dados_royalties?.nome_empresarial}</Table.TextCell>
                                                        <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} className="center">{processoGerenteGG.dados_royalties?.empresa_cnpj}</Table.TextCell>
                                                        <Table.TextCell flexBasis={110} flexShrink={0} flexGrow={0} className="center">{processoGerenteGG.dados_royalties?.creci_empresa}</Table.TextCell>
                                                        <Table.TextCell flexBasis={280} flexShrink={0} flexGrow={0} className="center">
                                                            {
                                                                processoGerenteGG.dados_royalties?.tipo_pagamento === 'pix'
                                                                    ? 'PIX: ' + processoGerenteGG.dados_royalties?.pix
                                                                    : 'Banco: ' + processoGerenteGG.dados_royalties?.nome_banco + ' Agência: ' + processoGerenteGG.dados_royalties?.agencia + ' C/C: ' + processoGerenteGG.dados_royalties?.numero_conta
                                                            }
                                                        </Table.TextCell>
                                                    </Table.Row>
                                                </Table.Body>

                                                {/*Comunicação*/}
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.TextCell flexBasis={134} flexShrink={0} flexGrow={0} className="right">Comunicação</Table.TextCell>
                                                        <Table.TextCell flexBasis={130} flexShrink={0} flexGrow={0} className="center">{processoGerenteGG.dados_comunicacao?.porcentagem}%</Table.TextCell>
                                                        <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className="right">{processoGerenteGG.dados_comunicacao?.desconto}</Table.TextCell>
                                                        <Table.TextCell flexBasis={115} flexShrink={0} flexGrow={0} className="right">R${processoGerenteGG.dados_comunicacao?.valor_real}</Table.TextCell>
                                                        <Table.TextCell flexBasis={210} flexShrink={0} flexGrow={0} className="center">{processoGerenteGG.dados_comunicacao?.nome_empresarial}</Table.TextCell>
                                                        <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} className="center">{processoGerenteGG.dados_comunicacao?.empresa_cnpj}</Table.TextCell>
                                                        <Table.TextCell flexBasis={110} flexShrink={0} flexGrow={0} className="center">{processoGerenteGG.dados_comunicacao?.creci_empresa}</Table.TextCell>
                                                        <Table.TextCell flexBasis={280} flexShrink={0} flexGrow={0} className="center">
                                                            {
                                                                processoGerenteGG.dados_comunicacao?.tipo_pagamento === 'pix'
                                                                    ? 'PIX: ' + processoGerenteGG.dados_comunicacao?.pix
                                                                    : 'Banco: ' + processoGerenteGG.dados_comunicacao?.nome_banco + '  Agência: ' + processoGerenteGG.dados_comunicacao?.agencia + 'C/C: ' + processoGerenteGG.dados_comunicacao?.numero_conta
                                                            }
                                                        </Table.TextCell>
                                                    </Table.Row>
                                                </Table.Body>
                                                {/*Franquias*/}
                                            </>
                                            /*:
                                            ''*/
                                    }

                                    {/*Empresa*/}
                                    {
                                        processo.imovel.comissao_empresas.map(empresa => 
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.TextCell flexBasis={134} flexShrink={0} flexGrow={0} className="right">Empresa</Table.TextCell>
                                                    <Table.TextCell flexBasis={130} flexShrink={0} flexGrow={0} className="center">{empresa.porcentagem_real + '%'}</Table.TextCell>
                                                    <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className="right">{empresa.desconto}</Table.TextCell>
                                                    <Table.TextCell flexBasis={115} flexShrink={0} flexGrow={0} className="right">R${empresa.valor_real}</Table.TextCell>
                                                    <Table.TextCell flexBasis={210} flexShrink={0} flexGrow={0} className="center">{empresa.nome_empresarial}</Table.TextCell>
                                                    <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} className="center">{empresa.cnpj}</Table.TextCell>
                                                    <Table.TextCell flexBasis={110} flexShrink={0} flexGrow={0} className="center">{empresa.creci}</Table.TextCell>
                                                    <Table.TextCell flexBasis={280} flexShrink={0} flexGrow={0} className="center">
                                                        {
                                                            empresa.tipo_pagamento === 'banco'
                                                            ?
                                                            'Banco: ' + empresa.nome_banco + ' AG: ' + empresa.agencia + ' C/C: ' + empresa.numero_conta
                                                            :
                                                            'PIX: ' + empresa.pix
                                                        }
                                                        
                                                    </Table.TextCell>
                                                </Table.Row>
                                            </Table.Body>
                                        )
                                    }

                                    {/* <Table.Body>
                                        <Table.Row>
                                            <Table.TextCell flexBasis={134} flexShrink={0} flexGrow={0} className="right">Empresa</Table.TextCell>
                                            <Table.TextCell flexBasis={130} flexShrink={0} flexGrow={0} className="center"></Table.TextCell>
                                            <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className="right"></Table.TextCell>
                                            <Table.TextCell flexBasis={115} flexShrink={0} flexGrow={0} className="right">R${processo.imovel.comissao.valor_receber_empresa}</Table.TextCell>
                                            <Table.TextCell flexBasis={210} flexShrink={0} flexGrow={0} className="center">{processo.imovel.empresa_receber.nome_empresarial}</Table.TextCell>
                                            <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} className="center">{processo.imovel.empresa_receber.cnpj}</Table.TextCell>
                                            <Table.TextCell flexBasis={110} flexShrink={0} flexGrow={0} className="center">{processo.imovel.empresa_receber.creci}</Table.TextCell>
                                            <Table.TextCell flexBasis={280} flexShrink={0} flexGrow={0} className="center">
                                                {
                                                    processo.imovel.empresa_receber.dados_bancarios.data[0].pix !== '' 
                                                    ?
                                                    'PIX: ' + processo.imovel.empresa_receber.dados_bancarios.data[0].pix
                                                    :
                                                    'Banco: ' + processo.imovel.empresa_receber.dados_bancarios.data[0].nome_banco + 'AG: ' + processo.imovel.empresa_receber.dados_bancarios.data[0].agencia + 'C/C: ' + processo.imovel.empresa_receber.dados_bancarios.data[0].numero_conta
                                                }
                                            </Table.TextCell>
                                        </Table.Row>
                                    </Table.Body> */}
                                </Table.Body>
                            </div>
                            <div className="footer">
                                <Pane className="atualizacao-comissao">
                                    <span>Atualizado em:</span> {selectProcess.imovel.comissao?.data_atualizacao}
                                </Pane>
                                <Button onClick={(e) => PlanilhaComissaoPDF(selectProcess.imovel)}><DownloadIcon size={12} color="#8F95B2" marginRight={10} /> Fazer download em .pdf</Button>
                                <Button appearance="primary" intent="success" onClick={(e) => handleEnviarPlanilha(e)}><ArrowRightIcon size={12} color="#fff" marginRight={10} /> Enviar Planilha de Comissão</Button>
                            </div>
                        </div>
                    </Pane>
                }

            </Dialog>
        </Pane>
    )
}
