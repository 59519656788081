import React, { useState, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import TablePanel from './components/TablePainel';
import { useHistory } from 'react-router-dom';
import NegociacaoContext from '../../context/NegociacaoContext';
import getResponsaveis from '../../apis/getResponsaveis';
import ListarStatus from '../../apis/getStatusListProcessos';
import ListarGerentes from '../../apis/getGerenteList';
import ListarClientes from '../../apis/getClienteList';
import ListarLaudemios from '../../apis/getLaudemiosList';
import ListarDocumentos from '../../apis/getDocumentsList';
import ListarServicos from '../../apis/getTypeServices';
import getServicosDetalhados from '../../apis/getDetailedService';
import ListaEnderecos from '../../apis/getEnderecos';
// import getProcessTable from '../../apis/getProcessTable'; PUXA TODOS OS DADOS
// import pagination from '../../components/pagination'; 
import getPagedProcessos from '../../apis/getPagedProcessTable';
import getOpcionistas from '../../apis/getOpcionistas';
import getChecklistPadrao from '../../apis/getChecklistPadrao';
import Axios from 'axios';
import Serve from '../../app/serve';
import getProcessByResponsaveis from '../../apis/getProcessByResponsaveis';
import getListEscrituras from '../../apis/getListEscrituras';
import getProcessById from '../../apis/getProcessById';

export default function Negociacoes() {
  const [perfil_login] = useState(localStorage.getItem('perfil_login'));
  const [userId] = useState(localStorage.getItem('usuario_id'));
  const history = useHistory();

  const app = Serve();

  if (perfil_login === "Backoffice" || perfil_login === "Coordenadora de Pós-Negociação") {

  } else {
    history.push('/');
  }

  //Context
  const [loading, setLoading] = useState(false);
  const [processoId, setProcessoId] = useState();  

  // TABELA COORDENAÇÃO DE PÓS VENDA  
  const [tableDataCoord, setTableDataCoord] = useState([]);

  //MODALS
  const [loadingModal, setLoadingModal] = useState(true);
  const [isShownStatus, setIsShownStatus] = useState(false);
  const [isShownRevisao, setIsShownRevisao] = useState(false);
  const [isShownRevisaoFeedBack, setIsShownRevisaoFeedBack] = useState(false);
  const [isShownNucleo, setIsShownNucleo] = useState(false);
  const [isShownFeedBackNucleo, setIsShownFeedBackNucleo] = useState(false);
  const [idSolicitacaoNucleo, setIdSolicitacaoNucleo] = useState();
  const [isShowResumo, setIsShowResumo] = useState(false);
  const [showFieldsEscritura, setShowFieldsEscritura] = useState(false);
  const [showFieldsTaxas, setShowFieldsTaxas] = useState(false);
  const [dadosProcesso, setDadosProcesso] = useState([]);
  const [statusCompartilhado, setStatusCompartilhado] = useState('');
  const [isShowConfirmDel, setIsShowConfirmDel] = useState(false);
  const [isShowChangeResponsavel, setIsShowChangeResponsavel] = useState(false);

  //ARQUIVO PARA MODAL DE CONFIRM DEL
  const [itemToRemove, setItemToRemove] = useState("");

  //SIDESHEET
  const [isShownSide, setIsShownSide] = useState(false);
  const [tabsVisualization, setTabsVisualization] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [processSelect, setProcessSelect] = useState({});

  //LISTAS FILTROS
  const [listaResponsaveis, setListaResponsaveis] = useState([]);
  const [listaStatusProcesso, setListaStatusProcesso] = useState([]);
  const [listaGerente, setListaGerente] = useState([]);
  const [listaClientes, setListaCliente] = useState([]);
  const [listaEndereco, setListaEndereco] = useState([]);

  //LISTAS MODAIS
  const [listaTipoServico, setListaTipoServico] = useState([]);
  const [listaDocumentos, setListaDocumentos] = useState([]);
  const [listaLaudemios, setListaLaudemios] = useState([]);
  const [listOpcionistas, setListOpcionistas] = useState([]);
  const [listaDetalheServico, setListaDetalheServico] = useState([]);  
  const [listEscrituras, setListEscrituras] = useState([]);

  //Filtros
  const [filtro] = useState({
    'responsavel': Number(localStorage.getItem('filtro_responsavel')) || '',
    'status': Number(localStorage.getItem('filtro_status')) || '',
    'gerente': JSON.parse(localStorage.getItem('filtro_gerente')) || '',
    'cliente': JSON.parse(localStorage.getItem('filtro_cliente')) || '',
    'endereco': localStorage.getItem('filtro_endereco') || '',
    'mes_coordenacao': localStorage.getItem('mes_coordenacao') || '',
    'forma_pagamento': localStorage.getItem('filtro_forma_pagamento') || '',
    'prazo': localStorage.getItem('filtro_prazo') || '',
  });
  const [quantFiltro, setQuantFiltro] = useState([]);

  //CHECKLIST DO RESUMO
  const [loadingChecklist, setLoadingChecklist] = useState(false);
  const [list, setList] = useState([]);
  const [listPadrao, setListPadrao] = useState([]);
  //VARIAVEL DOS CAMPOS ADD ITEM EM CHECKLIST E OBSERVAÇÃO
  const [text, setText] = useState({
    itemList: "",
    textArea: "",
  });

  //ORDENAÇÂO
  const [ordenacao] = useState({
    'valor': localStorage.getItem('ordenacao') || ''
  });

  //PAGINAÇÃO 
  const [limiteProcess] = useState(20);
  const [pageNumber, setPageNumber] = useState(Number(localStorage.getItem('page_number')) || 1);
  const [totalProcessosFiltrados, setTotalProcessosFiltrados] = useState(0);
  const [totalPaginas, setTotalPaginas] = useState(2);
  const [totalProcessos, setTotalProcessos] = useState(0);
  const [tablePaged, setTablePaged] = useState([]);

  //DOCUMENTOS
  const [formValues, setFormValues] = useState({
    'imovel': [{ 'documento': '', 'arquivo': '' }],
    'vendedores': [], // DESATIVADO
    'compradores': [] // DESATIVADO
  });  
  
  const [multiDocs, setMultiDocs] = useState({
    'imovel': [{item: [], file: '', id: ''}],
    'vendedores': [
      [{item: [], file: '', id: ''}]
    ],
    'compradores': [      
      [{item: [], file: '', id: '' }]
    ]
  });

  //FeedBack
  const [isShownFeedBack, setIsShownFeedBack] = useState(false);

  //Lojas
  const [listaLojas, setListaLojas] = useState([]);
  
  // UPLOAD PROGRESS BAR 
  const [progressBar, setProgressBar] = useState({ percent: 0, status: '' });

  const conxtextStatus = {
    userId,
    loading,
    setLoading,
    processoId, setProcessoId,
    progressBar, setProgressBar,

    // CORDENAÇÃO DE PÓS VENDA
    tableDataCoord,

    //MODAIS
    loadingModal, setLoadingModal,
    isShownStatus, setIsShownStatus,
    isShownRevisao, setIsShownRevisao,
    isShownFeedBack, setIsShownFeedBack,
    isShownRevisaoFeedBack, setIsShownRevisaoFeedBack,
    isShownNucleo, setIsShownNucleo,
    isShownFeedBackNucleo, setIsShownFeedBackNucleo,
    idSolicitacaoNucleo, setIdSolicitacaoNucleo,
    isShowResumo, setIsShowResumo,
    showFieldsEscritura, setShowFieldsEscritura,
    showFieldsTaxas, setShowFieldsTaxas,
    dadosProcesso, setDadosProcesso,
    statusCompartilhado, setStatusCompartilhado,
    isShowConfirmDel, setIsShowConfirmDel,
    isShowChangeResponsavel, setIsShowChangeResponsavel,

    // ARQUIVO PARA REMOVER
    itemToRemove, setItemToRemove,

    //RESUMO PROCESSO
    text, setText,

    //SIDESHEET
    isShownSide, setIsShownSide,
    processSelect, setProcessSelect,
    tabsVisualization, setTabsVisualization,
    selectedIndex, setSelectedIndex,
    formValues, setFormValues,
    multiDocs, setMultiDocs,

    //Table data
    tablePaged, setTablePaged,

    //Listas
    listaTipoServico,
    listaDocumentos,
    listaLaudemios,
    listOpcionistas,
    listaDetalheServico,
    list, setList,
    listPadrao, setListPadrao,
    listEscrituras,

    loadingChecklist, setLoadingChecklist,

    //Filtros
    filtro, quantFiltro, setQuantFiltro,
    listaResponsaveis, listaGerente, listaClientes,
    listaStatusProcesso, listaEndereco,

    //Ordenação
    ordenacao,

    //Paginação
    pageNumber, setPageNumber,
    totalPaginas, setTotalPaginas,
    totalProcessos,
    totalProcessosFiltrados,
    limiteProcess,

    listaLojas, setListaLojas
  };

  useEffect(() => {
    const lista_lojas = async () => {
      await Axios.get(app + 'listar_lojas', {
          headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
      }).then(res => {
          if (res !== undefined) {
              if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                  localStorage.clear();
                  history.push('/');
              } else {
                  const list_lojas = res.data.data;
                  setListaLojas(list_lojas);
              }
          }
          setLoading(true);
      })
    }
    lista_lojas()
    
    getListFiltros();
    getPaged(pageNumber);
    getOtherLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // API DE COORDENADOR DE PÓS VENDA
  async function getListTableCoordenador(month) {
    const data = await getProcessByResponsaveis(month);
    setTableDataCoord(data);
  };

  async function getListFiltros() {
    const responsaveis = await getResponsaveis();
    setListaResponsaveis(responsaveis);

    const status = await ListarStatus();
    setListaStatusProcesso(status);

    const gerentes = await ListarGerentes();
    setListaGerente(gerentes);

    const clientes = await ListarClientes();
    setListaCliente(clientes);

    const logradouros = await ListaEnderecos();
    setListaEndereco(logradouros);
  };

  async function getOtherLists() {
    const documentos = await ListarDocumentos();
    setListaDocumentos(documentos);

    const laudemios = await ListarLaudemios();
    setListaLaudemios(laudemios);

    const opcionistas = await getOpcionistas();
    setListOpcionistas(opcionistas);

    const servicos = await ListarServicos();
    setListaTipoServico(servicos);

    const servicosDetalhados = await getServicosDetalhados();
    setListaDetalheServico(servicosDetalhados);

    const listPadrao = await getChecklistPadrao();
    setListPadrao(listPadrao);

    const tiposEscrituras = await getListEscrituras();
    setListEscrituras(tiposEscrituras);
  };

  async function getPaged(page) {
    setLoading(true);
    getListTableCoordenador(0);
    const data = await getPagedProcessos(page, filtro, ordenacao);
    setTablePaged(data.processos.data);
    setTotalPaginas(Math.ceil(data.total_pagina));
    setTotalProcessos(data.total_processos);
    setTotalProcessosFiltrados(data.total_processos_filtrados);
    setLoading(false);
    if(processSelect?.id) {
      const dataProcess = await getProcessById(processSelect?.id);
      setProcessSelect(dataProcess);
    }
  };

  return (
    <NegociacaoContext.Provider value={conxtextStatus}>
      <Navbar Context={NegociacaoContext} />
      <div className="painel mt59">
        <div className="flex-container">
          <h1 className="title">
            Histórico de pós-venda
          </h1>
        </div>
        <TablePanel getPaged={getPaged} getListTableCoordenador={getListTableCoordenador} />
      </div>
    </NegociacaoContext.Provider>
  )
}
