import React, { useState, useContext, useEffect} from 'react';
import { Pane, Heading, Tablist, Tab, Card, SideSheet, LockIcon} from 'evergreen-ui';

import DirecaoContext from '../../../context/DirecaoContext';

//import FormImovel from './FormsSideSheet/Venda';
import Rascunho from './FormsSideSheet/Rascunho';
import ReciboDeSinal from './FormsSideSheet/ReciboDeSinal';
import BoletimDeVenda from './FormsSideSheet/BoletimDeVenda';
import Historico from './FormsSideSheet/Historico';

export default function SideSheetView() {

    const {
        //sideId, sideProcesso,
        isShowSide, setIsShowSide,
        dadosVenda,
        
        selectedIndexEnviados, setSelectedIndexEnviados,
        showTabsDetalhes, setShowTabsDetalhes,
        setShowTabsRascunho,
        setShowTabsBoletim,
        setSelectedIndexRascunho,
        setSelectedIndexBoletimDeVenda,
    } = useContext(DirecaoContext);

    const [tabs] = useState(['Rascunho', 'Recibo de Sinal', 'Boletim de Venda', 'Histórico']);

    const verificarTabs = async () => {    
        showTabsDetalhes[1] = false;
        showTabsDetalhes[2] = false;
        showTabsDetalhes[3] = false;

        if(dadosVenda.status_processo >= 1 && dadosVenda.status_processo <= 8){
            showTabsDetalhes[1] = true;
            showTabsDetalhes[2] = true;
            showTabsDetalhes[3] = true;            
        }
        //if(dadosVenda.status_processo >= 16){
        if(dadosVenda.status_processo >= 16 && dadosVenda.status_processo !== 24){
            showTabsDetalhes[1] = true;
            showTabsDetalhes[2] = false;
        }

        if(dadosVenda.status_processo >= 17 && dadosVenda.status_processo !== 24){
            showTabsDetalhes[1] = true;
            showTabsDetalhes[2] = true;
        }

        if(dadosVenda.status_processo === 22){
            showTabsDetalhes[1] = true;
            showTabsDetalhes[2] = true;
            showTabsDetalhes[3] = false;
        }           
    };

    verificarTabs();
    
    const handleClose = (e) => {
        setShowTabsDetalhes([true, false, false, false]);
        setShowTabsRascunho([true, false, false]);
        setShowTabsBoletim([true, false, false, false]);

        setSelectedIndexEnviados(0);
        setSelectedIndexRascunho(0);
        setSelectedIndexBoletimDeVenda(0);

        setIsShowSide(false);
    }

    return (
        <SideSheet
            isShown={isShowSide}
            width={696}
            onCloseComplete={(e) => handleClose(e)}
            onBeforeClose={(e) => handleClose(e)}
            shouldCloseOnOverlayClick={(e) => handleClose(e)}
            shouldCloseOnEscapePress={(e) => handleClose(e)}
            containerProps={{
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
                className: 'side-sheet-box side-sheet-detalhes'
            }}
            preventBodyScrolling
        >
            <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
                <Pane padding={20} borderBottom="muted">
                    <Heading size={600} style={{margin: '0 10px'}}>
                        {dadosVenda ? dadosVenda.logradouro + ', ' + dadosVenda.numero + (dadosVenda.unidade ? (" / " + dadosVenda.unidade) : '' ) : ''}
                    </Heading>
                </Pane>
                <Pane display="flex" padding={8}>
                    <Tablist className="teste-tab">
                        {tabs.map((tab, index) => (
                            <Tab
                                key={tab}
                                isSelected={selectedIndexEnviados === index}
                                onSelect={() => setSelectedIndexEnviados(index)}
                                aria-controls={`panel-${index}`}
                                aria-selected={selectedIndexEnviados === index ? true : false}
                                //className='tab-enable'
                                className={
                                    showTabsDetalhes[index] === true ? 'tab-enable' : 'tab-disable'
                                }
                                style={{margin: '0 20px'}}
                                appearance="primary"
                                intent="danger"
                            >
                                {tab === 'Histórico' && showTabsDetalhes[3] === false  ? '' : tab}
                                {
                                    showTabsDetalhes[index] === true 
                                    ? '' 
                                    : tab === 'Histórico' && showTabsDetalhes[3] === false ? '' : <LockIcon size={12} marginLeft={10}/>
                                }
                            </Tab>
                        ))}
                    </Tablist>
                </Pane>
            </Pane>
            <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
                {tabs.map((tab, index) => (
                    <Card
                        key={tab}
                        id={`panel-${tab}`}
                        role="tabpanel"
                        aria-labelledby={tab}
                        aria-hidden={index !== selectedIndexEnviados}
                        display={index === selectedIndexEnviados ? 'block' : 'none'}
                        appearance="primary"
                        style={{overflowY: 'hidden', background: '#fff'}}
                    >
                        {/*Id do processo - sideId*/}
                        {tab === 'Rascunho' && <Rascunho />}
                        {tab === 'Recibo de Sinal' && <ReciboDeSinal />}
                        {tab === 'Boletim de Venda' && <BoletimDeVenda/>}
                        {tab === 'Histórico' && <Historico />}
                    </Card>
                ))}
            </Pane>
        </SideSheet>
    )
}