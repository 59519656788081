import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Pane, ErrorIcon, Spinner, Dialog, TextInputField, FilePicker, Badge, CloudUploadIcon, Button, PlusIcon, ChevronLeftIcon, ChevronRightIcon, DeleteIcon, SelectField, Checkbox, Tablist, Tab, toaster } from 'evergreen-ui';

//Context
import VendasContext from '../../../../../../context/VendasContext';

//Components
import Axios from 'axios';
import Serve from '../../../../../../app/serve';
import { useHistory } from 'react-router-dom';
import Steps from './Steps';
import phoneMask from '../../../../../../components/phoneMask';
import RevisaoPosVenda from './components/RevisaoPosVenda';
import MultiUploadDoc from '../../../../../../components/MultiUploadDoc';

export default function Comprador() {

    const {
        loading,
        setCountMenu,
        dadosVenda, setDadosVenda,
        setItemAdicionado, setLoading,
        compradorProcesso, setCompradorProcesso,
        isShowFormBoletimDeVenda, setIsShowFormBoletimDeVenda,
        setPosicaoBoletimDeVenda,
        setIsShowCornerDialog,
        setModelCornerDialog,
        multiDocs,
    } = useContext(VendasContext);
    console.log(multiDocs);

    const app = Serve();
    const history = useHistory();
    const [token] = useState(localStorage.getItem('token'));
    const [gerente_id] = useState(localStorage.getItem('usuario_id'));
    // const idProcesso = idProcessos;

    const [alteracaoRevisao, setAlteracaoRevisao] = useState(false);

    //CSS inputs
    const inputGreen = {
        borderColor: '#429777',
        background: '#F5FBF8'
    };

    const inputDefault = {
        borderColor: '#ADC2FF',
        background: '#fff'
    };

    const inputError = {
        borderColor: '#FF0000',
        background: '#fff'
    }

    //Tabs
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [tabs, setTabs] = useState([])
    const [posicaoTabs, setPosicaoTabs] = useState([]);
    const [checkedPessoaJuridica, setCheckedPessoaJuridica] = useState(false);
    const [idsCompradores, setIdsCompradores] = useState([]);
    const [procuradores, setProcuradores] = useState([]);
    const [idsRepresentantes, setIdsRepresentantes] = useState([]);

    //Cantinho do hook Form
    const { register, unregister, handleSubmit, clearErrors, setValue, watch, reset, formState: { errors } } = useForm({
        defaultValues: {
            compradores: compradorProcesso.data.map(comprador => {
                return {
                    id: comprador.id,
                    procurador: {
                        check: comprador.procurador?.id ? true : false,
                        telefone: comprador.procurador?.telefone,
                        nome: comprador.procurador?.nome,
                    },
                    tipo_pessoa: comprador.tipo_pessoa,
                }
            }),
        }
    });
    const errorFileInput = "Selecione o arquivo para avançar";
    const errorSelectInput = "Selecione o tipo de arquivo para avançar";
    const errorTextInput = "Campo obrigatório.";

    const retornaDocumentos = async () => {

        if (compradorProcesso['data'].length > 0) {
            const IdsUsuarios = compradorProcesso.data.map(usuario => ({ "usuario_id": usuario.id }));
            setIdsCompradores(IdsUsuarios);
            compradorProcesso.data.forEach((comprador, index) => {
                // monto array de procuradores
                if (comprador.procurador === null) {
                    let procurador = { idUsuario: comprador.id, idProcurador: '', checkProcurador: false, procurador: '', telefoneProcurador: '' };
                    procuradores.push(procurador);
                    setProcuradores(procuradores);

                } else {
                    let procurador = { idProcurador: comprador.procurador.id, checkProcurador: true, procurador: comprador.procurador.nome, telefoneProcurador: comprador.procurador.telefone };
                    procuradores.push(procurador);
                }

                setIdsRepresentantes(idsRepresentantes);

                // Validação Revisão
                if (dadosVenda.status_processo === 22) {
                    if (dadosVenda.revisao.compradores_verific_save === 0) {
                        setAlteracaoRevisao(false);
                    }
                    else {
                        setAlteracaoRevisao(true);
                    }
                }
            });
        }
    }
    useEffect(() => {
        retornaDocumentos();

        const returnComprador = async () => {
            if (compradorProcesso['data'].length > 0) {
                setPosicaoTabs([...posicaoTabs, 1]);
                verificaTipoComprador(0);
            }
            const array = compradorProcesso['data'].length;
            var arrayFinal = []
            for (let i = 0; i < array; i++) {
                arrayFinal.push(i);
                setTabs(arrayFinal);
            }
        }
        returnComprador();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dadosVenda]);

    // Verifica se o Comprador é Pessoa Física ou Júridica
    const verificaTipoComprador = async (index) => {
        if (compradorProcesso.data[index] !== undefined) {
            if (compradorProcesso.data[index].tipo_pessoa === 0) {
                // PF
                setCheckedPessoaJuridica(false);
            }
            else {
                // PJ
                setCheckedPessoaJuridica(true);
            }
        }
        else {
            // PF
            setCheckedPessoaJuridica(false);
        }
    };

    const handleOpenTab = async (index) => {
        verificaTipoComprador(index);
    };

    const closeBoletimDeVenda = () => {
        reset();
        setIsShowFormBoletimDeVenda(false);
        setPosicaoBoletimDeVenda(3);
        setAlteracaoRevisao(false);
    };

    const handleCheckedProcurador = (e, index) => {
        setValue('compradores.' + index + '.procurador.check', e.target.checked);
        procuradores[index]['checkProcurador'] = e.target.checked;
        if (!e.target.checked) {
            clearErrors("compradores")
        }
    };

    const handleBtnAnterior = (e) => {
        e.preventDefault();
        //setPosicaoBoletimDeVenda(2);
        if (dadosVenda.status_processo === 22) {
            if (dadosVenda.revisao.vendedores === 1) {
                setPosicaoBoletimDeVenda(2);
            }
            else if (dadosVenda.revisao.valores_documentos_imovel === 1) {
                setPosicaoBoletimDeVenda(1);
            }
        }
        else {
            setPosicaoBoletimDeVenda(2);
        }
    };

    //Verificando se os campos foram alterados para Revisão
    const handleVerificarRevisao = (e) => {
        if (e.target !== undefined) {
            setAlteracaoRevisao(true);
        }
        else {
            toaster.danger('Realize os ajustes solicitados pelo pós-venda para avançar.');
        }
    };

    const handleBtnAvancar = (dados) => {
        setLoading(false);
        let checkProcurador = "";
        let procurador = "";
        let telefoneProcurador = "";

        const quantCompradores = compradorProcesso.data.length;
        let arrayMensagemServidor = [];

        compradorProcesso.data.forEach((element, index_Pessoa) => {
            let arrayData = new FormData();

            const saveMultiDocs = (e, index_documento, id_usuario) => {
                if (e.file && e.item[0]) {
                    if (typeof (e.file) === 'string' || e.file instanceof String) {
                        let arquivoVazio = new File(["foo"], "nao_teve_alteracao_foo.txt", {
                            type: "text/plain",
                        });
                        arrayData.append(`arquivos[${index_documento}]`, arquivoVazio);
                    } else {
                        arrayData.append(`arquivos[${index_documento}]`, e.file);
                    }
                    if (e) {
                        e.item.forEach((tipo, index_tipo) => {
                            arrayData.append(`tipo_documento_ids[${index_documento}][${index_tipo}]`, tipo.value);
                            arrayData.append(`multiplo_documento_id[${index_documento}][${index_tipo}]`, tipo.id ? tipo.id : "");
                        })
                    }
                    arrayData.append(`documentos_ids[${index_documento}]`, e.id ? e.id : "");
                    arrayData.append(`idDonoDocumento[${index_documento}]`, id_usuario);
                    arrayData.append(`papel[${index_documento}]`, 'Comprador');
                }
            };            

            // MULTIPLOS DOCUMENTOS EM 1 ARQUIVO PESSOA FISICA
            multiDocs.compradores[index_Pessoa].fisica?.forEach((docs, index_documento) => {
                saveMultiDocs(docs, index_documento, element.id);
            });

            //TIPO PESSOA JURIDICA
            if (element.tipo_pessoa === 1) {
                // MULTIPLOS DOCUMENTOS EM 1 ARQUIVO PESSOA JURIDICA
                multiDocs.compradores[index_Pessoa].juridica?.forEach((docs, index_documento) => {
                    saveMultiDocs(docs, index_documento, element.id);
                });

                // MULTIPLOS DOCUMENTOS EM 1 ARQUIVO REPRESENTANTE
                multiDocs.compradores[index_Pessoa].representante_socios?.forEach((representante, index_rp) => {
                    representante.forEach((rep, index_documento_rep) => {
                        if (rep.file && rep.item[0]) {
                            if (typeof (rep.file) === 'string' || rep.file instanceof String) {
                                let arquivoVazioRepresentante = new File(["foo"], "nao_teve_alteracao_foo.txt", {
                                    type: "text/plain",
                                });
                                arrayData.append(`rp_arquivos[${index_rp}][${index_documento_rep}]`, arquivoVazioRepresentante);
                            } else {
                                arrayData.append(`rp_arquivos[${index_rp}][${index_documento_rep}]`, rep.file);
                            }
                            if (rep) {
                                rep.item.forEach((tipo, index_tipo) => {
                                    arrayData.append(`rp_tipo_documento_ids[${index_rp}][${index_documento_rep}][${index_tipo}]`, tipo.value);
                                    arrayData.append(`rp_multiplo_documento_id[${index_rp}][${index_documento_rep}][${index_tipo}]`, tipo.id ? tipo.id : "");
                                })
                            }
                            arrayData.append(`rp_documentos_ids[${index_rp}][${index_documento_rep}]`, rep.id ? rep.id : "");
                            arrayData.append(`rp_idDonoDocumento[${index_rp}][${index_documento_rep}]`, element.representante_socios.data[index_rp].id);
                            arrayData.append(`rp_papel[${index_rp}][${index_documento_rep}]`, 'Representante');
                        }
                    })
                });

            };

            arrayData.append(`processo_id`, dadosVenda.processo_id);
            arrayData.append('usuario_id_logado', gerente_id);
            arrayData.append(`identifica_documento`, element.tipo_pessoa === 0 ? 'pessoa_fisica' : 'pessoa_juridica');

            if (procuradores[index_Pessoa]['checkProcurador'] === true) {
                checkProcurador = 'S';
                procurador = dados.compradores[index_Pessoa].procurador.nome;
                telefoneProcurador = dados.compradores[index_Pessoa].procurador.telefone;
            }
            else {
                checkProcurador = "N";
                procurador = "";
                telefoneProcurador = "";
            }
            arrayData.append('participante_negociacao_id', element.id);
            arrayData.append('checkProcurador', checkProcurador);
            arrayData.append('procurador', procurador);
            arrayData.append('telefoneProcurador', telefoneProcurador);

            if (dadosVenda.status_processo === 22) {
                arrayData.append('revisao', 1);
                arrayData.append('usuario_tipo', 'comprador');
                arrayData.append('revisao_id', dadosVenda.revisao.id)
            }

            arrayData.append('status_processo', dadosVenda.status_processo)

            Axios.post(app + 'salvar_documentos_usuario', arrayData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(res => {
                    if (res.data.status && res.data.status === (401 || 498)) {
                        localStorage.clear();
                        history.push('/');
                    } else {
                        let mensagem = res.data.mensagem_status_processo;
                        arrayMensagemServidor.push(mensagem);
                        if (arrayMensagemServidor.length === quantCompradores) {
                            //setCountMenu(4);
                            recarregarVenda(dadosVenda.processo_id, 'avancar');
                            alteracaoRevisao(false);
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
        });
    }

    async function recarregarVenda(idProcessoNovo, avancar) {
        //Exibe a listagem atualizada de acordo 
        setLoading(false);
        await Axios.post(app + 'retorno_processos_painel_gerentes', {
            "usuario_id": gerente_id,
            'tipo_processos': 'adicionados',
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        })
            .then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    const processos = res.data.processos.data;
                    setItemAdicionado(processos);
                    processos.forEach(element => {
                        if (Number(idProcessoNovo) === element.processo_id) {
                            setDadosVenda(element);
                            setCompradorProcesso(element.compradores);

                            if (avancar) {
                                if (dadosVenda.status_processo === 22) {
                                    if (dadosVenda.revisao.comissao === 1) {
                                        setCountMenu(4);
                                        setPosicaoBoletimDeVenda(4);
                                    }
                                    else {
                                        setIsShowCornerDialog(true);
                                        //setModelCornerDialog(3);
                                        setModelCornerDialog(6);
                                        setIsShowFormBoletimDeVenda(false);
                                    }
                                }
                                else {
                                    setCountMenu(4);
                                    setPosicaoBoletimDeVenda(4);
                                }

                            }
                        }
                    });
                }
            })
        setLoading(true);
        retornaDocumentos();
    };

    const onError = (error) => {
        tabs.forEach((tab, index) => {
            console.log("Erro ", error.compradores[tab]);
            if (error.compradores[tab]) {
                setSelectedIndex(tab);
            }
        })
    };

    const handleRedirect = (e) => {
        dadosVenda.status_processo !== 22
            ? handleBtnAvancar(e)
            : alteracaoRevisao === true ? handleBtnAvancar(e) : handleVerificarRevisao(e)
    };

    return (
        <Dialog
            width={dadosVenda.status_processo === 22 ? 950 : 808}
            isShown={isShowFormBoletimDeVenda}
            title=''
            onCloseComplete={(e) => closeBoletimDeVenda(e)}
            hasFooter={false}
            shouldCloseOnOverlayClick={false}
            containerProps={{
                className: 'form-dialog-props'
            }}
        >
            <div className="form-dialog-fixed" style={{ height: tabs.length < 6 ? "90px" : tabs.length < 12 ? "120px" : "150px" }}>
                <Steps />

                <Tablist marginBottom={16} flexBasis={240} marginRight={24} className="tab-form">
                    {tabs.map((tab, index) => (
                        <Tab
                            key={tab}
                            id={"titulo_top_comprador_" + index}
                            onSelect={() => setSelectedIndex(index)}
                            isSelected={index === selectedIndex}
                            aria-controls={`panel-${tab}`}
                            data-tab={tab}
                            onClick={() => handleOpenTab(index)}
                            title={index < compradorProcesso.data.length ? compradorProcesso.data[index].name : 'Comprador ' + tab}
                        >

                            {compradorProcesso.data[index].tipo_pessoa === 0
                                ? index < compradorProcesso.data.length ? compradorProcesso.data[index].name.slice(0, 10) + '...' : 'Comprador ' + tab
                                : index < compradorProcesso.data.length ? compradorProcesso.data[index].nome_fantasia.slice(0, 10) + '...' : 'Comprador ' + tab
                            }
                        </Tab>
                    ))}
                </Tablist>
            </div>

            {loading === false
                ?
                <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                    <Spinner size={80} />
                </Pane>
                :
                <div className="form-dialog formGroup formAdd">
                    {tabs.map((tab, index_Comprador) => (
                        <Pane
                            key={tab}
                            id={`panel-${tab}`}
                            role="tabpanel"
                            aria-labelledby={tab}
                            aria-hidden={index_Comprador !== selectedIndex}
                            display={index_Comprador === selectedIndex ? 'block' : 'none'}
                        >
                            {
                                dadosVenda.status_processo === 22
                                    ? dadosVenda.revisao.compradores === 1
                                        ? <RevisaoPosVenda
                                            tab="vendedor_comprador"
                                            situacao={dadosVenda.revisao.compradores_verific_save}
                                            mensagem={dadosVenda.revisao.compradores_observacao !== null ? dadosVenda.revisao.compradores_observacao : ''}
                                        />
                                        : ''
                                    : ''
                            }

                            {/* FORMULARIO PARA PESSOA JURÍDICA */}
                            {compradorProcesso.data[index_Comprador].tipo_pessoa === 1 &&
                                <div className='Form Pessoa Física'>
                                    <Badge color="green" marginTop={dadosVenda.status_processo === 22 ? 15 : 70}> PESSOA JURÍDICA </Badge>

                                    <form id="formGroup" className="formGroup-dialog" style={{ top: 0 }} encType="multipart/form-data" onChange={e => dadosVenda.status_processo === 22 ? handleVerificarRevisao(e) : (e)}>
                                        <input id={"comprador_id-" + index_Comprador} type="hidden" value={idsCompradores[index_Comprador]['usuario_id']} />
                                        <input id={"comprador_tipo_pessoa-" + index_Comprador} type="hidden" value={checkedPessoaJuridica === false ? "0" : "1"} />
                                        <Pane>
                                            <div className="row-recibo">
                                                <div className="subtitle">
                                                    <h4><CloudUploadIcon className="icon-subtitle" /> Upload de documentos </h4>
                                                </div>

                                                <p className="descritption description-upload">Atenção: subir arquivos no formato .doc, .docx ou .pdf. <br /> Para aprender a converter uma foto para pdf., <a href="https://www.tecmundo.com.br/apps/72485-apps-converter-documentos-para-pdf-celulares-tablets.htm" target="_blank" rel="noreferrer" title="Converter foto para PDF">clique aqui</a>.</p>

                                                {/* Sessão de Envio de Arquivos da Empresa */}
                                                <div className="SESSÃO DOC EMPRESA">
                                                    <p className='subtitle'>Empresa</p>
                                                    <MultiUploadDoc pessoa={'compradores'} type="juridica" context={VendasContext} indexPessoa={index_Comprador} refreshProcess={recarregarVenda} idDonoDocumento={compradorProcesso.data[index_Comprador].id} />
                                                </div>

                                                {/* Sessão de Envio de Arquivos dos Sócios/Representantes */}
                                                <div className="SESSÃO_DOC_SÓCIO_REPRESENTANTE">
                                                    <p className='subtitle'>Sócios e/ou Representantes Legais</p>
                                                    <p className="descritption"></p>

                                                    {compradorProcesso.data[index_Comprador].representante_socios.data.map((representante, index_Representante) => (
                                                        <div key={index_Representante}>
                                                            <div className='rowForm'>
                                                                <p className="descritption" style={{ marginBottom: '16px' }}>{representante.name}</p>
                                                                {representante.pj_representante === 1 && <Badge color="neutral" marginLeft={14}> REPRESENTANTE LEGAL </Badge>}
                                                                {representante.pj_socio === 1 && <Badge color="neutral" marginLeft={6}> SÓCIO </Badge>}
                                                            </div>

                                                            {/* UPLOAD DE DOCUMENTOS */}
                                                            <MultiUploadDoc pessoa={'compradores'} type="fisica" context={VendasContext} indexPessoa={index_Comprador} indexRepresentante={index_Representante} refreshProcess={recarregarVenda} idDonoDocumento={compradorProcesso.data[index_Comprador]?.representante_socios?.data?.[index_Representante]?.id}/>
                                                        </div>
                                                    ))}


                                                </div>

                                            </div>
                                            {/* SESSÃO DE RODAPÉ */}
                                            <div className="mensagem-campos msg-obrigatorio">*Campos obrigatórios</div>

                                            <div className="footer-form">
                                                <div>
                                                    {/* ESPAÇO PARA ALINHAR A DIREITA OS BOTÕES */}
                                                </div>
                                                <div>
                                                    {
                                                        dadosVenda.status_processo === 22
                                                            ?
                                                            dadosVenda.revisao.vendedores === 1
                                                                ?
                                                                <Button appearance="default" marginLeft={15} onClick={(e) => handleBtnAnterior(e)}>
                                                                    <ChevronLeftIcon className="icon-label" style={{ margin: '0 5px 0 0' }} />
                                                                    Anterior
                                                                </Button>
                                                                :
                                                                dadosVenda.revisao.valores_documentos_imovel === 1
                                                                    ? <Button appearance="default" marginLeft={15} onClick={(e) => handleBtnAnterior(e)}>
                                                                        <ChevronLeftIcon className="icon-label" style={{ margin: '0 5px 0 0' }} />
                                                                        Anterior
                                                                    </Button>
                                                                    : ''

                                                            :
                                                            <Button appearance="default" marginLeft={15} onClick={(e) => handleBtnAnterior(e)}>
                                                                <ChevronLeftIcon className="icon-label" style={{ margin: '0 5px 0 0' }} />
                                                                Anterior
                                                            </Button>

                                                    }

                                                    <Button
                                                        appearance="primary"
                                                        marginLeft={15}
                                                        //onClick={handleSubmit((e) => handleBtnAvancar(e))}
                                                        onClick={
                                                            handleSubmit(e => dadosVenda.status_processo !== 22
                                                                ? handleBtnAvancar(e)
                                                                : alteracaoRevisao === true ? handleBtnAvancar(e) : handleVerificarRevisao(e))
                                                        }
                                                    >
                                                        {dadosVenda.status_processo === 22
                                                            ? dadosVenda.revisao.compradores === 1
                                                                ? 'Próxima correção'
                                                                : dadosVenda.revisao.comissao === 1
                                                                    ? 'Próxima correção'
                                                                    : 'Salvar correções'

                                                            : 'Avançar'
                                                        }
                                                        <ChevronRightIcon className="icon-save" />
                                                    </Button>
                                                </div>
                                            </div>
                                        </Pane>
                                    </form>
                                </div>
                            }

                            {/* FORMULARIO PARA PESSOA FISICA */}
                            {compradorProcesso.data[index_Comprador].tipo_pessoa === 0 &&
                                <div className='Form Pessoa Física'>
                                    <h4 style={dadosVenda.status_processo === 22 ? { marginTop: '15px' } : { marginTop: '90px' }}>Dados do Comprador</h4>
                                    <p className="descritption" style={{ margin: '15px 0px 0px' }}>Preencha os dados do comprador responsável.</p>
                                    <form id="formGroup" className="formGroup-dialog" style={{ top: 0 }} encType="multipart/form-data" onChange={e => dadosVenda.status_processo === 22 ? handleVerificarRevisao(e) : (e)}>
                                        <input id={"comprador_id-" + index_Comprador} type="hidden" value={idsCompradores[index_Comprador]['usuario_id']} />
                                        <input id={"comprador_tipo_pessoa-" + index_Comprador} type="hidden" value={checkedPessoaJuridica === false ? "0" : "1"} />
                                        <Pane>
                                            <div className="row-recibo" style={{ marginTop: '0px' }}>
                                                <div className="line-checkbox">
                                                    <Checkbox
                                                        label="O comprador possui procurador."
                                                        checked={watch('compradores.' + index_Comprador + '.procurador.check', index_Comprador)}
                                                        className="checkbox"
                                                        {...register('compradores.' + index_Comprador + '.procurador.check', {
                                                            onChange: (e) => {
                                                                handleCheckedProcurador(e, index_Comprador)
                                                            }
                                                        })}
                                                    />
                                                </div>

                                                {procuradores[index_Comprador]['checkProcurador'] === true ?
                                                    <div className="rowForm">
                                                        <TextInputField
                                                            width={480}
                                                            label="Nome completo - Procurador"
                                                            description=""
                                                            placeholder="Ex: José Maria da Silva"
                                                            required
                                                            style={
                                                                watch('compradores.' + index_Comprador + '.procurador.nome')
                                                                    ? inputGreen
                                                                    : errors?.compradores?.[index_Comprador]?.procurador?.nome ? inputError : inputDefault
                                                            }
                                                            {...register('compradores.' + index_Comprador + '.procurador.nome', {
                                                                required: true,
                                                            })}
                                                            validationMessage={errors?.compradores?.[index_Comprador]?.procurador?.nome && errorTextInput}
                                                        />

                                                        <TextInputField
                                                            width={120}
                                                            label="Celular"
                                                            description=""
                                                            placeholder="Ex: (21) 9999-9999"
                                                            maxLength={15}
                                                            required
                                                            value={watch('compradores.' + index_Comprador + '.procurador.telefone')}
                                                            style={
                                                                watch('compradores.' + index_Comprador + '.procurador.telefone')?.length > 9
                                                                    ? inputGreen
                                                                    : errors?.compradores?.[index_Comprador]?.procurador?.telefone ? inputError : inputDefault
                                                            }
                                                            {...register('compradores.' + index_Comprador + '.procurador.telefone', {
                                                                required: true,
                                                                minLength: 10,
                                                                setValueAs: e => phoneMask(e)
                                                            })}
                                                            validationMessage={errors?.compradores?.[index_Comprador]?.procurador?.telefone && errorTextInput}
                                                        />
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>

                                            <div className="row-recibo">
                                                <div className="subtitle">
                                                    <CloudUploadIcon className="icon-subtitle" />
                                                    Upload de documentos do comprador
                                                </div>
                                                <p className="descritption description-upload">Atenção: subir arquivos no formato .doc, .docx ou .pdf. <br /> Para aprender a converter uma foto para pdf., <a href="https://www.tecmundo.com.br/apps/72485-apps-converter-documentos-para-pdf-celulares-tablets.htm" target="_blank" rel="noreferrer" title="Converter foto para PDF">clique aqui</a>.</p>

                                                <MultiUploadDoc pessoa={'compradores'} type="fisica" context={VendasContext} indexPessoa={index_Comprador} refreshProcess={recarregarVenda} idDonoDocumento={compradorProcesso.data[index_Comprador].id} />
                                            </div>

                                            <div className="mensagem-campos msg-obrigatorio">*Campos obrigatórios</div>

                                            <div className="footer-form">
                                                <div>
                                                    {/* ESPAÇO PARA ALINHA A DIREITA OS BOTÕES */}
                                                </div>

                                                <div>
                                                    {
                                                        dadosVenda.status_processo === 22
                                                            ?
                                                            dadosVenda.revisao.vendedores === 1
                                                                ?
                                                                <Button appearance="default" marginLeft={15} onClick={(e) => handleBtnAnterior(e)}>
                                                                    <ChevronLeftIcon className="icon-label" style={{ margin: '0 5px 0 0' }} />
                                                                    Anterior
                                                                </Button>
                                                                :
                                                                dadosVenda.revisao.valores_documentos_imovel === 1
                                                                    ? <Button appearance="default" marginLeft={15} onClick={(e) => handleBtnAnterior(e)}>
                                                                        <ChevronLeftIcon className="icon-label" style={{ margin: '0 5px 0 0' }} />
                                                                        Anterior
                                                                    </Button>
                                                                    : ''

                                                            :
                                                            <Button appearance="default" marginLeft={15} onClick={(e) => handleBtnAnterior(e)}>
                                                                <ChevronLeftIcon className="icon-label" style={{ margin: '0 5px 0 0' }} />
                                                                Anterior
                                                            </Button>

                                                    }

                                                    {/*<Button
                                                                appearance="primary"
                                                                marginLeft={15}
                                                                onClick={handleSubmit((e) => handleBtnAvancar(e))}
                                                            >
                                                                {dadosVenda.status_processo === 22
                                                                    ? dadosVenda.revisao.comissao === 1
                                                                        ? 'Próxima correção'
                                                                        : 'Salvar correções'
                                                                    : 'Avançar'
                                                                }
                                                                <ChevronRightIcon className="icon-save" />
                                                            </Button>*/}

                                                    <Button
                                                        appearance="primary"
                                                        marginLeft={15}
                                                        //onClick={handleSubmit((e) => handleBtnAvancar(e))}
                                                        onClick={handleSubmit(e => handleRedirect(e), onError)}
                                                    >
                                                        {dadosVenda.status_processo === 22
                                                            ? dadosVenda.revisao.compradores === 1
                                                                ? 'Próxima correção'
                                                                : dadosVenda.revisao.comissao === 1
                                                                    ? 'Próxima correção'
                                                                    : 'Salvar correções'

                                                            : 'Avançar'
                                                        }
                                                        <ChevronRightIcon className="icon-save" />
                                                    </Button>
                                                </div>
                                            </div>
                                        </Pane>
                                    </form>
                                </div>
                            }
                        </Pane>
                    ))}
                </div>
            }
        </Dialog>
    )
}