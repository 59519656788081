import Axios from 'axios';
import Serve from '../app/serve';
import ServeLogin from '../app/serve_login';

async function updateUsuarios(usuario_id, arrayData, acessoTemporario) {
  const token = localStorage.getItem('token');
  const app = acessoTemporario ? ServeLogin() : Serve();
  const api = acessoTemporario ? 'atualizar_usuario_acesso/' : 'atualizar_usuario/';
  let data;

  await Axios.post(app + api + usuario_id, arrayData, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
    .then(res => {
      if (res.data.status && res.data.status === (401 || 498)) {
        localStorage.clear();
      } else {
        data = res.data;
      }
    })
    .catch(function (error) {
      console.log(error);
    })

  return data;
};

export default updateUsuarios;