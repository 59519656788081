import React from 'react'
import {Pane, Button, ArrowRightIcon} from 'evergreen-ui';
import Image from '../../../../../images/undraw_login_re_4vu2.svg'
//import LogoDna from '../../../../../images/logo_dna.svg';
import { useHistory } from 'react-router-dom';

export default function Pagina404() {

    const history = useHistory();

    const handleLogin = (e) => {
        history.push('/acesso')
    }

    return (
        <Pane>
            <div className="logon-container container-404">
                <div className="coll">

                    {/*<div>
                        <img src={LogoDna} alt="logo_dna" title="DNA Imóveis" />
                    </div>*/}
                    
                    <div>
                        <img src={Image} alt="undraw_login" />
                    </div>

                    <h2>Parece que esse link não existe.</h2>

                    <p>Não se preocupe, vamos levar você<br/> de volta para nossa tela de login :)</p>

                    <Button className="btn-dna-green btn-login" onClick={(e) => handleLogin(e)}>
                        Ir para login <ArrowRightIcon className="icon-voltar"/>
                    </Button>
                </div>  
            </div>
        </Pane>
    )
}
