import React, { useState, useEffect, useContext } from 'react';
import { Pane, Tablist, Tab, Button } from 'evergreen-ui';

//Components
import VendasAdicionadasDG from './Table/VendasAdicionadasDC';
import VendasEnviadasDC from './Table/VendasEnviadasDC';



const subtitulo = {
    fontSize: 14,
    color: '#696F8C',
    fontWeight: 400,
    marginTop: 10,
}

export default function TablePanel() {

    const [tabs] = useState(['Em rascunho', 'Entregues']);
    const [selectTabs, setSelectTabs] = useState(0);    
    const [userName] = useState(localStorage.getItem('nome_usuario'));

    return (
        <Pane>
            <p style={subtitulo}>Olá, {userName} Acompanhe todas as vendas das lojas, antes e depois de enviadas para o Pós-venda :) </p>
            <Tablist marginTop={30} marginBottom={30}>
                {tabs.map((tab, index) => (
                    <Tab
                        key={tab}
                        id={tab}
                        onSelect={() => setSelectTabs(index)}
                        isSelected={index === selectTabs}
                        aria-controls={`panel-${tab}`}
                        appearance="primary"
                        height={44}
                        size={14}
                    >
                        {tab}
                    </Tab>
                ))}
            </Tablist>
            {selectTabs === 0 && <VendasAdicionadasDG />}
            {selectTabs === 1 && <VendasEnviadasDC />}
        </Pane >
    )
}