import React, {useState} from 'react'
import ShowDocument from '../../app/showDocument';

export default function InfoEscritura(props) {
    const [token] = useState(localStorage.getItem('token'));
    console.log('Props: ' , props);

    const prazo_assinatura = props.prazoAssinatura;
    const pg_na_escritura = props.processo.verificar_processo_escritura.processo.pg_na_escritura;
    const data_sugerida = props.processo.verificar_processo_escritura.processo.prazo_escritura;
    const respostas = props.processo.verificar_processo_escritura.respostas;   
    const comprovante = props.processo.verificar_processo_escritura.comprovante_pagamento
    //console.log('Prazo na escritura: ' , prazo_assinatura);
    //console.log('Pagamento na escritura: ' , pg_na_escritura);
    //console.log('Respostas selecionado: ' , respostas); 
    console.log('Comprovante: ' , comprovante);

    let pg_escritura = pg_na_escritura === 0 ? false : true;
    //console.log('O pg foi na escritura: ' + pg_escritura);

    // const dataNovaArr = respostas?.data_nova !== null ? respostas?.data_nova.split('-') : '';
    // const dia = dataNovaArr[2];
    // const mes = dataNovaArr[1];
    // const ano = dataNovaArr[0];
    // const dataConvertida = dia + '/' + mes + '/' + ano;

    const dataConvertida = respostas?.data_nova;

    const fontGerente = {
        fontSize: '12px'
    }

    return (
        <>
            {
                respostas?.resposta_id === null
                ?
                <div>
                    <div className="info-escritura">
                    <div className="row">
                            <span className="label">Previsão de escritura</span>
                            <p>{prazo_assinatura}</p>
                        </div>
                    </div>
                </div>

                :
                <div className="info-escritura" style={props.perfil === 'posVenda' ? {marginTop: '0'} : {marginTop: '15px'}} >
                    {
                        props.perfil === 'posVenda' 
                        ?
                        <h2 className="subtitle subtitle-table">Escritura</h2>

                        :
                        ''
                    }

                    <div className="row">
                        <span className="label" style={props.perfil === 'posVenda' ? {fontSize: 'inherit'} : {fontSize: '12px', fontWeight: '500'}}>
                            Data prevista {props.perfil === 'posVenda' ? '' : 'da escritura'}
                        </span>
                        <p style={props.perfil === 'posVenda' ? {fontSize: 'inherit'} : {fontSize: '12px'}}>
                            {respostas?.resposta_id === 6 ? respostas?.data_anterior : prazo_assinatura}
                        </p>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div>

                                {
                                    <>
                                        <p style={props.perfil === 'posVenda' ? {fontSize: 'inherit'} : {fontSize: '12px'}}>
                                            { 
                                                respostas === null &&
                                                <>
                                                    {/*A Escritura aconteceu. O pagamento foi em outro momento*/}
                                                    <span>Aguardando atualizações para registro na plataforma</span>.
                                                </>
                                            }

                                            {
                                                respostas?.resposta_id === 1 && 
                                                <>
                                                    {/*A Escritura aconteceu. O pagamento foi em outro momento*/}  
                                                    A escritura aconteceu na data prevista.
                                                </>
                                            }

                                            {
                                                respostas?.resposta_id === 3 && 
                                                <>
                                                    {/*A Escritura aconteceu. O pagamento não foi realizado.*/}
                                                    A escritura aconteceu na data prevista.<br/><span>Não houve pagamento de comissão</span>.
                                                </>
                                            }

                                            {
                                                respostas?.resposta_id === 4 &&
                                                <>
                                                    {/*A Escritura aconteceu. O pagamento foi realizado.*/} 
                                                    A escritura aconteceu na data prevista. <br/><span>Houve pagamento de comissão</span>.
                                                </>
                                            }

                                            {
                                                respostas?.resposta_id === 5 &&
                                                <>
                                                    {/*A Escritura não aconteceu. A venda caiu.*/} 
                                                    A escritura não aconteceu na data prevista. <br/><span>A venda caiu</span>.
                                                </>
                                            }

                                            {
                                                respostas?.resposta_id === 6 &&
                                                <>
                                                    {/*A Escritura não aconteceu. Houve reagendamento.*/} 
                                                    A escritura não aconteceu na data prevista.<br/> Foi sugerido um <span>reagendamento</span>.
                                                </>
                                            }
                                        </p>
                                    </>
                                }
                            </div>
                            
                            <div>
                                {/*Comprovante ou data*/}
                                {
                                    respostas?.resposta_id === 4 &&
                                    <>
                                        <span className="label" style={props.perfil === 'posVenda' ? {fontSize: 'inherit'} : {fontSize: '12px', fontWeight: '500'}}>Comprovante de pagamento</span>
                                        <p 
                                            style={{cursor: 'pointer', color: '#3366FF', textDecoration: 'undeline', fontSize: props.perfil === 'posVenda' ? 'inherit' : '12px'}}
                                            onClick={(e) => ShowDocument(comprovante.id, "documento", token)}
                                        >
                                            {comprovante.nome_original}
                                        </p> 
                                    </>
                                }
                                
                                {
                                    respostas?.resposta_id === 3 &&
                                    <>
                                        <span className="label" style={props.perfil === 'posVenda' ? {fontSize: 'inherit'} : {fontSize: '12px', fontWeight: '500'}}>Pagamento reagendado para:</span>
                                        <p style={props.perfil === 'posVenda' ? {fontSize: 'inherit'} : {fontSize: '12px'}}>{dataConvertida}</p>   
                                    </>
                                }

                                {
                                    respostas?.resposta_id === 6 &&
                                    <>
                                        <span className="label" style={props.perfil === 'posVenda' ? {fontSize: 'inherit'} : {fontSize: '12px', fontWeight: '500'}}>Escritura reagendada para:</span>
                                        <p style={props.perfil === 'posVenda' ? {fontSize: 'inherit'} : {fontSize: '12px'}}>{dataConvertida}, às {respostas?.horario}h</p> 
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {
                            (respostas?.resposta_id === 3 || respostas?.resposta_id === 5) &&
                            <>
                                <span className="label" style={props.perfil === 'posVenda' ? {fontSize: 'inherit'} : {fontSize: '12px', fontWeight: '500'}}>Observações:</span>
                                <p style={props.perfil === 'posVenda' ? {fontSize: 'inherit'} : {fontSize: '12px'}}>{respostas?.observacao}</p>
                            </>
                        }

                        {
                            (respostas?.resposta_id === 6) &&
                            <>
                                <span className="label" style={props.perfil === 'posVenda' ? {fontSize: 'inherit'} : {fontSize: '12px', fontWeight: '500'}}>Local da escritura</span>
                                <p style={props.perfil === 'posVenda' ? {fontSize: 'inherit'} : {fontSize: '12px'}}>{respostas?.local}, {respostas?.numero} {respostas?.unidade === null ? '' : ' - ' + respostas?.unidade} {respostas?.complemento === null ? '' : ' / ' + respostas?.complemento}</p>
                            </>
                        }
                    </div>
                </div>
            }
        </>
    )
}
