import React, { useContext, useState } from 'react';
import {
    Pane,
    Dialog,
    Button,
    OfficeIcon,
    CloudUploadIcon,
    EditIcon,
    TextInputField,
    Checkbox,
    FilePicker,
    Textarea,
    Label,
    SmallTickIcon,
    CornerDialog,
    LockIcon,
    ShareIcon
} from 'evergreen-ui';

import Axios from 'axios';
import Serve from '../../../../app/serve';
import { useHistory } from 'react-router-dom';

import './index.css';

import PosNegociacaoContext from '../../../../context/PosNegociacaoContext';

//Máscaras
import cepMask from '../../../../components/cepMask';
import somenteNumero from '../../../../components/somenteNumero';
import dateMask from '../../../../components/dateMask';

export default function FormRecibo() {
    const usuario_id = localStorage.getItem('usuario_id');

    const {
        setLoading,
        setItemAdicionado,
        setItemEnviado,
        isShownFormRecibo, setIsShownFormRecibo,
        isShowCornerDialog, setIsShowCornerDialog,
        setIsShowSendBackOffice,
        dadosRecibo,
        verificaRgi, setVerificaRgi,
        validateFieldRgi, setValidateFieldRgi,
        validateFieldCep, setValidateFieldCep,
        validateFieldDataAssinatura, setValidateFieldDataAssinatura,
        validateFieldFormaPagamento, setValidateFieldFormaPagamento,
        validateInputFile, setValidateInputFile,
        validateCepMessage, setValidateCepMessage,
        validateAdress, setValidateAdress,
        checkedAVista, setCheckedAVista,
        checkedFinanciamento, setCheckedFinanciamento,
        checkedFgts, setCheckedFgts,
        setIsShowFeedBack,
        estadoRecibo, setEstadoRecibo,
        aVista, setAvista,
        financiamento, setFinanciamento,
        fgts, setFgts

    } = useContext(PosNegociacaoContext);
    console.log(dadosRecibo);

    const [fileInput, setFileInput] = useState([]);

    const app = Serve();
    const history = useHistory();
    const [token] = useState(localStorage.getItem('token'));
    const [gerente_id] = useState(localStorage.getItem('usuario_id'));

    //Checkbox
    /*const [aVista, setAvista] = useState('');
    const [financiamento, setFinanciamento] = useState('');
    const [fgts, setFgts] = useState('');*/

    const [dadosVincularResponsavel, setDadosVincularResponsavel] = useState([]);

    const btnBlock = {
        pointerEvents: 'none',
        backgroundColor: '#D6E0FF',
        borderColor: '#D6E0FF',
        fontWeight: 600
    }

    const btnEnable = {
        pointerEvents: 'inherit',
        opacity: 'inherit',
    }

    const handleRgi = (e) => {
        setVerificaRgi(false);

        let rgi = document.querySelector('.recibo-rgi-1');

        Axios.post(app + 'verificar_imovel_rgi', {
            "rgi": rgi.value
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then(res => {
                //console.log(res);
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    let imovel = res.data.imovel;
                    console.log(imovel);

                    if (imovel !== null) {
                        if (imovel.status_processo === 7) {
                            document.querySelector('#recibo-imovel_id-1').value = imovel.id;
                            document.querySelector('#recibo-cep-1').value = imovel.cep;
                            document.querySelector('#recibo-logradouro-1').value = imovel.logradouro;
                            document.querySelector('#recibo-numero-1').value = imovel.numero;
                            document.querySelector('#recibo-unidade-1').value = imovel.unidade;
                            document.querySelector('#recibo-complemento-1').value = imovel.complemento;
                            document.querySelector('#recibo-bairro-1').value = imovel.bairro;

                            setVerificaRgi(false);

                        } else {
                            //alert("Imovel vinculado a outro processo");
                            setVerificaRgi(true);
                        }

                    } else {
                        /*document.querySelector('#recibo-cep-1').value = '';
                        document.querySelector('#recibo-logradouro-1').value = '';
                        document.querySelector('#recibo-numero-1').value = '';
                        document.querySelector('#recibo-unidade-1').value = '';
                        document.querySelector('#recibo-complemento-1').value = '';
                        document.querySelector('#recibo-bairro-1').value = '';*/
                        console.log('vazio');

                        //setVerificaRgi(true);
                    }
                }
                ////console.log(res.data.usuario);
                //let usuario = res.data.usuario;

            })

        if (rgi.value.length > 3) {
            setValidateFieldRgi(true);
        }
        else {
            setValidateFieldRgi(false);
        }
    }

    const handleCep = (e) => {
        let cep = document.querySelector('.recibo-cep-1');
        console.log();
        if (cep.value.length) {
            cepMask(cep.value);
            cep.value = cepMask(cep.value);

            let cep_validar = cep.value.replace(/\D/g, '')

            //Verifica se campo cep possui valor informado.
            if (cep_validar !== "") {
                //console.log("cep_validar = " + cep_validar)
                //Expressão regular para validar o CEP.
                let validacep = /^[0-9]{8}$/;

                //Valida o formato do CEP.
                if (validacep.test(cep_validar)) {

                    //Preenche os campos com "..." enquanto consulta webservice.
                    document.querySelector('.recibo-logradouro-1').value = '...';
                    document.querySelector('.recibo-bairro-1').value = '...';

                    //Consulta o webservice viacep.com.br/
                    Axios.get("https://viacep.com.br/ws/" + cep_validar + "/json/")
                        .then(res => {
                            //console.log(res.data);
                            if (!res.data.erro) {
                                //Atualiza os campos com os valores da consulta.
                                document.querySelector('.recibo-logradouro-1').value = res.data.logradouro;
                                document.querySelector('.recibo-bairro-1').value = res.data.bairro;
                                setValidateAdress(true);
                                setValidateCepMessage(true)

                            } //end if.
                            else {
                                //CEP pesquisado não foi encontrado.
                                limpa_formulário_cep();
                                setValidateAdress(false);
                                setValidateCepMessage(false)
                            }

                        })
                } //end if.
                else {
                    //console.log("entou 123");
                    //cep é inválido.
                    limpa_formulário_cep();
                    //alert("Formato de CEP inválido.");
                }
            } //end if.

            if (cep.value.length === 9) {
                setValidateFieldCep(true);
            }
            else {
                setValidateFieldCep(false);
            }
        }
    }

    function limpa_formulário_cep() {
        document.querySelector('.recibo-logradouro-1').value = '';
        document.querySelector('.recibo-bairro-1').value = '';
    }

    const handleNumero = () => {
        let numero = document.querySelector('.recibo-numero-1');
        if (numero.value.length > 0) {
            somenteNumero(numero.value);
            numero.value = somenteNumero(numero.value);
        }
    }

    const handleDataAssinatura = () => {
        let dataAssinatura = document.querySelector('.recibo-data-assinatura-1');
        setValidateFieldDataAssinatura(false);

        if (dataAssinatura.value.length) {
            dateMask(dataAssinatura.value);
            dataAssinatura.value = dateMask(dataAssinatura.value);

            if (dataAssinatura.value.length >= 8) {
                setValidateFieldDataAssinatura(true);
            }
            else {
                setValidateFieldDataAssinatura(false);
            }
        }
    }

    const handleChecked = (e) => {
        const valueCheckbox = e.target.value;
        console.log(valueCheckbox);

        if (valueCheckbox === '1') {
            setCheckedAVista(e.target.checked);
            console.log(checkedAVista);

            if (checkedAVista === false) {
                setValidateFieldFormaPagamento(true);
                setAvista('1');
            }
            else {
                setValidateFieldFormaPagamento(false);
                setAvista('');
            }
        }

        else if (valueCheckbox === '2') {
            setCheckedFinanciamento(e.target.checked);

            if (checkedFinanciamento === false || checkedFgts === true) {
                setValidateFieldFormaPagamento(true);

                if (checkedFinanciamento === true) {
                    setFinanciamento('');
                }
                else {
                    setFinanciamento('2');
                }
            }
            else {
                setValidateFieldFormaPagamento(false);
                setFinanciamento('');
            }
        }

        else if (valueCheckbox === '3') {
            setCheckedFgts(e.target.checked);

            if (checkedFgts === false || checkedFinanciamento === true) {
                setValidateFieldFormaPagamento(true);

                if (checkedFgts === true) {
                    setFgts('');
                }
                else {
                    setFgts('3');
                }
            }

            else {
                setValidateFieldFormaPagamento(false);
                setFgts('');
            }
        }

        else {
            console.log('Nada pode ser selecionado!!!');
        }

        console.log('Validação Financiamento: ' + checkedFinanciamento);
        console.log('Financiamento: ' + financiamento);

        console.log('Validação FGTS: ' + checkedFgts);
        console.log('FGTS: ' + fgts);
    }

    const handleUpload = (e) => {
        //const fileRef = e.target.files[0];
        const file = document.querySelector('input[type="file"]').files[0];
        //console.log(fileRef);
        //console.log(file);
        setFileInput(file);

        setValidateInputFile(true);
    }

    function openFileUpload() {
        if (validateFieldDataAssinatura === true
            && validateFieldFormaPagamento === true) {
            //console.log('open');
            return 'open';
        }
        else {
            //console.log('close');
            return 'close';
        }
    }
    openFileUpload();

    function formValidate() {
        if (verificaRgi === false
            && validateFieldRgi === true
            && validateFieldCep === true
            && validateFieldDataAssinatura === true
            && validateFieldFormaPagamento === true
            && validateInputFile === true) {

            //console.log('enabled');
            return 'enabled';
        }
        else {
            //console.log('disabled');
            return 'disabled';
        }
    }
    formValidate()

    const clearStateValidates = async () => {
        setValidateFieldRgi(false);
        setValidateFieldCep(false);
        setValidateFieldDataAssinatura(false);
        setValidateFieldFormaPagamento(false);
        setValidateInputFile(false);
        setValidateCepMessage(true);
        setValidateAdress(false);

        setCheckedAVista(false);
        setCheckedFinanciamento(false);
        setCheckedFgts(false);

        setAvista('');
        setFinanciamento('');
        setFgts('');
    }

    const handleSave = (e) => {
        e.preventDefault();
        console.log('Salvou');

        setLoading(false);

        //Dados do formulário
        let rgi = document.querySelector('.recibo-rgi-1').value;
        let cep = document.querySelector('.recibo-cep-1').value;
        let bairro = document.querySelector('.recibo-bairro-1').value;
        let logradouro = document.querySelector('.recibo-logradouro-1').value;
        let unidade = document.querySelector('.recibo-unidade-1').value;
        let complemento = document.querySelector('.recibo-complemento-1').value;
        let numero = document.querySelector('.recibo-numero-1').value;
        let dataAssinatura = document.querySelector('.recibo-data-assinatura-1').value;
        let selectPagamento = document.querySelector('.recibo-forma-pagamento-1').value;  //formaPagamento;
        let fileUpload = fileInput;
        let observacao = document.querySelector('.recibo-observacao-1').value;

        console.log(rgi);
        console.log(cep);
        console.log(bairro);
        console.log(logradouro);
        console.log(unidade);
        console.log(complemento);
        console.log(numero);
        console.log(dataAssinatura);
        console.log(selectPagamento);
        console.log(fileUpload);
        console.log(observacao);

        const data = new FormData();
        data.append('arquivo_recibo', fileUpload);
        data.append('rgi', rgi);
        data.append('cep', cep);
        data.append('logradouro', logradouro);
        data.append('numero', numero);
        data.append('unidade', unidade);
        data.append('complemento', complemento);
        data.append('bairro', bairro);
        data.append('usuario_id', gerente_id);
        data.append('data_assinatura', dataAssinatura);
        data.append('forma_pagamento', selectPagamento);
        data.append('observacao', observacao);
        data.append('origem', "recibo");

        console.log(data);
        console.log(data.get('arquivo_recibo'))

        /* Axios.post(app + 'salvar_imovel', {
            "rgi": rgi,
            "": cep,
            "": logradouro,
            "": numero,
            "": unidade,
            "": complemento,
            "": bairro,
            "": ,
            "": ,
            "": ,
            "": ,
            "": "",
            recibo
        }, */
        Axios.post(app + 'salvar_imovel', data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    //console.log(res);
                    //alert(res.data.mensagem_processo);  
                    let mensagem = res.data.mensagem_processo;

                    if (res.data.mensagem_documento !== '') {
                        mensagem += '\n' + res.data.mensagem_documento;
                    }
                    //alert(mensagem); 
                    //Se tiver resposta do servidor que os dados foram salvos

                    let arrayDadosVincularResponsavel = {
                        'imovel_id': res.data.imovel_id,
                        'processo_id': res.data.processo_id,
                        'usuario_id': gerente_id
                    };

                    console.log(arrayDadosVincularResponsavel);

                    setDadosVincularResponsavel(arrayDadosVincularResponsavel);
                    setIsShowCornerDialog(true);
                    setIsShownFormRecibo(false);
                    setEstadoRecibo('cadastrar');

                    clearStateValidates();

                    recarregarImoveis();

                    setTimeout(function () { setLoading(true); }, 1000);
                }
            })
            .catch(function (error) {
                // handle error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    let resposta = error.response.data;
                    //console.log(resposta);
                    //console.log(typeof(resposta));
                    /* resposta.forEach(element => {
                        ////console.log("key = " + key);
                        //console.log("element" + element);
                    }); */
                    let erros = '';
                    if (resposta.errors) {
                        for (let [key, value] of Object.entries(resposta.errors)) {
                            //console.log("key = " + key);
                            //console.log("value" + value);
                            erros += value + "\n";

                            //console.log(resposta.errors.rgi);
                            //console.log(value);

                            /*switch (key) {
                                case 'rgi':
                                    setErroRgi('' + value + '');
                                    break;
                                case 'bairro':
                                    setErroBairro('' + value + '');
                                    break;
                                case 'logradouro':
                                    setErroLogradouro('' + value + '');
                                    break;
                                case 'cep':
                                    setErroCep('' + value + '');
                                    break;
                                case '':
                                    setErroRgi('');
                                    setErroBairro('');
                                    setErroLogradouro('');
                                    setErroCep('');
                                    break
                                default:
                                    //console.log('Erro desconhecido!!!');
                                    alert('teste');
                            }*/
                        }
                    }

                    if (resposta.error) {
                        erros = resposta.error;
                    }

                    alert(erros);
                    //console.log(resposta.errors);
                    //console.log(error.response.status);
                    //console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    //console.log('Error', error.message);
                }
                //console.log(error.config);
            })
    }

    const handleEdit = (e) => {
        e.preventDefault();
        console.log('Salvou');

        setLoading(false);

        //Dados do formulário
        let imovel_id = document.querySelector('#recibo-id').value;
        let rgi = document.querySelector('.recibo-rgi-1').value;
        let cep = document.querySelector('.recibo-cep-1').value;
        let bairro = document.querySelector('.recibo-bairro-1').value;
        let logradouro = document.querySelector('.recibo-logradouro-1').value;
        let unidade = document.querySelector('.recibo-unidade-1').value;
        let complemento = document.querySelector('.recibo-complemento-1').value;
        let numero = document.querySelector('.recibo-numero-1').value;
        let dataAssinatura = document.querySelector('.recibo-data-assinatura-1').value;
        let selectPagamento = document.querySelector('.recibo-forma-pagamento-1').value;
        let fileUpload = fileInput;
        let observacao = document.querySelector('.recibo-observacao-1').value;

        console.log(rgi);
        console.log(cep);
        console.log(bairro);
        console.log(logradouro);
        console.log(unidade);
        console.log(complemento);
        console.log(numero);
        console.log(dataAssinatura);
        console.log(selectPagamento);
        console.log(fileUpload);
        console.log(observacao);

        const data = new FormData();
        data.append('arquivo_recibo', fileUpload);
        data.append('rgi', rgi);
        data.append('cep', cep);
        data.append('logradouro', logradouro);
        data.append('numero', numero);
        data.append('unidade', unidade);
        data.append('complemento', complemento);
        data.append('bairro', bairro);
        data.append('usuario_id', gerente_id);
        data.append('data_assinatura', dataAssinatura);
        data.append('forma_pagamento', selectPagamento);
        data.append('observacao', observacao);
        data.append('origem', "recibo");

        Axios.post(app + 'atualizar_imovel/' + imovel_id, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        })
            .then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    //console.log(res);
                    //alert(res.data.mensagem_processo);
                    let mensagem = res.data.mensagem_processo;
                    //Se tiver resposta do servidor que os dados foram editados
                    setIsShowCornerDialog(true);
                    setIsShownFormRecibo(false);
                    setEstadoRecibo('editar');

                    clearStateValidates();

                    recarregarImoveis();

                    setTimeout(function () { setLoading(true); }, 1000);
                }

            })
            .catch(function (error) {
                // handle error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    let resposta = error.response.data;
                    //console.log(resposta);
                    //console.log(typeof(resposta));
                    /* resposta.forEach(element => {
                        ////console.log("key = " + key);
                        //console.log("element" + element);
                    }); */
                    let erros = '';
                    if (resposta.errors) {
                        for (let [key, value] of Object.entries(resposta.errors)) {
                            //console.log("key = " + key);
                            //console.log("value" + value);
                            erros += value + "\n";
                        }
                        //console.log(resposta.errors);
                    }

                    if (resposta.error) {
                        erros = resposta.error;
                        //console.log(resposta.error);
                    }

                    alert(erros);

                    //console.log(error.response.status);
                    //console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    //console.log('Error', error.message);
                }
                //console.log(error.config);
            })


    }

    const recarregarImoveis = async () => {
        await Axios.post(app + 'retorno_processos_criados_gerente', {
            "usuario_id": usuario_id,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        })
            .then(res => {
                //console.log("asdfad");
                if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    console.log(res);
                    //console.log("erro sei la por que");
                    const processos = res.data.adicionados;
                    //console.log(processos);
                    setItemAdicionado(processos);

                    setLoading(true);
                }

            })
    }

    const handleSendBackoffice = (e) => {
        setIsShowSendBackOffice(true);
    }

    const closeRecibo = (e) => {

        setIsShownFormRecibo(false)

        setCheckedAVista(false);
        setCheckedFinanciamento(false);
        setCheckedFgts(false);

        setAvista('');
        setFinanciamento('');
        setFgts('');
    }

    const confirmSendBackoffice = (e) => {
        console.log("aaadddd");
        console.log(dadosVincularResponsavel);

        Axios.post(app + 'vincular_responsavel_processo', dadosVincularResponsavel, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        })
            .then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    //console.log(res);
                    //alert(res.data.mensagem_processo);
                    //let mensagem = res.data.mensagem_processo;
                    //Se tiver resposta do servidor que os dados foram editados 

                    recarregarRecibosAbertos();
                    recarregarRecibosEnviados();

                    setIsShowFeedBack(true);
                    setIsShowCornerDialog(false)
                }

            })
            .catch(function (error) {
                // handle error
                if (error.response) {
                    let resposta = error.response.data;

                    let erros = '';
                    if (resposta.errors) {
                        for (let [key, value] of Object.entries(resposta.errors)) {
                            erros += value + "\n";
                        }
                    }

                    if (resposta.error) {
                        erros = resposta.error;
                        //console.log(resposta.error);
                    }

                    alert(erros);

                    //console.log(error.response.status);
                    //console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                //console.log(error.config);
            })
        //setIsShowSendBackOffice(false);

    }

    const recarregarRecibosAbertos = async () => {
        await Axios.post(app + 'retorno_processos_criados_gerente', {
            "usuario_id": gerente_id,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        })
            .then(res => {
                //console.log("asdfad");
                if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    console.log(res);
                    //console.log("erro sei la por que");
                    const processos = res.data.adicionados;
                    //console.log(processos);
                    setItemAdicionado(processos);
                }

            })
    }

    const recarregarRecibosEnviados = async () => {
        await Axios.post(app + 'retorno_processos_criados_gerente', {
            "usuario_id": gerente_id,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        })
            .then(res => {
                //console.log("asdfad");
                if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    console.log(res);
                    //console.log("erro sei la por que");
                    const processos = res.data.enviados;
                    //console.log(processos);
                    setItemEnviado(processos);
                }

            })
    }

    return (
        <Pane>
            <Dialog
                width={808}
                isShown={isShownFormRecibo}
                title={estadoRecibo === 'cadastrar' ? "Adicione um Recibo de Sinal" : "Editar registro de Recibo de Sinal"}
                onCloseComplete={(e) => closeRecibo(e)}
                hasFooter={false}
                preventBodyScrolling
                shouldCloseOnOverlayClick={false}
                containerProps={{
                    className: 'form-dialog-props'
                }}
            >
                <div className="form-dialog">
                    <div className="subtitle"><OfficeIcon className="icon-subtitle" /> Endereço completo do imóvel</div>

                    <p className="descritption">Para agilizar o processo de pós-negociação, insira o endereço<br /> completo do imóvel abaixo.</p>

                    <div className="formGroup formAdd">
                        <form id="formGroup" encType="multipart/form-data">
                            <Pane>
                                {
                                    dadosRecibo !== null &&
                                    <input type="hidden" id="recibo-id" name="recibo-id" value={dadosRecibo.id} />
                                }

                                <div className="rowForm">
                                    {
                                        verificaRgi === true ?
                                            <TextInputField
                                                label="RGI"
                                                description=""
                                                placeholder="Registro Geral de Imóveis"
                                                defaultValue={dadosRecibo !== null ? dadosRecibo.rgi : ''}
                                                id={"recibo-rgi-1"}
                                                className={"recibo-rgi-1"}
                                                onChange={(e) => handleRgi(e)}
                                                required
                                                validationMessage="O RGI já está cadastrado na plataforma"
                                                isInvalid
                                            />

                                            :
                                            <TextInputField
                                                label="RGI"
                                                description=""
                                                placeholder="Registro Geral de Imóveis"
                                                defaultValue={dadosRecibo !== null ? dadosRecibo.rgi : ''}
                                                id={"recibo-rgi-1"}
                                                className={"recibo-rgi-1"}
                                                onChange={(e) => handleRgi(e)}
                                                required
                                            />
                                    }
                                </div>

                                <div className="rowForm">
                                    {validateCepMessage === true
                                        ?
                                        <TextInputField
                                            label="CEP"
                                            description=""
                                            placeholder="00000-000"
                                            defaultValue={dadosRecibo !== null ? dadosRecibo.cep : ''}
                                            id={"recibo-cep-1"}
                                            className={"recibo-cep-1"}
                                            maxLength="9"
                                            onChange={(e) => handleCep(e)}
                                            required
                                        />
                                        :
                                        <TextInputField
                                            isInvalid
                                            label="CEP"
                                            description=""
                                            placeholder="00000-000"
                                            defaultValue={dadosRecibo !== null ? dadosRecibo.cep : ''}
                                            id={"recibo-cep-1"}
                                            className={"recibo-cep-1"}
                                            maxLength="9"
                                            onChange={(e) => handleCep(e)}
                                            required
                                            validationMessage="O CEP não existe"
                                        />
                                    }

                                    <div>
                                        <Label htmlFor="textarea-2" marginBottom={0} display="">
                                            Bairro <LockIcon className="icon-label" />
                                        </Label>
                                        {validateAdress === true
                                            ?
                                            <TextInputField
                                                label=""
                                                description=""
                                                placeholder="---"
                                                defaultValue={dadosRecibo !== null ? dadosRecibo.bairro : ''}
                                                id={"recibo-bairro-1"}
                                                className={"recibo-bairro-1"}
                                                readOnly
                                            />

                                            :
                                            <TextInputField
                                                label=""
                                                description=""
                                                placeholder="---"
                                                defaultValue={dadosRecibo !== null ? dadosRecibo.bairro : ''}
                                                id={"recibo-bairro-1"}
                                                className={"recibo-bairro-1"}
                                                readOnly
                                                disabled
                                            />
                                        }
                                    </div>

                                    <div>
                                        <Label htmlFor="textarea-2" marginBottom={0} marginLeft={20} display="">
                                            Logradouro <LockIcon className="icon-label" />
                                        </Label>
                                        {validateAdress === true
                                            ?
                                            <TextInputField
                                                label=""
                                                description=""
                                                placeholder="---"
                                                defaultValue={dadosRecibo !== null ? dadosRecibo.logradouro : ''}
                                                id={"recibo-logradouro-1"}
                                                className={"recibo-logradouro-1"}
                                                readOnly
                                            //validationMessage=""
                                            />

                                            :
                                            <TextInputField
                                                label=""
                                                description=""
                                                placeholder="---"
                                                defaultValue={dadosRecibo !== null ? dadosRecibo.logradouro : ''}
                                                id={"recibo-logradouro-1"}
                                                className={"recibo-logradouro-1"}
                                                readOnly
                                                disabled
                                            />
                                        }
                                    </div>
                                </div>

                                <div className="rowForm">
                                    <TextInputField
                                        label="Número"
                                        description=""
                                        placeholder="Ex: 345"
                                        defaultValue={dadosRecibo !== null ? dadosRecibo.numero : ''}
                                        width={80}
                                        id={"recibo-numero-1"}
                                        className={"recibo-numero-1"}
                                        onChange={(e) => handleNumero(e)}
                                    />

                                    <TextInputField
                                        label="Unidade"
                                        width={200}
                                        description=""
                                        placeholder="Nº do apartamento (ou casa)"
                                        defaultValue={dadosRecibo !== null ? dadosRecibo.unidade : ''}
                                        id={"recibo-unidade-1"}
                                        className={"recibo-unidade-1"}
                                    />

                                    <div>
                                        <Label htmlFor="textarea-2" marginBottom={0} marginLeft={20} display="">
                                            Complemento
                                        </Label>
                                        <TextInputField
                                            label=""
                                            width={280}
                                            description=""
                                            placeholder="Informações de lote, bloco ou condomínio"
                                            defaultValue={dadosRecibo !== null ? dadosRecibo.complemento : ''}
                                            id={"recibo-complemento-1"}
                                            className={"recibo-complemento-1"}
                                        />
                                    </div>
                                </div>

                                <div className="row-recibo">
                                    <div className="subtitle">
                                        <CloudUploadIcon className="icon-subtitle" />
                                        Upload do Recibo de Sinal
                                    </div>

                                    <p className="descritption description-upload">Atenção: subir arquivos no formato .doc ou .pdf. <br /> Para aprender a converter uma foto para pdf., <a href="https://www.tecmundo.com.br/apps/72485-apps-converter-documentos-para-pdf-celulares-tablets.htm" target="_blank" rel="noreferrer" title="Converter foto para PDF">clique aqui</a>.</p>

                                    <div className="rowForm">
                                        <TextInputField
                                            label="Data de assinatura"
                                            description=""
                                            placeholder="DD/MM/AAAA"
                                            defaultValue={dadosRecibo !== null ? dadosRecibo.dataAssinatura : ''}
                                            id={"recibo-data-assinatura-1"}
                                            className={"recibo-data-assinatura-1"}
                                            onChange={(e) => handleDataAssinatura(e)}
                                            maxLength="10"
                                            required
                                        //validationMessage=""
                                        />

                                        <div>
                                            <Label htmlFor="textarea-2" marginBottom={3} display="block">
                                                Forma de pagamento <span style={{ fontFamily: 'Montserrat', fontWeight: 500, color: '#474D66' }}>*</span>
                                            </Label>

                                            <div className="line-checkbox">
                                                <Checkbox
                                                    appearance="default"
                                                    className="checkbox"
                                                    label="À vista"
                                                    id="check-1"
                                                    value="1"
                                                    checked={checkedAVista}
                                                    disabled={checkedFinanciamento === true || checkedFgts === true ? true : false}
                                                    onChange={e => handleChecked(e) /*setChecked(e.target.checked)*/}
                                                />

                                                <Checkbox
                                                    appearance="default"
                                                    className="checkbox"
                                                    id="check-2"
                                                    value="2"
                                                    label="Financiamento"
                                                    checked={checkedFinanciamento}
                                                    disabled={checkedAVista === true ? true : false}
                                                    onChange={e => handleChecked(e)}
                                                />

                                                <Checkbox
                                                    appearance="default"
                                                    className="checkbox"
                                                    label="FGTS"
                                                    id="check-3"
                                                    value="3"
                                                    checked={checkedFgts}
                                                    disabled={checkedAVista === true ? true : false}
                                                    onChange={e => handleChecked(e)}
                                                />

                                                <input
                                                    type="hidden"
                                                    value={
                                                        aVista !== '' ? [aVista]
                                                            : financiamento !== '' && fgts !== '' ? [financiamento, fgts]
                                                                : financiamento !== '' && fgts === '' ? [financiamento]
                                                                    : financiamento === '' && fgts !== '' ? [fgts]
                                                                        : dadosRecibo.formaPagamento
                                                    }
                                                    name="documento"
                                                    className={"recibo-forma-pagamento-1"}
                                                    id={"recibo-forma-pagamento-1"} />
                                            </div>
                                        </div>
                                    </div>


                                    <Label htmlFor="textarea-2" marginBottom={12} display="block">
                                        Arquivo do recibo {openFileUpload() === 'open' ? '' : <LockIcon className="icon-label" />}
                                    </Label>
                                    {
                                        openFileUpload() === 'open'
                                            ? <FilePicker
                                                width={250}
                                                placeholder={dadosRecibo !== null ? dadosRecibo.recibo : "Formato .doc ou .pdf"}
                                                type="file"
                                                value={dadosRecibo !== null ? dadosRecibo.recibo : ''}
                                                name="file"
                                                onChange={handleUpload}
                                                className="file-input"
                                            />

                                            : <FilePicker
                                                disabled
                                                width={250}
                                                value={dadosRecibo !== null ? dadosRecibo.recibo : ''}
                                                className="file-input"
                                                placeholder={dadosRecibo !== null ? dadosRecibo.recibo : "Formato .doc ou .pdf"}
                                            />
                                    }
                                </div>

                                <div className="row-recibo" style={{ marginTop: '35px' }}>
                                    <div className="subtitle">
                                        <EditIcon className="icon-subtitle" />
                                        Observações
                                    </div>

                                    <Label htmlFor="textarea-2" marginBottom={12} display="block">
                                        Caso necessário, insira observações para que o backoffice fique atento.
                                    </Label>

                                    <Textarea
                                        name="textarea-1"
                                        className="recibo-observacao-1"
                                        id="recibo-observacao-1"
                                        placeholder="Exemplo: O imóvel se encontra em inventário e o comprador só está disponível para contato de 13h às 18h. "
                                        defaultValue={dadosRecibo !== null ? dadosRecibo.observacoes : ''}
                                        height={110}
                                    />
                                </div>

                                <div className="footer-form">
                                    <div className="mensagem-campos">*Campos obrigatórios</div>
                                    <Button appearance="primary" style={formValidate() === 'enabled' ? btnEnable : btnBlock} onClick={(e) => document.querySelector('#recibo-id').value !== "" ? handleEdit(e) : handleSave(e)}>
                                        {estadoRecibo === 'cadastrar' ? 'Salvar' : 'Salvar alteração'}
                                        <SmallTickIcon className="icon-save" />
                                    </Button>
                                </div>
                            </Pane>
                        </form>
                    </div>
                </div>
            </Dialog>

            {/*Confirmação de cadastro ou edição do recibo de sinal*/}
            <CornerDialog
                width={512}
                title={estadoRecibo === 'cadastrar' ? 'Recibo adicionado! :)' : 'Recibo atualizado!'}
                isShown={isShowCornerDialog}
                onCloseComplete={() => setIsShowCornerDialog(false)}
                onConfirm={(e) => handleSendBackoffice(e)}
                hasCancel={true}
                hasFooter={false}
            >
                <span className="content-corner-dialog">
                    {estadoRecibo === 'cadastrar'
                        ? 'Para dar início ao processo de pós-venda, envie o recibo de sinal para o backoffice e fique atento às notificações.'
                        : 'Edite quantas vezes quiser antes de enviar para o backoffice. Depois não serão permitidas edições no documento.'}
                </span>

                <div style={{ display: 'flex', justifyContent: 'end', padding: '10px 0', fontWeight: 600 }}>
                    <Button
                        marginTop={16}
                        onClick={() => setIsShowCornerDialog(false)}
                        style={{ color: '#696F8C', borderColor: '#D8DAE5', fontWeight: 500 }}
                    >
                        Fazer depois
                    </Button>

                    <Button
                        marginLeft={12}
                        marginTop={16}
                        appearance="primary"
                        onClick={(e) => confirmSendBackoffice(e)}
                    >
                        <ShareIcon
                            color="white"
                            style={{ width: 11, height: 11, marginRight: 10 }}
                        />
                        Enviar agora para Backoffice
                    </Button>
                </div>
            </CornerDialog>
        </Pane>
    )
}