import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Lottie from 'react-lottie';
import { useForm } from 'react-hook-form';
import * as WebSocket from 'ws';
import {
  Button,
  Pane,
  toaster,
  TextInputField,
  EyeOpenIcon,
  Spinner,
  EyeOffIcon,
  ArrowRightIcon,
  CornerDialog,
} from 'evergreen-ui';

import Serve from '../../app/serve_login';
import AnimationLoading from '../../images/Animations/loading.json';
import ImageWelcome from '../../images/welcome.svg';
import Axios from 'axios';

import './styles.css';

export default function Logon({ match }) {
  const [showPass, setShowPass] = useState(false);
  const [countErro, setCountErro] = useState(0);
  const [isShownCornerErro, setIsShownCornerErro] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const app = Serve();
  const navegador = navigator.userAgent;
  const navegadorEdg = navegador.indexOf("Edg") > -1;

  const safeboxUrlAcesso = process.env.REACT_APP_SAFEBOX_REDIRECT_URL;

  useEffect(() => {
    const limparLocalStorage = (e) => {

      const currentUrl = window.location.href;

      // Verifica se o usuário está acessando a URL definida no env sem o "/acesso"
      if (currentUrl === `${safeboxUrlAcesso}` || currentUrl === safeboxUrlAcesso.replace('https', 'http')) {
        // Redireciona para a página /acesso
        history.push('/acesso')
      }

      let arrayStorage = [
        localStorage.removeItem('token'),
        localStorage.removeItem('usuario_id'),
        localStorage.removeItem('nome_usuario'),
        localStorage.removeItem('usuario_email'),
        localStorage.removeItem('perfil_login'),
        localStorage.removeItem('perfil_id'),
        localStorage.removeItem('loja_id'),
        localStorage.removeItem('usuario_id'),
        localStorage.removeItem('filtro_responsavel'),
        localStorage.removeItem('filtro_status'),
        localStorage.removeItem('filtro_gerente_rascunho'),
        localStorage.removeItem('filtro_gerente_entregue'),
        localStorage.removeItem('filtro_loja_rascunho'),
        localStorage.removeItem('filtro_loja_entregue'),
        localStorage.removeItem('filtro_mes_cadastro_rascunho'),
        //localStorage.removeItem('filtro_mes_cadastro_entregue'),
        localStorage.removeItem('filtro_limpar_filtro_rascunho'),
        localStorage.removeItem('filtro_limpar_filtro_entregue'),
        localStorage.removeItem('page_number'),
        localStorage.removeItem('ordenacao'),
      ];
      return arrayStorage;
    }
    limparLocalStorage();

    const { data } = match.params;
    const redirectData = () => {
      try {
        JSON.parse(decodeURIComponent(data));
      } catch (error) {
        return "ERRO DE REDIRECT"
      }    
      return redirect(JSON.parse(decodeURIComponent(data)))
    }
    redirectData();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirect = (data) => {
    const perfil_logins = data.perfil_logins.data[0];
    let nome_perfil_login = perfil_logins.nome;
    const loja = data.loja;
    const loja_id = loja[0] ? loja[0].id : '';
    //AsyncStorage.setItem('token', response.data.token);
    localStorage.setItem('token', data.token);
    localStorage.setItem('usuario_id', data.user.id);
    localStorage.setItem('nome_usuario', data.user.name);
    localStorage.setItem('usuario_email', data.user.email);
    localStorage.setItem('perfil_login', nome_perfil_login === "Pós-venda" ? 'Backoffice' : nome_perfil_login);
    localStorage.setItem('perfil_id', perfil_logins.id);
    localStorage.setItem('loja_id', loja_id);
    // modeDev && console.log("nome_perfil_login = " + nome_perfil_login);
    setLoading(false);
    if (nome_perfil_login === "Pós-venda" || nome_perfil_login === "Núcleo" || nome_perfil_login === "Coordenadora de Pós-Negociação") {
      localStorage.removeItem('filtro_status');
      localStorage.removeItem('filtro_gerente');
      localStorage.removeItem('page_number');
      localStorage.removeItem('filtro_endereco');
      localStorage.removeItem('ordenacao');
      localStorage.setItem('filtro_responsavel', data.user.id);
      history.push(nome_perfil_login === "Pós-venda" || nome_perfil_login === "Coordenadora de Pós-Negociação" ? '/negociacoes' : '/nucleo');
    }
    else if (nome_perfil_login === "Admin") {
      history.push('/painel-admin');
    }
    else if (nome_perfil_login === "Apoio") {
      history.push('/apoio');
    }
    else if (nome_perfil_login === "Financeiro") {
      history.push('/financeiro');
    }
    // else if (nome_perfil_login === "Gerente" || nome_perfil_login === "Gerente Geral" || nome_perfil_login === "Diretor Comercial") {
    // history.push('/vendas');
    // }
  };
  
  useEffect(() => {
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AnimationLoading,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: {
      email: '',
      password: '',
    }
  });

  useEffect(() => {
    if (errors.email || errors.password) {
      setCountErro(countErro + 1)
      toaster.danger("Email ou senha incorretos.")
    };
    reset();
    countErro === 3 && setIsShownCornerErro(true);
  }, [countErro, errors.email, errors.password, reset]);

  const inputError = {
    borderColor: '#FF0000',
    background: '#fff'
  }; 

  async function handleLogin(e) {
    // modeDev && console.log(e);
    setLoading(true);
    const email = e.email;
    const password = e.password;
    try {
      const response = await Axios.post(app + 'login', { email, password });
      // modeDev && console.log(response);

      const msgError = response.data?.mensagem;
      const status = response.data?.status;

      if (status === 'false') {
        toaster.danger(msgError);
        if (msgError === 'Usuário e/ou senha inválido(s)') setCountErro(countErro + 1)
        setLoading(false);
      }
      else {

        const perfil_logins = response.data.perfil_logins.data[0];
        let nome_perfil_login = perfil_logins.nome;
        
        const loja = response.data?.perfil_login?.loja || '';
        const loja_id = loja[0] ? loja[0].id : '';
        //AsyncStorage.setItem('token', response.data.token);      
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('usuario_id', response.data.user.id);
        localStorage.setItem('nome_usuario', response.data.user.name);
        localStorage.setItem('usuario_email', response.data.user.email);
        localStorage.setItem('perfil_login', nome_perfil_login === 'Pós-venda' ? 'Backoffice' : nome_perfil_login);
        localStorage.setItem('perfil_id', perfil_logins.id);
        localStorage.setItem('loja_id', loja_id);
        console.log("nome_perfil_login = " + nome_perfil_login);
        setLoading(false);
        if (nome_perfil_login === "Pós-venda"  || nome_perfil_login === "Núcleo") {
          localStorage.removeItem('filtro_status');
          localStorage.removeItem('filtro_gerente');
          localStorage.removeItem('page_number');
          localStorage.removeItem('filtro_endereco');
          localStorage.removeItem('ordenacao');
          localStorage.setItem('filtro_responsavel', response.data.user.id);
          if (nome_perfil_login === "Coordenadora de Pós-Negociação") history.push('/negociacoes');
          else history.push(nome_perfil_login === "Pós-venda" ? '/negociacoes' : '/nucleo');
        }
        else if (nome_perfil_login === "Coordenadora de Pós-Negociação") {
          history.push('/coordenacao-posvenda');
        }
        // else if (nome_perfil_login === "Gerente" || nome_perfil_login === "Gerente Geral") {
        //   history.push('/vendas');
        // }
        else if (nome_perfil_login === "Diretor Comercial") {
          history.push('/comercial');
        }
        else if (nome_perfil_login === "Admin") {
          history.push('/painel-admin');
        }
        else if (nome_perfil_login === "Apoio") {
          history.push('/apoio');
        }
        else if (nome_perfil_login === "Financeiro") {
          history.push('/financeiro');
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handlePass = (e) => {
    const value = e.target.value;
    localStorage.setItem('usuario_email', value);
  };

  return (
    <Pane >
      <CornerDialog
        title="Você não está lembrando sua senha?"
        width={512}
        appearance="minimal"
        isShown={isShownCornerErro}
        onCloseComplete={() => setIsShownCornerErro(false)}
        hasFooter={false}
      >
        Reparamos que você já fez diversas tentativas de Entrar,
        mas algum dado está incorreto. Deseja redefinir a sua senha?
        <Button
          marginLeft={310}
          marginTop={20}
          onClick={() => history.push('./login-help')}
        >
          Redefinir senha
        </Button>
      </CornerDialog>
      <div className="logon-container">
        <section className="form">

          <form onSubmit={handleSubmit((e) => handleLogin(e))}>
            <div className='center'>
              <img src={ImageWelcome} alt="Boas-vindas!" />
            </div>
            <h1>Boas-vindas!</h1>

            <TextInputField
              label="E-mail*"
              id='email'
              disabled={loading}
              description=""
              placeholder="Seu e-mail aqui"
              style={errors.email ? inputError : {}}
              {...register("email", {
                required: true,
                onChange: (e) => handlePass(e)
              })}
            />
            <div className='password'>
              {!navegadorEdg ?
                showPass
                  ? <EyeOffIcon className='passwordEye' onClick={() => setShowPass(!showPass)} />
                  : <EyeOpenIcon className='passwordEye' onClick={() => setShowPass(!showPass)} />
                : ""
              }
              <TextInputField
                label="Senha*"
                id='password'
                disabled={loading}
                placeholder="Sua senha aqui"
                type={showPass ? 'text' : 'password'}
                style={errors.password ? inputError : {}}
                {...register("password", {
                  required: true,
                })}
              />
            </div>
            {loading
              ?
              <Lottie
                options={defaultOptions}
                height={40}
                width={100}
              />
              :
              <div className='center'>
                <a href='./login-help' id='forgot-password'>Esqueci minha senha</a>
              </div>
            }
            <Button width="100%" marginTop={loading ? 15 : 40} appearance="primary" disabled={loading}>
              {loading && <Spinner size={24} style={{ marginRight: 20 }} />}
              Entrar &emsp;<ArrowRightIcon color='white' size={12} />
            </Button>

            {/* <Link className="back-link" to="/register">
              <FiLogIn size={16} color="#3498db" />
              Não tenho cadastro
            </Link> */}
          </form>
        </section>
      </div>
    </Pane>
  );
}