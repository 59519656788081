import React from 'react';
import {Pane, TextareaField, Label, ErrorIcon, TickCircleIcon} from 'evergreen-ui';

export default function RevisaoPosVenda(props){    
    return(
        <Pane style={{marginTop: props.tab === 'vendedor_comprador' ? '90px' : '50px'}}>
            <Label
                style={{
                    color: '#474D66',
                    fontFamily: 'Montserrat',
                    fontWeight: 600,
                    fontSize: '18px',
                }}
            >
                {
                    props.situacao === 1
                    ?
                    <TickCircleIcon className="icon-component-tick-revisao"/>
                    :
                    <ErrorIcon className="icon-component-revisao"/>
                } A venda foi devolvida e precisa de correções para voltar ao pós-venda 
            </Label>
            <TextareaField
                description=""
                defaultValue={props.mensagem}
                disabled={true}
            />
        </Pane>
    )
}