import React, { useState, useContext } from 'react';
import { Dialog, SelectField, Textarea, Label } from 'evergreen-ui';
import Axios from 'axios'
import Serve from '../../../../app/serve';
import NegociacaoContext from '../../../../context/NegociacaoContext';
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";

export default function DialogSolicitarNucleo({ refreshTable }) {
    const history = useHistory();
    const app = Serve();
    const [token] = useState(localStorage.getItem('token'));
    const usuario_id = localStorage.getItem('usuario_id');

    const disableStyle = { background: '#F4F6FA', color: '#8F95B2', border: '1px solid #E6E8F0' };


    const {
        loading,
        setLoading,
        listaDetalheServico,
        listaTipoServico,
        processoId,
        tablePaged,
        isShownNucleo, setIsShownNucleo,
        setIdSolicitacaoNucleo,
        setIsShownFeedBackNucleo,
    } = useContext(NegociacaoContext);

    const processSelect = tablePaged.find((process) => process.id === processoId);
    const processoModal = processSelect?.logradouro + ', ' + processSelect?.numero + (processSelect?.unidade || '') + ' - ' + processSelect?.bairro;

    const { register, handleSubmit, reset, watch, setValue, formState: { errors } } = useForm();
    // console.log(errors);

    const [listaDetalhesServicoSelecionada, setListaDetalhesServicoSelecionada] = useState([]);

    const handleSelectServicos = (e) => {
        let tipo_servico = e.target.value;        
        let detalheServico = listaDetalheServico.filter(detalhe => detalhe.tipo_servico.id === parseInt(tipo_servico));
        setValue("tipoServico", tipo_servico);
        setListaDetalhesServicoSelecionada(detalheServico);
    };

    const handleSelectServicoDetalhado = (e) => {
        setValue("servicoDetalhado", e.target.value)
    };

    const handleCloseComplete = (e) => {
        setIsShownNucleo(false);
        reset();
    };

    const handleConfirm = async (e) => {
        setLoading(true);
        let dados = {
            servicoDetalhado: e.servicoDetalhado,
            observacao: e.observacao,
            processo_id: processoId,
            responsavel_requisicao: usuario_id
        };
        await Axios.post(app + 'salvar_solicitacoes_nucleo', dados, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {
            // console.log(res);
            if (res.data.status && res.data.status === (401 || 498)) {
                localStorage.clear();
                history.push('/');
            } else {
                setIdSolicitacaoNucleo(res.solicitacao_id);
                setIsShownNucleo(false);  
                setIsShownFeedBackNucleo(true);
            }
        }).catch(function (error) {
            console.log(error);
        }).then(() => {
            setLoading(false);
        })
    };

    return (
        <Dialog
            title={<h3 style={{ fontWeight: '600' }}>Deseja solicitar serviço ao Núcleo?</h3>}
            isShown={isShownNucleo}
            onCloseComplete={(e) => handleCloseComplete(e)}
            isConfirmLoading={loading}
            isCancelLoading={loading}
            width={600}
            confirmLabel="Enviar solicitação ➜"
            cancelLabel="Voltar"
            onConfirm={handleSubmit((e, close) => handleConfirm(e, close))}
        >
            {/*<div className="dialog-content dialog-content-rua">{processoModal}</div>*/}
            <div className="dialog-content dialog-content-rua" style={{ margin: '30px 0 20px 0' }}>{processoModal}</div>
            <div className="dialog-content dialog-content-info">
                Selecione o tipo de serviço para encaminharmos ao Núcleo. <br /> Se necessário, escreva uma observação sobre o Tipo de Serviço soliticado.
            </div>
            <div className="dialog-content dialog-content-info"></div>

            <div className="form-dialog">
                <div className="row-select-servicos">
                    <div className='select-servicos'>
                        <SelectField
                            label="Tipo de serviço*"
                            className='bgWhite'
                            width={240}
                            {...register('tipoServico', {
                                required: true,
                                onChange: (e) => handleSelectServicos(e)
                            })}
                            validationMessage={errors.tipoServico && 'Campo obrigatório'}
                        >
                            <option value="" hidden>Selecione</option>
                            {Object.keys(listaTipoServico).map(key =>
                                <option value={listaTipoServico[key].id}>{listaTipoServico[key].nome}</option>
                            )}
                        </SelectField>
                    </div>

                    <div className='select-servicos'>
                        <SelectField
                            label="Serviço detalhado*"
                            width={240}
                            className="bgWhite"
                            disabled={!watch('tipoServico')}
                            style={!watch('tipoServico') ? disableStyle : { background: '#fff', color: '#696F8C' }}
                            {...register('servicoDetalhado', {
                                required: true,
                                onChange: (e) => handleSelectServicoDetalhado(e)
                            })}
                            validationMessage={
                                !watch('tipoServico')
                                    ? <span></span>
                                    : errors.servicoDetalhado && 'Campo obrigatório'
                            }
                        >
                            <option value="" hidden>Selecione</option>
                            {Object.keys(listaDetalhesServicoSelecionada).map(key =>
                                <option value={listaDetalhesServicoSelecionada[key].id}>{listaDetalhesServicoSelecionada[key].nome}</option>
                            )}
                        </SelectField>
                    </div>
                </div>

                <div className="row-nucleo-observacao">
                    <Label>Observações</Label>
                    <Textarea
                        name="textarea-1"
                        placeholder="Exemplo: lorem ipsum dollor..."
                        {...register('observacao')}
                    />
                </div>
            </div>
        </Dialog>
    )
}