import Axios from 'axios';
import Serve from '../app/serve';

async function removeItemChecklist(checklist_id, processo_id) {
  const token = localStorage.getItem('token');
  const app = Serve();
  let data;  

  await Axios.post(app + 'deletar_checklist', {
    checklist_id,
    processo_id
  }, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then(res => {
      if (res.data.status && res.data.status === (401 || 498)) {
        localStorage.clear();
      } else {
        data = res.data.mensagem_checklist;
      }
    })
    .catch(function (error) {
      console.log(error);
    })

  return data;
};

export default removeItemChecklist;