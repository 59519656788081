import React, { useContext } from 'react';
import {
    Pane,
    Table,
    Button,
    InfoSignIcon,
    Avatar,
    Combobox,
    Select,
    Pagination,
    FilterIcon,
    Tooltip,
} from 'evergreen-ui';

import Lottie from 'react-lottie';

//Context
import DirecaoContext from '../../../../context/DirecaoContext';

//Component
import SideSheetRecibo from '../SideSheetView';
import ShowDocument from '../../../../app/showDocument';

export default function VendasEnviadas() {

    const {
        loading,
        defaultOptions,

        // SIDESHEET
        setDadosVenda,
        setIsShowSide,

        // FILTRO
        filtro,
        quantFiltro,

        // LISTAS SELECT
        listGerentes,
        listLojas,
        listStatus,
        listResponsaveis,
        listEnderecos,

        // REFRESH API TABLE
        getProcessosEntregues,
        handleClearFilter,

        // ITEMS TABLE
        processosEntregues,

    } = useContext(DirecaoContext);

    const token = localStorage.getItem('token');

    // Filtro de Combo
    const handleCombobox = (e, campo) => {
        if (e) {
            localStorage.setItem('filtro_' + campo, JSON.stringify(e));
            filtro[campo] = e;
            getProcessosEntregues(1);
        }
    };

    const handleSelect = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        localStorage.setItem('filtro_' + name, value);
        filtro[name] = value;
        getProcessosEntregues(1);
    };

    const handleSideSheet = (e, recibo) => {
        setDadosVenda(recibo);
        // setDadosReciboEnviado(recibo);
        setIsShowSide(true);
    };

    return (
        <Pane>
            <React.Fragment>
                <Pane>
                    <div className='flex space-between pb10'>
                        <div className="flex gap20 end">
                            <span>Filtrar por: </span>
                            <div>
                                <Combobox
                                    openOnFocus
                                    name='endereco_entregue'
                                    items={listEnderecos.entregues}
                                    selectedItem={filtro.endereco_entregue}
                                    itemToString={item => (item ? item.endereco : '')}
                                    placeholder="🔍︎ Endereço"
                                    onChange={(e) => handleCombobox(e, 'endereco_entregue')}
                                    width={250}
                                    id="filtro-endereco"
                                    className="filtro-select color-select-filter"
                                />
                            </div>
                            <div>
                                <Combobox
                                    openOnFocus
                                    name='gerente_entregue'
                                    items={listGerentes}
                                    selectedItem={filtro.gerente_entregue}
                                    itemToString={item => (item ? item.name : '')}
                                    placeholder="🔍︎ Gerente"
                                    onChange={(e) => handleCombobox(e, 'gerente_entregue')}
                                    id="filtro-gerente"
                                    className="filtro-select color-select-filter"
                                />
                            </div>
                            <div>
                                <Select
                                    label=""
                                    name='loja_entregue'
                                    value={filtro.loja_entregue}
                                    onChange={(e) => handleSelect(e)}
                                    id="filtro-loja"
                                    className={filtro.loja_entregue === "" ? "filtro-select bgWhite color-select-filter-hidden" : "filtro-select bgWhite color-select-filter"}
                                >
                                    <option value="" hidden selected >Loja</option>
                                    {listLojas.map(valor =>
                                        <option key={valor.id} value={valor.id}>{valor.nome}</option>
                                    )}
                                </Select>
                            </div>
                            <div>
                                <Select
                                    label=""
                                    name='status'
                                    value={filtro.status}
                                    onChange={(e) => handleSelect(e)}
                                    id="filtro-status"
                                    className={filtro.status === "" ? "filtro-select bgWhite color-select-filter-hidden" : "filtro-select bgWhite color-select-filter"}
                                //className="filtro-select color-select-filter"
                                >
                                    <option value="" hidden selected >Status</option>
                                    {listStatus.map(valor =>
                                        <option key={valor.id} value={valor.id}>{valor.status}</option>
                                    )}
                                </Select>
                            </div>
                            <div>
                                <Select
                                    label=""
                                    name='responsavel'
                                    value={filtro.responsavel}
                                    onChange={(e) => handleSelect(e)}
                                    id="filtro-responsavel"
                                    className={filtro.responsavel === 0 ? "filtro-select bgWhite color-select-filter-hidden" : "filtro-select bgWhite color-select-filter"}
                                >
                                    <option value="" selected hidden>Pós-venda</option>
                                    {listResponsaveis.map(responsavel =>
                                        <option key={responsavel.id} value={responsavel.id}>{responsavel.name}</option>
                                    )}
                                </Select>
                            </div>
                        </div>
                        
                            <Pagination
                                page={processosEntregues.page}
                                totalPages={processosEntregues.totalPaginas}
                                onPageChange={(e) => !loading.entregues ? getProcessosEntregues(e) : ''}
                                onNextPage={() => getProcessosEntregues(processosEntregues.page + 1)}
                                onPreviousPage={() => getProcessosEntregues(processosEntregues.page - 1)}
                            />

                    </div>

                    {quantFiltro.entregues > 0 || localStorage.getItem('filtro_gerente_entregue') ?
                        <div className="filter-info">
                            <p>
                                <FilterIcon className="icon-filter" />
                                {quantFiltro.entregues} {quantFiltro.entregues ? 'filtro aplicado. ' : 'filtros aplicados. '}
                                {processosEntregues.items.length} / {processosEntregues.totalItemsFilter} {processosEntregues.totalItemsFilter === 1 ? 'processo visível' : 'processos visíveis'}.
                                <span onClick={(e) => handleClearFilter('entregues')}> Limpar filtro</span>
                            </p>
                        </div>

                        : ''
                    }
                </Pane>

                <Table.Body className="table" style={{ overflow: 'hidden' }}>
                    <Table.Head>
                        <Table.TextCell flexBasis={70} flexShrink={0} flexGrow={0} className="header-title">
                            Gerente
                        </Table.TextCell>
                        <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0} className="header-title">
                            Loja
                        </Table.TextCell>
                        <Table.TextCell flexBasis={300} flexShrink={0} flexGrow={0} className="header-title">
                            Endereço
                        </Table.TextCell>
                        <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} className="header-title">
                            Data de assinatura
                        </Table.TextCell>
                        <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="header-title">
                            Forma de pagamento
                        </Table.TextCell>
                        <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} className="header-title">
                            Arquivo do recibo
                        </Table.TextCell>
                        <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} className="header-title">
                            Status
                        </Table.TextCell>
                        <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="header-title">
                            Pós-Venda
                        </Table.TextCell>
                        <Table.TextCell flexBasis={130} flexShrink={0} flexGrow={0} className="header-title">
                        </Table.TextCell>
                    </Table.Head>

                    {loading.entregues ?
                        <div className='backgroundLoadingDocs'>
                            <Lottie options={defaultOptions} height={200} width={400} />
                        </div>

                        :
                        <Table.Body style={{ backgroundColor: 'white' }}>
                            {processosEntregues.items.length > 0
                                ?
                                <div>
                                    {processosEntregues.items.map(recibo => <Table.Row key={recibo.id}>
                                        {/*Avatar*/}
                                        <Table.TextCell flexBasis={70} flexShrink={0} flexGrow={0} className="content">
                                            <Avatar hashValue={recibo.nome_criador_processo} name={recibo.nome_criador_processo} size={40} marginRight={10} />
                                        </Table.TextCell>

                                        <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0} className="content">
                                            {recibo.loja !== null ? recibo.loja.nome : 'Sem loja'}
                                        </Table.TextCell>

                                        <Table.TextCell flexBasis={300} flexShrink={0} flexGrow={0} className="content-endereco content-click" onClick={(e) => handleSideSheet(e, recibo)}>
                                            {recibo.logradouro + ', ' + recibo.numero + (recibo.unidade !== null ? " / " + recibo.unidade : "")}
                                        </Table.TextCell>

                                        <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} className="content-endereco">
                                            {recibo.informacao !== null ? recibo.informacao.data_assinatura : ''}
                                        </Table.TextCell>

                                        <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="content-endereco">
                                            {
                                                recibo.informacao !== null
                                                    ?
                                                    recibo.informacao.forma_pagamento === '2,3' ? 'Financiamento, FGTS' : recibo.informacao.forma_pagamento_nome

                                                    : ''}
                                        </Table.TextCell>

                                        <Table.TextCell
                                            flexBasis={150} flexShrink={0} flexGrow={0} className="content-endereco" onClick={(e) => ShowDocument(recibo.informacao.imovel_id, "recibo", token)}>
                                            <div className="doc-name">{recibo.informacao.recibo}</div>
                                        </Table.TextCell>

                                        <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} >
                                            <div className="content-tag">
                                                <span className="circleIcon"></span>
                                                {listStatus.find((status) => (
                                                    recibo.status_processo_nome === status.status
                                                ))
                                                    ? recibo.status_processo_nome
                                                    : <Tooltip content={recibo.status_processo_nome}><span className='colorBlue'>Entrada</span></Tooltip>
                                                }
                                            </div>
                                        </Table.TextCell>

                                        <Table.TextCell
                                            flexBasis={130} flexShrink={0} flexGrow={0} className="content-endereco"
                                        >
                                            {listStatus.find((status) => (
                                                recibo.status_processo_nome === status.status
                                            ))
                                                ? recibo.nome_responsavel
                                                : "Franqueado"
                                            }
                                        </Table.TextCell>

                                        <Table.TextCell
                                            flexBasis={300} flexShrink={0} flexGrow={0}>
                                            <Button
                                                title="Ver detalhes"
                                                marginY={8}
                                                marginRight={0}
                                                appearance="primary"
                                                padding={0}
                                                onClick={(e) => handleSideSheet(e, recibo)}
                                            >
                                                <InfoSignIcon
                                                    color="white"
                                                    size={11}
                                                />
                                            </Button>
                                        </Table.TextCell>
                                    </Table.Row>
                                    )}
                                </div>

                                : !loading.entregues &&
                                <Table.Row key={1}>
                                    <div className="mensagem-processo">
                                        Acompanhe por aqui todas as vendas entregues.
                                    </div>
                                </Table.Row>
                            }
                        </Table.Body>
                    }
                </Table.Body>

                <SideSheetRecibo />
            </React.Fragment>
        </Pane>
    )
}