import React, { useContext, useState } from 'react';
import { Pane, Dialog, Button, ShareIcon } from 'evergreen-ui'

import Axios from 'axios';
import Serve from '../../../../app/serve';
import { useHistory } from 'react-router-dom';

import PosNegociacaoContext from '../../../../context/PosNegociacaoContext';

export default function SendToBackOffice() {
    const history = useHistory();
    const [token] = useState(localStorage.getItem('token'));
    const [gerente_id] = useState(localStorage.getItem('usuario_id'));
    const app = Serve();
    //const [loading, setLoading] = useState(false);

    const {
        isShowSendBackOffice, setIsShowSendBackOffice,
        setIsShowFeedBack, dadosRecibo,
        setItemAdicionado,
        setItemEnviado,
        loading, setLoading
    } = useContext(PosNegociacaoContext);

    console.log(dadosRecibo);
    console.log(loading)

    const resetState = (e) => {
        setIsShowSendBackOffice(false)
    }

    const confirmSendBackoffice = async (e, dadosRecibo) => {
        console.log(dadosRecibo);
        console.log("aqui esta a brincadeira");
        let imovel_id = dadosRecibo.id;
        let processo_id = dadosRecibo.processo_id;

        setLoading(false);

        Axios.post(app + 'vincular_responsavel_processo', {
            'imovel_id': imovel_id,
            'processo_id': processo_id,
            'usuario_id': gerente_id
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        })
            .then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    //console.log(res);
                    //alert(res.data.mensagem_processo);
                    //let mensagem = res.data.mensagem_processo;
                    //Se tiver resposta do servidor que os dados foram editados 

                    recarregarRecibosAbertos();
                    recarregarRecibosEnviados();

                    setIsShowSendBackOffice(false);
                    setIsShowFeedBack(true);
                }

            })
            .catch(function (error) {
                // handle error
                if (error.response) {
                    let resposta = error.response.data;

                    let erros = '';
                    if (resposta.errors) {
                        for (let [key, value] of Object.entries(resposta.errors)) {
                            erros += value + "\n";
                        }
                    }

                    if (resposta.error) {
                        erros = resposta.error;
                        //console.log(resposta.error);
                    }

                    alert(erros);

                    //console.log(error.response.status);
                    //console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                //console.log(error.config);
            })

        setTimeout(function () { setLoading(true); }, 1000);
    }

    const recarregarRecibosAbertos = async () => {
        await Axios.post(app + 'retorno_processos_criados_gerente', {
            "usuario_id": gerente_id,
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then(res => {
                //console.log("asdfad");
                if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    console.log(res);
                    //console.log("erro sei la por que");
                    const processos = res.data.adicionados;
                    //console.log(processos);
                    setItemAdicionado(processos);
                }

            })
    }

    const recarregarRecibosEnviados = async () => {
        await Axios.post(app + 'retorno_processos_criados_gerente', {
            "usuario_id": gerente_id,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        })
            .then(res => {
                //console.log("asdfad");
                if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    console.log(res);
                    //console.log("erro sei la por que");
                    const processos = res.data.enviados;
                    //console.log(processos);
                    setItemEnviado(processos);
                }

            })
    }

    return (
        <Pane>
            {/*Enviar o recibo de sinal para o backoffice*/}
            <Dialog
                isShown={isShowSendBackOffice}
                title="Tem certeza que deseja prosseguir?"
                onCloseComplete={() => setIsShowSendBackOffice(false)}
                onConfirm={(e) => resetState(e)}
                hasFooter={false}
                containerProps={{
                    className: "dialog-confirm",
                }}
                width={590}
            >
                <div className="content-dialog">
                    Depois do envio para o Backoffice, as informações não poderão sofrer alterações. Caso tenha certeza que está tudo preenchido corretamente, clique em Enviar para Backoffice.
                </div>

                <div style={{ display: 'flex', justifyContent: 'end', padding: '20px 0' }}>
                    <Button
                        marginTop={16}
                        onClick={() => setIsShowSendBackOffice(false)}
                        style={{ color: '#696F8C', borderColor: '#D8DAE5', fontWeight: 500 }}
                    >
                        Voltar
                    </Button>

                    <Button
                        marginLeft={12}
                        marginTop={16}
                        appearance="primary"
                        onClick={(e) => confirmSendBackoffice(e, dadosRecibo)}
                    >
                        <ShareIcon
                            color="white"
                            style={{ width: 11, height: 11, marginRight: 10, fontWeight: 600 }}
                        />
                        Enviar para o Backoffice
                    </Button>
                </div>
            </Dialog>
        </Pane>
    )
}