import React, {useContext} from 'react';
import { Pane, TextInputField, Badge, DocumentIcon, LinkIcon } from 'evergreen-ui';
import './index.css';
import PosNegociacaoContext from '../../../../context/PosNegociacaoContext';
//import { useHistory } from 'react-router-dom';

export default function Imovel(props) {
    //const history = useHistory();
    //const [token] = useState(localStorage.getItem('token'));
    //const [responsavel_id] = useState(localStorage.getItem('usuario_id'));
    const idProcesso = props.idProcesso;

    const {
        dadosReciboEnviado,
    } = useContext(PosNegociacaoContext);

    console.log(dadosReciboEnviado);

    return (
        <div>
            <div className="formGroup formAdd">
                <form id="formGroup">
                    <Pane>
                        <input id="imovel_id-1" type="hidden" value={dadosReciboEnviado.idProcesso !== undefined ? dadosReciboEnviado.idProcesso.id : ''} />
                        <input id="processo_id-1" type="hidden" value={idProcesso !== undefined ? idProcesso : ''} />
                        <div className="rowForm">
                            <TextInputField
                                label="RGI"
                                description=""
                                placeholder="O RGI possui 11 digitos"
                                defaultValue={dadosReciboEnviado.rgi}
                                id={"rgi-1"}
                                onChange={(e) => (e)}
                                readOnly
                                disabled
                                style={{ color: '#8F95B2' }}
                            />

                            <TextInputField width={95}
                                label="CEP"
                                description=""
                                placeholder="00000-000"
                                defaultValue={dadosReciboEnviado.cep}
                                id={"cep-1"}
                                maxLength="9"
                                onChange={(e) => (e)}
                                readOnly
                                disabled
                                style={{ color: '#8F95B2' }}
                            />
                        </div>

                        <div className="rowForm">
                            <TextInputField width={280}
                                label="Logradouro"
                                description=""
                                placeholder="Logradouro pode ser Ruas, Avenidas ..."
                                defaultValue={dadosReciboEnviado.logradouro}
                                id={"logradouro-1"}
                                readOnly
                                disabled
                                style={{ color: '#8F95B2' }}
                            />

                            <TextInputField width={110}
                                label="Número"
                                description=""
                                placeholder="Nº do imóvel"
                                defaultValue={dadosReciboEnviado.numero}
                                id={"numero-1"}
                                readOnly
                                disabled
                                style={{ color: '#8F95B2' }}
                            />
                        </div>

                        <div className="rowForm">
                            <TextInputField width={280}
                                label="Unidade"
                                description=""
                                placeholder="Informações de lote, bloco ou condominio"
                                defaultValue={dadosReciboEnviado.unidade}
                                id={"unidade-1"}
                                readOnly
                                disabled
                                style={{ color: '#8F95B2' }}
                            />

                            <TextInputField width={220}
                                label="Complemento"
                                description=""
                                placeholder="Informações complementares"
                                defaultValue={dadosReciboEnviado.complemento}
                                id={"complemento-1"}
                                readOnly
                                disabled
                                style={{ color: '#8F95B2' }}
                            />
                        </div>
                        
                        <div className="rowForm">
                            <TextInputField
                                label="Bairro"
                                description=""
                                placeholder=""
                                defaultValue={dadosReciboEnviado.bairro}
                                id={"bairro-1"}
                                readOnly
                                disabled
                                style={{ color: '#8F95B2' }}
                            />
                        </div>

                        <div className="row-documento">
                            <h2>Documentos do Imóvel 
                                {dadosReciboEnviado.documentos.length === 0
                                    ? null
                                    : <Badge
                                        display="inline-flex"
                                        margin={8}
                                        style={{ 'background-color': '#D6E0FF', 'color': '#2952CC' }}
                                    >
                                        {dadosReciboEnviado.documentos.length}
                                </Badge >}
                            </h2>

                            {dadosReciboEnviado.documentos.length !== 0
                                ?
                                    dadosReciboEnviado.documentos.map(documentos =>(
                                    <div className="doc-name">
                                        <DocumentIcon
                                            size={15}
                                            style={{ 'margin-right': '0px', 'fill': '#8F95B2' }}
                                        />
                                        
                                        <span className="name-ref">{documentos.tipo_documento.nome}:</span>

                                        <a className="link-ref" href={documentos.link} target="_blank" rel="noreferrer">
                                            {documentos.link} <LinkIcon className="iconLink" width={17} />
                                        </a>                                           
                                    </div>
                                ))

                                : <span className="mensagem-form">O Backoffice ainda não adicionou nenhum documento.</span>
                            }
                        </div>
                    </Pane>
                </form>
            </div>
        </div>
    )
}