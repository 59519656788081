import Axios from 'axios';
import Serve from '../app/serve';

async function getProcessById(processo_id) {
  const token = localStorage.getItem('token');
  const app = Serve();
  let data;

  await Axios.post(app + 'localizar_processo', {
    processo_id
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }).then(res => {
    console.log(res);
    if (!res) {
      data = false;
    }
    if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
      data = false;
    } else {
      data = res.data.processo;
      data.solicitacoesNucleo = res.data.solicitação_nucleo.data;
      data.lista_status = res.data.lista_status;
      console.log("localizar_processo Data: ", data);
      data.imovel.planilha_antiga = data.imovel?.comissao?.documentos?.data.filter((documento) => documento.identifica_documento === "comissão").length > 0
          ? true : ''      
    }
  })

  return data;
};

export default getProcessById;