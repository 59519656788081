import React, { useContext } from 'react';
import {
    Pane,
    Dialog,
} from 'evergreen-ui';

//Context
import VendasContext from '../../../../context/VendasContext';

//Conteudo
import UploadRecibo from './components/UploadRecibo';

//Image
import Image from '../../../../images/Group_99.png';

export default function FormUploadReciboVenda() {

    //Estados Laudemio e Formas de Pagamento estão sendo executados em VendasAdicionadas em formVenda;

    const {
        isShowFormUploadRecibo, setIsShowFormUploadRecibo,
        setValidateUploadReciboDataAssinatura,
        setValidateUploadReciboDocAssinado,
        setDocAssinado,
    } = useContext(VendasContext);

    const closeUpload = (e) => {
        setIsShowFormUploadRecibo(false);
        setValidateUploadReciboDataAssinatura(false);
        setValidateUploadReciboDocAssinado(false);
        setDocAssinado([]);
    };

    return (
        <Pane>
            <Dialog
                width={808}
                isShown={isShowFormUploadRecibo}
                title=''
                onCloseComplete={(e) => closeUpload(e)}
                hasFooter={false}
                preventBodyScrolling={true}
                shouldCloseOnOverlayClick={false}
                containerProps={{
                    className: 'form-dialog-props'
                }}
            >
                <div className="form-dialog">
                    <img className="ilustra-dialog" src={Image} alt="upload-recibo-sinal" />
                    <h4 style={{ marginTop: '15px' }}>Upload do Recibo de Sinal (assinado)</h4>
                    <p className="descritption" style={{ marginTop: '15px' }}>Com o Recibo de Sinal revisado e assinado por todas as partes, realize o<br /> Upload do arquivo (em .doc, .docx ou .pdf). O recibo segue o modelo de<br /> rascunho gerado na etapa anterior.</p>
                </div>
                <UploadRecibo />
            </Dialog>
        </Pane>
    );
};