import React, {useContext} from 'react'
import {Pane, Button, HelpIcon} from 'evergreen-ui';
import Whatsapp from '../../../../../images/whatsapp.svg';
import DialogAjuda from './DialogAjuda';
import AcessoTemporarioContext from '../../../../../context/AcessoTemporarioContext';

export default function Ajuda() {

    const {
        /*isShown,*/ setIsShown,
        responsavelProcesso
    } = useContext(AcessoTemporarioContext);

    //const ddiResponsavel = responsavelProcesso[0].ddi;
    const telefoneResponsavel = responsavelProcesso[0].telefone;
    const nomeResponsavel = responsavelProcesso[0].name;
    const linkWhatsapp = 'https://api.whatsapp.com/send?phone=55' + telefoneResponsavel + '&text=Olá ' + nomeResponsavel + ', tudo bem? Gostaria de tirar uma dúvida sobre minha negociação.';

    return (
        <Pane className="coll-ajuda">
            <div className='header'> 
                <div className="title">
                    <span>Tem alguma dúvida?</span>
                </div>
                <div className="content">
                    <a href={linkWhatsapp} style={{width: '100%'}} target="_blank" rel="noreferrer">
                        <Button 
                            className="btn-contato btn-dna-orange"
                        >
                            <img src={Whatsapp} alt="whatsapp-icon" className="icon-whatsapp" /> Falar no WhatsApp
                        </Button>
                    </a>
                    
                    <Button className="btn-ajuda" onClick={() => setIsShown(true)}>
                        <HelpIcon className="icon-help"/> Acesse o FAQ
                    </Button>
                </div>
            </div>
            
            <DialogAjuda/>
        </Pane>
    )
}
