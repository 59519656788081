import React, { useContext, useState, useEffect } from 'react';
import 'react-phone-input-2/lib/bootstrap.css'
import { Pane, Spinner, Tab, Tablist, Table, Badge, Button, EditIcon } from 'evergreen-ui';
import ReturnDocumentsForView from '../../../../../../../../../../components/MultiViewDocs'

//Context
import VendasContext from '../../../../../../../../../../context/VendasContext';

export default function Compradores() {

    const [idComprador, setIdComprador] = useState('');
    const [token] = useState(localStorage.getItem('token'));

    const {
        loading,
        dadosVenda,
        //dadosReciboEnviado,
        setValidateMinutaCompradorGenero,
        setValidateMinutaCompradorEstadoCivil,
        setValidateMinutaCompradorRegimeCasamento,
        setCheckedMinutaCompradorUniaoEstavel,
        setMinutaCompradorEstadoCivil,
        setMinutaCompradorRegimeCasamento,
        setMinutaCompradorUniaoEstavel,
        setValidateMinutaCompradorConjuge,
        setPosicaoMinuta,
        setCountMenu,
        setIsShowFormMinuta,
        setIsShowEntregarVenda,
    } = useContext(VendasContext);

    let msgCampo = '----'

    //Tabs e PF/PF
    const idProcesso = dadosVenda.processo_id;
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [tabsCadastro, setTabsCadastro] = useState([])
    const [posicaoTabs, setPosicaoTabs] = useState([]);
    const [displayForm, setDisplayForm] = useState(false);
    const [checkedPessoaJuridica, setCheckedPessoaJuridica] = useState(false);
    const [tipoPessoaValues, setTipoPessoaValues] = useState([]);

    const [validarPF, setValidarPf] = useState([]);
    const [validarPJ, setValidarPJ] = useState([]);

    const [compradoresArray, setCompradoresArray] = useState([]);

    const [estadoCivilValues, setEstadoCivilValues] = useState([]);
    const [idsCompradores, setIdsCompradores] = useState([]);
    const [generoUsuario, setGeneroUsuario] = useState([]);
    const [valorGenero, setValorGenero] = useState('');
    const [telNumberValue, setTelNumberValue] = useState([]);
    const [countryCode, setCountryCode] = useState([]);
    const [sinalizarValidacao, setSinalizarValidacao] = useState([]);
    const [disableButton, setDisableButton] = useState([]);
    //array de endereço
    const [arrayEndereco, setArrayEndereco] = useState([]);

    useEffect(() => {
        const returnComprador = async () => {

            if (dadosVenda.compradores['data'].length > 0) {
                setDisplayForm(true);
                setPosicaoTabs([...posicaoTabs, 1]);
                //setPrimeiroDocumento(true);
                const tipoPessoa = dadosVenda.compradores['data'].map(usuario => ({ "usuario_id": usuario.id, "tipo_pessoa": usuario.tipo_pessoa == 1 ? true : false }));
                setTipoPessoaValues(tipoPessoa);

                const estadoCivilSalvo = dadosVenda.compradores['data'].map(usuario => ({ "estado_civil": usuario.estado_civil, "uniao_estavel": usuario.uniao_estavel, "regime_casamento": usuario.registro_casamento, "conjuge": usuario.conjuge }));
                setEstadoCivilValues(estadoCivilSalvo);

                const IdsUsuarios = dadosVenda.compradores['data'].map(usuario => ({ "usuario_id": usuario.id }));
                setIdsCompradores(IdsUsuarios);

                const generoSalvo = dadosVenda.compradores['data'].map(usuario => ({ "genero": usuario.genero }));
                setGeneroUsuario(generoSalvo);
                let validarGenero = generoSalvo.filter(usuario => usuario.genero === generoSalvo);

                dadosVenda.compradores['data'][0].genero !== null ? setValidateMinutaCompradorGenero(true) : setValidateMinutaCompradorGenero(false);
                setValorGenero(dadosVenda.compradores['data'][0].genero);

                const responsaveisPj = dadosVenda.compradores['data'].map(responsavel => ({ nome: responsavel.name, email: responsavel.email, cpf: responsavel.cpf_cnpj }));

                const telefoneSalvo = await dadosVenda.compradores['data'].map(usuario => ({ "telefone": usuario.telefone }));
                setTelNumberValue(telefoneSalvo);

                const countryCodeDDI = await dadosVenda.compradores['data'].map(usuario => ({ "ddi": usuario.ddi }));
                setCountryCode(countryCodeDDI);

                /*dadosVenda.compradores.data.map((comprador, index) => {
                    rgNumber.current[index] = comprador.rg;
                    rgExpedicao.current[index] = comprador.rg_expedido;
                    rgDataExpedicao.current[index] = comprador.data_rg_expedido;
                    comprador.rg !== '' && comprador.rg
                        ? disableButton[index] = false
                        : disableButton[index] = true;
                });*/

                var arrayFinal = [];
                let arrayValidarSInalizacao = [];
                //let arrayForm = [];

                dadosVenda.compradores['data'].forEach((element, key_compradores) => {

                    arrayFinal.push(key_compradores);
                    arrayValidarSInalizacao.push('ok');

                    //endereco
                    let addArrayEndereco = { cep: element.cep, bairro: element.bairro, logradouro: element.logradouro, numero: element.numero, unidade: element.unidade, complemento: element.complemento, cidade: element.cidade, estado: element.uf };
                    arrayEndereco.push(addArrayEndereco);
                    setArrayEndereco([...arrayEndereco]);


                });
                setTabsCadastro(arrayFinal);
                setSinalizarValidacao(arrayValidarSInalizacao);

                //verificarTipoComprador(0);
            }
            else {
                setDisplayForm(false);

                setTabsCadastro([1]);
                setDisableButton([true]);
                setTipoPessoaValues([...tipoPessoaValues, { usuario_id: "", tipo_pessoa: false }])
                setEstadoCivilValues([...estadoCivilValues, { estado_civil: "", uniao_estavel: "", regime_casamento: '0', conjuge: "" }])
                setIdsCompradores([...idsCompradores, { usuario_id: msgCampo }])
                setGeneroUsuario([...generoUsuario, { genero: msgCampo }]);
                setTelNumberValue([...telNumberValue, { telefone: msgCampo }]);
                setCountryCode([...countryCode, { ddi: msgCampo }]);

                setArrayEndereco([...arrayEndereco, { cep: msgCampo, bairro: msgCampo, logradouro: msgCampo, numero: msgCampo, unidade: msgCampo, complemento: msgCampo, cidade: msgCampo, estado: msgCampo }])

                //Pessoa Física e Júridica
                if (checkedPessoaJuridica === false) {
                    setCompradoresArray([...compradoresArray, { tipo_pessoa: 0, cpf_cnpj: msgCampo }]);

                    setValidarPf([...validarPF, {
                        cpf: false,
                        telefone: false,
                        genero: false,
                        nome: false,
                        nacionalidade: false,
                        nomeMae: false,
                        estadoCivil: false,
                        regimeCasamento: false,
                        uniaoEstavel: false,
                        conjuge: false,
                        cep: false,
                        numero: false,
                    }])

                    setValidarPJ([...validarPJ, {}]);
                }
                else {
                    setCompradoresArray([...compradoresArray, { tipo_pessoa: 1, cpf_cnpj: msgCampo }]);

                    setValidarPJ([...validarPJ, {
                        cnpj: false,
                        telefone: false,
                        razaoSocial: false,
                        nomeFantasia: false,
                        cep: false,
                        numero: false,
                    }])

                    setValidarPf([...validarPF, {}]);
                }
            }
        }
        returnComprador();
    }, []);

    const verificaGenero = async () => {
        let validarGenero = generoUsuario.filter(usuario => usuario.genero === '');
        validarGenero.length === 0 ? setValidateMinutaCompradorGenero(true) : setValidateMinutaCompradorGenero(false);
    }

    const validarEtapasEstadoCivil = async (e, index) => {
        setValidateMinutaCompradorEstadoCivil(true);
        setValidateMinutaCompradorRegimeCasamento(true);
        setValidateMinutaCompradorConjuge(true);

        let constValidarEstadoCivil = 0;
        let constValidarRegimeCasamento = 0;
        let constValidarConjuge = 0;

        estadoCivilValues.forEach((element, index_estado) => {
            if (element.estado_civil === '' || element.estado_civil === null || element.estado_civil === 0) {
                constValidarEstadoCivil += 1;
            }

            if (element.estado_civil === '1' && element.regime_casamento === '0') {
                constValidarRegimeCasamento += 1;
            }

            if ((element.estado_civil === '1' && element.conjuge === '') || (element.estado_civil === '1' && element.conjuge === null)) {
                constValidarConjuge += 1;
            }

            if ((element.estado_civil !== '1' && element.uniao_estavel === 'S' && element.conjuge === '') ||
                element.estado_civil !== '1' && element.uniao_estavel === 'S' && element.conjuge === null) {
                constValidarConjuge += 1;
            }
        });

        if (constValidarEstadoCivil > 0) {
            setValidateMinutaCompradorEstadoCivil(false);
        }

        if (constValidarRegimeCasamento > 0) {
            setValidateMinutaCompradorRegimeCasamento(false);
        }

        if (constValidarConjuge > 0) {
            setValidateMinutaCompradorConjuge(false);
        }
    }

    const handleOpenTab = async (index) => {

        verificaGenero();
        setValorGenero(generoUsuario[index].genero);

        setCheckedPessoaJuridica(tipoPessoaValues[index].tipo_pessoa);
        if (dadosVenda.compradores.data[index] !== undefined) {
            setIdComprador(dadosVenda.compradores.data[index].id);
        } else {
            setIdComprador('');
        }

        //retornar dados estado civil
        const valorEstadoCivil = estadoCivilValues[index]['estado_civil'];
        const valorUniaoEstavel = (estadoCivilValues[index]['uniao_estavel'] === 'S') ? true : false;

        setMinutaCompradorEstadoCivil(valorEstadoCivil);

        if (valorEstadoCivil === '1') {
            //Zera o checkbox de União Estável
            setCheckedMinutaCompradorUniaoEstavel(false);
            setMinutaCompradorUniaoEstavel('');
            setMinutaCompradorRegimeCasamento(estadoCivilValues[index]['regime_casamento']);
        } else {
            setMinutaCompradorRegimeCasamento('0');
            setCheckedMinutaCompradorUniaoEstavel(valorUniaoEstavel);
        }
        validarEtapasEstadoCivil();
    }

    const handleEditar = (e) => {
        setPosicaoMinuta(3);
        setCountMenu(4);
        setIsShowFormMinuta(true);
        setIsShowEntregarVenda(false)
    }

    return (
        <Pane>
            {
                dadosVenda.compradores.data.length === 0
                    ?
                    <Table.Row>
                        <Table.TextCell className="historicoStatus">
                            <Table.TextCell className="fontStatus">
                                <span>Nenhum Comprador foi cadastrado no momento.</span>
                            </Table.TextCell>
                        </Table.TextCell>
                    </Table.Row>

                    :
                    <>
                        <div className="form-dialog head-rank-user">
                            <Tablist marginBottom={16} flexBasis={240} marginRight={24} className="tab-form">
                                {tabsCadastro.map((tabCadastro, index) => (
                                    <Tab
                                        key={tabCadastro}
                                        id={"titulo_top_comprador_" + index}
                                        onSelect={() => setSelectedIndex(index)}
                                        isSelected={index === selectedIndex}
                                        aria-controls={`panel-${tabCadastro}`}
                                        data-tab={tabCadastro}
                                        onClick={() => handleOpenTab(index)}
                                    >

                                        {index < dadosVenda.compradores.data.length
                                            ? (dadosVenda.compradores.data[index].tipo_pessoa === 1)
                                                ? dadosVenda.compradores.data[index].nome_fantasia.slice(0, 10) + '...'
                                                : dadosVenda.compradores.data[index].name.slice(0, 10) + '...'
                                            : 'Comprador ' + tabCadastro}
                                    </Tab>
                                ))}
                            </Tablist>

                            <Pane>
                                <Badge color="blue" className="badge-tipo-pesso" style={{ marginTop: '0' }}>
                                    <span style={{ display: 'block', color: '#2952CC', fontWeight: 600 }}>
                                        {dadosVenda.compradores.data.length > 1 ? dadosVenda.compradores.data.length + ' Compradores' : dadosVenda.compradores.data.length + ' Comprador'}
                                    </span>
                                </Badge>
                            </Pane>
                        </div>
                        <div className="form-detalhes">
                            <div className="formGroup formAdd" style={{ padding: 0 }}>
                                {tabsCadastro.map((tabCadastro, index) => (
                                    <Pane
                                        key={tabCadastro}
                                        id={`panel-${tabCadastro}`}
                                        role="tabpanel"
                                        aria-labelledby={tabCadastro}
                                        aria-hidden={index !== selectedIndex}
                                        display={index === selectedIndex ? 'block' : 'none'}
                                        className="detadelhes-sidesheet"
                                    >
                                        <Pane>
                                            <div className='type-rank-user'>
                                                <Badge color="green" className="badge-tipo-pesso" style={{ marginTop: '0' }}>
                                                    <span>
                                                        {dadosVenda.compradores.data[index].tipo_pessoa === 1 ? 'Pessoa Jurídica' : 'Pessoa Física'}
                                                    </span>
                                                </Badge>

                                                <Button marginRight={0} onClick={(e) => handleEditar(e)} className="btn-editar">
                                                    <EditIcon color="#8F95B2" size={12} marginRight={8} /> Editar comprador
                                                </Button>
                                            </div>

                                            <React.Fragment>
                                                <h2 className="subtitle subtitle-table" style={{ marginTop: '20px' }}>
                                                    {dadosVenda.compradores.data[index].tipo_pessoa === 1 ? 'Dados da Empresa' : 'Dados do Comprador'}
                                                </h2>
                                            </React.Fragment>

                                            <Table.Row>
                                                <Table.TextCell className="historicoStatus" width={200}>
                                                    <Table.TextCell className="fontStatus">
                                                        <span>{dadosVenda.compradores.data[index].tipo_pessoa === 1 ? 'CNPJ' : 'CPF'}</span>
                                                    </Table.TextCell>
                                                    <Table.TextCell className="mensagemStyle">
                                                        <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                            {index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].cpf_cnpj : msgCampo}
                                                        </span>
                                                    </Table.TextCell>
                                                </Table.TextCell>

                                                <Table.TextCell className="historicoStatus" width={200}>
                                                    <Table.TextCell className="fontStatus">
                                                        <span>Celular</span>
                                                    </Table.TextCell>
                                                    <Table.TextCell className="mensagemStyle">
                                                        <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                            {index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].telefone : msgCampo}
                                                        </span>
                                                    </Table.TextCell>
                                                </Table.TextCell>

                                                {
                                                    dadosVenda.compradores.data[index].tipo_pessoa === 0 &&
                                                    <Table.TextCell className="historicoStatus">
                                                        <Table.TextCell className="fontStatus">
                                                            <span>E-mail</span>
                                                        </Table.TextCell>
                                                        <Table.TextCell className="mensagemStyle">
                                                            <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                {/*index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].email : msgCampo*/}
                                                                {dadosVenda.compradores.data[index].email === null ? msgCampo : dadosVenda.compradores.data[index].email}
                                                            </span>
                                                        </Table.TextCell>
                                                    </Table.TextCell>
                                                }
                                            </Table.Row>

                                            {
                                                dadosVenda.compradores.data[index].tipo_pessoa === 0
                                                    ?
                                                    <>
                                                        {/*PF*/}
                                                        <Table.Row>
                                                            <Table.TextCell className="historicoStatus" width={200}>
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Gênero</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {
                                                                            index < dadosVenda.compradores.data.length
                                                                                ? dadosVenda.compradores.data[index].genero === 'M' ? 'Masculino' : 'Feminino'
                                                                                : msgCampo
                                                                        }
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>

                                                            <Table.TextCell className="historicoStatus">
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Nome completo</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].name : msgCampo}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>
                                                        </Table.Row>

                                                        <Table.Row>
                                                            <Table.TextCell className="historicoStatus" width={200}>
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Data de nascimento</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {/*index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].data_nascimento : msgCampo*/}
                                                                        {dadosVenda.compradores.data[index].data_nascimento === "" ? msgCampo : dadosVenda.compradores.data[index].data_nascimento}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>

                                                            <Table.TextCell className="historicoStatus" width={200}>
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Nacionalidade</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].nacionalidade : msgCampo}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>

                                                            <Table.TextCell className="historicoStatus">
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Profissão</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].profissao : msgCampo}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>
                                                        </Table.Row>

                                                        <Table.Row>
                                                            <Table.TextCell className="historicoStatus" width={200}>
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>R.G</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {/*index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].rg : msgCampo*/}
                                                                        {dadosVenda.compradores.data[index].rg === null ? msgCampo : dadosVenda.compradores.data[index].rg}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>

                                                            <Table.TextCell className="historicoStatus" width={200}>
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Expedita por</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {/*index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].rg_expedido : msgCampo*/}
                                                                        {dadosVenda.compradores.data[index].rg_expedido === null ? msgCampo : dadosVenda.compradores.data[index].rg_expedido}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>

                                                            <Table.TextCell className="historicoStatus">
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Data expedição</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {/*index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].data_rg_expedido : msgCampo*/}
                                                                        {dadosVenda.compradores.data[index].data_rg_expedido === null ? msgCampo : dadosVenda.compradores.data[index].data_rg_expedido}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>
                                                        </Table.Row>

                                                        <Table.Row
                                                            height={
                                                                dadosVenda.compradores.data[index].uniao_estavel === null ? '' : ''
                                                            }
                                                        >

                                                            <Table.TextCell className="historicoStatus" width={200}>
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Estado Civil</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {/*Estado Civil e Regime de Casamento*/}
                                                                        {
                                                                            index < dadosVenda.compradores.data.length ?
                                                                                dadosVenda.compradores.data[index].estado_civil === '1' ? 'Casado' :
                                                                                    dadosVenda.compradores.data[index].estado_civil === '2' ? 'Solteiro' :
                                                                                        dadosVenda.compradores.data[index].estado_civil === '3' ? 'Divorciado' :
                                                                                            dadosVenda.compradores.data[index].estado_civil === '4' ? 'Viúvo' : ''
                                                                                : ''
                                                                        }
                                                                        {
                                                                            index < dadosVenda.compradores.data.length ?
                                                                                dadosVenda.compradores.data[index].registro_casamento === '1' ? ' - Separação total de bens' :
                                                                                    dadosVenda.compradores.data[index].registro_casamento === '2' ? ' - Separação parcial de bens' :
                                                                                        dadosVenda.compradores.data[index].registro_casamento === '3' ? ' - Separação legal de bens' :
                                                                                            dadosVenda.compradores.data[index].registro_casamento === '4' ? ' - Comunhão de bens' : ''
                                                                                : ''
                                                                        }
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>

                                                            {
                                                                dadosVenda.compradores.data[index].uniao_estavel === null
                                                                    ?
                                                                    ''

                                                                    :
                                                                    <Table.TextCell className="historicoStatus" width={200}>
                                                                        <Table.TextCell className="fontStatus">
                                                                            <span>União Estável</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell className="mensagemStyle">
                                                                            {
                                                                                !dadosVenda.compradores.data[index] ? ''
                                                                                    : dadosVenda.compradores.data[index].uniao_estavel !== null ? 'Sim'
                                                                                        : ''
                                                                            }
                                                                        </Table.TextCell>
                                                                    </Table.TextCell>
                                                            }

                                                            {
                                                                dadosVenda.compradores.data[index].uniao_estavel === null
                                                                    ?
                                                                    ''

                                                                    :
                                                                    <Table.TextCell className="historicoStatus" width={200}>
                                                                        <Table.TextCell className="fontStatus">
                                                                            <span>Nome completo do Cônjuge/Companheiro(a)</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell className="mensagemStyle">
                                                                            {
                                                                                !dadosVenda.compradores.data[index] ? ''
                                                                                    : dadosVenda.compradores.data[index].conjuge
                                                                            }
                                                                        </Table.TextCell>
                                                                    </Table.TextCell>
                                                            }
                                                        </Table.Row>

                                                        <Table.Row>
                                                            <Table.TextCell className="historicoStatus" width={200}>
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Nome completo da mãe</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].nome_mae : msgCampo}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>

                                                            <Table.TextCell className="historicoStatus">
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Nome completo do pai</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {/*index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].nome_pai : 'Vazio'*/}
                                                                        {dadosVenda.compradores.data[index].nome_pai === null ? msgCampo : dadosVenda.compradores.data[index].nome_pai}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>
                                                        </Table.Row>

                                                        <div className="blue-line"></div>

                                                        <React.Fragment>
                                                            <h2 className="subtitle subtitle-table">Procurador</h2>
                                                        </React.Fragment>

                                                        <Table.Row>
                                                            <Table.TextCell className="historicoStatus" width={200}>
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Nome completo</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {
                                                                            index < dadosVenda.compradores.data.length
                                                                                ? dadosVenda.compradores.data[index].procurador !== null ? dadosVenda.compradores.data[index].procurador.nome : msgCampo
                                                                                : msgCampo
                                                                        }
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>

                                                            <Table.TextCell className="historicoStatus">
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Celular</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {
                                                                            index < dadosVenda.compradores.data.length
                                                                                ? dadosVenda.compradores.data[index].procurador !== null ? dadosVenda.compradores.data[index].procurador.nome : msgCampo
                                                                                : msgCampo
                                                                        }
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>
                                                        </Table.Row>

                                                    </>

                                                    :
                                                    <>
                                                        {/*PJ*/}
                                                        <Table.Row>
                                                            <Table.TextCell className="historicoStatus">
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Razão Social</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].razao_social : msgCampo}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>

                                                            <Table.TextCell className="historicoStatus">
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Nome Fantasia</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].nome_fantasia : msgCampo}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>
                                                        </Table.Row>
                                                    </>
                                            }

                                            <div className="blue-line"></div>

                                            <React.Fragment>
                                                <h2 className="subtitle subtitle-table">
                                                    {dadosVenda.compradores.data[index].tipo_pessoa === 0 ? 'Endereço' : 'Endereço da empresa'}
                                                </h2>
                                            </React.Fragment>

                                            <Table.Row>
                                                <Table.TextCell className="historicoStatus" width={200}>
                                                    <Table.TextCell className="fontStatus">
                                                        <span>CEP</span>
                                                    </Table.TextCell>
                                                    <Table.TextCell className="mensagemStyle">
                                                        <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                            {index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].cep : msgCampo}
                                                        </span>
                                                    </Table.TextCell>
                                                </Table.TextCell>

                                                <Table.TextCell className="historicoStatus" width={200}>
                                                    <Table.TextCell className="fontStatus">
                                                        <span>Bairro</span>
                                                    </Table.TextCell>
                                                    <Table.TextCell className="mensagemStyle">
                                                        <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                            {index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].bairro : msgCampo}
                                                        </span>
                                                    </Table.TextCell>
                                                </Table.TextCell>

                                                <Table.TextCell className="historicoStatus">
                                                    <Table.TextCell className="fontStatus">
                                                        <span>Logradouro</span>
                                                    </Table.TextCell>
                                                    <Table.TextCell className="mensagemStyle">
                                                        <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                            {index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].logradouro : msgCampo}
                                                        </span>
                                                    </Table.TextCell>
                                                </Table.TextCell>
                                            </Table.Row>

                                            <Table.Row>
                                                <Table.TextCell className="historicoStatus" width={200}>
                                                    <Table.TextCell className="fontStatus">
                                                        <span>Número</span>
                                                    </Table.TextCell>
                                                    <Table.TextCell className="mensagemStyle">
                                                        <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                            {index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].numero : msgCampo}
                                                        </span>
                                                    </Table.TextCell>
                                                </Table.TextCell>

                                                <Table.TextCell className="historicoStatus" width={200}>
                                                    <Table.TextCell className="fontStatus">
                                                        <span>Unidade</span>
                                                    </Table.TextCell>
                                                    <Table.TextCell className="mensagemStyle">
                                                        <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                            {/*index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].unidade : msgCampo*/}
                                                            {dadosVenda.compradores.data[index].unidade === null ? msgCampo : dadosVenda.compradores.data[index].unidade}
                                                        </span>
                                                    </Table.TextCell>
                                                </Table.TextCell>

                                                <Table.TextCell className="historicoStatus">
                                                    <Table.TextCell className="fontStatus">
                                                        <span>Complemento</span>
                                                    </Table.TextCell>
                                                    <Table.TextCell className="mensagemStyle">
                                                        <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                            {/*index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].complemento : msgCampo*/}
                                                            {dadosVenda.compradores.data[index].complemento === null ? msgCampo : dadosVenda.compradores.data[index].complemento}
                                                        </span>
                                                    </Table.TextCell>
                                                </Table.TextCell>
                                            </Table.Row>

                                            <Table.Row>
                                                <Table.TextCell className="historicoStatus" width={200}>
                                                    <Table.TextCell className="fontStatus">
                                                        <span>Estado</span>
                                                    </Table.TextCell>
                                                    <Table.TextCell className="mensagemStyle">
                                                        <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                            {index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].estado : msgCampo}
                                                        </span>
                                                    </Table.TextCell>
                                                </Table.TextCell>

                                                <Table.TextCell className="historicoStatus">
                                                    <Table.TextCell className="fontStatus">
                                                        <span>Cidade</span>
                                                    </Table.TextCell>
                                                    <Table.TextCell className="mensagemStyle">
                                                        <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                            {index < dadosVenda.compradores.data.length ? dadosVenda.compradores.data[index].cidade : msgCampo}
                                                        </span>
                                                    </Table.TextCell>
                                                </Table.TextCell>
                                            </Table.Row>

                                            <div className="blue-line"></div>

                                            <React.Fragment>
                                                <h2 className="subtitle subtitle-table">
                                                    {dadosVenda.compradores.data[index].tipo_pessoa === 0 ? 'Documentos do Comprador' : 'Documentos da Empresa'}
                                                </h2>
                                            </React.Fragment>

                                            {
                                                dadosVenda.compradores.data[index].documentos.data.length === 0
                                                    ?
                                                    <span className="mensagem" style={{ paddingLeft: 0, fontSize: '14px' }}>Nenhum documento foi adicionado no momento.</span>
                                                    :
                                                    <ReturnDocumentsForView docs={dadosVenda.compradores.data[index]?.documentos?.data} />
                                            }


                                            {
                                                dadosVenda.compradores.data[index].tipo_pessoa === 1
                                                    ?
                                                    <>
                                                        <React.Fragment>

                                                            <div className="blue-line" style={{ marginTop: '25px' }}></div>

                                                            <h2 className="subtitle subtitle-table">
                                                                Sócio e/ou Representantes Legais
                                                            </h2>
                                                        </React.Fragment>
                                                        {
                                                            dadosVenda.compradores.data[index].representante_socios.data.map((representante, index_representante) =>

                                                                <>
                                                                    <Table.Row style={{ height: 'auto' }}>
                                                                        {
                                                                            representante.pj_representante === 1
                                                                                ?
                                                                                <Table.TextCell className="historicoStatus">
                                                                                    <Table.TextCell className="fontStatus">
                                                                                        <Badge color="green" className="badge-tipo-pesso">
                                                                                            <span>
                                                                                                REPRESENTANTE LEGAL
                                                                                            </span>
                                                                                        </Badge>
                                                                                    </Table.TextCell>
                                                                                </Table.TextCell>

                                                                                :
                                                                                ''
                                                                        }


                                                                        {
                                                                            representante.pj_socio === 1
                                                                                ?
                                                                                <Table.TextCell className="historicoStatus">
                                                                                    <Table.TextCell className="fontStatus">
                                                                                        <Badge color="green" className="badge-tipo-pesso">
                                                                                            <span>
                                                                                                SÓCIO
                                                                                            </span>
                                                                                        </Badge>
                                                                                    </Table.TextCell>
                                                                                </Table.TextCell>
                                                                                :
                                                                                ''
                                                                        }
                                                                    </Table.Row>

                                                                    <Table.Row>
                                                                        <Table.TextCell className="historicoStatus" width={180}>
                                                                            <Table.TextCell className="fontStatus">
                                                                                <span>CPF</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell className="mensagemStyle">
                                                                                <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                                    {representante.cpf_cnpj !== null ? representante.cpf_cnpj : msgCampo}
                                                                                </span>
                                                                            </Table.TextCell>
                                                                        </Table.TextCell>

                                                                        <Table.TextCell className="historicoStatus" width={180}>
                                                                            <Table.TextCell className="fontStatus">
                                                                                <span>Gênero</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell className="mensagemStyle">
                                                                                <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                                    {representante.genero !== null
                                                                                        ? representante.genero === 'M' ? 'Masculino' : 'Feminino'
                                                                                        : msgCampo}
                                                                                </span>
                                                                            </Table.TextCell>
                                                                        </Table.TextCell>

                                                                        <Table.TextCell className="historicoStatus" width={180}>
                                                                            <Table.TextCell className="fontStatus">
                                                                                <span>E-mail</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell className="mensagemStyle">
                                                                                <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                                    {representante.email !== null ? representante.email : msgCampo}
                                                                                </span>
                                                                            </Table.TextCell>
                                                                        </Table.TextCell>

                                                                        <Table.TextCell className="historicoStatus">
                                                                            <Table.TextCell className="fontStatus">
                                                                                <span>Nome completo</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell className="mensagemStyle">
                                                                                <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                                    {representante.name !== null ? representante.name : msgCampo}
                                                                                </span>
                                                                            </Table.TextCell>
                                                                        </Table.TextCell>
                                                                    </Table.Row>

                                                                    <Table.Row>
                                                                        <Table.TextCell className="historicoStatus" width={130}>
                                                                            <Table.TextCell className="fontStatus">
                                                                                <span>RG</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell className="mensagemStyle">
                                                                                <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                                    {representante.rg !== null ? representante.rg : msgCampo}
                                                                                </span>
                                                                            </Table.TextCell>
                                                                        </Table.TextCell>

                                                                        <Table.TextCell className="historicoStatus" width={130}>
                                                                            <Table.TextCell className="fontStatus">
                                                                                <span>Expedita por</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell className="mensagemStyle">
                                                                                <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                                    {representante.rg_expedido !== null ? representante.rg_expedido : msgCampo}
                                                                                </span>
                                                                            </Table.TextCell>
                                                                        </Table.TextCell>

                                                                        <Table.TextCell className="historicoStatus" >
                                                                            <Table.TextCell className="fontStatus">
                                                                                <span>Data de expedição</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell className="mensagemStyle">
                                                                                <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                                    {representante.data_rg_expedido !== null ? representante.data_rg_expedido : msgCampo}
                                                                                </span>
                                                                            </Table.TextCell>
                                                                        </Table.TextCell>
                                                                    </Table.Row>

                                                                    <Table.Row>
                                                                        <Table.TextCell className="historicoStatus">
                                                                            <Table.TextCell className="fontStatus">
                                                                                <span>Profissão</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell className="mensagemStyle">
                                                                                <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                                    {representante.profissao !== null ? representante.profissao : msgCampo}
                                                                                </span>
                                                                            </Table.TextCell>
                                                                        </Table.TextCell>
                                                                    </Table.Row>

                                                                    <Table.Row height={representante.uniao_estavel !== null ? 150 : 100}>
                                                                        <Table.TextCell className="historicoStatus">
                                                                            <Table.TextCell className="fontStatus">
                                                                                <span>Estado Civil</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell className="mensagemStyle">
                                                                                <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                                    {/*Estado Civil e Regime de Casamento*/}
                                                                                    {
                                                                                        representante.estado_civil !== null ?
                                                                                            representante.estado_civil === '1' ? 'Casado' :
                                                                                                representante.estado_civil === '2' ? 'Solteiro' :
                                                                                                    representante.estado_civil === '3' ? 'Divorciado' :
                                                                                                        representante.estado_civil === '4' ? 'Viúvo' : ''
                                                                                            : ''
                                                                                    }
                                                                                    {
                                                                                        representante.remime_casamento !== null ?
                                                                                            representante.remime_casamento === '1' ? ' - Separação total de bens' :
                                                                                                representante.remime_casamento === '2' ? ' - Separação parcial de bens' :
                                                                                                    representante.remime_casamento === '3' ? ' - Separação legal de bens' :
                                                                                                        representante.remime_casamento === '4' ? ' - Comunhão de bens' : ''
                                                                                            : ''
                                                                                    }
                                                                                </span>
                                                                            </Table.TextCell>
                                                                        </Table.TextCell>

                                                                        {
                                                                            !representante.uniao_estavel
                                                                                ? ''
                                                                                : representante.uniao_estavel === null
                                                                                    ? ''
                                                                                    :
                                                                                    <Table.TextCell className="historicoStatus">
                                                                                        <Table.TextCell className="fontStatus">
                                                                                            <span>União Estável</span>
                                                                                        </Table.TextCell>
                                                                                        <Table.TextCell className="mensagemStyle">
                                                                                            {
                                                                                                !representante.uniao_estavel ? ''
                                                                                                    : representante.uniao_estavel !== null ? 'Sim'
                                                                                                        : ''
                                                                                            }
                                                                                        </Table.TextCell>
                                                                                    </Table.TextCell>
                                                                        }

                                                                        {
                                                                            !representante.conjuge
                                                                                ? ''
                                                                                : representante.conjuge === null
                                                                                    ? ''
                                                                                    :
                                                                                    <Table.TextCell className="historicoStatus">
                                                                                        <Table.TextCell className="fontStatus">
                                                                                            <span>Nome completo do Cônjuge/Companheiro(a)</span>
                                                                                        </Table.TextCell>
                                                                                        <Table.TextCell className="mensagemStyle">
                                                                                            {
                                                                                                !representante.conjuge ? ''
                                                                                                    : representante.conjuge
                                                                                            }
                                                                                        </Table.TextCell>
                                                                                    </Table.TextCell>
                                                                        }
                                                                    </Table.Row>

                                                                    <React.Fragment>
                                                                        <h2 className="subtitle subtitle-table">
                                                                            Documentos
                                                                        </h2>
                                                                    </React.Fragment>

                                                                    {
                                                                        representante.documentos.data.length === 0
                                                                            ?
                                                                            <span className="mensagem" style={{ paddingLeft: 0, fontSize: '14px', marginBottom: '35px', display: 'block' }}>Nenhum documento foi adicionado no momento.</span>

                                                                            :
                                                                            <ReturnDocumentsForView docs={representante.documentos.data} />
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </>

                                                    : ''
                                            }
                                        </Pane>
                                    </Pane>
                                ))}
                            </div>
                        </div>
                    </>
            }
        </Pane>
    )
}