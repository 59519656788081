/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { Pane, Button, FilePicker, PlusIcon, DeleteIcon, Dialog, toaster, Position, SelectMenu, Combobox, Tooltip, Image, ErrorIcon, TextInput, Badge, Text, Select } from 'evergreen-ui';
import ShowDocument from '../app/showDocument';
import removeDocument from '../apis/removeDocument';
import { createCancelToken } from '../apis/cancelRequests';
import saveDocument from '../apis/saveDocument';
import animationData from '../images/Animations/delete_files.json';
import Lottie from 'react-lottie';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import Document from '../images/icos/document.png';
import MultiDocument from '../images/icos/multi_document.png';
import { useForm } from 'react-hook-form';
import dateMask from './dateMask';
import dayjs from 'dayjs';

export default function UploadDoc({ pessoa, type, context, indexPessoa, indexRepresentante, refreshProcess, idDonoDocumento, acessoTemporario, pedidoProcesso }) {
  const {
    documentsList,
    listaDocumentos,
    processoId,
    dadosVenda,
    isShowConfirmDel, setIsShowConfirmDel,
    itemToRemove, setItemToRemove,
    multiDocs, setMultiDocs,
  } = useContext(context);

  const uploadLimit = 52428800;
  console.log(multiDocs)

  const token = localStorage.getItem('token');
  const [documentos, setDocumentos] = useState([]);
  const [loading, setLoading] = useState(false);
  const idProcesso = processoId ? processoId : dadosVenda?.processo_id;
  const emptyProgress = { percent: 0, status: '', error: false }
  const [progressBar, setProgressBar] = useState([emptyProgress]);
  const emptyDoc = { item: [{ id: '', value: '', emissao: '', validade: '', vencimento: '' }], file: '' };

  const { register, setValue, watch } = useForm();
  console.log(watch());
  // console.log(pessoa)
  // console.log(indexPessoa)

  const perfil = localStorage.getItem('perfil_login');
  const acceptFiles = perfil === 'Backoffice' || perfil === 'Coordenadora de Pós-Negociação' || perfil === 'Núcleo' || perfil === 'Gerente' || perfil === 'Gerente Geral' ? '.pdf, .doc, .docx' : '.pdf, .jpeg, .jpg, .png';

  useEffect(() => {
    let data = [];
    if (indexPessoa >= 0) {
      if (indexRepresentante >= 0) data = multiDocs[pessoa][indexPessoa].representante_socios[indexRepresentante];
      else data = multiDocs[pessoa][indexPessoa][type];
    } else data = multiDocs[pessoa];
    console.log(data);
    if (data.length === 0) data.push({ item: [], file: '' }); //emptyDoc
    setDocumentos(data);
    setProgressBar(data.map((e, index) =>
      progressBar[index] ? progressBar[index] : emptyProgress
    ));
    // console.log(data);    
    // console.log(pessoa)
    if (perfil === 'Backoffice' || perfil === 'Coordenadora de Pós-Negociação' || perfil === 'Núcleo' || pessoa === 'vendedores' || pessoa === 'compradores') {
      console.log('caiu aqui')
      data.map((e, index) => setValue(index + '.', e.item.map((item) => ({ emissao: item.emissao, validade: item.validade, have_validade: !!item.have_validade })))); ///*!!item.validade*/      
      console.log(data.map((e, index) => setValue(index + '.', e.item.map((item) => ({ emissao: item.emissao, validade: item.validade, have_validade: !!item.have_validade })))))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiDocs]);
  console.log('MULTIDOCS: ', multiDocs)
  console.log('DOCUMENTOS: ', documentos)

  const addItem = () => {
    if (indexPessoa >= 0) {
      if (indexRepresentante !== undefined) {
        multiDocs[pessoa][indexPessoa].representante_socios[indexRepresentante].push({ item: [], file: '' });
      }
      else multiDocs[pessoa][indexPessoa][type].push({ item: [], file: '' });
    } else multiDocs[pessoa].push({ item: [], file: '' });
    progressBar.push(emptyProgress);
    setProgressBar([...progressBar]);
    setMultiDocs({ ...multiDocs });
  };

  const removeItem = async (i, id) => {
    if (progressBar[i].percent > 0 && progressBar[i].percent < 100) {
      const { cancel } = createCancelToken();
      cancel();
    }
    else if (id) {
      removeFormValue(i);
    }
    else if (indexPessoa >= 0) {
      if (indexRepresentante !== undefined) {
        multiDocs[pessoa][indexPessoa].representante_socios[indexRepresentante].splice(i, 1);
      }
      else multiDocs[pessoa][indexPessoa][type].splice(i, 1);

      progressBar.splice(i, 1);
      setProgressBar([...progressBar]);
    } else multiDocs[pessoa].splice(i, 1)
    setMultiDocs({ ...multiDocs });
  };

  const removeFormValue = (i) => {
    let item;
    if (indexPessoa >= 0) {
      if (indexRepresentante >= 0) {
        item = multiDocs[pessoa][indexPessoa].representante_socios[indexRepresentante][i];
      }
      else {
        item = multiDocs[pessoa][indexPessoa][type][i];
      }
    } else {
      item = multiDocs[pessoa][i];
    }
    // console.log(i)
    // console.log(item)
    setItemToRemove({ item: item, index: i });
    // console.log(itemToRemove);
    setIsShowConfirmDel(true);
  };

  // FUNÇÕES PARA O DIALOG DO CONFIRM DELETE
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleConfirm = async (close) => {
    console.log('CONFIRME')
    setLoading(true);
    const remove = await removeDocument(itemToRemove.item.id, acessoTemporario);
    setTimeout(() => {
      if (remove) {
        toaster.success("Arquivo removido com sucesso!");
        progressBar.splice(itemToRemove.index, 1);
        setProgressBar([...progressBar]);
        setItemToRemove();
        if (refreshProcess) refreshProcess(idProcesso, undefined, returnIndexTab());

      } else {
        toaster.warning("Algo não saiu como esperado, tente novamente mais tarde!");
      }

      setIsShowConfirmDel(false);
      setLoading(false);
       //close();
    }, 3000)
  };

  const returnIndexTab = () => {
    setIsShowConfirmDel(false);
    if (pessoa === 'vendedores') return 3;
    else if (pessoa === 'compradores') return 4;
    else if (pessoa === 'imovel') return 1;
    else return 0;
  };

  const handleSelectItem = (value, index_Doc) => {
    console.log(value);
    if (indexPessoa >= 0) {
      if (indexRepresentante >= 0) multiDocs[pessoa][indexPessoa].representante_socios[indexRepresentante][index_Doc].item.push(value);
      else multiDocs[pessoa][indexPessoa][type][index_Doc].item.push(value)
    } else multiDocs[pessoa][index_Doc].item.push(value);
    setMultiDocs({ ...multiDocs });
  };

  const handleDeselectItem = (e, index_Doc) => {
    if (indexPessoa >= 0) {
      if (indexRepresentante >= 0) {
        multiDocs[pessoa][indexPessoa].representante_socios[indexRepresentante][index_Doc].item = multiDocs[pessoa][indexPessoa].representante_socios[indexRepresentante][index_Doc].item.filter((_item) => _item.value !== e.value);
      }
      // PESSOA FISICA E JURIDICA
      else multiDocs[pessoa][indexPessoa][type][index_Doc].item = multiDocs[pessoa][indexPessoa][type][index_Doc].item.filter((_item) => _item.value !== e.value);
    } else {
      multiDocs[pessoa][index_Doc].item = multiDocs[pessoa][index_Doc].item.filter((_item) => _item.value !== e.value);
    }

    setMultiDocs({ ...multiDocs });
  };

  const handleDisableUpload = (index_Doc) => {
    if (indexPessoa >= 0) {
      if (indexRepresentante >= 0) {
        if (multiDocs[pessoa][indexPessoa]?.representante_socios[indexRepresentante][index_Doc]?.item.length > 0) return false;
        else return true
      }
      // PESSOA FISICA E JURIDICA
      else {
        if (multiDocs[pessoa][indexPessoa][type][index_Doc]?.item.length > 0) return false;
        else return true
      }
    } else {
      if (multiDocs[pessoa][index_Doc]?.item.length > 0) return false;
      else return true
    }
  };

  const refreshDocs = (id, index_Doc, ids_items) => {
    if (id) {
      if (indexPessoa >= 0) {
        if (indexRepresentante !== undefined) {
          multiDocs[pessoa][indexPessoa].representante_socios[indexRepresentante][index_Doc].id = id;
          ids_items.forEach((e, index_Item) => {
            multiDocs[pessoa][indexPessoa].representante_socios[indexRepresentante][index_Doc].item[index_Item].id = e;
          })
        }
        else {
          multiDocs[pessoa][indexPessoa][type][index_Doc].id = id;
          ids_items.forEach((e, index_Item) => {
            multiDocs[pessoa][indexPessoa][type][index_Doc].item[index_Item].id = e;
          })
        }
      } else {
        multiDocs[pessoa][index_Doc].id = id;
        ids_items.forEach((e, index_Item) => {
          multiDocs[pessoa][index_Doc].item[index_Item].id = e;
        })
      }
      setMultiDocs({ ...multiDocs })
    }
  };

  const handleChangeDocumento = (i, e, type_doc) => {
    const value = (type_doc === 'documento') ? e.target.value : e[0];
    let docToSave;
    if (value) {
      if (indexPessoa >= 0) {
        if (indexRepresentante !== undefined) {
          multiDocs[pessoa][indexPessoa].representante_socios[indexRepresentante][i].file = value;
          docToSave = multiDocs[pessoa][indexPessoa].representante_socios[indexRepresentante][i];
        }
        else {
          multiDocs[pessoa][indexPessoa][type][i].file = value;
          docToSave = multiDocs[pessoa][indexPessoa][type][i];
        }
      } else {
        multiDocs[pessoa][i].file = value;
        docToSave = multiDocs[pessoa][i];
      }
      handleAutoSave(docToSave, i);
      setMultiDocs({ ...multiDocs });
    }
  };

  const handleAutoSave = async (docToSave, index_Doc) => {
    if (docToSave.file.size > uploadLimit) {
      progressBar[index_Doc].error = `O arquivo deve ter menos de ${uploadLimit / (1024 * 1024)} MB`;
      setProgressBar({ ...progressBar });
    } else {
      const res = await saveDocument(idProcesso, docToSave, idDonoDocumento, pessoa === 'imovel' ? pessoa : pessoa.replace("es", ''), index_Doc, setProgressBar, progressBar, acessoTemporario, pedidoProcesso);

      if (res?.data?.id_documento) {
        refreshDocs(res.data?.id_documento?.id, index_Doc, res.data?.ids_tipos_doc);
      }

      if (refreshProcess) refreshProcess(idProcesso, undefined, returnIndexTab());
    }
  };

  const handleVencimento = (e, index_Doc, index_Item, int) => {
    let { name, value } = e.target;    
    const path = name.split('.')[2];
    if(int) value = parseInt(value);

    if (indexPessoa >= 0) {
      if (indexRepresentante >= 0) {
        multiDocs[pessoa][indexPessoa].representante_socios[indexRepresentante][index_Doc].item[index_Item][path] = value;
      }
      // PESSOA FISICA E JURIDICA
      else multiDocs[pessoa][indexPessoa][type][index_Doc].item[index_Item][path] = value;
    } else {
      multiDocs[pessoa][index_Doc].item[index_Item][path] = value;
    }
    
    calcDataVencimento(index_Doc, index_Item)
  };

  const calcDataVencimento = (index_Doc, index_Item) => {
    const { emissao, validade } = indexPessoa >= 0 ?
      indexRepresentante >= 0
        ? multiDocs[pessoa][indexPessoa].representante_socios[indexRepresentante][index_Doc].item[index_Item]
        : multiDocs[pessoa][indexPessoa][type][index_Doc].item[index_Item]
      : multiDocs[pessoa][index_Doc].item[index_Item]

    let newData = ''

    if (emissao?.length === 10 && validade) {
      const oldData = emissao.split("/");
      const oldDataFormat = oldData[2] + '-' + oldData[1] + '-' + oldData[0]
      newData = dayjs(oldDataFormat).add(Number(validade), 'day').format('DD/MM/YYYY');      
    }

    if (indexPessoa >= 0) {
      if (indexRepresentante >= 0) {
        multiDocs[pessoa][indexPessoa].representante_socios[indexRepresentante][index_Doc].item[index_Item].vencimento = newData;
      }
      // PESSOA FISICA E JURIDICA
      else multiDocs[pessoa][indexPessoa][type][index_Doc].item[index_Item].vencimento = newData;
    } else {
      multiDocs[pessoa][index_Doc].item[index_Item].vencimento = newData;
    }
    setMultiDocs({ ...multiDocs });
  }


  return (
    <Pane>
      {/* DIALOG DO CONFIRM DELETE ARQUIVO */}
      {refreshProcess &&
        <Dialog
          title={<div className="dialog-content dialog-content-rua mt20 dialog-title-delete">Você quer mesmo remover o arquivo?</div>}
          isShown={isShowConfirmDel}
          //title={false}
          onCloseComplete={() => setIsShowConfirmDel(false)}
          confirmLabel="Remover"
          cancelLabel="Voltar"
          intent="danger"
          onConfirm={close => handleConfirm(close)}
          isConfirmLoading={loading}
          containerProps={{
            className: 'delete-doc-dialog'
          }}
        >
          {loading 
            ? 
            <Lottie options={defaultOptions} height={150} width={150} /> 
            :
            <Pane>
              {/* <div className="dialog-content dialog-content-rua mt20">Você quer mesmo remover o arquivo?</div> */}
              <div className="dialog-content fw400 mb10">
                Ao clicar em remover, o arquivo não poderá ser recuperado.
              </div>
            </Pane>
          }
        </Dialog>}

      <p className='mb8'>Tipos de documentos</p>

      {/* CAMPOS INPUT DOS ARQUIVOS */}
      {documentos.map((e, index_Doc) => (
        <Pane className='mb16' width="max-content" key={index_Doc}>
          <Pane className='flex gap8 mb5' width="max-content" >
            <div className='flex itemsCenter'>
              <Image marginRight={12} height={16} src={e.item.length > 1 ? MultiDocument : Document} />

              <div className="row-mobile">
                <SelectMenu
                  // openOnFocus={false} // Impede que o foco abra o teclado
                  isMultiSelect
                  hasFilter={false}
                  hasTitle={false}
                  options={(documentsList || listaDocumentos).filter((list) =>
                    list.tipo.split(" ").includes(type)).map((doc) => ({
                      label: doc.nome, value: doc.id, validade: doc.validade_dias, have_validade: doc.have_validade
                    })).sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0)
                  }
                  selected={e.item.map(y => y.value)}
                  onSelect={(value) => handleSelectItem(value, index_Doc)}
                  onDeselect={(e) => handleDeselectItem(e, index_Doc)}
                  itemHeight={50}
                  width={'95%'}
                  containerProps={{
                    className: 'custom-selectmenu-container', // Classe customizada
                    style: {
                      '@media (max-width: 600px)': {
                        width: '100%', // Responsivo para telas menores
                        maxWidth: '550px',
                        padding: '8px', // Ajusta o padding em telas pequenas
                        height: '400px'
                      },
                    },
                  }}
                >
                  <button className='gabiarraSelectMenuDocs' type='button'>
                    <Combobox
                      onFocus={(e) => {
                        e.preventDefault(); // Impede o foco no campo e a ativação do teclado
                        e.target.blur(); // Remove o foco do input
                      }}
                      items={[]}
                      width={210}
                      className={e.item[0]?.value ? 'inputGreenSelect' : 'inputDefaultSelect'}
                      placeholder={
                        e.item.length > 1 ? e.item.length.toString() + ' selecionados...' :
                          e.item[0]?.value ? (documentsList || listaDocumentos).find(doc => doc.id === e.item[0].value).nome : 'Selecione os tipos'
                      }
                    />
                  </button>
                </SelectMenu>
              </div>

              <div className="row-desktop">
                <SelectMenu
                  isMultiSelect
                  title="Selecione os tipos"
                  filterPlaceholder="Buscar"
                  options={(documentsList || listaDocumentos).filter((list) =>
                    list.tipo.split(" ").includes(type)).map((doc) => ({
                      label: doc.nome, value: doc.id, validade: doc.validade_dias, have_validade: doc.have_validade
                    })).sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0)
                  }
                  selected={e.item.map(y => y.value)}
                  onSelect={(value) => handleSelectItem(value, index_Doc)}
                  onDeselect={(e) => handleDeselectItem(e, index_Doc)}
                >
                  <button className='gabiarraSelectMenuDocs' type='button'>
                    <Combobox
                      items={[]}
                      width={210}
                      className={e.item[0]?.value ? 'inputGreenSelect' : 'inputDefaultSelect'}
                      placeholder={
                        e.item.length > 1 ? e.item.length.toString() + ' selecionados...' :
                          e.item[0]?.value ? (documentsList || listaDocumentos).find(doc => doc.id === e.item[0].value).nome : 'Selecione os tipos'
                      }
                    />
                  </button>
                </SelectMenu>
              </div>
            </div>

            <Tooltip content={"Selecione o tipo do arquivo"} isShown={!handleDisableUpload(index_Doc) ? false : ""} position={Position.BOTTOM}>
              <FilePicker
                width={230}
                disabled={handleDisableUpload(index_Doc)}
                marginRight={30}
                placeholder={e.file || "Selecione o arquivo"}
                defaultValue={e.file}
                type="file"
                className={handleDisableUpload(index_Doc) ? "notAallowed" : progressBar[index_Doc]?.error ? 'inputErrorSelect' : e.file ? 'inputGreenSelect' : 'inputDefaultSelect'}
                // accept=".pdf, .doc, .docx, .jpg, .jpeg"
                accept={acceptFiles}
                onChange={(e) => handleChangeDocumento(index_Doc, e, 'arquivo')}
              />
            </Tooltip>

            <div className="row-mobile">
              {/* CAMPO DATA DE VALIDADE DO DOCUMENTO PARA MOBILE*/}
              {console.log('CONSOLE E: ', e.item)}
              {e.item.map((doc, index) => {
                <>{console.log('CONSOLE DOC: ', doc)}</>
                if (e.item.find(doc => doc.have_validade) && index === 0) {
                  return (
                    <Pane className='mb28'>
                      {/* CABEÇALHO DOS INPUTS, OBS: width FORAM USADOS PARA ALINHAR A TABELA */}
                      {e.item.map((doc, index) => {
                        if (doc.have_validade) {
                          return (
                            <>
                              <Pane className='flex mt16 gap8' style={{margin: '10px 0'}}>
                                <Text fontSize={10} width={287} marginLeft={28}>DOCUMENTO</Text>
                                <TextInput
                                  marginLeft={0}
                                  width={'100%'}
                                  value={(documentsList || listaDocumentos).find(item => item.id === doc.value)?.nome}
                                  disabled
                                  marginBottom={0}
                                />
                              </Pane>

                              <Pane key={doc.value} className='flex gap8 mt8' style={{display: 'flex'}}>
                                {/* INPUTS DA TABELA, OBS: width FORAM USADOS PARA ALINHAR COM O CABEÇALHO */}
                                <div>
                                  <Text fontSize={10} width={90}>EMISSÃO (DATA)</Text>
                                  <TextInput
                                    width={100}
                                    marginBottom={0}
                                    className={watch(index_Doc + '.' + index + '.emissao') ? 'inputSucess' : 'inputDefault'}
                                    value={watch(index_Doc + '.' + index + '.emissao')}
                                    {...register(index_Doc + '.' + index + '.emissao', {
                                      required: false,
                                      setValueAs: e => dateMask(e),
                                      onChange: (e) => handleVencimento(e, index_Doc, index)
                                    })}
                                  />
                                </div>

                                <div>
                                  <Text fontSize={10} width={88}>VALIDADE (DIAS)</Text>
                                  <TextInput
                                    width={88}
                                    type="number"
                                    value={watch(index_Doc + '.' + index + '.validade')}                            
                                    className={watch(index_Doc + '.' + index + '.validade') ? 'inputSucess' : 'inputDefault'}
                                    {...register(index_Doc + '.' + index + '.validade', {
                                      required: false,
                                      onChange: (e) => handleVencimento(e, index_Doc, index, 'inteiro'),
                                      setValueAs: e => parseInt(e)
                                    })}
                                  />
                                </div>

                                <div>
                                  <Text fontSize={10} marginLeft={8}>VENCIMENTO</Text>
                                  <Badge color={doc.vencimento ? 'blue' : 'neutral'} marginLeft={0}>{doc.vencimento || "SEM DATA"}</Badge>
                                </div>
                              </Pane>
                            </>
                          )
                        }
                      }
                      )}
                    </Pane>
                  )
                }
              }
              )}
            </div>

            <div className="row-btn">
              <Button
                disabled={!e.id}
                width={53}
                appearance="primary"
                onClick={(btn) => [ShowDocument((e.id), 'documento', token, acessoTemporario), btn.preventDefault()]}
              >Ver
              </Button>

              <Button
                appearance="primary"
                intent="danger"
                width={32}
                padding={6}
                disabled={progressBar[index_Doc]?.percent > 0 && progressBar[index_Doc]?.percent < 100}
                onClick={() => removeItem(index_Doc, e.id)}
                type="button"
              >
                <DeleteIcon
                  size={12}
                  color="white"
                />
              </Button>
            </div>

            <div className="line-box-doc"></div>
          </Pane>
          {/* MSG DE ERRO */}
          {progressBar[index_Doc]?.error
            ?
            <Pane marginLeft={247} className="error-genero">
              <span><ErrorIcon /> {progressBar[index_Doc].error}</span>
            </Pane>
            : ''}

          {/* BARRA DE PROGRESSO DO UPLOAD */}
          {progressBar[index_Doc]?.percent !== 0 &&
            <Progress
              percent={progressBar[index_Doc]?.percent}
              status={progressBar[index_Doc]?.status}
              theme={{
                default: {
                  color: '#3366FF'
                }
              }}
            />}

          <div className="row-desktop">
          {/* CAMPO DATA DE VALIDADE DO DOCUMENTO */}
          {console.log('CONSOLE E: ', e.item)}
          {e.item.map((doc, index) => {
            <>{console.log('CONSOLE DOC: ', doc)}</>
            if (e.item.find(doc => doc.have_validade) && index === 0) {
              return (
                <Pane className='mb28'>
                  {/* CABEÇALHO DOS INPUTS, OBS: width FORAM USADOS PARA ALINHAR A TABELA */}
                  <Pane className='flex mt16 gap8'>
                    <Text fontSize={10} width={287} marginLeft={28}>DOCUMENTO</Text>
                    <Text fontSize={10} width={90}>EMISSÃO (DATA)</Text>
                    <Text fontSize={10} width={88}>VALIDADE (DIAS)</Text>
                    <Text fontSize={10} marginLeft={8}>VENCIMENTO</Text>
                  </Pane>
                  {e.item.map((doc, index) => {
                    if (doc.have_validade) {
                      return (
                        <Pane key={doc.value} className='flex gap8 mt8 itemsCenter'>
                          {/* INPUTS DA TABELA, OBS: width FORAM USADOS PARA ALINHAR COM O CABEÇALHO */}
                          <TextInput
                            marginLeft={28}
                            width={287}
                            value={(documentsList || listaDocumentos).find(item => item.id === doc.value)?.nome}
                            disabled
                            marginBottom={0}
                          />

                          <TextInput
                            width={90}
                            marginBottom={0}
                            className={watch(index_Doc + '.' + index + '.emissao') ? 'inputSucess' : 'inputDefault'}
                            value={watch(index_Doc + '.' + index + '.emissao')}
                            {...register(index_Doc + '.' + index + '.emissao', {
                              required: false,
                              setValueAs: e => dateMask(e),
                              onChange: (e) => handleVencimento(e, index_Doc, index)
                            })}
                          />

                          <TextInput
                            width={88}
                            type="number"
                            value={watch(index_Doc + '.' + index + '.validade')}                            
                            className={watch(index_Doc + '.' + index + '.validade') ? 'inputSucess' : 'inputDefault'}
                            {...register(index_Doc + '.' + index + '.validade', {
                              required: false,
                              onChange: (e) => handleVencimento(e, index_Doc, index, 'inteiro'),
                              setValueAs: e => parseInt(e)
                            })}
                          />

                          <Badge color={doc.vencimento ? 'blue' : 'neutral'} marginLeft={8}>{doc.vencimento || "SEM DATA"}</Badge>
                        </Pane>
                      )
                    }
                  }
                  )}
                </Pane>
              )
            }
          }
          )}
          </div>
        </Pane>
      ))}

      <Button
        className="btn-adicionar button add mb20"
        onClick={() => addItem()}
        type="button"
        width={185}
        marginLeft={28}
      >
        <PlusIcon
          size={10}
          color='#8F95B2'
          marginRight={7}
        />
        adicionar documento
      </Button>
    </Pane>
  )
}