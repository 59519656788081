import React, { useState, useContext, useEffect } from 'react';
import { Pane, TextInputField, Label, Textarea, DocumentIcon, Table  } from 'evergreen-ui';
import './index.css';
//import PosNegociacaoContext from '../../../../context/PosNegociacaoContext';
import VendasContext from '../../../../context/VendasContext';
import ShowDocument from '../../../../app/showDocument';

//import { useHistory } from 'react-router-dom';

export default function ReciboDeSinal(props) {
    //const history = useHistory();
    const [token] = useState(localStorage.getItem('token'));
    //const [responsavel_id] = useState(localStorage.getItem('usuario_id'));
    const idProcesso = props.idProcesso;    

    const {
        dadosReciboEnviado,
    } = useContext(VendasContext);

    console.log(dadosReciboEnviado);

    const imovelProcesso = 1;

    function forma_pagamento(forma_pagamento) {
        let string_forma_pagamento = '';

        console.log(forma_pagamento);

        if (forma_pagamento !== null) {
            let arrayPagamento = forma_pagamento.split(',');

            arrayPagamento.forEach((element, index) => {
                switch (element) {
                    case '1':
                        string_forma_pagamento += "À vista" + (arrayPagamento.length === (index + 1) ? '' : ', ');
                        break;
                    case '2':
                        string_forma_pagamento += "Financiamento" + (arrayPagamento.length === (index + 1) ? '' : ', ');
                        break;
                    case '3':
                        string_forma_pagamento += "FGTS" + (arrayPagamento.length === (index + 1) ? '' : ', ');
                        break;
                    case '4':
                        string_forma_pagamento += "Consórcio" + (arrayPagamento.length === (index + 1) ? '' : ', ');
                        break;
                    case '5':
                        string_forma_pagamento += "Parcelamento" + (arrayPagamento.length === (index + 1) ? '' : ', ');
                        break;
                    case '6':
                        string_forma_pagamento += "PIX" + (arrayPagamento.length === (index + 1) ? '' : ', ');
                        break;

                    default:
                        break;
                }
            });   
        }
        else {
            string_forma_pagamento = '';
        }
        return string_forma_pagamento;
    }

    return (
        <div className="detadelhes-sidesheet">
            <Pane>
                <React.Fragment>            
                    <h2 className="subtitle subtitle-table">Recibo de Sinal</h2>
                </React.Fragment>

                <Table.Row>
                    <Table.TextCell className="historicoStatus">
                        <Table.TextCell className="fontStatus">
                            <span>Data de Assinatura</span>
                        </Table.TextCell>
                        <Table.TextCell className="mensagemStyle">
                            <span className="mensagem" style={{paddingLeft: 0}}>
                                {dadosReciboEnviado.informacao.data_assinatura}
                            </span>
                        </Table.TextCell>
                    </Table.TextCell>
                </Table.Row>

                <Table.Row>
                    <Table.TextCell className="historicoStatus">
                        <Table.TextCell className="fontStatus">
                            <span>Arquivo do Recibo de Sinal</span>
                        </Table.TextCell>
                        <Table.TextCell className="mensagemStyle" onClick={(e) => ShowDocument(dadosReciboEnviado.id, "recibo", token)}>
                            <span className="mensagem" style={{paddingLeft: 0}}>
                                <span className="link-ref" style={{cursor: 'pointer', color: '#3366FF'}}>{dadosReciboEnviado.informacao.recibo}</span>
                            </span>
                        </Table.TextCell>
                    </Table.TextCell>
                </Table.Row>
            </Pane>
        </div>
    )
}