import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button, Pane, TextInputField, SelectField } from 'evergreen-ui';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'
import cpfMask from '../../../../components/cpfMask';
import rgMask from '../../../../components/rgMask';
import dateMask from '../../../../components/dateMask';
import Axios from 'axios';
import Serve from '../../../../app/serve';
import NegociacaoContext from '../../../../context/NegociacaoContext';
import { useHistory } from 'react-router-dom';
import { AddIcon, ResetIcon, DeleteIcon, TickIcon } from 'evergreen-ui'

export default function Gerente({ refreshProcess }) {

    const history = useHistory();
    const [token] = useState(localStorage.getItem('token'));

    const app = Serve();
    const {
        processSelect,
        setLoading,
        listaLojas
    } = useContext(NegociacaoContext);

    const processId = processSelect?.id;
    const gerenteProcesso = processSelect?.gerente?.data[0] || "";
    // console.log('Lojas: ', listaLojas);

    const telNumber = useRef();
    const countryCodeRef = useRef();

    const [buttonSave] = useState({
        opacity: 'inherit',
        pointerEvents: 'normal',
    });

    const [displayForm, setDisplayForm] = useState(false);

    useEffect(() => {
        const returnGerente = async () => {
            if (gerenteProcesso !== null) {
                setDisplayForm(true);
            }
            else {
                setDisplayForm(false);
            }
        }
        returnGerente();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleShowForm(e) {
        setDisplayForm(true);
    };

    function handlechangeCpf(e) {
        let cpf = document.querySelector('.cpf');

        if (cpf.value.length) {
            cpfMask(cpf.value);
            cpf.value = cpfMask(cpf.value);
        }

        Axios.post(app + 'verificar_usuario_cpf', {
            "cpf": cpf.value
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                }
                //console.logres.data.usuario);
                let usuario = res.data.usuario;
                if (usuario !== null) {
                    //console.log"usuario existe");
                    gerenteProcesso.telefone = usuario.telefone;
                    gerenteProcesso.ddi = usuario.ddi;
                    document.querySelector("#usuario_id-1").value = usuario.id;
                    // document.querySelector("#telefone-1").value = usuario.telefone;
                    document.querySelector("#nome-1").value = usuario.name;
                    document.querySelector("#email-1").value = usuario.email;
                    document.querySelector("#endereco-1").value = usuario.endereco;
                    document.querySelector("#nascimento-1").value = usuario.data_nascimento;
                    document.querySelector("#rg-1").value = usuario.rg;
                    document.querySelector("#nacionalidade-1").value = usuario.nacionalidade;
                } else {
                    //console.log"usuario não existe");
                    gerenteProcesso.telefone = '';
                    gerenteProcesso.ddi = '';
                    document.querySelector("#usuario_id-1").value = "";
                    // document.querySelector("#telefone-1").value = "";
                    document.querySelector("#nome-1").value = "";
                    document.querySelector("#email-1").value = "";
                    document.querySelector("#endereco-1").value = "";
                    document.querySelector("#nascimento-1").value = "";
                    document.querySelector("#rg-1").value = "";
                    document.querySelector("#nacionalidade-1").value = "";
                }
            })
    };

    function handlechangePhone(e, country) {
        countryCodeRef.current = country.dialCode;
        telNumber.current = e.replace(country.dialCode, '');
    };

    function handlechangeRg(e) {
        let rg = document.querySelector('.rg');

        if (rg.value.length) {
            rgMask(rg.value);
            rg.value = rgMask(rg.value);
        }
    };

    function handlechangeDate(e) {
        let nascimento = document.querySelector('#nascimento-1');

        if (nascimento.value.length) {
            dateMask(nascimento.value);
            nascimento.value = dateMask(nascimento.value);
        }
    };

    async function handleDelete(e) {
        e.preventDefault()
        setLoading(false);

        const attrBtn = 1;
        const usuario_id = document.querySelector('#usuario_id-' + attrBtn).value;

        document.querySelector('#usuario_id-' + attrBtn).value = "";
        document.querySelector('#cpf-' + attrBtn).value = "";
        document.querySelector('#telefone-' + attrBtn).value = "";
        document.querySelector('#nome-' + attrBtn).value = "";
        document.querySelector('#email-' + attrBtn).value = "";
        document.querySelector('#endereco-' + attrBtn).value = "";
        document.querySelector('#estado-civil-' + attrBtn).value = "";
        document.querySelector('#nascimento-' + attrBtn).value = "";
        document.querySelector('#rg-' + attrBtn).value = "";
        document.querySelector('#nacionalidade-' + attrBtn).value = "";
        document.querySelector('#loja-' + attrBtn).value = "";

        await Axios.post(app + 'deletar_relacao_usuario_processo', {
            "usuario_id": usuario_id,
            "tipo": "gerente",
            "processo_id": processId
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                }
                refreshProcess(processId)

            })
            .catch(function (error) {
                console.log(error);
            })
    };

    function handleClear(e) {
        e.preventDefault();

        e.preventDefault();
        gerenteProcesso.ddi = '';
        gerenteProcesso.telefone = '';
        document.querySelector("#cpf-1").value = "";
        document.querySelector("#nome-1").value = "";
        document.querySelector("#email-1").value = "";
        document.querySelector("#endereco-1").value = "";
        document.querySelector("#nascimento-1").value = "";
        document.querySelector("#rg-1").value = "";
        document.querySelector("#nacionalidade-1").value = "";
        document.querySelector("#loja-1").value = "";
    };

    async function handleSave(e) {
        e.preventDefault();

        setLoading(false);
        const attrBtn = 1;
        if (telNumber.current !== '' && telNumber.current !== undefined) gerenteProcesso.telefone = telNumber.current;
        if (countryCodeRef.current !== '' && countryCodeRef.current !== undefined) gerenteProcesso.ddi = countryCodeRef.current;
        const cpf_cnpj = document.querySelector('#cpf-' + attrBtn).value;
        const ddi = gerenteProcesso.ddi;
        const telefone = gerenteProcesso.telefone;
        const nome = document.querySelector('#nome-' + attrBtn).value;
        const email = document.querySelector('#email-' + attrBtn).value;
        const endereco = document.querySelector('#endereco-' + attrBtn).value;
        const estadoCivil = document.querySelector('#estado-civil-' + attrBtn).value;
        const nascimento = document.querySelector('#nascimento-' + attrBtn).value;
        const rg = document.querySelector('#rg-' + attrBtn).value;
        const nacionalidade = document.querySelector('#nacionalidade-' + attrBtn).value;
        const loja = document.querySelector('#loja-' + attrBtn).value;

        await Axios.post(app + 'salvar_usuario', {
            "name": nome,
            "email": email,
            "ddi": ddi,
            "telefone": telefone,
            "endereco": endereco,
            "cpf_cnpj": cpf_cnpj,
            "rg": rg,
            "estado_civil": estadoCivil,
            "data_nascimento": nascimento,
            "nacionalidade": nacionalidade,
            "tipo": "gerente",
            "processo_id": processId,
            "loja_id": loja
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                }
                refreshProcess(processId);
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    async function handleEdit(e) {
        e.preventDefault();
        setLoading(false);

        const attrBtn = 1;
        if (telNumber.current !== '' && telNumber.current !== undefined) gerenteProcesso.telefone = telNumber.current;
        if (countryCodeRef.current !== '' && countryCodeRef.current !== undefined) gerenteProcesso.ddi = countryCodeRef.current;
        const usuario_id = document.querySelector('#usuario_id-' + attrBtn).value;
        const cpf_cnpj = document.querySelector('#cpf-' + attrBtn).value;
        const ddi = gerenteProcesso.ddi;
        const telefone = gerenteProcesso.telefone;
        const nome = document.querySelector('#nome-' + attrBtn).value;
        const email = document.querySelector('#email-' + attrBtn).value;
        const endereco = document.querySelector('#endereco-' + attrBtn).value;
        const estadoCivil = document.querySelector('#estado-civil-' + attrBtn).value;
        const nascimento = document.querySelector('#nascimento-' + attrBtn).value;
        const rg = document.querySelector('#rg-' + attrBtn).value;
        const nacionalidade = document.querySelector('#nacionalidade-' + attrBtn).value;
        const loja = document.querySelector('#loja-' + attrBtn).value;
        // console.log("processo_id :", processId);

        await Axios.post(app + 'atualizar_usuario/' + usuario_id, {
            "usuario_id": usuario_id,
            "name": nome,
            "email": email,
            "ddi": ddi,
            "telefone": telefone,
            "endereco": endereco,
            "cpf_cnpj": cpf_cnpj,
            "rg": rg,
            "estado_civil": estadoCivil,
            "data_nascimento": nascimento,
            "nacionalidade": nacionalidade,
            "tipo": "gerente",
            "processo_id": processId,
            "loja_id": loja
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                }
                refreshProcess(processId);
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    return (
        <div>
            {displayForm === false &&
                <Button marginRight={16} appearance="primary" intent="none" onClick={(e) => handleShowForm(e)}>
                    <AddIcon size={12} style={{ fill: "#fff", marginRight: 8 }} /> Adicionar gerente
                </Button>
            }

            {displayForm === true &&
                <div className="formGroup formAdd">
                    <form id="formGroup">
                        <Pane>
                            <input id="usuario_id-1" type="hidden" value={gerenteProcesso !== undefined ? gerenteProcesso.id : ''} />

                            <div className="rowForm">
                                <TextInputField
                                    label="CPF*"
                                    description=""
                                    placeholder="000.000.000-00"
                                    //value={gerenteProcesso !== null ? gerenteProcesso.cpf : null}
                                    defaultValue={gerenteProcesso !== undefined ? gerenteProcesso.cpf_cnpj : ''}
                                    id={"cpf-1"}
                                    className="cpf"
                                    maxlength="14"
                                    onChange={(e) => handlechangeCpf(e)}
                                />

                                <Pane display="flex" flexDirection="column" marginLeft={16}>
                                    <label style={{ marginBottom: '12px' }}>Telefone/Celular*</label>
                                    <PhoneInput
                                        country={"br"}
                                        onChange={(e, country) => { handlechangePhone(e, country); }}
                                        value={gerenteProcesso ? gerenteProcesso.ddi + gerenteProcesso.telefone : '55'}
                                        // disableCountryCode={true}
                                        // countryCodeEditable={false}
                                        preferredCountries={['br', 'us']}
                                        inputClass={"PhoneInput telefone"}
                                        isValid={(inputNumber, country) => {
                                            if (country.countryCode === '55') {
                                                return inputNumber.length < 10 ? false : true;
                                            } else {
                                                return inputNumber.length < 6 ? false : true;
                                            }
                                        }}
                                    />

                                </Pane>

                                {/* <TextInputField
                                    label="Telefone/Celular*"
                                    description=""
                                    placeholder="55 21 99999-9999"
                                    defaultValue={gerenteProcesso !== undefined ? gerenteProcesso.telefone : ''}
                                    id={"telefone-1"}
                                    className="telefone"
                                    onChange={(e) => handlechangePhone(e)}
                                    maxLength="15"
                                /> */}
                            </div>

                            <TextInputField
                                label="Nome completo*"
                                description=""
                                placeholder="José Maria da Silva"
                                defaultValue={gerenteProcesso !== undefined ? gerenteProcesso.name : ''}
                                id={"nome-1"}
                            />

                            <TextInputField
                                label="E-mail*"
                                description=""
                                id={"email-1"}
                                defaultValue={gerenteProcesso !== undefined ? gerenteProcesso.email : ''}
                                placeholder="gerente@gmail.com"
                            />

                            <TextInputField
                                label="Endereço"
                                description=""
                                id={"endereco-1"}
                                defaultValue={gerenteProcesso !== undefined ? gerenteProcesso.endereco : ''}
                                placeholder="Rua Betrano da Silva, 789"
                            />

                            <div className="rowForm">
                                <SelectField width={240}
                                    className="bgWhite field-select"
                                    id={"estado-civil-1"}
                                    label="Estado civil"
                                    defaultValue={gerenteProcesso !== undefined ? gerenteProcesso.estado_civil : ''}
                                >
                                    <option value="0">Selecione</option>
                                    <option value="1">Solteiro</option>
                                    <option value="2">Casado</option>
                                    <option value="3">Separado</option>
                                    <option value="4">Viúvo</option>
                                    <option value="5">Separado</option>
                                </SelectField>

                                <TextInputField
                                    label="Data de nascimento"
                                    description=""
                                    placeholder="DD/MM/AAAA"
                                    id={"nascimento-1"}
                                    className="nascimento"
                                    defaultValue={gerenteProcesso !== undefined ? gerenteProcesso.data_nascimento : ''}
                                    onChange={(e) => handlechangeDate(e)}
                                    maxlength="10"
                                />
                            </div>

                            <div className="rowForm">
                                <TextInputField
                                    label="RG"
                                    description=""
                                    placeholder="000.000.000-00"
                                    id={"rg-1"}
                                    className="rg"
                                    defaultValue={gerenteProcesso !== undefined ? gerenteProcesso.rg : ''}
                                    onChange={(e) => handlechangeRg(e)}
                                    maxlength="12"
                                />

                                <TextInputField
                                    label="Nacionalidade"
                                    description=""
                                    id={"nacionalidade-1"}
                                    defaultValue={gerenteProcesso !== undefined ? gerenteProcesso.nacionalidade : ''}
                                    placeholder="Brasileira"
                                />
                            </div>

                            {/* <Select width={240} value={idStatusProcessoModal} onChange={(e) => handleSelectStatus(e)}>
                            {listaStatus.map(status => <option value={status.id}>{status.status}</option>)}
                        </Select> */}

                            <SelectField width={170}
                                label="Loja"
                                className="bgWhite field-select"
                                id={"loja-1"}
                                defaultValue={(gerenteProcesso !== undefined && gerenteProcesso.loja.data.length > 0) ? gerenteProcesso.loja.data[0].id : ''}
                            >
                                <option value="0" hidden>Selecione</option>
                                {
                                    listaLojas.map(loja => <option value={loja.id}>{loja.nome}</option>)
                                }
                            </SelectField>

                            <Pane display="flex" paddingBottom={20}>
                                <Pane flex={1}>
                                    <Button marginRight={16} appearance="primary" intent="danger" onClick={(e) => handleDelete(e)}>
                                        <DeleteIcon
                                            size={10}
                                            style={{ 'margin-right': '10px', 'fill': '#fff' }} />Excluir
                                    </Button>
                                </Pane>
                                <Button marginRight={16} intent="none" onClick={(e) => handleClear(e)}>
                                    <ResetIcon
                                        size={10}
                                        style={{ 'margin-right': '10px', 'fill': '#8F95B2' }} /> Limpar tudo
                                </Button>

                                <Button appearance="primary" intent="none" onClick={(e) => { document.querySelector('#usuario_id-1').value !== "" ? handleEdit(e) : handleSave(e) }} style={buttonSave}>
                                    <TickIcon
                                        size={10}
                                        style={{ 'margin-right': '10px', 'fill': '#fff' }} /> Salvar
                                </Button>
                            </Pane>
                        </Pane>
                    </form>
                </div>
            }
        </div>
    )
}