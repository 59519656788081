import React, { useContext, useState } from 'react';
import './index.css';
import { Pane } from 'evergreen-ui';
import VendasContext from '../../context/VendasContext';
import Navbar from '../../components/Navbar';

import TablePanel from './component/TablePanel';
import { useHistory } from 'react-router-dom';


export default function Vendas(props) {
    const [perfil_login] = useState(localStorage.getItem('perfil_login'));
    const nameGerente = localStorage.getItem('nome_usuario');
    const history = useHistory();

    const subtitulo = {
        fontSize: 14,
        color: '#696F8C',
        fontWeight: 400,
        marginTop: 10,
    }

    const link = {
        color: '#3366FF',
        textDecoration: 'none',
    }

    if (perfil_login !== "Gerente" && perfil_login !== "Gerente Geral" && perfil_login !== "Diretor Comercial") {
        history.push('/');
    }

    const contentPaper = (e) => {
        if (perfil_login === "Gerente") {
            return ' :) aqui você pode acompanhar todas as suas vendas, desde quando são geradas até quando são enviadas para o pós-venda.'
        }

        else if (perfil_login === "Gerente Geral") {
            return 'Acompanhe todas as vendas da loja, antes e depois de enviadas para o Pós-venda :)'
        }

        else if (perfil_login === "Diretor Comercial") {
            return 'Acompanhe todas as vendas das lojas, antes e depois de enviadas para o Pós-venda :)'
        }

        else {
            return '';
        }
    }

    return (

        <Pane>
            <Navbar Context={VendasContext} />
            <div className="painel mt68">
                <h1 className="title">Histórico de Vendas</h1>

                <p style={subtitulo}>Olá, {nameGerente} {contentPaper()} </p>
                <TablePanel />
            </div>
        </Pane>
    )
}