import React, { useContext, useState } from 'react';
import { Pane, Table } from 'evergreen-ui';
import ShowDocument from '../../../../../../app/showDocument';
import DirecaoContext from '../../../../../../context/DirecaoContext';

// MSG QUANDO CAMPO VAZIO
const msgCampo = '----';

function ColTable(props) {
    return (
        <Table.TextCell maxWidth={props.width}>
            {props.title && <div className={`mb7 color101840 `}>{props.title}</div>}

            <span className='fw400 color696F8C'>
                {props.subtitle || msgCampo}
            </span>
        </Table.TextCell>
    )
};

export default function Venda(props) {
    const [token] = useState(localStorage.getItem('token'));
    const {
        dadosVenda,
        tipoLaudemio,
    } = useContext(DirecaoContext);
    console.log(tipoLaudemio);



    //lê os laudemios retornados em dadosVenda
    const laudemiosEnviados = dadosVenda.laudemios.map(laudemio => parseInt(laudemio.valor_laudemio));
    //Comparar dadosVenda a uma lista de todos os laudemios "tipoLaudemio" e retorna o id e nomes para exibir
    const laudemiosSemNome = dadosVenda.laudemios.filter((laudemio) => laudemio.tipo_laudemio === "2" || laudemio.tipo_laudemio === "1");
    const laudemioValorToName = tipoLaudemio.filter((laudemio) => laudemiosEnviados.includes(laudemio.id));
    const arrayLaudemio = laudemiosSemNome;
    arrayLaudemio.push(...laudemioValorToName)

    function returnarEscritura(escritura) {
        let returnEscritura = '';
        switch (escritura) {
            case "1":
                returnEscritura = "Escritura Pública de Compra e Venda";
                break;
            case "2":
                returnEscritura = "Escritura de Pública Promessa de Compra e Venda";
                break;
            case "3":
                returnEscritura = "Escritura Pública Promessa Cessão de Direitos Aquisitivos";
                break;
            case "4":
                returnEscritura = "Escritura Pública de Cessão de Direitos Aquisitivos";
                break;
            case "5":
                returnEscritura = "Escritura Pública de Promessa de Compra e Venda e Bem Futuro";
                break
            case "6":
                returnEscritura = "Escritura Publica de Cessão de Direitos Aquisitivos";
                break;
            case "7":
                returnEscritura = "Escritura Pública de Doação e Escritura Pública de Inventário";
                break;
            case "8":
                returnEscritura = "Instrumento Particular com Força de Escritura Pública";
                break;

            default:
                break;
        }

        return returnEscritura;
    }

    function tipo_laudemio(tipo) {
        let tipo_laudemio = '';
        switch (tipo) {
            case 1:
                tipo_laudemio = "União";
                break;
            case 2:
                tipo_laudemio = "Prefeitura";
                break;
            case 3:
                tipo_laudemio = "Familia";
                break;
            case 4:
                tipo_laudemio = "Igreja";
                break;

            default:
                break;
        }

        return tipo_laudemio;
    }

    function valor_tipo_laudemio(tipo) {
        let tipo_laudemio = '';
        switch (tipo) {
            case 1:
                tipo_laudemio = "RIP nº";
                break;
            case 2:
                tipo_laudemio = "";
                break;
            case 3:
                tipo_laudemio = "Nome da Família";
                break;
            case 4:
                tipo_laudemio = "Nome da Igreja";
                break;

            default:
                break;
        }

        return tipo_laudemio;
    }

    function forma_pagamento(forma_pagamento) {
        let string_forma_pagamento = '';

        console.log(forma_pagamento);

        if (forma_pagamento !== null) {
            let arrayPagamento = forma_pagamento.split(',');

            arrayPagamento.forEach((element, index) => {
                switch (element) {
                    case '1':
                        string_forma_pagamento += "À vista" + (arrayPagamento.length === (index + 1) ? '' : ', ');
                        break;
                    case '2':
                        string_forma_pagamento += "Financiamento" + (arrayPagamento.length === (index + 1) ? '' : ', ');
                        break;
                    case '3':
                        string_forma_pagamento += "FGTS" + (arrayPagamento.length === (index + 1) ? '' : ', ');
                        break;
                    case '4':
                        string_forma_pagamento += "Consórcio" + (arrayPagamento.length === (index + 1) ? '' : ', ');
                        break;
                    case '5':
                        string_forma_pagamento += "Parcelamento" + (arrayPagamento.length === (index + 1) ? '' : ', ');
                        break;
                    case '6':
                        string_forma_pagamento += "PIX" + (arrayPagamento.length === (index + 1) ? '' : ', ');
                        break;

                    default:
                        break;
                }
            });
        }
        else {
            string_forma_pagamento = '';
        }
        return string_forma_pagamento;
    };

    return (
        <div className="">
            {
                dadosVenda.length === 0
                    ?
                    <Table.Row>
                        <Table.TextCell className="historicoStatus">
                            <span>Nenhuma Venda foi cadastrada no momento.</span>
                        </Table.TextCell>
                    </Table.Row>

                    :
                    <>
                        <Pane>
                            <React.Fragment>
                                <h2 className="subtitle subtitle-table">Dados do Imóvel</h2>
                            </React.Fragment>
                        </Pane>
                        <Table.Row>
                            <ColTable title={"Código"} subtitle={dadosVenda.codigo} />
                        </Table.Row>

                        <Table.Row>
                            <ColTable title={"CEP"} width="100px" subtitle={dadosVenda.cep} />
                            <ColTable title={"Bairro"} width="100px" subtitle={dadosVenda.bairro} />
                            <ColTable title={"Logradouro"} subtitle={dadosVenda.logradouro} />
                        </Table.Row>

                        <Table.Row>
                            <ColTable title={"Número"} width="100px" subtitle={dadosVenda.numero} />
                            <ColTable title={"Unidade"} width="100px" subtitle={dadosVenda.unidade} />
                            <ColTable title={"Complemento"} subtitle={dadosVenda.complemento} />
                        </Table.Row>

                        <Table.Row>
                            <ColTable title={"Cidade"} width="100px" subtitle={dadosVenda.cidade} />
                            <ColTable title={"Estado"} subtitle={dadosVenda.uf} />
                        </Table.Row>

                        <Table.Row>
                            <ColTable title={"Vagas na garagem (escrituradas)"} subtitle={dadosVenda.informacao?.vaga} />
                        </Table.Row>

                        <Pane>
                            <React.Fragment>
                                <h2 className="subtitle subtitle-table">Registro e Escritura</h2>
                            </React.Fragment>
                        </Pane>

                        <Table.Row>
                            <ColTable title={"Tipo"} subtitle={returnarEscritura(dadosVenda.informacao?.escritura)} />
                        </Table.Row>

                        <Table.Row>
                            <ColTable title={"Lavrado em"} width="120px" subtitle={dadosVenda.informacao?.lavrada} />
                            <ColTable title={"Livro"} width="120px" subtitle={dadosVenda.informacao?.livro} />
                            <ColTable title={"Folhas"} width="120px" subtitle={dadosVenda.informacao?.folha} />
                            <ColTable title={"Ato"} width="120px" subtitle={dadosVenda.informacao?.ato} />
                            <ColTable title={"Cartório"} subtitle={dadosVenda.informacao?.cartorio} />
                        </Table.Row>

                        <Table.Row>
                            <ColTable title={"Registrado no RGI"} width="160px" subtitle={dadosVenda.informacao?.rgi} />
                            <ColTable title={"Matrícula nº"} width="120px" subtitle={dadosVenda.informacao?.matricula} />
                            <ColTable title={"Inscrição Municipal nº"} subtitle={dadosVenda.informacao?.inscricaoMunicipal} />
                        </Table.Row>

                        <Pane>
                            <React.Fragment>
                                <h2 className="subtitle subtitle-table">Laudêmio</h2>
                            </React.Fragment>
                        </Pane>

                        {dadosVenda.laudemios.length === 0 && (
                            <Table.Row>
                                <ColTable title={"Não há laudêmio cadastrado."} />
                            </Table.Row>
                        )}
                        {arrayLaudemio.map(laudemio =>
                            <Table.Row>
                                <ColTable title={"Tipo"} width="100px" subtitle={tipo_laudemio(laudemio.tipo_laudemio_id ? laudemio.tipo_laudemio_id : parseInt(laudemio.tipo_laudemio))} />
                                <ColTable
                                    title={valor_tipo_laudemio(laudemio.tipo_laudemio_id ? laudemio.tipo_laudemio_id : parseInt(laudemio.tipo_laudemio))}
                                    width="150px"
                                    subtitle={laudemio.nome || laudemio.valor_laudemio}
                                />
                            </Table.Row>
                        )}

                        <Pane>
                            <React.Fragment>
                                <h2 className="subtitle subtitle-table">Formas de pagamentos</h2>
                            </React.Fragment>
                        </Pane>

                        <Table.Row>
                            <ColTable width="120px" subtitle={forma_pagamento(dadosVenda.informacao.forma_pagamento)} />
                        </Table.Row>

                        <Table.Row>
                            <ColTable title={"Observações"} subtitle={dadosVenda.informacao?.observacao_pagamento} />
                        </Table.Row>

                        <Pane>
                            <React.Fragment>
                                <h2 className="subtitle subtitle-table">Valor de venda</h2>
                            </React.Fragment>
                        </Pane>

                        <Table.Row>
                            <ColTable title={"Valor de venda"} width="130px" subtitle={dadosVenda.informacao?.valor_venda} />
                            <ColTable title={"Valor Anunciado"} width="130px" subtitle={dadosVenda.informacao?.valor_anunciado} />
                            <ColTable title={"Valor do Sinal"} width="130px" subtitle={dadosVenda.informacao?.valorSinal} />
                        </Table.Row>

                        <Table.Row>
                            <ColTable title={"Data da venda"} width="130px" subtitle={dadosVenda.informacao?.data_assinatura} />
                            <ColTable title={"Prazo da escritura"} subtitle={dadosVenda.informacao?.prazo} />
                        </Table.Row>

                        <Pane>
                            <React.Fragment>
                                <h2 className="subtitle subtitle-table" style={{ marginBottom: 0 }}>
                                    Multa
                                </h2>
                            </React.Fragment>
                        </Pane>

                        <Table.Row height={50}>
                            <ColTable subtitle={"Este valor está relacionado ao prazo limite de entrega das chaves."} />
                        </Table.Row>

                        <Table.Row>
                            <ColTable title={"Valor de multa diária"} subtitle={dadosVenda.informacao?.valoMulta} />
                        </Table.Row>
                        
                    </>
            }
        </div>
    )
}