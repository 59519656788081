import React, { useContext, useState } from 'react';
import { Pane, Table, Heading, Badge, Button } from 'evergreen-ui';
import NucleoContext from '../../../../context/NucleoContext';
// import UploadDoc from '../../../../components/UploadDoc';
import Axios from 'axios'
import Serve from '../../../../app/serve';
import { useHistory } from 'react-router-dom';
import MultiUploadDoc from '../../../../components/MultiUploadDoc';

export default function Imovel({ refreshTable }) {

  const history = useHistory();
  const app = Serve();
  const [token] = useState(localStorage.getItem('token'));
  const usuario_id = localStorage.getItem('usuario_id');

  const {
    processSelect,
    listLaudemios,
    formValues,
    setLoading,    
    listEscrituras,
    multiDocs,
  } = useContext(NucleoContext);
  console.log(formValues);
  const [imovelProcesso] = useState(processSelect.processo.imovel);

  console.log('processSelect IMOVEL: ' , processSelect);
  const pedidoProcesso = processSelect;

  const typeLaudemios = [
    { id: '1', nome: 'União' },
    { id: '2', nome: 'Prefeitura' },
    { id: '3', nome: 'Familia do imóvel' },
    { id: '4', nome: 'Igreja' }
  ];

  const escritura = () => {
    return listEscrituras.find(value => Number(imovelProcesso.informacao.escritura) === value.id)?.escritura;
  };

  const handleSalvarDocumentos = () => {
    setLoading(true);
    console.log(formValues);
    const data = new FormData();

    // formValues.imovel.forEach((element) => {
    //   if (typeof (element.arquivo) === 'string' || element.arquivo instanceof String) {
    //     let arquivoVazio = new File(["foo"], "nao_teve_alteracao_foo.txt", {
    //       type: "text/plain",
    //     });
    //     console.log("vazio");
    //     data.append('arquivos[]', arquivoVazio);
    //   } else {
    //     console.log("algo");
    //     data.append('arquivos[]', element.arquivo);
    //   }
    //   data.append('tipo_documento_ids[]', element.documento);
    //   data.append('documentos_ids[]', element.documentoId ? element.documentoId : '');
    // })

    data.append('documento_nucleo', 'ok');
    data.append('imovel_id', imovelProcesso.id);
    data.append('processo_id', processSelect.processo.id);
    data.append('solicitacao_id', processSelect.id);

    multiDocs.imovel?.forEach((doc, index_Doc) => {
        if(doc.file) {
            data.append(`documentos_ids[${index_Doc}]`, doc.id)
            doc.item.forEach((item, index_Item) => {
                console.log(item);
                data.append(`multiplo_documento_id[${index_Doc}][${index_Item}]`, item.id ? item.id : "");
                data.append(`tipo_documento_ids[${index_Doc}][${index_Item}]`, item.value || '');
                data.append(`data_emissao[${index_Doc}][${index_Item}]`, item.emissao || '');  // DATA INICIAL
                data.append(`validade_dias[${index_Doc}][${index_Item}]`, item.validade || ''); // DIAS
                data.append(`data_vencimento[${index_Doc}][${index_Item}]`, item.vencimento || '');  // DATA FINAL
            })
        }
    });

    Axios.post(app + 'salvar_documentos_imovel_nucleo', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      }
    }).then(res => {
      if (res.data.status && res.data.status === (401 || 498)) {
        localStorage.clear();
        history.push('/');
      } else {
        let mensagem = res.data.mensagem_processo;
        if (res.data.mensagem_documento !== '') {
          mensagem += '\n' + res.data.mensagem_documento;
        }
        console.log("mensagem Erro: ", mensagem);
        refreshTable();
      }
    }).catch(function (error) {
      console.log(error);
    })
  };

  return (
    <Pane padding={10}>
      <Heading size={600} className="mb10">
        Dados do Imóvel
      </Heading>
      <Pane>
        <Table.Row className="pb60 pt15 alignStart h65">
          <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Código</span><br />
            {imovelProcesso.codigo || "---"}
          </Table.TextCell>
        </Table.Row>

        <Table.Row className="pb60 pt15 alignStart h65">
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">CEP</span><br />
            {imovelProcesso.cep || "---"}
          </Table.TextCell>
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Bairro</span><br />
            {imovelProcesso.bairro || "---"}
          </Table.TextCell>
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Logradouro</span><br />
            {imovelProcesso.logradouro || "---"}
          </Table.TextCell>
        </Table.Row>

        <Table.Row className="pb60 pt15 alignStart h65">
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Número</span><br />
            {imovelProcesso.numero || "---"}
          </Table.TextCell>
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Unidade</span><br />
            {imovelProcesso.unidade || "---"}
          </Table.TextCell>
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Complemento</span><br />
            {imovelProcesso.complemento || "---"}
          </Table.TextCell>
        </Table.Row>

        <Table.Row className="pb60 pt15 alignStart h65">
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Cidade</span><br />
            {imovelProcesso.cidade || "---"}
          </Table.TextCell>
          <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Estado</span><br />
            {imovelProcesso.uf || "---"}
          </Table.TextCell>
        </Table.Row>

        <Table.Row className="pb60 pt15 alignStart h65">
          <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0}>
            <span className="fontStatus">Vagas na garagem (escrituradas)</span><br />
            {imovelProcesso.informacao.vaga || "---"}
          </Table.TextCell>
        </Table.Row>
      </Pane>

      <Heading size={600} className="mt20 mb10">
        Registro e Escritura
      </Heading>
      <Pane>
        <Table.Row className="pb60 pt15 alignStart h65">
          <Table.TextCell flexBasis={500} flexShrink={0} flexGrow={0}>
            <span className="fontStatus">Tipo</span><br />
            {escritura()}
          </Table.TextCell>
        </Table.Row>

        <Table.Row className="pb60 pt15 alignStart h65">
          <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Lavrada em</span><br />
            {imovelProcesso.informacao.lavrada || '---'}
          </Table.TextCell>
          <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Livro</span><br />
            {imovelProcesso.informacao.livro || '---'}
          </Table.TextCell>
          <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Folhas</span><br />
            {imovelProcesso.informacao.folha || '---'}
          </Table.TextCell>
          <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Ato</span><br />
            {imovelProcesso.informacao.ato || '---'}
          </Table.TextCell>
          <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Cartório</span><br />
            {imovelProcesso.informacao.cartorio || '---'}
          </Table.TextCell>
        </Table.Row>

        <Table.Row className="pb60 pt15 alignStart h65">
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Registrado no RGI</span><br />
            {imovelProcesso.informacao.rgi || '---'}
          </Table.TextCell>
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Matrícula nº</span><br />
            {imovelProcesso.informacao.matricula}
          </Table.TextCell>
          <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0}>
            <span className="fontStatus">Inscrição Municipal nº</span><br />
            {imovelProcesso.informacao.inscricaoMunicipal}
          </Table.TextCell>
        </Table.Row>
      </Pane>

      <Heading size={600} className="mt20 mb10">
        Laudêmio
      </Heading>
      <Pane>
        {imovelProcesso.laudemios?.map((laudemio) => (
          <Table.Row>
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">Tipo</span><br />
              {typeLaudemios.find((type) => laudemio.tipo_laudemio === type.id).nome}
            </Table.TextCell>
            <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className='wsNormal'>
              <span className="fontStatus">
                {laudemio.tipo_laudemio === '1' ? 'RIP nº' : laudemio.tipo_laudemio === '3' ? 'Nome da Família' : laudemio.tipo_laudemio === '4' ? 'Nome da Igreja' : ''}
              </span><br />
              {
                laudemio.tipo_laudemio === '1' ? laudemio.valor_laudemio :
                  laudemio.tipo_laudemio === '3' || laudemio.tipo_laudemio === '4' ? listLaudemios.find((list) => list.id === Number(laudemio.valor_laudemio)).nome : ''
              }
            </Table.TextCell>
          </Table.Row>
        ))}
        {!imovelProcesso.laudemios.length &&
          <Table.Row >
            <Table.TextCell flexBasis={400} flexShrink={0} flexGrow={0}>
              Não há laudemios cadastrados
            </Table.TextCell>
          </Table.Row>
        }
      </Pane>

      <Heading size={600} className="mt20 mb10">
        Documentos do imóvel <Badge>{imovelProcesso.documentos.data.length}</Badge>
      </Heading>
      <MultiUploadDoc pessoa={'imovel'} type="imóvel" context={NucleoContext} refreshProcess={refreshTable} idDonoDocumento={imovelProcesso.id} pedidoProcesso={pedidoProcesso} />

      <Pane className='alignRight'>
        <div> </div>
        <div className='mt50'>
          <spam className='fw400 f10 mr10'>
            Ao salvar alterações, os documentos serão compartilhados com o pós-venda
          </spam>
          <Button appearance='primary' onClick={() => handleSalvarDocumentos()}>✔ Salvar alterações</Button>
        </div>
      </Pane>
    </Pane>
  )
}