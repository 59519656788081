import React, { useContext, useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import Serve from '../../../app/serve';
import PhoneMask from '../../../components/phoneMask';
import { Pane, Avatar, Switch, Table, Spinner, Button, PlusIcon, Text, Select, Checkbox, toaster, Tooltip } from 'evergreen-ui';
import PainelAdminContext from '../../../context/PainelAdminContext'
import Lottie from 'react-lottie';
import animationData from '../../../images/Animations/loading.json';

export default function PosVenda({ userLoading }) {
  const app = Serve();
  const [token] = useState(localStorage.getItem('token'));
  const {
    setShowAddUser,
    setUserSelected,
    cargoList,
    shopsList,
    users,
    loading,
    setLoading,
    responsaveis
  } = useContext(PainelAdminContext);

  const [checked, setChecked] = useState([]);
  const [loadingCheck, setLoadingCheck] = useState([]);
  const [selectValue, setSelectValue] = useState({ "lojaId": '', "cargoId": '', "name": '' });
  const [usersTable, setUsersTable] = useState([]);
  const [responsaveisTable, setResponsaveisTable] = useState([]);

  useEffect(() => {
    userLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setResponsaveisTable(responsaveis);
    verificarResponsaveis();
    /* setUsersTable(users);
    setLoadingCheck(users.map((user) => user && false));
    setChecked(users.map((user) => user.activated)); */
  }, [responsaveis])

  const verificarResponsaveis = (e) => {
    const arrayloading = [];
    const array = [];
    console.log(responsaveis);
    responsaveis.forEach(responsavel => {
      array.push({
        'a_vista': responsavel.a_vista === 1 ? true : false ,
        'financiado': responsavel.financiado === 1 ? true : false
      });

      arrayloading.push({
        'a_vista': false ,
        'financiado': false
      });
    });
    setLoadingCheck(arrayloading);
    setChecked(array);    
  }

console.log(loadingCheck);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleSort = (e) => {
    console.log(e);
    console.log(usersTable);
    if (e === "UNIDADE" || e === "FUNÇÃO") {
      const query = e === "UNIDADE" ? 'loja' : 'cargo';
      usersTable.sort((a, b) => {
        const x = a[query]?.toLowerCase() || "z";
        const y = b[query]?.toLowerCase() || "z";
        return x < y ? -1 : x > y ? 1 : 0;
      })
    } else {
      usersTable.sort((a, b) => {
        const x = a.name.toLowerCase();
        const y = b.name.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      })
    }
    setUsersTable([...usersTable])
  };

  const cardUser = (user) => {
    setUserSelected(user);
    setShowAddUser(true);
  };

  const handleChangeStatus = (usuario_id, e, tipo, index) => {
    //setLoading(true);
    console.log(usuario_id);
    console.log(e.target.checked);
    console.log(tipo);
    console.log(index);
    let recarregarChecket = loadingCheck;
    let valor_checked = e.target.checked;
    
    loadingCheck[index][tipo] = true;
    setLoadingCheck([...loadingCheck])

/*     setTimeout(() => {
      setLoading(false);
    }, 500); */

    Axios.post(app + 'salvar_fila', {
      usuario_id,
      tipo, 
      valor_checked
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(res => {
      console.log(res);
      if (res.data) {
        toaster.success(res.data.msg  );
        checked[index][tipo] = valor_checked;
        setChecked([...checked])        
      }
    }).then(() => {
      loadingCheck[index][tipo] = false;
      setLoadingCheck([...loadingCheck])
    })
  };

  const handleFilters = (e) => {
    console.log(usersTable);
    console.log('name', e.target.name, 'value', e.target.value);
    let filter = users;
    if (e.target) {
      const name = e.target.name;
      const value = e.target.value;
      selectValue[name] = Number(value);
    } else {
      selectValue.name = e.toUpperCase();
    }

    console.log('selectValue', selectValue);
    if (selectValue.cargoId) filter = filter.filter((user) => user.cargoId === selectValue.cargoId);
    if (selectValue.lojaId) filter = filter.filter((u) => u.lojaId === selectValue.lojaId);
    if (selectValue.name) filter = filter.filter((user) => user.name.toUpperCase().includes(selectValue.name));

    console.log(filter);
    //setLoadingCheck(users.map((user) => user && true));
    setLoading(true);
    setTimeout(() => {
      setUsersTable(filter);
      setLoading(false);
    }, 500);
  };

  const removeFilters = () => {
    setUsersTable([...users]);
    setSelectValue({ "lojaId": '', "cargoId": '', "name": '' })
  };

  return (
    <Pane className='painel ml202'>
     {/*  <Button appearance='primary' onClick={() => setShowAddUser(true)}>
        <PlusIcon marginRight={8} color="white" /> Novo Usuário
      </Button> */}
      {/* AREA DE FILTROS */}
      {/* <Pane className='filters'>
        <div className='flex-center justifyBetween'>
          <div className='flex-center'>
            <div >
              <Select
                name='lojaId'
                width={240}
                background="white"
                value={selectValue.lojaId}
                onChange={(e) => handleFilters(e)}
              >
                <option value="" hidden selected >🔍︎ Loja</option>
                {shopsList.map((loja) => (
                  loja &&
                  <option value={loja.id} >{loja.nome}</option>
                ))}
              </Select>
            </div>
            <div className='ml20'>
              <Select
                name='cargoId'
                width={240}
                background="white"
                onChange={(e) => handleFilters(e)}
                value={selectValue.cargoId}
              >
                <option value="" hidden selected >🔍︎ Função</option>
                {cargoList.map((cargo) => (
                  <option value={cargo.id} >{cargo.nome}</option>
                ))}
              </Select>
            </div>
            <div className='ml20'>
              <Button onClick={() => removeFilters()}>Remover Filtros</Button>
            </div>
          </div>
        </div>
      </Pane> */}
      {/* TABELA DE USUARIOS */}
      <Pane>
        <Table>
          <Table.Head>
            <Table.TextHeaderCell className="table flexBasic215" onClick={(e) => handleSort(e.target.innerText)} >Responsaveis</Table.TextHeaderCell>            
            <Table.TextHeaderCell className="table flexBasic215" onClick={(e) => handleSort(e.target.innerText)} >A vista</Table.TextHeaderCell>
            <Table.TextHeaderCell className="table flexBasic215" onClick={(e) => handleSort(e.target.innerText)} >Financiado</Table.TextHeaderCell>
            <Table.TextHeaderCell className="table flexBasic215" onClick={(e) => handleSort(e.target.innerText)} >Ordem</Table.TextHeaderCell>
          </Table.Head>
          {loading ? <div className='mt30vh'><Lottie options={defaultOptions} height={80} width={200} /></div> :
            <Table.Body>
              {responsaveisTable.map((profile, index) => (
                <Table.Row key={profile.id}>
                  <Table.TextCell className="table name" onClick={() => cardUser(profile)}>
                    <div className='flex-center'>
                      <Avatar src={profile.avatar} name={profile.name} className={!profile.activated && "img-pb"} />
                      <Tooltip isShown={profile.name?.length > 21 ? undefined : false} content={profile.name}>
                        <span className='ml20'>{profile.name}</span>
                      </Tooltip>
                    </div>
                  </Table.TextCell>
                  <Table.TextCell className="table flexBasic215">
                    {<Switch  disabled={loadingCheck[index]['a_vista']} size={50} marginRight={20} checked={checked[index]['a_vista']} onChange={(e) => handleChangeStatus(profile.id, e, 'a_vista', index)} />}
                    {loadingCheck[index]['a_vista'] && <Spinner />}
                  </Table.TextCell>
                  <Table.TextCell className="table flexBasic215">
                    {<Switch disabled={loadingCheck[index]['financiado']} size={50} marginRight={20} checked={checked[index]['financiado']} onChange={(e) => handleChangeStatus(profile.id, e, 'financiado', index)} />}
                    {loadingCheck[index]['financiado'] && <Spinner />}
                  </Table.TextCell>
                  <Table.TextCell className="table flexBasic215">{profile.loja || "---"}</Table.TextCell>
                </Table.Row>
              ))}
            </Table.Body>}
        </Table>
      </Pane>
    </Pane>
  )
}