import React, {useContext, useState, useEffect} from 'react';
import { Pane, Dialog, Button } from 'evergreen-ui';
import NegociacaoContext from '../../../../context/NegociacaoContext';
import ImgFeedback from '../../../../images/img-feedback-status.svg';

export default function DialogFeedBack(){

    const {
        setIsShownStatus, 
        isShownFeedBack, setIsShownFeedBack,
        statusCompartilhado, 
        dadosProcesso,       
        setShowFieldsEscritura,
    } = useContext(NegociacaoContext);

    const [statusNome, setStatusNome] = useState('');

    useEffect(() => {
        const nomeStatus = (e) => {

            let status = '';
    
            switch (statusCompartilhado) {
                case '1':
                    status = 'Entrada'
                    break;
                
                case '2':
                    status = 'Análise'
                    break;
    
                case '3':
                    status = 'Certidões'
                    break;
    
                case '21':
                    status = 'Averbação'
                    break;
    
                case '4':
                    status = 'Taxas'
                    break;
    
                case '5':
                    status = 'Escritura'
                    break;
    
                case '6':
                    status = 'Registro'
                    break;
    
                case '7':
                    status = 'Finalizado'
                    break;
            
                default:
                    break;
            } 
    
            setStatusNome(status);
        }
        nomeStatus()
    },[setStatusNome,statusCompartilhado])

    function backDialogShare() {
        if(statusCompartilhado === '5'){
            setShowFieldsEscritura(true);
        }
        else{
            setShowFieldsEscritura(false);
        }

        setIsShownFeedBack(false);    
    };

    function closeFeedBack() {
        setIsShownStatus(false)
        setIsShownFeedBack(false);
        
    };

    // console.log(statusCompartilhado);    

    return (
        <Dialog
            isShown={isShownFeedBack}
            //title={}
            onCancel={() => closeFeedBack()}
            cancelLabel="Voltar"
            hasFooter={false}
            height={323}
            width={592}
        >
            {({ close }) => (
                <Pane style={{ 'position': 'relative', 'height': 300 }}>
                    <h2 className="title-dialog">Pronto!</h2>
                    <div className="dialog-content dialog-content-info">O status de {statusNome} foi compartilhado com o gerente.</div>
                    
                    <img src={ImgFeedback} alt="" style={{display: 'block', margin: '40px auto 0'}} />
                    
                    <div>
                        <Button marginTop={16} onClick={() => backDialogShare()} style={{ 'position': 'absolute', 'right': 20 }}>
                            Voltar
                        </Button>
                    </div>
                </Pane>
            )}
        </Dialog>
    )
}