import React, {useContext} from 'react'
import {Button, Tooltip, Dialog, Pane, Checkbox, ShareIcon} from 'evergreen-ui';
import VendasContext from '../../../../../../../context/VendasContext';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import Serve from '../../../../../../../app/serve';

export default function DialogDevolucaoRevisao({recarregarVendas}) {

    const {
        setLoading,
        dadosVenda,
        isInutilDay,
        isShowEntregarVenda, setIsShowEntregarVenda,
        checkDiaUtil,
        setCheckDiaUtil,
        //recarregarVendas,
        setIsShowCornerDialog,
        setModelCornerDialog,
        setRegressivaEntregarVenda,
    } = useContext(VendasContext);

    const history = useHistory();
    const usuario_id = localStorage.getItem('usuario_id');
    const processo_id = localStorage.getItem('processo_id');
    const token = localStorage.getItem('token');
    const app = Serve();

    const confirmarEntregaVenda = (e, venda) => {
        e.preventDefault();
        let imovel_id = venda.id;
        let processo_id = venda.processo_id;

        setLoading(false);

        Axios.post(app + 'vincular_responsavel_processo', {
            'imovel_id': imovel_id,
            'processo_id': processo_id,
            'usuario_id': usuario_id
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    recarregarVendas();

                    setIsShowEntregarVenda(false);
                    setIsShowCornerDialog(true);
                    setModelCornerDialog(4);

                    // Abre o contado para o Corner de Confirmação de Envio para o Backoffice
                    var timeleft = 5;
                    var downloadTimer = setInterval(function () {
                        if (timeleft <= 0) {
                            clearInterval(downloadTimer);
                            setRegressivaEntregarVenda(0)
                        } else {
                            setRegressivaEntregarVenda(timeleft)
                        }
                        timeleft -= 1;
                    }, 1000);

                }
            })
            .catch(function (error) {
                // handle error
                if (error.response) {
                    let resposta = error.response.data;

                    let erros = '';
                    if (resposta.errors) {
                        for (let [key, value] of Object.entries(resposta.errors)) {
                            erros += value + "\n";
                        }
                    }

                    if (resposta.error) {
                        erros = resposta.error;
                    }

                    alert(erros);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }

            })
        //setTimeout(function () { setLoading(true); }, 1000);*/        
    };

    return (
        <Dialog
            width={592}
            isShown={isShowEntregarVenda}
            title=""
            onCloseComplete={() => setIsShowEntregarVenda(false)}
            confirmLabel=""
            containerProps={{
                className: 'form-dialog-props'
            }}
            hasFooter={false}
            preventBodyScrolling={true}
            shouldCloseOnOverlayClick={false}
        >
            <Pane>
                <div className="form-dialog mb40">
                    <h4 style={{ marginTop: '15px' }}>
                        A venda devolvida foi revisada?
                    </h4>
                    <p className="fw400 mt15 mb16">
                        Entregue a venda somente se as correções solicitadas pela Equipe de pós-venda tenham sido realizadas. Caso contrário, o pós-venda poderá devolver a venda novamente. Atenção: Os prazos de vendas devolvidas só voltam ao normal, após as correções solicitadas.
                    </p>
                    {isInutilDay &&
                        <div>
                            <span className='fw600'>
                                Atenção: A Equipe de Pós-Venda dará início às tratativas do processo somente no próximo dia útil.
                            </span>
                            <Checkbox
                                label="Estou de acordo e desejo prosseguir com o envio."
                                className='checkbox'
                                checked={checkDiaUtil}
                                onChange={e => setCheckDiaUtil(e.target.checked)}
                            />
                        </div>
                    }
                </div>

                <div className="mb25" >
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button appearance="default" style={{ marginRight: '10px' }} onClick={(e) => setIsShowEntregarVenda(false)}>
                            Cancelar
                        </Button>

                        <Tooltip content="Clique no check se estiver de acordo." isShown={(isInutilDay && !checkDiaUtil) ? "" : false}>
                            <Button
                                marginY={0}
                                marginRight={12}
                                onClick={(e) => confirmarEntregaVenda(e, dadosVenda)}
                                intent="success"
                                appearance='primary'
                                className={(isInutilDay && !checkDiaUtil) ? 'btnGreenDisable' : ""}
                                iconBefore={<ShareIcon color="white" />}
                            >
                                Entregar venda
                            </Button>
                        </Tooltip>
                    </div>
                </div>
            </Pane>
        </Dialog>
    )
}