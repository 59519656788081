import React, { useContext } from 'react';
import {
  Pane,
  Dialog,
  toaster
} from 'evergreen-ui';

//Context
import VendasContext from '../../../../../../context/VendasContext';

//Components
import Steps from './Steps';
import RevisaoPosVenda from './components/RevisaoPosVenda';
import UploadRecibo from '../UploadRecibo';
import { useEffect } from 'react';

export default function Imovel() {

  const {
    alteracaoRevisao,
    setAlteracaoRevisao,
    dadosVenda,
    isShowFormBoletimDeVenda, setIsShowFormBoletimDeVenda,
    setPosicaoBoletimDeVenda,
  } = useContext(VendasContext);

  const closeBoletimDeVenda = (e) => {
    setIsShowFormBoletimDeVenda(false);
    setPosicaoBoletimDeVenda(0);
    setAlteracaoRevisao(false);
  };

  // Validação Revisão
  useEffect(() => {
    if(dadosVenda.status_processo === 22){
      if(dadosVenda.revisao.recibo_sinal_save === 0){
          setAlteracaoRevisao(false);
      }
      else{
          setAlteracaoRevisao(true);
      }
    }
  }, [])

  const handleVerificarRevisao = (e) =>{    
    if(e){
        setAlteracaoRevisao(true);
    }
    else {
        toaster.danger('Realize os ajustes solicitados pelo pós-venda para avançar.');
    }
  };
  
  return (
    <Pane>
      <Dialog
        width={950}
        isShown={isShowFormBoletimDeVenda}
        title=''
        onCloseComplete={(e) => closeBoletimDeVenda(e)}
        hasFooter={false}
        shouldCloseOnOverlayClick={false}
        containerProps={{
          className: 'form-dialog-props'
        }}
      >
        <div className="form-dialog-fixed-no-tabs">
          <Steps />
        </div> 
        <RevisaoPosVenda
          tab="imovel"
          situacao={dadosVenda.revisao.recibo_sinal_save}
          mensagem={dadosVenda.revisao.recibo_sinal_observacao !== null ? dadosVenda.revisao.recibo_sinal_observacao : ''}
        />
        <h4 style={{ marginBottom:'15px' }}>Upload do Recibo de Sinal (assinado)</h4>
        <UploadRecibo handleVerificarRevisao={handleVerificarRevisao} alteracaoRevisao={alteracaoRevisao} />
      </Dialog>
    </Pane>
  )
}