import React, { useState, useContext } from 'react';
import { Switch, Button, Pane, TextInputField, SelectField, ResetIcon, DeleteIcon, TickIcon, Tab, Badge, Radio, Label, PlusIcon, Checkbox } from 'evergreen-ui';
import PhoneInput from 'react-phone-input-2';
import { useForm } from 'react-hook-form';
import 'react-phone-input-2/lib/bootstrap.css'
import NegociacaoContext from '../../../../context/NegociacaoContext';
import validarCNPJ from '../../../../components/validarCNPJ';
import validarCPF from '../../../../components/validarCPF';
import MultiUploadDoc from '../../../../components/MultiUploadDoc';
import updateUsuarios from '../../../../apis/updateUsuarios';
import getCEP from '../../../../apis/getCEP';
import cepMask from '../../../../components/cepMask';
import dateMask from '../../../../components/dateMask';
import cpfMask from '../../../../components/cpfMask';
import cnpjMask from '../../../../components/cnpjMask';

export default function FormPessoas({ data, pessoa, refreshProcess }) {
  const tipoPessoa = pessoa === "vendedores" ? "vendedor" : "comprador";
  const [tabs] = useState(data.map((user) => user.name || user.nome_fantasia));
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [erroCep, setErroCep] = useState();

  // Cantinho do React Hook Form
  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      ...data
    }
  });

  const {
    setLoading,
    multiDocs,
    processSelect,
  } = useContext(NegociacaoContext);

  const inputError = {
    borderColor: '#FF0000',
    background: '#fff'
  };

  console.log("watch", watch());
  // console.log("Errors", errors);

  async function handleEdit(data, index) {
    setLoading(true);
    const dataToSave = data[index];

    let arrayData = new FormData();
    arrayData.append('cpf_cnpj', dataToSave.cpf_cnpj);
    arrayData.append('ddi', dataToSave.ddi || '');
    arrayData.append('telefone', dataToSave.telefone || '');
    arrayData.append('name', dataToSave.name || '');
    arrayData.append('nacionalidade', dataToSave.nacionalidade || '');
    arrayData.append('nomeMae', dataToSave.nome_mae || '');
    arrayData.append('nomePai', dataToSave.nome_pai || '');
    arrayData.append('profissao', dataToSave.profissao || '');
    arrayData.append('rg', dataToSave.rg || '');
    arrayData.append('rgExpedicao', dataToSave.rg_expedido || '');
    arrayData.append('dataExpedicao', dataToSave.data_rg_expedido || '');
    arrayData.append('email', dataToSave.email || '');
    arrayData.append('data_nascimento', dataToSave.data_nascimento || '');
    arrayData.append('cep', dataToSave.cep || '');
    arrayData.append('bairro', dataToSave.bairro || '');
    arrayData.append('logradouro', dataToSave.logradouro || '');
    arrayData.append('numero', dataToSave.numero || '');
    arrayData.append('unidade', dataToSave.unidade || '');
    arrayData.append('complemento', dataToSave.complemento || '');
    arrayData.append('cidade', dataToSave.cidade || '');
    arrayData.append('estado', dataToSave.estado || '');
    arrayData.append('genero', dataToSave.genero || '');
    arrayData.append('tipo_pessoa', dataToSave.tipo_pessoa || '');
    arrayData.append('usuario_id', dataToSave.id || '');
    arrayData.append('tipo', tipoPessoa);
    arrayData.append('estado_civil', dataToSave.estado_civil);
    arrayData.append('registro_casamento', dataToSave.registro_casamento);
    arrayData.append('uniao_estavel', dataToSave.uniao_estavel);
    arrayData.append('valor_conjuge', dataToSave.conjuge);

    arrayData.append('imovel_id', processSelect.imovel.id || '');
    arrayData.append('processo_id', processSelect.id || '');

    const saveMultiDocs = (e, index_documento, representante) => {
      if (e.file && e.item[0]) {        
        if (e) {
          e.item.forEach((tipo, index_tipo) => {
            arrayData.append(`tipo_documento_ids[${index_documento}][${index_tipo}]`, tipo.value);
            arrayData.append(`multiplo_documento_id[${index_documento}][${index_tipo}]`, tipo.id ? tipo.id : "");
            arrayData.append(`data_emissao[${index_documento}][${index_tipo}]`, tipo.emissao || '');  // DATA INICIAL
            arrayData.append(`validade_dias[${index_documento}][${index_tipo}]`, tipo.validade || ''); // DIAS
            arrayData.append(`data_vencimento[${index_documento}][${index_tipo}]`, tipo.vencimento || ''); // DATA FINAL
          })
        }
        arrayData.append(`documentos_ids[${index_documento}]`, e.id ? e.id : "");
        arrayData.append(`idDonoDocumento[${index_documento}]`, dataToSave.id);
        // arrayData.append(`papel[${index_documento}]`, representante || tipoPessoa.charAt(0).toUpperCase() + tipoPessoa.substring(1));
      }
    };

    // MULTIPLOS DOCUMENTOS EM 1 ARQUIVO PESSOA FISICA
    multiDocs[pessoa][index].fisica?.forEach((e, index_documento) => {
      saveMultiDocs(e, index_documento);
    });

    if (dataToSave.tipo_pessoa) {
      arrayData.append('razao_social', dataToSave.razao_social || '');
      arrayData.append('nome_fantasia', dataToSave.nome_fantasia || '');

      // MULTIPLOS DOCUMENTOS EM 1 ARQUIVO PESSOA JURIDICA
      multiDocs[pessoa][index].juridica?.forEach((e, index_documento) => {
        saveMultiDocs(e, index_documento);
      });

      // CAMPOS DOS REPRESENTANTES
      data[index].representante_socios.data.forEach((rep, index_rep) => {
        arrayData.append(`rp_id[${index_rep}]`, rep.id || '');
        arrayData.append(`rp_socio[${index_rep}]`, rep.pj_socio || 0);
        arrayData.append(`rp_representante[${index_rep}]`, rep.pj_representante || 0);
        arrayData.append(`rp_name[${index_rep}]`, rep.name || '');
        arrayData.append(`rp_cpf_cnpj[${index_rep}]`, rep.cpf_cnpj || '');
        arrayData.append(`rp_email[${index_rep}]`, rep.email || '');
        arrayData.append(`rp_rg[${index_rep}]`, rep.rg || '');
        arrayData.append(`rp_rg_expedido[${index_rep}]`, rep.rg_expedido || '');
        arrayData.append(`rp_data_rg_expedido[${index_rep}]`, rep.data_rg_expedido || '');
        arrayData.append(`rp_profissao[${index_rep}]`, rep.profissao || '');
        arrayData.append(`rp_nome_fantasia[${index_rep}]`, rep.nome_fantasia || '');
        arrayData.append(`rp_nacionalidade[${index_rep}]`, rep.nacionalidade || '');
        arrayData.append(`rp_estado_civil[${index_rep}]`, rep.estado_civil || '');
        arrayData.append(`rp_uniao_estavel[${index_rep}]`, rep.uniao_estavel || '');
        arrayData.append(`rp_registro_casamento[${index_rep}]`, rep.registro_casamento || '');
        arrayData.append(`rp_conjuge[${index_rep}]`, rep.conjuge || '');
        arrayData.append(`rp_genero[${index_rep}]`, rep.genero || '');

        // MULTIPLOS DOCUMENTOS EM 1 ARQUIVO REPRESENTANTE
        multiDocs[pessoa][index].representante_socios?.[index_rep].forEach((rep, index_documento_rep) => {
          if (rep.file && rep.item[0]) {
            if (rep) {
              rep.item.forEach((tipo, index_tipo) => {
                arrayData.append(`rp_tipo_documento_ids[${index_rep}][${index_documento_rep}][${index_tipo}]`, tipo.value);
                arrayData.append(`rp_multiplo_documento_id[${index_rep}][${index_documento_rep}][${index_tipo}]`, tipo.id ? tipo.id : "");
                arrayData.append(`rp_data_emissao[${index_rep}][${index_documento_rep}][${index_tipo}]`, tipo.emissao || '');  // DATA INICIAL
                arrayData.append(`rp_validade_dias[${index_rep}][${index_documento_rep}][${index_tipo}]`, tipo.validade || ''); // DIAS
                arrayData.append(`rp_data_vencimento[${index_rep}][${index_documento_rep}][${index_tipo}]`, tipo.vencimento || ''); // DATA FINAL
              })
            }
            arrayData.append(`rp_documentos_ids[${index_rep}][${index_documento_rep}]`, rep.id ? rep.id : "");
            //arrayData.append(`rp_idDonoDocumento[${index_rep}][${index_documento}]`, dataToSave.id);
            arrayData.append(`rp_papel[${index_rep}][${index_documento_rep}]`, 'Representante');
          }

        })
      })
    };

    const result = await updateUsuarios(dataToSave.id, arrayData);

    if (result?.status_usuario) {
      await refreshProcess(processSelect.id, undefined, tipoPessoa === 'vendedor' ? 3 : 4);
    }

    setLoading(false);
  };

  const handleCEP = async (cep, index) => {
    if (!Number(cep)) return setErroCep("Apenas numeros!")
    else setErroCep();

    if (cep.length === 8) {
      const res = await getCEP(cep);
      if (res.cep) {
        setValue(index + '.bairro', res.bairro);
        setValue(index + '.complemento', res.complemento);
        setValue(index + '.cidade', res.localidade);
        setValue(index + '.logradouro', res.logradouro);
        setValue(index + '.estado', res.uf);
        setValue(index + '.cpf_cnpj', cepMask(res.cep));
        let cep = document.querySelector('.cep-' + index);
        cep.value = cepMask(cep.value);
        setErroCep();
      } else {
        setErroCep("CEP não encontrado.")
      }
    }
  };

  const camposRepresentantes = {
    cpf_cnpj: '',
    data_rg_expedido: '',
    email: '',
    nacionalidade: '',
    name: '',
    pj_representante: false,
    profissao: '',
    rg: '',
    rg_expedido: '',
  };

  const addRepresentante = (index) => {
    let representantes = watch(index + '.representante_socios');
    if (representantes) {
      representantes.data.push(camposRepresentantes);
    } else {
      representantes = {
        data: [{
          pj_representante: true
        }]
      };
    }
    setValue(index + '.representante_socios', representantes);
    multiDocs[pessoa][index].representante_socios.push([{ item: [], file: '' }]);
  };

  const removeRepresentantes = async (index, indexRepresentante) => {
    const representante = watch(index + '.representante_socios.data');
    const pj_usuario_id = watch(index + '.id');

    if (representante.id) {
      const res = await removeRepresentantes(pj_usuario_id, representante[indexRepresentante].id, processSelect.id);

      if (res) {
        representante.splice(indexRepresentante, 1)
        setValue(index + '.representante_socios.data', representante);
        multiDocs[pessoa][index].representante_socios.splice(indexRepresentante, 1)
      }
    } else {
      representante.splice(indexRepresentante, 1)
      setValue(index + '.representante_socios.data', representante);
      multiDocs[pessoa][index].representante_socios.splice(indexRepresentante, 1)
    }
  };

  const onError = (error) => {
    tabs.forEach((tab, index) => {
      console.log("Erro ", error.compradores?.[tab]);
      if (error.compradores?.[tab]) {
        setSelectedIndex(tab);
      }
    })
  };

  return (
    <Pane className="pl10">
      {tabs.map((tab, index) => (
        <Tab
          key={tab}
          id={"titulo_top_vendedor_" + index}
          onSelect={() => setSelectedIndex(index)}
          isSelected={index === selectedIndex}
          aria-controls={`panel-${tab}`}
          data-tab={tab}
        >
          {(watch(index + '.name') || watch(index + '.nome_fantasia')).slice(0, 10) + '...'}
        </Tab>
      ))}

      {tabs.map((tab, index) => (
        <Pane
          key={tab}
          id={`panel-${tab}`}
          role="tabpanel"
          aria-labelledby={tab}
          aria-hidden={index !== selectedIndex}
          display={index === selectedIndex ? 'block' : 'none'}
        >
          {!watch(index + '.id') &&
            <Pane className="rowForm mt20 mb20">
              <Switch
                marginBottom={16}
                className='mr10'
                checked={watch(index + '.tipo_pessoa')}
                onChange={(e) => setValue(index + '.tipo_pessoa', e.target.checked)}
              />
              <label>Pessoa {watch(index + '.tipo_pessoa') ? 'Júridica' : 'Física'}</label>
            </Pane>
          }

          {!watch(index + '.tipo_pessoa')
            ? // FORM PESSOA FÍSICA
            <Pane>

              {watch(index + '.id') &&
                <Badge color="green" className="mt20 mb30">
                  Pessoa Física
                </Badge>}

              <div className="rowForm">

                <TextInputField
                  label='CPF*'
                  placeholder='000.000.000-00'
                  value={watch(index + '.cpf_cnpj')}
                  {...register(index + '.cpf_cnpj', {
                    required: "Campo obrigatório",
                    setValueAs: e => cpfMask(e),
                    maxLength: 14,
                    validate: (value) => validarCPF(value) || "CPF inválido"
                  })}
                  validationMessage={errors?.[index]?.cpf_cnpj?.message}
                  style={errors?.[index]?.cpf_cnpj && inputError}
                />

                <Pane display="flex" flexDirection="column" marginLeft={16}>
                  <label className='mb8'>Telefone/Celular*</label>
                  <PhoneInput
                    country={"br"}
                    onChange={(e, country) => { console.log(e, index, country); }}
                    value={watch(index + '.telefone') ? watch(index + '.ddi') + watch(index + '.telefone') : '55'}
                    preferredCountries={['br', 'us']}
                    inputClass={"PhoneInput telefone" + index}
                    isValid={(inputNumber, country) => {
                      if (country.countryCode === '55') {
                        return inputNumber.length < 10 ? false : true;
                      } else {
                        return inputNumber.length < 6 ? false : true;
                      }
                    }}
                  />
                </Pane>
              </div>

              <div className="rowForm">
                <div className="line-radius">
                  <Label>Gênero*</Label>
                  <Pane
                    aria-label="radio-group-genero"
                    role="group"
                    className={"radio radius radio-genero"}>
                    <Radio
                      size={16}
                      name={"genero-" + index}
                      className="radio-M"
                      value="M"
                      label="Masculino"
                      checked={watch(index + '.genero') === 'M'}
                    />
                    <Radio
                      size={16}
                      name={"genero-" + index}
                      value="F"
                      label="Feminino"
                      className="radio-F"
                      checked={watch(index + '.genero') === 'F'}
                    />
                  </Pane>
                </div>
              </div>

              <div className='mt10'>
                <TextInputField
                  label='Nome completo'
                  placeholder="José Maria da Silva"
                  width={468}
                  {...register(index + '.name')}
                />

              </div>

              <div className="rowForm">
                <TextInputField
                  width={180}
                  label="Data de nascimento"
                  placeholder="Ex: DD/MM/AAAA"
                  value={watch(index + '.data_nascimento')}
                  maxLength={10}
                  {...register(index + '.data_nascimento', {
                    setValueAs: e => dateMask(e)
                  })}
                />

                <TextInputField
                  width={180}
                  marginLeft={20}
                  label="Nacionalidade"
                  placeholder="Ex: Brasileiro"
                  {...register(index + '.nacionalidade')}
                />
              </div>

              <div className="rowForm">
                <TextInputField
                  width={463}
                  label="Nome da mãe"
                  placeholder="Ex: Maria da Silva"
                  {...register(index + '.nome_mae')}
                />
              </div>

              <div className="rowForm">
                <TextInputField
                  width={463}
                  label="Nome do Pai"
                  placeholder="Ex: José Maria da Silva"
                  {...register(index + '.nome_pai')}
                />
              </div>

              <div className="rowForm">
                <TextInputField
                  width={463}
                  label="Profissão"
                  placeholder="Ex: Corretor"
                  {...register(index + '.profissao')}
                />
              </div>

              <div className="rowForm">
                <TextInputField
                  width={216}
                  label="RG"
                  placeholder="Ex: 12.345.678-9"
                  {...register(index + '.rg')}
                />

                <TextInputField
                  width={216}
                  label="Expedida por:"
                  placeholder="Ex: Detran-RJ"
                  {...register(index + '.rg_expedido')}
                />

                <TextInputField
                  width={216}
                  marginBottom={0}
                  marginLeft={20}
                  value={watch(index + '.data_rg_expedido')}
                  label="Data de expedição"
                  placeholder="Ex: DD/MM/AAAA"
                  maxLength={10}
                  {...register(index + '.data_rg_expedido', {
                    setValueAs: e => dateMask(e)
                  })}
                />
              </div>

              <div className="rowForm">
                <TextInputField
                  width={463}
                  label="E-mail"
                  placeholder="Ex: gerente@gmail.com"
                  {...register(index + '.email')}
                />
              </div>

              <div className="rowForm">
                <SelectField
                  label="Estado Civil"
                  value={watch(index + '.estado_civil')}
                  className="bgWhite"
                  onChange={(e) => setValue(index + '.estado_civil', e.target.value)}
                >
                  <option value="0" selected hidden>Selecionar</option>
                  <option value="1">Casado</option>
                  <option value="2">Solteiro</option>
                  <option value="3">Divorciado</option>
                  <option value="4">Viúvo</option>
                  <option value="5">Separado</option>
                </SelectField>

                <div hidden={watch(index + '.estado_civil') !== "1"}>
                  <SelectField
                    label="Regime de casamento"
                    value={watch(index + '.registro_casamento')}
                    className="bgWhite"
                    onChange={(e) => setValue(index + '.registro_casamento', e.target.value)}
                  >
                    <option value="0" selected>Selecionar</option>
                    <option value="1">Separação total de bens</option>
                    <option value="2">Separação parcial de bens</option>
                    <option value="3">Separação legal de bens</option>
                    <option value="4">Comunhão de bens</option>
                    <option value="5">Comunhão parcial de bens</option>
                  </SelectField>
                </div>

                {watch(index + '.estado_civil') !== "1" &&
                  <Checkbox
                    label="União Estável"
                    checked={watch(index + '.uniao_estavel')}
                    style={{ alignItems: 'center', top: '3px' }}
                    className="checkbox representante-uniao-estavel"
                    onChange={(e) => setValue(index + '.uniao_estavel', e.target.checked)}
                  />}
              </div>

              {(watch(index + '.estado_civil') === "1" || watch(index + '.uniao_estavel'))
                ?
                <TextInputField
                  width={463}
                  label="Nome completo do Cônjuge/Companheiro(a)"
                  placeholder="Ex: José Maria da Silva"
                  {...register(index + '.conjuge')}
                />
                :
                ""
              }


              <div className="row-recibo mt30">
                <div className="subtitle">Endereço</div>
                <div className="rowForm">
                  <TextInputField
                    label="CEP"
                    placeholder="Ex: 00000-000"
                    maxLength="9"
                    className={'cep-' + index}
                    validationMessage={erroCep}
                    {...register(index + '.cep', {
                      value: watch(index + '.cep'),
                      onChange: (e) => handleCEP(e.target.value, index),
                    })}
                    required
                  />

                  <TextInputField
                    width={215}
                    label="Bairro"
                    placeholder="---"
                    {...register(index + '.bairro')}
                    disabled
                  />

                  <TextInputField
                    width={250}
                    marginBottom={0}
                    marginLeft={20}
                    label="Logradouro"
                    placeholder="---"
                    {...register(index + '.logradouro')}
                    disabled
                  />
                </div>
              </div>

              <div className="rowForm">
                <TextInputField
                  width={100}
                  label="Número"
                  placeholder="Ex: 456"
                  // onChange={(e) => handleSomenteNumero(e, index)}
                  {...register(index + '.numero')}
                  required
                />

                <TextInputField
                  width={200}
                  label="Unidade"
                  placeholder="Número do apartamento"
                  // onChange={(e) => handleUnidade(e, index)}
                  {...register(index + '.unidade')}
                />

                <TextInputField
                  width={280}
                  marginBottom={0}
                  marginLeft={20}
                  label="Complemento"
                  placeholder="Bloco, lote ou condomínio"
                  // onChange={(e) => (e)/*verificaInput(e, index)*/}                  
                  {...register(index + '.complemento')}
                />
              </div>

              <div className="rowForm">
                <TextInputField
                  width={250}
                  label="Cidade"
                  placeholder="---"
                  {...register(index + '.cidade')}
                  disabled
                />

                <TextInputField
                  width={250}
                  label="Estado"
                  placeholder="---"
                  {...register(index + '.estado')}
                  disabled
                />
              </div>

              <div className="subtitle mb5">
                Documentos do {tipoPessoa.charAt(0).toUpperCase() + tipoPessoa.substring(1)}
                <Badge margin={8} color="blue">
                  {multiDocs[pessoa][index]["fisica"].length}
                </Badge >
              </div>
              <MultiUploadDoc pessoa={pessoa} type="fisica" context={NegociacaoContext} indexPessoa={index} refreshProcess={refreshProcess} idDonoDocumento={watch(index + '.id')} />

            </Pane>

            : // FORM PESSOA JURIDICA
            <Pane>

              {watch(index + '.id') &&
                <Badge color="green" className="mt20 mb30">
                  Pessoa Juridica
                </Badge>}

              <div className="rowForm">

                <TextInputField
                  label='CNPJ*'
                  placeholder='00.000.000/0000-00'
                  value={watch(index + '.cpf_cnpj')}
                  {...register(index + '.cpf_cnpj', {
                    required: "Campo obrigatório",
                    maxLength: 18,
                    setValueAs: e => cnpjMask(e),
                    validate: (value) => validarCNPJ(value) || "CNPJ inválido",
                  })}
                  validationMessage={errors?.[index]?.cpf_cnpj?.message}
                  style={errors?.[index]?.cpf_cnpj && inputError}
                />
                <Pane display="flex" flexDirection="column" marginLeft={16}>
                  <label className='mb8'>Telefone/Celular*</label>
                  <PhoneInput
                    country={"br"}
                    onChange={(e, country) => { console.log(e, index, country); }}
                    value={watch(index + '.telefone') ? watch(index + '.ddi') + watch(index + '.telefone') : '55'}
                    preferredCountries={['br', 'us']}
                    inputClass={"PhoneInput telefone" + index}
                    isValid={(inputNumber, country) => {
                      if (country.countryCode === '55') {
                        return inputNumber.length < 10 ? false : true;
                      } else {
                        return inputNumber.length < 6 ? false : true;
                      }
                    }}
                  />
                </Pane>
              </div>

              <div>
                <TextInputField
                  label="Razão Social*"
                  placeholder="José Maria da Silva LTDA"
                  width={468}
                  {...register(index + '.razao_social', {
                    required: "Campo obrigatório"
                  })}
                />

                <TextInputField
                  label="Nome Fantasia*"
                  placeholder="José Maria da Silva"
                  width={468}
                  {...register(index + '.nome_fantasia', {
                    required: "Campo obrigatório"
                  })}
                />
              </div>

              <div className="row-recibo mt30">
                <div className="subtitle">Endereço</div>
                <div className="rowForm">
                  <TextInputField
                    label="CEP"
                    placeholder="Ex: 00000-000"
                    maxLength="9"
                    className={'cep-' + index}
                    validationMessage={erroCep}
                    {...register(index + '.cep', {
                      value: watch(index + '.cep'),
                      onChange: (e) => handleCEP(e.target.value, index),
                    })}
                  />

                  <TextInputField
                    width={215}
                    label="Bairro"
                    placeholder="---"
                    {...register(index + '.bairro')}
                    disabled
                  />

                  <TextInputField
                    width={250}
                    marginBottom={0}
                    marginLeft={20}
                    label="Logradouro"
                    placeholder="---"
                    {...register(index + '.logradouro')}
                    disabled
                  />
                </div>
              </div>

              <div className="rowForm">
                <TextInputField
                  width={100}
                  label="Número"
                  placeholder="Ex: 456"
                  // onChange={(e) => handleSomenteNumero(e, index)}
                  {...register(index + '.numero')}
                  required
                />

                <TextInputField
                  width={200}
                  label="Unidade"
                  placeholder="Número do apartamento"
                  // onChange={(e) => handleUnidade(e, index)}
                  {...register(index + '.unidade')}
                />

                <TextInputField
                  width={280}
                  marginBottom={0}
                  marginLeft={20}
                  label="Complemento"
                  placeholder="Bloco, lote ou condomínio"
                  // onChange={(e) => (e)/*verificaInput(e, index)*/}                  
                  {...register(index + '.complemento')}
                />
              </div>

              <div className="rowForm">
                <TextInputField
                  width={250}
                  label="Cidade"
                  placeholder="---"
                  {...register(index + '.cidade')}
                  disabled
                />

                <TextInputField
                  width={250}
                  label="Estado"
                  placeholder="---"
                  {...register(index + '.estado')}
                  disabled
                />
              </div>

              <div className="subtitle mb5">
                Documentos do {tipoPessoa.charAt(0).toUpperCase() + tipoPessoa.substring(1)}
                <Badge margin={8} color="blue">
                  {multiDocs[pessoa][index]?.juridica.length}
                </Badge >
              </div>
              <MultiUploadDoc pessoa={pessoa} type="juridica" context={NegociacaoContext} indexPessoa={index} refreshProcess={refreshProcess} idDonoDocumento={watch(index + '.id')} />

              <div className="subtitle mt30">
                Sócios e/ou Representantes Legais
              </div>
              {watch(index + '.representante_socios.data')?.map((representante, indexRepresentante) => (

                <div className="rowForm-responsavel-pj" key={indexRepresentante}>
                  <div className="rowForm">
                    <div className="line-checkbox">
                      <Checkbox
                        label="É representante legal da empresa."
                        disabled={indexRepresentante === 0}
                        className={indexRepresentante === 0 ? "checkbox checkbox-disabled" : "checkbox"}
                        checked={representante.pj_representante}
                        onChange={e => setValue(index + '.representante_socios.data.' + indexRepresentante + '.pj_representante', e.target.checked)}
                        marginTop={0}
                      />

                      <Checkbox
                        label="Sócio"
                        checked={representante.pj_socio}
                        className="checkbox"
                        onChange={e => setValue(index + '.representante_socios.data.' + indexRepresentante + '.pj_socio', e.target.checked)}
                        marginTop={0}
                      />
                    </div>
                  </div>

                  <div className="row-genero" style={{ display: 'block' }}>
                    <div className="rowForm">
                      <div className="line-radius">
                        <Label>Gênero*</Label>
                        <Pane
                          aria-label="radio-group-genero"
                          role="group"
                          className="radio radius">
                          <Radio
                            size={16}
                            value={'M'}
                            label="Masculino"
                            checked={representante.genero === 'M'}
                            onChange={() => setValue(index + '.representante_socios.data.' + indexRepresentante + '.genero', "M")}
                          />
                          <Radio
                            size={16}
                            value={'F'}
                            label="Feminino"
                            checked={representante.genero === 'F'}
                            onChange={() => setValue(index + '.representante_socios.data.' + indexRepresentante + '.genero', "F")}
                          />
                        </Pane>
                      </div>
                    </div>
                  </div>

                  <div className="rowForm">
                    <TextInputField
                      width={140}
                      label="CPF"
                      value={watch(index + '.representante_socios.data.' + indexRepresentante + '.cpf_cnpj')}
                      placeholder="000.000.000-00"
                      maxLength={14}
                      {...register(index + '.representante_socios.data.' + indexRepresentante + '.cpf_cnpj', {
                        required: "Campo obrigatório",
                        maxLength: '14',
                        setValueAs: e => cpfMask(e),
                        validate: (value) => validarCPF(value) || "CPF inválido"
                      })}
                    />

                    <TextInputField
                      width={380}
                      label="Nome Completo"
                      placeholder="Ex: José Maria da Silva"
                      {...register(index + '.representante_socios.data.' + indexRepresentante + '.name', {
                        // required: "Campo obrigatório",
                      })}
                    />
                  </div>

                  <div className="rowForm">
                    <TextInputField
                      width={463}
                      label="E-mail"
                      placeholder="Ex: teste@gmail.com"
                      {...register(index + '.representante_socios.data.' + indexRepresentante + '.email', {
                        // required: "Campo obrigatório",
                      })}
                    />
                  </div>

                  {/* DADOS DO REPRESENTANTE LEGAL */}
                  <Pane hidden={!representante.pj_representante}>
                    <div className="rowForm">
                      <TextInputField
                        width={180}
                        label="RG"
                        placeholder="Ex: 12.345.678-9"
                        {...register(index + '.representante_socios.data.' + indexRepresentante + '.rg', {
                          // required: "Campo obrigatório",
                        })}
                      />

                      <TextInputField
                        width={150}
                        label="Expedida por:"
                        placeholder="Ex: Detran-RJ"
                        {...register(index + '.representante_socios.data.' + indexRepresentante + '.rg_expedido', {
                          // required: "Campo obrigatório",
                        })}
                      />

                      <TextInputField
                        width={180}
                        marginBottom={0}
                        marginLeft={20}
                        label="Data de expedição"
                        placeholder="Ex: DD/MM/AAAA"
                        value={watch(index + '.representante_socios.data.' + indexRepresentante + '.data_rg_expedido')}
                        maxLength={10}
                        {...register(index + '.representante_socios.data.' + indexRepresentante + '.data_rg_expedido', {
                          // required: "Campo obrigatório",
                          setValueAs: e => dateMask(e)
                        })}
                      />
                    </div>

                    <div className="rowForm">
                      <TextInputField
                        width={300}
                        label="Profissão"
                        placeholder="Ex: Corretor"
                        {...register(index + '.representante_socios.data.' + indexRepresentante + '.profissao', {
                          // required: "Campo obrigatório",
                        })}
                      />

                      <TextInputField
                        width={150}
                        marginLeft={20}
                        label="Nacionalidade"
                        placeholder="Ex: Brasileiro"
                        {...register(index + '.representante_socios.data.' + indexRepresentante + '.nacionalidade', {
                          // required: "Campo obrigatório",
                        })}
                      />
                    </div>

                    <div className="rowForm">
                      <SelectField
                        label="Estado Civil"
                        value={representante.estado_civil}
                        className="bgWhite"
                        onChange={(e) => setValue(index + '.representante_socios.data.' + indexRepresentante + '.estado_civil', e.target.value)}
                      >
                        <option value="0" selected hidden>Selecionar</option>
                        <option value="1">Casado</option>
                        <option value="2">Solteiro</option>
                        <option value="3">Divorciado</option>
                        <option value="4">Viúvo</option>
                        <option value="5">Separado</option>
                      </SelectField>

                      <div hidden={watch(index + '.representante_socios.data.' + indexRepresentante + '.estado_civil') !== "1"}>
                        <SelectField
                          label="Regime de casamento"
                          value={representante.registro_casamento}
                          className="bgWhite"
                          onChange={(e) => setValue(index + '.representante_socios.data.' + indexRepresentante + '.registro_casamento', e.target.value)}
                        >
                          <option value="0" selected>Selecionar</option>
                          <option value="1">Separação total de bens</option>
                          <option value="2">Separação parcial de bens</option>
                          <option value="3">Separação legal de bens</option>
                          <option value="4">Comunhão de bens</option>
                          <option value="5">Comunhão parcial de bens</option>
                        </SelectField>
                      </div>

                      {representante.estado_civil !== "1" &&
                        <Checkbox
                          label="União Estável"
                          checked={representante.uniao_estavel}
                          style={{ alignItems: 'center', top: '3px' }}
                          className="checkbox representante-uniao-estavel"
                          onChange={(e) => setValue(index + '.representante_socios.data.' + indexRepresentante + '.uniao_estavel', e.target.checked)}
                        />}
                    </div>
                    {(representante.estado_civil === "1" || !!representante.uniao_estavel) &&
                      <TextInputField
                        width={463}
                        label="Nome completo do Cônjuge/Companheiro(a)"
                        placeholder="Ex: José Maria da Silva"
                        {...register(index + '.representante_socios.data.' + indexRepresentante + '.conjuge')}
                      />
                    }
                    <div className="row-documento-representante">
                      <div className='subtitle'>
                        Documentos do Representante
                        <Badge margin={8} color="blue">
                          {multiDocs[pessoa][index].representante_socios[indexRepresentante].length}
                        </Badge >
                      </div>
                      <MultiUploadDoc pessoa={pessoa} type="fisica" context={NegociacaoContext} indexPessoa={index} indexRepresentante={indexRepresentante} refreshProcess={refreshProcess} idDonoDocumento={representante.id} />
                    </div>

                  </Pane>
                  <Button
                    marginRight={16}
                    marginTop={25}
                    appearance="primary"
                    intent="danger"
                    className="button remove"
                    style={{ left: '0', top: '0', marginBottom: '0' }}
                    onClick={() => removeRepresentantes(index, indexRepresentante)}
                    type="button"
                  >
                    <DeleteIcon size={10} color='white' className='mr10' /> Excluir Representante </Button>
                </div>

              ))}
              <Button
                className="btn-add-documento"
                onClick={(e) => addRepresentante(index)}
                marginBottom={25}
              >
                <PlusIcon size={10} className="mr10 icon-label" /> Adicionar sócio e/ou representante
              </Button>
            </Pane>
          }

          {/* RODAPÉ */}
          <Pane display="flex" className='mt40 pb20'>

            <Pane flex={1}>
              <Button marginRight={16} appearance="primary" intent="danger" onClick={(e) => console.log(e)}>
                <DeleteIcon size={10} color='#fff' className='mr10' />
                Excluir {watch(index + '.tipo_pessoa') === "1" ? 'Empresa' : 'Pessoa física'}
              </Button>
            </Pane>

            <Button marginRight={16} intent="none" onClick={(e) => console.log(e, index)}>
              <ResetIcon size={10} className='mr10' /> Limpar tudo
            </Button>

            <Button
              appearance="primary"
              intent="none"
              onClick={handleSubmit((e) => handleEdit(e, index), onError)}
            >
              <TickIcon
                size={10}
                style={{ 'marginRight': '10px', 'fill': '#fff' }} /> Salvar
            </Button>
          </Pane>
        </Pane>
      ))}
    </Pane>
  )
}