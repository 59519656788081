import Axios from 'axios';
import Serve from '../app/serve';

async function getClienteList() {
  const token = localStorage.getItem('token');
  const app = Serve();
  let data;

  await Axios.post(app + 'cliente_filtro', {
    'token': token
  }, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
  })
    .then(res => {
      if (res !== undefined) {
        if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
          localStorage.clear();
        } else {
          const listaClientes = res.data;
          data = listaClientes.map(cliente => { return { "id": cliente.usuario_id, "value": cliente.usuario_id, "name": cliente.name } });
        }
      }
    })

  return data;
};

export default getClienteList;