import Axios from 'axios';

async function getCEP(cep_validar) {  
  let data;

  await Axios.get("https://viacep.com.br/ws/" + cep_validar + "/json/")
  .then(res => {
      ////console.log(res.data);
      if (res.data) {
        data = res.data;
      } //end if.
      else {
        data = "Cep não encontrado."
      }
  })
  return data;
};

export default getCEP;