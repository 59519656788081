import React, { useContext, useState } from 'react';
import { Pane, Table, Badge, Button, DownloadIcon } from 'evergreen-ui';
import './index.css';
//import PosNegociacaoContext from '../../../../context/PosNegociacaoContext';
import VendasContext from '../../../../context/VendasContext';
import ShowDocument from '../../../../app/showDocument';

export default function Historico() {

    const {
        dadosReciboEnviado
    } = useContext(VendasContext);
    const [token] = useState(localStorage.getItem('token'));

    // function filtraDados(dados) {
    //     let found = {};
    //     let out = dados.filter( (item) => {
    //         if (item.documento_id !== null)
    //         return found.hasOwnProperty(item.documento_id) ? false : (found[item.documento_id] = true);
    //     });        
    //     dados.map((item) => (item.documento_id === null) ? out.unshift(item) : false);
    //     return out;
    // }
    const historico = dadosReciboEnviado.historico_acoes;

    const vendedores = [];
    const representantes = [];
    dadosReciboEnviado.vendedores.data.forEach((vendedor) =>
        vendedor.documentos.data.map((doc) => vendedores.push({ 'id': doc.id, 'name': vendedor.name ? vendedor.name : vendedor.razao_social, 'tipo': 'Vendedor' })) &&
        vendedor.representante_socios.data.forEach((representante) =>
            representante.documentos.data.forEach(doc => representantes.push({ 'id': doc.id, 'name': representante.name, 'tipo': 'Vendedor', 'empresa': vendedor.razao_social, 'representante': representante.pj_representante === 1, 'socio': representante.pj_socio === 1 })))
    );
    const compradores = [];
    dadosReciboEnviado.compradores.data.forEach((comprador) =>
        comprador.documentos.data.map((doc) => compradores.push({ 'id': doc.id, 'name': comprador.name ? comprador.name : comprador.razao_social, 'tipo': 'Comprador' })) &&
        comprador.representante_socios.data.forEach((representante) =>
            representante.documentos.data.forEach(doc => representantes.push({ 'id': doc.id, 'name': representante.name, 'tipo': 'Comprador', 'empresa': comprador.razao_social, 'representante': representante.pj_representante === 1, 'socio': representante.pj_socio === 1 })))
    );

    const listaDocsNomes = [];
    listaDocsNomes.push(...vendedores)
    listaDocsNomes.push(...compradores)
    listaDocsNomes.push(...representantes)

    const capitalize = str => {
        if (typeof str !== 'string') {
            return '';
        }
        return str.charAt(0).toUpperCase() + str.substr(1);
    }

    return (
        <Pane>
            {historico.map(historico =>
                <Table.Row className="row-historico" style={{ height: 'auto' }}>
                    <Table.TextCell className="historicoStatus">
                        <div className="fontStatus">
                            <Badge
                                color={historico.identifica_documento === "pessoa_fisica" || historico.identifica_documento === 'pessoa_juridica' ? "green" : "yellow"}
                                marginBottom={14}
                                marginRight={17}
                            >
                                {historico.identifica_documento ? historico.identifica_documento.replace("_", " ") : historico.resposta_gerente_id !== null ? 'Escritura' : 'STATUS'}
                            </Badge>
                            {listaDocsNomes.filter(doc => doc.id === historico.documento_id)[0]?.representante &&
                                <Badge color="neutral" marginBottom={14} marginRight={17}>
                                    REPRESENTANTE LEGAL
                                </Badge>
                            }
                            {listaDocsNomes.filter(doc => doc.id === historico.documento_id)[0]?.socio &&
                                <Badge color="neutral" marginBottom={14}>
                                    SÓCIO
                                </Badge>
                            }

                        </div>
                        <div className="fontStatus">
                            {historico.status !== null
                                ?
                                <>
                                    <span className="boldStatus"> {historico.data_historico} </span>  - {historico.mensagem === 'Gerente realizou correções solicitadas.' ? 'Venda corrigida' : historico.status}
                                </>

                                :
                                <>
                                    {historico.identifica_documento === 'pessoa_fisica' || historico.identifica_documento === 'pessoa_juridica'
                                        ?
                                        <>
                                            <span className="boldStatus"> {historico.data_historico} - </span>
                                            {listaDocsNomes.filter(doc => doc.id === historico.documento_id).map(doc =>
                                                doc.empresa
                                                    ? doc.tipo + ' > ' + doc.empresa + ' > ' + doc.name
                                                    : doc.tipo + ' > ' + doc.name
                                            ) + ' > ' + historico.tipos_multiplos_documentos.map((e, index) => " " + e.nome_tipo )}
                                        </>

                                        : historico.resposta_gerente_id !== null
                                            ?
                                            <>
                                                {/*Data FeedBack Escritura*/}
                                                <span className="boldStatus">{historico.data_historico}</span> - {historico.status_escritura}
                                            </>
                                            :
                                            <>
                                                <span className="boldStatus">{historico.data_historico}</span> - {capitalize(historico.identifica_documento) + ' > '} {historico.nome}
                                            </>
                                    }
                                </>
                            }
                        </div>

                        {(historico.data_escritura || historico.resposta_gerente_id)
                            ?
                            <Table.TextCell paddingLeft={0} className="historic-message">
                                {/*Conteúdo Escritura*/}
                                {
                                    historico.status_id === 5
                                        ?
                                        <div>
                                            {
                                                historico.data_escritura === null
                                                    ?
                                                    ''
                                                    :
                                                    <div>
                                                        <div className="mensagem" style={{ marginBottom: '10px' }}>
                                                            O pós-venda alterou o status da venda para Escritura, além de compartilhar a data e local onde as assinaturas serão realizadas.
                                                        </div>

                                                        <div className="mensagem" style={{ marginBottom: '10px' }}>
                                                            Dia - {historico.data_escritura}, Hora - {historico.hora_escritura} e Local - {historico.logradouro}, {historico.numero}{historico.unidade === null ? '' : ', '} {historico?.unidade}{historico.complemento === null ? '' : ' / '}{historico?.complemento}
                                                        </div>
                                                    </div>
                                            }
                                        </div>

                                        :
                                        historico.resposta_gerente_id !== null
                                            ?
                                            <>

                                                {
                                                    historico.motivo_escritura !== null &&
                                                    <div className="mensagem" style={{ marginBottom: '10px' }}>
                                                        Observações: {historico.motivo_escritura}
                                                    </div>
                                                }

                                                {
                                                    historico.resposta_gerente_id === 6 &&
                                                    <div className="mensagem" style={{ marginBottom: '10px' }}>
                                                        Dia - {historico.data_escritura}, Hora - {historico.hora_escritura} e Local - {historico.logradouro}, {historico.numero}{historico.unidade === null ? '' : ', '} {historico?.unidade}{historico.complemento === null ? '' : ' / '}{historico?.complemento}
                                                    </div>
                                                }

                                            </>
                                            :
                                            ''
                                }

                                <span className="historico-mensagem-d-solicitacao">
                                    {historico.responsavel_novo_id !== null ? 'Nayara tirou ' + historico.antigo_responsavel + ' como responsável pela venda e colocou ' + historico.novo_responsavel : ''

                                    }</span>

                                {historico.mensagem === null ? '' : <span className="mensagem">{historico.mensagem}</span>}
                                {historico.link === null
                                    ? ''
                                    :

                                    <Button
                                        marginTop={14}
                                        onClick={(e) => ShowDocument(historico.documento_id, "documento", token)}
                                    >
                                        <DownloadIcon className="iconLink" width={17} marginRight={5} /><span> Baixar documento </span>
                                    </Button>
                                }
                            </Table.TextCell>
                            :
                            <Table.TextCell paddingLeft={0}>
                                {historico.mensagem === null ? '' : <span className="mensagem">{historico.mensagem}</span>}
                                {historico.link === null
                                    ? ''
                                    :
                                    <Button
                                        marginTop={14}
                                        onClick={(e) => ShowDocument(historico.documento_id, "documento", token)}
                                    >
                                        <DownloadIcon className="iconLink" width={17} marginRight={5} /><span> Baixar documento </span>
                                    </Button>
                                }
                            </Table.TextCell>}
                    </Table.TextCell>
                </Table.Row>
            )}
        </Pane>
    )
}