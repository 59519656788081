import Axios from 'axios';
import Serve from '../app/serve';

//STATUS LIST DE SERVIÇOS PARA O NUCLEO
async function getChecklistPadrao() {
  const token = localStorage.getItem('token');
  const app = Serve();
  let data;

  await Axios.get(app + 'checklist', {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
    .then(res => {
      if (res) {
        // console.log('checklist', res);
        if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
          localStorage.clear();
        } else {
          data = (res.data.map((item) => (
            {id: item.id, key: item.id, text: item.nome, checked: false,}
          )));
        }
      }
    })
  return data;
};

export default getChecklistPadrao;