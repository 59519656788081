import Axios from 'axios';
import Serve from '../app/serve';
import ServeLogin from '../app/serve_login';

async function removeDocument(document_id, acessoTemporario) {
    console.log('ID DOCUMENTO PARA API: ', document_id)
    const token = localStorage.getItem('token');
    const app = acessoTemporario ? ServeLogin() : Serve();
    const api = acessoTemporario ? 'deletar_documento_acesso' : 'deletar_documento';

    let data;

    await Axios.post(app + api, {
        "documento_id": document_id,
    }, {
        headers: { Authorization: `Bearer ${token}` }
    })
        .then(res => {
            if (res.data.status && res.data.status === (401 || 498)) {
                localStorage.clear();
                data = false;
            } else {
                data = true;
            };
        })
        .catch((error) => {
            console.log(error);
        })

    return data;
};

export default removeDocument;