import React, { useContext, useState, useEffect } from 'react'
import { Pane, Dialog, Badge, PersonIcon, OfficeIcon, Button, SelectField, FilePicker, toaster, Label, TrashIcon, DocumentOpenIcon, SmallTickIcon, BriefcaseIcon, ErrorIcon } from 'evergreen-ui';
import AcessoTemporarioContext from '../../../../../../context/AcessoTemporarioContext';
import { useForm } from 'react-hook-form';
import ShowDocumentNotToken from '../../../../../../app/showDocumentNotToken';
import Axios from 'axios';
import Serve from '../../../../../../app/serve_login';
import { useHistory } from 'react-router-dom';
import fimFeedbackImg from '../../../../../../images/end_feedback.png';
import MultiUploadDoc from '../../../../../../components/MultiUploadDoc';
import updateUsuarios from '../../../../../../apis/updateUsuarios';

export default function Documentos() {
    const {
        isShownDocuments, setIsShownDocuments,
        tipoDocumentos,
        documentosPessoais, setDocumentosPessoais,
        documentosImovel, setDocumentosImovel,
        usuario, responsavelProcesso,
        imovel, setImovel,
        loading, setLoading,
        setStatusProgresso,
        setStatusHistorico,
        setResponsavelProcesso,
        setUsuario,
        setUsuarioId,
        setAvaliacao,
        multiDocs, setMultiDocs,
        processSelect,
        setGerenteProcesso,
        setVendedorProcesso,
        setOpcionistaProcesso,
        setProcessSelect,
        setDadosVenda,
    } = useContext(AcessoTemporarioContext);

    const app = Serve();
    const history = useHistory();
    const [token] = useState(localStorage.getItem('token'));

    const [processo_id] = useState(localStorage.getItem('processo_id'));
    const [codigo_acesso] = useState(localStorage.getItem('codigo_acesso'));
    const [cpf] = useState(localStorage.getItem('cpf'));
    const [isSave, setIsSave] = useState(false);
    const pessoa = usuario.perfil === 'Comprador' ? 'compradores' : 'vendedores';
    const index = 0;
    const tipoPessoa = usuario.perfil;
    //console.log(tipoPessoa);
    //console.log(usuario)
    //console.log(pessoa)

    const { register, unregister, handleSubmit, clearErrors, setValue, watch, reset, formState: { errors } } = useForm({
        defaultValues: {
            //documentos: { ...documentosData }
        }
    })

    const refreshFormValues = () => {
        //Setar valor para formValues e o MultiDocs que será usado na Aba Imovel, Vendedores e Compradores        
        if (processSelect) {
            const compradores = processSelect.usuario.perfil === 'Comprador' ? [processSelect.usuario] : [];
            const vendedores = processSelect.usuario.perfil === 'Vendedor' ? [processSelect.usuario] : [];
            //const imovel = processSelect.imovel.documentos.data;

            const returnPessoa = (data_pessoa, type) => {
                const pessoa = processSelect.documentos.data.filter(
                    (doc) => doc.identifica_documento === type).map(
                        (doc) => ({
                            'file': doc.arquivo,
                            'id': doc.id,
                            'item': doc.tipos_multiplos_documentos.map((tipo) => ({ 
                                id: tipo.id, 
                                value: tipo.tipo_documento_id, 
                                emissao: tipo.data_emissao, 
                                validade: tipo.validade_dias,  
                                vencimento: tipo.data_vencimento,                                
                                have_validade: !!tipo.validade_dias
                            }))
                            // 'item': doc.tipos_multiplos_documentos.map((tipo) => ({ 'id': tipo.id, 'value': tipo.tipo_documento_id,  }))
                        }))
                return pessoa;
            };

            setMultiDocs({
                'imovel': [{ item: [], file: '', id: '' }],
                'vendedores': vendedores.map((vendedor) => {
                    if (vendedor.tipo_pessoa === 0) {
                        const fisica = returnPessoa(vendedor, "pessoa_fisica");
                        return { fisica };

                    } else {
                        const juridica = returnPessoa(vendedor, "pessoa_juridica");
                        const representante_socios = vendedor.representante_socios.data.map(
                            representante => returnPessoa(representante, "pessoa_fisica")
                        );
                        return { juridica, representante_socios };
                    }
                }),
                'compradores': compradores.map((comprador) => {
                    if (comprador.tipo_pessoa === 0) {
                        const fisica = returnPessoa(comprador, "pessoa_fisica");
                        return { fisica };

                    } else {
                        const juridica = returnPessoa(comprador, "pessoa_juridica");
                        const representante_socios = comprador.representante_socios.data.map(
                            representante => returnPessoa(representante, "pessoa_fisica")
                        );
                        return { juridica, representante_socios };
                    }                    
                }),
            });

        };
    };

    useEffect(() => {
        refreshFormValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processSelect]);

    const handleSave = async (data, action) => {       
        setLoading(true);

        const dataToSave = data;

        let arrayData = new FormData();
        arrayData.append('usuario_id', processSelect.usuario.id);
        arrayData.append('processo_id', processo_id);
        const saveMultiDocs = (e, index_documento, representante) => {
            if (e.file && e.item[0]) {
              if (typeof (e.file) === 'string' || e.file instanceof String) {
                let arquivoVazio = new File(["foo"], "nao_teve_alteracao_foo.txt", {
                  type: "text/plain",
                });
                arrayData.append(`arquivos[${index_documento}]`, arquivoVazio);
              } else {
                arrayData.append(`arquivos[${index_documento}]`, e.file);
              }
              if (e) {
                e.item.forEach((tipo, index_tipo) => {
                  arrayData.append(`tipo_documento_ids[${index_documento}][${index_tipo}]`, tipo.value);
                  arrayData.append(`multiplo_documento_id[${index_documento}][${index_tipo}]`, tipo.id ? tipo.id : "");
                  arrayData.append(`data_emissao[${index_documento}][${index_tipo}]`, tipo.emissao || '');  // DATA INICIAL
                  arrayData.append(`validade_dias[${index_documento}][${index_tipo}]`, tipo.validade || ''); // DIAS
                  arrayData.append(`data_vencimento[${index_documento}][${index_tipo}]`, tipo.vencimento || ''); // DATA FINAL
                })
              }
              arrayData.append(`documentos_ids[${index_documento}]`, e.id ? e.id : "");
              //arrayData.append(`idDonoDocumento[${index_documento}]`, dataToSave.id);
              arrayData.append(`idDonoDocumento[${index_documento}]`, processSelect.usuario.id);
              arrayData.append(`papel[${index_documento}]`, representante || tipoPessoa.charAt(0).toUpperCase() + tipoPessoa.substring(1));
            }
        };

        // MULTIPLOS DOCUMENTOS EM 1 ARQUIVO PESSOA FISICA
        multiDocs[pessoa][index].fisica?.forEach((e, index_documento) => {
            saveMultiDocs(e, index_documento);
        });

        recarregaProcesso(data, action)
        //setLoading(false);
        //return result;

        // API TESTE
        await Axios.post(app + 'atualizar_doc_acesso', arrayData, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          })
            .then(res => {
              if (res.data.status && res.data.status === (401 || 498)) {
                localStorage.clear();
              } else {
                data = res.data;
              }
            })
            .catch(function (error) {
              console.log(error);
            })
    };

    const recarregaProcesso = async (e, action) => {
        //setIsSave(false);

        await Axios.post(app + 'dados_processos_vendedor_comprador', {
         'processo_id': processo_id,
         'cpf': cpf
        }, {
            /* headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            } */
        }).then(res => {
            if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                localStorage.clear();
                history.push('/acesso');
            } else {
                //setDocumentosPessoais(res.data.documentos);
                //setDocumentosImovel(res.data.documentos_imovel);
                setUsuarioId(res.data.usuario.id);
                setAvaliacao(res.data.avaliacao);
                // let endereco  =  res.data.logradouro;
                // let endereco_completo = organizar_endereco(endereco);
                // setLogradouro(endereco_completo);
                const lista_status = res.data.status_processos.status.data;
                //const statusProgresso = res.data.status_processos.status_progresso.data;
                const responsavel = res.data.status_processos.responsavel;
                //setStatusProgresso(statusProgresso);
                //setStatusHistorico(lista_status);
                setResponsavelProcesso(responsavel)
                setDocumentosPessoais(res.data.documentos);
                setDocumentosImovel(res.data.documentos_imovel);
                setUsuario(res.data.usuario);
                setImovel(res.data.logradouro);
                setGerenteProcesso(res.data.gerente);
                setVendedorProcesso(res.data.corretor_vendedor);
                setOpcionistaProcesso(res.data.opcionista)
                setMultiDocs(multiDocs);

                setProcessSelect(res.data);
                setDadosVenda(res.data.imovel);

                //console.log(action);
                if(action === 'close'){
                    setIsSave(false);
                }
                else{
                    setIsSave(true);
                }
                

                //setIsShownDocuments(false);
            }
        })

        //return result;
    };

    const sideFunction = async (id, index, tabIndex) => {
        validarBtnSave();
        recarregaProcesso('', 'close');
      };

    let validarSave = false;
    function validarBtnSave(){
        const docs = usuario.perfil === 'Vendedor' ? multiDocs.vendedores.map(pessoa => pessoa) : multiDocs.compradores.map(pessoa => pessoa);
        const tipoDoc = docs[0]?.fisica?.map(fisica => fisica) || [];
        const validaDoc =  docs[0]?.fisica?.some(fisica => fisica.file === '');
        validarSave = tipoDoc.length === 0 ? true : validaDoc;
        return validarSave;
    }

    return (
        <Pane>
            <Dialog
                isShown={isShownDocuments}
                title={isSave === false ? <h2>Envio digital<br /> de documentos</h2> : ''}
                onCloseComplete={(e) => [setIsShownDocuments(false), setIsSave(false), recarregaProcesso(e, 'close')]}
                hasFooter={false}
                width={720}
                shouldCloseOnOverlayClick={false}
                containerProps={{ className: 'documentos-temporaria-dialog' }}
                // topOffset={20}
            >

                {
                    isSave === false 
                    ?
                    <>
                    <div className="row-content">
                        <div className="content">
                            Procure sempre subir arquivos<br /> nos formatos* <span>.pdf, .jpg, .jpeg e .png.</span>
                        </div>

                        <div className="row-documents">
                            <div className="title">
                                <PersonIcon color={'#5EA99B'} size={15} marginRight={'5px'} /> Documentos pessoais
                            </div>

                            {/* <div className="subtitle mb5">
                                Documentos do {tipoPessoa.toUpperCase() + tipoPessoa.substring(1)
                                <Badge margin={8} color="blue">
                                {multiDocs[pessoa][index]["fisica"].length}
                                </Badge >
                            </div> */}

                            {/*Componente Multi Upload*/}
                            <MultiUploadDoc pessoa={pessoa} type="fisica" context={AcessoTemporarioContext} indexPessoa={0} idDonoDocumento={usuario.id} acessoTemporario={true} refreshProcess={sideFunction} />
                        </div>
                    </div>

                    <div className="row-btn-save">
                        <Button disabled={validarBtnSave()} style={{opacity: validarBtnSave() ? '0.5' : 'inherit'}} className="btn-save" onClick={handleSubmit(e => handleSave(e, 'save'))}>
                            <SmallTickIcon color={'#fff'} marginRight={8} size={20} />
                            Salvar e enviar documentos
                        </Button>
                    </div>
                    </>

                    :
                    <div className="avaliacao-feedback-dialog">
                        <div className="end-feedback">
                            <img src={fimFeedbackImg} alt="end_feedback" />
                            <p style={{marginBottom: '25px'}}>
                                Obrigada por enviar sua<br/> documentação. 
                            </p>

                            <p>
                                Nossa equipe vai analisar<br/> o que foi enviado :)
                            </p>
                        </div>
                    </div>
                    
                }
                
            </Dialog>
        </Pane>
    )
}
