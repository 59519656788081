import Axios from 'axios';
import Serve from '../app/serve';

async function getServicosNucleo() {
  const token = localStorage.getItem('token');
  const app = Serve();
  let servicos;    

  await Axios.get(app + 'tipo_servicos', {
    headers: {
        Authorization: `Bearer ${token}`,
    }
})
    .then(res => {
        if (res !== undefined) {
            if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                localStorage.clear();
            } else {
                const listaTipoServico = res.data.data;
                servicos = listaTipoServico.map(servico => { return { "id": servico.id, "nome": servico.nome } });                
            }
        }
    })
  return servicos;
};
export default getServicosNucleo;