import { Button, CrossIcon, Text, toaster } from "evergreen-ui";
import React, { useContext, useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import removeItemChecklist from "../../../../../../apis/removeItemChecklist";
import NegociacaoContext from '../../../../../../context/NegociacaoContext';

function Checklist({refreshProcess}) {
  const {
    list, setList, isShownSide,
    processoId, selectedIndex,
  } = useContext(NegociacaoContext);

  console.log(list)

  const handleClick = (e, index) => {
    const value = e.target.checked;
    list[index].checked = value;
    setList([...list]);
  };

  const handleDelete = async (index) => {
    let result = 'Pronto! item excluido.' // VALOR PARA EXCLUIR O PROCESSO PADRAO QUE AINDA N EXISTI NO BANCO
  console.log(list);
    if (list[index].processoId) {
      const { key, processoId } = list[index]
      result = await removeItemChecklist(key, processoId);
    }
    if (result === 'Pronto! item excluido.') {
      toaster.success(result)
      const newList = [...list];
      newList.splice(index, 1);
      setList([...newList]);
    } else {
      toaster.warning("Algo não saiu como esperado. Tente novamente mais tarde.")
    }
    refreshProcess(processoId, undefined, selectedIndex);
  };

  const [showButton, setShowButton] = useState(null);

  useEffect(() => {
    setShowButton(null);
  }, [isShownSide])

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newList = [...list];
    const [removed] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, removed);
    setList(newList);
  };

  const margin_left = 60;
  const getItemStyle = (isDragging, draggableStyle) => ({
    marginLeft: isDragging ? margin_left - draggableStyle.left : 0,
    marginTop: isDragging ? 0 : 14,
    ...draggableStyle
  })

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <div
            className="mt24"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {list.map((item, index) => (
              <Draggable key={item.key} draggableId={item.key.toString()} index={index}>
                {(provided, snapshot) => {
                  // console.log(provided.dragHandleProps)
                  // provided.dragHandleProps = {
                  //   "aria-describedby" :  provided.dragHandleProps['aria-describedby'],
                  //   "data-rbd-drag-handle-context-id" : provided.dragHandleProps["data-rbd-drag-handle-context-id"],
                  //   "data-rbd-drag-handle-draggable-id" : provided.dragHandleProps["data-rbd-drag-handle-draggable-id"],
                  //   onDragStart : provided.dragHandleProps.onDragStart,
                  //   "draggable" :  false,
                  //   "role" :  "button",
                  // }
                  return (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      aria-describedby={undefined}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      onMouseEnter={() => setShowButton(index)}
                    >
                      <input type="checkbox" className="mr5 checklist" checked={item.checked} onChange={e => handleClick(e, index)} />
                      <Text padding={5} className={item.checked ? "riscado fw400 mr18" : "fw400 mr18"}>{item.text}</Text>
                      {showButton === index &&
                        <Button appearance="primary" size={"small"} intent="danger" onClick={() => handleDelete(index)}><CrossIcon color="white" /></Button>}
                    </div>
                  )
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default Checklist;