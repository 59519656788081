import React, {useContext, useState} from 'react';
import { Pane, Table } from 'evergreen-ui';
//import './index.css';
//import PosNegociacaoContext from '../../../../context/PosNegociacaoContext';
import ShowDocument from '../../../../../../app/showDocument';
import VendasContext from '../../../../../../context/VendasContext';
import InfoEscritura from '../../../../../../components/InfoEscritura';


//import { useHistory } from 'react-router-dom';

export default function Venda(props) {        
    const [token] = useState(localStorage.getItem('token'));

    const {
        dadosReciboEnviado,
        tipoLaudemio,
        listEscrituras,
    } = useContext(VendasContext);        
    console.log(dadosReciboEnviado);

    let msgCampo = '----'

    //lê os laudemios retornados em dadosReciboEnviado
    const laudemiosEnviados = dadosReciboEnviado.laudemios.map(laudemio => parseInt(laudemio.valor_laudemio));
    //Comparar dadosReciboEnviado a uma lista de todos os laudemios "tipoLaudemio" e retorna o id e nomes para exibir
    const laudemiosSemNome = dadosReciboEnviado.laudemios.filter((laudemio) => laudemio.tipo_laudemio === "2" || laudemio.tipo_laudemio === "1");
    const laudemioValorToName = tipoLaudemio.filter((laudemio) => laudemiosEnviados.includes(laudemio.id));
    const arrayLaudemio = laudemiosSemNome;
    arrayLaudemio.push(...laudemioValorToName)

    function returnarEscritura(escritura) {        
        return listEscrituras.find(
            (value) => Number(escritura) === value.id
        )?.escritura;
    };

    function  tipo_laudemio(tipo) {
        let tipo_laudemio = '';
        switch (tipo) {
            case 1:
                tipo_laudemio = "União";
                break;
            case 2:
                tipo_laudemio = "Prefeitura";
                break;
            case 3:
                tipo_laudemio = "Familia";
            break;
            case 4:
                tipo_laudemio = "Igreja";
                break;
        
            default:
                break;
        }

        return  tipo_laudemio;
    }

    function valor_tipo_laudemio(tipo) {
        let tipo_laudemio = '';
        switch (tipo) {
            case 1:
                tipo_laudemio = "RIP nº";
                break;
            case 2:
                tipo_laudemio = "";
                break;
            case 3:
                tipo_laudemio = "Nome da Família";
            break;
            case 4:
                tipo_laudemio = "Nome da Igreja";
                break;
        
            default:
                break;
        }

        return  tipo_laudemio;
    }

    function forma_pagamento(forma_pagamento) {
        let string_forma_pagamento = '';

        console.log(forma_pagamento);

        if (forma_pagamento !== null) {
            let arrayPagamento = forma_pagamento.split(',');

            arrayPagamento.forEach((element, index) => {
                switch (element) {
                    case '1':
                        string_forma_pagamento += "À vista" + (arrayPagamento.length === (index + 1) ? '' : ', ');
                        break;
                    case '2':
                        string_forma_pagamento += "Financiamento" + (arrayPagamento.length === (index + 1) ? '' : ', ');
                        break;
                    case '3':
                        string_forma_pagamento += "FGTS" + (arrayPagamento.length === (index + 1) ? '' : ', ');
                        break;
                    case '4':
                        string_forma_pagamento += "Consórcio" + (arrayPagamento.length === (index + 1) ? '' : ', ');
                        break;
                    case '5':
                        string_forma_pagamento += "Parcelamento" + (arrayPagamento.length === (index + 1) ? '' : ', ');
                        break;
                    case '6':
                        string_forma_pagamento += "PIX" + (arrayPagamento.length === (index + 1) ? '' : ', ');
                        break;

                    default:
                        break;
                }
            });   
        }
        else {
            string_forma_pagamento = '';
        }
        return string_forma_pagamento;
    };

    return (
        <div className="detadelhes-sidesheet">
            {
                dadosReciboEnviado.length === 0
                ?
                <Table.Row>
                    <Table.TextCell className="historicoStatus">
                        <Table.TextCell className="fontStatus">
                            <span>Nenhuma Venda foi cadastrada no momento.</span>
                        </Table.TextCell>
                    </Table.TextCell>
                </Table.Row>

                :
                <>
                    <Pane>
                        <React.Fragment>            
                            <h2 className="subtitle subtitle-table">Dados do Imóvel</h2>
                        </React.Fragment>
                    </Pane>
                    <Table.Row>
                        <Table.TextCell className="historicoStatus">
                            <Table.TextCell className="fontStatus">
                                <span>Código</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.codigo}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.TextCell className="historicoStatus" width={100}>
                            <Table.TextCell className="fontStatus">
                                <span>CEP</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.cep}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                        <Table.TextCell className="historicoStatus" width={100}>
                            <Table.TextCell className="fontStatus">
                                <span>Bairro</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.bairro}
                                </span>
                            </Table.TextCell>  
                        </Table.TextCell>
                        <Table.TextCell className="historicoStatus">
                            <Table.TextCell className="fontStatus">
                            <span>Logradouro</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.logradouro}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.TextCell className="historicoStatus" width={100}>
                            <Table.TextCell className="fontStatus">
                                <span>Número</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.numero}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                        <Table.TextCell className="historicoStatus" width={100}>
                            <Table.TextCell className="fontStatus">
                            <span>Unidade</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.unidade === null ? msgCampo : dadosReciboEnviado.unidade }
                                </span>
                            </Table.TextCell>  
                        </Table.TextCell>
                        <Table.TextCell className="historicoStatus">
                            <Table.TextCell className="fontStatus">
                                <span>Complemento</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.complemento === null ? msgCampo : dadosReciboEnviado.complemento}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.TextCell className="historicoStatus" width={100}>
                            <Table.TextCell className="fontStatus">
                                <span>Cidade</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.cidade}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                        <Table.TextCell className="historicoStatus">
                            <Table.TextCell className="fontStatus">
                                <span>Estado</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.uf}
                                </span>
                            </Table.TextCell>  
                        </Table.TextCell>
                        <Table.TextCell className="historicoStatus">
                            <Table.TextCell className="fontStatus">
                                {/* <span> CAMPO VAZIO AGUARDANDO OPÇÕES </span> */}
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                {/* <span className="mensagem" style={{paddingLeft: 0}}>
                                    CAMPO VAZIO AGUARDANDO OPÇÕES
                                </span> */}
                            </Table.TextCell>  
                        </Table.TextCell>
                    </Table.Row>

                    <Table.Row>
                        <Table.TextCell className="historicoStatus">
                            <Table.TextCell className="fontStatus">
                                <span>Vagas na garagem (escrituradas)</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.informacao.vaga === null ? msgCampo : dadosReciboEnviado.informacao.vaga}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                    </Table.Row>

                    <Pane>
                        <React.Fragment>            
                            <h2 className="subtitle subtitle-table">Registro e Escritura</h2>
                        </React.Fragment>
                    </Pane>

                    <Table.Row className="">
                        <Table.TextCell className="historicoStatus">
                            <Table.TextCell className="fontStatus">
                                <span>Tipo</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {returnarEscritura(dadosReciboEnviado.informacao.escritura)}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                    </Table.Row>

                    <Table.Row className="">
                        <Table.TextCell className="historicoStatus">
                            <Table.TextCell className="fontStatus" width={120}>
                                <span>Lavrado em</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.informacao.lavrada === null ? msgCampo : dadosReciboEnviado.informacao.lavrada}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                        <Table.TextCell className="historicoStatus" width={120}>
                            <Table.TextCell className="fontStatus">
                                <span>Livro</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.informacao.livro === null ? msgCampo : dadosReciboEnviado.informacao.livro}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                        <Table.TextCell className="historicoStatus" width={120}>
                            <Table.TextCell className="fontStatus">
                                <span>Folhas</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.informacao.folha === null ? msgCampo : dadosReciboEnviado.informacao.folha}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                        <Table.TextCell className="historicoStatus" width={120}>
                            <Table.TextCell className="fontStatus">
                                <span>Ato</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.informacao.ato === null ? msgCampo : dadosReciboEnviado.informacao.ato}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                        <Table.TextCell className="historicoStatus" width={120}>
                            <Table.TextCell className="fontStatus">
                                <span>Cartório</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.informacao.cartorio === null ? msgCampo : dadosReciboEnviado.informacao.cartorio}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                    </Table.Row>

                    <Table.Row>
                        <Table.TextCell className="historicoStatus" width={230}>
                            <Table.TextCell className="fontStatus">
                                <span style={{whiteSpace: 'normal'}}>Registrado no RGI</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.informacao.rgi === null ? msgCampo : dadosReciboEnviado.informacao.rgi}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                        <Table.TextCell className="historicoStatus" width={230}>
                            <Table.TextCell className="fontStatus">
                                <span>Matrícula nº</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.informacao.matricula}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                        <Table.TextCell className="historicoStatus">
                            <Table.TextCell className="fontStatus">
                                <span>Inscrição Municipal nº</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.informacao.inscricaoMunicipal}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                        <Table.TextCell className="historicoStatus" width={100}>
                            <Table.TextCell className="fontStatus">
                                {/* <span> CAMPO VAZIO AGUARDANDO OPÇÕES </span> */}
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                {/* <span className="mensagem" style={{paddingLeft: 0}}>
                                    CAMPO VAZIO AGUARDANDO OPÇÕES
                                </span> */}
                            </Table.TextCell>  
                        </Table.TextCell>
                        <Table.TextCell className="historicoStatus" width={100}>
                            <Table.TextCell className="fontStatus">
                                {/* <span> CAMPO VAZIO AGUARDANDO OPÇÕES </span> */}
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                {/* <span className="mensagem" style={{paddingLeft: 0}}>
                                    CAMPO VAZIO AGUARDANDO OPÇÕES
                                </span> */}
                            </Table.TextCell>  
                        </Table.TextCell>
                    </Table.Row>

                    {/*Informações sobre a Escritura*/}
                    <Table.Row style={{height: 'auto'}}>
                        <Table.TextCell style={{padding: '0'}}>
                            <InfoEscritura processo={dadosReciboEnviado} prazoAssinatura={dadosReciboEnviado.verificar_processo_escritura.processo.prazo_escritura} perfil="gerente" />
                        </Table.TextCell>
                    </Table.Row>

                    <Pane>
                        <React.Fragment>            
                            <h2 className="subtitle subtitle-table">Laudêmio</h2>
                        </React.Fragment>
                    </Pane>            
                    {dadosReciboEnviado.laudemios.length === 0 && (
                        <Table.Row>
                            <Table.TextCell className="historicoStatus" width={100}>
                                <Table.TextCell className="fontStatus">
                                    <span>Não há laudêmio cadastrado.</span>                            
                                </Table.TextCell>
                            </Table.TextCell>
                        </Table.Row>
                    )}
                    {arrayLaudemio.map( laudemio =>
                        <Table.Row className="">
                            <Table.TextCell className="historicoStatus" width={100}>
                                <Table.TextCell className="fontStatus">
                                    <span>Tipo</span>
                                </Table.TextCell>
                                <Table.TextCell className="mensagemStyle">
                                    <span className="mensagem" style={{paddingLeft: 0}}>                                
                                        {tipo_laudemio(laudemio.tipo_laudemio_id ? laudemio.tipo_laudemio_id : parseInt(laudemio.tipo_laudemio))}
                                    </span>
                                </Table.TextCell>
                            </Table.TextCell>
                            <Table.TextCell className="historicoStatus" width={150}>
                                <Table.TextCell className="fontStatus">
                                <span>{valor_tipo_laudemio(laudemio.tipo_laudemio_id ? laudemio.tipo_laudemio_id : parseInt(laudemio.tipo_laudemio))}</span>
                                </Table.TextCell>
                                <Table.TextCell className="mensagemStyle">
                                    <span className="mensagem" style={{paddingLeft: 0}}>
                                        {laudemio.nome || laudemio.valor_laudemio}
                                    </span>
                                </Table.TextCell>
                            </Table.TextCell>
                        </Table.Row>
                    )}
                    
                    <Pane>
                        <React.Fragment>            
                            <h2 className="subtitle subtitle-table">Formas de pagamentos</h2>
                        </React.Fragment>
                    </Pane>
                    <Table.Row>
                        <Table.TextCell className="historicoStatus" width={100}>                    
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {forma_pagamento(dadosReciboEnviado.informacao.forma_pagamento)}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.TextCell className="historicoStatus">
                            <Table.TextCell className="fontStatus">
                            <span>Observações</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.informacao.observacao_pagamento === null ? msgCampo : dadosReciboEnviado.informacao.observacao_pagamento}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                    </Table.Row>
                    <Pane>
                        <React.Fragment>            
                            <h2 className="subtitle subtitle-table">Valor de venda</h2>
                        </React.Fragment>
                    </Pane>
                    <Table.Row>
                        <Table.TextCell className="historicoStatus" width={130}>
                            <Table.TextCell className="fontStatus">
                            <span>Valor de venda</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.informacao.valor_venda !== null ? dadosReciboEnviado.informacao.valor_venda : msgCampo}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                        <Table.TextCell className="historicoStatus" width={130}>
                            <Table.TextCell className="fontStatus">
                            <span>Valor Anunciado</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.informacao.valor_anunciado === null ? msgCampo : dadosReciboEnviado.informacao.valor_anunciado}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                        <Table.TextCell className="historicoStatus" width={130}>
                            <Table.TextCell className="fontStatus">
                            <span>Valor do Sinal</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.informacao.valorSinal !== null ? dadosReciboEnviado.informacao.valorSinal : msgCampo}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.TextCell className="historicoStatus" width={130}>
                            <Table.TextCell className="fontStatus">
                            <span>Data da venda</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.informacao.data_assinatura === "" ? msgCampo : dadosReciboEnviado.informacao.data_assinatura}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                        <Table.TextCell className="historicoStatus" width={130}>
                            <Table.TextCell className="fontStatus">
                            <span>Prazo da escritura</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.informacao.prazo === null ? msgCampo : dadosReciboEnviado.informacao.prazo }
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>   
                        <Table.TextCell className="historicoStatus" width={130}>
                            {/* <Table.TextCell className="fontStatus">
                            <span>CAMPO VAZIO</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    CAMPO VAZIO
                                </span>
                            </Table.TextCell> */}
                        </Table.TextCell>             
                    </Table.Row>

                    <Pane>
                        <React.Fragment>            
                            <h2 className="subtitle subtitle-table" style={{marginBottom:0}}>
                                Multa
                            </h2>
                        </React.Fragment>
                    </Pane>
                    <Table.Row height={50}>
                        <Table.TextCell className="historicoStatus">                    
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0, marginBottom:10}}>
                                Este valor está relacionado ao prazo limite de entrega das chaves.
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.TextCell className="historicoStatus">
                            <Table.TextCell className="fontStatus">
                            <span>Valor de multa diária</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.informacao.valoMulta === null ? msgCampo : dadosReciboEnviado.informacao.valoMulta}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                    </Table.Row>                    
                </>
            }
        </div>
    )
}