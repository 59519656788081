import React, { useContext, useState, useEffect } from 'react';
import {
    Pane,
    Dialog,
    TextInputField,
    FilePicker,
    CloudUploadIcon,
    Button,
    PlusIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    //Select,
    SelectField,
    Checkbox,
    EditIcon,
    Textarea,
    Label,
    DeleteIcon
} from 'evergreen-ui';

//Context
import PosNegociacaoContext from '../../../../../../context/PosNegociacaoContext';

//Components
import Axios from 'axios';
import { useForm } from "react-hook-form";
import Steps from './Steps';
import cepMask from '../../../../../../components/cepMask';
import somenteNumero from '../../../../../../components/somenteNumero';
import dateMask from '../../../../../../components/dateMask';
import horaMask from '../../../../../../components/horaMask';
import formatoMoeda from '../../../../../../components/formatoMoeda';

export default function Imovel(props) {

    const { register, handleSubmit } = useForm();
    const onSubmit = data => console.log(data);

    const {
        isShowFormEspelhoVenda, setIsShowFormEspelhoVenda,
        posicaoEspelhoVenda, setPosicaoEspelhoVenda,
        dadosRecibo,
        validateFieldCodImovel, setValidateFieldCodImovel,
        validateFieldCep, setValidateFieldCep,
        validateCepMessage, setValidateCepMessage,
        validateAdress, setValidateAdress,
        validateFieldValorVenda, setValidateFieldValorVenda,
        validateFieldDataVenda, setValidateFieldDataVenda,
        tipoDocumentos, setTipoDocumentos,
        validateInputFile, setValidateInputFile,
        validateFieldFormaPagamento, setValidateFieldFormaPagamento,
        checkedAVista, setCheckedAVista,
        checkedFinanciamento, setCheckedFinanciamento,
        checkedFgts, setCheckedFgts,
        aVista, setAvista,
        financiamento, setFinanciamento,
        fgts, setFgts,
        dadosEspelhoImovel, setDadosEspelhoImovel
    } = useContext(PosNegociacaoContext);

    console.log(posicaoEspelhoVenda);
    console.log(dadosEspelhoImovel);

    //const [validateFieldUnidade, setValidateFieldUnidade] = useState(false);
    //const [validateFieldNumero, setValidateFieldNumero] = useState(false);
    //const [validateFieldValorEstimado, setValidateFieldValorEstimado] = useState(false);
    //const [validateFieldHorario, setValidateFieldHorario] = useState(false);
    const [validateDocObrigatorio, setValidateDocObrigatorio] = useState(false);
    const [formValues, setFormValues] = useState([{ tipoDocumento: "", documento: "" }])

    useEffect(() => {
        const retornaDocumentos = async () => {
            /*const filter = documentosProcesso.data.filter(documento => documento.identifica_documento === 'imóvel');
            const mapa = filter.map(doc => ({"documento_id": doc.id, "documento": doc.tipo_documento_id, "link": doc.link }))
            setFormValues(mapa)
            setPrimeiroDocumento(true);
            setIdImovel(filter.id);

            setFormValues(mapa)
            setPrimeiroDocumento(true);*/
        }
        retornaDocumentos();
    }, [])

    const closeEspelhoVenda = (e) => {
        setIsShowFormEspelhoVenda(false);
        setPosicaoEspelhoVenda(1);

        setDadosEspelhoImovel([]);
    }

    const handleCodImovel = (e) => {
        let codImovel = document.querySelector('.espelho-venda-codigo-imovel-1');
        if (codImovel.value.length > 0) {
            setValidateFieldCodImovel(true);
        }
        else {
            setValidateFieldCodImovel(false);
        }
    }

    const handleInscricaoMunicipal = (e) => {
        let inscricaoMunicpal = document.querySelector('.espelho-venda-inscricao-municipal-1');
        somenteNumero(inscricaoMunicpal.value);
        inscricaoMunicpal.value = somenteNumero(inscricaoMunicpal.value);
    }

    const handleCep = (e) => {
        let cep = document.querySelector('.espelho-venda-cep-imovel-1');
        console.log(cep.value.length);
        console.log(cep.value);
        if (cep.value.length) {
            cepMask(cep.value);
            cep.value = cepMask(cep.value);

            let cep_validar = cep.value.replace(/\D/g, '')

            //Verifica se campo cep possui valor informado.
            if (cep_validar !== "") {
                //console.log("cep_validar = " + cep_validar)
                //Expressão regular para validar o CEP.
                let validacep = /^[0-9]{8}$/;

                //Valida o formato do CEP.
                if (validacep.test(cep_validar)) {

                    //Preenche os campos com "..." enquanto consulta webservice.
                    document.querySelector('.espelho-venda-logradouro-imovel-1').value = '...';
                    document.querySelector('.espelho-venda-bairro-imovel-1').value = '...';

                    //Consulta o webservice viacep.com.br/
                    Axios.get("https://viacep.com.br/ws/" + cep_validar + "/json/")
                        .then(res => {
                            //console.log(res.data);
                            if (!res.data.erro) {
                                //Atualiza os campos com os valores da consulta.
                                document.querySelector('.espelho-venda-logradouro-imovel-1').value = res.data.logradouro;
                                document.querySelector('.espelho-venda-bairro-imovel-1').value = res.data.bairro;
                                setValidateAdress(true);
                                setValidateCepMessage(true)

                            } //end if.
                            else {
                                //CEP pesquisado não foi encontrado.
                                limpa_formulário_cep();
                                setValidateAdress(false);
                                setValidateCepMessage(false)
                                setValidateFieldCep(false);
                            }

                        })
                } //end if.
                else {
                    //console.log("entou 123");
                    //cep é inválido.
                    limpa_formulário_cep();
                    setValidateFieldCep(false);
                    //alert("Formato de CEP inválido.");
                }
            } //end if.

            if (cep.value.length === 9) {
                console.log('valido');
                setValidateFieldCep(true);
            }
            else {
                console.log('invalido');
                setValidateFieldCep(false);
                limpa_formulário_cep();
            }
        }
    }

    function limpa_formulário_cep() {
        document.querySelector('.espelho-venda-logradouro-imovel-1').value = '';
        document.querySelector('.espelho-venda-bairro-imovel-1').value = '';
    }

    const handleUnidade = () => {
        let unidade = document.querySelector('.espelho-venda-unidade-imovel-1');
        if (unidade.value.length > 0) {
            //setValidateFieldUnidade(true);
        }
        else {
            //setValidateFieldUnidade(false);
        }
    }

    const handleNumero = () => {
        let numero = document.querySelector('.espelho-venda-numero-imovel-1');
        if (numero.value.length > 0) {
            somenteNumero(numero.value);
            numero.value = somenteNumero(numero.value);

            //setValidateFieldNumero(true);
        }
        else {
            //setValidateFieldNumero(false);
        }
    }

    const handleValorVenda = (e) => {
        let valorVenda = document.querySelector('.espelho-venda-valor-venda-1');

        if (valorVenda.value.length > 0) {
            formatoMoeda(valorVenda.value);
            valorVenda.value = formatoMoeda(valorVenda.value);

            setValidateFieldValorVenda(true);
        }
        else {
            setValidateFieldValorVenda(false);
        }
    }

    const handleValorEstimado = (e) => {
        let valorEstimado = document.querySelector('.espelho-venda-valor-estimado-1');

        if (valorEstimado.value.length > 0) {
            formatoMoeda(valorEstimado.value);
            valorEstimado.value = formatoMoeda(valorEstimado.value);

            //setValidateFieldValorEstimado(true);
        }
        else {
            //setValidateFieldValorEstimado(false);
        }

    }

    const handleDataVenda = (e) => {
        let dataVenda = document.querySelector('.espelho-venda-data-venda-1');

        if (dataVenda.value.length >= 8) {
            dateMask(dataVenda.value);
            dataVenda.value = dateMask(dataVenda.value);

            setValidateFieldDataVenda(true);
        }
        else {
            setValidateFieldDataVenda(false);
        }
    }

    const handleDataEscritura = (e) => {
        let dataEscritura = document.querySelector('.espelho-venda-data-escritura-1');

        if (dataEscritura.value.length >= 8) {
            dateMask(dataEscritura.value);
            dataEscritura.value = dateMask(dataEscritura.value);

            setValidateFieldDataVenda(true);
        }
        else {
            setValidateFieldDataVenda(false);
        }
    }

    const handleHorario = (e) => {
        let horario = document.querySelector('.espelho-venda-horario-escritura-1');

        if (horario.value.length >= 4) {
            horaMask(horario.value);
            horario.value = horaMask(horario.value);

            //setValidateFieldHorario(true);
        }
        else {
            //setValidateFieldHorario(false);
        }

    }

    /*const handleFormaPagamento = (e) => {
        setFormaPagamento(e.target.value);
        console.log(formaPagamento);
        let valor = formaPagamento;

        if (valor !== '0') {
            console.log('diferente de zero');
            setValidateFieldFormaPagamento(true);
        }
        else {
            console.log('igual a zero');
            setValidateFieldFormaPagamento(false);
        }
    }*/

    const handleChecked = (e) => {
        const valueCheckbox = e.target.value;
        console.log(valueCheckbox);

        if (valueCheckbox === '1') {
            setCheckedAVista(e.target.checked);
            console.log(checkedAVista);

            if (checkedAVista === false) {
                setValidateFieldFormaPagamento(true);
                setAvista('1');
            }
            else {
                setValidateFieldFormaPagamento(false);
                setAvista('');
            }
        }

        else if (valueCheckbox === '2') {
            setCheckedFinanciamento(e.target.checked);

            if (checkedFinanciamento === false || checkedFgts === true) {
                setValidateFieldFormaPagamento(true);

                if (checkedFinanciamento === true) {
                    setFinanciamento('');
                }
                else {
                    setFinanciamento('2');
                }
            }
            else {
                setValidateFieldFormaPagamento(false);
                setFinanciamento('');
            }
        }

        else if (valueCheckbox === '3') {
            setCheckedFgts(e.target.checked);

            if (checkedFgts === false || checkedFinanciamento === true) {
                setValidateFieldFormaPagamento(true);

                if (checkedFgts === true) {
                    setFgts('');
                }
                else {
                    setFgts('3');
                }
            }

            else {
                setValidateFieldFormaPagamento(false);
                setFgts('');
            }
        }

        else {
            console.log('Nada pode ser selecionado!!!');
        }

        //console.log('Validação Financiamento: ' + checkedFinanciamento);
        //console.log('Financiamento: ' + financiamento);

        //console.log('Validação FGTS: ' + checkedFgts);
        //console.log('FGTS: ' + fgts);
    }

    const handleDocObrigatorio = (e) => {
        let file = document.querySelector('.documento-obrigatorio input[type="file"]').files[0];
        console.log(file);

        setValidateDocObrigatorio(true);
    }

    //Adcionando e removendo campos de Documento
    const handleChangeTipoDocumento = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i]['tipoDocumento'] = e.target.value;
        setFormValues(newFormValues);
        console.log(formValues);

        /*if (formValues.length > 0) {
            setValidateInputFile(true);   
        }
        else {
            setValidateInputFile(false);
        }*/

    }

    const handleChangeDocumento = (i, e) => {
        let file = document.querySelector('.upload-' + i + ' input[type="file"]').files[0];

        let newFormValues = [...formValues];
        newFormValues[i]['documento'] = file.name;
        setFormValues(newFormValues);
        console.log(formValues);

        /*if (formValues.length > 0) {
            setValidateInputFile(true);   
        }
        else {
            setValidateInputFile(false);
        }*/
    }

    const addFormFields = (e) => {
        e.preventDefault();
        setFormValues([...formValues, { tipoDocumento: "", documento: "" }])

        console.log(formValues);
    }

    const removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
        console.log(formValues);
    }

    /*const handleSubmit = (event) => {
        event.preventDefault();
        alert(JSON.stringify(formValues));
    }*/

    function validarSubmit() {
        if (validateFieldCodImovel === true &&
            validateFieldCep === true &&
            validateFieldValorVenda === true &&
            validateFieldDataVenda === true &&
            validateFieldFormaPagamento === true &&
            validateDocObrigatorio === true
            //validateInputFile === true*/
            //validateFieldUnidade === true &&
            //validateFieldNumero === true &&
            //validateFieldValorEstimado === true &&
            //validateFieldHorario === true &&
        ) {
            return 'enabled';
        }
        else {
            return 'disabled';
        }
    }
    validarSubmit();

    const clearStateValidates = async () => {
        setValidateFieldCodImovel(false);
        setValidateFieldCep(false);
        setValidateCepMessage(true);
        setValidateAdress(false);
        //setValidateFieldUnidade(false);
        //setValidateFieldNumero(false);
        setValidateFieldValorVenda(false);
        setValidateFieldDataVenda(false);
        setValidateFieldFormaPagamento(false);
        setValidateInputFile(false)

        setCheckedAVista(false);
        setCheckedFinanciamento(false);
        setCheckedFgts(false);

        setAvista('');
        setFinanciamento('');
        setFgts('');
    }

    /*Ações dos Botões*/
    const handleSalvarContinuar = (e) => {
        e.preventDefault();
        console.log('Salvar e Continuar');

        clearStateValidates();
    }

    const handleBtnAnterior = (e) => {
        e.preventDefault();
        console.log('Anterior');

        clearStateValidates();
    }

    const handleBtnAvancar = (e) => {
        e.preventDefault();
        console.log('Avançar');

        const arrayImovel = {
            codSistema: document.querySelector('.espelho-venda-codigo-imovel-1').value,
            inscricaoMunicipal: document.querySelector('.espelho-venda-inscricao-municipal-1').value,
            cep: document.querySelector('.espelho-venda-cep-imovel-1').value,
            bairro: document.querySelector('.espelho-venda-bairro-imovel-1').value,
            logradouro: document.querySelector('.espelho-venda-logradouro-imovel-1').value,
            numero: document.querySelector('.espelho-venda-numero-imovel-1').value,
            unidade: document.querySelector('.espelho-venda-unidade-imovel-1').value,
            complemento: document.querySelector('.espelho-venda-complemento-1').value,
            valorVenda: document.querySelector('.espelho-venda-valor-venda-1').value,
            valorEstimado: document.querySelector('.espelho-venda-valor-estimado-1').value,
            dataVenda: document.querySelector('.espelho-venda-data-venda-1').value,
            dataEscritura: document.querySelector('.espelho-venda-data-escritura-1').value,
            horario: document.querySelector('.espelho-venda-horario-escritura-1').value,
            nomeCartorio: document.querySelector('.espelho-venda-nome-cartorio-1').value,
            nomeEscrevente: document.querySelector('.espelho-venda-nome-escrevente-1').value,
            formaPagamento: document.querySelector('.espelho-venda-forma-pagamento-1').value,
            arquivos: formValues,
            observacao: document.querySelector('.espelho-venda-observacao-1').value,
        }
        console.log(arrayImovel);

        setDadosEspelhoImovel(arrayImovel);
        console.log(dadosEspelhoImovel);

        setPosicaoEspelhoVenda(2);
    }

    return (
        <Pane>
            <Dialog
                width={808}
                isShown={isShowFormEspelhoVenda}
                title=''
                onCloseComplete={(e) => closeEspelhoVenda(e)}
                hasFooter={false}
                preventBodyScrolling
                shouldCloseOnOverlayClick={false}
                containerProps={{
                    className: 'form-dialog-props'
                }}
            >
                <Steps />

                <div className="form-dialog">
                    <h4 style={{ marginTop: '15px' }}>Dados do Imóvel</h4>
                    <p className="descritption" style={{ marginTop: '15px' }}>Preencha os dados do imóvel responsável.</p>

                    <div className="formGroup formAdd">
                        <form id="formGroup" encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                            <Pane>
                                <div className="rowForm">
                                    <TextInputField
                                        width={140}
                                        label="Cód. do sistema"
                                        description=""
                                        placeholder="DNA1234"
                                        defaultValue={dadosEspelhoImovel === [] ? '' : dadosEspelhoImovel.codSistema}
                                        id={"espelho-venda-codigo-imovel-1"}
                                        className={"espelho-venda-codigo-imovel-1"}
                                        required
                                        onChange={(e) => handleCodImovel(e)}
                                    />

                                    <TextInputField
                                        width={160}
                                        label="Inscrição Municipal"
                                        description=""
                                        placeholder="12345678"
                                        defaultValue={dadosEspelhoImovel === [] ? '' : dadosEspelhoImovel.inscricaoMunicipal}
                                        id={"espelho-venda-inscricao-municipal-1"}
                                        className={"espelho-venda-inscricao-municipal-1"}
                                        maxLength={8}
                                        onChange={(e) => handleInscricaoMunicipal(e)}
                                    />
                                </div>

                                <div className="rowForm">
                                    {validateCepMessage === true
                                        ?
                                        <TextInputField
                                            label="CEP"
                                            description=""
                                            placeholder="00000-000"
                                            defaultValue={dadosEspelhoImovel === [] ? '' : dadosEspelhoImovel.cep}
                                            id={"espelho-venda-cep-imovel-1"}
                                            className={"espelho-venda-cep-imovel-1"}
                                            maxLength="9"
                                            onChange={(e) => handleCep(e)}
                                            required
                                        />
                                        :
                                        <TextInputField
                                            isInvalid
                                            label="CEP"
                                            description=""
                                            placeholder="00000-000"
                                            defaultValue={dadosEspelhoImovel === [] ? '' : dadosEspelhoImovel.cep}
                                            id={"espelho-venda-cep-imovel-1"}
                                            className={"espelho-venda-cep-imovel-1"}
                                            maxLength="9"
                                            onChange={(e) => handleCep(e)}
                                            required
                                            validationMessage="O CEP não existe"
                                        />
                                    }

                                    <TextInputField
                                        width={215}
                                        label="Bairro"
                                        description=""
                                        placeholder="---"
                                        defaultValue={dadosEspelhoImovel === [] ? '' : dadosEspelhoImovel.bairro}
                                        id={"espelho-venda-bairro-imovel-1"}
                                        className={"espelho-venda-bairro-imovel-1"}
                                        readOnly
                                        disabled
                                    />

                                    <TextInputField
                                        width={250}
                                        marginBottom={0}
                                        marginLeft={20}
                                        label="Logradouro"
                                        description=""
                                        placeholder="---"
                                        defaultValue={dadosEspelhoImovel === [] ? '' : dadosEspelhoImovel.logradouro}
                                        id={"espelho-venda-logradouro-imovel-1"}
                                        className={"espelho-venda-logradouro-imovel-1"}
                                        readOnly
                                        disabled
                                    />
                                </div>

                                <div className="rowForm">
                                    <TextInputField
                                        width={100}
                                        label="Número"
                                        description=""
                                        placeholder="Ex: 456"
                                        defaultValue={dadosEspelhoImovel === [] ? '' : dadosEspelhoImovel.numero}
                                        id={"espelho-venda-numero-imovel-1"}
                                        className={"espelho-venda-numero-imovel-1"}
                                        {...register("numero", { required: true })}
                                        onChange={(e) => handleNumero(e)}
                                    />

                                    <TextInputField
                                        width={280}
                                        label="Unidade"
                                        description=""
                                        placeholder="Informações de lote, bloco ou condomínio"
                                        defaultValue={dadosEspelhoImovel === [] ? '' : dadosEspelhoImovel.unidade}
                                        id={"espelho-venda-unidade-imovel-1"}
                                        className={"espelho-venda-unidade-imovel-1"}
                                        {...register("unidade", { required: true })}
                                        onChange={(e) => handleUnidade(e)}
                                    />

                                    <TextInputField
                                        width={230}
                                        marginBottom={0}
                                        marginLeft={20}
                                        label="Complemento"
                                        description=""
                                        placeholder="Nº do apartamento (ou casa)"
                                        defaultValue={dadosEspelhoImovel === [] ? '' : dadosEspelhoImovel.complemento}
                                        id={"espelho-venda-complemento-1"}
                                        className={"espelho-venda-complemento-1"}
                                    />
                                </div>

                                <div className="row-recibo">
                                    <div className="subtitle">Valores</div>
                                    <div className="rowForm">
                                        <TextInputField
                                            width={138}
                                            label="Valor de venda"
                                            description=""
                                            placeholder="R$ 500.000,00"
                                            defaultValue={dadosEspelhoImovel === [] ? '' : dadosEspelhoImovel.valorVenda}
                                            id={"espelho-venda-valor-venda-1"}
                                            className={"espelho-venda-valor-venda-1"}
                                            {...register("valor-venda", { required: true })}
                                            onChange={(e) => handleValorVenda(e)}
                                            required
                                        />

                                        <TextInputField
                                            width={138}
                                            label="Valor estimado"
                                            description=""
                                            placeholder="R$ 500.000,00"
                                            defaultValue={dadosEspelhoImovel === [] ? '' : dadosEspelhoImovel.valorEstimado}
                                            id={"espelho-venda-valor-estimado-1"}
                                            className={"espelho-venda-valor-estimado-1"}
                                            onChange={(e) => handleValorEstimado(e)}
                                        />

                                        <TextInputField
                                            width={133}
                                            marginBottom={0}
                                            marginLeft={20}
                                            label="Data da venda"
                                            description=""
                                            placeholder="DD/MM/AAAA"
                                            defaultValue={dadosEspelhoImovel === [] ? '' : dadosEspelhoImovel.dataVenda}
                                            id={"espelho-venda-data-venda-1"}
                                            className={"espelho-venda-data-venda-1"}
                                            {...register("data-venda", { required: true })}
                                            onChange={(e) => handleDataVenda(e)}
                                            maxLength={10}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row-recibo">
                                    <div className="subtitle">Escritura</div>
                                    <div className="rowForm">
                                        <TextInputField
                                            width={152}
                                            label="Data da escritura"
                                            description=""
                                            placeholder="DD/MM/AAAA"
                                            defaultValue={dadosEspelhoImovel === [] ? '' : dadosEspelhoImovel.dataEscritura}
                                            id={"espelho-venda-data-escritura-1"}
                                            className={"espelho-venda-data-escritura-1"}
                                            {...register("data-escritura", { required: true })}
                                            onChange={(e) => handleDataEscritura(e)}
                                            maxLength={10}
                                        />

                                        <TextInputField
                                            width={82}
                                            label="Horário"
                                            description=""
                                            placeholder="00:00"
                                            defaultValue={dadosEspelhoImovel === [] ? '' : dadosEspelhoImovel.horario}
                                            id={"espelho-venda-horario-escritura-1"}
                                            className={"espelho-venda-horario-escritura-1"}
                                            {...register("horario", { required: true })}
                                            onChange={(e) => handleHorario(e)}
                                            maxLength={5}
                                        />

                                        <TextInputField
                                            marginBottom={0}
                                            marginLeft={20}
                                            label="Nome do cartório"
                                            description=""
                                            placeholder="Cartorio do Teste"
                                            defaultValue={dadosEspelhoImovel === [] ? '' : dadosEspelhoImovel.nomeCartorio}
                                            id={"espelho-venda-nome-cartorio-1"}
                                            className={"espelho-venda-nome-cartorio-1"}
                                        />

                                        <TextInputField
                                            marginBottom={0}
                                            marginLeft={20}
                                            label="Nome do escrevente"
                                            description=""
                                            placeholder="Cartorio do Teste"
                                            defaultValue={dadosEspelhoImovel === [] ? '' : dadosEspelhoImovel.nomeEscrevente}
                                            id={"espelho-venda-nome-escrevente-1"}
                                            className={"espelho-venda-nome-escrevente-1"}
                                            onChange={(e) => (e)}

                                        />
                                    </div>

                                    {/*<div className="rowForm">
                                        <TextInputField
                                            width={205}
                                            label="Livro"
                                            description=""
                                            placeholder="Livro"
                                            defaultValue=""
                                            id={"espelho-venda-livro-1"}
                                            className={"espelho-venda-livro-1"}
                                            onChange={(e) => (e)}
                                            
                                        />

                                        <TextInputField
                                            width={205}
                                            label="Folha"
                                            description=""
                                            placeholder="Folha"
                                            defaultValue=""
                                            id={"espelho-venda-folha-1"}
                                            className={"espelho-venda-folha-1"}
                                            onChange={(e) => (e)}
                                            
                                        />

                                        <TextInputField
                                            width={205}
                                            marginBottom={0}
                                            marginLeft={20}
                                            label="Ato"
                                            description=""
                                            placeholder="Ato"
                                            defaultValue=""
                                            id={"espelho-venda-ato-1"}
                                            className={"espelho-venda-ato-1"}
                                            onChange={(e) => (e)}
                                            
                                        />
                                    </div>*/}
                                </div>

                                <div className="row-recibo">
                                    <div className="subtitle">Forma de pagamento*</div>

                                    <div className="line-checkbox">
                                        <Checkbox
                                            appearance="default"
                                            className="checkbox espelho-venda-checkbox-1"
                                            label="À vista"
                                            id="check-1"
                                            value="1"
                                            checked={checkedAVista}
                                            disabled={checkedFinanciamento === true || checkedFgts === true ? true : false}
                                            onChange={e => handleChecked(e) /*setChecked(e.target.checked)*/}
                                        />

                                        <Checkbox
                                            appearance="default"
                                            className="checkbox"
                                            id="check-2 espelho-venda-checkbox-2"
                                            value="2"
                                            label="Financiamento"
                                            checked={checkedFinanciamento}
                                            disabled={checkedAVista === true ? true : false}
                                            onChange={e => handleChecked(e)}
                                        />

                                        <Checkbox
                                            appearance="default"
                                            className="checkbox espelho-venda-checkbox-3"
                                            label="FGTS"
                                            id="check-3"
                                            value="3"
                                            checked={checkedFgts}
                                            disabled={checkedAVista === true ? true : false}
                                            onChange={e => handleChecked(e)}
                                        />

                                        <input
                                            type="hidden"
                                            value={
                                                aVista !== '' ? [aVista]
                                                    : financiamento !== '' && fgts !== '' ? [financiamento, fgts]
                                                        : financiamento !== '' && fgts === '' ? [financiamento]
                                                            : financiamento === '' && fgts !== '' ? [fgts]
                                                                : dadosEspelhoImovel.inscricaoMunicipal
                                            }
                                            name="documento"
                                            className={"espelho-venda-forma-pagamento-1"}
                                            id={"espelho-venda-forma-pagamento-1"} />
                                    </div>
                                </div>

                                <div className="row-recibo" style={{ marginTop: '35px' }}>
                                    <div className="subtitle">
                                        <CloudUploadIcon className="icon-subtitle" />
                                        Upload de documentos do imóvel
                                    </div>

                                    <p className="descritption description-upload">Atenção: subir arquivos no formato .doc ou .pdf. <br /> Para aprender a converter uma foto para pdf., <a href="https://www.tecmundo.com.br/apps/72485-apps-converter-documentos-para-pdf-celulares-tablets.htm" target="_blank" rel="noreferrer" title="Converter foto para PDF">clique aqui</a>.</p>

                                    <div className={"rowForm upload-espelho-venda upload-obrigatorio"}>
                                        <TextInputField
                                            width={215}
                                            marginBottom={0}
                                            marginLeft={0}
                                            label=""
                                            description=""
                                            placeholder="Ônus Reais*"
                                            defaultValue="Ônus Reais*"
                                            id={"espelho-venda-docs-1 input-obrigatorio"}
                                            className={"espelho-venda-doc-1 input-obrigatorio"}
                                            onChange={(e) => (e)}
                                            readOnly
                                        />
                                        <input type="hidden" className="doc-hidden-imovel" id="doc-hidden-imovel" name="" value="2" />
                                        <FilePicker
                                            width={250}
                                            placeholder="nome.arquivo"
                                            type="file"
                                            name="documento-obrigatorio"
                                            value={''}
                                            onChange={e => handleDocObrigatorio(e)}
                                            className={"file-input documento-obrigatorio"}
                                        />
                                    </div>

                                    {formValues.map((e, index) => (
                                        <div className={"rowForm upload-espelho-venda upload-" + index} key={index}>
                                            <SelectField
                                                value={e.tipoDocumento || ""}
                                                onChange={e => handleChangeTipoDocumento(index, e)}
                                                name="tipoDocumento"
                                                className={'documento-imovel espelho-venda-imovel-tipo-documento espelho-venda-documento-imovel-novo-' + index}
                                                style={{ marginBottom: 0 }}
                                                id={'espelho-venda-documento-imovel-novo-' + index}
                                                data-documento={'tipo-documento-novo-' + index}
                                                width={216}
                                                marginBottom={0}
                                                padding={0}
                                            >
                                                <option value="0" hidden>Selecione o tipo</option>
                                                {
                                                    tipoDocumentos.filter(tipoDocumento => tipoDocumento.tipo === "imóvel")
                                                        .map(tipoDocumento => <option value={tipoDocumento.id}>{tipoDocumento.nome}</option>)
                                                }
                                            </SelectField>

                                            <FilePicker
                                                width={250}
                                                placeholder="nome.arquivo"
                                                type="file"
                                                name="documento"
                                                value={e.documento || ""}
                                                onChange={e => handleChangeDocumento(index, e)}
                                                className={"file-input documento-" + index}
                                            />
                                            {
                                                index ?
                                                    <Button
                                                        marginRight={16}
                                                        appearance="primary"
                                                        intent="danger"
                                                        className="button remove"
                                                        style={{ left: '75px' }}
                                                        onClick={() => removeFormFields(index)}
                                                        type="button"
                                                    >
                                                        <DeleteIcon
                                                            size={10}
                                                            style={{ 'marginRight': '10px', 'fill': '#fff' }}
                                                        /> Excluir
                                                </Button>
                                                    : null
                                            }
                                        </div>
                                    ))}

                                    <Button
                                        className="btn-add-documento"
                                        onClick={(e) => addFormFields(e)}
                                    >
                                        <PlusIcon className="icon-label" />
                                        Adicionar mais documentos
                                    </Button>
                                </div>

                                <div className="row-recibo" style={{ marginTop: '35px' }}>
                                    <div className="subtitle">
                                        <EditIcon className="icon-subtitle" />
                                        Observações
                                    </div>

                                    <Label htmlFor="textarea-2" marginBottom={12} display="block">
                                        Caso necessário, insira observações para que o backoffice fique atento.
                                    </Label>

                                    <Textarea
                                        name="textarea-1"
                                        className="espelho-venda-observacao-1"
                                        id="recibo-observacao-1"
                                        placeholder="Exemplo: O imóvel se encontra em inventário e o comprador só está disponível para contato de 13h às 18h. "
                                        defaultValue={dadosEspelhoImovel === [] ? '' : dadosEspelhoImovel.observacao}
                                        height={110}
                                    />
                                </div>

                                <div className="mensagem-campos msg-obrigatorio">*Campos obrigatórios</div>

                                <div className="footer-form">
                                    <div>
                                        <Button className="btn-minimal btn-minimal-disabled" appearance="default" disabled onClick={(e) => handleSalvarContinuar(e)}>
                                            Salvar e continuar depois
                                        </Button>
                                    </div>

                                    <div>
                                        <Button appearance="default" marginLeft={15} onClick={(e) => handleBtnAnterior(e)}>
                                            <ChevronLeftIcon className="icon-label" style={{ margin: '0 5px 0 0' }} />
                                            Anterior
                                        </Button>

                                        <Button
                                            type="submit"
                                            disabled={validarSubmit() === 'enabled' ? false : true}
                                            appearance="primary"
                                            marginLeft={15}
                                            onClick={(e) => handleBtnAvancar(e)}
                                        >
                                            Avançar
                                            <ChevronRightIcon className="icon-save" />
                                        </Button>
                                    </div>
                                </div>
                            </Pane>
                        </form>
                    </div>
                </div>
            </Dialog>
        </Pane>
    )
}