const dateMask = v => {
    // if (!v) return "";
    // v = v.replace(/\D/g, ""); //Substituí o que não é dígito por "", /g é [Global][1]
    // v = v.replace(/(\d{2})(\d)/, "$1/$2");
    // v = v.replace(/(\d{2})(\d)/, "$1/$2");
    // v = v.replace(/(\d{4})(\d)/, "$1");

    // //v = v.replace(/(\d{2})(\d{2})(\d{4})$/, "$1/$2/$3");
    // // \d{1,2} = Separa 1 grupo de 1 ou 2 carac. (\d{3}) = Separa 1 grupo de 3 carac. (\d{1}) = Separa o grupo de 1 carac.
    // // "$1.$2.$3-$4" = recupera os grupos e adiciona "." após cada.

    // return v

    if (!v) return "";
    v = v.replace(/\D/g, ""); //Substituí o que não é dígito por "", /g é [Global][1]
    v = v.replace(/(\d{2})(\d)/, "$1/$2");
    v = v.replace(/(\d{2})(\d)/, "$1/$2");
    v = v.replace(/(\d{4})(\d)/, "$1");

    return v
}

export default dateMask;