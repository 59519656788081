import React, {useContext} from 'react'
import {Pane} from 'evergreen-ui';
import AcessoTemporarioContext from '../../../../../../context/AcessoTemporarioContext';

export default function MobileAjuda() {

    const {
        faq,
    } = useContext(AcessoTemporarioContext);

    return (
        <Pane>
            <div className="ajuda-temporaria-dialog ajuda-temporaria-dialog-mobile">
                <h2>FAQ - Perguntas frequentes</h2>

                <ul>
                    {
                        faq.map(resp => <li key={resp.id}>
                            <div className="title">{resp.title}</div>
                            <div className="content">{resp.content}</div>
                        </li>)
                    }
                </ul>
            </div>
        </Pane>
    )
}
