import React, { useContext, useState, useEffect } from 'react';
import Axios from 'axios';
import Serve from '../../../../app/serve';
import { Dialog,  TextInputField, Pane, Avatar, Text, SelectField, StatusIndicator, Pill, toaster, ErrorIcon, Label } from 'evergreen-ui';
import PainelAdminContext from '../../../../context/PainelAdminContext'
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import cpfMask from '../../../../components/cpfMask';
import validarCPF from '../../../../components/validarCPF';

export default function AddUser({userLoading}) {
  const token = localStorage.getItem('token');	
	const app = Serve();
  const { 
    showAddUser, setShowAddUser,     
    userSelected, setUserSelected,    
    cargoList,
    shopsList,
    loading,
    setLoading,
  } = useContext(PainelAdminContext);    

  const { register, handleSubmit, setValue, watch, reset,setError,clearErrors, formState: { errors } } = useForm();
  const msgError = "Campo obrigatório.​"   
  console.log(errors);
  
  const [telNumberValue, setTelNumberValue] = useState();  

  const inputError = {
    borderColor: '#429777 !important',
    background: '#F5FBF8 !important'
  };

  const handleSelect = (e) => {
    if (e) {
      const value = e.target.value;
      const name = e.target.name
      setValue(name, value)
    }
  };

  useEffect(() => {
    if(userSelected.tel) {
      setTelNumberValue(userSelected.ddi + userSelected.tel);
      setValue("funcao", userSelected.cargoId)
      setValue("loja", userSelected.lojaId)
      setValue("email", userSelected.email)
      setValue("nome", userSelected.name)
      setValue("cpf", userSelected.cpf)
      setValue("telefone", userSelected.telefone)
    } else {
      setTelNumberValue("");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userSelected])

  const addUser = (e, close) => {
    console.log(e);
    setLoading(true);
    const api = userSelected.id ? 'editar_usuario' : 'criar_usuario';
    const msg = userSelected.id ? 'Usuário atualizado com sucesso' : 'Usuário criado com sucesso';
    Axios.post(app + api, {
      "id_usuario": userSelected.id,
      "name": e.nome,
      "cpf": e.cpf,
      "email": e.email,
      "telefone": e.telefone,
      "perfil_login_id": e.funcao,
      "loja_id": e.loja,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(res => {
      if (res) {
        console.log(res);
        if (res.data.mensagem_usuario === "Usuario já cadastrado") {
          toaster.danger("O e-mail já está em uso. Insira outro.")
          setError('email_cadastrado', { type: 'custom', message: 'custom message' });
        } else {
          userLoading();
          toaster.success(msg)
          close();
        }        
      } else {
        toaster.danger('Ops, algo deu errado')
      }
    }).then(() => {
      setLoading(false)
    })
  };

  const handleTelefone = (e, country) => {
    setTelNumberValue(e);
    console.log(e.replace(country.dialCode, ""), country);
    setValue('telefone', e.replace(country.dialCode, ""))
  };

  console.log(watch());
  
  return (
    <Dialog
      isShown={showAddUser}
      title={
        <div className='flex-center justifyBetween pb20'>
          <h4>{userSelected.id ? userSelected.cargo : "Insira um novo usuário"}</h4>
          {userSelected.activated ?
          <Pill  color="green" marginRight={60} paddingBottom={20} >    
            <StatusIndicator color="success" dotSize={20} margin={0}> Ativo </StatusIndicator>
          </Pill >
          :
          <Pill  color="red" marginRight={60} paddingBottom={20} >    
            <StatusIndicator color="danger" dotSize={20} margin={0}> Desativado </StatusIndicator>
          </Pill >
          }
        </div>
      }
      onCloseComplete={() => [setShowAddUser(false), reset(), setUserSelected({})]}
      onConfirm={handleSubmit((e, close) => addUser(e, close))}
      isConfirmLoading={loading}
      shouldCloseOnOverlayClick={false}
      confirmLabel="Confirmar"
      cancelLabel="Cancelar"
    >
      <Pane className='addUser'>
        <div className='headerUsers'>
          <Avatar size={90} name={userSelected.name || watch('nome')} className={!userSelected.activated && "img-pb"} />          
          <Text className='textOverflow ml20 mxW400'>{userSelected.name} </Text>          
        </div>
        
        <div>
          <TextInputField
            label='Nome:'
            name='Nome'
            autoFocus
            defaultValue={userSelected.name}
            width='100%'
            background="white"
            marginBottom={3}
            placeholder='Exemplo: Josefina Craudia'
            className={(
              watch('nome') ? 'inputGreenSelect' :
                errors.funcao ? 'inputRedSelect' : ''
            )}
            {...register("nome", {
              required: true,
            })}
          />
          {errors.nome &&
          <div className='flex-center error-genero'>
            <ErrorIcon /> <Text marginLeft={0}>{msgError}</Text>
          </div>}
        </div>

        <div>
          <TextInputField
            label='CPF:'
            name='CPF'
            autoFocus
            defaultValue={userSelected.cpf}
            width='100%'
            background="white"
            marginTop={20}
            marginBottom={3}
            placeholder='000.000.000-00'
            maxLength='14'
            value={watch('cpf')}
            className={(
              watch('cpf')?.length === 14 && !errors?.cpf?.message ? 'inputGreenSelect' :
                  errors?.cpf?.message ? 'inputRedSelect' : ''
            )}
            {...register("cpf", {
              required: msgError,
              onChange: (e) => setValue('cpf', e.target.value),
              maxLength: 14,
              setValueAs: e => cpfMask(e),
              validate: (value) => validarCPF(value) || "CPF inválido"
            })}
            validationMessage={errors?.cpf?.message}
          />
          
        </div>

        <div>
          <SelectField
            label='Cargo:'
            name='Função'
            defaultValue={userSelected.cargoId}
            width='100%'
            marginTop={20}
            background="white"            
            marginBottom={3}
            className={(
              watch('funcao')  ? 'inputGreenSelect' :
                  errors.funcao ? 'inputRedSelect' : ''
            )}
            {...register("funcao", {
              required: true,
              onChange: ((e) => handleSelect(e))
            })}
          >
            <option value="" hidden selected >Cargo</option>            
            {cargoList.map((cargo) => (
              <option value={cargo.id}>{cargo.nome}</option>
            ))}
          </SelectField>
          {errors.funcao &&
          <div className='flex-center error-genero'>
            <ErrorIcon /> <Text marginLeft={0}>{msgError}</Text>
          </div>}
        </div>

        <div hidden={(watch("funcao") === "3" || watch("funcao") === "4" || watch("funcao") === "7") ? false : true}>          
          <SelectField
            label='Unidade:'
            name='Loja'
            defaultValue={userSelected.lojaId}
            width='100%'
            background="white"
            marginTop={20}
            marginBottom={3}
            className={(
              watch('loja')  ? 'inputGreenSelect' :
                  errors.loja ? 'inputRedSelect' : ''
            )}
            {...register("loja", {
              required: watch("funcao") === "3" || watch("funcao") === "4" || watch("funcao") === "7",              
              onChange: ((e) => handleSelect(e))
            })}
          >
            <option value="" hidden selected >Loja</option>
            {shopsList.map((loja) => (
              loja &&
              <option value={loja.id}>{loja.nome}</option>
            ))}
          </SelectField>
          {errors.loja &&
          <div className='flex-center error-genero'>
            <ErrorIcon /> <Text marginLeft={0}>{msgError}</Text>
          </div>}
        </div>

        <TextInputField
          label="E-mail:"
          marginTop={20}
          placeholder="email@email.com.br"
          width="100%"
          marginBottom={errors.email ? 3 : 20}
          defaultValue={userSelected.email}
          className={(
            errors.email || errors.email_cadastrado  ? 'inputRedSelect' :
            watch('email') ? 'inputGreenSelect' : ''
          )}
          {...register("email", {
            required: true,
            onChange: (() => clearErrors())
          })}
        />
        {errors.email &&
          <div className='flex-center error-genero'>
            <ErrorIcon /> <Text marginLeft={0}>{msgError}</Text>
          </div>}
        
        <Label>Telefone:</Label>        
        <PhoneInput
          country={"br"}
          onChange={(e, country) => { handleTelefone(e, country); }}
          value={telNumberValue}          
          preferredCountries={['br', 'us']}
          containerClass={'w20'}
          inputClass={
            watch('telefone')  ? 'inputGreenSelect' :
                errors.telefone ? 'inputRedSelect' : ''
          }
        />        
        {errors.telefone &&
          <div className='flex-center error-genero'>
            <ErrorIcon /> <Text marginLeft={0}>{msgError}</Text>
          </div>}
      </Pane>
    </Dialog>
  )
};