import Axios from 'axios';
import Serve from '../app/serve';

async function updateStatusVisualizarApoio(id) {
  const token = localStorage.getItem('token');
  const app = Serve();
  let data;

  Axios.post(app + 'atualizar_status_visualizacao_apoio', {
    id
  }, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then(res => {
      if (res.data.status && res.data.status === (401 || 498)) {
        localStorage.clear();
      }
      console.log('atualizar_status_visualizacao_gerente', res);
      data = res.data;      
    })
    .catch(function (error) {
      console.log(error);
    })

  return data;
};

export default updateStatusVisualizarApoio;