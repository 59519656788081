import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Pane, ErrorIcon, Spinner, Dialog, TextInputField, FilePicker, Badge, CloudUploadIcon, Button, PlusIcon, ChevronLeftIcon, ChevronRightIcon, DeleteIcon, SelectField, Checkbox, Tablist, Tab, toaster } from 'evergreen-ui';

//Context
import VendasContext from '../../../../../../context/VendasContext';

//Components
import Axios from 'axios';
import Serve from '../../../../../../app/serve';
import { useHistory } from 'react-router-dom';
import Steps from './Steps';
import phoneMask from '../../../../../../components/phoneMask';
import MultiUploadDoc from '../../../../../../components/MultiUploadDoc';
import RevisaoPosVenda from './components/RevisaoPosVenda';

export default function Vendedor() {

    const {
        loading,
        setCountMenu,
        dadosVenda, setDadosVenda,
        setItemAdicionado, setLoading,
        vendedorProcesso, setVendedorProcesso,
        isShowFormBoletimDeVenda, setIsShowFormBoletimDeVenda,
        setPosicaoBoletimDeVenda, tipoDocumentos,
        setIsShowCornerDialog,
        setModelCornerDialog,
        multiDocs,
    } = useContext(VendasContext);

    console.log("multiDocs: ", multiDocs);

    const app = Serve();
    const history = useHistory();
    const [token] = useState(localStorage.getItem('token'));
    const [gerente_id] = useState(localStorage.getItem('usuario_id'));
    const [alteracaoRevisao, setAlteracaoRevisao] = useState(false);

    //CSS inputs
    const inputGreen = {
        borderColor: '#429777',
        background: '#F5FBF8'
    };

    const inputDefault = {
        borderColor: '#ADC2FF',
        background: '#fff'
    };

    const inputError = {
        borderColor: '#FF0000',
        background: '#fff'
    }

    //Tabs
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [tabs, setTabs] = useState([])
    const [posicaoTabs, setPosicaoTabs] = useState([]);
    const [checkedPessoaJuridica, setCheckedPessoaJuridica] = useState(false);
    const [idsVendedores, setIdsVendedores] = useState([]);
    const [procuradores, setProcuradores] = useState([]);
    const [idsRepresentantes, setIdsRepresentantes] = useState([]);

    //Cantinho do hook Form
    const { register, unregister, handleSubmit, clearErrors, setValue, watch, reset, formState: { errors } } = useForm({
        defaultValues: {
            vendedores: vendedorProcesso.data.map(vendedor => {
                return {
                    id: vendedor.id,
                    procurador: {
                        check: vendedor.procurador?.id ? true : false,
                        telefone: vendedor.procurador?.telefone,
                        nome: vendedor.procurador?.nome,
                    },
                    tipo_pessoa: vendedor.tipo_pessoa,
                }
            }),
        }
    });
    const errorFileInput = "Selecione o arquivo para avançar";
    const errorSelectInput = "Selecione o tipo de arquivo para avançar";
    const errorTextInput = "Campo obrigatório.";

    console.log("watch", watch());
    console.log("errors: ", errors);

    const retornaDocumentos = async () => {
        if (vendedorProcesso['data'].length > 0) {
            const IdsUsuarios = vendedorProcesso.data.map(usuario => ({ "usuario_id": usuario.id }));
            setIdsVendedores(IdsUsuarios);
            vendedorProcesso.data.forEach((vendedor, index) => {
                // monto array de procuradores
                if (vendedor.procurador === null) {
                    let procurador = { idUsuario: vendedor.id, idProcurador: '', checkProcurador: false, procurador: '', telefoneProcurador: '' };
                    procuradores.push(procurador);
                    setProcuradores(procuradores);

                } else {
                    let procurador = { idProcurador: vendedor.procurador.id, checkProcurador: true, procurador: vendedor.procurador.nome, telefoneProcurador: vendedor.procurador.telefone };
                    procuradores.push(procurador);
                }

                setIdsRepresentantes(idsRepresentantes);

                // Validação Revisão
                if (dadosVenda.status_processo === 22) {
                    if (dadosVenda.revisao.vendedores_verific_save === 0) {
                        setAlteracaoRevisao(false);
                    }
                    else {
                        setAlteracaoRevisao(true);
                    }
                }
            });
        }
    };
    
    useEffect(() => {
        retornaDocumentos();

        const returnVendedor = async () => {
            if (vendedorProcesso['data'].length > 0) {
                setPosicaoTabs([...posicaoTabs, 1]);
                verificarTipoVendedor(0);
            }
            const array = vendedorProcesso['data'].length;
            var arrayFinal = []
            for (let i = 0; i < array; i++) {
                arrayFinal.push(i);
                setTabs(arrayFinal);
            }
        }
        returnVendedor();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Verifica se o Vendedor é Pessoa Física ou Júridica
    const verificarTipoVendedor = async (index) => {
        if (vendedorProcesso.data[index] !== undefined) {
            if (vendedorProcesso.data[index].tipo_pessoa === 0) {
                // PF
                setCheckedPessoaJuridica(false);
            }
            else {
                // PJ
                setCheckedPessoaJuridica(true);
            }
        }
        else {
            // PF
            setCheckedPessoaJuridica(false);
        }
    };

    const handleOpenTab = async (index) => {
        verificarTipoVendedor(index);
    };

    const closeBoletimDeVenda = () => {
        reset();
        setIsShowFormBoletimDeVenda(false);
        setPosicaoBoletimDeVenda(2);
        setAlteracaoRevisao(false);
    };

    const handleCheckedProcurador = (e, index) => {
        setValue('vendedores.' + index + '.procurador.check', e.target.checked);
        procuradores[index]['checkProcurador'] = e.target.checked;
        if (!e.target.checked) {
            clearErrors("vendedores")
        }
    };

    const handleBtnAnterior = (e) => {
        e.preventDefault();
        //setPosicaoBoletimDeVenda(1);
        if (dadosVenda.status_processo === 22) {
            if (dadosVenda.revisao.valores_documentos_imovel === 1) {
                setPosicaoBoletimDeVenda(1);
            }
        }
        else {
            setPosicaoBoletimDeVenda(1);
        }
    };

    //Verificando se os campos foram alterados para Revisão
    const handleVerificarRevisao = (e) => {
        if (e.target !== undefined) {
            setAlteracaoRevisao(true);
        }
        else {
            toaster.danger('Realize os ajustes solicitados pelo pós-venda para avançar.');
        }
    };

    const handleBtnAvancar = (dados) => {
        setLoading(false);
        let checkProcurador = "";
        let procurador = "";
        let telefoneProcurador = "";

        const quantVendedores = vendedorProcesso.data.length;
        let arrayMensagemServidor = [];

        vendedorProcesso.data.forEach((element, index_Pessoa) => {
            let arrayData = new FormData();

            const saveMultiDocs = (e, index_documento, id_usuario) => {
                if (e.file && e.item[0]) {
                    if (typeof (e.file) === 'string' || e.file instanceof String) {
                        let arquivoVazio = new File(["foo"], "nao_teve_alteracao_foo.txt", {
                            type: "text/plain",
                        });
                        arrayData.append(`arquivos[${index_documento}]`, arquivoVazio);
                    } else {
                        arrayData.append(`arquivos[${index_documento}]`, e.file);
                    }
                    if (e) {
                        e.item.forEach((tipo, index_tipo) => {
                            arrayData.append(`tipo_documento_ids[${index_documento}][${index_tipo}]`, tipo.value);
                            arrayData.append(`multiplo_documento_id[${index_documento}][${index_tipo}]`, tipo.id ? tipo.id : "");
                        })
                    }
                    arrayData.append(`documentos_ids[${index_documento}]`, e.id ? e.id : "");
                    arrayData.append(`idDonoDocumento[${index_documento}]`, id_usuario);
                    arrayData.append(`papel[${index_documento}]`, 'vendedor');
                }
            };

            

            // MULTIPLOS DOCUMENTOS EM 1 ARQUIVO PESSOA FISICA
            multiDocs.vendedores[index_Pessoa].fisica?.forEach((docs, index_documento) => {
                saveMultiDocs(docs, index_documento, element.id);
            });

            //TIPO PESSOA JURIDICA
            if (element.tipo_pessoa === 1) {
                // MULTIPLOS DOCUMENTOS EM 1 ARQUIVO PESSOA JURIDICA
                multiDocs.vendedores[index_Pessoa].juridica?.forEach((docs, index_documento) => {
                    saveMultiDocs(docs, index_documento, element.id);
                });

                // MULTIPLOS DOCUMENTOS EM 1 ARQUIVO REPRESENTANTE
                multiDocs.vendedores[index_Pessoa].representante_socios?.forEach((representante, index_rp) => {
                    representante.forEach((rep, index_documento_rep) => {
                        if (rep.file && rep.item[0]) {
                            if (typeof (rep.file) === 'string' || rep.file instanceof String) {
                                let arquivoVazioRepresentante = new File(["foo"], "nao_teve_alteracao_foo.txt", {
                                    type: "text/plain",
                                });
                                arrayData.append(`rp_arquivos[${index_rp}][${index_documento_rep}]`, arquivoVazioRepresentante);
                            } else {
                                arrayData.append(`rp_arquivos[${index_rp}][${index_documento_rep}]`, rep.file);
                            }
                            if (rep) {
                                rep.item.forEach((tipo, index_tipo) => {
                                    arrayData.append(`rp_tipo_documento_ids[${index_rp}][${index_documento_rep}][${index_tipo}]`, tipo.value);
                                    arrayData.append(`rp_multiplo_documento_id[${index_rp}][${index_documento_rep}][${index_tipo}]`, tipo.id ? tipo.id : "");
                                })
                            }
                            arrayData.append(`rp_documentos_ids[${index_rp}][${index_documento_rep}]`, rep.id ? rep.id : "");
                            arrayData.append(`rp_idDonoDocumento[${index_rp}][${index_documento_rep}]`, element.representante_socios.data[index_rp].id);
                            arrayData.append(`rp_papel[${index_rp}][${index_documento_rep}]`, 'Representante');
                        }
                    })
                });

            };

            console.log('DADOSVENDA: ', dadosVenda)
            console.log('DADOS:' , dados)

            arrayData.append(`processo_id`, dadosVenda.processo_id);
            arrayData.append('usuario_id_logado', gerente_id);
            arrayData.append(`identifica_documento`, element.tipo_pessoa === 0 ? 'pessoa_fisica' : 'pessoa_juridica');

            if (procuradores[index_Pessoa]['checkProcurador'] === true) {
                checkProcurador = 'S';
                procurador = dados.vendedores[index_Pessoa].procurador.nome;
                telefoneProcurador = dados.vendedores[index_Pessoa].procurador.telefone;
            }
            else {
                checkProcurador = "N";
                procurador = "";
                telefoneProcurador = "";
            }
            arrayData.append('participante_negociacao_id', element.id);
            arrayData.append('checkProcurador', checkProcurador);
            arrayData.append('procurador', procurador);
            arrayData.append('telefoneProcurador', telefoneProcurador);

            if (dadosVenda.status_processo === 22) {
                arrayData.append('revisao', 1);
                arrayData.append('usuario_tipo', 'vendedor');
                arrayData.append('revisao_id', dadosVenda.revisao.id)
            }

            arrayData.append('status_processo', dadosVenda.status_processo)

            // // Documentos
            // const saveMultiDocs = (e, index_documento, representante) => {
            //     if (e.file && e.item[0]) {        
            //       if (e) {
            //         e.item.forEach((tipo, index_tipo) => {
            //           arrayData.append(`tipo_documento_ids[${index_documento}][${index_tipo}]`, tipo.value);
            //           arrayData.append(`multiplo_documento_id[${index_documento}][${index_tipo}]`, tipo.id ? tipo.id : "");
            //           arrayData.append(`data_emissao[${index_documento}][${index_tipo}]`, tipo.emissao || '');  // DATA INICIAL
            //           arrayData.append(`validade_dias[${index_documento}][${index_tipo}]`, tipo.validade || ''); // DIAS
            //           arrayData.append(`data_vencimento[${index_documento}][${index_tipo}]`, tipo.vencimento || ''); // DATA FINAL
            //         })
            //       }
            //       arrayData.append(`documentos_ids[${index_documento}]`, e.id ? e.id : "");
            //       arrayData.append(`idDonoDocumento[${index_documento}]`, dadosVenda.id);
            //       // arrayData.append(`papel[${index_documento}]`, representante || tipoPessoa.charAt(0).toUpperCase() + tipoPessoa.substring(1));
            //     }
            // };

            // // MULTIPLOS DOCUMENTOS EM 1 ARQUIVO PESSOA FISICA
            // multiDocs['vendedores'][index_Pessoa].fisica?.forEach((e, index_documento) => {
            //     saveMultiDocs(e, index_documento);
            // });

            // //if (dadosVenda.tipo_pessoa) {
            //     arrayData.append('razao_social', dadosVenda.razao_social || '');
            //     arrayData.append('nome_fantasia', dadosVenda.nome_fantasia || '');
          
            //     // MULTIPLOS DOCUMENTOS EM 1 ARQUIVO PESSOA JURIDICA
            //     multiDocs['vendedores'][index_Pessoa].juridica?.forEach((e, index_documento) => {
            //       saveMultiDocs(e, index_documento);
            //     });
          
            //     // CAMPOS DOS REPRESENTANTES
            //     dadosVenda.data[index_Pessoa].representante_socios.data.forEach((rep, index_rep) => {
            //       arrayData.append(`rp_id[${index_rep}]`, rep.id || '');
            //       arrayData.append(`rp_socio[${index_rep}]`, rep.pj_socio || 0);
            //       arrayData.append(`rp_representante[${index_rep}]`, rep.pj_representante || 0);
            //       arrayData.append(`rp_name[${index_rep}]`, rep.name || '');
            //       arrayData.append(`rp_cpf_cnpj[${index_rep}]`, rep.cpf_cnpj || '');
            //       arrayData.append(`rp_email[${index_rep}]`, rep.email || '');
            //       arrayData.append(`rp_rg[${index_rep}]`, rep.rg || '');
            //       arrayData.append(`rp_rg_expedido[${index_rep}]`, rep.rg_expedido || '');
            //       arrayData.append(`rp_data_rg_expedido[${index_rep}]`, rep.data_rg_expedido || '');
            //       arrayData.append(`rp_profissao[${index_rep}]`, rep.profissao || '');
            //       arrayData.append(`rp_nome_fantasia[${index_rep}]`, rep.nome_fantasia || '');
            //       arrayData.append(`rp_nacionalidade[${index_rep}]`, rep.nacionalidade || '');
            //       arrayData.append(`rp_estado_civil[${index_rep}]`, rep.estado_civil || '');
            //       arrayData.append(`rp_uniao_estavel[${index_rep}]`, rep.uniao_estavel || '');
            //       arrayData.append(`rp_registro_casamento[${index_rep}]`, rep.registro_casamento || '');
            //       arrayData.append(`rp_conjuge[${index_rep}]`, rep.conjuge || '');
            //       arrayData.append(`rp_genero[${index_rep}]`, rep.genero || '');
          
            //       // MULTIPLOS DOCUMENTOS EM 1 ARQUIVO REPRESENTANTE
            //       multiDocs['vendedores'][index_Pessoa].representante_socios?.[index_rep].forEach((rep, index_documento_rep) => {
            //         if (rep.file && rep.item[0]) {
            //           if (rep) {
            //             rep.item.forEach((tipo, index_tipo) => {
            //               arrayData.append(`rp_tipo_documento_ids[${index_rep}][${index_documento_rep}][${index_tipo}]`, tipo.value);
            //               arrayData.append(`rp_multiplo_documento_id[${index_rep}][${index_documento_rep}][${index_tipo}]`, tipo.id ? tipo.id : "");
            //               arrayData.append(`rp_data_emissao[${index_rep}][${index_documento_rep}][${index_tipo}]`, tipo.emissao || '');  // DATA INICIAL
            //               arrayData.append(`rp_validade_dias[${index_rep}][${index_documento_rep}][${index_tipo}]`, tipo.validade || ''); // DIAS
            //               arrayData.append(`rp_data_vencimento[${index_rep}][${index_documento_rep}][${index_tipo}]`, tipo.vencimento || ''); // DATA FINAL
            //             })
            //           }
            //           arrayData.append(`rp_documentos_ids[${index_rep}][${index_documento_rep}]`, rep.id ? rep.id : "");
            //           //arrayData.append(`rp_idDonoDocumento[${index_rep}][${index_documento}]`, dataToSave.id);
            //           arrayData.append(`rp_papel[${index_rep}][${index_documento_rep}]`, 'Representante');
            //         }
          
            //       })
            //     })
            // //};

            Axios.post(app + 'salvar_documentos_usuario', arrayData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(res => {
                    if (res.data.status && res.data.status === (401 || 498)) {
                        localStorage.clear();
                        history.push('/');
                    } else {
                        let mensagem = res.data.mensagem_status_processo;
                        arrayMensagemServidor.push(mensagem);
                        if (arrayMensagemServidor.length === quantVendedores) {
                            recarregarVenda(dadosVenda.processo_id, 'avancar');
                            alteracaoRevisao(false);
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
        });
    }

    async function recarregarVenda(idProcessoNovo, avancar) {
        //Exibe a listagem atualizada de acordo        
        setLoading(false);
        await Axios.post(app + 'retorno_processos_painel_gerentes', {
            "usuario_id": gerente_id,
            'tipo_processos': 'adicionados',
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        })
            .then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    const processos = res.data.processos.data;
                    setItemAdicionado(processos);
                    processos.forEach(element => {
                        if (Number(idProcessoNovo) === element.processo_id) {
                            setDadosVenda(element);
                            setVendedorProcesso(element.vendedores);

                            if (avancar) {
                                if (dadosVenda.status_processo === 22) {
                                    if (dadosVenda.revisao.compradores === 1) {
                                        setCountMenu(3);
                                        setPosicaoBoletimDeVenda(3);
                                    }
                                    else if (dadosVenda.revisao.comissao === 1) {
                                        setCountMenu(4);
                                        setPosicaoBoletimDeVenda(4);
                                    }
                                    else {
                                        setIsShowCornerDialog(true);
                                        //setModelCornerDialog(3);
                                        setModelCornerDialog(6);
                                        setIsShowFormBoletimDeVenda(false);
                                    }
                                }
                                else {
                                    setCountMenu(3);
                                    setPosicaoBoletimDeVenda(3);
                                }
                            }
                        }
                    });
                }
            })            
        setLoading(true);
        retornaDocumentos();
    };

    const onError = (error) => {
        tabs.forEach((tab, index) => {
            console.log("Erro", error.vendedores[tab]);
            if (error.vendedores[tab]) {
                setSelectedIndex(tab);
            }
        })
    };

    useEffect(() => {
        console.log("TESTE MULTI DOCS");
    }, [multiDocs])

    const handleRedirect = (e) => {
        dadosVenda.status_processo !== 22
            ? handleBtnAvancar(e)
            : alteracaoRevisao === true ? handleBtnAvancar(e) : handleVerificarRevisao(e)
    };

    return (
        <Dialog
            width={dadosVenda.status_processo === 22 ? 950 : 808}
            isShown={isShowFormBoletimDeVenda}
            title=''
            onCloseComplete={(e) => closeBoletimDeVenda(e)}
            hasFooter={false}
            shouldCloseOnOverlayClick={false}
            containerProps={{
                className: 'form-dialog-props'
            }}
        >
            <div className="form-dialog-fixed" style={{ height: tabs.length < 6 ? "90px" : tabs.length < 12 ? "120px" : "150px" }}>
                <Steps />

                <Tablist marginBottom={16} flexBasis={240} marginRight={24} className="tab-form">
                    {tabs.map((tab, index) => (
                        <Tab
                            key={tab}
                            id={"titulo_top_vendedor_" + index}
                            onSelect={() => setSelectedIndex(index)}
                            isSelected={index === selectedIndex}
                            aria-controls={`panel-${tab}`}
                            data-tab={tab}
                            onClick={() => handleOpenTab(index)}
                            title={index < vendedorProcesso.data.length ? vendedorProcesso.data[index].name : 'Vendedor ' + tab}
                        >

                            {vendedorProcesso.data[index].tipo_pessoa === 0
                                ? index < vendedorProcesso.data.length ? vendedorProcesso.data[index].name.slice(0, 10) + '...' : 'Vendedor ' + tab
                                : index < vendedorProcesso.data.length ? vendedorProcesso.data[index].nome_fantasia.slice(0, 10) + '...' : 'Vendedor ' + tab
                            }
                        </Tab>
                    ))}
                </Tablist>
            </div>
            {loading === false
                ?
                <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                    <Spinner size={80} />
                </Pane>
                :
                <div className="form-dialog formGroup formAdd">
                    {tabs.map((tab, index_Vendedor) => (
                        <Pane
                            key={tab}
                            id={`panel-${tab}`}
                            role="tabpanel"
                            aria-labelledby={tab}
                            aria-hidden={index_Vendedor !== selectedIndex}
                            display={index_Vendedor === selectedIndex ? 'block' : 'none'}
                        >
                            {
                                dadosVenda.status_processo === 22
                                    ? dadosVenda.revisao.vendedores === 1
                                        ? <RevisaoPosVenda
                                            tab="vendedor_comprador"
                                            situacao={dadosVenda.revisao.vendedores_verific_save}
                                            mensagem={dadosVenda.revisao.vendedores_observacao !== null ? dadosVenda.revisao.vendedores_observacao : ''}
                                        />
                                        : ''
                                    : ''
                            }

                            {/* FORMULARIO PARA PESSOA JURÍDICA */}
                            {vendedorProcesso.data[index_Vendedor].tipo_pessoa === 1 &&
                                <div className='Form Pessoa Física'>
                                    <Badge color="green" marginTop={dadosVenda.status_processo === 22 ? 15 : 70}> PESSOA JURÍDICA </Badge>

                                    <form id="formGroup" className="formGroup-dialog" style={{ top: 0 }} encType="multipart/form-data" onChange={e => dadosVenda.status_processo === 22 ? handleVerificarRevisao(e) : (e)}>
                                        <input id={"vendedor_id-" + index_Vendedor} type="hidden" value={idsVendedores[index_Vendedor]['usuario_id']} />
                                        <input id={"vendedor_tipo_pessoa-" + index_Vendedor} type="hidden" value={checkedPessoaJuridica === false ? "0" : "1"} />
                                        <Pane>
                                            <div className="row-recibo">
                                                <div className="subtitle">
                                                    <h4><CloudUploadIcon className="icon-subtitle" /> Upload de documentos </h4>
                                                </div>

                                                <p className="descritption description-upload">Atenção: subir arquivos no formato .doc, .docx ou .pdf. <br /> Para aprender a converter uma foto para pdf., <a href="https://www.tecmundo.com.br/apps/72485-apps-converter-documentos-para-pdf-celulares-tablets.htm" target="_blank" rel="noreferrer" title="Converter foto para PDF">clique aqui</a>.</p>

                                                {/* Sessão de Envio de Arquivos da Empresa */}
                                                <div className="SESSÃO DOC EMPRESA">
                                                    <p className='subtitle'>Empresa</p>
                                                    <MultiUploadDoc pessoa={'vendedores'} type="juridica" context={VendasContext} indexPessoa={index_Vendedor} refreshProcess={recarregarVenda} idDonoDocumento={vendedorProcesso.data[index_Vendedor].id} />
                                                </div>

                                                {/* Sessão de Envio de Arquivos dos Sócios/Representantes */}
                                                <div className="SESSÃO_DOC_SÓCIO_REPRESENTANTE">
                                                    <p className='subtitle'>Sócios e/ou Representantes Legais</p>
                                                    <p className="descritption"></p>

                                                    {vendedorProcesso.data[index_Vendedor].representante_socios.data.map((representante, index_Representante) => (
                                                        <div key={index_Representante}>
                                                            <div className='rowForm'>
                                                                <p className="descritption" style={{ marginBottom: '16px' }}>{representante.name}</p>
                                                                {representante.pj_representante === 1 && <Badge color="neutral" marginLeft={14}> REPRESENTANTE LEGAL </Badge>}
                                                                {representante.pj_socio === 1 && <Badge color="neutral" marginLeft={6}> SÓCIO </Badge>}
                                                            </div>

                                                            {/* UPLOAD DE DOCUMENTOS */}
                                                            <MultiUploadDoc pessoa={'vendedores'} type="fisica" context={VendasContext} indexPessoa={index_Vendedor} indexRepresentante={index_Representante} refreshProcess={recarregarVenda} idDonoDocumento={vendedorProcesso.data[index_Vendedor]?.representante_socios?.data?.[index_Representante]?.id} />

                                                        </div>
                                                    ))}


                                                </div>

                                            </div>
                                            {/* SESSÃO DE RODAPÉ */}
                                            <div className="mensagem-campos msg-obrigatorio">*Campos obrigatórios</div>

                                            <div className="footer-form">
                                                <div>
                                                    {/* ESPAÇO PARA ALINHAR A DIREITA OS BOTÕES */}
                                                </div>
                                                <div>
                                                    {
                                                        dadosVenda.status_processo === 22
                                                            ?
                                                            dadosVenda.revisao.valores_documentos_imovel === 1
                                                                ?
                                                                <Button appearance="default" marginLeft={15} onClick={(e) => handleBtnAnterior(e)}>
                                                                    <ChevronLeftIcon className="icon-label" style={{ margin: '0 5px 0 0' }} />
                                                                    Anterior
                                                                </Button>
                                                                :
                                                                ''

                                                            :
                                                            <Button appearance="default" marginLeft={15} onClick={(e) => handleBtnAnterior(e)}>
                                                                <ChevronLeftIcon className="icon-label" style={{ margin: '0 5px 0 0' }} />
                                                                Anterior
                                                            </Button>

                                                    }

                                                    <Button
                                                        appearance="primary"
                                                        marginLeft={15}
                                                        //onClick={handleSubmit((e) => handleBtnAvancar(e))}
                                                        onClick={
                                                            handleSubmit(e => dadosVenda.status_processo !== 22
                                                                ? handleBtnAvancar(e)
                                                                : alteracaoRevisao === true ? handleBtnAvancar(e) : handleVerificarRevisao(e))
                                                        }
                                                    >
                                                        {dadosVenda.status_processo === 22
                                                            ? dadosVenda.revisao.compradores === 1
                                                                ? 'Próxima correção'
                                                                : dadosVenda.revisao.comissao === 1
                                                                    ? 'Próxima correção'
                                                                    : 'Salvar correções'

                                                            : 'Avançar'
                                                        }
                                                        <ChevronRightIcon className="icon-save" />
                                                    </Button>
                                                </div>
                                            </div>
                                        </Pane>
                                    </form>
                                </div>
                            }

                            {/* FORMULARIO PARA PESSOA FISICA */}
                            {vendedorProcesso.data[index_Vendedor].tipo_pessoa === 0 &&
                                <div className='Form Pessoa Física'>
                                    <h4 style={dadosVenda.status_processo === 22 ? { marginTop: '15px' } : { marginTop: '90px' }}>Dados do Vendedor</h4>
                                    <p className="descritption" style={{ margin: '15px 0px 0px' }}>Preencha os dados do vendedor responsável.</p>
                                    <form id="formGroup" className="formGroup-dialog" style={{ top: 0 }} encType="multipart/form-data" onChange={e => dadosVenda.status_processo === 22 ? handleVerificarRevisao(e) : (e)}>
                                        <input id={"vendedor_id-" + index_Vendedor} type="hidden" value={idsVendedores[index_Vendedor]['usuario_id']} />
                                        <input id={"vendedor_tipo_pessoa-" + index_Vendedor} type="hidden" value={checkedPessoaJuridica === false ? "0" : "1"} />
                                        <Pane>
                                            <div className="row-recibo" style={{ marginTop: '0px' }}>
                                                <div className="line-checkbox">
                                                    <Checkbox
                                                        label="O vendedor possui procurador."
                                                        checked={watch('vendedores.' + index_Vendedor + '.procurador.check', index_Vendedor)}
                                                        className="checkbox"
                                                        {...register('vendedores.' + index_Vendedor + '.procurador.check', {
                                                            onChange: (e) => {
                                                                handleCheckedProcurador(e, index_Vendedor)
                                                            }
                                                        })}
                                                    />
                                                </div>

                                                {procuradores[index_Vendedor].checkProcurador === true ?
                                                    <div className="rowForm">
                                                        <TextInputField
                                                            width={480}
                                                            label="Nome completo - Procurador"
                                                            description=""
                                                            placeholder="Ex: José Maria da Silva"
                                                            required
                                                            style={
                                                                watch('vendedores.' + index_Vendedor + '.procurador.nome')
                                                                    ? inputGreen
                                                                    : errors?.vendedores?.[index_Vendedor]?.procurador?.nome ? inputError : inputDefault
                                                            }
                                                            {...register('vendedores.' + index_Vendedor + '.procurador.nome', {
                                                                required: procuradores[index_Vendedor].checkProcurador === true,
                                                            })}
                                                            validationMessage={errors?.vendedores?.[index_Vendedor]?.procurador?.nome && errorTextInput}
                                                        />

                                                        <TextInputField
                                                            width={120}
                                                            label="Celular"
                                                            description=""
                                                            placeholder="Ex: (21) 9999-9999"
                                                            maxLength={15}
                                                            required
                                                            value={watch('vendedores.' + index_Vendedor + '.procurador.telefone')}
                                                            style={
                                                                watch('vendedores.' + index_Vendedor + '.procurador.telefone')?.length > 9
                                                                    ? inputGreen
                                                                    : errors?.vendedores?.[index_Vendedor]?.procurador?.telefone ? inputError : inputDefault
                                                            }
                                                            {...register('vendedores.' + index_Vendedor + '.procurador.telefone', {
                                                                required: procuradores[index_Vendedor].checkProcurador === true,
                                                                minLength: 10,
                                                                setValueAs: (e) => phoneMask(e)
                                                            })}
                                                            validationMessage={errors?.vendedores?.[index_Vendedor]?.procurador?.telefone && errorTextInput}
                                                        />
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>

                                            <div className="row-recibo">
                                                <div className="subtitle">
                                                    <CloudUploadIcon className="icon-subtitle" />
                                                    Upload de documentos do vendedor
                                                </div>
                                                <p className="descritption description-upload">Atenção: subir arquivos no formato .doc, .docx ou .pdf. <br /> Para aprender a converter uma foto para pdf., <a href="https://www.tecmundo.com.br/apps/72485-apps-converter-documentos-para-pdf-celulares-tablets.htm" target="_blank" rel="noreferrer" title="Converter foto para PDF">clique aqui</a>.</p>

                                                <MultiUploadDoc pessoa={'vendedores'} type="fisica" context={VendasContext} indexPessoa={index_Vendedor} refreshProcess={recarregarVenda} idDonoDocumento={vendedorProcesso.data[index_Vendedor].id} />

                                            </div>

                                            <div className="mensagem-campos msg-obrigatorio">*Campos obrigatórios</div>

                                            <div className="footer-form">
                                                <div>
                                                    {/* ESPAÇO PARA ALINHA A DIREITA OS BOTÕES */}
                                                </div>

                                                <div>
                                                    {
                                                        dadosVenda.status_processo === 22
                                                            ?
                                                            dadosVenda.revisao.valores_documentos_imovel === 1
                                                                ?
                                                                <Button appearance="default" marginLeft={15} onClick={(e) => handleBtnAnterior(e)}>
                                                                    <ChevronLeftIcon className="icon-label" style={{ margin: '0 5px 0 0' }} />
                                                                    Anterior
                                                                </Button>
                                                                :
                                                                ''

                                                            :
                                                            <Button appearance="default" marginLeft={15} onClick={(e) => handleBtnAnterior(e)}>
                                                                <ChevronLeftIcon className="icon-label" style={{ margin: '0 5px 0 0' }} />
                                                                Anterior
                                                            </Button>

                                                    }

                                                    <Button
                                                        appearance="primary"
                                                        marginLeft={15}
                                                        //onClick={handleSubmit((e) => handleBtnAvancar(e))}
                                                        onClick={handleSubmit(e => handleRedirect(e), onError)}
                                                    >
                                                        {dadosVenda.status_processo === 22
                                                            ? dadosVenda.revisao.compradores === 1
                                                                ? 'Próxima correção'
                                                                : dadosVenda.revisao.comissao === 1
                                                                    ? 'Próxima correção'
                                                                    : 'Salvar correções'

                                                            : 'Avançar'
                                                        }
                                                        <ChevronRightIcon className="icon-save" />
                                                    </Button>
                                                </div>
                                            </div>
                                        </Pane>
                                    </form>
                                </div>
                            }
                        </Pane>
                    ))}
                </div>
            }
        </Dialog>
    )
}