import React, { useState, useEffect } from 'react';
import {   
  Pane, toaster,
} from 'evergreen-ui';
import LogonContext from '../../../context/LogonContext';
import Serve from '../../../app/serve_login';
import Axios from 'axios';
import './styles.css';
import EsqueciSenha from './Dialogs/EnviarEmail'
import EmailEnviado from './Dialogs/EmailEnviado'

export default function RedefinirSenha() {
  const [loading, setLoading] = useState(false);
  const app = Serve();
  
  const [isShowEnviarEmail, setIsShowEnviarEmail] = useState(true);
  const [isShowEmailEnviado, setIsShowEmailEnviado] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const classTeste = 'redefinirSenha css-xeqldi ub-pst_fixed ub-top_0px ub-lft_0px ub-rgt_0px ub-btm_0px ub-z-idx_20 ub-dspl_flex ub-algn-itms_flex-start ub-just-cnt_center ub-box-szg_border-box'
      const element = document.getElementsByClassName('css-xeqldi')      
      if(element[0]) document.getElementsByClassName('css-xeqldi')[0].className = classTeste;
    }, 250)
  },[isShowEnviarEmail,isShowEmailEnviado])

  const conxtextStatus = {
    isShowEnviarEmail,
    setIsShowEnviarEmail,
    isShowEmailEnviado,
    setIsShowEmailEnviado,
    loading,
    setLoading,
    errorEmail,
    setErrorEmail,
  };

  function handleEnviarEmail() {
    const email = localStorage.getItem('email');
    console.log(email);
    setLoading(true)    

    Axios.post(app + 'enviaremailsenha', {
      email,
    }).then(res => {
      console.log(res);
      if (res.data) {
        if(res.data.mensagem === "Email Enviado Com Sucesso !"){
          setLoading(false);
          setIsShowEnviarEmail(false);
          setIsShowEmailEnviado(true);       
        } else {
          toaster.danger(res.data.mensagem)
          setErrorEmail(true); 
        }
      }
    }).catch((error) => {
      console.log(error);
      setErrorEmail(true);
    }).then(() => {
      setLoading(false);
    })
  };

  return (
    <Pane >
      <LogonContext.Provider value={conxtextStatus}>
        <EsqueciSenha handleEnviarEmail={handleEnviarEmail} />
        <EmailEnviado />
      </LogonContext.Provider>
    </Pane>
  );
}