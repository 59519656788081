import React, {useContext, useState} from 'react';
import { Pane, Table } from 'evergreen-ui';
import VendasContext from '../../../../../../context/VendasContext';
import ReturnDocumentsForView from '../../../../../../components/MultiViewDocs';


//import { useHistory } from 'react-router-dom';

export default function Imovel(props) {        
    const [token] = useState(localStorage.getItem('token'));

    const msgCampo = "----";

    const {
        dadosReciboEnviado,
        tipoLaudemio,
    } = useContext(VendasContext);        
    console.log(dadosReciboEnviado);

    //lê os laudemios retornados em dadosReciboEnviado
    const laudemiosEnviados = dadosReciboEnviado.laudemios.map(laudemio => parseInt(laudemio.valor_laudemio));
    //Comparar dadosReciboEnviado a uma lista de todos os laudemios "tipoLaudemio" e retorna o id e nomes para exibir
    const laudemioValorToName = tipoLaudemio.filter((laudemio) => laudemiosEnviados.includes(laudemio.id));
    const laudemiosSemNome = dadosReciboEnviado.laudemios.filter((laudemio) => laudemio.tipo_laudemio === "2" || laudemio.tipo_laudemio === "1");
    laudemioValorToName.push(...laudemiosSemNome);

    return (
        <div className="detadelhes-sidesheet">
            {
                dadosReciboEnviado.length === 0
                ?
                <Table.Row>
                    <Table.TextCell className="historicoStatus" width={150}>
                        <Table.TextCell className="fontStatus">
                            <span>{msgCampo}</span>
                        </Table.TextCell>
                    </Table.TextCell>
                </Table.Row>

                :
                <>
                    <Pane>
                        <React.Fragment>            
                            <h2 className="subtitle subtitle-table">Valores</h2>
                        </React.Fragment>
                    </Pane>
                    <Table.Row>
                        <Table.TextCell className="historicoStatus" width={130}>
                            <Table.TextCell className="fontStatus">
                                <span>Valor de venda</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.informacao.valor_venda ? dadosReciboEnviado.informacao.valor_venda : msgCampo}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                        <Table.TextCell className="historicoStatus">
                            <Table.TextCell className="fontStatus">
                            <span>Valor anunciado</span>
                            </Table.TextCell>
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.informacao.valor_anunciado !== null ? dadosReciboEnviado.informacao.valor_anunciado : msgCampo }
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                    </Table.Row>

                    <Pane>
                        <React.Fragment>            
                            <h2 className="subtitle subtitle-table">Documentos do imóvel</h2>
                        </React.Fragment>
                    </Pane>
                    <ReturnDocumentsForView docs={dadosReciboEnviado.documentos.data} />                    

                    <Pane>
                        <React.Fragment>            
                            <h2 className="subtitle subtitle-table">Observações</h2>
                        </React.Fragment>
                    </Pane>
                    <Table.Row>
                        <Table.TextCell className="historicoStatus">
                            <Table.TextCell className="mensagemStyle">
                                <span className="mensagem" style={{paddingLeft: 0}}>
                                    {dadosReciboEnviado.informacao.observacao ? dadosReciboEnviado.informacao.observacao : ''}
                                </span>
                            </Table.TextCell>
                        </Table.TextCell>
                    </Table.Row>
                </>
            }
        </div>
    )
}