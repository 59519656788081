import React, {useContext} from 'react'
import {Pane, HomeIcon, HistoryIcon, HelpIcon, Button, TickIcon, PersonIcon, Alert} from 'evergreen-ui';
//import '../../../styles.css';
import AcessoTemporarioContext from '../../../../../context/AcessoTemporarioContext';
// import Whatsapp from '../../../../../images/whatsapp.svg';
// import updateUsuarios from '../../../../../apis/updateUsuarios';

export default function NavMobile({processoId, register, handleSubmit, setValue, watch, errors, clearErrors, handleEdit}) {

    const {
        navMenu, setNavMenu,
        isShowFeedbackDados, setIsShowFeedbackdados,
    } = useContext(AcessoTemporarioContext);

    const handleNavMobile = (e, item) => {
        scrollToTop();
        console.log(item);
        
        // if(item === 'dados-pessoais'){
        //     returnDadosUsuario()
        // }
        
        setIsShowFeedbackdados(false)
        setNavMenu(item);
    }

    // const {
    //     responsavelProcesso
    // } = useContext(AcessoTemporarioContext);

    //const ddiResponsavel = responsavelProcesso[0].ddi;
    // const telefoneResponsavel = responsavelProcesso[0].telefone;
    // const nomeResponsavel = responsavelProcesso[0].name;
    // const linkWhatsapp = 'https://api.whatsapp.com/send?phone=55' + telefoneResponsavel + '&text=Olá ' + nomeResponsavel + ', tudo bem? Gostaria de tirar uma dúvida sobre minha negociação.';

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // rolagem suave
        });
     };

    return (
        <Pane>
            <nav className="nav-mobile">
                {
                    navMenu === "dados-pessoais" && 
                    <div className="nav-faq">
                        <div className="content">
                           {
                                isShowFeedbackDados && 
                                    <Alert
                                    intent="success"
                                    title="Seus dados foram atualizados :)"
                                    marginBottom={32}
                                    style={{maxWidth: '720px', width: '100%', marginBottom: '10px'}}
                                />
                            }

                            {/* <a href={linkWhatsapp} style={{width: '100%'}} target="_blank" rel="noreferrer"> */}
                                <Button 
                                    className="btn-contato btn-dna-green"
                                    onClick={handleSubmit((e) => handleEdit(e)/*, onError*/)}
                                >
                                    <TickIcon color={'#fff'} marginRight={10} />  Salvar edição
                                </Button>
                            {/* </a> */}
                        </div>
                    </div>
                }

                <ul>
                    
                    
                    <li 
                        className={navMenu === 'principal' ? "nav-enable" : "nav-disable"} 
                        onClick={(e) => handleNavMobile(e, 'principal')}
                    >
                        <HomeIcon className={navMenu === "principal" ? "nav-item-enable icon-nav-mobile" : "nav-item-disable icon-nav-mobile"} />
                        <span className={navMenu === "principal" ? "nav-item-enable" : "nav-item-disable"}>Principal</span>
                    </li>
                    
                    <li 
                        className={navMenu === 'historico' ? "nav-enable" : "nav-disable"} 
                        onClick={(e) => handleNavMobile(e, 'historico')}
                    >
                        <HistoryIcon className={navMenu === "historico" ? "nav-item-enable icon-nav-mobile" : "nav-item-disable icon-nav-mobile"} />
                        <span className={navMenu === "historico" ? "nav-item-enable" : "nav-item-disable"}>Historico</span>
                    </li> 

                    <li 
                        className={navMenu === 'dados-pessoais' ? "nav-enable" : "nav-disable"} 
                        onClick={(e) => handleNavMobile(e, 'dados-pessoais')}
                    >
                        <PersonIcon className={navMenu === "dados-pessoais" ? "nav-item-enable icon-nav-mobile" : "nav-item-disable icon-nav-mobile"} />
                        <span className={navMenu === "dados-pessoais" ? "nav-item-enable" : "nav-item-disable"}>Meu perfil</span>
                    </li> 
                   

                    {/*
                    // Quando não tiver documentos 
                    <li 
                        className={navMenu === 'historico' ? "nav-enable" : "nav-disable"} 
                        onClick={(e) => handleNavMobile(e, 'historico')}
                    >
                        <HomeIcon className={navMenu === "historico" ? "nav-item-enable icon-nav-mobile" : "nav-item-disable icon-nav-mobile"} />
                        <span className={navMenu === "historico" ? "nav-item-enable" : "nav-item-disable"}>Principal</span>
                    </li> */}

                    <li 
                        className={navMenu === 'ajuda' ? "nav-enable" : "nav-disable"} 
                        onClick={(e) => handleNavMobile(e, 'ajuda')}
                    > 
                        <HelpIcon className={navMenu === "ajuda" ? "nav-item-enable icon-nav-mobile" : "nav-item-disable icon-nav-mobile"} />
                        <span className={navMenu === "ajuda" ? "nav-item-enable" : "nav-item-disable"}>Ajuda</span>
                    </li>
                </ul>
            </nav>
        </Pane>
    )
}
