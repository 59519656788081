import Axios from 'axios';
import Serve from '../app/serve';

async function getUsers() {
  const token = localStorage.getItem('token');
  const app = Serve();
  let users = [];
  await Axios.post(app + 'listar_usuarios', {}, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }).then(res => {
    if (res.data?.status && (res.data.status === 498 || res.data.status === 401)) {
      
    } else {
      console.log('listar_usuarios', res);
      const usuarios = [];
      res.data.usuarios.filter((e) => e.name).forEach((user) => {        
        const userData = {
          "id": user.id,
          "name": user.name,
          "avatar": "",
          "email": user.email,
          "cargoId": user.perfil_login_id,          
          "cargo": user.perfil_login_nome,
          "lojaId": user.loja_id,          
          "loja": user.loja_nome,
          "tel": user.telefone,
          "ddi": user.ddi,
          "cpf": user.cpf_cnpj,
          "criado": user.created_at,
          "activated": user.usuario_situacao === 1,
        }
        usuarios.push(userData)
      })
      users = usuarios;
    }
  })
  return users;
};

export default getUsers;