import React, {useState } from 'react';
import { useHistory, useLocation  } from 'react-router-dom';
import Lottie from 'react-lottie';
import { useForm } from 'react-hook-form';
import { 
  Button, 
  Pane, 
  toaster, 
  TextInputField, 
  Spinner,
  ArrowRightIcon,
  //CornerDialog,
} from 'evergreen-ui';

import cpfMask from '../../components/cpfMask';
import Serve from '../../app/serve_login';
import AnimationLoading from '../../images/Animations/loading_dna.json';
import ImageWelcome from '../../images/undraw_login_re_4vu2.svg';
import LogoDNA from '../../images/logo_dna.svg';
import Axios from 'axios';

import './styles.css';

export default function AcessoTemporario() {

    const [countErro, setCountErro] = useState(0);
    const [loading, setLoading] = useState(false);  
    const history = useHistory();
    const app = Serve();
    //const navegador = navigator.userAgent;  
    //const navegadorEdg = navegador.indexOf("Edg") > -1;

    function useQuery(){
        const { search } = useLocation();
        const parametro = React.useMemo(() => new URLSearchParams(search), [search]);

        const codProcesso = parametro.get('cod');
        return codProcesso;
    }

    const [parametroUrl] = useState(useQuery());

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: AnimationLoading,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    
    const { register, handleSubmit, clearErrors, reset, formState: { errors } } = useForm({
        defaultValues: {
          codProcesso: parametroUrl,
          cpf: '',
        }
    });

    const handleCod = (e) => {
        let codInput = document.querySelector('.input-cod');

        if(e.target.value.length > 4){
            codInput.style.cssText = 'border-color: #429777; background: #F5FBF8';
            clearErrors('codProcesso');
        }
        else{
            codInput.style.cssText = 'border-color: #ADC2FF; background: #fff';
        }
    };  

    const handleCpf = (e) => {
        let value = e.target.value;    
        let cpfInput = document.querySelector('.input-cpf');

        cpfInput.value = cpfMask(cpfInput.value);

        if(value.length === 14){
            cpfInput.style.cssText = 'border-color: #429777; background: #F5FBF8';
            clearErrors('cpf');
        }
        else{
            cpfInput.style.cssText = 'border-color: #ADC2FF; background: #fff';
        }
    };  

    const inputError = {
        borderColor: '#FF0000',
        background: '#fff'
    };

    async function handleLogin(e) {
        setLoading(true);
        const codProcesso = e.codProcesso;
        const cpf = e.cpf
        
        try {
          const response = await Axios.post(app + 'acesso_vendedor_comprador', { codProcesso, cpf });
          const msgError = response.data?.mensagem;
          const status = response.data?.status;      
    
          if (status === 'false') {
            toaster.danger(msgError);
            if(msgError === 'Usuário e/ou senha inválido(s)') setCountErro(countErro + 1)  
            setLoading(false);
          }
          else {
            localStorage.setItem('processo_id', response.data.processo.id);
            localStorage.setItem('codigo_acesso', response.data.processo.codigo_acesso);
            localStorage.setItem('cpf', cpf);

            history.push('/acompanhar-status-imovel');
          }
        } catch (error) {      
          setLoading(false);              
        }
    };
    
    return (
        <Pane>
            <div className="acesso-container">
                <section class="form form-acesso">
                <form onSubmit={handleSubmit((e) => handleLogin(e))}>
                    <div className='center' style={{marginTop: '0'}}>
                        <img src={LogoDNA} alt="logo_dna!" title="DNA Imóveis" style={{marginTop: '0', width: '160px', height: '120px'}} />
                    </div>

                    <div className='center'>
                        <img src={ImageWelcome} alt="boas_vindas" title="Boas Vindas!"  style={{marginTop: '0'}} />
                    </div>
                    <h1>Boas-vindas! :)</h1>

                    <h3>Realize o login para acompanhar sua compra ou venda de imóvel.</h3>

                    <TextInputField
                        label="Qual é o código do processo?*"
                        disabled={parametroUrl ? true : false}
                        className="input-cod" 
                        description=""
                        placeholder="12345678"
                        defaultValue={parametroUrl}
                        style={errors.codProcesso ? inputError : {}}    
                        {...register("codProcesso", {
                            required: 'Campo obrigatório',
                            //onChange: (e) => handlePass(e)
                            validate: (value) => {
                                if (value.length === 0) {
                                    return "Código de processo inválido";
                                }
                            },
                        })}
                        onChange={(e) => handleCod(e)}
                        validationMessage={errors.codProcesso && errors.codProcesso.message}
                    />

                    <TextInputField
                        label="Qual é o seu CPF?*"
                        disabled={loading}
                        placeholder="111.111.111-11"     
                        className="input-cpf"         
                        maxLength={14}
                        style={errors.cpf ? inputError : {}}    
                        {...register("cpf", { 
                            required: 'Campo obrigatório',
                            min: 14,
                            //onChange: (e) => handlePass(e),
                            validate: (value) => {
                                if (value.length < 14) {
                                    return "Formato de CPF inválido";
                                }
                            },
                        })}
                        onChange={(e) => handleCpf(e)}
                        validationMessage={errors.cpf && errors.cpf.message}
                        inputmode="numeric"
                    />

                    {loading
                    ?
                    <Lottie
                        options={defaultOptions}
                        height={40}                
                        width={100}                
                    />
                    :
                    ''
                    }
                    <Button width="100%" marginTop={loading ? 15 : 40} intent="none" disabled={loading} className="btn-acess-temp btn-dna-green">
                        {loading && <Spinner size={24} style={{ marginRight: 20 }} />}
                        Entrar &emsp;<ArrowRightIcon color='white' size={12} />
                    </Button>
                </form>
                </section>
            </div>
        </Pane>
    )
}