import Axios from 'axios';
import Serve from '../app/serve';

async function getServicosById(solicitacao_id) {
  const token = localStorage.getItem('token');
  const app = Serve();
  let servicos;

  await Axios.post(app + 'localizar_solicitacao', {
    solicitacao_id,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }).then(res => {
    console.log(res);
    if (res?.data.status && (res.data.status === 498 || res.data.status === 401)) {
      localStorage.clear();
    } else {
      servicos = res.data.solicitacao;          
    }
  })
  return servicos;
};
export default getServicosById;