import React, { useContext, useState }  from 'react';
import { Pane, Table, Badge, Button, DownloadIcon } from 'evergreen-ui';
//import PosNegociacaoContext from '../../../../context/PosNegociacaoContext';
import DirecaoContext from '../../../../context/DirecaoContext';
import ShowDocument from '../../../../app/showDocument';

export default function Historico() {

    const { dadosVenda } = useContext(DirecaoContext);
    const [token] = useState(localStorage.getItem('token'));
    
    const historico = dadosVenda.historico_acoes;
    console.log(dadosVenda);
    
    const vendedores = [];
    const representantes = [];
    dadosVenda.vendedores.data.forEach((vendedor) =>
        vendedor.documentos.data.map((doc) => vendedores.push({ 'id': doc.id, 'name': vendedor.name ? vendedor.name : vendedor.razao_social, 'tipo': 'Vendedor' })) &&
        vendedor.representante_socios.data.forEach((representante) =>
            representante.documentos.data.forEach(doc => representantes.push({ 'id': doc.id, 'name': representante.name, 'tipo': 'Vendedor', 'empresa': vendedor.razao_social, 'representante': representante.pj_representante === 1, 'socio': representante.pj_socio === 1 })))
    );
    const compradores = [];
    dadosVenda.compradores.data.forEach((comprador) =>
        comprador.documentos.data.map((doc) => compradores.push({ 'id': doc.id, 'name': comprador.name ? comprador.name : comprador.razao_social, 'tipo': 'Comprador' })) &&
        comprador.representante_socios.data.forEach((representante) =>
            representante.documentos.data.forEach(doc => representantes.push({ 'id': doc.id, 'name': representante.name, 'tipo': 'Comprador', 'empresa': comprador.razao_social, 'representante': representante.pj_representante === 1, 'socio': representante.pj_socio === 1 })))
    );

    const listaDocsNomes = [];
    listaDocsNomes.push(...vendedores)
    listaDocsNomes.push(...compradores)
    listaDocsNomes.push(...representantes)

    const capitalize = str => {
        if (typeof str !== 'string') {
            return '';
        }
        return str.charAt(0).toUpperCase() + str.substr(1);
    }

    return (
        <Pane>            
            {historico.map(historico => 
            <Table.Row className="row-historico" style={{height: 'auto'}}>
                <Table.TextCell className="historicoStatus">
                    <div className="fontStatus">
                        <Badge
                            color={historico.identifica_documento === "pessoa_fisica" || historico.identifica_documento === 'pessoa_juridica' ? "green" : "yellow"}
                            marginBottom={14}
                            marginRight={17}
                        >
                            {historico.identifica_documento ? historico.identifica_documento.replace("_", " ") : "STATUS"}
                        </Badge>
                        {listaDocsNomes.filter(doc => doc.id === historico.documento_id)[0]?.representante &&
                            <Badge color="neutral" marginBottom={14} marginRight={17}>
                                REPRESENTANTE LEGAL
                        </Badge>
                        }
                        {listaDocsNomes.filter(doc => doc.id === historico.documento_id)[0]?.socio &&
                            <Badge color="neutral" marginBottom={14}>
                                SÓCIO
                        </Badge>
                        }

                    </div>
                    <div className="fontStatus">
                        {historico.status !== null
                            ?
                            <>
                                <span className="boldStatus"> {historico.data_historico} </span>  - {historico.mensagem === 'Gerente realizou correções solicitadas.' ? 'Venda corrigida' : historico.status}
                            </>

                            :
                            <>
                                {historico.identifica_documento === 'pessoa_fisica' || historico.identifica_documento === 'pessoa_juridica'
                                    ?
                                    <>
                                        <span className="boldStatus"> {historico.data_historico} - </span>
                                        {listaDocsNomes.filter(doc => doc.id === historico.documento_id).map(doc =>
                                            doc.empresa
                                                ? doc.tipo + ' > ' + doc.empresa + ' > ' + doc.name
                                                : doc.tipo + ' > ' + doc.name
                                        ) + ' > ' + historico.tipos_multiplos_documentos.map((e, index) => " " + e.nome_tipo )}
                                    </>
                                    :
                                    <>
                                        <span className="boldStatus">{historico.data_historico}</span> - {capitalize(historico.identifica_documento) + ' > '} {historico.nome}
                                    </>
                                }
                            </>
                        }
                    </div>
                    <Table.TextCell paddingLeft={0}>
                        {historico.mensagem === null ? '' : <span className="mensagem">{historico.mensagem}</span>}
                        {historico.link === null
                            ? ''
                            :
                            <Button
                                marginTop={14}
                                onClick={(e) => ShowDocument(historico.documento_id, "documento", token)}
                            >
                                <DownloadIcon className="iconLink" width={17} marginRight={5} /><span> Baixar documento </span>
                            </Button>
                        }
                    </Table.TextCell>
                </Table.TextCell>
                </Table.Row>
            )}
        </Pane>
    )
}