import React, { useContext, useState, useEffect } from 'react'
import { Button, Tooltip, Dialog, Pane, Checkbox, ShareIcon, Tab, Tablist, Radio, ErrorIcon } from 'evergreen-ui';
import VendasContext from '../../../../../../../context/VendasContext';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import Serve from '../../../../../../../app/serve';

//Componentes Tabs
import Performance from './components/Tabs/Performance';
import Venda from './components/Tabs/Venda';
import Vendedores from './components/Tabs/Vendedores';
import Compradores from './components/Tabs/Compradores';
import ReciboSinal from './components/Tabs/ReciboSinal';
import Comissao from './components/Tabs/Comissao';

export default function DialogRankGerente({ recarregarVendas }) {
    const {
        setLoading,
        dadosVenda,
        isShowEntregarVenda, setIsShowEntregarVenda,
        setIsShowCornerDialog,
        setModelCornerDialog,
        setRegressivaEntregarVenda,
        holidays,
        divergenciaEntrega,
    } = useContext(VendasContext);

    const [optionsPosVenda] = useState([
        { label: 'Pós-negociação DNA Imóveis', value: '0' },
        { label: 'Pós-negociação pelo franqueado', value: 1 },
    ]);
    const [selectOptionPosVenda, setSelectOptionPosVenda] = useState(0);
    useEffect(() => {
        setSelectOptionPosVenda(0);
    }, [dadosVenda]);

    const history = useHistory();
    const usuario_id = localStorage.getItem('usuario_id');
    const processo_id = localStorage.getItem('processo_id');
    const token = localStorage.getItem('token');
    const app = Serve();

    const [selectedIndex, setSelectedIndex] = useState(0)
    const [tabs] = useState(['Performance', 'Venda', 'Vendedor', 'Comprador', 'Recibo de Sinal', 'Comissão']);
    const [tabSelect, setTabSelect] = useState('Performance');

    const [checkConfirm, setCheckConfirm] = useState(false);

    const today = new Date();
    const isInutilDay = (
        today.getDay() === 0 ||
        (today.getDay() === 5 && today.getHours() >= 18) ||
        today.getDay() === 6 ||
        holidays.some((holiday) =>
            holiday.date.getDate() === today.getDate() &&
            holiday.date.getMonth() === today.getMonth()
        )
    );
    // const isInutilDay = true
    const [checkDiaUtil, setCheckDiaUtil] = useState(false);

    useEffect(() => {
        setSelectedIndex(0);
    }, [])

    const confirmarEntregaVenda = (e, venda) => {
        e.preventDefault();
        let imovel_id = venda.id;
        let processo_id = venda.processo_id;

        setLoading(false);

        Axios.post(app + 'vincular_responsavel_processo', {
            'imovel_id': imovel_id,
            'processo_id': processo_id,
            'usuario_id': usuario_id,
            'divergencia_entrega': divergenciaEntrega,
            'posvenda_franquia': selectOptionPosVenda
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    recarregarVendas();
                    setIsShowEntregarVenda(false);
                    setIsShowCornerDialog(true);
                    setModelCornerDialog(4);

                    // Abre o contado para o Corner de Confirmação de Envio para o Backoffice
                    var timeleft = 5;
                    var downloadTimer = setInterval(function () {
                        if (timeleft <= 0) {
                            clearInterval(downloadTimer);
                            setRegressivaEntregarVenda(0)
                        } else {
                            setRegressivaEntregarVenda(timeleft)
                        }
                        timeleft -= 1;
                    }, 1000);

                }
            })
            .catch(function (error) {
                console.log(error);

            })
    };

    const tabSelected = (e, index) => {
        setSelectedIndex(index);
    };

    return (
        <Dialog
            width={850}
            isShown={isShowEntregarVenda}
            title=""
            onCloseComplete={() => [setIsShowEntregarVenda(false), setSelectedIndex(0), setTabSelect(Performance), setCheckConfirm(false)]}
            confirmLabel=""
            containerProps={{
                className: 'form-dialog-props'
            }}
            hasFooter={false}
            preventBodyScrolling={true}
            shouldCloseOnOverlayClick={false}
        >
            <Pane>
                <Tablist marginBottom={16} flexBasis={240} marginRight={24} className="tab-rank">
                    {tabs.map((tab, index) => (
                        <Tab
                            appearance={'primary'}
                            aria-controls={`panel-${tab}`}
                            isSelected={index === selectedIndex}
                            key={tab}
                            onSelect={(e) => tabSelected(e, index)}
                            paddingBottom={25}
                        >
                            {tab}
                        </Tab>
                    ))}
                </Tablist>

                <Pane>
                    <div className="rank">
                        {selectedIndex === 0
                            ? <Performance />
                            : selectedIndex === 1
                                ? <Venda />
                                : selectedIndex === 2
                                    ? <Vendedores />
                                    : selectedIndex === 3
                                        ? <Compradores />
                                        : selectedIndex === 4
                                            ? <ReciboSinal />
                                            : <Comissao />
                        }
                    </div>

                    <div className="mb25" style={{ borderTop: '1px solid #E6E8F0', paddingTop: '20px' }}>
                        {
                            dadosVenda.quantidade_foguete?.qts_foguete >= 4 &&
                            <div className="form-dialog mb10">
                                {isInutilDay &&
                                    <div>
                                        <span className='fw600'>
                                            Atenção: A Equipe de Pós-Venda dará início às tratativas do processo somente no próximo dia útil.
                                        </span>
                                        <Checkbox
                                            label="Estou de acordo e desejo prosseguir com o envio."
                                            className='checkbox'
                                            checked={checkDiaUtil}
                                            onChange={e => setCheckDiaUtil(e.target.checked)}
                                        />
                                    </div>
                                }
                            </div>
                        }


                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                                {dadosVenda?.empresa_receber?.verificar_franquia !== 0 &&
                                    <div className='line-radius'>
                                        <Pane className='radio radius flex' aria-label="Radio Group Label 16" role="group">
                                            {optionsPosVenda.map((option, i) => (
                                                <Radio
                                                    className='mr20 radius'
                                                    key={i}
                                                    disabled={dadosVenda.quantidade_foguete?.qts_foguete < 4}
                                                    size={12}
                                                    onClick={() => setSelectOptionPosVenda(option.value)}
                                                    checked={selectOptionPosVenda === option.value}
                                                    name="group"
                                                    label={option.label}
                                                />
                                            ))}
                                        </Pane>
                                    </div>
                                }
                            </div>

                            <div>

                                <Button appearance="default" style={{ marginRight: '10px' }} onClick={() => setIsShowEntregarVenda(false)}>
                                    Cancelar
                                </Button>
                                <Tooltip content="Clique no check se estiver de acordo." isShown={(isInutilDay && !checkDiaUtil) ? "" : false}>
                                    <Button
                                        marginY={0}
                                        marginRight={12}
                                        onClick={e => confirmarEntregaVenda(e, dadosVenda)}
                                        intent="success"
                                        appearance='primary'
                                        className={(isInutilDay && !checkDiaUtil) ? 'btnGreenDisable' : ""}
                                        iconBefore={<ShareIcon color="white" />}
                                        disabled={
                                            (dadosVenda?.empresa_receber?.verificar_franquia === 1 && !selectOptionPosVenda)
                                                ? true
                                                : dadosVenda?.quantidade_foguete?.qts_foguete >= 4
                                                    ?
                                                    dadosVenda?.quantidade_foguete?.compradores === 1 || dadosVenda?.quantidade_foguete?.vendedores === 1
                                                        ? true
                                                        : false
                                                    : dadosVenda?.quantidade_foguete?.qts_foguete < 4 && true
                                        }
                                    >
                                        Entregar venda
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </Pane>
            </Pane>
        </Dialog>
    )
}