import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Pane, Badge, SmallTickIcon, Tab } from 'evergreen-ui';

//Context
import VendasContext from '../../../../../../context/VendasContext';

export default function Steps() {

    const {
        dadosVenda,
        countMenu,
        posicaoMinuta, setPosicaoMinuta,
    } = useContext(VendasContext);
    console.log("posicaoMinuta = " + posicaoMinuta);
    console.log("countMenu = " + countMenu);

    const [stepImovel, setStepImovel] = useState('default')
    const [stepVendedor, setStepVendedor] = useState('default');
    const [stepComprador, setStepComprador] = useState('default');
    const [stepRevisao, setStepRevisao] = useState('default');

    useEffect(() => {
        const posicaoStep = async () => {
            if (posicaoMinuta === 1) {
                //setStepFormaPagamento('active');
                setStepImovel('active');
                setStepVendedor('default');
                setStepComprador('default');
                setStepRevisao('default');
            }
            else if (posicaoMinuta === 2) {
                //setStepFormaPagamento('default');
                setStepImovel('default');
                setStepVendedor('active');
                setStepComprador('default');
                setStepRevisao('default');
            }
            else if (posicaoMinuta === 3) {
                //setStepFormaPagamento('default');
                setStepImovel('default');
                setStepVendedor('default');
                setStepComprador('active');
                setStepRevisao('default');
            }
            else if (posicaoMinuta === 4) {
                //setStepFormaPagamento('default');
                setStepImovel('default');
                setStepVendedor('default');
                setStepComprador('default');
                setStepRevisao('default');
            }
            else {
                //setStepFormaPagamento('default');
                /*setStepImovel('default');
                setStepVendedor('default');
                setStepComprador('default');
                setStepComissao('active');*/
                console.log('Erro');
            }
        }
        posicaoStep();
    }, [posicaoMinuta]);

    return (
        <Pane>
            <div className="steps">
                {/*<div className="step-item">
                    <Badge
                        className={posicaoMinuta > 1 ? 'badge-check' : 'badge-active'}
                    >
                        {posicaoMinuta > 1 ? <SmallTickIcon className="step-check" /> : 1}
                    </Badge>
                    <span className={stepFormaPagamento}>Forma de pagamento</span>
                </div>*/}
                <Tab
                    isSelected={posicaoMinuta === 1}
                    onSelect={() => setPosicaoMinuta(1)}
                >
                    <div className="step-item">
                        <Badge
                            className={posicaoMinuta > 1 ? 'badge-check' : 'badge-default'}
                        >
                            {countMenu > 1 ? <SmallTickIcon className="step-check" /> : <> &nbsp;1&nbsp; </>}
                            {/* {posicaoBoletimDeVenda > 1 ? <SmallTickIcon className="step-check" /> : <> &nbsp;1&nbsp; </>} */}
                        </Badge>
                        <span className={stepImovel}>Dados da Venda</span>
                    </div>
                </Tab>

                <Tab
                    isSelected={posicaoMinuta === 2}
                    onSelect={() => countMenu >= 2 ? setPosicaoMinuta(2) : ""}
                    style={{ cursor: countMenu < 2 || posicaoMinuta === 2 ? 'auto' : 'pointer' }}
                >
                    <div className="step-item">
                        <Badge
                            className={posicaoMinuta === 2 ? "badge-active" : countMenu > 2 ? 'badge-check' : 'badge-default'}
                        >
                            {countMenu > 2 ? <SmallTickIcon className="step-check" /> : <> &nbsp;2&nbsp; </>}
                        </Badge>
                        <span className={stepVendedor}>Dados do Vendedor</span>
                    </div>
                </Tab>

                {/*
                    <Tab
                        isSelected={posicaoMinuta === 3}
                        onSelect={() => countMenu >= 3 ? setPosicaoMinuta(3) : ""}
                        style={{ cursor: countMenu < 3 || posicaoMinuta === 3 ? 'auto' : 'pointer' }}
                    >
                        <div className="step-item">
                            <Badge
                                className={posicaoMinuta === 3 ? "badge-active" : countMenu > 3 ? 'badge-check' : 'badge-default'}
                            >
                                {countMenu > 3 ? <SmallTickIcon className="step-check" /> : <> &nbsp;3&nbsp; </>}
                            </Badge>
                            <span className={stepComprador}>Dados do Comprador</span>
                        </div>
                    </Tab>
                */}

                {
                    // Pulou comprador status 9 e anterior 10
                    dadosVenda.status_processo === 10 && dadosVenda.status_anterior_processo_id === 9
                    || (dadosVenda.status_processo === 24 && dadosVenda.status_anterior_processo_id === 10)
                    || (dadosVenda.status_processo === 24 && dadosVenda.status_anterior_processo_id === 23)
                    ?
                    <Tab
                        isSelected={posicaoMinuta === 3}
                        onSelect={
                            (e) => 
                            dadosVenda.status_processo === 10 && dadosVenda.status_anterior_processo_id === 9
                            || (dadosVenda.status_processo === 24 && dadosVenda.status_anterior_processo_id === 10)
                            || (dadosVenda.status_processo === 24 && dadosVenda.status_anterior_processo_id === 23)
                            ? setPosicaoMinuta(3) 
                            : ""
                        }
                        style={
                            { cursor: 
                                dadosVenda.status_processo === 10 && dadosVenda.status_anterior_processo_id === 9
                                || (dadosVenda.status_processo === 24 && dadosVenda.status_anterior_processo_id === 10)
                                || (dadosVenda.status_processo === 24 && dadosVenda.status_anterior_processo_id === 23)
                            ? 'pointer' 
                            : 'auto' }
                        }                    >
                        <div className="step-item">
                            <Badge
                                className={
                                    posicaoMinuta === 3 
                                    ? "badge-active" 
                                    : "badge-default"
                                }
                            >
                                {<> &nbsp;3&nbsp; </>}
                            </Badge>
                            <span className={stepComprador}>Dados do Comprador</span>
                        </div>
                    </Tab>
                    
                    :
                    <Tab
                        isSelected={posicaoMinuta === 3}
                        onSelect={() => countMenu >= 3 ? setPosicaoMinuta(3) : ""}
                        style={{ cursor: countMenu < 3 || posicaoMinuta === 3 ? 'auto' : 'pointer' }}
                    >
                        <div className="step-item">
                            <Badge
                                className={posicaoMinuta === 3 ? "badge-active" : countMenu > 3 ? 'badge-check' : 'badge-default'}
                            >
                                {countMenu > 3 ? <SmallTickIcon className="step-check" /> : <> &nbsp;3&nbsp; </>}
                            </Badge>
                            <span className={stepComprador}>Dados do Comprador</span>
                        </div>
                    </Tab>
                }
                

                <Tab
                    isSelected={posicaoMinuta === 4}
                    onSelect={() => countMenu === 4 ? setPosicaoMinuta(4) : ""}
                    style={{ cursor: countMenu < 4 || posicaoMinuta === 4 ? 'auto' : 'pointer' }}
                >
                    <div className="step-item">
                        <Badge
                            className={
                                /*countMenu === 4 ? "badge-active" : 'badge-default'*/
                                posicaoMinuta === 4 ? "badge-active" : 'badge-default'
                            }
                        >
                            {/*countMenu === 4 ? <SmallTickIcon className="step-check" /> : <> &nbsp;4&nbsp; </>*/}
                            {
                                dadosVenda.status_processo >= 15
                                || (dadosVenda.status_processo === 11)
                                || (dadosVenda.status_processo === 24)
                                ? <SmallTickIcon className="step-check" /> : <> &nbsp;4&nbsp; </>
                            }
                        </Badge>
                        <span className={stepRevisao}>Revisão</span>
                    </div>
                </Tab>
            </div>
        </Pane>
    )
}