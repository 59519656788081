import React, { useContext, useState } from 'react';
import { Pane, Tablist, Tab } from 'evergreen-ui';
import ApoioContext from '../../../../context/ApoioContext';
import FormPessoa from '../../../../components/FormPessoa';

export default function Vendedores() {

  const {
    selectProcess,
  } = useContext(ApoioContext);

  const [vendedoresProcesso] = useState(selectProcess.imovel.vendedores.data);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tabs] = useState(vendedoresProcesso?.map((vendedor) => vendedor.name || vendedor.nome_fantasia));

  return (
    <Pane padding={10}>
      <Pane>
        <Tablist marginBottom={16} flexBasis={650} marginRight={24}>
          {tabs.map((tab, index) => (
            <Tab
              key={tab}
              onSelect={() => setSelectedIndex(index)}
              isSelected={index === selectedIndex}
              aria-controls={`panel-${tab}`}
              data-tab={tab}
              className='tabList'
            >
              {tab.slice(0, 10) + '...'}
            </Tab>
          ))}
        </Tablist>

        {tabs.map((tab, index) => (
          <Pane
            key={tab}
            id={`panel-${tab}`}
            role="tabpanel"
            aria-labelledby={tab}
            aria-hidden={index !== selectedIndex}
            display={index === selectedIndex ? 'block' : 'none'}
          >
            <FormPessoa pessoa="vendedores" dataPessoa={vendedoresProcesso[index]} context={ApoioContext} indexPessoa={index} uploadDisable />

          </Pane>
        ))}
      </Pane>
    </Pane>
  )
}