import React, { useContext, useState } from 'react';
import 'react-phone-input-2/lib/bootstrap.css'
import { Pane, Tab, Tablist, Table, Badge } from 'evergreen-ui';
import DirecaoContext from '../../../../../../context/DirecaoContext';
import ReturnDocumentsForView from '../../../../../../components/MultiViewDocs'

// MSG QUANDO CAMPO VAZIO
const msgCampo = '----';

function ColTable(props) {
    return (
        <Table.TextCell maxWidth={props.width}>
            {props.title && <div className={`mb7 color101840 `}>{props.title}</div>}

            <span className='fw400 color696F8C'>
                {props.subtitle || msgCampo}
            </span>
        </Table.TextCell>
    )
};

export default function Vendedor({ type }) {
    const {
        dadosVenda,
    } = useContext(DirecaoContext);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const userData = (type === 'Vendedor' ? dadosVenda?.vendedores.data : dadosVenda?.compradores.data) || [];

    return (
        <Pane>
            {userData?.length === 0
                ?
                <Table.Row>
                    <Table.TextCell className="historicoStatus">
                        <Table.TextCell className="fontStatus">
                            <span>{`Nenhum ${type} foi cadastrado no momento.`}</span>
                        </Table.TextCell>
                    </Table.TextCell>
                </Table.Row>

                :
                <>
                    <div className="form-dialog">
                        <Tablist marginTop={16} flexBasis={240} marginRight={24} className="tab-form">
                            {userData.map((tabCadastro, index) => (
                                <Tab
                                    key={tabCadastro}
                                    id={"titulo_top_pessoa_" + index}
                                    onSelect={() => setSelectedIndex(index)}
                                    isSelected={index === selectedIndex}
                                    aria-controls={`panel-${tabCadastro}`}
                                    data-tab={tabCadastro}
                                // onClick={() => handleOpenTab(index)}
                                >

                                    {index < userData.length
                                        ? (userData[index].tipo_pessoa === 1)
                                            ? userData[index].nome_fantasia.slice(0, 10) + '...'
                                            : userData[index].name.slice(0, 10) + '...'
                                        : type + ' ' + tabCadastro}
                                </Tab>
                            ))}
                        </Tablist>
                    </div>
                    <div className="form-detalhes">
                        <div className="formGroup formAdd">
                            {userData.map((user, index) => (
                                <Pane
                                    key={user.id}
                                    id={`panel-${user.nome}`}
                                    role="tabpanel"
                                    aria-labelledby={user.nome}
                                    aria-hidden={index !== selectedIndex}
                                    display={index === selectedIndex ? 'block' : 'none'}
                                    className="detadelhes-sidesheet"
                                >
                                    <Pane>
                                        <Badge color="green" className="badge-tipo-pesso">
                                            <span>
                                                {user.tipo_pessoa === 1 ? 'Pessoa Jurídica' : 'Pessoa Física'}
                                            </span>
                                        </Badge>

                                        {user.tipo_pessoa === 0 && user.procurador ?
                                            <>
                                                <React.Fragment>
                                                    <h2 className="subtitle subtitle-table">Procurador</h2>
                                                </React.Fragment>

                                                <Table.Row>
                                                    <ColTable title={"Nome completo"} width="130px" subtitle={user.procurador?.nome} />
                                                </Table.Row>

                                                <Table.Row>
                                                    <ColTable title={"Telefone/Celular"} width="130px" subtitle={user.procurador?.telefone} />
                                                </Table.Row>
                                            </>
                                            :
                                            ''
                                        }

                                        <React.Fragment>
                                            <h2 className="subtitle subtitle-table">
                                                {user.tipo_pessoa === 0 ? `Documentos do ${type}` : 'Documentos da Empresa'}
                                            </h2>
                                        </React.Fragment>

                                        <ReturnDocumentsForView docs={user.documentos.data} />

                                        {user.tipo_pessoa === 1 ?
                                            <>
                                                <React.Fragment>
                                                    <h2 className="subtitle subtitle-table">
                                                        Sócio e/ou Representantes Legais
                                                    </h2>
                                                </React.Fragment>
                                                {user.representante_socios.data.map((representante, index_representante) =>
                                                    <Pane className='mt30'>
                                                        <Table.Row>
                                                            {representante.pj_representante === 1 &&
                                                                <ColTable subtitle={
                                                                    <Badge color="green">
                                                                        <span>
                                                                            REPRESENTANTE LEGAL
                                                                        </span>
                                                                    </Badge>
                                                                } />
                                                            }
                                                            {representante.pj_socio === 1 &&
                                                                <ColTable subtitle={
                                                                    <Badge color="green">
                                                                        <span>
                                                                            SÓCIO
                                                                        </span>
                                                                    </Badge>
                                                                } />
                                                            }
                                                        </Table.Row>

                                                        <Table.Row>
                                                            <ColTable title={"Nome completo"} subtitle={representante.name} />
                                                        </Table.Row>

                                                        <ReturnDocumentsForView docs={representante.documentos.data} />
                                                    </Pane>
                                                )
                                                }
                                            </>

                                            : ''
                                        }
                                    </Pane>
                                </Pane>
                            ))}
                        </div>
                    </div>
                </>
            }
        </Pane>
    )
}