import React, { useContext, useState, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'
import { Pane, TextInputField, Button, SelectField, Checkbox, ClipboardIcon, Label, Radio, Switch, ErrorIcon, Badge } from 'evergreen-ui';

//Context
import VendasContext from '../../../../../../../context/VendasContext';

//Components
import Axios from 'axios';
import Serve from '../../../../../../../app/serve';
import cepMask from '../../../../../../../components/cepMask';
import cpfMask from '../../../../../../../components/cpfMask';
import dateMask from '../../../../../../../components/dateMask';
import validarCPF from '../../../../../../../components/validarCPF';

export default function PessoaFisica({ type, register, unregister, setValue, watch, clearErrors, errors, userData, index }) {

  const app = Serve();
  const [token] = useState(localStorage.getItem('token'));

  const {
    origemComprador,
    dadosVenda,
    perfilUsuario, setPerfilUsuario
  } = useContext(VendasContext);

  console.log('userData Pessoa Fisica: ', userData);
  console.log("watch Pessoa Fisica: ", watch());
  console.log("Errors Pessoa Fisica:", errors);

  //const [perfilUsuario, setPerfilUsuario] = useState(false);

  useEffect(() => {
    console.log(watch(index + '.cpf_cnpj'));
    console.log(watch(index + ".representante_socios.data"));
    if (!watch(index + ".tipo_pessoa")) {
      setValue(index + ".representante_socios.data.0.pj_representante", "");
      setValue(index + ".representante_socios.data.0.pj_socio", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch])

  const validarCpfCampo = async () => {
    let cpf = await watch(index + '.cpf_cnpj');
    if(cpf !== '' || cpf !== undefined){
      Axios.post(app + 'verificar_usuario_cpf', { cpf }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        if (res?.data.usuario) {
          const { usuario } = res.data;
          console.log(index, usuario);
          setValue(index.toString(), { ...usuario });
          setPerfilUsuario(usuario.perfil);
          setValue(index + '.perfil', usuario.perfil);
          clearErrors();
        }
      })
    }
    else{
      setPerfilUsuario(false)
      setValue(index + '.perfil', false)
    }
  }

  useEffect(() => {
    console.log('Teste useEffect');
    validarCpfCampo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const msgObrigatorio = "Campo obrigatório";

  const inputDisabled = {
    borderColor: '#d8dae5',
    background: '#F4F5F9',
    color: '#696f8c'
  };

  const inputGreen = {
    borderColor: '#429777',
    background: '#F5FBF8'
  };

  const inputDefault = {
    borderColor: '#ADC2FF',
    background: '#fff'
  };

  const inputError = {
    borderColor: '#FF0000',
    background: '#fff'
  };

  const [disableCamposEndereco, setDisableCamposEndereco] = useState({
    bairro: true,
    logradouro: true,
    cidade: true,
    estado: true,
  });

  async function handleCpf(cpf, index) {
    if (cpf.length === 14) {
      await Axios.post(app + 'verificar_usuario_cpf', { cpf }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        if (res?.data.usuario) {
          const { usuario } = res.data;
          console.log(index, usuario);
          setValue(index.toString(), { ...usuario });
          setPerfilUsuario(usuario.perfil);
          clearErrors();
        }
      })
    }
  };

  async function handleCep(cep, index) {
    if (cep.replace(/\D/g, '').length === 8) {
      await Axios.get("https://viacep.com.br/ws/" + cep + "/json/")
        .then(res => {
          if (!res.data.erro) {
            setValue(index + '.bairro', res.data?.bairro);
            disableCamposEndereco.bairro = res.data?.bairro ? true : false;

            setValue(index + '.logradouro', res.data?.logradouro);
            disableCamposEndereco.logradouro = res.data?.logradouro ? true : false;

            setValue(index + '.cidade', res.data?.localidade);
            disableCamposEndereco.cidade = res.data?.localidade ? true : false;

            setValue(index + '.estado', res.data?.uf);
            disableCamposEndereco.estado = res.data?.uf ? true : false;
          } else {
            setValue(index + '.bairro', "")
            disableCamposEndereco.bairro = false;
            setValue(index + '.logradouro', "")
            disableCamposEndereco.logradouro = false;
            setValue(index + '.cidade', "")
            disableCamposEndereco.cidade = false;
            setValue(index + '.estado', "")
            disableCamposEndereco.estado = false;
            setDisableCamposEndereco({ ...disableCamposEndereco });
          }
        })
      setValue(index + '.cep', cepMask(cep));
    };
  };

  function copyEnderecoImovel(index) {
    if(dadosVenda.cep) {
      setValue(index + '.cep', dadosVenda.cep);
      clearErrors(index + '.cep');
    }
    if(dadosVenda.bairro) {
      setValue(index + '.bairro', dadosVenda.bairro);
      clearErrors(index + '.bairro');
    }
    if(dadosVenda.logradouro) {
      setValue(index + '.logradouro', dadosVenda.logradouro);
      clearErrors(index + '.logradouro');
    }
    if(dadosVenda.cidade) {
      setValue(index + '.cidade', dadosVenda.cidade);
      clearErrors(index + '.cidade');
    }
    if(dadosVenda.uf) {
      setValue(index + '.estado', dadosVenda.uf);
      clearErrors(index + '.estado');
    }
    if(dadosVenda.numero) {
      setValue(index + ".numero", dadosVenda.numero);
      clearErrors(index + '.numero');
    }
    if(dadosVenda.unidade) {
      setValue(index + ".unidade", dadosVenda.unidade);
      clearErrors(index + '.unidade');
    }
    if(dadosVenda.complemento){
      setValue(index + ".complemento", dadosVenda.complemento);
      clearErrors(index + '.complemento');
    }
  };

  const handleSelect = (e) => {
    setValue(index + '.estado_civil', e.target.value);
    if (e.target.value !== '1') setValue(index + '.registro_casamento')
    else setValue(index + ".uniao_estavel", false)
  };

  return (
    <Pane>
      <form id="formGroup" className="formGroup-dialog">
        {!userData
          ? <div className="rowForm mt35">
            <Switch
              marginBottom={16}
              checked={watch(index + '.tipo_pessoa') === 1}
              onChange={() => setValue(index + '.tipo_pessoa', watch(index + '.tipo_pessoa') === 1 ? 0 : 1)}
            />
            <label className='ml10'>Pessoa Física</label>
          </div>
          : <Badge color="green" className="badge-tipo-pesso">
            <span>Pessoa Física</span>
          </Badge>
        }

        <h4 className='mt15'>{"Quem está " + (type === 'vendedores' ? 'vendendo' : 'comprando') + " o imóvel?"}</h4>
        <p className="descritption mb30 mt15" >{"Insira os dados necessários da(s) pessoa(s) " + (type === 'vendedores' ? 'vendendo' : 'comprando') + " o imóvel."}</p>

        <Pane>
          {/* LINHA CPF E TELEFONE  */}
          <div className="rowForm">
            <input type="hidden" {...register(index + '.perfil')} />
            <TextInputField
              width={160}
              label='CPF *'
              description=""
              placeholder='000.000.000-00'
              maxLength={14}
              value={watch(index + '.cpf_cnpj')}
              {...register(index + '.cpf_cnpj', {
                required: msgObrigatorio,
                validate: (value) => validarCPF(value) ? true : "CPF inválido",
                onChange: (e) => handleCpf(e.target.value, index),
                setValueAs: e => cpfMask(e)
              })}
              validationMessage={errors?.[index]?.cpf_cnpj && errors?.[index]?.cpf_cnpj.message}
              style={watch(index + '.cpf_cnpj') ? inputGreen :
                errors?.[index]?.cpf_cnpj ? inputError : inputDefault
              }
            />

            <Pane display="flex" flexDirection="column" marginLeft={16}>
              <label className='mb12'>Celular*</label>
              <PhoneInput
                country={"br"}
                onChange={(e, country) => {
                  setValue(index + '.telefone', e.replace(country.dialCode, ''));
                  setValue(index + '.ddi', country.dialCode);
                  clearErrors(index + '.telefone');
                }}
                value={watch(index + ".telefone") ? watch(index + ".ddi") + watch(index + ".telefone") : '55'}
                disabled={!!watch(index + '.perfil')}
                preferredCountries={['br', 'us']}
                // inputStyle={watch(index + ".telefone")?.length >= 10 ? inputGreen : errors?.[index]?.telefone ? inputError : inputDefault}
                inputStyle={
                  watch(index + ".telefone")?.length >= 10
                  ? !!watch(index + '.perfil') === false
                    ? inputGreen
                    : inputDisabled
                  : errors?.[index]?.telefone 
                    ? inputError : inputDefault
                }
                inputClass="PhoneInput"
                isValid={(inputNumber, country) => {
                  if (country.countryCode === '55') {
                    return inputNumber.length < 12 ? false : true;
                  } else {
                    return inputNumber.length < 6 ? false : true;
                  }
                }}
              />
              <input
                type="hidden"
                {...register(index + '.telefone', {
                  required: 'Campo obrigatório',
                  validate: (value) => {
                    if (value.length < 10) {
                      return "Telefone inválido";
                    }
                  },
                })}
              />
              <spam className="error-input mt8">
                {errors?.[index]?.telefone ? <ErrorIcon /> : ''}
                <span>{errors?.[index]?.telefone && errors?.[index]?.telefone.message}</span>
              </spam>
            </Pane>
          </div>

          {/* CAMPO GENERO */}
          <div className="rowForm mb10">
            <div className='line-radius' tabIndex={-1} id={index + ".genero"}>
              <Label>Gênero*</Label>
              <Pane
                aria-label="radio-group-genero"
                role="group"
                className="radio radius radio-genero">
                <Radio
                  size={16}
                  className="radio-M"
                  value="M"
                  label="Masculino"
                  checked={watch(index + '.genero') === 'M'}
                  onChange={() => [setValue(index + '.genero', "M"), clearErrors(index + '.genero')]}
                />
                <Radio
                  size={16}
                  value="F"
                  label="Feminino"
                  className="radio-F"
                  checked={watch(index + '.genero') === 'F'}
                  onChange={() => [setValue(index + '.genero', "F"), clearErrors(index + '.genero')]}
                />
                <input
                  type="hidden"
                  {...register(index + '.genero', {
                    required: msgObrigatorio,
                  })}
                />
              </Pane>
              <spam className="error-input">
                {errors?.[index]?.genero && <ErrorIcon />}
                <span>{errors?.[index]?.genero && errors?.[index]?.genero.message}</span>
              </spam>
            </div>
          </div>

          {/* CAMPO NOME */}
          <div className="rowForm">
            <TextInputField
              width={463}
              label="Nome Completo *"
              description=""
              name={index + '.name'}
              placeholder="Ex: José Maria da Silva"
              disabled={!!watch(index + '.perfil')}
              {...register(index + '.name', {
                required: msgObrigatorio,
                // onChange: (e) => setValue(index + '.name', e.target.value),
              })}
              validationMessage={errors?.[index]?.name && errors?.[index]?.name?.message}
              // style={watch(index + '.name') ? inputGreen : errors?.[index]?.name ? inputError : inputDefault}
              style={
                watch(index + '.name')
                  ? !!watch(index + '.perfil') === false
                    ? inputGreen
                    : inputDisabled
                  : errors?.[index]?.name 
                    ? inputError : inputDefault
              }
            />
          </div>

          {/* CAMPO DATA DE NASCIMENTO E NACIONALIDADE */}
          <div className="rowForm">
            <TextInputField
              width={180}
              label="Data de nascimento"
              description=""
              placeholder="Ex: DD/MM/AAAA"
              maxLength={10}
              value={watch(index + '.data_nascimento')}
              //disabled={!!watch(index + '.perfil')}
              //style={watch(index + '.data_nascimento') ? inputGreen : inputDefault}
              style={
                watch(index + '.data_nascimento') 
                  ? inputGreen 
                  : inputDefault
              }
              {...register(index + '.data_nascimento', {
                setValueAs: e => dateMask(e)
                // onChange: (e) => handleDataNascimento(e, index),
                // onBlur: (e) => handleVerificarQuantidadeCaracteres(e, index)
              }
              )}
            />

            <TextInputField
              width={180}
              marginLeft={20}
              label="Nacionalidade *"
              description=""
              placeholder="Ex: Brasileiro"
              value={watch(index + '.nacionalidade')}
              {...register(index + '.nacionalidade', {
                required: "Campo obrigatório",
                // onChange: (e) => handleNacionalidade(e, index),
              })}
              validationMessage={errors?.[index]?.nacionalidade && errors?.[index]?.nacionalidade.message}
              style={watch(index + '.nacionalidade') ? inputGreen : errors?.[index]?.nacionalidade ? inputError : inputDefault}
            />
          </div>

          {/* CAMPO NOME DA MÃE */}
          <div className="rowForm">
            <TextInputField
              width={463}
              label="Nome da mãe *"
              description=""
              placeholder="Ex: Maria da Silva"
              value={watch(index + '.nome_mae')}
              //disabled={!!watch(index + '.perfil')}
              {...register(index + '.nome_mae', {
                required: "Campo obrigatório",
                // onChange: (e) => handleNomeMae(e, index),
              })}
              validationMessage={errors?.[index]?.nome_mae && errors?.[index]?.nome_mae.message}
              // style={watch(index + '.nome_mae') ? inputGreen : errors?.[index]?.nome_mae ? inputError : inputDefault}
              style={
                watch(index + '.nome_mae')
                    ? inputGreen
                    : errors?.[index]?.nome_mae 
                      ? inputError 
                      : inputDefault
              }
            />
          </div>

          {/* CAMPO NOME DO PAI */}
          <div className="rowForm">
            <TextInputField
              width={463}
              label="Nome do Pai"
              description=""
              placeholder="Ex: José Maria da Silva"
              value={watch(index + '.nome_pai')}
              //disabled={!!watch(index + '.perfil')}
              //style={watch(index + '.nome_pai') ? inputGreen : inputDefault}
              style={
                watch(index + '.nome_pai')
                  ? inputGreen
                  : errors?.[index]?.nome_pai 
                    ? inputError 
                    : inputDefault
              }
              {...register(index + '.nome_pai', {
                // onChange: (e) => verificaInput(e, index) 
              })}
            />
          </div>

          {/* CAMPO PROFISSAO */}
          <div className="rowForm">
            <TextInputField
              width={463}
              label="Profissão *"
              description=""
              placeholder="Ex: Corretor"
              value={watch(index + '.profissao')}
              {...register(index + '.profissao', {
                required: "Campo obrigatório",
                // onChange: (e) => verificaInput(e, index),
              })}
              validationMessage={errors?.[index]?.profissao && errors?.[index]?.profissao.message}
              style={watch(index + '.profissao') ? inputGreen : errors?.[index]?.profissao ? inputError : inputDefault}
            />
          </div>

          {/* CAMPO RG E DEPENDENTES */}
          <div className="rowForm">
            <TextInputField
              width={216}
              label="RG"
              description=""
              value={watch(index + '.rg')}
              //disabled={!!watch(index + '.perfil')}
              placeholder="Ex: 12.345.678-9"
              //style={watch(index + '.rg') ? inputGreen : inputDefault}
              style={
                watch(index + '.rg') 
                  ? inputGreen
                  : inputDefault 
              }
              {...register(index + '.rg', {
                // onChange: (e) => handleRg(e, index)
              })}
            />
            {watch(index + '.rg')?.length > 0
              ?
              <>
                <TextInputField
                  width={216}
                  label="Expedida por: *"
                  //disabled={!watch(index + '.rg') || watch(index + '.perfil') === true}
                  description=""
                  placeholder="Ex: Detran-RJ"
                  value={watch(index + '.rg_expedido')}
                  validationMessage={errors?.[index]?.rg_expedido && "Campo obrigatório"}
                  //style={watch(index + '.rg_expedido') ? inputGreen : errors?.[index]?.rg_expedido ? inputError : inputDefault}
                  style={
                    watch(index + '.rg_expedido')
                    ? inputGreen
                    : errors?.[index]?.rg_expedido 
                      ? inputError : inputDefault
                  }
                  {...register(index + '.rg_expedido', {
                    required: watch(index + '.rg')?.length > 0 ? msgObrigatorio : false,
                  })}
                />

                <TextInputField
                  width={216}
                  label="Data de expedição *"
                  //disabled={!watch(index + '.rg') || watch(index + '.perfil') === true}
                  description=""
                  placeholder="Ex: DD/MM/AAAA"
                  marginBottom={0}
                  marginLeft={20}
                  maxLength={10}
                  value={watch(index + '.data_rg_expedido')}
                  //style={watch(index + '.data_rg_expedido') ? inputGreen : errors?.[index]?.data_rg_expedido ? inputError : inputDefault}
                  style={
                    watch(index + '.data_rg_expedido')
                    ? inputGreen
                    : errors?.[index]?.data_rg_expedido 
                      ? inputError : inputDefault
                  }        
                  {...register(index + '.data_rg_expedido', {
                    required: watch(index + '.rg')?.length > 0 ? msgObrigatorio : false,
                    // onChange: (e) => handleDataExpedicao(e, index),
                    setValueAs: e => dateMask(e)
                  })}
                  validationMessage={errors?.[index]?.data_rg_expedido && "Campo obrigatório"}
                />
              </>
              : ''}
          </div>

          {/* CAMPO EMAIL */}
          <div className="rowForm">
            <TextInputField
              width={463}
              label="E-mail"
              description=""
              placeholder={type === 'vendedores' ? "Ex: vendedor@gmail.com" : "Ex: comprador@gmail.com"}
              value={watch(index + '.email')}
              disabled={!!watch(index + '.perfil')}
              style={
                watch(index + '.email') ? 
                  !!watch(index + '.perfil') === false 
                    ? inputGreen 
                    : inputDisabled
                  : !!watch(index + '.perfil') === true ? inputDisabled : inputDefault
              }
              {...register(index + '.email', {
                required: false,
                // onChange: (e) => verificaInput(e, index),
              })}
            // validationMessage={errors.email && errors.email[index] && errors.email[index].message}              
            />
          </div>


          {/* CAMPO ESTADO CIVIL, REGIME CASAMENTO E UNIÃO ESTAVEL */}
          <div className="rowForm" tabIndex={-1} id={index + ".estado_civil"}>
            <SelectField
              label="Estado Civil *"
              description=""
              name={index + '.estado_civil'}
              validationMessage={errors?.[index]?.estado_civil && errors?.[index]?.estado_civil.message}
              className={watch(index + '.estado_civil') ? 'inputGreenSelect' : errors?.[index]?.estado_civil ? 'inputErrorSelect' : ""}
              value={watch(index + '.estado_civil')}
              {...register(index + '.estado_civil', {
                required: msgObrigatorio,
                onChange: (e) => handleSelect(e),
              })}
            >
              <option value="" hidden>Selecionar</option>
              <option value="1">Casado</option>
              <option value="2">Solteiro</option>
              <option value="3">Divorciado</option>
              <option value="4">Viúvo</option>
              <option value="5">Separado</option>
            </SelectField>

            {watch(index + '.estado_civil') === '1' &&
              <div tabIndex={-1} id={index + ".registro_casamento"}>
                <SelectField
                  label="Regime de casamento *"
                  description=""
                  name={index + '.registro_casamento'}
                  validationMessage={errors?.[index]?.registro_casamento && errors?.[index]?.registro_casamento.message}
                  className={watch(index + '.registro_casamento') ? 'inputGreenSelect' : errors?.[index]?.registro_casamento ? 'inputErrorSelect' : ""}
                  value={watch(index + '.registro_casamento')}
                  {...register(index + '.registro_casamento', {
                    required: false,
                    validate: value => { if (!value && watch(index + '.estado_civil') === '1') return msgObrigatorio },
                    onChange: (e) => setValue(index + '.registro_casamento', e.target.value)
                  })}
                >
                  <option value="0" hidden>Selecionar</option>
                  <option value="1">Separação total de bens</option>
                  <option value="2">Separação parcial de bens</option>
                  <option value="3">Separação legal de bens</option>
                  <option value="4">Comunhão de bens</option>
                  <option value="5">Comunhão parcial de bens</option>
                </SelectField>
              </div>
            }
            { }
            {watch(index + '.estado_civil') > '1' &&
              <div className="row-recibo">
                <div className="line-checkbox">
                  <Checkbox
                    marginTop={38}
                    className="checkbox"
                    label="União Estável"
                    appearance="default"
                    value="S"
                    {...register(index + ".uniao_estavel", {
                      required: false,
                      onChange: () => watch(index + ".uniao_estavel") === "S" ? "" : "S"
                    })}
                    checked={watch(index + ".uniao_estavel") === "S"}
                  />
                </div>
              </div>
            }
          </div>

          {/* CAMPO NOME CONJUGUE */}
          <div className="rowForm">
            {
              watch(index + '.registro_casamento') || watch(index + ".uniao_estavel") === "S"
                ?
                <TextInputField
                  width={463}
                  label="Nome completo do Cônjuge/Compaheiro(a)"
                  description=""
                  placeholder="Ex: José Maria da Silva"
                  value={watch(index + '.conjuge')}
                  validationMessage={errors?.[index]?.conjuge && errors?.[index]?.conjuge.message}
                  style={watch(index + '.conjuge') ? inputGreen : errors?.[index]?.conjuge ? inputError : inputDefault}
                  {...register(index + '.conjuge', {
                    required: (watch(index + '.registro_casamento') !== '0' || watch(index + ".uniao_estavel") === 'S') ? 'Campo obrigatório' : false,
                    // onChange: (e) => handleChangeConjuge(e, index)

                  })}
                />
                : ""
            }
          </div>

          {/* CAMPO ORIGEM DO CLIENTE */}
          {type === 'compradores' && index === 0 ?
            <div className="row-recibo mb30">
              <div className="subtitle">
                Sobre a origem do seu cliente
              </div>

              <div className="rowForm origem-pj" >
                <div tabIndex={-1} id={index + ".meio_entrada"}>
                  <SelectField
                    label="Por qual meio ele veio *"
                    description=""
                    width={250}
                    tabIndex={-1}
                    id={index + ".meio_entrada"}
                    value={watch(index + '.meio_entrada')}
                    className={watch(index + '.meio_entrada') ? 'inputGreenSelect' : errors?.[index]?.meio_entrada ? 'inputErrorSelect' : ''}
                    {...register(index + '.meio_entrada', {
                      required: (type === 'compradores' && index === 0) ? msgObrigatorio : false,
                      onChange: (e) => setValue(index + '.meio_entrada', e.target.value)
                    })}
                    marginBottom={0}
                    validationMessage={errors?.[index]?.meio_entrada && errors?.[index]?.meio_entrada.message}
                  >
                    <option value="" hidden>Selecionar</option>
                    {
                      origemComprador.lista_origem.filter(origem => origem.id !== 11).map(origem => <option value={origem.id}>{origem.nome}</option>)
                    }
                  </SelectField>
                </div>

                <div tabIndex={-1} id={index + ".forma_contato"}>
                  <SelectField
                    label="Forma de contato *"
                    description=""
                    width={250}
                    value={watch(index + '.forma_contato')}
                    className={watch(index + '.forma_contato') ? 'inputGreenSelect' : errors?.[index]?.forma_contato ? 'inputErrorSelect' : ''}
                    {...register(index + '.forma_contato', {
                      required: 'Campo obrigatório',
                      onChange: (e) => setValue(index + '.forma_contato', e.target.value)
                    })}
                    marginBottom={0}
                    validationMessage={errors?.[index]?.forma_contato && errors?.[index]?.forma_contato.message}
                  >
                    <option value="" hidden>Selecionar</option>
                    {
                      origemComprador.lista_contato.map(contato => <option value={contato.id}>{contato.nome}</option>)
                    }
                  </SelectField>
                </div>

                <TextInputField
                  label="Data de entrada *"
                  description=""
                  placeholder="Ex: DD/MM/AAAA"
                  width={170}
                  maxLength={10}
                  marginLeft={20}
                  marginBottom={0}
                  value={watch(index + '.data_entrada_origem')}
                  style={watch(index + '.data_entrada_origem') ? inputGreen : errors?.[index]?.data_entrada_origem ? inputError : inputDefault}
                  {...register(index + '.data_entrada_origem', {
                    required: msgObrigatorio,

                    validate: (value) => {
                      if (value.length >= 1 && value.length < 10) {
                        return "Formato de data inválido";
                      }
                      if (value.length === 0) {
                        return "Campo obrigatório";
                      }
                    },
                    //onChange: (e) => handleDataEntradaOrigem(e, index)
                    setValueAs: e => dateMask(e)
                  })}
                  validationMessage={errors?.[index]?.data_entrada_origem && errors?.[index]?.data_entrada_origem.message}
                />
              </div>
            </div>
            :
            ''
          }

          {/* CAMPOS DE ENDEREÇO */}
          <div className="row-recibo">
            <div className="subtitle">Endereço</div>

            {type === "vendedores" &&
              <Button
                appearance="default"
                iconBefore={<ClipboardIcon />}
                onClick={() => copyEnderecoImovel(index)}
                className="btn-default mb15"
                type='button'
              >
                Copiar endereço do imóvel vendido
              </Button>
            }

            {/* CAMPOS CEP BAIRRO E LOGRADOURO */}
            <div className="rowForm">
              <TextInputField
                label="CEP *"
                description=""
                placeholder="Ex: 00000-000"
                maxLength={9}
                value={watch(index + '.cep')}
                validationMessage={errors?.[index]?.cep && errors?.[index]?.cep.message}
                style={watch(index + '.cep') ? inputGreen : errors?.[index]?.cep ? inputError : inputDefault}
                {...register(index + '.cep', {
                  required: "Campo obrigatório",
                  onChange: (e) => handleCep(e.target.value, index),
                  // setValueAs: e => cepMask(e)
                })}
              />

              <TextInputField
                width={215}
                label="Bairro"
                description=""
                value={watch(index + '.bairro')}
                placeholder={watch(index + '.cep') && !watch(index + '.bairro') ? 'Preencha com o bairro' : '---'}
                disabled={disableCamposEndereco.bairro}
                {...register(index + '.bairro', {
                  required: watch(index + '.cep') && !watch(index + '.bairro') ? "Campo obrigatório" : false,
                  // onChange: (e) => handleBairroLogradouro(e, index, 'bairro'),
                })}
                validationMessage={errors?.[index]?.bairro && errors?.[index]?.bairro.message}
                style={disableCamposEndereco.bairro ? undefined : watch(index + '.bairro') ? inputGreen : errors?.[index]?.bairro ? inputError : inputDefault}
              />

              <TextInputField
                width={250}
                label="Logradouro"
                description=""
                marginBottom={0}
                marginLeft={20}
                value={watch(index + '.logradouro')}
                placeholder={watch(index + '.cep') && !watch(index + '.logradouro') ? 'Preencha com o logradouro' : '---'}
                disabled={disableCamposEndereco.logradouro}
                {...register(index + '.logradouro', {
                  required: watch(index + '.cep') && !watch(index + '.logradouro') ? "Campo obrigatório" : false,
                  // onChange: (e) => handleBairroLogradouro(e, index, 'logradouro'),
                })}
                validationMessage={errors?.[index]?.logradouro && errors?.[index]?.logradouro.message}
                style={disableCamposEndereco.logradouro ? undefined : watch(index + '.logradouro') ? inputGreen : errors?.[index]?.logradouro ? inputError : inputDefault}
              />
            </div>

            {/* CAMPOS NUMERO UNIDADE E COMPLEMENTO */}
            <div className="rowForm">
              <TextInputField
                width={130}
                label="Número *"
                description=""
                placeholder="Ex: 456"
                value={watch(index + '.numero')}
                validationMessage={errors?.[index]?.numero && errors?.[index]?.numero.message}
                style={watch(index + '.numero') ? inputGreen : errors?.[index]?.numero ? inputError : inputDefault}
                {...register(index + '.numero', {
                  required: "Campo obrigatório",
                  // onChange: (e) => handleSomenteNumero(e, index),
                })}
              />

              <TextInputField
                width={180}
                label="Unidade"
                description=""
                placeholder="Número do apartamento"
                value={watch(index + '.unidade')}
                style={watch(index + '.unidade') ? inputGreen : inputDefault}
                {...register(index + '.unidade', {
                  // onChange: (e) => 
                })}
              />

              <TextInputField
                width={360}
                label="Complemento"
                placeholder="Bloco, lote ou condomínio"
                description=""
                marginBottom={0}
                marginLeft={20}
                value={watch(index + '.complemento')}
                style={watch(index + '.complemento') ? inputGreen : inputDefault}
                {...register(index + '.complemento', {
                  // onChange: (e) => 
                })}
              />
            </div>

            {/* CAMPOS CIDADE E ESTADO */}
            <div className="rowForm">
              <TextInputField
                width={250}
                label="Cidade"
                description=""
                value={watch(index + '.cidade')}
                placeholder={watch(index + '.cep') && !watch(index + '.cidade') ? 'Preencha com a cidade' : '---'}
                disabled={disableCamposEndereco.cidade}
                {...register(index + '.cidade', {
                  required: watch(index + '.cep') && !watch(index + '.cidade') ? "Campo obrigatório" : false,
                  // onChange: (e) => 
                })}
                validationMessage={errors?.[index]?.cidade && errors?.[index]?.cidade.message}
                style={disableCamposEndereco.cidade ? undefined : watch(index + '.cidade') ? inputGreen : errors?.[index]?.cidade ? inputError : inputDefault}
              />

              <TextInputField
                width={250}
                label="Estado"
                description=""
                value={watch(index + '.estado')}
                placeholder={watch(index + '.cep') && !watch(index + '.estado') ? 'Preencha com o estado' : '---'}
                disabled={disableCamposEndereco.estado}
                {...register(index + '.estado', {
                  required: watch(index + '.cep') && !watch(index + '.estado') ? "Campo obrigatório" : false,
                  // onChange: (e) => 
                })}
                validationMessage={errors?.[index]?.estado && errors?.[index]?.estado.message}
                style={disableCamposEndereco.estado ? undefined : watch(index + '.estado') ? inputGreen : errors?.[index]?.estado ? inputError : inputDefault}
              />
            </div>
          </div>
        </Pane>
      </form>
    </Pane >
  )
}