import React, { useState, useContext, useEffect } from 'react';
import { Pane, Tooltip, Table, Badge, Avatar, Combobox, Autocomplete, TextInput, FilterIcon, Pagination, toaster, Button } from 'evergreen-ui';
import ApoioContext from '../../../context/ApoioContext'
import updateStatusVisualizarApoio from '../../../apis/updateStatusVisualizarApoio';
import Lottie from 'react-lottie';
import animationData from '../../../images/Animations/loadingDocs.json';
import SideSheetView from './SideSheetView';
import getProcessTableApoio from '../../../apis/getProcessTableApoio';
import getProcessById from '../../../apis/getProcessById';
import { CSVLink } from "react-csv";
import Axios from 'axios';
import Serve from '../../../app/serve';

export default function TablePainel({ getList }) {

  const token = localStorage.getItem('token');
  const app = Serve();

  //LOTTIE
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const {
    loading, setLoading,

    //LISTAS
    listaEndereco,
    listaGerente, listaProcessos,

    //SIDESHEET
    setIsShownSide,
    setSelectProcess,
    selectProcess,
    setIdProcess,

    filtro,
    totalProcessos,
    setListaProcessos,
    pageNumber, setPageNumber,
    totalPaginas, setTotalPaginas,
    totalProcessosFiltrados,
    setTotalProcessosFiltrados,
  } = useContext(ApoioContext);

  const [classNovo, setClassNovo] = useState([]);
  const [quantFiltro] = useState([]);

  //Pagination  
  const [paginaAnterior, setPaginaAnterior] = useState([]);
  const [paginaSubsequente, setPaginaSubsequente] = useState([]);
  const [loadingProximas, setLoadingProximas] = useState([]);

  // console.log("Table data: ", listaProcessos);

  //CSV
  const [csvVendas, setCsvVendas] = useState([]);
  const dataHoje = new Date();
  const dataGerado = dataHoje.getDate() + '/' + (dataHoje.getMonth() < 10 ? '0' : '') + (dataHoje.getMonth() + 1) + '/' + dataHoje.getFullYear();  

  useEffect(() => {
    listaProcessos.forEach((processo, index) => {
      (processo.status_visualizacao_apoio === 1)
        ? classNovo[index] = "fw400" : classNovo[index] = "fw600"
    })
    setClassNovo([...classNovo]);

    const csvVenda = async () => {

      await Axios.get(app + 'csv_vendas', {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
        .then(res => {
          const vendas = res.data;
           
          let apiCsv = [];
          vendas.forEach(element => {
            apiCsv.push(
              {
                processo: element.processo_id,
                referencia: element.codigo,
                imovel: element.logradouro + ', ' + element.numero  + (element.unidade ? ' / ' + element.unidade :  ' ') + (element.complemento ? ' / ' + element.complemento + ', ' :  ', ') + element.bairro + ' - ' + element.cidade,   
                status: element.status_nome,
                forma_pagamento: element.forma_pagamento,
                loja: element.loja,
                gerente: element.gerente,
                responsavel: element.responsavel, 
                data_entrada: element.data_entrada,
                data_assinatura: element.data_assinatura,
                comprador: element.nome_comprador,
                email: element.email_comprador,
                telefone: element.telefone_comprador,
                origem_lead: element.origem_cliente_nome,
                meio_lead: element.meio_lead_nome,
                data_entrada_lead: element.data_entrada_lead,
                valor_venda: element.valor_venda,
                valor_comissao_liquida: element.valor_comissao_liquida, 
              }
            );
          });
          setCsvVendas(apiCsv);
        })
        .catch(function (error) {
          console.log(error);
        })
    };
    csvVenda();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listaProcessos])

  const refreshTable = async () => {
    await getList();
    await quantidadeFiltros();
    proximasPaginas(pageNumber)
  };

  useEffect(() => {
    refreshTable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Filtro de logradouro
  function handleFilterEndereco(e) {
    filtro.endereco = e;
    setPageNumber(1);
    localStorage.setItem('filtro_endereco', e);
    handleFilter(1, 'filtro');
  };

  // Filtro de Gerente
  const handleCombobox = (e) => {
    setPageNumber(1);
    if (e) {
      filtro.gerente = e
      localStorage.setItem('filtro_gerente', JSON.stringify({ 'id': e.id, 'name': e.name }));
      handleFilter(1, 'filtro');
    }
  };

  const quantidadeFiltros = async () => {
    let filtrosSelecionados = 0;
    if (filtro.responsavel !== '') {
      filtrosSelecionados++;
    }
    if (filtro.status !== '') {
      filtrosSelecionados++;
    }
    if (filtro.gerente !== '') {
      filtrosSelecionados++;
    }
    if (filtro.endereco !== '') {
      filtrosSelecionados++;
    }
    quantFiltro[0] = filtrosSelecionados;
  };

  const handleFilter = async (page, temFiltro) => {
    localStorage.setItem('page_number', page);
    setPageNumber(page);
    if (page === pageNumber && !temFiltro) {
      return;
    };
    if (page === pageNumber + 1 && !temFiltro && paginaSubsequente.length > 0 && !loadingProximas) {
      setListaProcessos(paginaSubsequente);
      proximasPaginas(page);
      return;
    };
    if (page === pageNumber - 1 && !temFiltro && paginaAnterior.length > 0 && !loadingProximas) {
      setListaProcessos(paginaAnterior);
      proximasPaginas(page);
      return;
    } else {
      setLoading(true);
      const processos = await getProcessTableApoio(filtro, page);
      setListaProcessos(processos.solicitacoes.data);
      setTotalPaginas(processos.total_pagina);
      setTotalProcessosFiltrados(processos.total_solicitacoes_filtradas);
      quantidadeFiltros();
      setLoading(false);
      proximasPaginas(page)
    }
  };

  async function proximasPaginas(page) {
    setPaginaAnterior([]);
    setPaginaSubsequente([]);
    setLoadingProximas(true);
    if (page < totalPaginas) {
      const proxima = await getProcessTableApoio(filtro, page + 1);
      setPaginaSubsequente(proxima.solicitacoes.data);
    }
    if (page > 1) {
      const anterior = await getProcessTableApoio(filtro, page - 1);
      setPaginaAnterior(anterior.solicitacoes.data);
    }
    setLoadingProximas(false);
  };

  const handleClearFilter = async (e) => {
    setLoading(false);
    setPageNumber(1);
    filtro.responsavel = '';
    filtro.status = '';
    filtro.gerente = '';
    filtro.endereco = '';
    localStorage.removeItem('filtro_status');
    localStorage.removeItem('filtro_responsavel');
    localStorage.removeItem('filtro_gerente');
    localStorage.removeItem('filtro_endereco');
    handleFilter(1, 'filtro');
  };

  const sideFunction = async (processo, index) => {
    setLoading(true);
    if (processo.status_visualizacao_apoio === 0) {
      await updateStatusVisualizarApoio(processo.comissao_id);
      listaProcessos[index].status_visualizacao_apoio = 1;
      setListaProcessos([...listaProcessos]);
    }

    if(selectProcess?.id === processo.id) {
      setIsShownSide(true);
      return setLoading(false);
    }
    
    setIdProcess(processo.id);
    const processSelect = await getProcessById(processo.id);
    if (processSelect) {
      setSelectProcess(processSelect);
      setIsShownSide(true);
    } else {
      toaster.notify("Algo não funcionou como deveria. Tente novamente.")
    }
    setLoading(false);
  };

  return (
    <Pane>
      <SideSheetView refreshTable={refreshTable} />

      <h2 className="subtitle subtitle-table" style={{ padding: "0px" }}>
        Processos
        {
          <Tooltip
            content="Numero total de processos"
          >
            <Badge color="blue" marginLeft={10}>
              {totalProcessos}
            </Badge>
          </Tooltip>
        }
      </h2>

      <Button marginRight={16} marginBottom={20} appearance="primary" disabled={loading}>
        <CSVLink
          style={{color: '#fff'}}
          data={csvVendas}
          filename={"safebox_apoio_vendas_" + dataGerado + ".csv"}
          onClick={() => {
            console.log("Download CSV");
          }}
        >
          Baixar CSV das Vendas
        </CSVLink>
      </Button>

      <div className='filter-table space-between'>
        <div className="filter-table">
          <span>Filtrar por: </span>
          {/* REMOVIDO FILTRO DE STATUS NO DIA 15/02 (BATIDO COM VINI) */}
          {/* <div className='filter-field'>
            <Select
              label=""
              name='status'
              value={filtro.status}
              onChange={(e) => handleSelect(e)}
              id="filtro-status"
              className={filtro.status === "" ? "filtro-select color-select-filter-hidden" : "filtro-select color-select-filter"}
            >
              <option value="" hidden selected >Status</option>
              {listaStatus.map(valor =>
                <option value={valor.id}>{valor.status}</option>
              )}
            </Select>
          </div> */}
          <div className='filter-field'>
            <Autocomplete
              onChange={(e) => (handleFilterEndereco(e))}
              items={listaEndereco.sort()}
              selectedItem={filtro.endereco}
              allowOtherValues={true}
            >
              {props => {
                const { getInputProps, getRef, inputValue, openMenu } = props
                return (
                  <TextInput
                    placeholder="🔍︎ Endereço"
                    value={inputValue}
                    ref={getRef}
                    {...getInputProps({
                      onClick: () => {
                        openMenu()
                      }
                    })}
                  />
                )
              }}
            </Autocomplete>
          </div>
          
          <div className='filter-field'>
            <Combobox
              openOnFocus
              name='gerente'
              items={listaGerente}
              selectedItem={filtro.gerente}
              itemToString={item => (item ? item.name : '')}
              placeholder="🔍︎ Gerente"
              onChange={(e) => handleCombobox(e)}
              id="filtro-gerente"
              className={filtro.gerente === "" ? "filtro-select color-select-filter-hidden" : "filtro-select color-select-filter"}
            />
          </div>
          
        </div>
        <div className='filter-table'>
          <Pagination
            page={pageNumber}
            totalPages={totalPaginas}
            onPageChange={(e) => !loading ? handleFilter(e) : ''}
            onNextPage={() => handleFilter(pageNumber + 1)}
            onPreviousPage={() => handleFilter(pageNumber - 1)}
          />
        </div>
      </div>

      {quantFiltro[0] > 0 &&
        <div className="filter-info">
          <p>
            <FilterIcon className="icon-filter" />
            {quantFiltro[0]} {quantFiltro[0] === 1 ? 'filtro aplicado. ' : 'filtros aplicados. '}
            {listaProcessos.length} / {totalProcessosFiltrados} {totalProcessosFiltrados === 1 ? 'processo visível' : 'processos visíveis'}.
            <span onClick={(e) => handleClearFilter(e)}> Limpar filtro</span>
          </p>
        </div>
      }

      <div className='Rectangle35 Blue'></div>
      <Table.Body className="table overHidden">
        <Table.Head>
          <Table.TextHeaderCell className="table apoio_resp textEllipsis">
            Responsável
          </Table.TextHeaderCell>
          <Table.TextHeaderCell className="table apoio_endereco">
            Endereço
          </Table.TextHeaderCell>

          <Table.TextHeaderCell className="table apoio_comissao" >
            Comissão
          </Table.TextHeaderCell>

          <Table.TextHeaderCell className="table apoio_fechamento" >
            Fechamento
          </Table.TextHeaderCell>

          <Table.TextHeaderCell className="table apoio_vgv" >
            VGV
          </Table.TextHeaderCell>

          <Table.TextHeaderCell className="table apoio_gerente">
            Gerente
          </Table.TextHeaderCell>

          <Table.TextHeaderCell className="table apoio_data">
            Data
          </Table.TextHeaderCell>

          <Table.TextHeaderCell className="table apoio_hora">
            Hora
          </Table.TextHeaderCell>

          <Table.TextHeaderCell className="table apoio_badge">
            {/* CAMPO COLUNA NOVO */}
          </Table.TextHeaderCell>

          <Table.TextHeaderCell className="table apoio_tools">
            {/*  CAMPO EXTRA PARA SIDE */}
          </Table.TextHeaderCell>
        </Table.Head>
        {
          //Enquanto não carregar os dados exibe um Spinner
          loading &&
          <div className='backgroundLoadingDocs'><Lottie options={defaultOptions} height={200} width={400} /></div>
        }
        {
          //Exibe os dados carregados
          !loading &&
          //Table de processos em andamento
          <>          
            <Table.Body>              
              {listaProcessos.map((processo, index) =>
                <Table.Row key={index} isSelectable className='overHidden'>
                  <Table.TextCell className="table apoio_resp" onClick={() => sideFunction(processo, index)}>
                    <Avatar hashValue={"id_" + processo.responsavel_id || 0} name={processo.posvenda ? 'Franquia' : processo.nome_responsavel} size={40} marginRight={16} />
                  </Table.TextCell>

                  <Table.TextCell className="table apoio_endereco" onClick={() => sideFunction(processo, index)}>
                    <div
                      className={classNovo[index] + ' endereco-imovel-' + processo.id}
                    >
                      {processo.logradouro}, {processo.numero} {processo.unidade != null ? '/ ' + processo.unidade : ''}</div>
                  </Table.TextCell>

                  {/* CAMPO DE COMISSÃO */}
                  <Table.TextCell className='table apoio_comissao' onClick={() => sideFunction(processo, index)}>
                    <div className={classNovo[index]}>
                      {processo.comissao_liquida || "---"}
                    </div>
                  </Table.TextCell>

                  {/* CAMPO DATA DE FECHAMENTO */}
                  <Table.TextCell className='table apoio_fechamento' onClick={() => sideFunction(processo, index)}>
                    <div className={classNovo[index]}>
                      {processo.fechamento || "---"}
                    </div>
                  </Table.TextCell>

                  {/* CAMPO VALOR DE VENDA */}
                  <Table.TextCell className='table apoio_vgv' onClick={() => sideFunction(processo, index)}>
                    <div className={classNovo[index]}>
                      {processo.valor_venda || "---"}
                    </div>
                  </Table.TextCell>

                  {/* CAMPO DE GERENTE */}
                  <Table.TextCell className='table apoio_gerente' onClick={() => sideFunction(processo, index)}>
                    <div className={classNovo[index]}>
                      {processo.nome_gerente || "---"}
                    </div>
                  </Table.TextCell>

                  {/* EXIBE CAMBO DATA DE CRIAÇÃO */}
                  <Table.TextCell className='table apoio_data' onClick={() => sideFunction(processo, index)}>
                    <div className={classNovo[index]}>
                      {processo?.data_hora?.split(' ')[0] || ''}
                    </div>
                  </Table.TextCell>

                  {/* EXIBE CAMBO HORA DE CRIAÇÃO */}
                  <Table.TextCell className='table apoio_hora' onClick={() => sideFunction(processo, index)}>
                    <div className={classNovo[index]}>
                      {processo?.data_hora ? (processo.data_hora.split(' ')[2]).substring(0,5) : ''}
                    </div>
                  </Table.TextCell>

                  {/* EXIBE CAMBO DE TAGS */}
                  <Table.TextCell className='table apoio_badge' onClick={() => sideFunction(processo, index)}>
                    <Pane>
                      {processo.status_visualizacao_apoio === 0
                        ? processo.status_anterior === 22
                          ? <Pane display='block'><Badge color="red" className={'novo-' + processo.progress_status_progresses_id}>DEVOLUÇÃO</Badge></Pane>
                          : <Pane display='block'><Badge color="green" className={'novo-' + processo.progress_status_progresses_id}>NOVO</Badge></Pane>
                        : processo.status_processo_atual === 7
                          ? <Pane display='block'><Badge color="neutral">Finalizados</Badge></Pane>
                          : ''
                      }
                      {processo.posvenda && <Tooltip content={processo.posvenda}><Badge color="blue" className='mt8'>FRANQUEADO</Badge></Tooltip>}
                    </Pane>
                  </Table.TextCell>

                  {/* CAMPO EXTRA PARA SIDE */}
                  <Table.TextCell className='table apoio_tools' onClick={() => sideFunction(processo, index)}>
                  </Table.TextCell>
                </Table.Row>
              )}
            </Table.Body>
          </>
        }
      </Table.Body>
    </Pane>
  )
}