import Axios from 'axios';
import Serve from '../app/serve';

async function getDashboardDiretor(filtro) {  
  const app = Serve();
  let data;

  await Axios.get(app + 'painel_diretor_comercial', {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }, params: {
      filtro_mes: filtro.ano ? filtro.mes : 0,
      filtro_ano: filtro.ano
    }
  })
    .then(res => {
      if (res?.data.status && (res.data.status === 498 || res.data.status === 401)) {
        localStorage.clear();
      } else {    
        if (res.data.mensagem) {
          data = {
            mes_vazio: res.data.mensagem,
          }
        } else {
          let total = 0;
          res.data.lojas.forEach(loja => {
            total = total + loja.gerentes.reduce((acc, value) => acc + value.total_entregues, 0)
          })       
          data = { 
            lojas: res.data.lojas,
            total_processos_entregues: total,
           };
        }
      }
    })
  return data;
}

export default getDashboardDiretor;