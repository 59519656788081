import React, { useState } from 'react';
import { Pane, Tablist, Tab, Card } from 'evergreen-ui';
import ValoresVenda from './VendaComponentes/ValoresVenda';
import Comissao from './VendaComponentes/Comissao';
import Alertas from './VendaComponentes/Alertas';
import PagamentoComissao from './VendaComponentes/Pagamento_comissao';

export default function Venda({ refreshProcess }) {
    const [selectedIndex, setSelectedIndex] = useState(0);    
    const [tabs] = useState(['Resumo da venda', 'Valores da Venda', 'Comissão', 'Pagamento da comissão']);


    return (
        <Pane>
            <Tablist>
                {tabs.map((tab, index) => (
                    <Tab
                        key={tab}
                        id={tab}
                        onSelect={() => setSelectedIndex(index)}
                        isSelected={index === selectedIndex}
                        aria-controls={`panel-${tab}`}
                    >
                        {tab}
                    </Tab>
                ))}
            </Tablist>
            {tabs.map((tab, index) => (
                <Card
                    key={tab}
                    id={`panel-${tab}`}
                    role="tabpanel"
                    className='mt20'
                    aria-labelledby={tab}
                    aria-hidden={index !== selectedIndex}
                    display={index === selectedIndex ? 'block' : 'none'}
                >
                    {tab === 'Resumo da venda' && index === selectedIndex && <Alertas refreshProcess={refreshProcess} setSelectedTabIndex={setSelectedIndex} />}
                    {tab === 'Valores da Venda' && index === selectedIndex && <ValoresVenda refreshProcess={refreshProcess} setSelectedTabIndex={setSelectedIndex} />}
                    {tab === 'Comissão' && index === selectedIndex && <Comissao refreshProcess={refreshProcess} setSelectedTabIndex={setSelectedIndex} />}
                    {tab === 'Pagamento da comissão' && index === selectedIndex && <PagamentoComissao refreshProcess={refreshProcess} setSelectedTabIndex={setSelectedIndex} />}
                </Card>
            ))}
        </Pane>
    )
}