import React, { useContext, useState } from 'react';
import { Badge, Pane, Table, Button, DownloadIcon, LockIcon, Tooltip } from 'evergreen-ui';
import ShowDocument from '../../../../../../../../../../app/showDocument';
import VendasContext from '../../../../../../../../../../context/VendasContext';
import PlanilhaComissaoPDF from '../../../../../../../../../../pdf/PlanilhaDeComissao';

function ColTable(props) {
    return (
        <Table.TextCell maxWidth={props.width} className="row">
            <div className={`mb7 color101840 field`}>{props.title}</div>

            <span className='fw400 color696F8C value'>
                {props.subtitle}
            </span>
        </Table.TextCell>
    )
};

function ColMapPorcentagens({ users }) {
    return (
        <Table.Row height={"auto"}>
            <Table.TextCell className="p10">
                <div className='flex row'>
                    <div className='mr17 w195 field'>Nome completo </div>
                    <div className="mr42 w97 field">Porcentagem</div>
                    <div className="mr42 w68 field">Desconto</div>
                    <div className='w97 field'>Valor</div>
                </div>

                {users?.map((user) => (
                    <div className='flex mb5 row'>
                        <div className='wsNormal mr17 w195 fw400 value'>
                            {user.name}
                        </div>
                        <div className="mr42 w97 fw400 value">
                            {user.porcentagem_comissao} %
                        </div>
                        <div className="mr42 w68 fw400 value">
                            {user.desconto || "---"}
                        </div>
                        <div className="fw400 w97 wsNormal value">
                            R$ {user.valor_real}
                        </div>
                    </div>
                ))}
            </Table.TextCell>
        </Table.Row>
    )
};

function ColMapParcelas({ parcelas, integral }) {
    const { listaPeriodo } = useContext(VendasContext);
    const labelPeriodo = (value) => {
        if(!value) return ""
        return listaPeriodo.find((item) => item.value === value).label
    };

    return (
        <Table.Row height={"auto"}>
            <Table.TextCell className="p10">
                <div className='flex row' style={{marginTop: '10px'}}>
                    <div hidden={integral} className='mr35 w60 field'>Parcelas </div>
                    <div hidden={integral} className="mr44 w97 field">Valor</div>
                    <div className="mr42 w229 field">Período do pagamento</div>
                </div>
                {parcelas.length === 0 && <div className='mb5'>---</div>}
                {parcelas?.map((parcela, index) => (
                    <div className='flex mb5 row'>
                        <div hidden={integral} className='wsNormal mr35 w60 fw400 value'>
                            {index + 1}
                        </div>
                        <div hidden={integral} className="mr44 w97 fw400 value">
                            {parcela.valor_parcela}
                        </div>
                        <div className="mr42 w229 fw400 value">
                            {labelPeriodo(parcela.periodo_pagamento)}
                        </div>
                    </div>
                ))}
            </Table.TextCell>
        </Table.Row>
    )
};

export default function Comissao() {
    //const [token] = useState(localStorage.getItem('token'));
    const msgCampo = '----';

    const {
        dadosVenda,
        tipoLaudemio,
    } = useContext(VendasContext);

    const { comissao } = dadosVenda;
    const gerentesGerais = dadosVenda.comissao_gerente_gerais;
    const gerentes = dadosVenda.comissao_gerentes;
    const corretores = comissao?.corretores_vendedores_comissao.map((user) => ({
        ...user, 'porcentagem_comissao': user.porcentagem_comissao_corretor
    }));
    const opcionistas = comissao?.corretores_opicionistas_comissao.map((user) => ({
        ...user, 'porcentagem_comissao': user.porcentagem_comissao_opcionista
    }));
    const planilha = comissao?.documentos.data.filter((documento) => documento.identifica_documento === "comissão");

    const percentualOpcionistas = (type) => {
        switch (opcionistas[0]?.tipo_laudo_opcionista) {
            case "simples":
                return type === "total" ? "16%" : 'Simples';
            case "com_chave":
                return type === "total" ? "18%" : 'Com chave';
            case "exclusividade":
                return type === "total" ? "20%" : 'Exclusividade';
            case "lançamento":
                return type === "total" ? "8%" : 'Lançamento';
            default:
                return "";
        }
    };

    //lê os laudemios retornados em dadosVenda
    const laudemiosEnviados = dadosVenda.laudemios.map(laudemio => parseInt(laudemio.valor_laudemio));

    //Comparar dadosVenda a uma lista de todos os laudemios "tipoLaudemio" e retorna o id e nomes para exibir
    const laudemioValorToName = tipoLaudemio.filter((laudemio) => laudemiosEnviados.includes(laudemio.id));
    const laudemiosSemNome = dadosVenda.laudemios.filter((laudemio) => laudemio.tipo_laudemio === "2" || laudemio.tipo_laudemio === "1");
    laudemioValorToName.push(...laudemiosSemNome);

    const capitalize = (text) => {
        if(!text) return ""
        const words = text.split(" ").map((word) => (
            word[0].toUpperCase() + word.substring(1)
        ))
        return words.join(" ");
    };

    return (
        <div className="detadelhes-sidesheet detalhes-rank container">
            {comissao === null
                ?
                <Table.Row>
                    <Table.TextCell className="historicoStatus" width={150}>
                        <Table.TextCell className="fontStatus">
                            <span>Nenhuma Comissão foi cadastrada no momento.</span>
                        </Table.TextCell>
                    </Table.TextCell>
                </Table.Row>

                :
                <>
                    <Pane>
                        <React.Fragment>
                            <h2 className="subtitle subtitle-table title" style={{marginTop: '0'}}>Dados de comissão</h2>
                        </React.Fragment>

                        <React.Fragment>
                            <h2 className="subtitle subtitle-table title">Total</h2>
                        </React.Fragment>
                    </Pane>

                    <Table.Row>
                        <ColTable title={"Tipo"} width="95px" subtitle={comissao.comissao === 'integral' ? "Integral" : "Parcelada"} />
                        <ColTable title={"Comissão total"} width="140px" subtitle={comissao?.valor_comissao_total || ''} />
                        <ColTable title={"Deduções"} subtitle={comissao.deducao !== null ? comissao.deducao : msgCampo} />
                    </Table.Row>

                    <ColMapParcelas parcelas={comissao.parcelas_empresa} integral={comissao.comissao === "integral"} />

                    <Pane>
                        <React.Fragment className="row">
                            <h2 className="subtitle subtitle-table title">Líquida</h2>
                        </React.Fragment>
                    </Pane>

                    <Table.Row>
                        <ColTable title={"Forma de Pagamento"} subtitle={capitalize(comissao?.liquida) || msgCampo} />
                        <ColTable title={"Comissão líquida"} subtitle={comissao?.valor_comissao_liquida || msgCampo} />
                    </Table.Row>

                    <div className="blue-line" style={{marginTop: '20px', marginBottom: '30px'}}></div>

                    {(!planilha || planilha.length === 0) &&
                        <Pane>
                            <Pane /*hidden={gerentesGerais.length <= 0}*/>
                                <h2 className="subtitle subtitle-table title">Comissão e porcentagens</h2>
                            </Pane>

                            <Pane className='mt16'>
                                <React.Fragment>
                                    <spam className="mr20 ttp">Gerentes Gerais</spam> {gerentesGerais.length > 0 && <Badge color="blue">{`Total de ${comissao?.valor_comissao_gg}%`}</Badge>}
                                </React.Fragment>
                                {/*dadosVenda.loja.gerente_geral_id === null &&
                                    <div className='color101840 fw400 mt15'>
                                        A loja {dadosVenda.loja.nome} não possui nenhum GG no momento, mas podem ter sido registrados um ou mais profissionais para receber a comissão equivalente.
                                </div>*/}
                            </Pane>

                            {gerentesGerais.length > 0 ?
                                <Pane className='mt16'>
                                    <ColMapPorcentagens users={gerentesGerais} />
                                </Pane>
                                : <div className="fw400 f12 mt10 row">
                                    <div className="field" style={{marginBottom: '25px'}}>
                                        A loja {dadosVenda.loja.nome} não possui nenhum GG no momento, mas podem ter sido registrados um ou mais profissionais para receber a comissão equivalente.
                                    </div>
                                </div>
                            }

                            <div className="blue-line" style={{marginTop: '20px', marginBottom: '30px'}}></div>

                            {gerentes.length > 0 &&
                                <Pane>
                                    <Pane className={"mt26"}>
                                        <React.Fragment>
                                            <spam className="mr20 ttp">Gerentes</spam> <Badge color="blue">Total de {comissao?.valor_comissao_gerente}%</Badge>
                                        </React.Fragment>
                                    </Pane>

                                    <Pane className='mt16'>
                                        <ColMapPorcentagens users={gerentes} />
                                    </Pane>
                                </Pane>
                            }

                            <div className="blue-line" style={{marginTop: '20px', marginBottom: '30px'}}></div>

                            {corretores.length > 0 &&
                                <Pane>
                                    <Pane className={
                                        gerentesGerais.length > 0 || gerentes.length > 0
                                            ? 'mt26'
                                            : 'mt16'
                                    }
                                    >
                                        <React.Fragment>
                                            <spam className="mr20 ttp">Corretores vendedores</spam> <Badge color="blue">Total de {corretores[0]?.total_comissao_corretor}%</Badge>
                                        </React.Fragment>
                                    </Pane>

                                    <Pane className='mt16'>
                                        <ColMapPorcentagens users={corretores} />
                                    </Pane>
                                </Pane>
                            }

                            <div className="blue-line" style={{marginTop: '20px', marginBottom: '30px'}}></div>

                            {opcionistas.length > 0 &&
                                <Pane>
                                    <Pane className='mt26'>
                                        <React.Fragment>
                                            <spam className="mr20 ttp">Opcionistas</spam> <Badge color="blue">Total de {percentualOpcionistas("total")}</Badge>
                                        </React.Fragment>
                                    </Pane>
                                    <Table.Row className='mt16'>
                                        <ColTable title={"Tipo de Laudo"} subtitle={percentualOpcionistas("tipo")} />
                                    </Table.Row>

                                    <Pane className='mt5'>
                                        <ColMapPorcentagens users={opcionistas} />
                                    </Pane>

                                </Pane>
                            }
                        </Pane>
                    }

                    <div className="blue-line" style={{marginTop: '20px', marginBottom: '30px'}}></div>

                    <Pane>
                        <React.Fragment>
                            <h2 className="subtitle subtitle-table">Observações</h2>
                        </React.Fragment>
                    </Pane>
                    <Table.Row className='hAuto'>
                        <Table.TextCell className="wsNormal hAuto">
                            <div className='fw400 pb5 pt5'>
                                {comissao.observacoes ? comissao.observacoes : msgCampo}
                            </div>
                        </Table.TextCell>
                    </Table.Row>
                </>
            }
        </div>
    )
}