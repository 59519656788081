import React, {useContext, useState, useEffect} from 'react';
import { Pane, Badge, SmallTickIcon, Tab } from 'evergreen-ui';

//Context
import VendasContext from '../../../../../../context/VendasContext';

export default function Steps(props) {

    const {
        dadosVenda,
        countMenu,
        posicaoBoletimDeVenda,
        setPosicaoBoletimDeVenda,
    } = useContext(VendasContext);

    console.log("countMenu", countMenu);

    const [stepImovel, setStepImovel] = useState('default')
    const [stepVendedor, setStepVendedor] = useState('default');
    const [stepComprador, setStepComprador] = useState('default');
    const [stepComissao, setStepComissao] = useState('default');

    const tabReciboNumber = dadosVenda.revisao?.recibo_sinal || 0;
    const tabImovelNumber = tabReciboNumber + (dadosVenda.revisao?.valores_documentos_imovel || 0);
    const tabVendedorNumber = tabImovelNumber + (dadosVenda.revisao?.vendedores || 0);
    const tabCompradorNumber = tabVendedorNumber + (dadosVenda.revisao?.compradores || 0);
    const tabComissaoNumber = tabCompradorNumber + dadosVenda.revisao?.comissao;

    useEffect(() => {
        const posicaoStep = async () => {
            if (posicaoBoletimDeVenda === 1) {                
                setStepImovel('active');
                setStepVendedor('default');
                setStepComprador('default');
                setStepComissao('default');
            }
            else if (posicaoBoletimDeVenda === 2) {
                setStepImovel('default');
                setStepVendedor('active');
                setStepComprador('default');
                setStepComissao('default');
            }
            else if (posicaoBoletimDeVenda === 3) {
                setStepImovel('default');
                setStepVendedor('default');
                setStepComprador('active');
                setStepComissao('default');
            }
            else {
                setStepImovel('default');
                setStepVendedor('default');
                setStepComprador('default');
                setStepComissao('active');
            }
        }
        posicaoStep();    
    }, [posicaoBoletimDeVenda]);
    
    return (
        <Pane>
            <div className="steps">
                {
                    //Tab Recibo de Sinal DEVOLVIDO
                    dadosVenda.status_processo === 22
                        && dadosVenda.revisao.recibo_sinal === 1
                            ?
                            <Tab
                                isSelected={posicaoBoletimDeVenda === 0}                                
                                onSelect={() => countMenu >= 0 ? setPosicaoBoletimDeVenda(0) : ""}                        
                                style={{ cursor: countMenu < 0 || posicaoBoletimDeVenda === 0 ? 'auto' : 'pointer' }}
                            >
                                <div className="step-item">
                                    <Badge
                                        className={/*posicaoBoletimDeVenda*/
                                            dadosVenda.revisao.recibo_sinal_save === 1 ? 'badge-check' : 'badge-active'
                                        }
                                    >
                                        {/*countMenu > 1*/ dadosVenda.revisao.recibo_sinal_save === 1 ? <SmallTickIcon className="step-check" /> : <>&nbsp;{tabReciboNumber}&nbsp;</>}
                                    </Badge>
                                    <span className={stepImovel}>Upload do Recibo de Sinal</span>
                                </div>
                            </Tab>
                            :
                            ''
                }

                {
                    //Tab Imóvel
                    dadosVenda.status_processo === 22
                    ? dadosVenda.revisao.valores_documentos_imovel === 1
                        ?
                        <Tab
                            isSelected={posicaoBoletimDeVenda === 1}                            
                            onSelect={() => countMenu >= 1 ? setPosicaoBoletimDeVenda(1) : ""}                        
                            style={{ cursor: countMenu < 1 || posicaoBoletimDeVenda === 1 ? 'auto' : 'pointer' }}
                        >
                            <div className="step-item">
                                <Badge
                                    className={/*posicaoBoletimDeVenda*/ 
                                                dadosVenda.revisao.valores_documentos_imovel_verific_save === 1 ? 'badge-check' : 'badge-active'
                                            }
                                >
                                    {/*countMenu > 1*/ dadosVenda.revisao.valores_documentos_imovel_verific_save === 1 ? <SmallTickIcon className="step-check" /> : <> &nbsp;{tabImovelNumber}&nbsp; </>}
                                </Badge>
                                <span className={stepImovel}>Valores e Documentos</span>
                            </div>
                        </Tab>
                        :
                        ''
                     
                    : 
                    <Tab
                        isSelected={posicaoBoletimDeVenda === 1}
                        onSelect={() => setPosicaoBoletimDeVenda(1)}
                    >
                        <div className="step-item">
                            <Badge
                                className={posicaoBoletimDeVenda > 1 ? 'badge-check' : 'badge-active'}
                            >
                                {countMenu > 1 ? <SmallTickIcon className="step-check" /> : <> &nbsp;1&nbsp; </>}
                                {/* {posicaoBoletimDeVenda > 1 ? <SmallTickIcon className="step-check" /> : <> &nbsp;1&nbsp; </>} */}
                            </Badge>
                            <span className={stepImovel}>Valores e Documentos do Imóvel</span>
                        </div>
                    </Tab>
                }


                {
                    //Tab Vendedores
                    dadosVenda.status_processo === 22
                    ? dadosVenda.revisao.vendedores === 1
                       ?
                       <Tab
                            isSelected={posicaoBoletimDeVenda === 2}
                            onSelect={() => countMenu >= 2 ? setPosicaoBoletimDeVenda(2) : ""}
                            style={{ cursor: countMenu < 2 || posicaoBoletimDeVenda === 2 ? 'auto' : 'pointer' }}
                        >
                            <div className="step-item">
                                <Badge
                                    //className={countMenu === 2 ? "badge-active" : countMenu > 2 ? 'badge-check' : 'badge-default'}
                                    className={
                                        dadosVenda.revisao.vendedores_verific_save === 1 ? 'badge-check' : 'badge-active'
                                    }
                                >
                                    {dadosVenda.revisao.vendedores_verific_save === 1 ? <SmallTickIcon className="step-check" /> : <> &nbsp;{tabVendedorNumber}&nbsp; </>}
                                </Badge>
                                <span className={stepVendedor}>Vendedor</span>
                            </div>
                        </Tab>
                        :
                        ''

                    :
                    <Tab
                        isSelected={posicaoBoletimDeVenda === 2}
                        onSelect={() => countMenu >= 2 ? setPosicaoBoletimDeVenda(2) : ""}
                        style={{ cursor: countMenu < 2 || posicaoBoletimDeVenda === 2 ? 'auto' : 'pointer' }}
                    >
                        <div className="step-item">
                            <Badge
                                className={countMenu === 2 ? "badge-active" : countMenu > 2 ? 'badge-check' : 'badge-default'}
                            >
                                {countMenu > 2 ? <SmallTickIcon className="step-check" /> : <> &nbsp;2&nbsp; </>}
                                {/* {countMenu > 2 && posicaoBoletimDeVenda !== 2 ? <SmallTickIcon className="step-check" /> : <> &nbsp;2&nbsp; </>} */}
                            </Badge>
                            <span className={stepVendedor}>Vendedor</span>
                        </div>
                    </Tab>
                }
                

                {
                    //Tab compradores
                    dadosVenda.status_processo === 22
                    ? dadosVenda.revisao.compradores === 1
                       ?
                       <Tab
                            isSelected={posicaoBoletimDeVenda === 3}
                            onSelect={() => countMenu >= 3 ? setPosicaoBoletimDeVenda(3) : ""}
                            style={{ cursor: countMenu < 3 || posicaoBoletimDeVenda === 3 ? 'auto' : 'pointer' }}
                        >
                            <div className="step-item">
                                <Badge
                                    //className={countMenu === 3 ? "badge-active" : countMenu > 3 ? 'badge-check' : 'badge-default'}
                                    className={
                                        dadosVenda.revisao.compradores_verific_save === 1 ? 'badge-check' : 'badge-active'
                                    }
                                >
                                    {dadosVenda.revisao.compradores_verific_save === 1 ? <SmallTickIcon className="step-check" /> : <> &nbsp;{tabCompradorNumber}&nbsp; </>}
                                </Badge>
                                <span className={stepComprador}>Comprador</span>
                            </div>
                        </Tab>
                        :
                        ''

                    :
                    <Tab
                        isSelected={posicaoBoletimDeVenda === 3}
                        onSelect={() => countMenu >= 3 ? setPosicaoBoletimDeVenda(3) : ""}
                        style={{ cursor: countMenu < 3 || posicaoBoletimDeVenda === 3 ? 'auto' : 'pointer' }}
                    >
                        <div className="step-item">
                            <Badge
                                className={countMenu === 3 ? "badge-active" : countMenu > 3 ? 'badge-check' : 'badge-default'}
                            >
                                {countMenu > 3 ? <SmallTickIcon className="step-check" /> : <> &nbsp;3&nbsp; </>}
                                {/* {countMenu > 3 && posicaoBoletimDeVenda !== 3 ? <SmallTickIcon className="step-check" /> : <> &nbsp;3&nbsp; </>} */}
                            </Badge>
                            <span className={stepComprador}>Comprador</span>
                        </div>
                    </Tab>
                }
                
                {
                    //Tab comissão
                    dadosVenda.status_processo === 22
                    ? dadosVenda.revisao.comissao === 1
                       ?
                       <Tab
                            isSelected={posicaoBoletimDeVenda === 4}
                            onSelect={() => countMenu >= 4 ? setPosicaoBoletimDeVenda(4) : ""}
                            style={{ cursor: countMenu < 4 || posicaoBoletimDeVenda === 4 ? 'auto' : 'pointer' }}
                        >
                            <div className="step-item">
                                <Badge
                                    //className={countMenu === 4 ? "badge-active" : countMenu > 4 ? 'badge-check' : 'badge-default'}
                                    className={
                                        dadosVenda.revisao.comissao_verific_save === 1 ? 'badge-check' : 'badge-active'
                                    }
                                >
                                    {dadosVenda.revisao.comissao_verific_save === 1 ? <SmallTickIcon className="step-check" /> : <> &nbsp;{tabComissaoNumber}&nbsp; </>}
                                </Badge>
                                <span className={stepComissao}>Comissão</span>
                            </div>
                        </Tab>
                        :
                        ''

                    :
                    <Tab
                        isSelected={posicaoBoletimDeVenda === 4}
                        onSelect={() => countMenu >= 4 ? setPosicaoBoletimDeVenda(4) : ""}
                        style={{ cursor: countMenu < 4 || posicaoBoletimDeVenda === 4 ? 'auto' : 'pointer' }}
                    >
                        <div className="step-item">
                            <Badge
                                className={countMenu === 4 ? "badge-active" : countMenu > 4 ? 'badge-check' : 'badge-default'}
                            >
                                {countMenu > 4 ? <SmallTickIcon className="step-check" /> : <>&nbsp;4&nbsp;</>}
                            </Badge>
                            <span className={stepComissao}>Comissão</span>
                        </div>
                    </Tab>
                }
                
            </div>
        </Pane>
    )
}