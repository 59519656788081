import React, {useContext, useEffect} from 'react';
import { Dialog, Pane, LockIcon } from 'evergreen-ui';
import VendasContext from '../../../../../../../context/VendasContext';
import Processo from './components/Processo';
import Processos from './components/Processos';
import getGerentePerguntasEscritura from '../../../../../../../apis/getGerentePerguntasEscritura';

export default function DialogConfirmacaoEscritura() {

    const {
        modalEscritura, setModalEscritura,
        showListaEscritura, setShowListaEscritura,
        listaEscritura, setListaEscritura,
        selectProcessoEscritura, setSelectProcessoEscritura,
        setPerguntasEscritura,
    } = useContext(VendasContext);

    useEffect(() => {
        const retornaPerguntas = async () => {
            // Perguntas
            const perguntas = await getGerentePerguntasEscritura();
            setPerguntasEscritura(perguntas);
        }
        retornaPerguntas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // if(selectProcessoEscritura === undefined && listaEscritura.length > 0) {
    //     setSelectProcessoEscritura(listaEscritura[0])
    // }

    return (
        <Pane>
            <Dialog
                shouldCloseOnOverlayClick={false}
                // onCancel={false}
                hasHeader={false}
                hasFooter={false}
                isShown={modalEscritura}
                onCloseComplete={() => setModalEscritura(false)}
                width={809}
                topOffset={22}
                preventBodyScrolling={true}
                //minHeightContent={900}
                containerProps={{
                    className: 'dialog-escritura'
                }}
            >
                <div className="form-dialog mb40">
                    
                    <div className="header">
                        <div className="p20">
                            <h1 style={{ marginBottom: "0", fontWeight: "600" }}>
                                {
                                    !!showListaEscritura
                                    ? 'Precisamos saber como foi a Escritura'
                                    : selectProcessoEscritura?.data_escritura + ' - ' + selectProcessoEscritura?.logradouro
                                }
                            </h1>

                            <LockIcon size={16} color={'#8F95B2'} />
                        </div>
                    </div>

                    <div className='content'>
                        {   
                            !!showListaEscritura
                            ? <Processos />
                            : <Processo />
                        }
                    </div>

                </div>
            </Dialog>
        </Pane>
    )
}
