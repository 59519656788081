import Axios from 'axios';
import Serve from '../app/serve';

async function SaveReciboSinal(data, setProgressBar) {
  const token = localStorage.getItem('token');
  const app = Serve();
  let result;

  await Axios.post(app + 'salvar_recibo_posvenda', data, {
    headers: { Authorization: `Bearer ${token}` },
    onUploadProgress: (progressEvent) => {
      const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      setProgressBar({percent: percentage, status: 'active'});
    },
  })
    .then(res => {
      result = res;
      console.log(res);
      setProgressBar({percent: 100, status: 'success'}); 
    })
    .catch(function (error) {
      console.log(error);
      setProgressBar({percent: 0, status: 'error'});
    })

  return result;
};

export default SaveReciboSinal;