import React, { useContext, useState } from 'react';
import {
    Pane,
    Dialog,
    TextInputField,
    FilePicker,
    CloudUploadIcon,
    Button,
    PlusIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    //Select,
    SelectField,
    Checkbox,
    EditIcon,
    Textarea,
    Label,
    DeleteIcon,
    RadioGroup,
} from 'evergreen-ui';

//Context
import PosNegociacaoContext from '../../../../../../context/PosNegociacaoContext';

//Components
//import Axios from 'axios';
//import { useForm } from "react-hook-form";
import Steps from './Steps';
//import cepMask from '../../../../../../components/cepMask';
//import somenteNumero from '../../../../../../components/somenteNumero';
import dateMask from '../../../../../../components/dateMask';
//import horaMask from '../../../../../../components/horaMask';
import formatoMoeda from '../../../../../../components/formatoMoeda';

export default function Comissao() {
    const {
        isShowFormEspelhoVenda, setIsShowFormEspelhoVenda,
        posicaoEspelhoVenda, setPosicaoEspelhoVenda,
        dadosRecibo,
        tipoDocumentos, setTipoDocumentos,
        validateInputFile, setValidateInputFile,
    } = useContext(PosNegociacaoContext);

    console.log(posicaoEspelhoVenda);

    const [totalRadius, setTotalRadius] = useState('null');
    const [liquidaRadius, setLiquidaRadius] = useState('null');

    const [total] = React.useState([
        { label: 'Integral', value: 'integral' },
        { label: 'Partes', value: 'partes' },
    ])

    const [liquida] = React.useState([
        { label: 'Espécie', value: 'especie' },
        { label: 'Depósito', value: 'deposito' },
        { label: 'TED/DOC', value: 'ted_doc' },
        //{ label: 'PIX', value: 'pix' },
        { label: 'Cheque/Cheque Administrativo', value: 'cheque' }
    ])

    const [checkedPrimeiraParte, setCheckedPrimeiraParte] = React.useState(false)
    const [checkedSegundaParte, setCheckedSegundaParte] = React.useState(false)

    const [comissaoTotal, setComissaoTotal] = useState('');
    const [validateRadiusTotal, setValidateRadiusTotal] = useState(false);
    const [validateRadiusLiquida, setValidateRadiusLiquida] = useState(false);
    const [validateComissaoTotal, setValidateComissaoTotal] = useState(false);
    const [validateComissaoLiquida, setValidateComissaoLiquida] = useState(false);
    const [formValues, setFormValues] = useState([{ tipoDocumento: "", documento: "" }])

    const closeEspelhoVenda = (e) => {
        setIsShowFormEspelhoVenda(false);
        setPosicaoEspelhoVenda(1);
    }

    const handleTotalComissao = (e) => {
        setValidateRadiusTotal(true);
        
        setTotalRadius(e.target.value);

        if (totalRadius === 'integral') {
            setCheckedPrimeiraParte(false);
            setCheckedSegundaParte(false);
            setValidateComissaoTotal(true);
            console.log(validateComissaoTotal);
        }
        else {           
            setComissaoTotal('');
            setValidateComissaoTotal(false);
            console.log(validateComissaoTotal);
        }
    }

    const handleLiquidaComissao = (e) => {
        setLiquidaRadius(e.target.value);
        setValidateRadiusLiquida(true);
    }

    const handleComissaoTotal = () => {
        let valorComissaoTotal = document.querySelector('.espelho-venda-comissao-total-1');
        setComissaoTotal(valorComissaoTotal);

        if (valorComissaoTotal.value.length > 0) {
            formatoMoeda(valorComissaoTotal.value);
            valorComissaoTotal.value = formatoMoeda(valorComissaoTotal.value);
            setValidateComissaoTotal(true);  
        }
        else {
            setValidateComissaoTotal(false);
        }
    }

    const handleComissaoLiquida = (e) => {
        let comissaoLiquida = document.querySelector('.espelho-venda-comissao-liquida-1');

        if (comissaoLiquida.value.length > 0) {
            formatoMoeda(comissaoLiquida.value);
            comissaoLiquida.value = formatoMoeda(comissaoLiquida.value);

            setValidateComissaoLiquida(true)
        }
        else {
            setValidateComissaoLiquida(false)
        }

    }

    const handleDataParteComissao = (e) => {
        let dataParteComissao = document.querySelector('.espelho-venda-data-parte-comissao-1');

        if (dataParteComissao.value.length >= 8) {
            dateMask(dataParteComissao.value);
            dataParteComissao.value = dateMask(dataParteComissao.value);

            //setValidateFieldDataVenda(true);
        }
        else {
            //setValidateFieldDataVenda(false);
        }
    }

    const handleComissaoPrimeiraParte = () => {
        let comissaoPrimeiraParte = document.querySelector('.espelho-venda-comissao-primeira-parte-1');

        if (comissaoPrimeiraParte.value.length > 0) {
            formatoMoeda(comissaoPrimeiraParte.value);
            comissaoPrimeiraParte.value = formatoMoeda(comissaoPrimeiraParte.value);

            //setValidateFieldValorEstimado(true);
        }
        else {
            //setValidateFieldValorEstimado(false);
        }
    }

    const handleComissaoSegundaParte = () => {
        let comissaoSegundaParte = document.querySelector('.espelho-venda-comissao-segunda-parte-1');

        if (comissaoSegundaParte.value.length > 0) {
            formatoMoeda(comissaoSegundaParte.value);
            comissaoSegundaParte.value = formatoMoeda(comissaoSegundaParte.value);

            //setValidateFieldValorEstimado(true);
        }
        else {
            //setValidateFieldValorEstimado(false);
        }
    }

    const handleDeducoes = () => {
        let deducoes = document.querySelector('.espelho-venda-deducoes-1');

        if (deducoes.value.length > 0) {
            formatoMoeda(deducoes.value);
            deducoes.value = formatoMoeda(deducoes.value);

            //setValidateFieldValorEstimado(true);
        }
        else {
            //setValidateFieldValorEstimado(false);
        }
    }

    //Adcionando e removendo campos de Documento
    const handleChangeTipoDocumento = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i]['tipoDocumento'] = e.target.value;
        setFormValues(newFormValues);
        console.log(formValues);

        /*if (formValues.length > 0) {
            setValidateInputFile(true);   
        }
        else {
            setValidateInputFile(false);
        }*/
    }

    const handleChangeDocumento = (i, e) => {
        let file = document.querySelector('.upload-' + i + ' input[type="file"]').files[0];

        let newFormValues = [...formValues];
        newFormValues[i]['documento'] = file.name;
        setFormValues(newFormValues);
        console.log(formValues);

        /*if (formValues.length > 0) {
            setValidateInputFile(true);   
        }
        else {
            setValidateInputFile(false);
        }*/
    }

    const addFormFields = (e) => {
        e.preventDefault();
        setFormValues([...formValues, { tipoDocumento: "", documento: "" }])

        console.log(formValues);
    }

    const removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
        console.log(formValues);
    }

    /*const handleSubmit = (event) => {
        event.preventDefault();
        alert(JSON.stringify(formValues));
    }*/

    function validarSubmit() {
        if (
            //validateInputFile === true
            validateRadiusTotal === true &&
            validateRadiusLiquida === true &&
            validateComissaoTotal === true &&
            validateComissaoLiquida === true
        ) {
            return 'enabled';
        }
        else {
            return 'disabled';
        }
    }
    validarSubmit();

    const clearStateValidates = async () => {
        
    }

    /*Ações dos Botões*/
    const handleSalvarContinuar = (e) => {
        e.preventDefault();
        console.log('Salvar e Continuar');

        clearStateValidates();
    }

    const handleBtnAnterior = (e) => {
        e.preventDefault();
        console.log('Anterior');

        clearStateValidates();
        setPosicaoEspelhoVenda(3);
    }

    const handleBtnAvancar = (e) => {
        e.preventDefault();
        console.log('Avançar');

        const dadosImovel = {
            total: totalRadius,
            comissaoTotal: comissaoTotal.value,//document.querySelector('.espelho-venda-comissao-total-1').value,
            deducoes: document.querySelector('.espelho-venda-deducoes-1').value,
            partePagamento: document.querySelector('.espelho-venda-parte-pagamento').value,
            comissaoParteUm: document.querySelector('.espelho-venda-comissao-primeira-parte-1').value,
            comissaoParteDois: document.querySelector('.espelho-venda-comissao-segunda-parte-1').value,
            dataParteComissao: document.querySelector('.espelho-venda-data-parte-comissao-1').value,
            pagamento: liquidaRadius,
            comissaoLiquida: document.querySelector('.espelho-venda-comissao-liquida-1').value,
            arquivos: formValues,
            observacao: document.querySelector('.espelho-venda-observacao-1').value,
        }
        console.log(dadosImovel);

        //setPosicaoEspelhoVenda(2);
    }

    return (
        <Pane>
            <Dialog
                width={808}
                isShown={isShowFormEspelhoVenda}
                title=''
                onCloseComplete={(e) => closeEspelhoVenda(e)}
                hasFooter={false}
                preventBodyScrolling
                shouldCloseOnOverlayClick={false}
                containerProps={{
                    className: 'form-dialog-props'
                }}
            >
                <Steps />

                <div className="form-dialog">
                    <h4 style={{ marginTop: '15px' }}>Dados de Comissão</h4>
                    <p className="descritption" style={{ marginTop: '15px' }}>Preencha os dados de comissão do imóvel.</p>

                    <div className="formGroup formAdd">
                        <form id="formGroup" encType="multipart/form-data">
                            <Pane>
                                <div className="row-recibo">
                                    <div className="subtitle">Total</div>
                                    <div className="rowForm line-radius">
                                        <RadioGroup
                                            label=""
                                            size={16}
                                            value={totalRadius}
                                            options={total}
                                            onChange={(e) => handleTotalComissao(e)}
                                            className="radius"
                                        />
                                    </div>

                                    <div className="rowForm">
                                        {
                                            totalRadius === 'integral' || totalRadius === 'null'
                                                ?
                                                <TextInputField
                                                    width={150}
                                                    label="Comissão total"
                                                    description=""
                                                    placeholder="R$ 500.000,00"
                                                    defaultValue={comissaoTotal}
                                                    id={"espelho-venda-comissao-total-1"}
                                                    className={"espelho-venda-comissao-total-1"}
                                                    onChange={(e) => handleComissaoTotal(e)}
                                                    disabled={totalRadius === 'integral' ? false : true}    
                                                    required
                                                />
                                                
                                                :
                                                <>
                                                    <div>
                                                        <Checkbox
                                                            label="1º Parte"
                                                            checked={checkedPrimeiraParte}
                                                            onChange={e => setCheckedPrimeiraParte(e.target.checked)}
                                                            className="checkbox checkox-label"
                                                        />
                                                        <TextInputField
                                                            width={150}
                                                            label=""
                                                            description=""
                                                            placeholder="R$ 500.000,00"
                                                            defaultValue=""
                                                            id={"espelho-venda-comissao-primeira-parte-1"}
                                                            className={"espelho-venda-comissao-primeira-parte-1"}
                                                            onChange={(e) => handleComissaoPrimeiraParte(e)}
                                                            disabled={checkedPrimeiraParte === true ? false : true}
                                                        />
                                                    </div>

                                                    <div>
                                                        <Checkbox
                                                            label="2º Parte"
                                                            checked={checkedSegundaParte}
                                                            onChange={e => setCheckedSegundaParte(e.target.checked)}
                                                            className="checkbox checkox-label"
                                                        />
                                                        <TextInputField
                                                            width={150}
                                                            label=""
                                                            description=""
                                                            placeholder="R$ 500.000,00"
                                                            defaultValue=""
                                                            id={"espelho-venda-comissao-segunda-parte-1"}
                                                            className={"espelho-venda-comissao-segunda-parte-1"}
                                                            onChange={(e) => handleComissaoSegundaParte(e)}
                                                            disabled={checkedSegundaParte === true ? false : true}
                                                        />
                                                    </div>
                                                    <input
                                                        type="hidden"
                                                        name="espelho-venda-parte-pagamento"
                                                        className="espelho-venda-parte-pagamento"
                                                        value={
                                                            checkedPrimeiraParte === true && checkedSegundaParte === false ? '1'
                                                                :
                                                                checkedPrimeiraParte === false && checkedSegundaParte === true ? '2'
                                                                    :
                                                                    checkedPrimeiraParte === true && checkedSegundaParte === true ? '1,2'
                                                                        :
                                                                        ''
                                                        }
                                                    />

                                                    {checkedSegundaParte === true ?
                                                        <div className={checkedSegundaParte === true ? 'field-parte-pagamento' : ''}>
                                                            <TextInputField
                                                                width={115}
                                                                marginBottom={0}
                                                                marginLeft={20}
                                                                label="Data"
                                                                description=""
                                                                placeholder="DD/MM/AAAA"
                                                                defaultValue=""
                                                                id={"espelho-venda-data-parte-comissao-1"}
                                                                className={"espelho-venda-data-parte-comissao-1"}
                                                                onChange={(e) => handleDataParteComissao(e)}
                                                                maxLength={10}
                                                            />
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </>
                                        }

                                        <div
                                            className={totalRadius === 'partes' ? 'field-parte-pagamento' : ''}
                                            style={{ marginLeft: totalRadius === 'partes' ? '20px' : '' }}
                                        >
                                            <TextInputField
                                                width={150}
                                                label="Deduções"
                                                description=""
                                                placeholder="R$ 500.000,00"
                                                defaultValue=""
                                                id={"espelho-venda-deducoes-1"}
                                                className={"espelho-venda-deducoes-1"}
                                                onChange={(e) => handleDeducoes(e)}
                                                disabled={totalRadius !== 'null' ? false : true}
                                                style={{ marginLeft: checkedSegundaParte === true ? '0' : '' }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row-recibo">
                                    <div className="subtitle">Líquida</div>
                                    <div className="rowForm line-radius">
                                        <RadioGroup
                                            label=""
                                            size={16}
                                            value={liquidaRadius}
                                            options={liquida}
                                            onChange={(e) => handleLiquidaComissao(e)}
                                            className="radius espelho-venda-forma-pagamento-1"
                                        />
                                    </div>

                                    <div className="rowForm">
                                        <TextInputField
                                            width={150}
                                            label="Comissão líquida"
                                            description=""
                                            placeholder="R$ 100.000,00"
                                            defaultValue=""
                                            id={"espelho-venda-comissao-liquida-1"}
                                            className={"espelho-venda-comissao-liquida-1"}
                                            onChange={(e) => handleComissaoLiquida(e)}
                                            disabled={liquidaRadius !== 'null' ? false : true}
                                            required
                                        />
                                    </div>
                                    
                                </div>

                                <div className="row-recibo" style={{ marginTop: '35px' }}>
                                    <div className="subtitle">
                                        <CloudUploadIcon className="icon-subtitle" />
                                        Upload de documentos de venda*
                                    </div>

                                    <p className="descritption description-upload">Atenção: subir arquivos no formato .doc ou .pdf. <br /> Para aprender a converter uma foto para pdf., <a href="https://www.tecmundo.com.br/apps/72485-apps-converter-documentos-para-pdf-celulares-tablets.htm" target="_blank" rel="noreferrer" title="Converter foto para PDF">clique aqui</a>.</p>

                                    {formValues.map((e, index) => (
                                        <div className={"rowForm upload-espelho-venda upload-" + index} key={index}>
                                            <SelectField
                                                value={e.tipoDocumento || ""}
                                                onChange={e => handleChangeTipoDocumento(index, e)}
                                                name="tipoDocumento"
                                                className={'documento-imovel espelho-venda-imovel-tipo-documento espelho-venda-documento-imovel-novo-' + index}
                                                style={{ marginBottom: 0 }}
                                                id={'espelho-venda-documento-imovel-novo-' + index}
                                                data-documento={'tipo-documento-novo-' + index}
                                                width={216}
                                                marginBottom={0}
                                                padding={0}
                                            >
                                                <option value="0" hidden>Selecione o tipo</option>
                                                {
                                                    tipoDocumentos.filter(tipoDocumento => tipoDocumento.tipo === "imóvel")
                                                        .map(tipoDocumento => <option value={tipoDocumento.id}>{tipoDocumento.nome}</option>)
                                                }
                                            </SelectField>

                                            <FilePicker
                                                width={250}
                                                placeholder="nome.arquivo"
                                                type="file"
                                                name="documento"
                                                value={e.documento || ""}
                                                onChange={e => handleChangeDocumento(index, e)}
                                                className={"file-input documento-" + index}
                                            />
                                            {
                                                index ?
                                                    <Button
                                                        marginRight={16}
                                                        appearance="primary"
                                                        intent="danger"
                                                        className="button remove"
                                                        style={{ left: '75px' }}
                                                        onClick={() => removeFormFields(index)}
                                                        type="button"
                                                    >
                                                        <DeleteIcon
                                                            size={10}
                                                            style={{ 'marginRight': '10px', 'fill': '#fff' }}
                                                        /> Excluir
                                                    </Button>
                                                    : null
                                            }
                                        </div>
                                    ))}

                                    <Button
                                        className="btn-add-documento"
                                        onClick={(e) => addFormFields(e)}
                                    >
                                        <PlusIcon className="icon-label" />
                                        Adicionar mais documentos
                                    </Button>
                                </div>

                                <div className="row-recibo" style={{ marginTop: '35px' }}>
                                    <div className="subtitle">
                                        <EditIcon className="icon-subtitle" />
                                        Observações
                                    </div>

                                    <Label htmlFor="textarea-2" marginBottom={12} display="block">
                                        Caso necessário, insira observações para que o backoffice fique atento.
                                    </Label>

                                    <Textarea
                                        name="textarea-1"
                                        className="espelho-venda-observacao-1"
                                        id="recibo-observacao-1"
                                        placeholder="Exemplo: O imóvel se encontra em inventário e o comprador só está disponível para contato de 13h às 18h. "
                                        defaultValue=""
                                        height={110}
                                    />
                                </div>

                                <div className="mensagem-campos msg-obrigatorio">*Campos obrigatórios</div>

                                <div className="footer-form">
                                    <div>
                                        <Button className="btn-minimal btn-minimal-disabled" appearance="default" disabled onClick={(e) => handleSalvarContinuar(e)}>
                                            Salvar e continuar depois
                                        </Button>
                                    </div>

                                    <div>
                                        <Button appearance="default" marginLeft={15} onClick={(e) => handleBtnAnterior(e)}>
                                            <ChevronLeftIcon className="icon-label" style={{ margin: '0 5px 0 0' }} />
                                            Anterior
                                        </Button>

                                        <Button
                                            type="submit"
                                            disabled={validarSubmit() === 'enabled' ? false : true}
                                            appearance="primary"
                                            marginLeft={15}
                                            onClick={(e) => handleBtnAvancar(e)}
                                        >
                                            Avançar
                                            <ChevronRightIcon className="icon-save" />
                                        </Button>
                                    </div>
                                </div>
                            </Pane>
                        </form>
                    </div>
                </div>
            </Dialog>
        </Pane>
    )
}