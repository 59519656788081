import React from "react";
import {
    BrowserRouter,
    Switch,
    Route,
} from "react-router-dom";

//import Home from './pages/Home';
//import NotFound404 from "./pages/NotFound404";
import NotFound404 from "./pages/AcessoTemporario/AcompanharStatus/components/Pagina404";
import Logon from './pages/Logon';
import RedefinirSenha from './pages/Logon/RedefinirSenha';
import ResetLogin from './pages/Logon/ResetLogin';
import Nucleo from './pages/Nucleo';
import PosVenda from './pages/PosVenda';
import CoordPosVenda from './pages/CoordPosVenda';
import PosNegociacao from './pages/PosNegociacao';
import ReciboDeSinal from './pages/Documento/ReciboDeSinal';
import Vendas from './pages/Vendas';
import Direcao from './pages/DiretorComercial';
import PainelAdmin from './pages/PainelAdmin';
import Apoio from './pages/Apoio';
import AcessoTemporario from "./pages/AcessoTemporario";
import AcompanharStatus from "./pages/AcessoTemporario/AcompanharStatus";
import Financeiro from "./pages/Financeiro";

export default function App() {
    return (
        <BrowserRouter basename="/">
            <div>
                <Switch>
                    <Route path="/" exact component={Logon} />
                    <Route path="/redirecionando/:data" component={Logon} />
                    <Route path="/login-help" exact component={RedefinirSenha} />
                    <Route path="/redefinir=:token" component={ResetLogin} />
                    
                    <Route path="/negociacoes" exact component={PosVenda} />
                    <Route path="/coordenacao-posvenda" exact component={CoordPosVenda} />
                    <Route path="/nucleo" exact component={Nucleo} />
                    <Route path="/pos-negociacao" exact component={PosNegociacao} />
                    <Route path="/painel-admin" exact component={PainelAdmin} />
                    <Route path="/apoio" exact component={Apoio} />
                    <Route path="/financeiro" exact component={Financeiro} />
                    <Route path="/vendas" exact component={Vendas} />
                    <Route path="/comercial" exact component={Direcao} />
                    
                    <Route path="/documento/recibo-de-sinal" exact component={ReciboDeSinal} />
                    {/*Acesso temporário*/}
                    {/*<Route path="/acesso-temporario/:id"  component={AcessoTemporario} />*/}
                    {/*<Route path="/acesso-temporario/"  component={AcessoTemporario} />*/}
                    <Route path="/acesso?cod=codProcesso"  component={AcessoTemporario} />
                    <Route path="/acesso"  component={AcessoTemporario} />
                    <Route path="/acompanhar-status-imovel" component={AcompanharStatus} />                    
                    
                    <Route path="*" exact component={NotFound404} />
                </Switch>
            </div>
        </BrowserRouter>
    );
}
