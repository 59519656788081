import React, {useContext, useState} from 'react'
import { Pane, Link, Button, EditIcon } from 'evergreen-ui';
import VendasContext from '../../../../../../../../../../context/VendasContext';
import ShowDocument from '../../../../../../../../../../app/showDocument';

export default function Venda() {
    const {
        dadosVenda, 
        listLaudemios,
        setPosicaoMinuta,
        listEscrituras,
        setCountMenu,
        setIsShowFormMinuta,
        setIsShowEntregarVenda,
    } = useContext(VendasContext);
    //const [token] = useState(localStorage.getItem('token'));

    const typeLaudemios = [
        { id: '1', nome: 'União' },
        { id: '2', nome: 'Prefeitura' },
        { id: '3', nome: 'Familia do imóvel' },
        { id: '4', nome: 'Igreja' }
    ];

    const escritura = () => {
        return listEscrituras.find(
            (value) => Number(dadosVenda.informacao.escritura) === value.id
        )?.escritura;
    };

    const handleEditar = (e) => {
        setPosicaoMinuta(1);
        setCountMenu(4);
        setIsShowFormMinuta(true);
        setIsShowEntregarVenda(false)
    }

    return (
        <Pane className="container">
            <div className="type-rank-user" style={{marginBottom: '25px'}}>
                <div className="title" style={{marginBottom: '0'}}>Dados do Imóvel</div>

                <Button marginRight={0} onClick={(e) => handleEditar(e)} className="btn-editar">
                    <EditIcon color="#8F95B2" size={12} marginRight={8} /> Editar dados da venda
                </Button>
            </div>
        
            <ul className="row">
                <li>
                    <div className="field">Código</div>
                    <div className="value">{dadosVenda.codigo}</div>
                </li>

                <li>
                    <div className="field">CEP</div>
                    <div className="value">{dadosVenda.cep}</div>
                </li>

                <li>
                    <div className="field">Bairro</div>
                    <div className="value">{dadosVenda.bairro}</div>
                </li>

                <li>
                    <div className="field">Logradouro</div>
                    <div className="value">{dadosVenda.logradouro}</div>
                </li>
            </ul>

            <ul className="row">
                <li>
                    <div className="field">Número</div>
                    <div className="value">{dadosVenda.numero}</div>
                </li>

                <li>
                    <div className="field">Unidade</div>
                    <div className="value">{dadosVenda.unidade || '---'}</div>
                </li>

                <li>
                    <div className="field">Complemento</div>
                    <div className="value">{dadosVenda.complemento || '---'}</div>
                </li>

                <li>
                    <div className="field">Cidade</div>
                    <div className="value">{dadosVenda.cidade}</div>
                </li>

                <li>
                    <div className="field">Estado</div>
                    <div className="value">{dadosVenda.uf}</div>
                </li>
            </ul>

            <ul className="row">
                <li>
                    <div className="field">Vagas na garagem</div>
                    <div className="value">{dadosVenda.informacao.vaga || '---'}</div>
                </li>
            </ul>

            <div className="blue-line"></div>

            <div className="title">Registro e Escritura</div>
            <ul className="row">
                <li>
                    <div className="field">Tipo</div>
                    <div className="value">{escritura()}</div>
                </li>
            </ul>

            <ul className="row">
                <li>
                    <div className="field">Lavrada em</div>
                    <div className="value">{dadosVenda.informacao.lavrada || '---'}</div>
                </li>

                <li>
                    <div className="field">Livro</div>
                    <div className="value">{dadosVenda.informacao.livro || '---'}</div>
                </li>

                <li>
                    <div className="field">Folhas</div>
                    <div className="value">{dadosVenda.informacao.folhas || '---'}</div>
                </li>

                <li>
                    <div className="field">Ato</div>
                    <div className="value">{dadosVenda.informacao.ato || '---'}</div>
                </li>

                <li>
                    <div className="field">Cartório</div>
                    <div className="value">{dadosVenda.informacao.cartorio || '---'}</div>
                </li>
            </ul>

            <ul className="row">
                <li>
                    <div className="field">Registrado no RGI</div>
                    <div className="value">{dadosVenda.informacao.rgi || '---'}</div>
                </li>

                <li>
                    <div className="field">Matrícula nº</div>
                    <div className="value">{dadosVenda.informacao.matricula || '---'}</div>
                </li>

                <li>
                    <div className="field">Inscrição Municipal nº</div>
                    <div className="value">{dadosVenda.informacao.inscricaoMunicipal || '---'}</div>
                </li>
            </ul>

            <div className="blue-line"></div>

            <div className="title">Laudêmio</div>
            <ul className="row">
                {
                    dadosVenda.laudemios.length === 0
                    ? 
                    <li>
                        <div className="value">---</div>
                    </li>

                    :
                    dadosVenda.laudemios.map((laudemio) => (
                        <>
                            <li>
                                <div className="field">Tipo</div>
                                <div className="value">{typeLaudemios.find((type) => laudemio.tipo_laudemio === type.id).nome}</div>
                            </li>

                            <li>
                                <div className="field">{laudemio.tipo_laudemio === '1' ? 'RIP nº' : laudemio.tipo_laudemio === '3' ? 'Nome da Família' : laudemio.tipo_laudemio === '4' ? 'Nome da Igreja' : ''}</div>
                                <div className="value">{
                                        laudemio.tipo_laudemio === '1' ? laudemio.valor_laudemio :
                                        laudemio.tipo_laudemio === '3' || laudemio.tipo_laudemio === '4' ? listLaudemios.find((list) => list.id === Number(laudemio.valor_laudemio)).nome : ''
                                    }
                                </div>
                            </li>
                        </>
                    ))
                }
            </ul>

            <div className="blue-line"></div>

            <div className="title">Formas de pagamentos</div>
            <ul className="row">
                <li>
                    <div className="value">{dadosVenda.informacao.forma_pagamento_nome}</div>
                </li>
            </ul>

            <ul className="row">
                <li>
                    <div className="field">Observações</div>
                    <div className="value">{dadosVenda.informacao.observacao_pagamento || '---'}</div>
                </li>
            </ul>

            <div className="blue-line"></div>

            <div className="title">Valor de venda</div>
            <ul className="row">
                <li>
                    <div className="field">Valor de venda</div>
                    <div className="value">{dadosVenda.informacao.valor_venda || '---'}</div>
                </li>

                <li>
                    <div className="field">Valor anunciado</div>
                    <div className="value">{dadosVenda.informacao.valor_anunciado || '---'}</div>
                </li>

                <li>
                    <div className="field">Valor do sinal</div>
                    <div className="value">{dadosVenda.informacao.valorSinal || '---'}</div>
                </li>
            </ul>

            <ul>
                <li>
                    <div className="field">Data da venda</div>
                    <div className="value">{dadosVenda.informacao.data_assinatura || '---'}</div>
                </li>

                <li>
                    <div className="field">Prazo da escritura</div>
                    <div className="value">{dadosVenda.data_prazo_escritura || '---'}</div>
                </li>
            </ul>

            <div className="blue-line"></div>

            <div className="title">Multa</div>
            <ul className="row">
                <li>
                    <div className="field">Valor de multa diária</div>
                    <div className="value">{dadosVenda.informacao.valorMulta || '---'}</div>
                </li>
            </ul>

            <div className="blue-line"></div>

            <div className="title">Documentos do imóvel</div>
            <ul className="row" style={{display: 'inline-block'}}>
                {dadosVenda.documentos.data.map((doc, index) => (
                    <li style={{display: 'flex', marginBottom: '0'}}>
                        <div className="field" style={{marginRight: '15px'}}>{doc.tipo_documento?.nome}</div>
                        <div className="value">
                            <Link className='content-click font12' onClick={() => ShowDocument(doc.id, 'documento', localStorage.getItem('token'))}>
                                {doc.arquivo}
                            </Link>   
                        </div>
                    </li>
                ))}
            </ul>
        </Pane>
    )
}
