import React, { useState, useContext, useRef } from 'react';
import { Pane, TextInputField, Button, Textarea, FilePicker, Checkbox, TickIcon, ResetIcon, Table } from 'evergreen-ui';
import NegociacaoContext from '../../../../../context/NegociacaoContext';
import dateMask from '../../../../../components/dateMask';
import formatoMoeda from '../../../../../components/formatoMoeda';
import ShowDocument from '../../../../../app/showDocument';
import postReciboSinal from '../../../../../apis/postReciboSinal';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

//Components
import Axios from 'axios';
import Serve from '../../../../../app/serve';
import { useHistory } from 'react-router-dom';
import InfoEscritura from '../../../../../components/InfoEscritura';

export default function ValoresVenda({ refreshProcess }) {

    const app = Serve();
    const history = useHistory();
    const [token] = useState(localStorage.getItem('token'));

    const {
        processSelect,
        setLoading,
        processoId,
        progressBar, setProgressBar
    } = useContext(NegociacaoContext);



    const handleSaveRecibo = async (e) => {
        const { imovel } = processSelect;
        const data = new FormData();
        setProgressBar({ percent: 0, status: '' })
        data.append('arquivo_recibo', e);
        // data.append('data_assinatura', dataAssinatura);
        data.append('informacao_imovel_id', imovel?.informacao?.id);
        data.append('imovel_id', imovel?.id);
        data.append('usuario_id_logado', localStorage.getItem('usuario_id'));
        data.append('processo_id', processSelect.id);

        await postReciboSinal(data, setProgressBar);
    };

    const infoVenda = processSelect?.imovel.informacao;
    const idPagamento = infoVenda ? infoVenda.forma_pagamento : '';
    const idInformacaoImovel = infoVenda ? infoVenda.id : '';
    const prazo = infoVenda ? infoVenda.prazo : '';
    const valorAnunciado = infoVenda ? infoVenda.valor_anunciado : '';
    const valorMulta = infoVenda && infoVenda.valoMulta !== null && infoVenda.valoMulta !== 'null' ? infoVenda.valoMulta : '';
    const obs = infoVenda && infoVenda.observacao !== null && infoVenda.observacao !== 'null' ? infoVenda.observacao : '';
    const obsPagamento = infoVenda && infoVenda.observacao_pagamento !== null && infoVenda.observacao_pagamento !== 'null' ? infoVenda.observacao_pagamento : '';
    const valorVenda = infoVenda ? infoVenda.valor_venda : '';
    const valorSinal = infoVenda ? infoVenda.valorSinal : '';
    const reciboName = infoVenda ? infoVenda.recibo : '';
    const dataAssinatura = infoVenda ? infoVenda.data_assinatura : '';
    const prazoAssinatura = infoVenda ? infoVenda.prazo_escritura : '';

    const [checkedMinutaFinanciamento, setCheckedMinutaFinanciamento] = useState((idPagamento !== '' && idPagamento !== null) ? idPagamento.includes('2') : '');
    const [checkedMinutaParcelamento, setCheckedMinutaParcelamento] = useState((idPagamento !== '' && idPagamento !== null) ? idPagamento.includes('5') : '');
    const [checkedMinutaConsorcio, setCheckedMinutaConsorcio] = useState((idPagamento !== '' && idPagamento !== null) ? idPagamento.includes('4') : '');
    const [checkedMinutaAVista, setCheckedMinutaAVista] = useState((idPagamento !== '' && idPagamento !== null) ? idPagamento.includes('1') : '');
    const [checkedMinutaFgts, setCheckedMinutaFgts] = useState((idPagamento !== '' && idPagamento !== null) ? idPagamento.includes('3') : '');
    const [checkedMinutaPix, setCheckedMinutaPix] = useState((idPagamento !== '' && idPagamento !== null) ? idPagamento.includes('6') : '');

    const dataAssinaturaRef = useRef("");
    const reciboRef = useRef(""); // useRef para não precisar ficar recarregando a pagina toda vez que o usuário alterar o arquivo
    const obsPagamentoRef = useRef("");
    const valorVendaRef = useRef("");
    const valorSinalRef = useRef("");
    const prazoEscrituraRef = useRef("");
    const valorAnunciadoRef = useRef("");
    const valorMultaRef = useRef("");

    const handleClear = (e) => {
        e.preventDefault();
        dataAssinaturaRef.current.value = dataAssinatura;
        //prazoAssinaturaRef.current.value = prazoAssinatura;
        reciboRef.current.value = reciboName;
        obsPagamentoRef.current.value = obsPagamento;
        valorVendaRef.current.value = valorVenda;
        valorSinalRef.current.value = valorSinal;
        prazoEscrituraRef.current.value = prazo;
        valorAnunciadoRef.current.value = valorAnunciado;
        valorMultaRef.current.value = valorMulta;
        setCheckedMinutaAVista(idPagamento.includes('1'));
        setCheckedMinutaConsorcio(idPagamento.includes('4'));
        setCheckedMinutaFinanciamento(idPagamento.includes('2'));
        setCheckedMinutaFgts(idPagamento.includes('3'));
        setCheckedMinutaParcelamento(idPagamento.includes('5'));
        setCheckedMinutaPix(idPagamento.includes('6'));
    }

    const tipoPagamento = () => {
        const formasPagamento = [];
        const idPagamento = [];
        if (checkedMinutaFinanciamento) {
            formasPagamento.push("Financiamento");
            idPagamento.push(2);
        }
        if (checkedMinutaParcelamento) {
            formasPagamento.push("Parcelamento");
            idPagamento.push(5);
        }
        if (checkedMinutaConsorcio) {
            formasPagamento.push("Consórcio");
            idPagamento.push(4);
        }
        if (checkedMinutaAVista) {
            formasPagamento.push("À vista");
            idPagamento.push(1);
        }
        if (checkedMinutaFgts) {
            formasPagamento.push("FGTS");
            idPagamento.push(3);
        }
        if (checkedMinutaPix) {
            formasPagamento.push("PIX");
            idPagamento.push(6);
        }
        if (formasPagamento.length === 0) {
            formasPagamento.push(infoVenda.forma_pagamento_nome);
        }
        if (idPagamento.length === 0) {
            idPagamento.push(infoVenda.forma_pagamento);
        }
        return {
            formasPagamento,
            idPagamento,
        }
    };

    async function handleSave(e) {
        // console.log(imovelProcesso);
        e.preventDefault();
        setLoading(true);
        dataAssinaturaRef.current.value = dateMask(dataAssinaturaRef.current.value);
        //prazoAssinaturaRef.current.value = dateMask(dataAssinaturaRef.current.value);
        valorVendaRef.current.value = formatoMoeda(valorVendaRef.current.value);
        valorSinalRef.current.value = formatoMoeda(valorSinalRef.current.value);
        valorAnunciadoRef.current.value = formatoMoeda(valorAnunciadoRef.current.value);
        valorMultaRef.current.value = formatoMoeda(valorMultaRef.current.value);

        const data = new FormData();
        data.append('idResponsavel', localStorage.getItem('usuario_id'));
        data.append('id', processoId);
        data.append('processo_id', processoId);
        data.append('imovel_id', processSelect.imovel.id);
        data.append('idInformacaoImovel', idInformacaoImovel);
        data.append('data_assinatura', dataAssinaturaRef.current.value ?? dataAssinatura);
        //data.append('prazo_escritura', prazoAssinaturaRef.current.value ?? prazoAssinatura);
        data.append('forma_pagamento', tipoPagamento().idPagamento.toString());
        data.append('observacao', obs);
        // data.append('observacao', 'laudemio, averbação, registro');
        data.append('observacao_pagamento', obsPagamentoRef.current.value === "" ? obsPagamento : obsPagamentoRef.current.value);
        data.append('prazo', prazoEscrituraRef.current.value ?? prazo);
        data.append('recibo', reciboRef.current.name ?? reciboName);
        data.append('valoMulta', valorMultaRef.current.value ?? valorMulta);
        data.append('valorSinal', valorSinalRef.current.value ?? valorSinal);
        data.append('valor_anunciado', valorAnunciadoRef.current.value ?? valorAnunciado);
        data.append('valor_venda', valorVendaRef.current.value ?? valorVenda);

        // console.log(data.values());

        await Axios.post(app + 'salvar_venda_backoffice', data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
            }
        }).then(res => {
            if (res.data.status && res.data.status === (401 || 498)) {
                localStorage.clear();
                history.push('/');
            } else {
                refreshProcess(processoId);
            }
        }).catch(function (error) {
            console.log(error);
        })
        setLoading(false);
    };

    return (
        <div>
            <div className="formGroup formAdd">
                <form id="formGroup">
                    <Pane>
                        <input id="imovel_id-1" type="hidden" value={processSelect?.imovel !== undefined ? processSelect.imovel.id : ''} />
                        <input id="processo_id-1" type="hidden" value={processoId || ''} />
                        <input id="processo_id-1" type="hidden" value={processoId || ''} />

                        <h2 className='subtitle'>Recibo de sinal assinado</h2>
                        <div className="">
                            <TextInputField width={160}
                                label="Data de assinatura*"
                                defaultValue={dataAssinatura}
                                placeholder="Ex: DD/MM/AAAA"
                                id={"data-assinatura-1"}
                                ref={dataAssinaturaRef}
                                onChange={() => dataAssinaturaRef.current.focus()}
                            />
                        </div>
                        <Pane width="max-content">
                            <div className="doc-name">
                                <span className="name-ref">Recibo de Sinal (assinado)*</span>
                                <FilePicker
                                    id="file-picker-recibo-de-sinal"
                                    ref={reciboRef}
                                    onChange={(e) => handleSaveRecibo(e[0])}
                                    placeholder={reciboName}
                                    accept=".docx, .doc, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                />
                            </div>

                            <Button
                                marginTop={-2}
                                marginLeft={10}
                                marginRight={10}
                                appearance="primary"
                                intent="none"
                                onClick={(e) => [ShowDocument((processSelect?.imovel !== undefined ? processSelect.imovel.id : ''), 'recibo', token), e.preventDefault()]}
                            >Ver
                            </Button>
                            {progressBar.percent !== 0 &&
                                <div className='mt8'>
                                    <Progress
                                        percent={progressBar.percent}
                                        status={progressBar.status}
                                        theme={{
                                            default: {
                                                color: '#3366FF'
                                            }
                                        }}
                                    />
                                </div>
                            }
                        </Pane>

                        <h2 className="subtitle subtitle-table">Valor de venda</h2>
                        <Pane display="flex">
                            <Pane flex={1}>
                                <TextInputField width={260}
                                    label="Valor de venda *"
                                    defaultValue={valorVenda}
                                    id={"data-assinatura-1"}
                                    placeholder="R$"
                                    ref={valorVendaRef}
                                    name={valorVendaRef}
                                    onChange={e => valorVendaRef.current.value = formatoMoeda(e.target.value)}
                                />
                            </Pane>
                            <TextInputField width={260}
                                label="Valor do sinal *"
                                defaultValue={valorSinal}
                                id={"data-assinatura-1"}
                                ref={valorSinalRef}
                                name={valorSinalRef}
                                placeholder="R$"
                                onChange={e => valorSinalRef.current.value = formatoMoeda(e.target.value)}
                            />
                        </Pane>
                        <Pane display="flex">
                            <Pane flex={1}>
                                <TextInputField width={260}
                                    label="Prazo para escritura *"
                                    defaultValue={prazo}
                                    id={"data-assinatura-1"}
                                    ref={prazoEscrituraRef}
                                    placeholder="Exemplo: 120"
                                    onChange={(e) => prazoEscrituraRef.current.focus()}
                                />
                            </Pane>
                            <TextInputField width={260}
                                label="Valor anunciado"
                                defaultValue={valorAnunciado}
                                id={"data-assinatura-1"}
                                ref={valorAnunciadoRef}
                                name={valorAnunciadoRef}
                                placeholder="R$"
                                onChange={e => valorAnunciadoRef.current.value = formatoMoeda(e.target.value)}
                            />
                        </Pane>
                        {/* <TextInputField
                                width={260}
                                label="Previsão de escritura"
                                description=""
                                placeholder="---"
                                defaultValue={prazoAssinatura}
                                id={"prazo-assinatura"}
                                data-campo="prazo-assinatura"
                                readOnly
                                disabled
                            /> */}


                        {/*Informações sobre a Escritura*/}
                        <InfoEscritura processo={processSelect.imovel} prazoAssinatura={prazoAssinatura} perfil="posVenda" />

                        <h2 className="subtitle subtitle-table">Formas de pagamentos</h2>
                        <div className="line-checkbox">
                            <Checkbox
                                marginTop={5}
                                whiteSpace="nowrap"
                                appearance="default"
                                className="checkbox minuta-venda-checkbox-1"
                                label="À vista"
                                id="check-1"
                                value="1"
                                checked={checkedMinutaAVista}
                                onChange={e => setCheckedMinutaAVista(e.target.checked)}
                            />

                            <Checkbox
                                marginTop={5}
                                appearance="default"
                                className="checkbox"
                                id="check-2 minuta-venda-checkbox-2"
                                value="2"
                                label="Financiamento"
                                checked={checkedMinutaFinanciamento}
                                onChange={e => setCheckedMinutaFinanciamento(e.target.checked)}
                            />

                            <Checkbox
                                marginTop={5}
                                appearance="default"
                                className="checkbox minuta-venda-checkbox-3"
                                label="FGTS"
                                id="check-3"
                                value="3"
                                checked={checkedMinutaFgts}
                                onChange={e => setCheckedMinutaFgts(e.target.checked)}
                            />

                            <Checkbox
                                marginTop={5}
                                appearance="default"
                                className="checkbox minuta-venda-checkbox-4"
                                label="Consórcio"
                                id="check-4"
                                value="4"
                                checked={checkedMinutaConsorcio}
                                onChange={e => setCheckedMinutaConsorcio(e.target.checked)}
                            />

                            <Checkbox
                                marginTop={5}
                                appearance="default"
                                className="checkbox minuta-venda-checkbox-5"
                                label="Parcelamento"
                                id="check-5"
                                value="5"
                                checked={checkedMinutaParcelamento}
                                onChange={e => setCheckedMinutaParcelamento(e.target.checked)}
                            />

                            {/* <Checkbox
                                marginTop={5}
                                appearance="default"
                                className="checkbox minuta-venda-checkbox-5"
                                label="PIX"
                                id="check-6"
                                value="6"
                                checked={checkedMinutaPix}
                                onChange={e => setCheckedMinutaPix(e.target.checked)}
                            /> */}
                            <input
                                type="hidden"
                                value={idPagamento}
                                name="documento"
                                className={"minuta-venda-forma-pagamento-1"}
                                id={"minuta-venda-forma-pagamento-1"} />
                        </div>
                        <div className="doc-name" style={{ cursor: "auto" }}>
                            <span className="name-ref">Caso necessário, insira observações sobre o pagamento.</span>
                            <Textarea
                                id="textarea-2"
                                ref={obsPagamentoRef}
                                placeholder="Exemplo: A primeira parte do pagamento do imóvel será à vista. O restante financiado."
                                defaultValue={obsPagamento}
                                onChange={() => (obsPagamentoRef.current.focus())}
                                marginBottom={10}
                            />
                        </div>
                        <h2 className="subtitle mt5">Multa</h2>
                        <div className="mb10" >
                            <span className="fw400">Se necessário, informe o valor de multa diária para o vendedor.
                                <br />Este valor está relacionado ao prazo limite de entrega das chaves.</span>
                        </div>
                        <TextInputField width={260}
                            label="Valor multa diária"
                            defaultValue={valorMulta}
                            id={"data-assinatura-1"}
                            ref={valorMultaRef}
                            placeholder="R$"
                            onChange={e => valorMultaRef.current.value = formatoMoeda(e.target.value)}
                        />

                        {/* CAMPO DE OBSERVAÇÕES ENVIADAS PELO GERENTE NO CADASTRO */}
                        <h2 className="subtitle subtitle-table">Observações</h2>
                        <div>
                            <Table.TextCell>
                                <span className="mensagem mensagem-completa">
                                    {processSelect?.imovel?.informacao?.observacao || '---'}
                                </span>
                            </Table.TextCell>
                        </div>

                        <Pane display="flex" paddingBottom={20} paddingTop={20}>
                            <Pane flex={1}>
                            </Pane>
                            <Button marginRight={16} intent="none" onClick={(e) => handleClear(e)}>
                                <ResetIcon
                                    size={10}
                                    style={{ 'marginRight': '10px', 'fill': '#8F95B2' }} /> Limpar tudo
                            </Button>
                            <Button appearance="primary" intent="none" onClick={(e) => handleSave(e)}>
                                <TickIcon
                                    size={10}
                                    style={{ 'marginRight': '10px', 'fill': '#fff' }} /> Salvar
                            </Button>
                        </Pane>
                    </Pane>
                </form>
            </div>
        </div>
    )
}