import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { useForm } from 'react-hook-form';
import {
  Pane,
  Badge,
  TextInputField,
  Button,
  PlusIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  toaster,
  Radio,
  Spinner,
  Autocomplete,
  Textarea,
} from 'evergreen-ui';

//Context
import VendasContext from '../../../../../../../context/VendasContext';
import delCorretores from '../../../../../../../apis/delCorretores';
import Serve from '../../../../../../../app/serve';
import getOpcionistas from '../../../../../../../apis/getOpcionistas';

//CSS inputs
const inputGreen = {
  borderColor: '#429777',
  background: '#F5FBF8'
};

const inputDefault = {
  borderColor: '#ADC2FF',
  background: '#fff'
};

const inputError = {
  borderColor: '#FF0000',
  background: '#fff'
};

const errorTextInput = "Campo obrigatório.";
const porcentagemInvalida = "Porcentagem incorreta.";
const inicialValor = { id: '', userId: '', nome: '', porcentagem: 100, valor: '' };


const percentualOpcionistas = (laudo) => {
  switch (laudo) {
    case "simples":
      return "16%";
    case "com_chave":
      return "18%";
    case "exclusividade":
      return "20%";
    case "lançamento":
      return "8%";
    default:
      return "";
  }
};

const FormulariosUsuarios = ({ register, setValue, watch, errors, users, valores }) => {
  const {
    listaOpicionistas,
  } = useContext(VendasContext);

  function calculoPorcentagem(campo) {
    campo.forEach((parcela, index) => {
      let value = (100 / campo.length).toFixed(2)
      if (index === 0 && value === '33.33') value = '33.34';
      parcela.porcentagem = value;
    })
  }

  function addUser() {
    const parcelasAdd = [...watch(users), inicialValor]
    calculoPorcentagem(parcelasAdd);
    setValue(users, parcelasAdd)
  };

  const type = () => {
    switch (users) {
      case "corretoresVendedores":
        return "corretores";
      default:
        return users;
    }
  }

  async function removeUser(index) {
    const camposUser = watch(users);
    if (camposUser[index].id) {
      const res = await delCorretores(camposUser[index].id, type());
      if (res) {
        camposUser.splice(camposUser.indexOf(index), 1);
        toaster.notify("Corretor deletado do sistema")
        calculoPorcentagem(camposUser);
        setValue(users, camposUser);
      } else {
        toaster.notify("Algo não saiu como esperado. Tente novamente mais tarde.")
      }
    } else {
      camposUser.splice(camposUser.indexOf(index), 1);
      calculoPorcentagem(camposUser);
      setValue(users, camposUser);
    }
  };

  const nameLabel = () => {
    switch (users) {
      case "gerentesGerais":
        return "gerente geral";
      case "gerentes":
        return "gerente";
      case "corretoresVendedores":
        return "corretor";
      case "opcionistas":
        return "opcionista";
      default:
        return "";
    }
  };

  const limitValidate = (type) => {
    let value = 0;
    watch(type).forEach((pessoa) => {
      value = value + Number(pessoa.porcentagem);
    })
    if (value !== 100) return false
    else return true
  };

  return (
    <div className='mb40'>
      {users !== 'opcionistas' &&
        <div className='flex fw400'>
          <TextInputField
            label="Total da comissão*"
            className='w135 pr25'
            type='number'
            style={errors?.totalComissao?.[users] ? inputError : watch('totalComissao.' + users) ? inputGreen : inputDefault}
            {...register('totalComissao.' + users, {
              required: errorTextInput,
              max: { value: 100, message: porcentagemInvalida }
            })}
            step="0.50"
            validationMessage={errors?.totalComissao?.[users] && errors?.totalComissao?.[users].message}
          />
          <span className='sobrepor'>%</span>
        </div>}

      {watch(users).map((user, index) => (
        <div className='flex'>
          <Pane className='w250 mr20'>
            <Autocomplete
              onChange={e => setValue(users + '.' + index + '.nome', e)}
              items={listaOpicionistas.map((opcionista) => opcionista.nome)}
              allowOtherValues={true}
              selectedItem={user.nome}
            >
              {props => {
                const { getInputProps, getRef, inputValue, openMenu } = props
                return (
                  <TextInputField
                    label={"Nome completo do " + nameLabel()}
                    required
                    placeholder={"Nome completo do " + nameLabel()}
                    value={inputValue}
                    ref={getRef}
                    validationMessage={errors?.[users]?.[index]?.nome && errors?.[users]?.[index]?.nome.message}
                    style={user.nome ? inputGreen : errors?.[users]?.[index]?.nome ? inputError : inputDefault}
                    {...getInputProps({
                      onClick: () => {
                        openMenu()
                      }
                    })}
                  />
                )
              }}
            </Autocomplete>
            <input
              {...register(users + '.' + index + '.nome', {
                required: errorTextInput,
              })}
              hidden
            />
          </Pane>
          <div className='flex'>
            <TextInputField
              label="Porcentagem da comissão*"
              className='w200 pr25'
              type='number'
              style={errors?.[users]?.[index]?.porcentagem ? inputError : user.porcentagem ? inputGreen : inputDefault}
              {...register(users + '.' + index + '.porcentagem', {
                required: errorTextInput,
                validate: value => limitValidate(users) || porcentagemInvalida,
              })}
              validationMessage={errors?.[users]?.[index]?.porcentagem && errors?.[users]?.[index].porcentagem.message}
            />
            <span className='sobrepor mr10'>%</span>
          </div>
          <div className='flex w120'>
            <TextInputField
              label="Valor em R$"
              disabled
              value={((valores[users] * Number(watch(users + '.' + index + '.porcentagem'))) / 100).toLocaleString('pt-br', { minimumFractionDigits: 2 })}
              marginLeft={20}
            />
          </div>
          {console.log('users: ', users)}
          {(index !== 0 || (users === 'gerentesGerais' && !user.userId)) &&
            <Button
              type='button'
              appearance='primary'
              intent='danger'
              className='ml20 mt26'
              onClick={() => removeUser(index)}
            >
              Excluir
            </Button>}
        </div>
      ))}
      <Button iconBefore={PlusIcon} onClick={() => addUser()}>{"Adicionar " + nameLabel()}</Button>
    </div>
  )
};

export default function Corretores({ setSelectedIndex, recarregarVenda }) {
  const {
    loading, setLoading,
    dadosVenda,
    listaOpicionistas, setListaOpicionistas,
  } = useContext(VendasContext);
  const app = Serve();
  const [token] = useState(localStorage.getItem('token'));
  const [gerente_id] = useState(localStorage.getItem('usuario_id'));
  // const [alteracaoRevisao, setAlteracaoRevisao] = useState(true);
  const { comissao, comissao_gerente_gerais, comissao_gerentes, opcionistas } = dadosVenda;

  const [laudoOptions] = useState([
    { label: "Simples", value: "simples" },
    { label: "Com chave", value: "com_chave" },
    { label: "Exclusividade", value: "exclusividade" },
    { label: "Lançamentos", value: "lançamento" },
  ]);

  const { register, handleSubmit, clearErrors, setValue, watch, reset, formState: { errors } } = useForm({
    defaultValues: {
      totalComissao: {
        gerentesGerais: comissao?.valor_comissao_gg || 2.5,
        gerentes: comissao?.valor_comissao_gerente || 10,
        corretoresVendedores: comissao?.corretores_vendedores_comissao?.[0]?.total_comissao_corretor || 20,
        tipoLaudoOpcionista: comissao?.corretores_opicionistas_comissao?.[0]?.tipo_laudo_opcionista || 'simples',
      },

      gerentesGerais: comissao_gerente_gerais ? comissao_gerente_gerais.map((user) => ({
        id: user.id,
        userId: user.usuario_id,
        nome: user.name,
        porcentagem: user.porcentagem_comissao,
        valor: user.valor_real
      })) : [inicialValor],

      gerentes: comissao_gerentes.length > 0 ? comissao_gerentes.map((user) => ({
        id: user.id,
        userId: user.usuario_id,
        nome: user.name,
        porcentagem: user.porcentagem_comissao,
        valor: user.valor_real
      })) : [inicialValor],

      corretoresVendedores: comissao?.corretores_vendedores_comissao.length > 0 ? comissao.corretores_vendedores_comissao.map((user) => ({
        id: user.id,
        userId: user.usuario_id,
        nome: user.name,
        porcentagem: user.porcentagem_comissao_corretor,
        valor: user.valor_real
      })) : [inicialValor],

      opcionistas: opcionistas.length > 0 ? opcionistas.map((user) => ({
        id: user.id,
        userId: user.usuario_id,
        nome: user.name,
        porcentagem: user.porcentagem_comissao_opcionista,
        valor: user.valor_real
      })) : [inicialValor],

      observacoes: comissao?.observacoes || ''
    }
  });
  console.log("Watch Corretores: ", watch());
  console.log("Errors Corretores: ", errors);

  const comissaoLiquida = comissao?.valor_comissao_liquida ? Number(comissao?.valor_comissao_liquida?.replace(/[R$.]+/g, '').replace(",", ".")) : 0;
  const valores = {
    gerentesGerais: (comissaoLiquida * Number(watch("totalComissao.gerentesGerais")) / 100),
    gerentes: (comissaoLiquida * Number(watch("totalComissao.gerentes")) / 100),
    corretoresVendedores: (comissaoLiquida * Number(watch("totalComissao.corretoresVendedores")) / 100),
    opcionistas: ((comissaoLiquida) * Number(percentualOpcionistas(watch('totalComissao.tipoLaudoOpcionista')).replace("%", "")) / 100)
  };

  function handleBtnAnterior() {
    setSelectedIndex(0);
  };

  const handleBtnAvancar = async (e) => {
    setLoading(false);
    //CALCULO PARA VALOR E PORCENTAGEM DA EMPRESA
    const valorComunicacao = dadosVenda.empresa_receber.verificar_franquia ? comissaoLiquida * 2 / 100 : 0;
    const valorRoyalties = dadosVenda.empresa_receber.verificar_franquia ? comissaoLiquida * 7 / 100 : 0;
    let valorEmpresa = Number(comissaoLiquida - (
      valores.gerentesGerais + valores.gerentes
      + valores.corretoresVendedores + valores.opcionistas
      + valorComunicacao + valorRoyalties
    ));

    if( dadosVenda.comissao.verificar_enviar_planilha === 0 ) {
      valorEmpresa = valorEmpresa - (comissaoLiquida * 3 / 100);
    }

    const porcentagemEmpresa = (
      valorEmpresa / comissaoLiquida
    ) * 100;

    const data = new FormData();
    data.append('comissao_id', comissao.id);
    data.append('processo_id', dadosVenda.processo_id);
    data.append('usuario_id_logado', gerente_id);
    data.append('observacoes', e.observacoes);
    data.append('condicaostatus', 1);
    data.append('status_processo', dadosVenda.status_processo);

    data.append('empresa_valor', valorEmpresa.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
    data.append('empresa_porcentagem', porcentagemEmpresa);

    // GERENTES GERAIS
    data.append('total_comissao_GG', e.totalComissao.gerentesGerais);
    e.gerentesGerais.forEach((user) => {
      const valorReal = ((valores.gerentesGerais * Number(user.porcentagem)) / 100).toFixed(2);

      data.append('id_gerente_geral_usuario[]', listaOpicionistas.find((usuarios) => usuarios.nome === user.nome)?.usuario_id || user.userId);
      data.append('nome_gerente_geral[]', user.nome);
      data.append('porcentagem_comissao_gerente_geral[]', user.porcentagem);
      data.append('id_gerente_geral[]', user.id || '');
      data.append('valor_real_gerente_geral[]', valorReal);
    })

    // GERENTES
    data.append('total_comissao_gerentes', e.totalComissao.gerentes);
    e.gerentes.forEach((user) => {
      const valorReal = ((valores.gerentes * Number(user.porcentagem)) / 100).toFixed(2);

      data.append('id_gerente_usuario[]', listaOpicionistas.find((usuarios) => usuarios.nome === user.nome)?.usuario_id || user.userId);
      data.append('nome_gerente[]', user.nome);
      data.append('porcentagem_comissao_gerente[]', user.porcentagem);
      data.append('id_gerente[]', user.id || '');
      data.append('valor_real_gerente[]', valorReal);
    })

    // CORRETORES VENDEDORES
    data.append('total_comissao', e.totalComissao.corretoresVendedores);
    e.corretoresVendedores.forEach((user) => {
      const valorReal = ((valores.corretoresVendedores * Number(user.porcentagem)) / 100).toFixed(2);

      data.append('id_corretor_usuario[]', listaOpicionistas.find((usuarios) => usuarios.nome === user.nome)?.usuario_id || user.userId);
      data.append('nome_corretor[]', user.nome);
      data.append('porcentagem_comissao_corretor[]', user.porcentagem);
      data.append('id_corretor[]', user.id || '');
      data.append('valor_real_corretor[]', valorReal);
    })

    // CORRETORES OPCIONISTAS
    data.append('tipo_laudo_opicionista', e.totalComissao.tipoLaudoOpcionista);
    e.opcionistas.forEach((user) => {
      const valorReal = ((valores.opcionistas * Number(user.porcentagem)) / 100).toFixed(2);

      data.append('id_opcionista_usuario[]', listaOpicionistas.find((usuarios) => usuarios.nome === user.nome)?.usuario_id || user.userId);
      data.append('nome_opcionista[]', user.nome);
      data.append('porcentagem_comissao_opcionista[]', user.porcentagem);
      data.append('id_opcionista[]', user.id || '');
      data.append('valor_real_opcionista[]', valorReal);
    })

    const res = await Axios.post(app + 'salvar_comissao_corretores', data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        }
      })
      .then(res => {
        return res.data;
      })
      .catch(error => {
        console.log(error);
      })
    if (res) await recarregarVenda("Corretor");
    else toaster.notify("Algo não saiu como esperado. Tente novamente mais tarde.")
    setLoading(true);
    const list = await getOpcionistas();
    setListaOpicionistas(list || listaOpicionistas);
  };

  return (
    <Pane>
      {!loading ?
        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
          <Spinner size={80} />
        </Pane> :
        <Pane className='mb30'>
          <div>
            <p className='f24 fw600'>Comissão dos profissionais envolvidos na venda</p>
            <p className='fw400 mt16'>Preencha com dados complementares sobre a comissão da venda.</p>
          </div>

          <div className="fw600 mb20 mt36">Gerentes Gerais</div>
          {
            dadosVenda.loja.gerente_geral_id === null
              ? <div style={{ color: '#474D66', fontWeight: '400', marginBottom: '15px' }}>A loja {dadosVenda.loja.nome} não possui nenhum Gerente Geral no momento, entretanto é possível adicionar um profissional para receber a comissão equivalente.</div>
              : ''
          }
          <FormulariosUsuarios errors={errors} register={register} watch={watch} setValue={setValue} users='gerentesGerais' valores={valores} />

          <div className="fw600 mb20 mt36">Gerentes</div>
          <FormulariosUsuarios errors={errors} register={register} watch={watch} setValue={setValue} users='gerentes' valores={valores} />

          <div className="fw600 mb20 mt36">Corretores vendedores</div>
          <FormulariosUsuarios errors={errors} register={register} watch={watch} setValue={setValue} users='corretoresVendedores' valores={valores} />

          <div className="fw600 mb15 mt36">Opcionistas</div>
          <p className='fw400'>A soma da porcentagem de cada opcionista, deve ser no total do laudo.</p>
          <div className="fw600 mb10 mt15">
            <span className='mr20'>Tipo de laudo*</span>
            {watch('totalComissao.tipoLaudoOpcionista') && <Badge color="blue">Total de {percentualOpcionistas(watch('totalComissao.tipoLaudoOpcionista'))}</Badge>}
          </div>
          <div className="rowForm line-radius mt10 flex">
            <Pane className='flex' aria-label="Radio Group Label 16" role="group">
              {laudoOptions.map((option) => (
                <Radio
                  className='mr20 radius'
                  size={16}
                  onClick={() => [setValue('totalComissao.tipoLaudoOpcionista', option.value), clearErrors('totalComissao.tipoLaudoOpcionista')]}
                  checked={watch('totalComissao.tipoLaudoOpcionista') === option.value}
                  name="group2"
                  label={option.label}
                />
              ))}
            </Pane>
          </div>

          <FormulariosUsuarios errors={errors} register={register} watch={watch} setValue={setValue} users='opcionistas' valores={valores} />

          <div className="f18 fw600 mb15 mt36">Observações</div>
          <p className='fw400 mb15'>Caso necessário, insira observações para que o backoffice fique atento.</p>
          <Textarea
            placeholder="Exemplo: O imóvel se encontra em inventário e o comprador só está disponível para contato de 13h às 18h. "
            style={watch('observacoes') ? inputGreen : inputDefault}
            height={110}
            {...register('observacoes', {
              required: false
            })}
          />
        </Pane>}

      <div className="flex flexEnd">
        <div>
        </div>
        <Button
          iconBefore={<ChevronLeftIcon />}
          disabled={!loading}
          appearance="default"
          marginLeft={15}
          onClick={(e) => handleBtnAnterior(e)}
        >
          Anterior
        </Button>
        <Button
          type="submit"
          appearance="primary"
          marginLeft={15}
          color="white"
          disabled={!loading}
          iconAfter={<ChevronRightIcon color='white' />}
          onClick={
            handleSubmit(e => dadosVenda.status_processo !== 22
              ? handleBtnAvancar(e)
              // : alteracaoRevisao === true ? handleBtnAvancar(e) : toaster.danger('Realize os ajustes solicitados pelo pós-venda para avançar.'))
              : handleBtnAvancar(e)
            )
          }
        >
          Avançar
        </Button>
      </div>
    </Pane>
  )
}