import Axios from 'axios';

async function getMidas(cod) {
  let data;
  
  await Axios.post('https://infoideias.net/servicos/portal/pesquisa_imovel_dna/lead/288',
    { "referencia_dna": cod, "token_dna": "XYZ2022" },
    {
      mode: "cors",
      crossdomain: true,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
  )
    .then(res => {
      data = res.data
    })
    .catch(err => {
      console.log(err);
    })
  return data;
}

export default getMidas;