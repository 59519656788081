import React, { useState, useEffect, useContext } from 'react';
import {
    Pane,
    Tablist,
    Tab,
    Button,
} from 'evergreen-ui';

//Context
import VendasContext from '../../../context/VendasContext';

import '../../../index.css';

//Components
import Axios from 'axios';
import Serve from '../../../app/serve';
import { useHistory } from 'react-router-dom';
//import FormVenda from '../component/Form/FormVenda';
import FormBoletimDeVenda from '../component/Form/FormBoletimDeVenda';
import SendToBackOffice from './SendToBackOffice';
import FeedBack from './SendToBackOffice/FeedBack';
import VendasAdicionadas from './Table/VendasAdicionadas';
import VendasAdicionadasGG from './Table/VendasAdicionadasGG';
import VendasAdicionadasDG from './Table/VendasAdicionadasDC';
import VendasEnviadas from './Table/VendasEnviadas';
import VendasEnviadasDC from './Table/VendasEnviadasDC';
import FormUploadReciboVenda from './Form/FormUploadReciboVenda';
import CornerDialog from '../component/CornerDialog';
import CornerDialogFeedbackEscritura from './CornerDialog/CornerDialogFeedbackEscritura';
import getGerenteConfirmarEscritura from '../../../apis/getGerenteConfirmarEscritura';
import DialogConfirmacaoEscritura from './Table/components/Dialog/DialogConfirmacaoEscritura';

//import Lottie from 'react-lottie';
import animationData from '../../../images/Animations/loadingDocs.json';
import ChangeLog from '../../components/ChangeLog';
import getHolidays from '../../../apis/getHolidays';
import getListEnderecos from '../../../apis/getListEnderecos';
import getListEscrituras from '../../../apis/getListEscrituras';

export default function TablePanel() {
    const app = Serve();
    const history = useHistory();
    const [token] = useState(localStorage.getItem('token'));
    const [perfil_login] = useState(localStorage.getItem('perfil_login'));

    //Exibir Botão Gerar Venda
    const [exibirGerarVenda, setExibirGerarVenda] = useState(0);

    //Lista de Filtros
    const [listEnderecos, setListEnderecos] = useState({entregues: [], rascunhos: []});

    //Table
    const [loading, setLoading] = useState(false);
    const [itemAdicionado, setItemAdicionado] = useState([]);
    const [itemEnviado, setItemEnviado] = useState([]);
    const [dadosReciboEnviado, setDadosReciboEnviado] = useState([]);
    const [listaGerente, setListaGerente] = useState([]);
    const [listaStatusProcesso] = useState([[]]);
    const [listaResponsaveis] = useState([[]]);
    const [listaLojas, setListaLojas] = useState([]);
    const [listaOpicionistas, setListaOpicionistas] = useState([]);
    const [totalItensAdicionados, setTotalItensAdicionados] = useState(0);
    const [totalItensEntregues, setTotalItensEntregues] = useState(0);

    //Dialog seleção tipo de Venda
    const [isShownFormRecibo, setIsShownFormRecibo] = useState(false);

    //Cadastro ou Edição do Recibo de Sinal
    const [estadoRecibo, setEstadoRecibo] = useState('');

    //Dados do Recibo
    const [dadosRecibo, setDadosRecibo] = useState([]);

    //Filtros
    const [filtro] = useState({
        'responsavel': Number(localStorage.getItem('filtro_responsavel')) || '',
        'status': Number(localStorage.getItem('filtro_status')) || '',
        'gerente_rascunho': JSON.parse(localStorage.getItem('filtro_gerente_rascunho')) || '',
        'gerente_entregue': JSON.parse(localStorage.getItem('filtro_gerente_entregue')) || '',
        'loja_rascunho': JSON.parse(localStorage.getItem('filtro_loja_rascunho')) || '',
        'loja_entregue': JSON.parse(localStorage.getItem('filtro_loja_entregue')) || '',
        'mes_cadastro_rascunho': JSON.parse(localStorage.getItem('filtro_mes_cadastro_rascunho')) || '',
        //'mes_cadastro_entregue': JSON.parse(localStorage.getItem('filtro_mes_cadastro_entregue')) || '',
        'limpar_filtro_rascunho': localStorage.getItem('filtro_limpar_filtro_rascunho') || '',
        'limpar_filtro_entregue': localStorage.getItem('filtro_limpar_filtro_entregue') || '',
        'endereco_entregue': JSON.parse(localStorage.getItem('filtro_endereco_entregue')) || '',
        'endereco_rascunho': JSON.parse(localStorage.getItem('filtro_endereco_rascunho')) || '',
    });
    //const [pageNumber, setPageNumber] = useState(Number(localStorage.getItem('page_number')) || 1);
    const [pageNumberAdicionados, setPageNumberAdicionados] = useState(Number(localStorage.getItem('page_number')) || 1);
    const [pageNumberEnviados, setPageNumberEnviados] = useState(Number(localStorage.getItem('page_number')) || 1);
    const [quantFiltro] = useState([]);
    const [quantFiltroEnviados] = useState([]);
    const [totalProcessosFiltradosAdicionados, setTotalProcessosFiltradosAdicionados] = useState(0);
    const [totalProcessosFiltradosEnviados, setTotalProcessosFiltradosEnviados] = useState(0);

    /////////////////////////////////////////////////////////////////////////////////////////////////////
    //Venda

    const [perfilUsuario, setPerfilUsuario] = useState(false);

    //Cadastro Minuta
    const [isShowFormMinuta, setIsShowFormMinuta] = useState(false);
    const [posicaoMinuta, setPosicaoMinuta] = useState(0);
    const [dadosMinutaFormaPagamento, setDadosMinutaFormaPagamento] = useState([]);
    const [dadosVenda, setDadosVenda] = useState([]);
    const [dadosMinutaImovel, setDadosMinutaImovel] = useState([]);
    const [dadosMinutaVendedor, setDadosMinutaVendedor] = useState([]);
    const [dadosMinutaComprador, setDadosMinutaComprador] = useState([]);
    const [dadosMinutaRevisao, setDadosMinutaRevisao] = useState([]);
    const [dadosVincularResponsavel, setDadosVincularResponsavel] = useState([]);
    
    // Form PreCadastro
    const [isShowPreCadastro, setIsShowPreCadastro] = useState(false);

    // Form Imovel
    const [validateMinutaVendaCodImovel, setValidateMinutaVendaCodImovel] = useState(false);
    const [validateMinutaVendaCepMessage, setValidateMinutaVendaCepMessage] = useState(true)
    const [validateMinutaVendaCep, setValidateMinutaVendaCep] = useState(false);
    const [validateMinutaVendaNumero, setValidateMinutaVendaNumero] = useState(false);
    const [validateMinutaVendaUnidade, setValidateMinutaVendaUnidade] = useState(false);
    const [validateMinutaVendaEscritura, setValidateMinutaVendaEscritura] = useState(false);
    const [minutaVendaEscritura, setMinutaVendaEscritura] = useState('');
    const [validateMinutaVendaMatricula, setValidateMinutaVendaMatricula] = useState(false);
    const [validateMinutaVendaInscricaoMunicipal, setValidateMinutaVendaInscricaoMunicipal] = useState(false);
    const [validateMinutaVendaValorVenda, setValidateMinutaVendaValorVenda] = useState(false);
    const [validateMinutaVendaValorSinal, setValidateMinutaVendaValorSinal] = useState(false);
    const [validateMinutaVendaPrazoEscritura, setValidateMinutaVendaPrazoEscritura] = useState(false);
    const [validateMinutaVendaFormaPagamento, setValidateMinutaVendaFormaPagamento] = useState(false);
    const [validateMinutaVendaLaudemio, setValidateMinutaVendaLaudemio] = useState(true);
    const [formasPagamento, setFormasPagamento] = useState('');
    const [checkedMinutaAVista, setCheckedMinutaAVista] = useState(false);
    const [checkedMinutaFinanciamento, setCheckedMinutaFinanciamento] = useState(false);
    const [checkedMinutaFgts, setCheckedMinutaFgts] = useState(false);
    const [checkedMinutaConsorcio, setCheckedMinutaConsorcio] = useState(false);
    const [checkedMinutaParcelamento, setCheckedMinutaParcelamento] = useState(false);
    const [checkedMinutaPix, setCheckedMinutaPix] = useState(false);
    const [validateMinutaImovelSalvarContinuar, setValidateMinutaSalvarContinuar] = useState(false);
    const [minutaVendaLaudemio, setMinutaVendaLaudemio] = useState([]);
    const [primeiroLaudemio, setPrimeiroLaudemio] = useState(true);

    //Form Vendedor
    const [validateMinutaVendedorGenero, setValidateMinutaVendedorGenero] = useState(false);
    const [validateMinutaVendedorCpf, setValidateMinutaVendedorCpf] = useState(false);
    const [validateMinutaVendedorNome, setValidateMinutaVendedorNome] = useState(false);
    const [validateMinutaVendedorNomeMae, setValidateMinutaVendedorNomeMae] = useState(false);
    const [validateMinutaVendedorNacionalidade, setValidateMinutaVendedorNacionalidade] = useState(false);
    const [validateMinutaVendedorDataNascimento, setValidateMinutaVendedorDataNascimento] = useState(false);
    const [validateMinutaVendedorTelefone, setValidateMinutaVendedorTelefone] = useState(false);
    const [validateMinutaVendedorCepMessage, setValidateMinutaVendedorCepMessage] = useState(true);
    const [validateMinutaVendedorCep, setValidateMinutaVendedorCep] = useState(false);
    const [validateMinutaVendedorUnidade, setValidateMinutaVendedorUnidade] = useState(false);
    const [validateMinutaVendedorRgExpedicao, setValidateMinutaVendedorRgExpedicao] = useState(false);
    const [validateMinutaVendedorDataExpedicao, setValidateMinutaVendedorDataExpedicao] = useState(false);
    const [validateMinutaVendedorNumero, setValidateMinutaVendedorNumero] = useState(false);
    const [validateMinutaVendedorRg, setValidateMinutaVendedorRg] = useState(false);
    const [validateMinutaVendedorEstadoCivil, setValidateMinutaVendedorEstadoCivil] = useState(false);
    const [validateMinutaVendedorRegimeCasamento, setValidateMinutaVendedorRegimeCasamento] = useState(false);
    const [checkedMinutaVendedorUniaoEstavel, setCheckedMinutaVendedorUniaoEstavel] = useState('');
    const [minutaVendedorEstadoCivil, setMinutaVendedorEstadoCivil] = useState('0');
    const [minutaVendedorRegimeCasamento, setMinutaVendedorRegimeCasamento] = useState('0');
    const [minutaVendedorUniaoEstavel, setMinutaVendedorUniaoEstavel] = useState('');
    const [validateMinutaVendedorConjuge, setValidateMinutaVendedorConjuge] = useState(false);
    const [validateMinutaNomeFantasia, setValidateMinutaNomeFantasia] = useState(true);

    //Form Comprador
    const [validateMinutaCompradorGenero, setValidateMinutaCompradorGenero] = useState(false);
    const [validateMinutaCompradorCpf, setValidateMinutaCompradorCpf] = useState(false);
    const [validateMinutaCompradorNome, setValidateMinutaCompradorNome] = useState(false);
    const [validateMinutaCompradorDataNascimento, setValidateMinutaCompradorDataNascimento] = useState(false);
    const [validateMinutaCompradorNomeMae, setValidateMinutaCompradorNomeMae] = useState(false);
    const [validateMinutaCompradorNacionalidade, setValidateMinutaCompradorNacionalidade] = useState(false);
    const [validateMinutaCompradorTelefone, setValidateMinutaCompradorTelefone] = useState(false);
    const [validateMinutaCompradorCepMessage, setValidateMinutaCompradorCepMessage] = useState(true);
    const [validateMinutaCompradorCep, setValidateMinutaCompradorCep] = useState(false);
    const [validateMinutaCompradorUnidade, setValidateMinutaCompradorUnidade] = useState(false);
    const [validateMinutaCompradorRgExpedicao, setValidateMinutaCompradorRgExpedicao] = useState(false);
    const [validateMinutaCompradorDataExpedicao, setValidateMinutaCompradorDataExpedicao] = useState(false);
    const [validateMinutaCompradorNumero, setValidateMinutaCompradorNumero] = useState(false);
    const [validateMinutaCompradorRg, setValidateMinutaCompradorRg] = useState(false);
    const [validateMinutaCompradorEstadoCivil, setValidateMinutaCompradorEstadoCivil] = useState(false);
    const [validateMinutaCompradorRegimeCasamento, setValidateMinutaCompradorRegimeCasamento] = useState(false);
    const [checkedMinutaCompradorUniaoEstavel, setCheckedMinutaCompradorUniaoEstavel] = useState('');
    const [minutaCompradorEstadoCivil, setMinutaCompradorEstadoCivil] = useState('0');
    const [minutaCompradorRegimeCasamento, setMinutaCompradorRegimeCasamento] = useState('0');
    const [minutaCompradorUniaoEstavel, setMinutaCompradorUniaoEstavel] = useState('');
    const [validateMinutaCompradorConjuge, setValidateMinutaCompradorConjuge] = useState(false);

    //Form Upload Recibo de Sinal
    const [isShowFormUploadRecibo, setIsShowFormUploadRecibo] = useState(false);
    const [validateUploadReciboDataAssinatura, setValidateUploadReciboDataAssinatura] = useState(false);
    const [validateUploadReciboDocAssinado, setValidateUploadReciboDocAssinado] = useState(false);
    const [docAssinado, setDocAssinado] = useState([]);
    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////////
    //Boletim de Venda

    //Tabs
    const [vendedorProcesso, setVendedorProcesso] = useState([]);
    const [compradorProcesso, setCompradorProcesso] = useState([]);
    const [tabsVendedor, setTabsVendedor] = useState(false);
    const [tabsComprador, setTabsComprador] = useState(false);
    const [tabsVisualization, setTabsVisualization] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0); //Exibição da tab selecionada
    const [documentosProcesso, setDocumentosProcesso] = useState([]);
    const [statusPrimeiraVisualizacaoGerente, setStatusPrimeiraVisualizacaoGerente] = useState('');
    const [statusRelacaoProcessoId, setStatusRelacaoProcessoId] = useState('');
    const [idProcessosModal, setIdProcessosModal] = useState([]);
    const [tabs] = useState(['Em rascunho', 'Entregues']);
    const [selectTabs, setSelectTabs] = useState(0);

    const [checkedExibirInfoBoletimVenda, setCheckedExibirInfoBoletimVenda] = useState(false);

    //Cadastro BoletimDeVenda de Venda
    const [isShownTipoVenda, setIsShownTipoVenda] = useState(false);
    const [isShowFormBoletimDeVenda, setIsShowFormBoletimDeVenda] = useState(false);
    const [posicaoBoletimDeVenda, setPosicaoBoletimDeVenda] = useState(0);
    const [dadosBoletimDeVendaImovel, setDadosBoletimDeVendaImovel] = useState([]);
    const [dadosBoletimDeVendaVendedor, setDadosBoletimDeVendaVendedor] = useState([]);
    const [dadosBoletimDeVendaComprador, setDadosBoletimDeVendaComprador] = useState([]);
    const [dadosBoletimDeVendaComissao, setDadosBoletimDeVendaComissao] = useState([]);

    //Validação Form Boletim de Venda
    const [verificaRgi, setVerificaRgi] = useState(false);
    const [validateFieldRgi, setValidateFieldRgi] = useState(false);
    const [validateFieldCep, setValidateFieldCep] = useState(false);
    const [validateFieldDataAssinatura, setValidateFieldDataAssinatura] = useState(false);
    const [validateFieldFormaPagamento, setValidateFieldFormaPagamento] = useState(false);
    const [validateInputFile, setValidateInputFile] = useState(false);
    const [validateCepMessage, setValidateCepMessage] = useState(true);
    const [validateAdress, setValidateAdress] = useState();
    const [validateFieldCodImovel, setValidateFieldCodImovel] = useState(false);
    const [validateFieldValorVenda, setValidateFieldValorVenda] = useState(false);
    const [validateFieldDataVenda, setValidateFieldDataVenda] = useState(false);
    const [validateBoletimVendaDocumentos, setValidateBoletimVendaDocumentos] = useState(false);

    //Vendedor
    const [validateBoletimVendaDocumentosVendedor, setValidateBoletimVendaDocumentosVendedor] = useState(false);
    const [validateProcuradorVendedor, setValidateProcuradorVendedor] = useState(true);
    const [validateTelefoneProcuradorVendedor, setValidateTelefoneProcuradorVendedor] = useState(true);
    const [checkedProcuradorVendedor, setCheckedProcuradorVendedor] = useState(false);

    //Comprador
    const [validateBoletimVendaDocumentosComprador, setValidateBoletimVendaDocumentosComprador] = useState(false);
    const [validateProcuradorComprador, setValidateProcuradorComprador] = useState(true);
    const [validateTelefoneProcuradorComprador, setValidateTelefoneProcuradorComprador] = useState(true);
    const [checkedProcuradorComprador, setCheckedProcuradorComprador] = useState(false);

    //Comissão
    const [total] = React.useState([
        { label: 'Integral', value: 'integral' },
        { label: 'Parcelada', value: 'partes' },
    ])

    const [listaPeriodo] = useState([
        { label: 'Na assinatura do Recibo de Sinal', value: '1' },
        { label: 'Na retirada das certidões', value: '2' },
        { label: 'No ato da escritura do Imóvel', value: '3' },
        { label: 'Na entrega das chaves', value: '4' },
        { label: 'Na transferência de registros', value: '5' },
    ])

    const [listaPeriodo_gerente] = useState([
        { label: 'Na assinatura do Recibo de Sinal', value: '1' },
        { label: 'Na retirada das certidões', value: '2' },
        { label: 'No ato da escritura do Imóvel', value: '3' },
        //{ label: 'Na entrega das chaves', value: '4' },
        { label: 'Na transferência de registros', value: '5' },
    ])

    const [liquida] = React.useState([
        { label: 'Espécie', value: 'especie' },
        { label: 'Depósito', value: 'deposito' },
        { label: 'TED/DOC', value: 'ted_doc' },
        { label: 'PIX', value: 'pix' },
        { label: 'Cheque/Cheque Administrativo', value: 'cheque' }
    ])

    const [validateBoletimVendaDocumentosComissao, setValidateBoletimVendaDocumentosComissao] = useState(false);
    const [totalRadiusComissao, setTotalRadiusComissao] = useState('integral');
    const [liquidaRadius, setLiquidaRadius] = useState('null');
    const [checkedPrimeiraParte, setCheckedPrimeiraParte] = useState(false)
    const [checkedSegundaParte, setCheckedSegundaParte] = useState(false)
    const [comissaoTotal, setComissaoTotal] = useState('');
    const [validateRadiusTotal, setValidateRadiusTotal] = useState(true);
    const [validateRadiusLiquida, setValidateRadiusLiquida] = useState(false);
    const [validateComissaoTotal, setValidateComissaoTotal] = useState(false);
    const [validateComissaoLiquida, setValidateComissaoLiquida] = useState(false);

    /////////////////////////////////////////////////////////////////////////////////////////////////////

    //Notificações
    const [isShowCornerDialog, setIsShowCornerDialog] = useState(false);
    const [modelCornerDialog, setModelCornerDialog] = useState(4);
    const [isShowSendBackOffice, setIsShowSendBackOffice] = useState(false);
    const [isShowEntregarVenda, setIsShowEntregarVenda] = useState(false);
    const [regressivaEntregarVenda, setRegressivaEntregarVenda] = useState(5);

    //Checked Form Recibo
    const [checkedAVista, setCheckedAVista] = useState(false);
    const [checkedFinanciamento, setCheckedFinanciamento] = useState(false);
    const [checkedFgts, setCheckedFgts] = useState(false);
    const [aVista, setAvista] = useState('');
    const [financiamento, setFinanciamento] = useState('');
    const [fgts, setFgts] = useState('');

    //Tipos de Documento
    const [tipoDocumentos, setTipoDocumentos] = useState([]);
    const [tipoDocumentosTipos, setTipoDocumentosTipos] = useState([]);

    //Origem Comprador
    const [origemComprador, setOrigemComprador] = useState([]);

    //Lista Laudemios
    const [tipoLaudemio, setTipoLaudemio] = useState([]);

    //SideSheet
    const [isShownSide, setIsShownSide] = useState(false);
    const [selectedIndexEnviados, setSelectedIndexEnviados] = useState(0)
    const [selectedIndexRascunho, setSelectedIndexRascunho] = useState(0)
    const [selectedIndexBoletimDeVenda, setSelectedIndexBoletimDeVenda] = useState(0);
    const [showTabsDetalhes, setShowTabsDetalhes] = useState([true, false, false, false]);
    const [showTabsRascunho, setShowTabsRascunho] = useState([true, false, false]);
    const [showTabsBoletim, setShowTabsBoletim] = useState([true, false, false, false]);

    //Utilizado para contar quais menus foram passados e poder voltar com Click no titulo
    const [countMenu, setCountMenu] = useState(0);

    //FeedBack
    const [isShowFeedBack, setIsShowFeedBack] = useState(false);
    const usuario_id = localStorage.getItem('usuario_id');

    //Dialog Remover Venda
    const [isShowRemover, setIsShowRemover] = useState(false);
    const [itemsArquivados, setItemsArquivados] = useState([]);
    const [processoIdRemove, setProcessoIdRemove] = useState(0);

    //Dialog Restaurar Venda
    const [isShowRestore, setIsShowRestore] = useState(false);
    const [totalPaginasAdicionados, setTotalPaginasAdicionados] = useState('');
    const [totalPaginasEntregues, setTotalPaginasEntregues] = useState('');
    const [alteracaoRevisao, setAlteracaoRevisao] = useState(false);
    const [holidays, setHolidays] = useState([]);
    const [divergenciaEntrega, setDivergenciaEntrega] = useState(''); 

    // LIST ESCRITURAS
    const [listEscrituras, setListEscrituras] = useState([]);

    async function holidayList(ano) {
        const holidayList = await getHolidays(ano);        
        if(holidayList) setHolidays(holidayList);
    };

    useEffect(() => {
        holidayList("2023")
    },[])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    // Feedback Confirmar Escritura
    const [modalEscritura, setModalEscritura] = useState(false);
    const [showListaEscritura, setShowListaEscritura] = useState(false);
    const [listaEscritura, setListaEscritura] = useState([]);
    const [selectProcessoEscritura, setSelectProcessoEscritura] = useState([]);
    const [isShowFeedBackEscritura, setIsShowFeedbackEscritura] = useState(false);
    const [perguntasEscritura, setPerguntasEscritura] = useState([]);

   

    const [multiDocs, setMultiDocs] = useState({
        'imovel': [{item: [], file: '', id: ''}],
        'vendedores': [
          [{item: [], file: '', id: ''}]
        ],
        'compradores': [      
          [{item: [], file: '', id: '' }]
        ]
      });

    //  MULTIPLOS DOCUMENTOS    
    const [isShowConfirmDel, setIsShowConfirmDel] = useState(false);
    const [itemToRemove, setItemToRemove] = useState();  
    const [listaDocumentos, setListaDocumentos] = useState([]);
    
    // Context
    const conxtextStatus = {

        alteracaoRevisao,
        setAlteracaoRevisao,
        holidays,        
        listEscrituras,

        //Count menu
        countMenu, setCountMenu,

        //Listas
        tipoLaudemio,
        listEnderecos,

        //Exibir Botão Gerar Venda
        exibirGerarVenda, setExibirGerarVenda,

        //Tabs Geral
        tabsVendedor, setTabsVendedor,
        tabsComprador, setTabsComprador,
        setSelectTabs,

        //Remover venda
        isShowRemover, setIsShowRemover,
        processoIdRemove, setProcessoIdRemove,
        itemsArquivados, setItemsArquivados,

        //Restaurar Venda
        isShowRestore, setIsShowRestore,

        //Tabs Boletim de Vendas
        vendedorProcesso, setVendedorProcesso,
        compradorProcesso, setCompradorProcesso,
        tabsVisualization, setTabsVisualization,
        selectedIndex, setSelectedIndex,
        documentosProcesso, setDocumentosProcesso,
        statusPrimeiraVisualizacaoGerente, setStatusPrimeiraVisualizacaoGerente,
        statusRelacaoProcessoId, setStatusRelacaoProcessoId,
        idProcessosModal, setIdProcessosModal,

        checkedExibirInfoBoletimVenda, setCheckedExibirInfoBoletimVenda,

        //Boletim de Venda
        loading, setLoading,
        itemAdicionado, setItemAdicionado,
        itemEnviado, setItemEnviado,
        dadosReciboEnviado, setDadosReciboEnviado,
        isShowCornerDialog, setIsShowCornerDialog,
        modelCornerDialog, setModelCornerDialog,
        isShowSendBackOffice, setIsShowSendBackOffice,
        dadosRecibo, setDadosRecibo,
        verificaRgi, setVerificaRgi,
        validateFieldRgi, setValidateFieldRgi,
        validateFieldCep, setValidateFieldCep,
        validateFieldDataAssinatura, setValidateFieldDataAssinatura,
        validateFieldFormaPagamento, setValidateFieldFormaPagamento,
        validateInputFile, setValidateInputFile,
        validateCepMessage, setValidateCepMessage,
        validateAdress, setValidateAdress,
        validateBoletimVendaDocumentos, setValidateBoletimVendaDocumentos,
        checkedAVista, setCheckedAVista,
        checkedFinanciamento, setCheckedFinanciamento,
        checkedFgts, setCheckedFgts,
        isShowFeedBack, setIsShowFeedBack,
        estadoRecibo, setEstadoRecibo,
        isShownSide, setIsShownSide,
        selectedIndexEnviados, setSelectedIndexEnviados,
        selectedIndexRascunho, setSelectedIndexRascunho,
        selectedIndexBoletimDeVenda, setSelectedIndexBoletimDeVenda,
        showTabsDetalhes, setShowTabsDetalhes,
        showTabsRascunho, setShowTabsRascunho,
        showTabsBoletim, setShowTabsBoletim,
        aVista, setAvista,
        financiamento, setFinanciamento,
        fgts, setFgts,
        validateFieldCodImovel, setValidateFieldCodImovel,
        validateFieldValorVenda, setValidateFieldValorVenda,
        validateFieldDataVenda, setValidateFieldDataVenda,
        isShownTipoVenda, setIsShownTipoVenda,
        isShownFormRecibo, setIsShownFormRecibo,   
        
        perfilUsuario, setPerfilUsuario,

        //Form Boletim de Venda
        isShowFormBoletimDeVenda, setIsShowFormBoletimDeVenda,
        posicaoBoletimDeVenda, setPosicaoBoletimDeVenda,
        tipoDocumentos, setTipoDocumentos,
        listaDocumentos,
        tipoDocumentosTipos, setTipoDocumentosTipos,
        origemComprador, setOrigemComprador,
        dadosBoletimDeVendaImovel, setDadosBoletimDeVendaImovel,
        dadosBoletimDeVendaVendedor, setDadosBoletimDeVendaVendedor,
        dadosBoletimDeVendaComprador, setDadosBoletimDeVendaComprador,
        dadosBoletimDeVendaComissao, setDadosBoletimDeVendaComissao,
  
        
        //Pre cadastro
        isShowPreCadastro, setIsShowPreCadastro,

        //Vendedor
        validateProcuradorVendedor, setValidateProcuradorVendedor,
        validateTelefoneProcuradorVendedor, setValidateTelefoneProcuradorVendedor,
        checkedProcuradorVendedor, setCheckedProcuradorVendedor,
        validateBoletimVendaDocumentosVendedor, setValidateBoletimVendaDocumentosVendedor,

        //Comprador
        validateProcuradorComprador, setValidateProcuradorComprador,
        validateTelefoneProcuradorComprador, setValidateTelefoneProcuradorComprador,
        checkedProcuradorComprador, setCheckedProcuradorComprador,
        validateBoletimVendaDocumentosComprador, setValidateBoletimVendaDocumentosComprador,

        //Comissão
        total,
        liquida,
        liquidaRadius, setLiquidaRadius,
        totalRadiusComissao, setTotalRadiusComissao,
        checkedPrimeiraParte, setCheckedPrimeiraParte,
        checkedSegundaParte, setCheckedSegundaParte,
        comissaoTotal, setComissaoTotal,
        validateRadiusTotal, setValidateRadiusTotal,
        validateRadiusLiquida, setValidateRadiusLiquida,
        validateComissaoTotal, setValidateComissaoTotal,
        validateComissaoLiquida, setValidateComissaoLiquida,
        validateBoletimVendaDocumentosComissao, setValidateBoletimVendaDocumentosComissao,
        listaPeriodo,
        listaPeriodo_gerente,

        /////////////////////////////////////////////////////////////////////

        //Venda
        isShowFormMinuta, setIsShowFormMinuta,
        posicaoMinuta, setPosicaoMinuta,
        dadosMinutaFormaPagamento, setDadosMinutaFormaPagamento,
        dadosVenda, setDadosVenda,
        dadosMinutaImovel, setDadosMinutaImovel,
        dadosVincularResponsavel, setDadosVincularResponsavel,
        dadosMinutaVendedor, setDadosMinutaVendedor,
        dadosMinutaComprador, setDadosMinutaComprador,
        dadosMinutaRevisao, setDadosMinutaRevisao,

        //Form Venda
        validateMinutaVendaCodImovel, setValidateMinutaVendaCodImovel,
        validateMinutaVendaCepMessage, setValidateMinutaVendaCepMessage,
        validateMinutaVendaCep, setValidateMinutaVendaCep,
        validateMinutaVendaNumero, setValidateMinutaVendaNumero,
        validateMinutaVendaUnidade, setValidateMinutaVendaUnidade,
        validateMinutaVendaEscritura, setValidateMinutaVendaEscritura,
        minutaVendaEscritura, setMinutaVendaEscritura,
        validateMinutaVendaMatricula, setValidateMinutaVendaMatricula,
        validateMinutaVendaInscricaoMunicipal, setValidateMinutaVendaInscricaoMunicipal,
        validateMinutaVendaValorVenda, setValidateMinutaVendaValorVenda,
        validateMinutaVendaValorSinal, setValidateMinutaVendaValorSinal,
        validateMinutaVendaPrazoEscritura, setValidateMinutaVendaPrazoEscritura,
        validateMinutaVendaFormaPagamento, setValidateMinutaVendaFormaPagamento,
        validateMinutaVendaLaudemio, setValidateMinutaVendaLaudemio,
        formasPagamento, setFormasPagamento,
        checkedMinutaAVista, setCheckedMinutaAVista,
        checkedMinutaFinanciamento, setCheckedMinutaFinanciamento,
        checkedMinutaFgts, setCheckedMinutaFgts,
        checkedMinutaConsorcio, setCheckedMinutaConsorcio,
        checkedMinutaParcelamento, setCheckedMinutaParcelamento,
        checkedMinutaPix, setCheckedMinutaPix,
        validateMinutaImovelSalvarContinuar, setValidateMinutaSalvarContinuar,
        minutaVendaLaudemio, setMinutaVendaLaudemio,
        primeiroLaudemio, setPrimeiroLaudemio,

        //Form Vendedor
        validateMinutaVendedorGenero, setValidateMinutaVendedorGenero,
        validateMinutaVendedorCpf, setValidateMinutaVendedorCpf,
        validateMinutaVendedorNome, setValidateMinutaVendedorNome,
        validateMinutaVendedorNomeMae, setValidateMinutaVendedorNomeMae,
        validateMinutaVendedorNacionalidade, setValidateMinutaVendedorNacionalidade,
        validateMinutaVendedorDataNascimento, setValidateMinutaVendedorDataNascimento,
        validateMinutaVendedorTelefone, setValidateMinutaVendedorTelefone,
        validateMinutaVendedorCepMessage, setValidateMinutaVendedorCepMessage,
        validateMinutaVendedorCep, setValidateMinutaVendedorCep,
        validateMinutaVendedorUnidade, setValidateMinutaVendedorUnidade,
        validateMinutaVendedorRgExpedicao, setValidateMinutaVendedorRgExpedicao,
        validateMinutaVendedorDataExpedicao, setValidateMinutaVendedorDataExpedicao,
        validateMinutaVendedorNumero, setValidateMinutaVendedorNumero,
        validateMinutaVendedorRg, setValidateMinutaVendedorRg,
        validateMinutaVendedorEstadoCivil, setValidateMinutaVendedorEstadoCivil,
        validateMinutaVendedorRegimeCasamento, setValidateMinutaVendedorRegimeCasamento,
        checkedMinutaVendedorUniaoEstavel, setCheckedMinutaVendedorUniaoEstavel,
        minutaVendedorEstadoCivil, setMinutaVendedorEstadoCivil,
        minutaVendedorRegimeCasamento, setMinutaVendedorRegimeCasamento,
        minutaVendedorUniaoEstavel, setMinutaVendedorUniaoEstavel,
        validateMinutaVendedorConjuge, setValidateMinutaVendedorConjuge,
        validateMinutaNomeFantasia, setValidateMinutaNomeFantasia,

        //Form Comprador
        validateMinutaCompradorGenero, setValidateMinutaCompradorGenero,
        validateMinutaCompradorCpf, setValidateMinutaCompradorCpf,
        validateMinutaCompradorNome, setValidateMinutaCompradorNome,
        validateMinutaCompradorDataNascimento, setValidateMinutaCompradorDataNascimento,
        validateMinutaCompradorNomeMae, setValidateMinutaCompradorNomeMae,
        validateMinutaCompradorNacionalidade, setValidateMinutaCompradorNacionalidade,
        validateMinutaCompradorTelefone, setValidateMinutaCompradorTelefone,
        validateMinutaCompradorCepMessage, setValidateMinutaCompradorCepMessage,
        validateMinutaCompradorCep, setValidateMinutaCompradorCep,
        validateMinutaCompradorUnidade, setValidateMinutaCompradorUnidade,
        validateMinutaCompradorRgExpedicao, setValidateMinutaCompradorRgExpedicao,
        validateMinutaCompradorDataExpedicao, setValidateMinutaCompradorDataExpedicao,
        validateMinutaCompradorNumero, setValidateMinutaCompradorNumero,
        validateMinutaCompradorRg, setValidateMinutaCompradorRg,
        validateMinutaCompradorEstadoCivil, setValidateMinutaCompradorEstadoCivil,
        validateMinutaCompradorRegimeCasamento, setValidateMinutaCompradorRegimeCasamento,
        checkedMinutaCompradorUniaoEstavel, setCheckedMinutaCompradorUniaoEstavel,
        minutaCompradorEstadoCivil, setMinutaCompradorEstadoCivil,
        minutaCompradorRegimeCasamento, setMinutaCompradorRegimeCasamento,
        minutaCompradorUniaoEstavel, setMinutaCompradorUniaoEstavel,
        validateMinutaCompradorConjuge, setValidateMinutaCompradorConjuge,

        //Form Upload Recibo de Sinal
        isShowFormUploadRecibo, setIsShowFormUploadRecibo,
        validateUploadReciboDataAssinatura, setValidateUploadReciboDataAssinatura,
        validateUploadReciboDocAssinado, setValidateUploadReciboDocAssinado,
        docAssinado, setDocAssinado,

        //Entregar Venda
        isShowEntregarVenda, setIsShowEntregarVenda,
        regressivaEntregarVenda, setRegressivaEntregarVenda,

        //listaGerente
        listaGerente,

        //filtro
        filtro,
        //pageNumber, setPageNumber, 
        pageNumberAdicionados, setPageNumberAdicionados,
        pageNumberEnviados, setPageNumberEnviados,
        quantFiltro, quantFiltroEnviados,
        totalProcessosFiltradosAdicionados, setTotalProcessosFiltradosAdicionados,
        totalProcessosFiltradosEnviados, setTotalProcessosFiltradosEnviados,
        listaStatusProcesso, listaResponsaveis,
        totalItensAdicionados, setTotalItensAdicionados,
        totalItensEntregues, setTotalItensEntregues,
        totalPaginasAdicionados, setTotalPaginasAdicionados,
        totalPaginasEntregues, setTotalPaginasEntregues,
        listaLojas,
        listaOpicionistas, setListaOpicionistas,

        //loading
        defaultOptions,

        divergenciaEntrega, setDivergenciaEntrega,

        //Feedback Escritura
        modalEscritura, setModalEscritura,
        showListaEscritura, setShowListaEscritura,
        listaEscritura, setListaEscritura,
        selectProcessoEscritura, setSelectProcessoEscritura,
        isShowFeedBackEscritura, setIsShowFeedbackEscritura,
        perguntasEscritura, setPerguntasEscritura,

        //Múltiplos documentos
        isShowConfirmDel, setIsShowConfirmDel,
        itemToRemove, setItemToRemove,
        multiDocs, setMultiDocs
    }
    useEffect(() => {
        const retornaListaEscritura = async () => {
            const escrituras = await getGerenteConfirmarEscritura();
            if(escrituras.length === 1){
                setSelectProcessoEscritura(escrituras[0]);
            }

            if(escrituras.length > 0){
                setModalEscritura(true);
                setListaEscritura(escrituras);

                if(escrituras.length > 1) setShowListaEscritura(true);
                else setShowListaEscritura(false);

                if(escrituras.length === 1){
                    setSelectProcessoEscritura(escrituras[0]);
                }
            }
            else{
                setModalEscritura(false);
            }
        }
        retornaListaEscritura();
        
        ListEnderecos();    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function ListEnderecos () {
        const entregues = await getListEnderecos('entregues', usuario_id);
        const rascunhos = await getListEnderecos('rascunhos', usuario_id);
        setListEnderecos({entregues: entregues, rascunhos: rascunhos});
    }; 

    useEffect(() => {
        const exibirImoveisAdicionados = async () => {
            let dados = {
                //"usuario_id": filtro.gerente_rascunho ? filtro.gerente_rascunho : usuario_id,
                "usuario_id": usuario_id,
                filtro: filtro.limpar_filtro_rascunho,
                'tipo_processos': 'adicionados',
                mes_cadastro: filtro.mes_cadastro_rascunho
            }

            if (perfil_login === 'Gerente Geral') {
                let loja_id = localStorage.getItem('loja_id');
                dados['loja_id'] = loja_id;
                localStorage.setItem('filtro_loja_rascunho', loja_id);
                localStorage.setItem('filtro_loja_entregue', loja_id);
                filtro.loja_rascunho = loja_id;
                filtro.loja_entregue = loja_id;
            } else {
                dados['loja_id'] = filtro.loja_rascunho;
            }

            await Axios.post(app + 'retorno_processos_painel_gerentes', dados, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }).then(res => {
                if (!res || res?.data?.status === 498 || res?.data?.status === 401) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    const itensAdicionados = res.data.processos.data;
                    let totalItens = res.data.total_processos;
                    let totalPaginas = res.data.total_pagina;

                    setItemAdicionado(itensAdicionados);
                    setTotalItensAdicionados(totalItens);
                    setTotalPaginasAdicionados(totalPaginas);
                    listaLaudemios();
                    listaTipoDocumentos();
                    listaOrigemComprador();
                    listaStatus();
                    listaOpicionistas();
                    listar_responsaveis();
                    
                    setTotalProcessosFiltradosAdicionados(res.data.total_processos_filtrados);
                    if (perfil_login === 'Gerente Geral') {
                        listar_gerentes('gg');
                    } else if (perfil_login === 'Diretor Comercial') {
                        listar_gerentes('dc');
                        lista_lojas();
                    }
                    //setLoading(true);
                }
            })

            const listEscriturasData = await getListEscrituras();
            setListEscrituras(listEscriturasData);
        };

        const exibirImoveisEnviados = () => {
            let dados = {
                "usuario_id": !filtro.gerente_entregue ? usuario_id : filtro.gerente_entregue,
                responsavel_id: Number(localStorage.getItem('filtro_responsavel')) || '',
                status_id: filtro.status,
                filtro: filtro.limpar_filtro_entregue,
                'tipo_processos': 'entregues',
                loja_id: filtro.loja_entregue,
            }

            Axios.post(app + 'retorno_processos_painel_gerentes', dados, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }).then(res => {
                if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    const itensEnviados = res.data.processos.data;
                    let totalItens = res.data.total_processos;
                    let totalPaginas = res.data.total_pagina;
                    setTotalItensEntregues(totalItens);
                    setTotalPaginasEntregues(totalPaginas);
                    setTotalProcessosFiltradosEnviados(res.data.total_processos_filtrados);
                    setItemEnviado(itensEnviados);
                }
            })
        };

        const exibirImoveisExcluidos = () => {
            Axios.post(app + 'retorno_processos_painel_gerentes', {
                "usuario_id": usuario_id,
                'tipo_processos': 'arquivados',
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }).then(res => {
                if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    const itemsArquivados = res.data.processos.data;
                    setItemsArquivados(itemsArquivados)
                }
            })
        };

        const listar_gerentes = async (perfil) => {
            let dados = {};
            if (perfil === 'gg') {
                dados['gg_id'] = usuario_id;
            } else if (perfil === 'dc') {
                dados['perfil'] = 'diretor_geral';
            }
            await Axios.post(app + 'listar_gerentes', dados, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }).then(res => {
                if (res !== undefined) {
                    if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                        localStorage.clear();
                        history.push('/');
                    } else {
                        const listaGerentes = res.data.data;
                        setListaGerente(listaGerentes.map(gerente => { return { "id": gerente.id, "name": gerente.name } }));
                    }
                }
            })
        }

        const listaLaudemios = async () => {
            await Axios.get(app + 'listlaudemio', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }).then(res => {
                if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    setTipoLaudemio(res.data.data);
                    setLoading(true);
                }
            })
        };

        const listaTipoDocumentos = async () => {

            await Axios.get(app + 'tipodocumentos', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }).then(res => {
                if (res !== undefined) {
                    if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                        localStorage.clear();
                        history.push('/');
                    } else {
                        const tipodocumentos = res.data.data;
                        setTipoDocumentos(tipodocumentos);
                        setListaDocumentos(tipodocumentos);
                    }
                }
            })
        };

        const listaOrigemComprador = async () => {

            await Axios.get(app + 'lista_origem_cliente', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }).then(res => {
                if (res !== undefined) {
                    if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                        localStorage.clear();
                        history.push('/');
                    } else {
                        const origem_comprador = res.data;
                        setOrigemComprador(origem_comprador);
                    }
                }
            })
        };

        const listaStatus = async () => {
            await Axios.get(app + 'progressstatus', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }).then(res => {
                if (res !== undefined) {
                    if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                        localStorage.clear();
                        history.push('/');
                    } else {
                        const list_status = res.data.data;
                        listaStatusProcesso[0] = (list_status);
                    }
                }
                setLoading(true);
            })
        }

        const listar_responsaveis = async () => {
            await Axios.post(app + 'listar_responsaveis', {
                'token': token
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }).then(res => {
                if (res !== undefined) {
                    if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                        localStorage.clear();
                        history.push('/');
                    } else {
                        const lista_responsaveis = res.data.data;
                        const novo_responsavel = lista_responsaveis.map(usuario => ({ "id": usuario.id, "name": usuario.name }));
                        listaResponsaveis[0] = (novo_responsavel);
                    }
                }
            })
        }

        const listaOpicionistas = async () => {
            await Axios.get(app + 'lista_opcionista', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }).then(res => {
                if (res !== undefined) {
                    if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                        localStorage.clear();
                        history.push('/');
                    } else {
                        const lista_opcionista = res.data;
                        setListaOpicionistas(lista_opcionista);
                    }
                }
                setLoading(true);
            })
        }

        const lista_lojas = async () => {
            await Axios.get(app + 'listar_lojas', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }).then(res => {
                if (res !== undefined) {
                    if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                        localStorage.clear();
                        history.push('/');
                    } else {
                        const list_lojas = res.data.data;
                        setListaLojas(list_lojas);
                    }
                }
                setLoading(true);
            })
        }
                            
        exibirImoveisExcluidos();
        exibirImoveisEnviados();
        exibirImoveisAdicionados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app, token])
    

    return (
        <Pane>
            <VendasContext.Provider value={conxtextStatus}>
                <Tablist marginTop={30}>
                    {tabs.map((tab, index) => (
                        <Tab
                            key={tab}
                            id={tab}
                            onSelect={() => setSelectTabs(index)}
                            isSelected={index === selectTabs}
                            aria-controls={`panel-${tab}`}
                            appearance="primary"
                            height={44}
                            marginBottom={20}
                            size={'medium'}
                        >
                            {tab}
                        </Tab>
                    ))}
                </Tablist>
                {
                    selectTabs === 0 && (
                        perfil_login === 'Gerente'
                            ? <VendasAdicionadas /> // Gerente
                            : perfil_login === 'Gerente Geral'
                                ? <VendasAdicionadasGG /> // Gerente Geral
                                : <VendasAdicionadasDG /> // Diretor Comercial
                    )
                }
                {
                    selectTabs === 1 &&
                    (perfil_login === 'Gerente'
                        ? <VendasEnviadas /> // Gerente
                        : perfil_login === 'Gerente Geral'
                            ? <VendasEnviadas /> // Gerente Geral
                            : <VendasEnviadasDC /> // Diretor Comercial
                    )
                }
                {isShowFormUploadRecibo === true && < FormUploadReciboVenda />}
                {isShowFormBoletimDeVenda === true && <FormBoletimDeVenda />}
                <DialogConfirmacaoEscritura />
                <SendToBackOffice />
                <CornerDialog />
                <CornerDialogFeedbackEscritura />
                <FeedBack />
                <ChangeLog data="Vendas" />
            </VendasContext.Provider>
        </Pane>
    )
}