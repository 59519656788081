import React, {useContext} from 'react'
import {Badge} from 'evergreen-ui';
import AcessoTemporarioContext from '../../../../../context/AcessoTemporarioContext';

export default function Historico() {
    const {
        statusHistorico,
    } = useContext(AcessoTemporarioContext);

    return (
        <div className="coll-historico">
            <h2>Histórico</h2>
            <ul>

                {
                    statusHistorico.filter(status => status.id !== 1).map(status => <li>
                        <div className="data">{status.data_historico}</div>
                        <div style={{width: 'auto'}}>
                            <Badge 
                                //color="green" 
                                className="badge"
                            >
                                {
                                    status.id === 22
                                    ? 'Análise'
                                    : status.status
                                }
                            </Badge>
                        </div>
                        
                        <div className='text'>
                            {
                                '• ' + status.mensagem_vendedor_comprador
                            }
                        </div>
                    </li>)
                }

                {/* <li>
                    <div className="data">{statusHistorico[0].data_historico}</div>
                    <div style={{width: '145px'}}>
                        <Badge color="green" className="badge">
                            Análise
                        </Badge>
                    </div>
                    <div className='text'>Analisando a documentação para dar início ao processo.</div>
                </li> */}
            </ul>
        </div>
    )
}