import Axios from 'axios';
import Serve from '../app/serve';
// API QUE ALIMENTA A TABLE DE SERVIÇOS DO NUCLEO

async function getResumoServicos(params) {
  const token = localStorage.getItem('token');
  const app = Serve();
  const filtro = params.filtros;
  let servicos;

  console.log("filtros: ", filtro);

  await Axios.get(app + 'lista_resumida_nucleo', {
    headers: {
      Authorization: `Bearer ${token}`,
    }, params: {
      responsavel_id: Number(localStorage.getItem('filtro_responsavel')) || 0,
      page: params.pageNumber,
      status_id: filtro.status,
      logradouro: filtro.endereco,
    }
  }).then(res => {
    console.log(res);
    if (res?.data.status && (res.data.status === 498 || res.data.status === 401)) {
      localStorage.clear();
    } else {
      console.log("Res Data: ", res.data);
      servicos = res.data;          
    }
  })
  return servicos;
};
export default getResumoServicos;