import React from 'react';
import { Badge, Image, Pane, Table } from 'evergreen-ui';
import ShowDocument from '../app/showDocument';
import Document from '../images/icos/document.png';
import MultiDocument from '../images/icos/multi_document.png';
import dayjs from 'dayjs';

export default function ReturnDocumentsForView({ docs }) {
  const token = localStorage.getItem('token');

  const calcColor = (tipo) => {
    const now = dayjs();
    if(tipo.validade_dias) {      
      if(tipo.data_vencimento) {
        const oldDate = tipo.data_vencimento.split("/");
        const dateFormat = oldDate[2] + '-' + oldDate[1] + '-' + oldDate[0]        
        const daysLeft = dayjs(dateFormat).diff(now, "day");
        
        return { 
          color: daysLeft > 3 ? 'green' : daysLeft > 0 ? 'yellow' : 'red',
          label: tipo.data_vencimento || 'N/D', 
          opacity: 1 
        }
      }
      return { color: 'neutral', label: 'N/D', opacity: 0.5 }
    } else {
      return { color: 'neutral', label: "S/ VALIDADE", opacity: 0.5 }
    }
  };

  return (
    <Pane>
      {docs ?
        <Pane >
          {docs.map((doc, i) => (
            <Table.Row height='auto' key={doc.arquivo}>
              <Table.TextCell padding={12}>                
              {i === 0 && <p className='ft10 mb16'>VENCIMENTO</p>}

                  {doc.tipos_multiplos_documentos?.map((tipo, index) => (
                    <Pane className='flex gap8 mb16' key={index} >
                      <Badge color={calcColor(tipo).color} opacity={calcColor(tipo).opacity}>{calcColor(tipo).label}</Badge>
                      <Pane className='flexColl gap8'>
                        <Badge maxWidth={480} width='fit-content' className='hiddenOverflow'>{tipo.nome_tipo}</Badge>

                        {index + 1 === doc.tipos_multiplos_documentos.length &&
                          <Pane className='flex itemsCenter'>
                            <Image marginRight={12} height={16} src={doc.tipos_multiplos_documentos.length > 1 ? MultiDocument : Document} />
                            <span className="doc-name" onClick={() => ShowDocument(doc.id, "documento", token)}>
                              {doc.arquivo}
                            </span>
                          </Pane>
                        }

                      </Pane>
                    </Pane>
                  ))}

              </Table.TextCell>
            </Table.Row>
          ))}
        </Pane>
        : <span>Não há documentos cadastrados.</span>
      }
    </Pane>
  )
}