import Axios from 'axios';
import Serve from '../app/serve';

async function getBanesh() {
  const token = localStorage.getItem('token');
  const app = Serve();
  let lojas = [];

  await Axios.get(app + 'listar_conta_benesh', {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }).then(res => {
    if (res !== undefined) {
      if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
        //localStorage.clear();
      } else {
        lojas = res.data;

      }
    }
  })

  return lojas;
};

export default getBanesh;