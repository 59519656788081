import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import 'react-phone-input-2/lib/bootstrap.css'
import { Pane, Dialog, Spinner, Button, ChevronLeftIcon, ChevronRightIcon, Tab, Tablist, AddIcon, DeleteIcon } from 'evergreen-ui';

//Context
import VendasContext from '../../../../../../../context/VendasContext';

//Components
import Steps from '../Steps';
import PessoaFisica from './PessoaFisica';
import PessoaJuridica from './PessoaJuridica';
import updateSalvarMultiploUsuarios from '../../../../../../../apis/updateSalvarMultiploUsuarios';
import getProcessosGerente from '../../../../../../../apis/getProcessosGerente';

export default function FormPessoa({ type }) {
  const [gerente_id] = useState(localStorage.getItem('usuario_id'));

  const {
    loading, setCountMenu,
    setItemAdicionado, setLoading,
    setDadosVenda,
    isShowFormMinuta, setIsShowFormMinuta,
    setPosicaoMinuta,
    dadosVenda,
  } = useContext(VendasContext);
  const userData = dadosVenda[type]?.data || [];

  const quantPessoaFisicaVendedores = Number(dadosVenda?.pre_venda?.qtd_vendedores_fis);
  const quantPessoaJuridicaVendedores = Number(dadosVenda?.pre_venda?.qtd_vendedores_jur);
  const quantPessoaFisicaCompradores = Number(dadosVenda?.pre_venda?.qtd_compradores_fis);
  const quantPessoaJuridicaCompradores = Number(dadosVenda?.pre_venda?.qtd_compradores_jur);

  // Cantinho do React Hook Form
  const { register, handleSubmit, setValue, watch, reset, unregister, clearErrors, setFocus, formState: { errors } } = useForm({
    defaultValues: {
      ...dadosVenda[type]?.data.map((user) => ({ ...user, perfil: false }))
    }
  });

  useEffect(() => {
    console.log("EFFECT INDEX");
    reset({
      ...dadosVenda[type]?.data.map((user) => ({
        ...user,
        perfil: false,
        meio_entrada: dadosVenda.cliente_origem?.origem,
        forma_contato: dadosVenda.cliente_origem?.forma_contato,
        data_entrada_origem: dadosVenda.cliente_origem?.data_entrada,
      }))
    });
  }, [reset, type, dadosVenda]);

  const watchForm = watch();
  console.log(type, userData);
  console.log('dadosVenda: ', dadosVenda);
  console.log("watch: ", watchForm);
  console.log("Errors", errors);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tabs, setTabs] = useState(userData.length > 0 ? userData.map((user, index) => index) : [0]);

  useEffect(() => {
    const contador = {
      'pessoaFisica': type === 'vendedores' ? quantPessoaFisicaVendedores : quantPessoaFisicaCompradores,
      'pessoaJuridica': type === 'vendedores' ? quantPessoaJuridicaVendedores : quantPessoaJuridicaCompradores
    }
    console.log('contador: ', contador);
    if (dadosVenda[type]?.data.length <= 0 && dadosVenda.pre_venda) {
      const total = contador.pessoaFisica + contador.pessoaJuridica;
      const array = []
      for (let i = 1; i <= total; i++) {
        array.push(i-1);
        setValue(i -1 + '.tipo_pessoa', i > contador.pessoaFisica ? 1 : 0);
      };
      setTabs([...array]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosVenda, type]);
  console.log('TABS: ', tabs);

  const addPessoa = () => {
    setSelectedIndex(tabs.length);
    setValue(tabs.length + '.tipo_pessoa', 0);
    tabs.push(tabs.length);
    setTabs([...tabs]);
  };

  const removeUser = (index) => {
    unregister((tabs[index]).toString());
    tabs.splice(index, 1);
    setTabs([...tabs]);
    setSelectedIndex(index - 1);
  };

  const onSubmit = async (data) => {
    setLoading(false);
    const page = localStorage.getItem('page_number') || 1;
    const filtro = ""

    //PREENCHENDO COM DADOS ADICIONAIS
    console.log(Object.values(data));
    const arrayData = Object.values(data).map((user) => ({
      ...user,      
      bairro: user.bairro || "",
      cep: user.cep || "",
      cidade: user.cidade || "",
      complemento: user.complemento || "",
      conjuge: user.conjuge || "",
      cpf_cnpj: user.cpf_cnpj || "",
      data_entrada_origem: user.data_entrada_origem || "",
      data_nascimento: user.data_nascimento || "",
      data_rg_expedido: user.data_rg_expedido || "",
      ddi: user.ddi || "",
      documentos: user.documentos || "",
      email: user.email || "",
      endereco: user.endereco || "",
      estado: user.estado || "",
      estado_civil: user.estado_civil || "",
      forma_contato: user.forma_contato || "",
      genero: user.genero || "",
      id: user.id || "",
      logradouro: user.logradouro || "",
      loja: user.loja || "",
      meio_entrada: user.meio_entrada || "",
      nacionalidade: user.nacionalidade || "",
      name: user.name || "",
      nome_fantasia: user.nome_fantasia || "",
      nome_mae: user.nome_mae || "",
      nome_pai: user.nome_pai || "",
      numero: user.numero || "",
      pj_representante: user.pj_representante || "",
      pj_socio: user.pj_socio || "",
      procurador: user.procurador || "",
      razao_social: user.razao_social || "",
      registro_casamento: user.registro_casamento || "",
      rg: user.rg || "",
      profissao: user.profissao || "",
      tipo_pessoa: user.tipo_pessoa ? user.tipo_pessoa : user.razao_social ? '1' : '0' ,
      rgExpedicao: user.rg_expedido || '',
      telefone: user.telefone || '',
      nomeMae: user.nome_mae || '',
      nomePai: user.nome_pai || '',
      dataExpedicao: user.data_rg_expedido || '',
      uniao_estavel: user.uniao_estavel || '',
      valor_conjuge: user.conjuge || '',
      origem: 'minuta',
      imovel_id: dadosVenda.id,
      processo_id: dadosVenda.processo_id,
      usuario_id: gerente_id,
      tipo: type === "vendedores" ? "vendedor" : "comprador",
      status_processo: type === "vendedores" ? 10 : 11,
      id_origem_processo: dadosVenda.cliente_origem === null ? '' : dadosVenda.cliente_origem.id,
      representante_socio: user.representante_socios?.data?.map((representante) => ({
        conjugeRepresentante: representante.conjuge || "",
        cpfRepresentante: representante.cpf_cnpj,
        dataExpedicaoRgRepresentante: representante.data_rg_expedido || "",
        emailRepresentante: representante.email,
        estadoCivilRepresentante: representante.estado_civil,
        expedicaoRgRepresentante: representante.rg_expedido || "",
        generoRepresentante: representante.genero,
        nacionalidadeRepresentante: representante.nacionalidade || "",
        nomeRepresentante: representante.name,
        pj_usuario_id: representante.id || "",
        profissaoRepresentante: representante.profissao || "",
        regimeCasamentoRepresentante: representante.registro_casamento || "",
        representante: representante.pj_representante === '1' ? true : false,
        rgRepresentante: representante.rg || "",
        socio: representante.pj_socio === '1' ? true : false,
        uniaoEstavelRepresentante: representante.uniao_estavel ? "S" : "",
      }))
    }));

    const save = await updateSalvarMultiploUsuarios(arrayData);
    if (save) {
      const data = await getProcessosGerente(filtro, page, 'adicionados');
      if (!data) return setLoading(true);
      setItemAdicionado(data.processos.data);
      data.processos.data.forEach(element => {
        if (Number(dadosVenda.processo_id) === element.processo_id) {
          setDadosVenda(element)
          if (type === 'compradores') {
            setPosicaoMinuta(4);
            setCountMenu(4);
          }
          else if (element.pre_venda && element.pre_venda.check_pular_comprador === "1") {
            setPosicaoMinuta(5); // Modal Pular Comprador  
            setCountMenu(3);
          } else {
            setPosicaoMinuta(3);
            setCountMenu(3);
          }
        }
      })
    }
    setLoading(true);
  };


  const onError = (errors) => {
    tabs.forEach((tab, index) => {
      const selectsInputs = ['registro_casamento', 'estado_civil', 'meio_entrada', 'forma_contato', '.genero'];
      if (errors[tab]) {
        setSelectedIndex(index);
        const error = errors[tab][Object.keys(errors[tab])[0]];
        console.log('error: ', error);
        // QUANDO ERRO ESTIVER EM REPRESENTANTES PRECISA DO INDEX REPRESENTANTE
        const errorRepresentante = error?.data?.find((errorValue) => errorValue)[Object.keys(error.data.find((stringError) => (stringError)))[0]];
        console.log('errorRepresentante: ', errorRepresentante);
        const name = errorRepresentante ? errorRepresentante.ref?.name : error[0] ? error.data[0].name.ref.name : error.ref.name;
        
        console.log("ERROR CAMPO: ", name, "NO " + type, tab + 1);
        if (selectsInputs.find((string) => name.includes(string))) {
          const divSelect = document.getElementById(name);
          console.log(divSelect);
          divSelect.focus();
        } else if (name) {
          setFocus(name, { shouldSelect: true })
        }
        return
      }
    })
  };

  return (
    <Pane>
      <Dialog
        width={808}
        isShown={isShowFormMinuta}
        title=''
        onCloseComplete={() => setIsShowFormMinuta(false)}
        hasFooter={false}
        preventBodyScrolling={false}
        shouldCloseOnOverlayClick={false}
        containerProps={{
          className: 'form-dialog-props'
        }}
      >
        <div className="form-dialog-fixed">
          <Steps />
          <Tablist marginBottom={16} flexBasis={240} marginRight={24} className="tab-form">
            {tabs.map((tab, index) => (
              <Tab
                key={tab}
                id={"titulo_top_vendedor_" + index}
                onSelect={() => setSelectedIndex(index)}
                isSelected={index === selectedIndex}
                aria-controls={`panel-${tab}`}
                data-tab={tab}
              // onClick={() => handleOpenTab(index)}
              >
                {index < userData.length
                  ? (userData[index].tipo_pessoa === 1)
                    ? userData[index].nome_fantasia?.slice(0, 10) + '...'
                    : userData[index].name?.slice(0, 10) + '...'
                  : (type === 'vendedores' ? 'Vendedor ' : 'Comprador ') + (tab + 1)}
              </Tab>
            ))}

            <Button
              onClick={() => addPessoa()}
              marginRight={16} appearance="primary" intent="none"
              iconBefore={<AddIcon color='white' />}
            >
              Adicionar mais
            </Button>
          </Tablist>
        </div>

        {!loading &&
          <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
            <Spinner size={80} />
          </Pane>}

        {loading &&
          tabs.map((tab, index) => (
            <Pane
              key={tab}
              id={`panel-${tab}`}
              role="tabpanel"
              aria-labelledby={tab}
              aria-hidden={index !== selectedIndex}
              display={index === selectedIndex ? 'block' : 'none'}
            >
              <Pane className='mb80'>

                {/* FORMULÁRIO */}
                {watch(index + '.tipo_pessoa') === 1
                  ? <PessoaJuridica type={type} register={register} unregister={unregister} setValue={setValue} watch={watch} clearErrors={clearErrors} errors={errors} userData={userData[index]} index={tab} />
                  : <PessoaFisica type={type} register={register} unregister={unregister} setValue={setValue} watch={watch} clearErrors={clearErrors} errors={errors} userData={userData[index]} index={tab} />
                }

              </Pane>

              {/* RODAPÉ */}
              <div className="mensagem-campos mb100">*Campos obrigatórios</div>
              <div className="footerForm">
                <div>
                  {index > 0 &&
                    <Button
                      marginRight={16}
                      appearance="primary"
                      intent="danger"
                      onClick={() => removeUser(index)}
                      iconBefore={<DeleteIcon color="white" />}
                    >
                      {watch(index + '.tipo_pessoa') ? 'Excluir Empresa' : ('Excluir este ' +  (type === 'vendedores' ? 'vendedor?' : 'comprador?'))}
                    </Button>
                  }
                </div>

                <div>
                  <Button
                    appearance="default"
                    marginLeft={15}
                    onClick={() => setPosicaoMinuta(1)}
                    iconBefore={<ChevronLeftIcon />}
                  >
                    Anterior
                  </Button>

                  <Button
                    appearance="primary"
                    marginLeft={15}
                    type='submit'
                    onClick={handleSubmit((data) => onSubmit(data), onError)}
                    iconAfter={<ChevronRightIcon color="white" />}
                  >
                    Avançar
                  </Button>
                </div>
              </div>
            </Pane>
          ))
        }

      </Dialog>
    </Pane >
  )
}