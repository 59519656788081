import React, { useEffect, useState } from 'react';
import { Pane } from 'evergreen-ui';
import { useHistory } from 'react-router-dom';
import NucleoContext from '../../context/NucleoContext'
import Navbar from '../../components/Navbar';
import TableNucleo from './components/TablePainel';
import getStatusList from '../../apis/getStatusList';
import getLaudemiosList from '../../apis/getLaudemiosList';
import getDocumentsList from '../../apis/getDocumentsList';
import ChangeLog from '../components/ChangeLog';
import getEndereco from '../../apis/getEnderecos';
import getResumoServicos from '../../apis/getResumoServicos';
import getListEscrituras from '../../apis/getListEscrituras';

export default function Nucleo() {
  const [perfil_login] = useState(localStorage.getItem('perfil_login'));
  const [userName] = useState(localStorage.getItem('nome_usuario'))
  const history = useHistory();
  if (perfil_login !== "Núcleo") history.push('/');

  //Context
  const [loading, setLoading] = useState(false);
  const [nucleoTableValue, setNucleoTableValue] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalProcessNumber, setTotalProcessNumber] = useState(0);
  const [totalProcessFilter, setTotalProcessFilter] = useState(0);
  
  // LISTAS
  const [listLaudemios, setListLaudemios] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [adressList, setAdressList] = useState([]);
  const [documentsList, setDocumentsList] = useState([]);
  const [listEscrituras, setListEscrituras] = useState([]);

  // DOCUMENTOS
  const [formValues, setFormValues] = useState({
    'imovel': [{ 'documento': '', 'arquivo': '' }],
    'vendedores': [],
    'compradores': []
  });

  // [ {item: [ {id: '', label: '', value: ''} ], file: '', id: ''} ]
  const [multiDocs, setMultiDocs] = useState({
    'imovel': [{item: [], file: '', id: ''}],
    'vendedores': [
      {fisica: [], juridica: [], representante_socios: []}
    ],
    'compradores': [      
      {fisica: [], juridica: [], representante_socios: []}
    ]
  });

  // FILTROS
  const [filtros, setFiltros] = useState({
    'status': Number(localStorage.getItem('filtro_status')) || '',
    'endereco': localStorage.getItem('filtro_endereco') || '',
  });

  // SideSheet
  const [isShownSide, setIsShownSide] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [processoId, setProcessoId] = useState();
  const [serviceId, setServiceId] = useState();
  const [processSelect, setProcessSelect] = useState();

  //Dialogs
  const [isShowStatusChange, setIsShowStatusChange] = useState(false);
  const [isShowConfirmDel, setIsShowConfirmDel] = useState(false);
  const [itemToRemove, setItemToRemove] = useState();

  //Corner
  const [isShowSharePosVenda, setIsShowSharePosVenda] = useState(false);
  const [contador, setContador] = useState(5)
  const [idStatusSolicitacaoCancelar, setIdStatusSolicitacaoCancelar] = useState('');

  const conxtextStatus = {
    loading, setLoading,
    nucleoTableValue, setNucleoTableValue,
    statusList, adressList,
    isShownSide, setIsShownSide,
    selectedIndex, setSelectedIndex,
    processSelect, setProcessSelect,
    processoId, setProcessoId,
    serviceId, setServiceId,
    totalPages, setTotalPages,
    totalProcessFilter, totalProcessNumber,
    setTotalProcessNumber, setTotalProcessFilter,
    listLaudemios,
    isShowStatusChange, setIsShowStatusChange,
    isShowConfirmDel, setIsShowConfirmDel,
    isShowSharePosVenda, setIsShowSharePosVenda,
    formValues, setFormValues,
    itemToRemove, setItemToRemove,
    filtros, setFiltros,
    pageNumber, setPageNumber,
    contador, setContador,
    documentsList,
    idStatusSolicitacaoCancelar, setIdStatusSolicitacaoCancelar,
    listEscrituras,
    multiDocs, setMultiDocs
  };

  async function getSelects() {
    const status = await getStatusList();
    setStatusList(status);

    const laudemios = await getLaudemiosList();
    setListLaudemios(laudemios);

    const adress = await getEndereco();
    setAdressList(adress);

    const documents = await getDocumentsList();
    setDocumentsList(documents);

    const tiposEscrituras = await getListEscrituras();
    setListEscrituras(tiposEscrituras);
  };

  useEffect(() => {
    getSelects();
  }, [])

  // API QUE ABASTECE TABLE E SIDESHEET
  async function getLists(params) {
    setLoading(true);
    // const services = await getServicosNucleo(params);
    const listServices = await getResumoServicos(params);
    console.log("Resumo Serviços Nucleo: ", listServices);
    setNucleoTableValue([...listServices.solicitacoes.data]);
    setTotalPages(listServices.total_pagina);
    setTotalProcessFilter(listServices.total_solicitacoes_filtradas)
    setTotalProcessNumber(listServices.total_solicitacoes);
    setLoading(false);
  };

  return (
    <Pane>
      <NucleoContext.Provider value={conxtextStatus}>
      <Navbar Context={NucleoContext} />
        <div className="painel mt59">
          <div class="flex-container">
            <h1 className="title">Histórico de Serviços</h1>
          </div>
          <h4 className="mt10 mb56">
            Olá, {userName}! Acompanhe todos os serviços, solicitados pelo Pós-venda.
          </h4>
          <TableNucleo getLists={getLists} />
          <ChangeLog data="Nucleo" />

        </div>
      </NucleoContext.Provider>
    </Pane>
  )
}