import React, { useContext, useRef, useState } from 'react'
import { Pane, Textarea, SmallTickIcon, Checkbox, Radio, ErrorIcon } from 'evergreen-ui';
import GreenRocket from '../../../../../../../../../../images/rockets/green_rocket.svg'
import GrayRocket from '../../../../../../../../../../images/rockets/grey_rocket.svg'
import YellowRocket from '../../../../../../../../../../images/rockets/yellow_rocket.svg'
import RedRocket from '../../../../../../../../../../images/rockets/red_rocket.svg'
import VendasContext from '../../../../../../../../../../context/VendasContext';

export default function Performance() {

    const {
        dadosVenda,
        divergenciaEntrega, setDivergenciaEntrega,        
    } = useContext(VendasContext);

    const compradoresPre = parseInt(dadosVenda.pre_venda.qtd_compradores_fis) + parseInt(dadosVenda.pre_venda.qtd_compradores_jur);
    const vendedoresPre = parseInt(dadosVenda.pre_venda.qtd_vendedores_fis) + parseInt(dadosVenda.pre_venda.qtd_vendedores_jur);

    const compradores = dadosVenda.compradores.data.length;
    const vendedores = dadosVenda.vendedores.data.length;

    return (
        <Pane className="container">
            <div className="rocket">
                <div className="r-image">

                    {
                        dadosVenda.quantidade_foguete.qts_foguete === 2 &&
                        <>
                            <img src={RedRocket} alt="" />
                            <img src={RedRocket} alt="" />
                            <img src={GrayRocket} alt="" />
                            <img src={GrayRocket} alt="" />
                            <img src={GrayRocket} alt="" />
                        </>
                    }

                    {
                        dadosVenda.quantidade_foguete.qts_foguete === 3 &&
                        <>
                            <img src={YellowRocket} alt="" />
                            <img src={YellowRocket} alt="" />
                            <img src={YellowRocket} alt="" />
                            <img src={GrayRocket} alt="" />
                            <img src={GrayRocket} alt="" />
                        </>
                    }

                    {
                        dadosVenda.quantidade_foguete.qts_foguete === 4 &&
                        <>
                            <img src={GreenRocket} alt="" />
                            <img src={GreenRocket} alt="" />
                            <img src={GreenRocket} alt="" />
                            <img src={GreenRocket} alt="" />
                            <img src={GrayRocket} alt="" />
                        </>
                    }

                    {
                        dadosVenda.quantidade_foguete.qts_foguete === 5 &&
                        <>
                            <img src={GreenRocket} alt="" />
                            <img src={GreenRocket} alt="" />
                            <img src={GreenRocket} alt="" />
                            <img src={GreenRocket} alt="" />
                            <img src={GreenRocket} alt="" />
                        </>
                    }
                </div>

                <div className="r-content">
                    {
                        dadosVenda.quantidade_foguete.qts_foguete === 5 &&
                        <>
                            <h2 style={{ color: '#52BD94' }}>Parabéns, sua venda está perfeita!</h2>
                        </>
                    }

                    {
                        dadosVenda.quantidade_foguete.qts_foguete === 4 &&
                        <>
                            <h2 style={{ color: '#52BD94' }}>
                                {
                                    dadosVenda.quantidade_foguete.compradores === 1 || dadosVenda.quantidade_foguete.vendedores === 1
                                        ?
                                        'Sua venda não pode ser entregue.'
                                        :
                                        'Falta pouco para uma venda perfeita!'
                                }
                            </h2>
                        </>
                    }

                    {
                        dadosVenda.quantidade_foguete.qts_foguete === 3 &&
                        <>
                            <h2 style={{ color: '#FFB020' }}>Sua venda precisa de correções.</h2>
                        </>
                    }

                    {
                        dadosVenda.quantidade_foguete.qts_foguete === 2 &&
                        <>
                            <h2 style={{ color: '#D14343' }}>Realize as devidas correções...</h2>
                        </>
                    }

                    <p>
                        {
                            dadosVenda.quantidade_foguete.qts_foguete === 5
                                ?
                                'Entregar uma venda perfeita faz parte do nosso DNA! A cada venda com nota máxima, você garante uma maior pontuação.'
                                :
                                'Com base nas informações cadastradas, avaliamos sua venda. Quanto mais completa a entrega da venda, maior será a nota e sua pontuação.'
                        }
                    </p>
                </div>
            </div>

            <div className="content content-performance">
                {
                    dadosVenda.quantidade_foguete.qts_foguete < 5 &&
                    <div className="melhorias mt20">

                        {
                            dadosVenda.quantidade_foguete.qts_data_assinatura === 1 &&
                            <div className="melhorias">
                                <span className="title-span">Como melhorar sua venda e pontuar mais:</span>
                                {dadosVenda.quantidade_foguete.qts_data_assinatura === 1 && <p><SmallTickIcon color="#8F95B2" className="positionIcon" /> Sua venda foi entregue após 1 dia útil da Data de Assinatura do Recibo de Sinal. <br />Se organize para ter entregas pontuais.</p>}
                            </div>
                        }

                        {
                            (dadosVenda.quantidade_foguete.compradores === 1 || dadosVenda.quantidade_foguete.vendedores === 1) &&
                            <div className="melhorias">
                                <span className="title-span">Como corrigir sua venda e deixar elegível para entrega:</span>
                                {
                                    dadosVenda.quantidade_foguete.compradores === 1 &&
                                    <p><SmallTickIcon color="#8F95B2" className="positionIcon" />
                                        No pré-cadastro você disse que essa venda possui {compradoresPre} compradores, mas cadastrou apenas {compradores} até agora. <br />Corrija os dados dos compradores.
                                    </p>
                                }

                                {
                                    dadosVenda.quantidade_foguete.vendedores === 1 &&
                                    <p><SmallTickIcon color="#8F95B2" className="positionIcon" />
                                        No pré-cadastro você disse que essa venda possui {vendedoresPre} vendedores, mas cadastrou apenas {vendedores} até agora. <br />Corrija os dados dos vendedores.
                                    </p>
                                }
                            </div>
                        }
                    </div>
                }

                {
                    (dadosVenda.quantidade_foguete.compradores === 1 || dadosVenda.quantidade_foguete.vendedores === 1) &&
                    <div className="mt20 mb-25">
                        <p>Atenção: Você ainda pode editar a venda para inserir os dados de vendedores e/ou compradores pendentes.</p>
                    </div>
                }

                {/*
                    dadosVenda.quantidade_foguete.qts_foguete >= 4 &&
                    <>
                        <p>Se houver qualquer divergência, justifique abaixo para a Equipe de Pós-Venda:</p>
                        <Textarea name="textarea-1" onChange={(e) => setDivergenciaEntrega(e.target.value)} placeholder="Ex: Quando subi o recibo de sinal assinado, precisei acrescentar mais um comprador na negociação." />
                    </>
                */}
            </div>            
        </Pane>
    )
}
