import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Pane,
  Dialog,
  Image,  
  TextInputField,
  Checkbox,
  Button,
  MinusIcon,
  TextInput,
  PlusIcon,  
  TickCircleIcon,
  Spinner,
  InlineAlert,
  ErrorIcon,
} from 'evergreen-ui';

//Context
import VendasContext from '../../../../../../context/VendasContext';
import headerImg from '../../../../../../images/header_precadastro.png'
import Axios from 'axios';
import getMidas from '../../../../../../apis/getMidas';
import cepMask from '../../../../../../components/cepMask';
import formatoMoeda from '../../../../../../components/formatoMoeda';

function Count({ count, setCount, pessoa, papel }) {
  const handleCount = (value) => {
    const data = count;
    data[papel][pessoa] = value;
    setCount({ ...data });
  };

  return (
    <div className='mt16 mr38'>
      <div>
        <span>Pessoas {pessoa === 'fisica' ? " Fisicas" : " Jurídicas"}</span>
      </div>
      <div className='flex divMRight4 mt7'>
        <Button padding={0} width={32} disabled={count[papel][pessoa] <= 0} appearance='primary'><MinusIcon size={14} color="white" onClick={() => handleCount(Number(count[papel][pessoa]) - 1)} /></Button>
        <TextInput className='inputContador textCenter mr5' width={41} value={count[papel][pessoa]} type="number" min="1" step="1" onChange={(e) => handleCount(e.target.value)} />
        <Button padding={0} width={32} appearance='primary' onClick={() => handleCount(Number(count[papel][pessoa]) + 1)}><PlusIcon size={14} color="white" /></Button>
      </div>
    </div>
  )
}

export default function Imovel({ openMinuta }) {
  const {
    isShowPreCadastro, setIsShowPreCadastro,
    loading,
  } = useContext(VendasContext);

  const inputGreen = { borderColor: '#429777', background: '#F5FBF8' };
  const inputDefault = { borderColor: '#ADC2FF', background: '#fff' };
  const [cardEnable, setCardEnable] = useState(false);
  const [loadCod, setLoadCod] = useState(false);

  const { register, watch, reset, handleSubmit, clearErrors, setValue, formState: { errors } } = useForm({
    defaultValues: {
      checkCodImovel: true,
    }
  });

  const inicialCount = {
    vendedores: {
      fisica: 0,
      juridica: 0
    },
    compradores: {
      fisica: 0,
      juridica: 0
    }
  }
  const [count, setCount] = useState(inicialCount);
  const [error, setError] = useState(false);

  const handleSave = (e) => {
    const qtd_vendedores_fis = count.vendedores.fisica;
    const qtd_vendedores_jur = count.vendedores.juridica;
    if (qtd_vendedores_fis <= 0 && qtd_vendedores_jur <= 0) {
      setError(true);
      return
    };

    // NTAP22979
    const dadosVenda = {
      ...e,
      qtd_vendedores_fis,
      qtd_vendedores_jur,
      qtd_compradores_fis: count.compradores.fisica <= 0 ? 0 : count.compradores.fisica,
      qtd_compradores_jur: count.compradores.juridica <= 0 ? 0 : count.compradores.juridica,
    };

    setIsShowPreCadastro(false);
    openMinuta(dadosVenda);
  };

  const handleCod = async (value) => {
    setLoadCod(true);
    clearErrors("checkCodImovel");
    let count = 1;
    const opcionistas = [];
    if (value.length >= 9) {
      const data = await getMidas(value);
      console.log('retorno do midas', value , data );
      setLoadCod(false);
      if(data) {
        if (data.cep) {
          await Axios.get("https://viacep.com.br/ws/" + data.cep + "/json/")
            .then(res => {
              const { data } = res;
              setValue('bairro', data.bairro);
              setValue('cidade', data.localidade);
              setValue('estado', data.uf);
              setValue('logradouro', data.logradouro);
              setCardEnable(true);
            })
        }
        setValue('cep', cepMask(data.cep));
        setValue('numero', data.numero);
        setValue('unidade', data.unidade);
        setValue('complemento', data.complemento);
        setValue('vagas', data.qtde_vagas);
        setValue('inscricaoMunicipal', data.inscricao_municipal);
        setValue('valorEstimado', formatoMoeda(data.valor_venda));
        setValue('laudo_opcionista', data.tipo_opcao);
        for (let prop in data) {
          if (prop.includes('nome_opcionista') && data[prop] !== "") {
            opcionistas.push({
              id: data['opcionista' + count],
              nome: data[prop],
              percentual: data['percentual_opcao' + count]
            });
            count++;
          }
        }
        setValue('opcionistas', opcionistas);
      }
    } else {
      setLoadCod(false);
      setCardEnable(false);
      setTimeout(() => {
        reset();
      }, 800)
    }
  };

  const handleClose = () => {
    setIsShowPreCadastro(false);
    reset();
    setCount(inicialCount);
    setError(false);
  };

  const validateCheckCod = (e) => {
    if (e && !watch('logradouro')) {
      return false
    } else {
      return true
    }
  };

  return (
    <Pane>
      <Dialog
        width={808}
        isShown={isShowPreCadastro}
        title={''}
        isConfirmLoading={loading}
        footer={(
          <Pane className='alignRight'>
            <div className='mr60'>
              <p className='fw600 f14'>
                Obs: É importante ter os dados dos clientes em mãos ao cadastrar a venda.
              </p>
              <span className='fw400 f14'>
                Evite registrar a venda com dados incorretos.
              </span>
            </div>
            <div className=''>
              <Button appearance='primary' intent="success" onClick={handleSubmit(e => handleSave(e))}>Iniciar o cadastro</Button>
            </div>
          </Pane>
        )}
        onCloseComplete={() => handleClose()}
        preventBodyScrolling={false}
        shouldCloseOnOverlayClick={false}
      >
        {!loading ?
          <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
            <Spinner size={80} />
          </Pane> :
          <Pane>

            <div className='center mb16'>
              <Image src={headerImg} />
            </div>
            <h1 className='fw600 f24 mb16 lh32'>
              Para poupar seu tempo, <br />
              responda as perguntas abaixo :)
            </h1>
            <h2 className='fw400 f16 lh24'>
              Responda corretamente para evitar devoluções de vendas e gerar uma  <br />
              venda completa. O formulário será adaptado conforme suas respostas.
            </h2>

            <div className='mt47'>
              <div className='f16'>
                <span className='fw600 mr5'>Qual é a referência do imóvel?</span>
                <span className='fw400'>(opcional)</span>
              </div>

              <div className='mt19'>
                <TextInputField
                  width={164}
                  marginRight={23}
                  description=''
                  label="Código do Imóvel"
                  placeholder="Ex: DNA123456"
                  style={watch('codigo') ? inputGreen : inputDefault}
                  {...register("codigo", {
                    required: false,
                    onChange: (e) => handleCod(e.target.value)
                  })}

                />
                {loadCod && <Spinner className='mt30' size={30} />}
                {watch('logradouro') &&
                  <Pane className={'card ' + (cardEnable ? 'card-enable' : 'card-hidden')}>
                    <div className='cardTitle'>
                      {(watch('bairro') + ' - ') || ''} {watch('estado') || ''}
                      <TickCircleIcon className={watch('checkCodImovel') ? "green" : "lightGrayFill"} />
                    </div>
                    <div className='cardText'>
                      {watch('logradouro') + ' - ' || ''} {watch('numero') || ''}
                      {watch('complemento') || ''}
                    </div>
                  </Pane>}
              </div>

              <Checkbox
                label="O endereço do imóvel está correto."
                appearance='default'
                className='checkbox'
                {...register("checkCodImovel", {
                  required: false,
                  validate: value => validateCheckCod(value) || "Imóvel não encontrado, desmarque o check para avançar."
                })}
                checked={watch('checkCodImovel')}
              />
            </div>
            {errors?.checkCodImovel &&
              <InlineAlert intent='danger' className="inline-alert">
                <span className='error-input f12 mb0'>{errors?.checkCodImovel?.message}</span>
              </InlineAlert>
            }

            <div className='mt47'>
              <div className='f16'>
                <span className='fw600 mr5'>Quantas pessoas vendem o imóvel?*</span>
              </div>
              <div className='flex'>
                <Count count={count} setCount={setCount} papel="vendedores" pessoa="fisica" />
                <Count count={count} setCount={setCount} papel="vendedores" pessoa="juridica" />
              </div>
              {(error && count.vendedores.fisica <=0 && count.vendedores.juridica <=0) &&
                  <span className="error-input mt10 f12 flex">
                    <ErrorIcon color='#D14343' /> É necessario informar a quantidade de vendedores!
                  </span>                
              }
            </div>

            <div className='mt47'>
              <div className='f16'>
                <span className='fw600 mr5'>E quantas compram?*</span>
              </div>
              <div className='flex'>
                <Count count={count} setCount={setCount} papel="compradores" pessoa="fisica" />
                <Count count={count} setCount={setCount} papel="compradores" pessoa="juridica" />
              </div>
              <Checkbox
                label="Não tenho os dados pessoais de compradores e prefiro preencher depois."
                appearance='default'
                className='checkbox'
                {...register("checkComprador", {
                  required: false,
                })}
                checked={watch('checkComprador')}
              />
            </div>

          </Pane>

        }
      </Dialog>
    </Pane>
  )
}