import Axios from 'axios';
import { toaster } from 'evergreen-ui';
import Serve from './serve';
import ServeLogin from './serve_login'

export default function ShowDocument(id, tipo, token, acessoTemporario) {
    const app = acessoTemporario ? ServeLogin() : Serve();
    const api = acessoTemporario ? 'exibir_arquivo_acesso' : 'exibir_arquivo';

    const funcShowDocument = async (id, tipo, token) => {
        console.log(id);

        Axios.post(app + api, {
            "id": id,
            "tipo": tipo
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',
        })
            .then(res => {
                if (res) {
                    console.log(res);
                    let type_arquivo = res.data.type;
                    if (type_arquivo !== 'text/html') {
                        const file = new Blob(
                            [res.data],
                            { type: type_arquivo }
                        );
                        console.log(file);
                        if (type_arquivo === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || type_arquivo === 'application/msword') {
                            let finalTypeArquivo = 'docx';
                            if (type_arquivo === 'application/msword') {
                                finalTypeArquivo = 'doc';
                            }
                            let url = window.URL.createObjectURL(file);
                            let a = document.createElement("a");
                            a.href = url;
                            a.download = "arquivo_DNA." + finalTypeArquivo;
                            a.click();
                        } else {
                            const fileURL = URL.createObjectURL(file);
                            window.open(fileURL);
                        }

                    } else {
                        console.log("não teve retorno satisfatorio");
                    }
                } else {
                    toaster.danger("Erro, arquivo não encontrado!")
                }
            })
    }

    return funcShowDocument(id, tipo, token);
};