import React, { useContext } from 'react';
import { Dialog } from 'evergreen-ui';
import NegociacaoContext from '../../../../context/NegociacaoContext';

export default function DialogRevisaoFeedBack() {

  const {
    isShownRevisaoFeedBack, setIsShownRevisaoFeedBack,
  } = useContext(NegociacaoContext);

  return (
    <Dialog
      isShown={isShownRevisaoFeedBack}
      title={<h3 className='revisao'>Venda devolvida!</h3>}
      onCloseComplete={() => setIsShownRevisaoFeedBack(false)}
      hasFooter={false}
    >
      <div className="dialog-content dialog-content-info">
        <p className='dialogSubTitle'>
        Você devolveu a venda e solicitou correções para o gerente responsável. <br/>
        Quando o gerente realizar os ajustes, a venda voltará para você.
        </p>
      </div>
    </Dialog>
  )
}