import React from 'react'
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

export default function PlanilhaComissaoPDF(processo) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    console.log(processo);

    const d = new Date();
    const semana = ["domingo", "segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado"];
    const mes = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"]
    const datas = d.getDate();
    const ano = d.getFullYear();
    
    //const processo = comissao;
    const processoComissao = processo.comissao;
    const processoGerenteGG = processo;

    //const tipoLoja = processo.empresa_receber.verificar_franquia; //0 - loja própria / 1 - franquia
    let tipoLoja = 1;
    const tipoLojaArray = processoGerenteGG.comissao_empresas.map((empresa, index_empresa) => {
        if(empresa.nome_empresarial === "DNA Imóveis LTDA") {
            tipoLoja = 0;
        }
    })
    console.log(tipoLoja);
    const enderecoImovel = processo.logradouro + ", " + processo.numero + "" + (processo.unidade !== null ? (', ' + processo.unidade) : '') + (processo.complemento !== null ? (', ' + processo.complemento) : '') + ", " + processo.bairro + ", " + processo.cidade + " - " + processo.uf;
    const enderecoNomePDF = processo.logradouro + "_" + processo.numero + "" + (processo.unidade !== null ? ('_' + processo.unidade) : '') + (processo.complemento !== null ? ('_' + processo.complemento) : '') + "_" + processo.bairro + "_" + processo.cidade + "_" + processo.uf;

    const reportTitle = [
        {
            text: 'Planilha de Comissão',
            fontSize: 15,
            bold: true,
            margin: [15, 20, 0, 45]
        },
    ];

    const dadosVendedor = processoComissao.corretores_vendedores_comissao.map((corretor, index) => {
        return [
            {text: (index + 1) + 'º Vendedor', fontSize: 9, margin: [0, 2, 0, 2], alignment: 'right'},
            {text: corretor.porcentagem_real + '%', fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.desconto === null || corretor.desconto === '0' ||  corretor.desconto === ''  ? '' : corretor.desconto, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.valor_real === null ? '0,00' : corretor.valor_real, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.tipo_pessoa === 'PF' ? corretor.name : corretor.nome_empresarial, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.tipo_pessoa === 'PF' ? corretor.cpf : corretor.cnpj, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.creci, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.tipo_pagamento === 'pix'
                ? 'PIX: ' + corretor.pix
                : 'Banco: ' + corretor.banco_nome + ' Agência: ' + corretor.agencia + ' C/C: ' + corretor.numero_conta, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
        ]
    })

    const dadosOpcionista = processoComissao.corretores_opicionistas_comissao.map((corretor, index) => {
        return [
            {text: (index + 1) + 'º Opcionista', fontSize: 9, margin: [0, 2, 0, 2], alignment: 'right'},
            {text: corretor.porcentagem_real + '%', fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.desconto === null || corretor.desconto === '0' ||  corretor.desconto === ''  ? '' : corretor.desconto, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.valor_real === null ? '0,00' : corretor.valor_real, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.tipo_pessoa === 'PF' ? corretor.name : corretor.nome_empresarial, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.tipo_pessoa === 'PF' ? corretor.cpf : corretor.cnpj, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.creci, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.tipo_pagamento === 'pix'
                ? 'PIX: ' + corretor.pix
                : 'Banco: ' + corretor.banco_nome + ' Agência: ' + corretor.agencia + ' C/C: ' + corretor.numero_conta, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
        ]
    })

    const dadosGerente = processoGerenteGG.comissao_gerentes.map((corretor, index) => {
        return [
            {text: (index + 1) + 'º Gerente', fontSize: 9, margin: [0, 2, 0, 2], alignment: 'right'},
            {text: corretor.porcentagem_real + '%', fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.desconto === null || corretor.desconto === '0' ||  corretor.desconto === '' ? '' : corretor.desconto, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.valor_real === null ? '0,00' : corretor.valor_real, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.tipo_pessoa === 'PF' ? corretor.name : corretor.nome_empresarial, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.tipo_pessoa === 'PF' ? corretor.cpf : corretor.cnpj, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.creci, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.tipo_pagamento === 'pix'
                ? 'PIX: ' + corretor.pix
                : 'Banco: ' + corretor.banco_nome + ' Agência: ' + corretor.agencia + ' C/C: ' + corretor.numero_conta, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
        ]
    })

    const dadosGG = processoGerenteGG.comissao_gerente_gerais.map((corretor, index) => {
        return [
            {text: 'Gerente Geral', fontSize: 9, margin: [0, 2, 0, 2], alignment: 'right'},
            {text: corretor.porcentagem_real + '%', fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.desconto === null || corretor.desconto === '0' ||  corretor.desconto === '' ? '' : corretor.desconto, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.valor_real === null ? '0,00' : corretor.valor_real, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.tipo_pessoa === 'PF' ? corretor.name : corretor.nome_empresarial, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.tipo_pessoa === 'PF' ? corretor.cpf : corretor.cnpj, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: corretor.creci, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
            {text: 
                corretor.tipo_pagamento === 'pix'
                ? 'PIX: ' + corretor.pix
                : 'Banco: ' + corretor.banco_nome + ' Agência: ' + corretor.agencia + ' C/C: ' + corretor.numero_conta, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
        ]
    })
    console.log(dadosGG)

    let dadosDiretor = '';
    if(tipoLoja === 0){
        dadosDiretor = [[
            {text: 'Diretor', fontSize: 9, margin: [0, 15, 0, 2], alignment: 'right'},
            {text: processoGerenteGG.dados_diretor_comercial.porcetagem_comissao + '%', fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
            {text: processoGerenteGG.dados_diretor_comercial.desconto, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
/*          {text: 'R$' + (Number(processoGerenteGG?.dados_diretor_comercial?.valor_real?.replace(",","."))).toLocaleString('pt-br', {minimumFractionDigits: 2}), fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},*/
            {text: 'R$ ' + processoGerenteGG?.dados_diretor_comercial?.valor_real, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
            {text: processoGerenteGG.dados_diretor_comercial.name, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
            {text: processoGerenteGG.dados_diretor_comercial.cpf_cnpj, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
            {text: processoGerenteGG.dados_diretor_comercial.creci, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
            {text: processoGerenteGG.dados_diretor_comercial?.pix === null ? '' : 'PIX: ' + processoGerenteGG.dados_diretor_comercial?.pix, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
        ]]
    }
    //{/*text: processoGerenteGG.dados_diretor_comercial?.nome_banco === null ? '' : 'Banco: ' + processoGerenteGG.dados_diretor_comercial?.nome_banco + ' AG:' + processoGerenteGG.dados_diretor_comercial?.agencia + ' C/C:' + processoGerenteGG.dados_diretor_comercial?.numero_conta, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'*/}

    //Franquia
    let dadosRepasse = '';
    let dadosRoyalties = '';
    let dadosComunicacao = '';

    //if(tipoLoja === 1){
        if(processoGerenteGG.dados_repasse && processoComissao?.verificar_repasse === 'true') {
            dadosRepasse = [[
                {text: 'Repasse Franquia', fontSize: 9, margin: [0, 15, 0, 2], alignment: 'right'},
                {text: processoGerenteGG.dados_repasse?.porcentagem + '%', fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
                {text: processoGerenteGG.dados_repasse?.desconto, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
                {text: 'R$' + processoGerenteGG.dados_repasse?.valor_real, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
                {text: processoGerenteGG.dados_repasse?.nome_empresarial, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
                {text: processoGerenteGG.dados_repasse?.empresa_cnpj, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
                {text: processoGerenteGG.dados_repasse?.empresa_creci, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
                {text: 
                    processoGerenteGG.dados_repasse?.tipo_pagamento === 'pix'
                    ? 'PIX: ' + processoGerenteGG.dados_repasse?.pix
                    : 'Banco: ' + processoGerenteGG.dados_repasse?.nome_banco + ' Agência: ' + processoGerenteGG.dados_repasse?.agencia + 'C/C: ' + processoGerenteGG.dados_repasse?.numero_conta, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
            ]]
        }
    //}
        
    dadosRoyalties = [[
        {text: 'Royalties', fontSize: 9, margin: [0, 15, 0, 2], alignment: 'right'},
        {text: processoGerenteGG.dados_royalties?.porcentagem + '%', fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
        {text: processoGerenteGG.dados_royalties?.desconto, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
        {text: 'R$' + processoGerenteGG.dados_royalties?.valor_real, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
        {text: processoGerenteGG.dados_royalties?.nome_empresarial, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
        {text: processoGerenteGG.dados_royalties?.empresa_cnpj, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
        {text: processoGerenteGG.dados_royalties?.creci_empresa, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
        {text: 
            processoGerenteGG.dados_royalties.tipo_pagamento === 'pix'
            ? 'PIX: ' + processoGerenteGG.dados_royalties?.pix
            : 'Banco: ' + processoGerenteGG.dados_royalties?.nome_banco + ' Agência: ' + processoGerenteGG.dados_royalties?.agencia + ' C/C: ' + processoGerenteGG.dados_royalties?.numero_conta, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
    ]]

    dadosComunicacao = [[
        {text: 'Comunicação', fontSize: 9, margin: [0, 15, 0, 2], alignment: 'right'},
        {text: processoGerenteGG.dados_comunicacao?.porcentagem + '%', fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
        {text: processoGerenteGG.dados_comunicacao?.desconto, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
        {text: 'R$' + processoGerenteGG.dados_comunicacao?.valor_real, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
        {text: processoGerenteGG.dados_comunicacao?.nome_empresarial, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
        {text: processoGerenteGG.dados_comunicacao?.empresa_cnpj, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
        {text: processoGerenteGG.dados_comunicacao?.creci_empresa, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
        {text: 
            processoGerenteGG.dados_comunicacao.tipo_pagamento === 'pix'
            ? 'PIX: ' + processoGerenteGG.dados_comunicacao?.pix
            : 'Banco: ' + processoGerenteGG.dados_comunicacao?.nome_banco + ' Agência: ' + processoGerenteGG.dados_comunicacao?.agencia + ' C/C: ' + processoGerenteGG.dados_comunicacao?.numero_conta, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
    ]]
    //Franquia

    const dadosEmpresa = processoGerenteGG.comissao_empresas.map((empresa, index) => {
        return [
            {text: 'Empresa', fontSize: 9, margin: [0, 15, 0, 2], alignment: 'right'},
            {text: empresa.porcentagem_real + '%', fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
            {text: empresa.desconto, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
            {text: 'R$' + empresa.valor_real, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
            {text: empresa.nome_empresarial, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
            {text: empresa.cnpj, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
            {text: empresa.creci, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
            {text: 
                empresa.tipo_pagamento === 'pix'
                ? 'PIX: ' + empresa.pix
                : 'Banco: ' + empresa.nome_banco + ' Agência: ' + empresa.agencia + ' C/C: ' + empresa.numero_conta, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
        ]
    })

    // const dadosEmpresa = [[
    //     {text: 'Empresa', fontSize: 9, margin: [0, 15, 0, 2], alignment: 'right'},
    //     {text: '', fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
    //     {text: processo.empresa_receber.desconto, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
    //     {text: 'R$' + processo.comissao.valor_receber_empresa, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
    //     {text: processo.empresa_receber.nome_empresarial, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
    //     {text: processo.empresa_receber.cnpj, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
    //     {text: processo.empresa_receber.creci, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
    //     {text: 'Banco: ' + processo.empresa_receber.dados_bancarios.data[0].nome_banco + ' AG: ' + processo.empresa_receber.dados_bancarios.data[0].agencia + ' C/C: ' + processo.empresa_receber.dados_bancarios.data[0].numero_conta, fontSize: 9, margin: [0, 15, 0, 2], alignment: 'center'},
    // ]]

    const details = [
        {
            text: 'Rio de Janeiro, ' + semana[d.getDay()] + ', ' + datas + ' de ' +  mes[d.getMonth()] + ' de ' + ano + '',
            fontSize: 10,
            bold: false,
            margin: [0, 10, 0, 15] //left, top, right, bottom
        },
        
        {
            text: 'Loja: ' + processo.loja.nome,
            fontSize: 10,
            bold: false,
            margin: [0, 0, 0, 0]
        },
        
        {
            text: 'Data da Venda: ' + processo.informacao.data_assinatura,
            fontSize: 10,
            bold: false,
            margin: [0, 5, 0, 0]
        },

        {
            text: 'Referência: ' + processo.codigo,
            fontSize: 10,
            bold: false,
            margin: [0, 5, 0, 0]
        },

        {
            text: 'Imóvel: ' + enderecoImovel,
            fontSize: 10,
            bold: false,
            margin: [0, 5, 0, 10]
        },

        // {
        //     margin: [0, 10, 0, 0],
        //     table: {
		// 		headerRows: 1,
        //         widths: [230, 180, 150, 150],
		// 		body: [
        //             [
        //                 {text: 'Nome da Empresa: ' + processo.empresa_receber.nome_empresarial, style: 'tableHeader', fontSize: 10},
        //                 {text: 'Banco ' + processo.empresa_receber.dados_bancarios.data[0].nome_banco + ' Agência: ' + processo.empresa_receber.dados_bancarios.data[0].agencia + ' C/c: ' + processo.empresa_receber.dados_bancarios.data[0].numero_conta, style: 'tableHeader', fontSize: 10},
        //                 {text: 'Chave Pix: ' + processo.empresa_receber.dados_bancarios.data[0].pix, style: 'tableHeader', fontSize: 10},
        //                 {text: 'Creci: ' + processo.empresa_receber.creci, style: 'tableHeader', fontSize: 10},
        //             ]
		// 		]
		// 	},
		// 	layout: 'noBorders'
        // },

        {
            text: 'Valor da Comissão: R$ ' + processo.comissao.valor_comissao_liquida,
            fontSize: 9,
            bold: false,
            margin: [0, 10, 0, 25]
        },

        // {
        //     text: 'Total Comissão Empresa: R$ ' + processo.comissao.valor_receber_empresa_opcao,
        //     fontSize: 9,
        //     bold: true,
        //     margin: [0, 10, 0, 25]
        // },
        
        {
            table: {
                headerRows: 1,
                widths: [60, 55, 50, 60, 180, 85, 60, 150],
				body: [
					[
                        {text: '', style: 'tableHeader', fontSize: 9, alignment: 'right'},
                        {text: 'Porcentagem', style: 'tableHeader', fontSize: 9, bold: true, alignment: 'center'},
                        {text: 'Descontos', style: 'tableHeader', fontSize: 9, bold: true, alignment: 'center'},
                        {text: 'A Receber', style: 'tableHeader', fontSize: 9, bold: true, alignment: 'center'},
                        {text: 'Nome Empresarial', style: 'tableHeader', fontSize: 9, bold: true, alignment: 'center'},
                        {text: 'CNPJ (MEI)/CPF', style: 'tableHeader', fontSize: 9, bold: true, alignment: 'center'},
                        {text: 'CRECI', style: 'tableHeader', fontSize: 9, bold: true, alignment: 'center'},
                        {text: 'Dados Bancários', style: 'tableHeader', fontSize: 9, bold: true, alignment: 'center'},
                    ],

                    ...dadosVendedor, ...dadosOpcionista, ...dadosGerente, ...dadosGG, ...dadosDiretor, ...dadosRepasse, ...dadosRoyalties, ...dadosComunicacao, ...dadosEmpresa
				],
            },
            layout: 'headerLineOnly'
        }
    ];

    function Rodape(currentPage, pageCount){
        return{
            margin: [20, 10, 20, 0], //left, top, right, bottom
            table: {
                headerRows: 1,
                widths: [150, '*', 150],
                body: [
                    [
                        {
                            text: 'Atualizado em: ' + processo.comissao?.data_atualizacao,
                            fontSize: 9,
                            bold: false,
                            alignment: 'left',
                        },
                        {
                            text: 'DNA Imóveis LTDA',
                            fontSize: 9,
                            bold: false,
                            alignment: 'center',
                        },
                        {
                            text: `Página ${currentPage.toString()} de ${pageCount}`,
                            fontSize: 9,
                            alignment: 'right',
                            margin: [0, 0, 0, 10],
                        }
                    ]
                ]
            },
            layout: 'noBorders',
        }
    }

    const docDefinitions = {
        pageSize: 'A4',
        pageMargins: [15, 50, 15, 40],
        pageOrientation: 'landscape',

        header: [reportTitle],
        content: [details],
        footer: Rodape
    }

    pdfMake.createPdf(docDefinitions).download('Planilha_de_Comissao_' + enderecoNomePDF + '.pdf') //Fazer download do PDF
    //pdfMake.createPdf(docDefinitions).open({}, window.open('', '_blank')); //Visualizar o PDF sem dowload

    return (
        <div></div>
    )
}