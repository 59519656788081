import React, { useContext} from 'react';
import {
    Pane,
} from 'evergreen-ui';

//import { useHistory } from 'react-router-dom';

import './index.css';

import PosNegociacaoContext from '../../../../context/PosNegociacaoContext';

//Components
import Vendedor from '../Form/components/EspelhoDeVendas/Vendedor';
import Comprador from '../Form/components/EspelhoDeVendas/Comprador';
import Imovel from '../Form/components/EspelhoDeVendas/Imovel';
import Comissao from '../Form/components/EspelhoDeVendas/Comissao';

//Máscaras
/*import cepMask from '../../../../components/cepMask';
import somenteNumero from '../../../../components/somenteNumero';
import dateMask from '../../../../components/dateMask';*/

export default function FormEspelhoVenda() {

    const {
        posicaoEspelhoVenda,
    } = useContext(PosNegociacaoContext);

    //const usuario_id = localStorage.getItem('usuario_id');
    //const app = Serve();
    //const history = useHistory();
    //const [token] = useState(localStorage.getItem('token'));
    //const [gerente_id] = useState(localStorage.getItem('usuario_id'));

    return (
        <Pane>
            {posicaoEspelhoVenda === 1 && <Imovel />}
            {posicaoEspelhoVenda === 2 && <Vendedor />}
            {posicaoEspelhoVenda === 3 && <Comprador />}
            {posicaoEspelhoVenda === 4 && <Comissao />}
        </Pane>
    )
}