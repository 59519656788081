import React, { useContext, useState, useEffect } from 'react';
import 'react-phone-input-2/lib/bootstrap.css'
import { Pane, Spinner, Tab, Tablist, Table, Badge } from 'evergreen-ui';
import ShowDocument from '../../../../../../app/showDocument';

//Context
import VendasContext from '../../../../../../context/VendasContext';

export default function Vendedor() {

    const [idVendedor, setIdVendedor] = useState('');
    const [token] = useState(localStorage.getItem('token'));

    const {
        loading,
        //dadosVenda,
        dadosReciboEnviado,
        setValidateMinutaVendedorGenero,
        setValidateMinutaVendedorEstadoCivil,
        setValidateMinutaVendedorRegimeCasamento,
        setCheckedMinutaVendedorUniaoEstavel,
        setMinutaVendedorEstadoCivil,
        setMinutaVendedorRegimeCasamento,
        setMinutaVendedorUniaoEstavel,
        setValidateMinutaVendedorConjuge,
    } = useContext(VendasContext);


    let msgCampo = '----'

    //Tabs e PF/PF
    const idProcesso = dadosReciboEnviado.processo_id;
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [tabsCadastro, setTabsCadastro] = useState([])
    const [posicaoTabs, setPosicaoTabs] = useState([]);
    const [displayForm, setDisplayForm] = useState(false);
    const [checkedPessoaJuridica, setCheckedPessoaJuridica] = useState(false);
    const [tipoPessoaValues, setTipoPessoaValues] = useState([]);

    const [validarPF, setValidarPf] = useState([]);
    const [validarPJ, setValidarPJ] = useState([]);

    const [vendedoresArray, setVendedoresArray] = useState([]);

    const [estadoCivilValues, setEstadoCivilValues] = useState([]);
    const [idsVendedores, setIdsVendedores] = useState([]);
    const [generoUsuario, setGeneroUsuario] = useState([]);
    const [valorGenero, setValorGenero] = useState('');
    const [telNumberValue, setTelNumberValue] = useState([]);
    const [countryCode, setCountryCode] = useState([]);
    const [sinalizarValidacao, setSinalizarValidacao] = useState([]);
    const [disableButton, setDisableButton] = useState([]);
    //array de endereço
    const [arrayEndereco, setArrayEndereco] = useState([]);

    useEffect(() => {
        const returnVendedor = async () => {

            if (dadosReciboEnviado.vendedores['data'].length > 0) {
                setDisplayForm(true);
                setPosicaoTabs([...posicaoTabs, 1]);
                //setPrimeiroDocumento(true);
                const tipoPessoa = dadosReciboEnviado.vendedores['data'].map(usuario => ({ "usuario_id": usuario.id, "tipo_pessoa": usuario.tipo_pessoa == 1 ? true : false }));
                setTipoPessoaValues(tipoPessoa);

                const estadoCivilSalvo = dadosReciboEnviado.vendedores['data'].map(usuario => ({ "estado_civil": usuario.estado_civil, "uniao_estavel": usuario.uniao_estavel, "regime_casamento": usuario.registro_casamento, "conjuge": usuario.conjuge }));
                setEstadoCivilValues(estadoCivilSalvo);

                const IdsUsuarios = dadosReciboEnviado.vendedores['data'].map(usuario => ({ "usuario_id": usuario.id }));
                setIdsVendedores(IdsUsuarios);

                const generoSalvo = dadosReciboEnviado.vendedores['data'].map(usuario => ({ "genero": usuario.genero }));
                setGeneroUsuario(generoSalvo);
                let validarGenero = generoSalvo.filter(usuario => usuario.genero === generoSalvo);
                dadosReciboEnviado.vendedores['data'][0].genero !== null ? setValidateMinutaVendedorGenero(true) : setValidateMinutaVendedorGenero(false);
                setValorGenero(dadosReciboEnviado.vendedores['data'][0].genero);

                const responsaveisPj = dadosReciboEnviado.vendedores['data'].map(responsavel => ({ nome: responsavel.name, email: responsavel.email, cpf: responsavel.cpf_cnpj }));
            

                const telefoneSalvo = await dadosReciboEnviado.vendedores['data'].map(usuario => ({ "telefone": usuario.telefone }));
                setTelNumberValue(telefoneSalvo);

                const countryCodeDDI = await dadosReciboEnviado.vendedores['data'].map(usuario => ({ "ddi": usuario.ddi }));
                setCountryCode(countryCodeDDI);

                var arrayFinal = [];
                let arrayValidarSInalizacao = [];
                //let arrayForm = [];

                dadosReciboEnviado.vendedores['data'].forEach((element, key_vendedores) => {
        
                    arrayFinal.push(key_vendedores);
                    arrayValidarSInalizacao.push('ok');

                    //endereco
                    let addArrayEndereco = { cep: element.cep, bairro: element.bairro, logradouro: element.logradouro, numero: element.numero, unidade: element.unidade, complemento: element.complemento, cidade: element.cidade, estado: element.uf };
                    arrayEndereco.push(addArrayEndereco);
                    setArrayEndereco([...arrayEndereco]);


                });
                setTabsCadastro(arrayFinal);
                setSinalizarValidacao(arrayValidarSInalizacao);

                //verificarTipoVendedor(0);
            }
            else {
                setDisplayForm(false);

                setTabsCadastro([1]);
                setDisableButton([true]);
                setTipoPessoaValues([...tipoPessoaValues, { usuario_id: "", tipo_pessoa: false }])
                setEstadoCivilValues([...estadoCivilValues, { estado_civil: "", uniao_estavel: "", regime_casamento: '0', conjuge: "" }])
                setIdsVendedores([...idsVendedores, { usuario_id: msgCampo }])
                setGeneroUsuario([...generoUsuario, { genero: msgCampo }]);
                setTelNumberValue([...telNumberValue, { telefone: msgCampo }]);
                setCountryCode([...countryCode, { ddi: msgCampo }]);

                setArrayEndereco([...arrayEndereco, { cep: msgCampo, bairro: msgCampo, logradouro: msgCampo, numero: msgCampo, unidade: msgCampo, complemento: msgCampo, cidade: msgCampo, estado: msgCampo }])
                

                //Pessoa Física e Júridica
                if (checkedPessoaJuridica === false) {
                    setVendedoresArray([...vendedoresArray, { tipo_pessoa: 0, cpf_cnpj: msgCampo }]);

                    setValidarPf([...validarPF, {
                        cpf: false,
                        telefone: false,
                        genero: false,
                        nome: false,
                        nacionalidade: false,
                        nomeMae: false,
                        estadoCivil: false,
                        regimeCasamento: false,
                        uniaoEstavel: false,
                        conjuge: false,
                        cep: false,
                        numero: false,
                    }])

                    setValidarPJ([...validarPJ, {}]);
                }
                else {
                    setVendedoresArray([...vendedoresArray, { tipo_pessoa: 1, cpf_cnpj: msgCampo }]);

                    setValidarPJ([...validarPJ, {
                        cnpj: false,
                        telefone: false,
                        razaoSocial: false,
                        nomeFantasia: false,
                        cep: false,
                        numero: false,
                    }])

                    setValidarPf([...validarPF, {}]);
                }
            }
        }
        returnVendedor();
    }, []);

    const verificaGenero = async () => {
        let validarGenero = generoUsuario.filter(usuario => usuario.genero === '');
        validarGenero.length === 0 ? setValidateMinutaVendedorGenero(true) : setValidateMinutaVendedorGenero(false);
    }

    const validarEtapasEstadoCivil = async (e, index) => {
        setValidateMinutaVendedorEstadoCivil(true);
        setValidateMinutaVendedorRegimeCasamento(true);
        setValidateMinutaVendedorConjuge(true);

        let constValidarEstadoCivil = 0;
        let constValidarRegimeCasamento = 0;
        let constValidarConjuge = 0;

        estadoCivilValues.forEach((element, index_estado) => {
            if (element.estado_civil === '' || element.estado_civil === null || element.estado_civil === 0) {
                constValidarEstadoCivil += 1;
            }
            
            if (element.estado_civil === '1' && element.regime_casamento === '0') {
                constValidarRegimeCasamento += 1;
            }

            if ((element.estado_civil === '1' && element.conjuge === '') || (element.estado_civil === '1' && element.conjuge === null)) {
                constValidarConjuge += 1;
            }

            if ((element.estado_civil !== '1' && element.uniao_estavel === 'S' && element.conjuge === '') ||
                element.estado_civil !== '1' && element.uniao_estavel === 'S' && element.conjuge === null) {
                constValidarConjuge += 1;
            }
        });

        if (constValidarEstadoCivil > 0) {
            setValidateMinutaVendedorEstadoCivil(false);
        }

        if (constValidarRegimeCasamento > 0) {
            setValidateMinutaVendedorRegimeCasamento(false);
        }

        if (constValidarConjuge > 0) {
            setValidateMinutaVendedorConjuge(false);
        }
    }

    const handleOpenTab = async (index) => {

        verificaGenero();
        setValorGenero(generoUsuario[index].genero);

        setCheckedPessoaJuridica(tipoPessoaValues[index].tipo_pessoa);
        if (dadosReciboEnviado.vendedores.data[index] !== undefined) {
            setIdVendedor(dadosReciboEnviado.vendedores.data[index].id);
        } else {
            setIdVendedor('');
        }

        //retornar dados estado civil
        const valorEstadoCivil = estadoCivilValues[index]['estado_civil'];
        const valorUniaoEstavel = (estadoCivilValues[index]['uniao_estavel'] === 'S') ? true : false;

        setMinutaVendedorEstadoCivil(valorEstadoCivil);

        if (valorEstadoCivil === '1') {
            //Zera o checkbox de União Estável
            setCheckedMinutaVendedorUniaoEstavel(false);
            setMinutaVendedorUniaoEstavel('');
            setMinutaVendedorRegimeCasamento(estadoCivilValues[index]['regime_casamento']);
        } else {
            setMinutaVendedorRegimeCasamento('0');
            setCheckedMinutaVendedorUniaoEstavel(valorUniaoEstavel);
        }
        validarEtapasEstadoCivil();
    }

    return (
        <Pane>
            {
                dadosReciboEnviado.vendedores.data.length === 0
                    ?
                    <Table.Row>
                        <Table.TextCell className="historicoStatus">
                            <Table.TextCell className="fontStatus">
                                <span>Nenhum Vendedor foi cadastrado no momento.</span>
                            </Table.TextCell>
                        </Table.TextCell>
                    </Table.Row>

                    :
                    <>
                        <div className="form-dialog">
                            <Tablist marginBottom={16} flexBasis={240} marginRight={24} className="tab-form">
                                {tabsCadastro.map((tabCadastro, index) => (
                                    <Tab
                                        key={tabCadastro}
                                        id={"titulo_top_vendedor_" + index}
                                        onSelect={() => setSelectedIndex(index)}
                                        isSelected={index === selectedIndex}
                                        aria-controls={`panel-${tabCadastro}`}
                                        data-tab={tabCadastro}
                                        onClick={() => handleOpenTab(index)}
                                    >

                                        {index < dadosReciboEnviado.vendedores.data.length
                                            ? (dadosReciboEnviado.vendedores.data[index].tipo_pessoa === 1)
                                                ? dadosReciboEnviado.vendedores.data[index].nome_fantasia.slice(0, 10) + '...'
                                                : dadosReciboEnviado.vendedores.data[index].name.slice(0, 10) + '...'
                                            : 'Vendedor ' + tabCadastro}
                                    </Tab>
                                ))}
                            </Tablist>
                        </div>
                        <div className="form-detalhes">
                            <div className="formGroup formAdd">
                                {tabsCadastro.map((tabCadastro, index) => (
                                    <Pane
                                        key={tabCadastro}
                                        id={`panel-${tabCadastro}`}
                                        role="tabpanel"
                                        aria-labelledby={tabCadastro}
                                        aria-hidden={index !== selectedIndex}
                                        display={index === selectedIndex ? 'block' : 'none'}
                                        className="detadelhes-sidesheet"
                                    >
                                        <Pane>
                                            <Badge color="green" className="badge-tipo-pesso">
                                                <span>
                                                    {dadosReciboEnviado.vendedores.data[index].tipo_pessoa === 1 ? 'Pessoa Jurídica' : 'Pessoa Física'}
                                                </span>
                                            </Badge>

                                            <React.Fragment>
                                                <h2 className="subtitle subtitle-table">
                                                    {dadosReciboEnviado.vendedores.data[index].tipo_pessoa === 1 ? 'Dados da Empresa' : 'Dados do Vendedor'}
                                                </h2>
                                            </React.Fragment>

                                            <Table.Row>
                                                <Table.TextCell className="historicoStatus" width={180}>
                                                    <Table.TextCell className="fontStatus">
                                                        <span>{dadosReciboEnviado.vendedores.data[index].tipo_pessoa === 1 ? 'CNPJ' : 'CPF'}</span>
                                                    </Table.TextCell>
                                                    <Table.TextCell className="mensagemStyle">
                                                        <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                            {index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].cpf_cnpj : msgCampo}
                                                        </span>
                                                    </Table.TextCell>
                                                </Table.TextCell>

                                                <Table.TextCell className="historicoStatus">
                                                    <Table.TextCell className="fontStatus">
                                                        <span>Telefone/Celular</span>
                                                    </Table.TextCell>
                                                    <Table.TextCell className="mensagemStyle">
                                                        <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                            {index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].telefone : msgCampo}
                                                        </span>
                                                    </Table.TextCell>
                                                </Table.TextCell>
                                            </Table.Row>

                                            {
                                                dadosReciboEnviado.vendedores.data[index].tipo_pessoa === 0
                                                    ?
                                                    <>
                                                        {/*PF*/}
                                                        <Table.Row>
                                                            <Table.TextCell className="historicoStatus" width={180}>
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Nome completo</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].name : msgCampo}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>

                                                            <Table.TextCell className="historicoStatus">
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Gênero</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {
                                                                            index < dadosReciboEnviado.vendedores.data.length
                                                                                ? dadosReciboEnviado.vendedores.data[index].genero === 'M' ? 'Masculino' : 'Feminino'
                                                                                : msgCampo
                                                                        }
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>
                                                        </Table.Row>

                                                        <Table.Row>
                                                            <Table.TextCell className="historicoStatus" width={180}>
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Data de nascimento</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {/*index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].data_nascimento : msgCampo*/}
                                                                        {dadosReciboEnviado.vendedores.data[index].data_nascimento === "" ? msgCampo : dadosReciboEnviado.vendedores.data[index].data_nascimento}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>

                                                            <Table.TextCell className="historicoStatus" width={180}>
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Nacionalidade</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].nacionalidade : msgCampo}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>
                                                        </Table.Row>

                                                        <Table.Row>
                                                            <Table.TextCell className="historicoStatus" width={180}>
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Nome completo da mãe</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].nome_mae : msgCampo}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>

                                                            <Table.TextCell className="historicoStatus">
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Nome completo do pai</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {/*index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].nome_pai : msgCampo*/}
                                                                        {dadosReciboEnviado.vendedores.data[index].nome_pai === null ? msgCampo : dadosReciboEnviado.vendedores.data[index].nome_pai}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>
                                                        </Table.Row>

                                                        <Table.Row 
                                                            height={
                                                                dadosReciboEnviado.vendedores.data[index].uniao_estavel === null ? 100 : 150
                                                            }
                                                        >
                                                            <Table.TextCell className="historicoStatus">
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Estado Civil</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {/*Estado Civil e Regime de Casamento*/}
                                                                        {
                                                                            index < dadosReciboEnviado.vendedores.data.length ?
                                                                                dadosReciboEnviado.vendedores.data[index].estado_civil === '1' ? 'Casado' :
                                                                                    dadosReciboEnviado.vendedores.data[index].estado_civil === '2' ? 'Solteiro' :
                                                                                        dadosReciboEnviado.vendedores.data[index].estado_civil === '3' ? 'Divorciado' :
                                                                                            dadosReciboEnviado.vendedores.data[index].estado_civil === '4' ? 'Viúvo' :
                                                                                                dadosReciboEnviado.compradores.data[index].estado_civil === '5' ? 'Separado' : ''
                                                                                : ''
                                                                        }
                                                                        {
                                                                            index < dadosReciboEnviado.vendedores.data.length ?
                                                                                dadosReciboEnviado.vendedores.data[index].registro_casamento === '1' ? ' - Separação total de bens' :
                                                                                    dadosReciboEnviado.vendedores.data[index].registro_casamento === '2' ? ' - Separação parcial de bens' :
                                                                                        dadosReciboEnviado.vendedores.data[index].registro_casamento === '3' ? ' - Separação legal de bens' :
                                                                                            dadosReciboEnviado.vendedores.data[index].registro_casamento === '4' ? ' - Comunhão de bens' : ''
                                                                                : ''
                                                                        }
                                                                    </span>
                                                                </Table.TextCell>
                                                                    
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {/*União Estável*/}
                                                                        {
                                                                            !dadosReciboEnviado.vendedores.data[index]
                                                                                ? ''
                                                                                : dadosReciboEnviado.vendedores.data[index].uniao_estavel === null ? '' : <div className="titulo" style={{margin: '5px 0'}}>União Estável</div>
                                                                        }
                                                                        {
                                                                            !dadosReciboEnviado.vendedores.data[index] ? ''
                                                                                : dadosReciboEnviado.vendedores.data[index].uniao_estavel !== null ? 'Sim'
                                                                                    : ''
                                                                        }
                                                                    </span>
                                                                </Table.TextCell>

                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {/*Conjuge*/}
                                                                        {!dadosReciboEnviado.vendedores.data[index]
                                                                            ? ''
                                                                            : dadosReciboEnviado.vendedores.data[index].conjuge === null ? '' : <div className="titulo" style={{margin: '5px 0'}}>Nome completo do Cônjuge/Companheiro(a)</div>
                                                                        }
                                                                        {
                                                                            !dadosReciboEnviado.vendedores.data[index] ? ''
                                                                                : dadosReciboEnviado.vendedores.data[index].conjuge
                                                                        }
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>
                                                        </Table.Row>

                                                        <Table.Row>
                                                            <Table.TextCell className="historicoStatus">
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Profissão</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].profissao : msgCampo}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>
                                                        </Table.Row>

                                                        <Table.Row>
                                                            <Table.TextCell className="historicoStatus" width={100}>
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>R.G</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {/*index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].rg : msgCampo*/}
                                                                        {dadosReciboEnviado.vendedores.data[index].rg === null ? msgCampo : dadosReciboEnviado.vendedores.data[index].rg}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>

                                                            <Table.TextCell className="historicoStatus" width={100}>
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Expedita por</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {/*index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].rg_expedido : msgCampo*/}
                                                                        {dadosReciboEnviado.vendedores.data[index].rg_expedido === null ? msgCampo : dadosReciboEnviado.vendedores.data[index].rg_expedido}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>

                                                            <Table.TextCell className="historicoStatus" width={100}>
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Data expedição</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {/*index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].data_rg_expedido : msgCampo*/}
                                                                        {dadosReciboEnviado.vendedores.data[index].data_rg_expedido === null ? msgCampo : dadosReciboEnviado.vendedores.data[index].data_rg_expedido}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>
                                                        </Table.Row>

                                                        <Table.Row>
                                                            <Table.TextCell className="historicoStatus">
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>E-mail</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {/*index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].email : msgCampo*/}
                                                                        {dadosReciboEnviado.vendedores.data[index].email === null ? msgCampo : dadosReciboEnviado.vendedores.data[index].email}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>
                                                        </Table.Row>

                                                        <React.Fragment>
                                                            <h2 className="subtitle subtitle-table">Procurador</h2>
                                                        </React.Fragment>

                                                        <Table.Row>
                                                            <Table.TextCell className="historicoStatus" width={200}>
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Nome completo</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {
                                                                            index < dadosReciboEnviado.vendedores.data.length
                                                                                ? dadosReciboEnviado.vendedores.data[index].procurador !== null ? dadosReciboEnviado.vendedores.data[index].procurador.nome : msgCampo
                                                                                : msgCampo
                                                                        }
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>

                                                            <Table.TextCell className="historicoStatus">
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Telefone/Celular</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {
                                                                            index < dadosReciboEnviado.vendedores.data.length
                                                                                ? dadosReciboEnviado.vendedores.data[index].procurador !== null ? dadosReciboEnviado.vendedores.data[index].procurador.nome : msgCampo
                                                                                : msgCampo
                                                                        }
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>
                                                        </Table.Row>

                                                    </>

                                                    :
                                                    <>
                                                        {/*PJ*/}
                                                        <Table.Row>
                                                            <Table.TextCell className="historicoStatus">
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Razão Social</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].razao_social : msgCampo}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>
                                                        </Table.Row>

                                                        <Table.Row>
                                                            <Table.TextCell className="historicoStatus">
                                                                <Table.TextCell className="fontStatus">
                                                                    <span>Nome Fantasia</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell className="mensagemStyle">
                                                                    <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                        {index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].nome_fantasia : msgCampo}
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.TextCell>
                                                        </Table.Row>
                                                    </>
                                            }

                                            <React.Fragment>
                                                <h2 className="subtitle subtitle-table">
                                                    {dadosReciboEnviado.vendedores.data[index].tipo_pessoa === 0 ? 'Endereço' : 'Endereço da empresa'}
                                                </h2>
                                            </React.Fragment>

                                            <Table.Row>
                                                <Table.TextCell className="historicoStatus" width={100}>
                                                    <Table.TextCell className="fontStatus">
                                                        <span>CEP</span>
                                                    </Table.TextCell>
                                                    <Table.TextCell className="mensagemStyle">
                                                        <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                            {index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].cep : msgCampo}
                                                        </span>
                                                    </Table.TextCell>
                                                </Table.TextCell>

                                                <Table.TextCell className="historicoStatus" width={100}>
                                                    <Table.TextCell className="fontStatus">
                                                        <span>Bairro</span>
                                                    </Table.TextCell>
                                                    <Table.TextCell className="mensagemStyle">
                                                        <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                            {index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].bairro : msgCampo}
                                                        </span>
                                                    </Table.TextCell>
                                                </Table.TextCell>

                                                <Table.TextCell className="historicoStatus">
                                                    <Table.TextCell className="fontStatus">
                                                        <span>Logradouro</span>
                                                    </Table.TextCell>
                                                    <Table.TextCell className="mensagemStyle">
                                                        <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                            {index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].logradouro : msgCampo}
                                                        </span>
                                                    </Table.TextCell>
                                                </Table.TextCell>
                                            </Table.Row>

                                            <Table.Row>
                                                <Table.TextCell className="historicoStatus" width={100}>
                                                    <Table.TextCell className="fontStatus">
                                                        <span>Número</span>
                                                    </Table.TextCell>
                                                    <Table.TextCell className="mensagemStyle">
                                                        <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                            {index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].numero : msgCampo}
                                                        </span>
                                                    </Table.TextCell>
                                                </Table.TextCell>

                                                <Table.TextCell className="historicoStatus" width={100}>
                                                    <Table.TextCell className="fontStatus">
                                                        <span>Unidade</span>
                                                    </Table.TextCell>
                                                    <Table.TextCell className="mensagemStyle">
                                                        <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                            {/*index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].unidade : msgCampo*/}
                                                            {dadosReciboEnviado.vendedores.data[index].unidade === null ? msgCampo : dadosReciboEnviado.vendedores.data[index].unidade}
                                                        </span>
                                                    </Table.TextCell>
                                                </Table.TextCell>

                                                <Table.TextCell className="historicoStatus">
                                                    <Table.TextCell className="fontStatus">
                                                        <span>Complemento</span>
                                                    </Table.TextCell>
                                                    <Table.TextCell className="mensagemStyle">
                                                        <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                            {/*index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].complemento : msgCampo*/}
                                                            {dadosReciboEnviado.vendedores.data[index].complemento === null ? msgCampo : dadosReciboEnviado.vendedores.data[index].complemento}
                                                        </span>
                                                    </Table.TextCell>
                                                </Table.TextCell>
                                            </Table.Row>

                                            <Table.Row>
                                                <Table.TextCell className="historicoStatus" width={100}>
                                                    <Table.TextCell className="fontStatus">
                                                        <span>Estado</span>
                                                    </Table.TextCell>
                                                    <Table.TextCell className="mensagemStyle">
                                                        <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                            {index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].estado : msgCampo}
                                                        </span>
                                                    </Table.TextCell>
                                                </Table.TextCell>

                                                <Table.TextCell className="historicoStatus">
                                                    <Table.TextCell className="fontStatus">
                                                        <span>Cidade</span>
                                                    </Table.TextCell>
                                                    <Table.TextCell className="mensagemStyle">
                                                        <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                            {index < dadosReciboEnviado.vendedores.data.length ? dadosReciboEnviado.vendedores.data[index].cidade : msgCampo}
                                                        </span>
                                                    </Table.TextCell>
                                                </Table.TextCell>
                                            </Table.Row>
                                            

                                            {
                                                dadosReciboEnviado.vendedores.data[index].tipo_pessoa === 1
                                                    ?
                                                    <>
                                                        <React.Fragment>
                                                            <h2 className="subtitle subtitle-table">
                                                                Sócio e/ou Representantes Legais
                                                </h2>
                                                        </React.Fragment>
                                                        {
                                                            dadosReciboEnviado.vendedores.data[index].representante_socios.data.map((representante, index_representante) =>

                                                                <>
                                                                    <Table.Row>
                                                                        {
                                                                            representante.pj_representante === 1
                                                                                ?
                                                                                <Table.TextCell className="historicoStatus">
                                                                                    <Table.TextCell className="fontStatus">
                                                                                        <Badge color="green" className="badge-tipo-pesso">
                                                                                            <span>
                                                                                                REPRESENTANTE LEGAL
                                                                        </span>
                                                                                        </Badge>
                                                                                    </Table.TextCell>
                                                                                </Table.TextCell>

                                                                                :
                                                                                ''
                                                                        }


                                                                        {
                                                                            representante.pj_socio === 1
                                                                                ?
                                                                                <Table.TextCell className="historicoStatus">
                                                                                    <Table.TextCell className="fontStatus">
                                                                                        <Badge color="green" className="badge-tipo-pesso">
                                                                                            <span>
                                                                                                SÓCIO
                                                                        </span>
                                                                                        </Badge>
                                                                                    </Table.TextCell>
                                                                                </Table.TextCell>
                                                                                :
                                                                                ''
                                                                        }
                                                                    </Table.Row>

                                                                    <Table.Row>
                                                                        <Table.TextCell className="historicoStatus" width={150}>
                                                                            <Table.TextCell className="fontStatus">
                                                                                <span>Nome completo</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell className="mensagemStyle">
                                                                                <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                                    {representante.name !== null ? representante.name : msgCampo}
                                                                                </span>
                                                                            </Table.TextCell>
                                                                        </Table.TextCell>

                                                                        <Table.TextCell className="historicoStatus" width={150}>
                                                                            <Table.TextCell className="fontStatus">
                                                                                <span>CPF</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell className="mensagemStyle">
                                                                                <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                                    {representante.cpf_cnpj !== null ? representante.cpf_cnpj : msgCampo}
                                                                                </span>
                                                                            </Table.TextCell>
                                                                        </Table.TextCell>

                                                                        <Table.TextCell className="historicoStatus" width={150}>
                                                                            <Table.TextCell className="fontStatus">
                                                                                <span>Gênero</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell className="mensagemStyle">
                                                                                <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                                    {representante.genero !== null
                                                                                        ? representante.genero === 'M' ? 'Masculino' : 'Feminino'
                                                                                        : msgCampo}
                                                                                </span>
                                                                            </Table.TextCell>
                                                                        </Table.TextCell>
                                                                    </Table.Row>

                                                                    <Table.Row>
                                                                        <Table.TextCell className="historicoStatus">
                                                                            <Table.TextCell className="fontStatus">
                                                                                <span>E-mail</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell className="mensagemStyle">
                                                                                <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                                    {representante.email !== null ? representante.email : msgCampo}
                                                                                </span>
                                                                            </Table.TextCell>
                                                                        </Table.TextCell>
                                                                    </Table.Row>

                                                                    <Table.Row>
                                                                        <Table.TextCell className="historicoStatus" width={150}>
                                                                            <Table.TextCell className="fontStatus">
                                                                                <span>RG</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell className="mensagemStyle">
                                                                                <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                                    {representante.rg !== null ? representante.rg : msgCampo}
                                                                                </span>
                                                                            </Table.TextCell>
                                                                        </Table.TextCell>

                                                                        <Table.TextCell className="historicoStatus" width={150}>
                                                                            <Table.TextCell className="fontStatus">
                                                                                <span>Expedita por</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell className="mensagemStyle">
                                                                                <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                                    {representante.rg_expedido !== null ? representante.rg_expedido : msgCampo}
                                                                                </span>
                                                                            </Table.TextCell>
                                                                        </Table.TextCell>

                                                                        <Table.TextCell className="historicoStatus" width={150}>
                                                                            <Table.TextCell className="fontStatus">
                                                                                <span>Data de expedição</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell className="mensagemStyle">
                                                                                <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                                    {representante.data_rg_expedido !== null ? representante.data_rg_expedido : msgCampo}
                                                                                </span>
                                                                            </Table.TextCell>
                                                                        </Table.TextCell>
                                                                    </Table.Row>

                                                                    <Table.Row>
                                                                        <Table.TextCell className="historicoStatus">
                                                                            <Table.TextCell className="fontStatus">
                                                                                <span>Profissão</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell className="mensagemStyle">
                                                                                <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                                    {representante.profissao !== null ? representante.profissao : msgCampo}
                                                                                </span>
                                                                            </Table.TextCell>
                                                                        </Table.TextCell>
                                                                    </Table.Row>

                                                                    <Table.Row height={representante.uniao_estavel !== null ? 150 : 100}>
                                                                        <Table.TextCell className="historicoStatus">
                                                                            <Table.TextCell className="fontStatus">
                                                                                <span>Estado Civil</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell className="mensagemStyle">
                                                                                <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                                {/*Estado Civil e Regime de Casamento*/}
                                                                                {
                                                                                    representante.estado_civil !== null ?
                                                                                        representante.estado_civil === '1' ? 'Casado' :
                                                                                            representante.estado_civil === '2' ? 'Solteiro' :
                                                                                                representante.estado_civil === '3' ? 'Divorciado' :
                                                                                                    representante.estado_civil === '4' ? 'Viúvo' :
                                                                                                        representante.estado_civil === '5' ? 'Separado' : ''
                                                                                        : ''
                                                                                }
                                                                                {
                                                                                    representante.remime_casamento !== null ?
                                                                                        representante.remime_casamento === '1' ? ' - Separação total de bens' :
                                                                                            representante.remime_casamento === '2' ? ' - Separação parcial de bens' :
                                                                                                representante.remime_casamento === '3' ? ' - Separação legal de bens' :
                                                                                                    representante.remime_casamento === '4' ? ' - Comunhão de bens' : ''
                                                                                        : ''
                                                                                }
                                                                                </span>
                                                                            </Table.TextCell>

                                                                            <Table.TextCell className="mensagemStyle">
                                                                                <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                                {/*União Estável*/}
                                                                                
                                                                                {
                                                                                    !representante.uniao_estavel
                                                                                        ? ''
                                                                                        : representante.uniao_estavel === null ? '' : <div className="titulo" style={{margin: '5px 0'}}>União Estável</div>
                                                                                }
                                                                                {
                                                                                    !representante.uniao_estavel ? ''
                                                                                        : representante.uniao_estavel !== null ? 'Sim'
                                                                                            : ''
                                                                                }
                                                                                </span>
                                                                            </Table.TextCell>

                                                                            <Table.TextCell className="mensagemStyle">
                                                                                <span className="mensagem" style={{ paddingLeft: 0 }}>
                                                                                {/*Conjuge*/}
                                                                                {!representante.conjuge
                                                                                    ? ''
                                                                                    : representante.conjuge === null ? '' : <div className="titulo" style={{margin: '5px 0'}}>Nome completo do Cônjuge/Companheiro(a)</div>
                                                                                }
                                                                                {
                                                                                    !representante.conjuge ? ''
                                                                                        : representante.conjuge
                                                                                }
                                                                            </span>
                                                                        </Table.TextCell>
                                                                        </Table.TextCell>
                                                                    </Table.Row>                                                                    
                                                                </>
                                                            )
                                                        }
                                                    </>

                                                    : ''
                                            }
                                        </Pane>
                                    </Pane>
                                ))}
                            </div>
                        </div>
                    </>
            }
        </Pane>
    )
}