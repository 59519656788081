import React, { useContext } from 'react';
import { Pane, Table } from 'evergreen-ui';
import VendasContext from '../../../../../../context/VendasContext';

export default function Comissao() {
    const msgCampo = '----';

    const {
        dadosReciboEnviado,
    } = useContext(VendasContext);

    const { comissao, vendedores } = dadosReciboEnviado;
    const arrVendedores = vendedores.data
    const integral = comissao.comissao === 'integral'

    const returnNameVendedor = (id) => {
        const saler = arrVendedores.find((user) => user.id === id)
        if (saler.name) return saler.name
        else return saler.razao_social
    };

    return (
        <div className="detadelhes-sidesheet">
            <Pane>
                <React.Fragment>
                    <h2 className="subtitle subtitle-table">Dados sobre o pagamento de comissão realizado pelos vendedores</h2>
                </React.Fragment>

                <Table.Row height={"auto"}>
                    <Table.TextCell className="p10">
                        <div className='flex mb10'>
                            <div className='mr35 w60'>Tipo </div>
                        </div>
                        <div className='flex mb5'>
                            <div className='wsNormal mr35 w60 fw400'>
                                {integral ? "Integral" : "Parcelada" }
                            </div>
                        </div>
                    </Table.TextCell>
                </Table.Row>

                <Table.Row height={"auto"}>
                    <Table.TextCell className="p10">
                        <div className='flex mb10'>
                            <div hidden={integral} className='mr35 w60'>Parcelas </div>
                            <div className="mr44 w229">Vendedor</div>
                            <div className="mr42 w97">Valor em R$</div>
                        </div>
                        {comissao?.vendedor_responsavel_pagamento.length === 0 && <div className='mb5'>---</div>}
                        {comissao?.vendedor_responsavel_pagamento?.map((vendedor, index) => (
                            <div className='flex mb5'>
                                <div hidden={integral} className='wsNormal mr35 w60 fw400'>
                                    {vendedor.numero_parcela}
                                </div>
                                <div className="mr44 w229 fw400">
                                    {vendedor.usuario_id && returnNameVendedor(vendedor.usuario_id)}
                                </div>
                                <div className="mr42 w97 fw400">
                                    {vendedor.parcela_valor}
                                </div>
                            </div>
                        ))}
                    </Table.TextCell>
                </Table.Row>
                <Pane>
                    <React.Fragment>
                        <h2 className="subtitle subtitle-table">Observações</h2>
                    </React.Fragment>
                </Pane>
                <Table.Row className='hAuto'>
                    <Table.TextCell className="wsNormal hAuto">
                        <div className='fw400 pb5 pt5'>
                            {comissao.observacoes ? comissao.observacoes : msgCampo}
                        </div>
                    </Table.TextCell>
                </Table.Row>
            </Pane>

        </div>
    )
}