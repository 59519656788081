import Axios from 'axios';
import Serve from '../app/serve';

//STATUS LIST DE SERVIÇOS PARA O NUCLEO
async function getListEscrituras() {
  const token = localStorage.getItem('token');
  const app = Serve();
  let data;

  await Axios.get(app + 'tipos_escrituras', {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
    .then(res => {
      if (res) {
        if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
          localStorage.clear();
        } else {
          data = res.data
        }
      }
    })
  return data;
};

export default getListEscrituras;