import Axios from 'axios';
import Serve from '../app/serve';

async function getProcessTableApoio(filtro, page) {
  const token = localStorage.getItem('token');
  const app = Serve();
  let data;

  await Axios.get(app + 'lista_resumida_apoio', {
    headers: {
      Authorization: `Bearer ${token}`,
    }, params: {
      responsavel_id: Number(localStorage.getItem('filtro_responsavel')) || 0,
      page,
      status_id: filtro.status,
      gerente_id: filtro.gerente?.id,
      logradouro: filtro.endereco,
    }
  }).then(res => {
    if (!res) {
      data = false;
    }
    if (res?.data.status && (res.data.status === 498 || res.data.status === 401)) {
      data = false
    } else {
      data = res.data;    
    }
  })

  return data;
};

export default getProcessTableApoio;