import Axios from 'axios';
import Serve from '../app/serve';

async function updateComfirmPgt(parcela_id, check_comfirmacao) {
  const token = localStorage.getItem('token');
  const app = Serve();
  let data;

  await Axios.post(app + 'salvar_comfirmacao_pagamento', {
    parcela_id,
    check_comfirmacao
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
    .then(res => {
      if (res.data.status && res.data.status === (401 || 498)) {
        localStorage.clear();
      } else {
        data = true;
      }
    })
    .catch(function (error) {
      console.log(error);
      data = false
    })

  return data;
};

export default updateComfirmPgt;