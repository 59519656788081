import React, { useContext, useState } from 'react';
import Axios from 'axios';
import Serve from '../../../app/serve';
import { Pane, Button, FilePicker } from 'evergreen-ui';
import PainelAdminContext from '../../../context/PainelAdminContext'
import Lottie from 'react-lottie';
import animationData from '../../../images/Animations/loading.json';

export default function UploadCorretores() {
  const app = Serve(); 

  const {
    loading, setLoading,
  } = useContext(PainelAdminContext);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const [file, setFile] = useState();
  const [error, setError] = useState();
  console.log(file);

  const handleUpload = () => {
    if (!file) setError("Arquivo obrigatório!")
    else {
      setError();
      setLoading(true);
      console.log(file);

      const data = new FormData();
      data.append('arquivos', file);

      Axios.post(app + 'upload_corretores', data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      }).then(res => {
        console.log(res);
        if (res.data) {
          //toaster.success(res.data.mensagem_usuario);
        }
      })
      //Axios aqui


      setLoading(false);
    }
  };

  return (
    <Pane className='painel ml202'>
      {loading ? <div className='mt30vh'><Lottie options={defaultOptions} height={80} width={200} /></div> :
        <Pane className='flex'>
          <div>
            <FilePicker onChange={(e) => setFile(e[0])} />
            {error &&
              <span className="error">{error}</span>
            }
          </div>

          <Button onClick={() => handleUpload()} appearance='primary' className='ml20'>Fazer Upload de Arquivo</Button>
        </Pane>
      }
    </Pane>
  )
}