import React, { useContext } from 'react';
import { Pane } from 'evergreen-ui';
import NegociacaoContext from '../../../../context/NegociacaoContext';
import FormPessoas from './FormPessoas'

export default function FormVendedores({refreshProcess}) {
    const {        
        processSelect,
    } = useContext(NegociacaoContext);

    return (
        <Pane>
            <FormPessoas data={processSelect.imovel.vendedores.data} pessoa='vendedores' refreshProcess={refreshProcess}/>
        </Pane>
    )
}