import Axios from 'axios';
import Serve from '../app/serve';

//STATUS LIST DE SERVIÇOS PARA O NUCLEO
async function getStatusList() {
  const token = localStorage.getItem('token');
  const app = Serve();
  let status;

  await Axios.get(app + 'progressstatus', {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
    .then(res => {
      if (res !== undefined) {
        if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
          localStorage.clear();
        } else {
          status = res.data.data;
        }
      }
    })
  return status;
};

export default getStatusList;