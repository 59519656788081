import React, { useContext, useState } from 'react';
import { Pane, Table, Button, DownloadIcon, Badge, StatusIndicator } from 'evergreen-ui';
import NegociacaoContext from '../../../../context/NegociacaoContext';
import ShowDocument from '../../../../app/showDocument';

export default function Historico() {

    const {
        processSelect,
    } = useContext(NegociacaoContext);
    const [token] = useState(localStorage.getItem('token'));

    const historicoStatus = processSelect?.imovel.historico_acoes;
    const datas = [...new Set(historicoStatus.map(historico => historico.data_historico))]
    console.log(historicoStatus);

    const vendedores = [];
    const representantes = [];
    processSelect?.imovel.vendedores.data.forEach((vendedor) =>
        vendedor.documentos.data.map((doc) => vendedores.push({ 'id': doc.id, 'name': vendedor.name ? vendedor.name : vendedor.razao_social, 'tipo': 'Vendedor' })) &&
        vendedor.representante_socios.data.forEach((representante) =>
            representante.documentos.data.forEach(doc => representantes.push({ 'id': doc.id, 'name': representante.name, 'tipo': 'Vendedor', 'empresa': vendedor.razao_social, 'representante': representante.pj_representante === 1, 'socio': representante.pj_socio === 1 })))
    );
    const compradores = [];
    processSelect?.imovel.compradores.data.forEach((comprador) =>
        comprador.documentos.data.map((doc) => compradores.push({ 'id': doc.id, 'name': comprador.name ? comprador.name : comprador.razao_social, 'tipo': 'Comprador' })) &&
        comprador.representante_socios.data.forEach((representante) =>
            representante.documentos.data.forEach(doc => representantes.push({ 'id': doc.id, 'name': representante.name, 'tipo': 'Comprador', 'empresa': comprador.razao_social, 'representante': representante.pj_representante === 1, 'socio': representante.pj_socio === 1 })))
    );

    const listaDocsNomes = [];
    listaDocsNomes.push(...vendedores)
    listaDocsNomes.push(...compradores)
    listaDocsNomes.push(...representantes)
    ///// console.log(listaDocsNomes);
    const capitalize = str => {
        if (typeof str !== 'string') {
            return '';
        }
        return str.charAt(0).toUpperCase() + str.substr(1);
    };

    const colorStatus = (status_solicitacao) => {
        if (status_solicitacao === 'Entrada') return '#3366FF'
        else if (status_solicitacao === 'Andamento') return 'warning'
        else if (status_solicitacao === 'Parado') return 'danger'
        else return 'success'
    };

    // function capitalizeFirstLetter(string) {
    //     if (typeof string !== 'string') return ''; // Verifica se o input é uma string
    //     if (string.length === 0) return ''; // Verifica se a string não está vazia
    //     return string.charAt(0).toUpperCase() + string.slice(1);
    // }

    return (
        <Pane>
            {datas.map(date =>
                <div style={{ paddingBottom: "0" }}>
                    {historicoStatus.filter(filter => filter.data_historico === date).map(historico =>
                        <Table.Row className="row-historico" style={{ height: 'auto' }}>
                            <Table.TextCell className="historicoStatus">
                                <div className="fontStatus">
                                    <Badge
                                        color={
                                            historico.status_solicitacao === null
                                                ? historico.identifica_documento === "pessoa_fisica" || historico.identifica_documento === 'pessoa_juridica'
                                                    ? "green"
                                                    : "yellow"

                                                // Solcitação Núcleo
                                                : historico.status_solicitacao === 'Entrada'
                                                    ? 'blue'
                                                    : 'yellow'
                                        }
                                        marginBottom={14}
                                        marginRight={17}
                                    >
                                        {
                                            historico.identifica_documento
                                                ? historico.identifica_documento.replace("_", " ")

                                                // Solcitação Núcleo
                                                : historico.status_solicitacao === null
                                                    ? historico.resposta_gerente_id !== null ? 'Escritura' : 'STATUS'
                                                    : historico.status_solicitacao === 'Entrada'
                                                        ? 'SERVIÇO SOLICITADO'
                                                        : 'ATUALIZAÇÃO DE SERVIÇO'
                                            //: "STATUS"
                                        }
                                    </Badge>

                                    {listaDocsNomes.filter(doc => doc.id === historico.documento_id)[0]?.representante &&
                                        <Badge color="neutral" marginBottom={14} marginRight={17}>
                                            REPRESENTANTE LEGAL
                                        </Badge>
                                    }

                                    {listaDocsNomes.filter(doc => doc.id === historico.documento_id)[0]?.socio &&
                                        <Badge color="neutral" marginBottom={14}>
                                            SÓCIO
                                        </Badge>
                                    }

                                </div>
                                <div className="fontStatus">
                                    {historico.status !== null
                                        ?
                                        <>
                                            <span className="boldStatus"> {historico.data_historico} </span> - {historico.mensagem === 'Gerente realizou correções solicitadas.' ? 'Venda corrigida' : historico.status}
                                        </>

                                        :
                                        <>
                                            {
                                                historico.status_solicitacao !== null
                                                    // Solcitação Núcleo
                                                    ?
                                                    <>
                                                        <span className="boldStatus"> {historico.data_historico}</span> - {historico.nome_solicitacao}
                                                        {
                                                            <>
                                                                <div>
                                                                    {historico.status_solicitacao !== 'Entrada' &&
                                                                        <div className="historico-status-solicitacao">
                                                                            <StatusIndicator
                                                                                color={colorStatus(historico.status_solicitacao)}
                                                                                width={10}
                                                                            /> {historico.status_solicitacao === 'Andamento'
                                                                                ? 'Em andamento'
                                                                                : historico.status_solicitacao
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {/* {console.log(historico)} */}
                                                                    <span className="historico-mensagem-d-solicitacao">{
                                                                        historico.status_solicitacao === 'Entrada'
                                                                            ? 'Solicitação de serviço ao Núcleo de Certidões pelo Pós-venda.'
                                                                            : 'O status do serviço foi atualizado pelo Núcleo de Certidões.'
                                                                    }</span>
                                                                </div>

                                                                {
                                                                    // Descomentar quando tiver na API

                                                                    historico.data_previsao !== null && historico.status_solicitacao !== 'Entrada'
                                                                        ?
                                                                        <div style={{ marginBottom: '15px', marginTop: '15px' }}>
                                                                            <span className="boldStatus">Previsão de entrega:</span>
                                                                            <div className="historico-mensagem-solicitacao">
                                                                                {historico.data_previsao}
                                                                            </div>
                                                                        </div>

                                                                        :
                                                                        ''
                                                                }

                                                                {
                                                                    historico.mensagem_solicitacao !== '' &&
                                                                    <div style={{ marginTop: '15px' }}>
                                                                        <span className="boldStatus">
                                                                            {historico.status_solicitacao === 'Entrada'
                                                                                ? 'Observações do Pós-venda:' : 'Observações do Núcleo de Certidões:'
                                                                            }
                                                                        </span>
                                                                        <div className="historico-mensagem-solicitacao" style={{ fontWeight: '400' }}>
                                                                            {historico.mensagem_solicitacao}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    </>

                                                    : historico.identifica_documento === 'pessoa_fisica' || historico.identifica_documento === 'pessoa_juridica' || historico.identifica_documento === 'imóvel'
                                                        ?
                                                        <>
                                                            <span className="boldStatus"> {historico.data_historico} - </span>
                                                            {listaDocsNomes.filter(doc => doc.id === historico.documento_id).map(doc =>
                                                                doc.empresa
                                                                    ? doc.tipo + ' > ' + doc.empresa + ' > ' + doc.name
                                                                    : doc.tipo + ' > ' + doc.name
                                                            ) + ' > ' + (historico.documento_deletado === 1 ? `O documento ${historico.tipos_multiplos_documentos.map((e, index) => " " +  e.nome_tipo )} foi deletado do processo.` : historico.tipos_multiplos_documentos.map((e, index) => " " + e.nome_tipo ))}
                                                        </>
                                                        : historico.resposta_gerente_id !== null
                                                            ?
                                                            <>
                                                                {/*Data FeedBack Escritura*/}
                                                                <span className="boldStatus">{historico.data_historico}</span> - {historico.status_escritura}
                                                            </>

                                                            :
                                                            <>
                                                                <span className="boldStatus">{historico.data_historico}</span> - {capitalize(historico.identifica_documento) + ' > '} {historico.nome}
                                                            </>
                                            }
                                        </>
                                    }
                                </div>
                                <Table.TextCell paddingLeft={0} className="historic-message">
                                    {/*Conteúdo Escritura*/}
                                    {
                                        historico.status_id === 5
                                            ?
                                            <div>
                                                {
                                                    historico.data_escritura === null
                                                        ?
                                                        ''
                                                        :
                                                        <div>
                                                            <div className="mensagem" style={{ marginBottom: '10px' }}>
                                                                O pós-venda alterou o status da venda para Escritura, além de compartilhar a data e local onde as assinaturas serão realizadas.
                                                            </div>

                                                            <div className="mensagem" style={{ marginBottom: '10px' }}>
                                                                Dia - {historico.data_escritura}, Hora - {historico.hora_escritura} e Local - {historico.logradouro}, {historico.numero}{historico.unidade === null ? '' : ', '} {historico?.unidade}{historico.complemento === null ? '' : ' / '}{historico?.complemento}
                                                            </div>
                                                        </div>
                                                }
                                            </div>

                                            :
                                            historico.resposta_gerente_id !== null
                                                ?
                                                <>
                                                    {/*Conteúdo Feedback Escritura*/}

                                                    {
                                                        historico.motivo_escritura !== null &&
                                                        <div className="mensagem" style={{ marginBottom: '10px' }}>
                                                            Observações: {historico.motivo_escritura}
                                                        </div>
                                                    }

                                                    {
                                                        historico.resposta_gerente_id === 6 &&
                                                        <div className="mensagem" style={{ marginBottom: '10px' }}>
                                                            Dia - {historico.data_escritura}, Hora - {historico.hora_escritura} e Local - {historico.logradouro}, {historico.numero}{historico.unidade === null ? '' : ', '} {historico?.unidade}{historico.complemento === null ? '' : ' / '}{historico?.complemento}
                                                        </div>
                                                    }

                                                </>
                                                :
                                                ''
                                    }

                                    <span className="historico-mensagem-d-solicitacao">
                                        {historico.responsavel_novo_id !== null ? 'Nayara tirou ' + historico.antigo_responsavel + ' como responsável pela venda e colocou ' + historico.novo_responsavel : ''

                                        }</span>

                                    {historico.mensagem === null ? '' : <span className="mensagem">{historico.mensagem}</span>}
                                                                        
                                    {/* {historico.link === null
                                        ? ''
                                        :

                                        <Button
                                            marginTop={14}
                                            onClick={(e) => ShowDocument(historico.documento_id, "documento", token)}
                                        >
                                            <DownloadIcon className="iconLink" width={17} marginRight={5} /><span> Baixar documento </span>
                                        </Button>
                                    } */}

                                    {
                                        ((historico.documento_id !== null && (historico.link === null || historico.link === '') && historico.documento_ativado !== 0) && historico.solicitacao_id === null) &&
                                        <Button
                                            marginTop={14}
                                            onClick={(e) => ShowDocument(historico.documento_id, "documento", token)}
                                        >
                                            <DownloadIcon className="iconLink" width={17} marginRight={5} /><span> Baixar documento </span>
                                        </Button>
                                    }

                                    {/* {
                                        ((historico.documento_id !== null && (historico.link !== null && historico.link !== '')) && historico.solicitacao_id === null) &&
                                        <a href={historico.link} target="_blank" rel="noreferrer" title={historico.nome}>{historico.link} <LinkIcon className="iconLink" width={17} /></a>
                                    } */}

                                    {((historico.documento_id !== null && historico.solicitacao_id !== null) && historico.documento_ativado !== 0) &&
                                        <div>
                                            <p className="color696F8C f12"> O upload de documento foi realizado pelo Núcleo de Certidões.</p>
                                            <Button
                                                marginTop={14}
                                                onClick={(e) => ShowDocument(historico.documento_id, "documento", token)}
                                            >
                                                <DownloadIcon className="iconLink" width={17} marginRight={5} /><span> Baixar documento </span>
                                            </Button>
                                        </div>
                                    }
                                </Table.TextCell>
                            </Table.TextCell>
                        </Table.Row>

                    )}
                </div>
            )}
        </Pane>
    )
}