import React, { useState, useContext, useEffect } from 'react';
import { Pane, SelectField, DeleteIcon, Button, PlusIcon, Textarea, TextInputField, toaster, Badge } from 'evergreen-ui';
import NegociacaoContext from '../../../../context/NegociacaoContext';
import formatoMoeda from '../../../../components/formatoMoeda';
import removeVendedorRespPagamento from '../../../../apis/removeVendedorRespPagamento';

//CSS inputs
const inputGreen = {
  borderColor: '#429777',
  background: '#F5FBF8'
};

const inputDefault = {
  borderColor: '#c1c4d6',
  background: '#fff'
};

const inputError = {
  borderColor: '#FF0000',
  background: '#fff'
};

function Select({ label, width, value, errors, list, ...rest }) {
  return (
    <SelectField
      label={label}
      width={width}
      margin={0}
      className={value ? 'inputGreenSelect bgWhite' : errors ? 'inputRedSelect bgWhite' : ''}
      value={value}
      style={value ? inputGreen : errors ? inputError : inputDefault}
      {...rest}
    >
      <option value="0" hidden>{label === 'Parcela*' ? '' : 'Selecione'}</option>
      {list.map((value) => (
        <option value={value.id} key={value.id}>{value.name}</option>
      ))}
    </SelectField>
  )
};

export default function PrevisaoComissa({ refreshProcess, watch, setValue, errors, register, unregister, active }) {

  const {
    processSelect, processoId,
  } = useContext(NegociacaoContext);

  const emptyValuePayer = { id: '', parcela: 1, user_id: '', porcentagem: '', valor: '' };
  const listTypePayment = [{ id: 'integral', name: 'Integral' }, { id: 'partes', name: 'Parcelada' }];
  const [listSellers, setListSellers] = useState([]);
  const [arrayQntParcelas, setArrayQntParcelas] = useState([]);

  useEffect(() => {
    // PEGO AS LISTAS QUE ABASTECEM OS SELECTS VENDEDORES E PARCELAS
    if (processSelect) {
      const sellers = processSelect.imovel?.vendedores?.data?.map((vendedor) => ({ id: vendedor.id, name: vendedor.name ? vendedor.name : vendedor.razao_social }))
      setListSellers(sellers || []);

      const parcelas = processSelect.imovel?.comissao?.parcelas_empresa?.map((parcela, index) => ({ id: index + 1, name: index + 1 }))
      setArrayQntParcelas(parcelas);
    }
  }, [processSelect])

  // console.log('Form: ', watch());
  // console.log('Errors: ', errors);
  // console.log('processSelect: ', processSelect);

  const addPayer = () => {
    const oldArray = watch('arrayPayers');
    oldArray.push(emptyValuePayer);
    setValue('arrayPayers', oldArray);
  };

  const removePayer = async (i) => {
    const oldArray = watch('arrayPayers');
    if (oldArray[i].id) {
      const res = await removeVendedorRespPagamento(oldArray[i].id);
      if (!res) return toaster.danger("Erro, tente novamento mais tarde");
      else {
        toaster.success(res.mensagem_pagamento);
        refreshProcess(processoId);
      }
    }
    unregister('arrayPayers.' + i);
    oldArray.splice(i, 1);
    setValue('arrayPayers', oldArray);
  };

  const handleSelect = (e, path) => {
    const value = e.target.value;
    setValue(path, value);
  };

  return (
    <Pane className='flexColl gap24'>
      <Pane className='flex gap24 end'>
        <Select
          label='Tipo de pagamento'
          width={191}
          list={listTypePayment}
          value={watch('tipo_pagamento')}
          // onChange={(e) => handleSelect(e, 'tipo_pagamento')}
          // errors={!!errors?.tipo_pagamento}
          disabled
        />
        <Badge color="blue">{arrayQntParcelas.length >= 1 ? arrayQntParcelas.length + 'x Parcelas' : 'Parcela única'}</Badge>
      </Pane>

      {watch('arrayPayers').map((payer, index) => (
        <Pane key={index} className='flex gap20' alignItems='end'>
          {watch('tipo_pagamento') === 'partes' &&
            <Select
              label='Parcela*'
              width={62}
              list={arrayQntParcelas}
              value={payer.parcela}
              errors={!!errors?.arrayPayers?.[index]?.parcela}
              {...register('arrayPayers.' + index + '.parcela', {
                // required: active,
                onChange: (e) => handleSelect(e, 'arrayPayers.' + index + '.parcela')
              })}
            />
          }
          <Select
            label='Selecione o vendedor*'
            width={191}
            list={listSellers}
            value={payer.user_id}
            errors={!!errors?.arrayPayers?.[index]?.user_id}
            {...register('arrayPayers.' + index + '.user_id', {
              // required: active,
              onChange: (e) => handleSelect(e, 'arrayPayers.' + index + '.user_id')
            })}
          />

          {/* <div className='flex'>
            <TextInputField
              label='Porcentagem*'
              width={112}
              margin={0}
              value={payer.porcentagem}
              {...register('arrayPayers.' + index + '.porcentagem', {
                required: true,
              })}
              style={payer.porcentagem ? inputGreen : errors?.arrayPayers?.[index]?.porcentagem ? inputError : inputDefault}
            />
            <span className='sobrepor mr10'>%</span>
          </div> */}

          <TextInputField
            label='Valor em R$*'
            margin={0}
            width={112}
            placeholder='R$'
            value={payer.valor}
            {...register('arrayPayers.' + index + '.valor', {
              // required: active,
              setValueAs: e => formatoMoeda(e)
            })}
            style={payer.valor ? inputGreen : errors?.arrayPayers?.[index]?.valor ? inputError : inputDefault}
          />

          <Button
            appearance="primary"
            intent="danger"
            onClick={() => removePayer(index)}
            type="button"
          >
            <DeleteIcon
              size={10}
              color='white'
            />
          </Button>

        </Pane>
      ))}

      <Button
        onClick={() => addPayer()}
        type="button"
        style={{ 'color': '#696F8C', 'border-color': '#D8DAE5' }}
        width={172}
      >
        <PlusIcon
          width={10}
          color='#8F95B2'
          marginRight={8}
        />
        Adicionar vendedor
      </Button>

      <Pane className='flexColl gap15'>
        <h4 className='fw600'>Observações</h4>
        <span>Caso necessário, insira observações para que o financeiro e gerente fiquem atentos.</span>

        <Textarea
          placeholder='Ex.: A venda foi realizada por dois corretores; ambos receberão parte da comissão.'
          {...register('.observacoes_pagamento_comissao')}
          style={watch('observacoes_pagamento_comissao') ? inputGreen : inputDefault}
        />
      </Pane>
    </Pane>
  )
}