import React, { useState, useContext, useEffect } from 'react';
import { Pane, TextInputField, Label, Textarea, DocumentIcon  } from 'evergreen-ui';
import './index.css';
import PosNegociacaoContext from '../../../../context/PosNegociacaoContext';
import Axios from 'axios';
import Serve from '../../../../app/serve';
//import { useHistory } from 'react-router-dom';

export default function Venda(props) {
    //const history = useHistory();
    const [token] = useState(localStorage.getItem('token'));
    //const [responsavel_id] = useState(localStorage.getItem('usuario_id'));
    const idProcesso = props.idProcesso;

    const app = Serve();

    const {
        dadosReciboEnviado,
    } = useContext(PosNegociacaoContext);

    console.log(dadosReciboEnviado);

    const imovelProcesso = 1;

    const showDocument = async (e, id_imovel) => {
        console.log(id_imovel);

        Axios.post(app + 'exibir_arquivo', {
            "id": id_imovel
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',
        })
            .then(res => {
                console.log(res);
                let type_arquivo = res.data.type;
                if (type_arquivo !== 'text/html') {
                    const file = new Blob(
                        [res.data],
                        { type: type_arquivo });

                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                } else {
                    console.log("não teve retorno satisfatorio");
                }


            })

        //window.open('/documento/recibo-de-sinal', '_blank');
    }

    return (
        <div>
            <div className="formGroup formAdd">
                <form id="formGroup">
                    <Pane>
                        <input id="imovel_id-1" type="hidden" value={imovelProcesso !== undefined ? imovelProcesso.id : ''} />
                        <input id="processo_id-1" type="hidden" value={idProcesso !== undefined ? idProcesso : ''} />
                        <h2 style={{ fontWeight: 600, fontSize: 18, color: '#474D66', margin: '20px 0 20px 0'}}>Recibo de Sinal</h2>
                        <div className="rowForm">
                            <TextInputField width={160}
                                label="Data de assinatura"
                                description=""
                                placeholder="O RGI possui 11 digitos"
                                defaultValue={dadosReciboEnviado.dataAssinatura}
                                id={"data-assinatura-1"}
                                onChange={(e) => (e)}
                                readOnly
                                disabled
                                style={{ color: '#8F95B2' }}
                            />

                            <TextInputField width={200}
                                label="Forma de pagamento"
                                description=""
                                placeholder=""
                                defaultValue={dadosReciboEnviado.formaPagamentoId === '2,3' ? 'Financiamento, FGTS' : dadosReciboEnviado.formaPagamento}
                                id={"forma-pagamento-1"}
                                maxLength="9"
                                onChange={(e) => (e)}
                                readOnly
                                disabled
                                style={{ color: '#8F95B2' }}
                            />
                        </div>

                        <div className="doc-name" style={{ margin: '20px 0 30px 0' }} onClick={(e) => showDocument(e, dadosReciboEnviado.imovelId)}>
                            <DocumentIcon size={16} style={{ fill: '#8F95B2' }} />
                            <span className="name-ref">Recibo de Sinal:</span>
                            <span className="link-ref">{dadosReciboEnviado.recibo}</span>
                        </div>

                        <Pane>
                            <Label htmlFor="textarea-2" marginBottom={4} display="block" style={{ fontWeight: 600, fontSize: 18, color: '#474D66', margin: '0 0 20px 0'}}>
                                Observações
                            </Label>
                            <Textarea
                                style={{ color: '#8F95B2' }}
                                id="textarea-2"
                                placeholder={dadosReciboEnviado.observacao === null
                                    ? 'Nenhuma observação foi adicionada para esse imóvel.'
                                    : ''
                                }
                                value={dadosReciboEnviado.observacao !== null
                                    ? dadosReciboEnviado.observacao
                                    : ''}
                                readOnly
                                disabled
                            />
                        </Pane>
                    </Pane>
                </form>
            </div>
        </div>
    )
}