import React, { useState, useContext, useEffect } from 'react';
import {
    Pane,
    Table,
    Button,
    Badge,
    Spinner,
    DotIcon,
    Popover,
    Menu,
    WarningSignIcon,
    TickCircleIcon,
    ShareIcon,
    SmallTickIcon,
    LockIcon,
    Dialog,
    EditIcon,
    ErrorIcon,
    Tablist,
    Tab,
    Tooltip,
    TrashIcon,
    ResetIcon,
    Pagination,
    toaster,
    Checkbox,
    Combobox,
    FilterIcon,
} from 'evergreen-ui';
import Axios from 'axios';
import Serve from '../../../../app/serve';
import { useHistory } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from '../../../../images/Animations/loadingDocs.json';

//Context
import VendasContext from '../../../../context/VendasContext';

//Components
import FormVenda from '../Form/FormVenda';
import DialogRemover from './DialogRemover';
import DialogRestore from './DialogRestore';
import DialogEntregarVenda from './DialogEntregarVenda';

//import CornerDialog from '../CornerDialog';

import '../../../../index.css';
import getProcessosGerente from '../../../../apis/getProcessosGerente';
// import getGerenteConfirmarEscritura from '../../../../apis/getGerenteConfirmarEscritura';
// import DialogConfirmacaoEscritura from './components/Dialog/DialogConfirmacaoEscritura';

export default function VendasAdicionadas() {

    const {
        holidays,
        setCountMenu,
        setPosicaoMinuta,
        setPosicaoBoletimDeVenda,
        itemAdicionado, setItemAdicionado,
        setItemEnviado,
        itemsArquivados, setItemsArquivados,
        loading, setLoading,
        dadosVenda, setDadosVenda,
        setIsShowFormMinuta,
        setCheckedMinutaAVista,
        setCheckedMinutaFgts,
        setCheckedMinutaFinanciamento,
        setCheckedMinutaParcelamento,
        setCheckedMinutaConsorcio,
        setCheckedMinutaPix,
        setMinutaVendaLaudemio,
        setValidateMinutaVendaFormaPagamento,
        setValidateMinutaVendaCep,
        setValidateMinutaVendaNumero,
        setValidateMinutaVendaEscritura,
        setMinutaVendaEscritura,
        setValidateMinutaVendaMatricula,
        setValidateMinutaVendaInscricaoMunicipal,
        setValidateMinutaVendaValorVenda,
        setValidateMinutaVendaValorSinal,
        setValidateMinutaVendaPrazoEscritura,
        setIsShowFormUploadRecibo,
        setVendedorProcesso,
        setCompradorProcesso,
        setIdProcessosModal,
        setValidateFieldValorVenda,
        filtro,
        setFormasPagamento,

        //Lists filtro
        listEnderecos,

        //Remover Venda        
        setIsShowRemover,
        setProcessoIdRemove,

        //Restaurar Venda
        setIsShowRestore,

        //Vendedor
        setDadosMinutaVendedor,
        setMinutaVendedorEstadoCivil,
        setMinutaVendedorUniaoEstavel,
        setCheckedMinutaVendedorUniaoEstavel,
        setValidateMinutaVendedorCpf,
        setValidateMinutaVendedorTelefone,
        setValidateMinutaVendedorDataNascimento,
        setValidateMinutaVendedorNome,
        setValidateMinutaVendedorNomeMae,
        setValidateMinutaVendedorNacionalidade,
        setValidateMinutaVendedorCep,
        setValidateMinutaVendedorNumero,
        setValidateMinutaVendedorRg,
        setValidateMinutaVendedorRgExpedicao,
        setValidateMinutaVendedorDataExpedicao,
        setValidateMinutaVendedorEstadoCivil,
        setValidateMinutaVendedorRegimeCasamento,
        setMinutaVendedorRegimeCasamento,
        setValidateMinutaVendedorConjuge,

        //Comprador
        setDadosMinutaComprador,
        setMinutaCompradorEstadoCivil,
        setMinutaCompradorUniaoEstavel,
        setCheckedMinutaCompradorUniaoEstavel,
        setValidateMinutaCompradorCpf,
        setValidateMinutaCompradorTelefone,
        setValidateMinutaCompradorDataNascimento,
        setValidateMinutaCompradorNome,
        setValidateMinutaCompradorNomeMae,
        setValidateMinutaCompradorNacionalidade,
        setValidateMinutaCompradorCep,
        setValidateMinutaCompradorNumero,
        setValidateMinutaCompradorRg,
        setValidateMinutaCompradorRgExpedicao,
        setValidateMinutaCompradorDataExpedicao,
        setValidateMinutaCompradorEstadoCivil,
        setValidateMinutaCompradorRegimeCasamento,
        setMinutaCompradorRegimeCasamento,
        setValidateMinutaCompradorConjuge,

        //Upload Recibo de Sinal
        setValidateUploadReciboDataAssinatura,
        setValidateUploadReciboDocAssinado,

        //Boletim de Venda
        setIsShowFormBoletimDeVenda,
        isShowEntregarVenda, setIsShowEntregarVenda,
        setIsShowCornerDialog,
        setModelCornerDialog,
        setValidateBoletimVendaDocumentos,

        //Vendedor
        setValidateProcuradorVendedor,
        setValidateTelefoneProcuradorVendedor,
        setCheckedProcuradorVendedor,
        setValidateBoletimVendaDocumentosVendedor,

        //Comprador
        setValidateProcuradorComprador,
        setValidateTelefoneProcuradorComprador,
        setCheckedProcuradorComprador,
        setValidateBoletimVendaDocumentosComprador,

        //Comissão
        setLiquidaRadius,
        totalRadiusComissao, setTotalRadiusComissao,
        setCheckedPrimeiraParte,
        setCheckedSegundaParte,
        setValidateRadiusTotal,
        setValidateRadiusLiquida,
        setValidateComissaoTotal,
        setValidateComissaoLiquida,
        setValidateBoletimVendaDocumentosComissao,
        setRegressivaEntregarVenda,

        pageNumberAdicionados, setPageNumberAdicionados,
        totalPaginasAdicionados, setTotalPaginasAdicionados,
        totalItensAdicionados,

        //Verificar exibição do Btn Gerar Venda
        exibirGerarVenda, setExibirGerarVenda,
        setTotalItensAdicionados, setTotalItensEntregues, setTotalPaginasEntregues, setTotalProcessosFiltradosEnviados,

        // Confirmação Escritura
        modalEscritura, setModalEscritura,
        setShowListaEscritura,
        listaEscritura, setListaEscritura,

        quantFiltro, 
        totalProcessosFiltradosAdicionados, setTotalProcessosFiltradosAdicionados,
    } = useContext(VendasContext);
    //Tabs
    const [tabs] = useState(['Todos', 'Lixeira']);
    const [selectTabs, setSelectTabs] = useState(0);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const history = useHistory();
    const usuario_id = localStorage.getItem('usuario_id');
    const processo_id = localStorage.getItem('processo_id');
    const token = localStorage.getItem('token');
    const app = Serve();

    const today = new Date();
    const isInutilDay = (
        today.getDay() === 0 ||
        (today.getDay() === 5 && today.getHours() >= 18) ||
        today.getDay() === 6 ||
        holidays.some((holiday) =>
            holiday.date.getDate() === today.getDate() &&
            holiday.date.getMonth() === today.getMonth()
        )
    );
    // const isInutilDay = true
    const [checkDiaUtil, setCheckDiaUtil] = useState(false);


    const verificaFormVenda = async (venda) => {
        // Retornar as Formas de Pagamento no Form de Vendas
        let arrayPagamento = null;
        arrayPagamento = venda.informacao.forma_pagamento !== null ? venda.informacao.forma_pagamento.split(',') : 0;

        setFormasPagamento(venda.informacao.forma_pagamento);

        for (let i = 0; i < arrayPagamento.length; i++) {

            if (arrayPagamento[i] === '1') {
                setCheckedMinutaAVista(true);
            }

            if (arrayPagamento[i] === '2') {
                setCheckedMinutaFinanciamento(true);
            }

            if (arrayPagamento[i] === '3') {
                setCheckedMinutaFgts(true);
            }

            if (arrayPagamento[i] === '4') {
                setCheckedMinutaConsorcio(true);
            }

            if (arrayPagamento[i] === '5') {
                setCheckedMinutaParcelamento(true);
            }

            if (arrayPagamento[i] === '6') {
                setCheckedMinutaPix(true);
            }

            if (arrayPagamento[i].length > 0) {
                setValidateMinutaVendaFormaPagamento(true);
            }
        }

        // Retorna os Laudemios do Form de Venda
        let mapa = venda.laudemios.map(laudemio => ({ "tipo_laudemio": laudemio.tipo_laudemio, "valor_laudemio": laudemio.valor_laudemio }));
        setMinutaVendaLaudemio(mapa);

        // Retorna validação
        venda.cep !== null ? setValidateMinutaVendaCep(true) : setValidateMinutaVendaCep(false);
        venda.numero !== null ? setValidateMinutaVendaNumero(true) : setValidateMinutaVendaNumero(false);
        venda.informacao.escritura !== null ? setValidateMinutaVendaEscritura(true) : setValidateMinutaVendaEscritura(false);
        venda.informacao.matricula !== null ? setValidateMinutaVendaMatricula(true) : setValidateMinutaVendaMatricula(false);
        venda.informacao.inscricaoMunicipal !== null ? setValidateMinutaVendaInscricaoMunicipal(true) : setValidateMinutaVendaInscricaoMunicipal(false);
        venda.informacao.valor_venda !== null ? setValidateMinutaVendaValorVenda(true) : setValidateMinutaVendaValorVenda(false);
        venda.informacao.valorSinal !== null ? setValidateMinutaVendaValorSinal(true) : setValidateMinutaVendaValorSinal(false);
        venda.informacao.prazo !== null ? setValidateMinutaVendaPrazoEscritura(true) : setValidateMinutaVendaPrazoEscritura(false);
        venda.informacao.forma_pagamento !== null ? setValidateMinutaVendaFormaPagamento(true) : setValidateMinutaVendaFormaPagamento(false)

        venda.informacao.escritura !== null ? setMinutaVendaEscritura(venda.informacao.escritura) : setMinutaVendaEscritura('');
    };

    const verificarFormVendedor = (venda) => {
        if (venda.vendedores.data.length !== 0) {
            setDadosMinutaVendedor(venda.vendedores.data[0])

            if (venda.vendedores.data[0].estado_civil !== null) {
                setMinutaVendedorEstadoCivil(venda.vendedores.data[0].estado_civil);
            }
            else {
                setMinutaVendedorEstadoCivil('0');
            }

            if (venda.vendedores.data[0].registro_casamento !== null) {
                setMinutaVendedorRegimeCasamento(venda.vendedores.data[0].registro_casamento);
                setValidateMinutaVendedorRegimeCasamento(true);

                if (venda.vendedores.data[0].conjuge !== null) {
                    setValidateMinutaVendedorConjuge(true);
                }
                else {
                    setValidateMinutaVendedorConjuge(false);
                }
            }
            else {
                setMinutaVendedorRegimeCasamento('0');
                setValidateMinutaVendedorRegimeCasamento(false);
            }

            if (venda.vendedores.data[0].uniao_estavel !== null) {
                setMinutaVendedorUniaoEstavel(venda.vendedores.data[0].uniao_estavel);
                setCheckedMinutaVendedorUniaoEstavel(true);
                if (venda.vendedores.data[0].conjuge !== null) {
                    setValidateMinutaVendedorConjuge(true);
                }
            }
            else {
                setMinutaVendedorUniaoEstavel('0');
                setCheckedMinutaVendedorUniaoEstavel(false);
                if (venda.vendedores.data[0].conjuge === null) {
                    setValidateMinutaVendedorConjuge(true);
                }
            }

            venda.vendedores.data[0].cpf_cnpj !== null ? setValidateMinutaVendedorCpf(true) : setValidateMinutaVendedorCpf(false);
            venda.vendedores.data[0].telefone !== null ? setValidateMinutaVendedorTelefone(true) : setValidateMinutaVendedorTelefone(false);
            venda.vendedores.data[0].data_nascimento !== null ? setValidateMinutaVendedorDataNascimento(true) : setValidateMinutaVendedorDataNascimento(false);
            venda.vendedores.data[0].name !== null ? setValidateMinutaVendedorNome(true) : setValidateMinutaVendedorNome(false);
            venda.vendedores.data[0].nome_mae !== null ? setValidateMinutaVendedorNomeMae(true) : setValidateMinutaVendedorNomeMae(false);
            venda.vendedores.data[0].nacionalidade !== null ? setValidateMinutaVendedorNacionalidade(true) : setValidateMinutaVendedorNacionalidade(true);
            venda.vendedores.data[0].cep !== null ? setValidateMinutaVendedorCep(true) : setValidateMinutaVendedorCep(false);
            venda.vendedores.data[0].numero !== null ? setValidateMinutaVendedorNumero(true) : setValidateMinutaVendedorNumero(false)
            venda.vendedores.data[0].rg !== null ? setValidateMinutaVendedorRg(true) : setValidateMinutaVendedorRg(false)
            venda.vendedores.data[0].rg_expedido !== null ? setValidateMinutaVendedorRgExpedicao(true) : setValidateMinutaVendedorRgExpedicao(false)
            venda.vendedores.data[0].data_rg_expedido !== null ? setValidateMinutaVendedorDataExpedicao(true) : setValidateMinutaVendedorDataExpedicao(false)
            venda.vendedores.data[0].estado_civil !== null ? setValidateMinutaVendedorEstadoCivil(true) : setValidateMinutaVendedorEstadoCivil(false)
            venda.vendedores.data[0].registro_casamento !== null ? setValidateMinutaVendedorRegimeCasamento(true) : setValidateMinutaVendedorRegimeCasamento(false);
            //venda.vendedores.data[0].conjuge !== null ? setValidateMinutaVendedorConjuge(true) : setValidateMinutaVendedorConjuge(false);
        }
    };

    const verificarFormComprador = (venda) => {
        if (venda.compradores.data.length !== 0) {
            setDadosMinutaComprador(venda.compradores.data[0])

            if (venda.compradores.data[0].estado_civil !== null) {
                setMinutaCompradorEstadoCivil(venda.compradores.data[0].estado_civil);
            }
            else {
                setMinutaCompradorEstadoCivil('0');
            }

            if (venda.compradores.data[0].registro_casamento !== null) {
                setMinutaCompradorRegimeCasamento(venda.compradores.data[0].registro_casamento);
                setValidateMinutaCompradorRegimeCasamento(true);

                if (venda.compradores.data[0].conjuge !== null) {
                    setValidateMinutaCompradorConjuge(true);
                }
                else {
                    setValidateMinutaCompradorConjuge(false);
                }
            }
            else {
                setMinutaCompradorRegimeCasamento('0');
                setValidateMinutaCompradorRegimeCasamento(false);
            }

            if (venda.compradores.data[0].uniao_estavel !== null) {
                setMinutaCompradorUniaoEstavel(venda.compradores.data[0].uniao_estavel);
                setCheckedMinutaCompradorUniaoEstavel(true);
                //setValidateMinutaCompradorConjuge(false)
                if (venda.compradores.data[0].conjuge !== null) {
                    setValidateMinutaCompradorConjuge(true);
                }
            }
            else {
                setMinutaCompradorUniaoEstavel('0');
                setCheckedMinutaCompradorUniaoEstavel(false);
                if (venda.compradores.data[0].conjuge === null) {
                    setValidateMinutaCompradorConjuge(true);
                }
            }

            venda.compradores.data[0].cpf_cnpj !== null ? setValidateMinutaCompradorCpf(true) : setValidateMinutaCompradorCpf(false);
            venda.compradores.data[0].telefone !== null ? setValidateMinutaCompradorTelefone(true) : setValidateMinutaCompradorTelefone(false);
            venda.compradores.data[0].data_nascimento !== null ? setValidateMinutaCompradorDataNascimento(true) : setValidateMinutaCompradorDataNascimento(false);
            venda.compradores.data[0].name !== null ? setValidateMinutaCompradorNome(true) : setValidateMinutaCompradorNome(false);
            venda.compradores.data[0].nome_mae !== null ? setValidateMinutaCompradorNomeMae(true) : setValidateMinutaCompradorNomeMae(false);
            venda.compradores.data[0].nacionalidade !== null ? setValidateMinutaCompradorNacionalidade(true) : setValidateMinutaCompradorNacionalidade(true);
            venda.compradores.data[0].cep !== null ? setValidateMinutaCompradorCep(true) : setValidateMinutaCompradorCep(false);
            venda.compradores.data[0].numero !== null ? setValidateMinutaCompradorNumero(true) : setValidateMinutaCompradorNumero(false)
            venda.compradores.data[0].rg !== null ? setValidateMinutaCompradorRg(true) : setValidateMinutaCompradorRg(false)
            venda.compradores.data[0].rg_expedido !== null ? setValidateMinutaCompradorRgExpedicao(true) : setValidateMinutaCompradorRgExpedicao(false)
            venda.compradores.data[0].data_rg_expedido !== null ? setValidateMinutaCompradorDataExpedicao(true) : setValidateMinutaCompradorDataExpedicao(false)
            venda.compradores.data[0].estado_civil !== null ? setValidateMinutaCompradorEstadoCivil(true) : setValidateMinutaCompradorEstadoCivil(false)
            venda.compradores.data[0].registro_casamento !== null ? setValidateMinutaCompradorRegimeCasamento(true) : setValidateMinutaCompradorRegimeCasamento(false);
            //venda.compradores.data[0].conjuge !== null ? setValidateMinutaCompradorConjuge(true) : setValidateMinutaCompradorConjuge(false);
        }
    };

    const clearStatesVenda = (e) => {
        //Venda 
        setCheckedMinutaAVista(false);
        setCheckedMinutaFgts(false);
        setCheckedMinutaFinanciamento(false);
        setCheckedMinutaParcelamento(false);
        setCheckedMinutaConsorcio(false);
        setMinutaVendaLaudemio([]);
        setValidateMinutaVendaFormaPagamento(false);
        setValidateMinutaVendaCep(false);
        setValidateMinutaVendaNumero(false)
        setValidateMinutaVendaEscritura(false);
        setValidateMinutaVendaMatricula(false);
        setValidateMinutaVendaInscricaoMunicipal(false);
        setValidateMinutaVendaValorVenda(false);
        setValidateMinutaVendaValorSinal(false);
        setValidateMinutaVendaPrazoEscritura(false);

        //Vendedor
        setCheckedMinutaVendedorUniaoEstavel('');
        setMinutaVendedorEstadoCivil('0');
        setMinutaVendedorRegimeCasamento('0');
        setMinutaVendedorUniaoEstavel('')
        setValidateMinutaVendedorConjuge(false);
        setValidateMinutaVendedorCpf(false);
        setValidateMinutaVendedorTelefone(false);
        setValidateMinutaVendedorDataNascimento(false);
        setValidateMinutaVendedorNome(false);
        setValidateMinutaVendedorNomeMae(false);
        setValidateMinutaVendedorNacionalidade(false);
        setValidateMinutaVendedorCep(false);
        setValidateMinutaVendedorNumero(false);
        setValidateMinutaVendedorRg(false);
        setValidateMinutaVendedorRgExpedicao(false);
        setValidateMinutaVendedorDataExpedicao(false);
        setValidateMinutaVendedorEstadoCivil(false);
        setValidateMinutaVendedorRegimeCasamento(false);

        //Comprador
        setCheckedMinutaCompradorUniaoEstavel('');
        setMinutaCompradorEstadoCivil('0');
        setMinutaCompradorRegimeCasamento('0');
        setMinutaCompradorUniaoEstavel('')
        setValidateMinutaCompradorConjuge(false);
        setValidateMinutaCompradorCpf(false);
        setValidateMinutaCompradorTelefone(false);
        setValidateMinutaCompradorDataNascimento(false);
        setValidateMinutaCompradorNome(false);
        setValidateMinutaCompradorNomeMae(false);
        setValidateMinutaCompradorNacionalidade(false);
        setValidateMinutaCompradorCep(false);
        setValidateMinutaCompradorNumero(false);
        setValidateMinutaCompradorRg(false);
        setValidateMinutaCompradorRgExpedicao(false);
        setValidateMinutaCompradorDataExpedicao(false);
        setValidateMinutaCompradorEstadoCivil(false);
        setValidateMinutaCompradorRegimeCasamento(false);

        //Boletim de Vendas
        setValidateFieldValorVenda(false);
        setValidateBoletimVendaDocumentos(false);
        setValidateBoletimVendaDocumentosVendedor(false);
        setValidateProcuradorVendedor(true);
        setValidateTelefoneProcuradorVendedor(true);
        setCheckedProcuradorVendedor(false);
        setValidateBoletimVendaDocumentosComprador(false)
        setValidateProcuradorComprador(true)
        setValidateTelefoneProcuradorComprador(true);
        setCheckedProcuradorComprador(false);
        setValidateRadiusTotal(false);
        setValidateRadiusLiquida(false);
        setValidateComissaoTotal(false);
        setValidateComissaoLiquida(false);
        setValidateBoletimVendaDocumentosComissao(false);
    };

    const formVenda = async (e, venda) => {
        clearStatesVenda();
        verificaFormVenda(venda);
        verificarFormVendedor(venda);
        verificarFormComprador(venda);
        setDadosVenda(venda);
        setDadosMinutaVendedor(venda.vendedores.data.length !== 0 ? venda.vendedores.data[0] : []);
        setDadosMinutaComprador(venda.compradores.data.length !== 0 ? venda.compradores.data[0] : []);

        //Identifica qual formulario vai abrir
        if (venda.status_processo === 24) {
            setPosicaoMinuta(3);
            setCountMenu(4);
        }
        else {
            if (venda.status_processo === 10 && venda.status_anterior_processo_id === 15 ||
                (venda.status_processo === 15 && venda.status_anterior_processo_id === 10)) {

                setPosicaoMinuta(3);
                setCountMenu(4);
            }
            else {
                if (venda.status_processo > 17) {
                    setPosicaoMinuta(4);
                    setCountMenu(4);
                }
                else {
                    switch (venda.status_processo) {
                        case 9:
                            setPosicaoMinuta(2);
                            setCountMenu(2);
                            break;
                        case 10:
                            setPosicaoMinuta(3);
                            setCountMenu(3);
                            break;
                        case 11:
                            setPosicaoMinuta(4);
                            setCountMenu(4);
                            break;
                        case 12:
                            setPosicaoMinuta(1);
                            setCountMenu(1);
                            break;
                        case 13:
                            setPosicaoMinuta(2);
                            setCountMenu(2);
                            break;
                        case 14:
                            setPosicaoMinuta(3);
                            setCountMenu(3);
                            break;
                        case 15:
                        case 16:
                        case 17:
                            setPosicaoMinuta(4);
                            setCountMenu(4);
                            break;
                        default:
                            break;
                    }
                }
            }
        }

        setIsShowFormMinuta(true);
    };

    const dialogTipoVenda = (e) => {
        setIsShowFormMinuta(true);

        // Limpa todos os dados e estados para um novo Gerar Venda
        setCheckedMinutaAVista(false);
        setCheckedMinutaFgts(false);
        setCheckedMinutaFinanciamento(false);
        setCheckedMinutaParcelamento(false);
        setCheckedMinutaConsorcio(false);
        setMinutaVendaLaudemio([]);
        setValidateMinutaVendaFormaPagamento(false);
        setValidateMinutaVendaCep(false);
        setValidateMinutaVendaNumero(false)
        setValidateMinutaVendaEscritura(false);
        setValidateMinutaVendaMatricula(false);
        setValidateMinutaVendaInscricaoMunicipal(false);
        setValidateMinutaVendaValorVenda(false);
        setValidateMinutaVendaValorSinal(false);
        setValidateMinutaVendaPrazoEscritura(false);
        setDadosVenda([]);
    };

    const formUploadRecibo = (e, venda) => {
        venda.informacao.data_assinatura !== '' ? setValidateUploadReciboDataAssinatura(true) : setValidateUploadReciboDataAssinatura(false);
        venda.informacao.recibo !== null ? setValidateUploadReciboDocAssinado(true) : setValidateUploadReciboDocAssinado(false);

        setDadosVenda(venda);
        setIsShowFormUploadRecibo(true);
    };

    const formBoletimDeVendas = (e, venda) => {

        clearStatesVenda();

        if (venda.comissao && venda.comissao.liquida !== null) {
            let liquida = venda.comissao.liquida;

            switch (liquida) {
                case 'especie':
                    setLiquidaRadius('especie');
                    break;

                case 'deposito':
                    setLiquidaRadius('deposito');
                    break;

                case 'ted_doc':
                    setLiquidaRadius('ted_doc');
                    break;

                case 'pix':
                    setLiquidaRadius('pix');
                    break;

                case 'cheque':
                    setLiquidaRadius('cheque');
                    break;

                default:
                    break;
            }
        }

        setDadosVenda(venda);
        setIdProcessosModal(venda.informacao.id);
        setVendedorProcesso(venda.vendedores);
        setCompradorProcesso(venda.compradores);

        switch (venda.status_processo) {
            case 16:
                setPosicaoBoletimDeVenda(1);
                setCountMenu(1);
                break;
            case 17:
                setPosicaoBoletimDeVenda(2);
                setCountMenu(2);
                break;
            case 18:
                setPosicaoBoletimDeVenda(3);
                setCountMenu(3);
                break;
            case 19:
                setPosicaoBoletimDeVenda(3);
                setCountMenu(3);
                break;
            case 20:
                setPosicaoBoletimDeVenda(4);
                setCountMenu(5);
                break;
            case 22:
                if (venda.revisao.recibo_sinal === 1 && venda.revisao.recibo_sinal_save === 0) {
                    setPosicaoBoletimDeVenda(0);
                    setCountMenu(0);
                }
                else if (venda.revisao.valores_documentos_imovel === 1 && venda.revisao.valores_documentos_imovel_verific_save === 0) {
                    setPosicaoBoletimDeVenda(1);
                    setCountMenu(1);
                }
                else if (venda.revisao.vendedores === 1 && venda.revisao.vendedores_verific_save === 0) {
                    setPosicaoBoletimDeVenda(2);
                    setCountMenu(2);
                }
                else if (venda.revisao.compradores === 1 && venda.revisao.compradores_verific_save === 0) {
                    setPosicaoBoletimDeVenda(3);
                    setCountMenu(3);
                }
                else if (venda.revisao.comissao === 1 && venda.revisao.comissao_verific_save === 0) {
                    setPosicaoBoletimDeVenda(4);
                    setCountMenu(4);
                }
                else {
                    //setPosicaoBoletimDeVenda(4);
                    //setCountMenu(5);

                    if (venda.revisao.valores_documentos_imovel === 1 && venda.revisao.valores_documentos_imovel_verific_save === 1) {
                        setPosicaoBoletimDeVenda(1);
                        setCountMenu(5);
                    }
                    else if (venda.revisao.vendedores === 1 && venda.revisao.vendedores_verific_save === 1) {
                        setPosicaoBoletimDeVenda(2);
                        setCountMenu(5);
                    }
                    else if (venda.revisao.compradores === 1 && venda.revisao.compradores_verific_save === 1) {
                        setPosicaoBoletimDeVenda(3);
                        setCountMenu(5);
                    }
                    else if (venda.revisao.comissao === 1 && venda.revisao.comissao_verific_save === 1) {
                        setPosicaoBoletimDeVenda(4);
                        setCountMenu(5);
                    }
                }
                break;
            default:
                break;
        }
        setIsShowFormBoletimDeVenda(true);
    };

    const handleFilter = async (page, temFiltro) => {
        setLoading(false);
        localStorage.setItem('page_number', page);
        setPageNumberAdicionados(page);

        await Axios.post(app + 'retorno_processos_painel_gerentes', {
            usuario_id: usuario_id,
            page: page,
            logradouro: filtro.endereco_rascunho ? filtro.endereco_rascunho.endereco : "",
            tipo_processos: 'adicionados',
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {
            if (!res.data || (res.data.status === 498 || res.data.status === 401)) {
                localStorage.clear();
                history.push('/');
            } else {
                const itensAdicionados = res.data.processos.data;
                let totalPaginas = res.data.total_pagina;
                let totalItensFiltrados = res.data.total_processos_filtrados;
                setItemAdicionado(itensAdicionados);
                setTotalPaginasAdicionados(totalPaginas);
                setTotalProcessosFiltradosAdicionados(totalItensFiltrados);
                setLoading(true);
            }
        })
    };

    // Paginação
    function listItemsPerPage(pageNumberAdicionados) {
        handleFilter(pageNumberAdicionados);
    };

    const DialogEntrega = (e, venda) => {
        setDadosVenda(venda);
        setIsShowCornerDialog(false);
        setModelCornerDialog('');
        setIsShowEntregarVenda(true);
    };

    const confirmarEntregaVenda = (e, venda) => {
        e.preventDefault();
        let imovel_id = venda.id;
        let processo_id = venda.processo_id;

        setLoading(false);

        Axios.post(app + 'vincular_responsavel_processo', {
            'imovel_id': imovel_id,
            'processo_id': processo_id,
            'usuario_id': usuario_id
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    recarregarVendas();

                    setIsShowEntregarVenda(false);
                    setIsShowCornerDialog(true);
                    setModelCornerDialog(4);

                    // Abre o contado para o Corner de Confirmação de Envio para o Backoffice
                    var timeleft = 5;
                    var downloadTimer = setInterval(function () {
                        if (timeleft <= 0) {
                            clearInterval(downloadTimer);
                            setRegressivaEntregarVenda(0)
                        } else {
                            setRegressivaEntregarVenda(timeleft)
                        }
                        timeleft -= 1;
                    }, 1000);

                }
            })
            .catch(function (error) {
                // handle error
                if (error.response) {
                    let resposta = error.response.data;

                    let erros = '';
                    if (resposta.errors) {
                        for (let [key, value] of Object.entries(resposta.errors)) {
                            erros += value + "\n";
                        }
                    }

                    if (resposta.error) {
                        erros = resposta.error;
                    }

                    alert(erros);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }

            })
        //setTimeout(function () { setLoading(true); }, 1000);*/        
    };

    function verificarRevisao(venda) {
        let status = ''
        if (venda.revisao) {
            if (
                venda.revisao.recibo_sinal === venda.revisao.recibo_sinal_save &&
                venda.revisao.valores_documentos_imovel === venda.revisao.valores_documentos_imovel_verific_save &&
                venda.revisao.vendedores === venda.revisao.vendedores_verific_save &&
                venda.revisao.compradores === venda.revisao.compradores_verific_save &&
                venda.revisao.comissao === venda.revisao.comissao_verific_save
            ) {
                status = 'revisado';
                return status;
            }
            else {
                status = 'revisar';
                return status;
            }
        } else {
            return '';
        }
    };

    const recarregarVendas = async (type) => {
        setLoading(false);

        const adicionados = await getProcessosGerente(filtro, 1, 'adicionados');
        if (adicionados.processos) {
            setItemAdicionado(adicionados.processos.data);
            setTotalItensAdicionados(adicionados.total_processos);
            setTotalPaginasAdicionados(adicionados.total_pagina);
        } else {
            toaster.danger("Erro ao recarregar os processos, relogue e tente novamente.")
        }

        if (!type) {
            const enviados = await getProcessosGerente(filtro, 1, 'entregues');
            if (enviados.processos) {
                setItemEnviado(enviados.processos.data);
                setTotalItensEntregues(enviados.total_processos);
                setTotalPaginasEntregues(enviados.total_pagina);
                setTotalProcessosFiltradosEnviados(enviados.total_processos_filtrados);
            } else {
                toaster.danger("Erro ao recarregar os processos, relogue e tente novamente.")
            }
        }

        if (type) {
            const arquivados = await getProcessosGerente(filtro, 1, 'arquivados');
            if (arquivados.processos) {
                setItemsArquivados(arquivados.processos.data);
            } else {
                toaster.danger("Erro ao recarregar os processos, relogue e tente novamente.")
            }
        }

        setLoading(true);
    };

    async function handleRemoveAndRestore(processo_id, type) {
        if (type === "Remove") {
            setIsShowRemover(true);
            setProcessoIdRemove(processo_id);
        }
        else {
            setIsShowRestore(true);
            setProcessoIdRemove(processo_id);
        };
    };

    const handleVerificaGerarVenda = (e, index) => {
        setSelectTabs(index)
        setExibirGerarVenda(index);
    };

    // Filtro de Combo
    const handleCombobox = (e, campo) => {
        if (e) {
            localStorage.setItem('filtro_' + campo, JSON.stringify(e));
            filtro[campo] = e;
            handleFilter(1, 'filtro');
        }
    };

    const handleClearFilter = async (e) => {
        setLoading(false);
        setPageNumberAdicionados(1);
        filtro.endereco_rascunho = '';
        localStorage.removeItem('filtro_endereco_rascunho');
        handleFilter(1, 'filtro');
    };

    // Filtros
    useEffect(() => {
        quantidadeFiltros();
    })

    const quantidadeFiltros = async () => {
        let filtrosSelecionados = 0;

        if (filtro.endereco_rascunho !== '') {
            filtrosSelecionados++;
        }
        quantFiltro[0] = filtrosSelecionados;
    };

    return (
        <Pane>
            <Tablist marginTop={27.5} marginBottom={27.5}>
                {tabs.map((tab, index) => (
                    <Tab
                        key={tab}
                        id={tab}
                        onSelect={(e) => handleVerificaGerarVenda(e, index)}
                        isSelected={index === selectTabs}
                        aria-controls={`panel-${tab}`}
                        intent="danger"
                        width={120}
                        height={32}
                        className={tab === "Lixeira" && "dangerTab"}
                    >
                        {tab === 'Todos'
                            ? (tab + ` (${totalItensAdicionados})`)
                            : (tab + ` (${itemsArquivados.length})`)
                        }
                    </Tab>
                ))}
            </Tablist>

            <FormVenda />

            {
                selectTabs === 0 &&

                <Pane className='mt30'>
                    <div className='flex space-between pb10'>
                        <div className="flex gap20 end">
                            <span>Filtrar por: </span>
                            <div>
                                <Combobox
                                    openOnFocus
                                    name='endereco_rascunho'
                                    items={listEnderecos.rascunhos}
                                    selectedItem={filtro.endereco_rascunho}
                                    itemToString={item => (item ? item.endereco : '')}
                                    placeholder="🔍︎ Endereço"
                                    onChange={(e) => handleCombobox(e, 'endereco_rascunho')}
                                    width={250}
                                    id="filtro-endereco"
                                    className="filtro-select color-select-filter"
                                />
                            </div>
                        </div>
                        <div>
                            <Pagination
                                page={pageNumberAdicionados}
                                totalPages={Number(totalPaginasAdicionados)}
                                onPageChange={(e) => loading ? listItemsPerPage(e) : ''}
                                onNextPage={() => listItemsPerPage(pageNumberAdicionados + 1)}
                                onPreviousPage={() => listItemsPerPage(pageNumberAdicionados - 1)}
                            />
                        </div>
                    </div>

                    {quantFiltro[0] >= 1 ?
                        <div className="filter-info">
                            <p>
                                <FilterIcon className="icon-filter" />
                                {quantFiltro[0]} {quantFiltro[0] === 1 ? 'filtro aplicado. ' : 'filtros aplicados. '}
                                {itemAdicionado.length} / {totalProcessosFiltradosAdicionados} {totalProcessosFiltradosAdicionados === 1 ? 'processo visível' : 'processos visíveis'}.
                                <span onClick={(e) => handleClearFilter(e)}> Limpar filtro</span>
                            </p>
                        </div>

                        :
                        ''}

                </Pane>
            }
            <div className={selectTabs === 0 ? "Rectangle35 Blue" : "Rectangle35 Red300"}></div>
            <Table.Body className="table" style={{ overflow: 'hidden' }}>
                <Table.Head>
                    {/* <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0} className="header-title">
                    </Table.TextCell> */}
                    <Table.TextCell flexBasis={280} flexShrink={0} flexGrow={0} className="header-title">
                        Endereço
                    </Table.TextCell>
                    <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="header-title">
                        Etapas
                    </Table.TextCell>
                    <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="header-title">
                        {/*Forma de pagamento*/}
                    </Table.TextCell>
                    <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} className="header-title">
                        {/*Arquivo do venda*/}
                    </Table.TextCell>
                    <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className="header-title">
                        {/*Arquivo do venda*/}
                    </Table.TextCell>
                </Table.Head>

                {
                    //Enquanto não carregar os dados exibe um Spinner
                    loading === false &&
                    <div className='backgroundLoadingDocs'>
                        <Lottie
                            options={defaultOptions}
                            height={200}
                            width={400}
                        />
                    </div>
                }

                {
                    loading === true &&
                        selectTabs === 0
                        ?
                        <Table.Body style={{ backgroundColor: 'white' }}>
                            {
                                itemAdicionado.length > 0
                                    ?
                                    <div>
                                        {itemAdicionado.map(venda =>
                                            <Table.Row key={venda.id}>

                                                <Table.TextCell flexBasis={280} flexShrink={0} flexGrow={0} className="content-endereco content-click" onClick={(e) => venda.status_processo !== 22 && formVenda(e, venda)}>
                                                    {venda.logradouro + ', ' + venda.numero + (venda.unidade !== null ? " / " + venda.unidade : "")}
                                                </Table.TextCell>

                                                <Table.TextCell
                                                    flexBasis={
                                                        //venda.status_processo === 22 ? 200 : 600 verificarRevisao
                                                        venda.status_processo === 22
                                                            ? 180
                                                            : 600
                                                    }
                                                    flexShrink={0}
                                                    flexGrow={0}
                                                    className="content-endereco"
                                                >
                                                    <div className="steps steps-lista">
                                                        {venda.status_processo === 22
                                                            ?
                                                            <div className="step-item">
                                                                <span className="default">
                                                                    {verificarRevisao(venda) === 'revisado' ? "Revisão da devolução" : "Ajustes na devolução "}
                                                                    {
                                                                        verificarRevisao(venda) === 'revisado'
                                                                            ?
                                                                            <TickCircleIcon size={16} className='icon-tick-revisao' />
                                                                            :
                                                                            <ErrorIcon size={16} className="icon-error-revisao" />
                                                                    }
                                                                </span>
                                                            </div>

                                                            :
                                                            <>
                                                                <div className="step-item">
                                                                    <Badge
                                                                        className="badge-default"
                                                                        style={
                                                                            //venda.status_processo >= 15 ? { padding: 0 } : { padding: '' }
                                                                            venda.status_processo >= 15 && venda.status_processo !== 24
                                                                                || (venda.status_processo === 11 && venda.status_anterior_processo_id === 24)
                                                                                || (venda.status_processo === 11 && venda.status_anterior_processo_id === 23)
                                                                                || (venda.status_processo === 15 && venda.status_anterior_processo_id === 23)
                                                                                ? { padding: 0 } : { padding: '' }
                                                                        }
                                                                    >
                                                                        {/*venda.status_processo >= 15 ? <SmallTickIcon className="step-check" /> : 1*/}
                                                                        {
                                                                            venda.status_processo >= 15 && venda.status_processo !== 24
                                                                                || (venda.status_processo === 11 && venda.status_anterior_processo_id === 24)
                                                                                || (venda.status_processo === 11 && venda.status_anterior_processo_id === 23)
                                                                                || (venda.status_processo === 15 && venda.status_anterior_processo_id === 23)
                                                                                ? <SmallTickIcon className="step-check" /> : 1
                                                                        }
                                                                    </Badge>
                                                                    <span className="default">Dados e rascunho</span>
                                                                </div>

                                                                <div className="step-item">
                                                                    <Badge
                                                                        className="badge-default"
                                                                        style={
                                                                            venda.status_processo >= 16 && venda.status_processo !== 24 ? { padding: 0 } : { padding: '' }
                                                                        }
                                                                    >
                                                                        {venda.status_processo >= 16 && venda.status_processo !== 24 ? <SmallTickIcon className="step-check" /> : 2}
                                                                    </Badge>
                                                                    <span className="default">Upload do Recibo de Sinal</span>
                                                                </div>

                                                                <div className="step-item">
                                                                    <Badge
                                                                        className="badge-default"
                                                                        style={
                                                                            venda.status_processo === 20 ? { padding: 0 } : { padding: '' }
                                                                        }
                                                                    >
                                                                        {venda.status_processo === 20 ? <SmallTickIcon className="step-check" /> : 3}
                                                                    </Badge>
                                                                    <span className="default">Boletim de venda</span>
                                                                </div>

                                                                <div className="step-item">
                                                                    <div className="progress-line">
                                                                        <div className={
                                                                            //venda.status_processo < 15 ? "total-progress progress-step-10" : "total-progress progress-step-" + venda.status_processo
                                                                            venda.status_processo >= 15 && venda.status_processo !== 24
                                                                                || (venda.status_processo === 11 && venda.status_anterior_processo_id === 24)
                                                                                || (venda.status_processo === 11 && venda.status_anterior_processo_id === 23)
                                                                                || (venda.status_processo === 15 && venda.status_anterior_processo_id === 23)
                                                                                ? "total-progress progress-step-" + venda.status_processo : "total-progress progress-step-10"
                                                                        }
                                                                        ></div>
                                                                    </div>
                                                                </div>

                                                                <div className="step-item">
                                                                    <span className="etapa">
                                                                        {
                                                                            venda.status_processo === 15 && venda.status_processo !== 24
                                                                                || (venda.status_processo === 11 && venda.status_anterior_processo_id === 24)
                                                                                || (venda.status_processo === 11 && venda.status_anterior_processo_id === 23)
                                                                                || (venda.status_processo === 15 && venda.status_anterior_processo_id === 23)
                                                                                ? 1
                                                                                : venda.status_processo >= 16 && venda.status_processo < 20
                                                                                    ? 2
                                                                                    : venda.status_processo === 20
                                                                                        ? <TickCircleIcon className="step-check" />
                                                                                        : 0
                                                                        }
                                                                    </span>{venda.status_processo === 20 ? '' : '/3'}
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </Table.TextCell>

                                                <Table.TextCell
                                                    flexBasis={
                                                        venda.status_processo === 22 ? 130 : 148
                                                    }
                                                    flexShrink={0}
                                                    flexGrow={0}>

                                                    {
                                                        venda.status_processo !== 22
                                                            ?
                                                            <Popover
                                                                className="nav-rascunho"
                                                                content={
                                                                    <Menu>
                                                                        <Menu.Group
                                                                            title="Continuar Edição"
                                                                        >
                                                                            <div className="nav-rascunho-venda">
                                                                                <Menu.Item
                                                                                    icon={
                                                                                        //venda.status_processo >= 14
                                                                                        venda.status_processo >= 15 && venda.status_processo !== 24
                                                                                            || (venda.status_processo === 11 && venda.status_anterior_processo_id === 24)
                                                                                            || (venda.status_processo === 11 && venda.status_anterior_processo_id === 23)
                                                                                            || (venda.status_processo === 15 && venda.status_anterior_processo_id === 23)
                                                                                            ? TickCircleIcon : WarningSignIcon
                                                                                    }
                                                                                    className={
                                                                                        venda.status_processo >= 15 && venda.status_processo !== 24
                                                                                            || (venda.status_processo === 11 && venda.status_anterior_processo_id === 24)
                                                                                            || (venda.status_processo === 11 && venda.status_anterior_processo_id === 23)
                                                                                            || (venda.status_processo === 15 && venda.status_anterior_processo_id === 23)
                                                                                            ? "tick-cicle-icon-menu nav-item"
                                                                                            : "warning-icon-menu nav-item"
                                                                                    }
                                                                                    onClick={(e) => formVenda(e, venda)}
                                                                                >
                                                                                    Dados e rascunho
                                                                                </Menu.Item>

                                                                                <Menu.Item
                                                                                    icon={
                                                                                        venda.status_processo === 15 && venda.status_processo !== 24
                                                                                            || (venda.status_processo === 11 && venda.status_anterior_processo_id === 24) ? WarningSignIcon :
                                                                                            venda.status_processo >= 16 && venda.status_processo !== 24
                                                                                                ? TickCircleIcon
                                                                                                : LockIcon
                                                                                    }
                                                                                    className={
                                                                                        venda.status_processo === 15 && venda.status_processo !== 24
                                                                                            || (venda.status_processo === 11 && venda.status_anterior_processo_id === 24)
                                                                                            ? "warning-icon-menu nav-item"
                                                                                            : venda.status_processo >= 16 && venda.status_processo !== 24
                                                                                                ? "tick-cicle-icon-menu nav-item"
                                                                                                : "lock-icon-menu nav-item"
                                                                                    }
                                                                                    onClick={
                                                                                        (e) => venda.status_processo >= 15 && venda.status_processo !== 24
                                                                                            || (venda.status_processo === 11 && venda.status_anterior_processo_id === 24) ? formUploadRecibo(e, venda) : (e)
                                                                                    }
                                                                                >
                                                                                    Recibo de Sinal
                                                                                </Menu.Item>

                                                                                <Menu.Item
                                                                                    icon={
                                                                                        venda.status_processo >= 16 && venda.status_processo <= 19 ? WarningSignIcon :
                                                                                            venda.status_processo === 20 ? TickCircleIcon
                                                                                                : LockIcon
                                                                                    }
                                                                                    className={
                                                                                        venda.status_processo >= 16 && venda.status_processo <= 19 ? "warning-icon-menu nav-item" :
                                                                                            venda.status_processo === 20 ? "tick-cicle-icon-menu nav-item"
                                                                                                : "lock-icon-menu nav-item"
                                                                                    }
                                                                                    onClick={(e) => venda.status_processo >= 16 && venda.status_processo !== 24 ? formBoletimDeVendas(e, venda) : (e)}
                                                                                >
                                                                                    Boletim de Vendas
                                                                                </Menu.Item>
                                                                            </div>
                                                                        </Menu.Group>
                                                                        <Menu.Divider />
                                                                    </Menu>
                                                                }
                                                            >
                                                                <Button
                                                                    marginY={8}
                                                                    marginRight={12}
                                                                    appearance="primary"
                                                                >
                                                                    {venda.status_processo >= 20 && venda.status_processo !== 24
                                                                        ?
                                                                        <>
                                                                            <EditIcon
                                                                                color="white"
                                                                                style={{ width: 12, height: 11, marginRight: 10 }} /
                                                                            >
                                                                            Editar
                                                                        </>

                                                                        :
                                                                        <>
                                                                            <DotIcon
                                                                                color="white"
                                                                                style={{ width: 8, height: 11, marginRight: 0 }} /
                                                                            >
                                                                            <DotIcon
                                                                                color="white"
                                                                                style={{ width: 8, height: 11, marginRight: 0 }} /
                                                                            >
                                                                            <DotIcon
                                                                                color="white"
                                                                                style={{ width: 8, height: 11, marginRight: 5 }} /
                                                                            >
                                                                            Continuar
                                                                        </>
                                                                    }
                                                                </Button>
                                                            </Popover>

                                                            :
                                                            <Popover
                                                                className="nav-rascunho"
                                                                content={
                                                                    <Menu>
                                                                        <Menu.Group
                                                                            title={verificarRevisao(venda) === 'revisado' ? "Realizar Edições" : "Realizar Correções"}
                                                                        >
                                                                            <div className="nav-rascunho-venda">
                                                                                <Menu.Item
                                                                                    icon={
                                                                                        //venda.status_processo >= 14
                                                                                        venda.status_processo >= 15 && venda.status_processo !== 24
                                                                                            || (venda.status_processo === 11 && venda.status_anterior_processo_id === 24)
                                                                                            || (venda.status_processo === 11 && venda.status_anterior_processo_id === 23)
                                                                                            || (venda.status_processo === 15 && venda.status_anterior_processo_id === 23)
                                                                                            ? TickCircleIcon : WarningSignIcon
                                                                                    }
                                                                                    className={
                                                                                        venda.status_processo >= 15 && venda.status_processo !== 24
                                                                                            || (venda.status_processo === 11 && venda.status_anterior_processo_id === 24)
                                                                                            || (venda.status_processo === 11 && venda.status_anterior_processo_id === 23)
                                                                                            || (venda.status_processo === 15 && venda.status_anterior_processo_id === 23)
                                                                                            ? "tick-cicle-icon-menu nav-item"
                                                                                            : "warning-icon-menu nav-item"
                                                                                    }
                                                                                    onClick={(e) => formVenda(e, venda)}
                                                                                >
                                                                                    Em dados e rascunho
                                                                                </Menu.Item>

                                                                                <Menu.Item
                                                                                    icon={
                                                                                        verificarRevisao(venda) === 'revisado' ? TickCircleIcon : WarningSignIcon
                                                                                    }
                                                                                    className={
                                                                                        verificarRevisao(venda) === 'revisado' ? "tick-cicle-icon-menu nav-item" : "warning-icon-menu nav-item"
                                                                                    }
                                                                                    onClick={(e) => venda.status_processo >= 16 && venda.status_processo !== 24 ? formBoletimDeVendas(e, venda) : (e)}
                                                                                >
                                                                                    Em pedidos de revisão
                                                                                </Menu.Item>
                                                                            </div>
                                                                        </Menu.Group>
                                                                        <Menu.Divider />
                                                                    </Menu>
                                                                }
                                                            >
                                                                <Button
                                                                    marginY={8}
                                                                    marginRight={12}
                                                                    appearance="primary"
                                                                    intent="danger"
                                                                >
                                                                    {venda.status_processo >= 20 && venda.status_processo !== 24 && verificarRevisao(venda) === 'revisado'
                                                                        ?
                                                                        <>
                                                                            <EditIcon
                                                                                color="white"
                                                                                style={{ width: 12, height: 11, marginRight: 10 }} /
                                                                            >
                                                                            Editar
                                                                        </>

                                                                        :
                                                                        <>
                                                                            <EditIcon
                                                                                color="white"
                                                                                style={{ width: 12, height: 11, marginRight: 10 }}
                                                                            />
                                                                            Corrigir
                                                                        </>
                                                                    }
                                                                </Button>
                                                            </Popover>

                                                    }
                                                </Table.TextCell>

                                                {
                                                    venda.status_processo === 22
                                                        ?
                                                        <Table.TextCell
                                                            flexBasis={180} flexShrink={0} flexGrow={0}>
                                                            <Button
                                                                onClick={(e) => DialogEntrega(e, venda)}
                                                                className={
                                                                    //venda.status_processo === 20 ? "btn-enabled-entregar-venda" : 'btn-disabled-entregar-venda'
                                                                    verificarRevisao(venda) === 'revisado'
                                                                        ? "btn-enabled-entregar-venda" : 'btn-disabled-entregar-venda'
                                                                }
                                                                disabled={
                                                                    //venda.status_processo === 20 ? false : true
                                                                    verificarRevisao(venda) === 'revisado'
                                                                        ? false : true
                                                                }
                                                            >
                                                                <ShareIcon
                                                                    color="white"
                                                                    style={{ width: 12, height: 12, marginRight: 8 }} /
                                                                >
                                                                Reenviar Venda
                                                            </Button>
                                                        </Table.TextCell>

                                                        :
                                                        <Table.TextCell
                                                            flexBasis={180} flexShrink={0} flexGrow={0}>
                                                            <Button
                                                                onClick={(e) => DialogEntrega(e, venda)}
                                                                className={venda.status_processo === 20 ? "btn-enabled-entregar-venda" : 'btn-disabled-entregar-venda'}
                                                                disabled={venda.status_processo === 20 ? false : true}
                                                            >
                                                                <ShareIcon
                                                                    color="white"
                                                                    style={{ width: 12, height: 12, marginRight: 8 }} /
                                                                >
                                                                Entregar venda
                                                            </Button>
                                                        </Table.TextCell>

                                                }
                                                {venda.status_processo !== 22 &&
                                                    <Table.TextCell
                                                        flexBasis={200} flexShrink={0} flexGrow={0}>
                                                        <Tooltip content="Remover venda">
                                                            <Button
                                                                onClick={(e) => handleRemoveAndRestore(venda.processo_id, "Remove")}
                                                            >
                                                                <TrashIcon size={12} />
                                                            </Button>
                                                        </Tooltip>
                                                    </Table.TextCell>
                                                }
                                            </Table.Row>
                                        )}
                                    </div>

                                    :
                                    // Se não tiver nenhum documento exibe esse bloco
                                    loading === true &&
                                    <Table.Row key={1}>
                                        <div className="mensagem-processo">
                                            Puxa, você ainda não possui nenhuma venda cadastrada :( <a href onClick={(e) => dialogTipoVenda(e)} title="Para adicionar o primeiro, clique aqui.">Para cadastrar a primeira venda, acesse aqui.</a>
                                        </div>
                                    </Table.Row>
                            }
                        </Table.Body>
                        :
                        loading === true &&
                        <Table.Body style={{ backgroundColor: 'white' }}>
                            {
                                itemsArquivados.length > 0
                                    ?
                                    <div>
                                        {itemsArquivados.map(venda =>
                                            <Table.Row key={venda.id}>

                                                <Table.TextCell flexBasis={300} flexShrink={0} flexGrow={0} className="content-endereco" >
                                                    {venda.logradouro + ', ' + venda.numero + (venda.unidade !== null ? " / " + venda.unidade : "")}
                                                </Table.TextCell>

                                                <Table.TextCell
                                                    flexBasis={
                                                        //venda.status_processo === 22 ? 200 : 600 verificarRevisao
                                                        venda.status_anterior_processo_id === 22
                                                            ? 180
                                                            : 600
                                                    }
                                                    flexShrink={0}
                                                    flexGrow={0}
                                                    className="content-endereco"
                                                >
                                                    <div className="steps steps-lista">
                                                        {venda.status_anterior_processo_id === 22
                                                            ?
                                                            <div className="step-item">
                                                                <span className="default" style={{ color: '#3366FF' }}>
                                                                    {verificarRevisao(venda) === 'revisado' ? "Revisão da devolução" : "Ajustes na devolução "}
                                                                    {
                                                                        verificarRevisao(venda) === 'revisado'
                                                                            ?
                                                                            <TickCircleIcon size={16} className='icon-tick-revisao' />
                                                                            :
                                                                            <ErrorIcon size={16} className="icon-error-revisao" />
                                                                    }
                                                                </span>
                                                            </div>

                                                            :
                                                            <>
                                                                <div className="step-item">
                                                                    <Badge
                                                                        className="badge-default"
                                                                        style={
                                                                            //venda.status_anterior_processo_id > 15 ? { padding: 0 } : { padding: '' }
                                                                            (venda.status_anterior_processo_id === 11 && venda.status_anterior_processo_id !== 24)
                                                                                || (venda.status_anterior_processo_id >= 15 && venda.status_anterior_processo_id !== 24)
                                                                                ? { padding: 0 } : { padding: '' }
                                                                        }
                                                                    >
                                                                        {
                                                                            //venda.status_anterior_processo_id > 15 ? <SmallTickIcon className="step-check" /> : 1
                                                                            (venda.status_anterior_processo_id === 11 && venda.status_anterior_processo_id !== 24)
                                                                                || (venda.status_anterior_processo_id >= 15 && venda.status_anterior_processo_id !== 24)
                                                                                ? <SmallTickIcon className="step-check" /> : 1
                                                                        }
                                                                    </Badge>
                                                                    <span className="default">Dados e rascunho</span>
                                                                </div>

                                                                <div className="step-item">
                                                                    <Badge
                                                                        className="badge-default"
                                                                        style={
                                                                            venda.status_anterior_processo_id >= 16 && venda.status_anterior_processo_id !== 24 ? { padding: 0 } : { padding: '' }
                                                                        }
                                                                    >
                                                                        {venda.status_anterior_processo_id >= 16 && venda.status_anterior_processo_id !== 24 ? <SmallTickIcon className="step-check" /> : 2}
                                                                    </Badge>
                                                                    <span className="default">Upload do Recibo de Sinal</span>
                                                                </div>

                                                                <div className="step-item">
                                                                    <Badge
                                                                        className="badge-default"
                                                                        style={
                                                                            venda.status_anterior_processo_id === 20 ? { padding: 0 } : { padding: '' }
                                                                        }
                                                                    >
                                                                        {venda.status_anterior_processo_id === 20 ? <SmallTickIcon className="step-check" /> : 3}
                                                                    </Badge>
                                                                    <span className="default">Boletim de venda</span>
                                                                </div>

                                                                <div className="step-item">
                                                                    <div className="progress-line">
                                                                        <div className={
                                                                            venda.status_anterior_processo_id >= 15 && venda.status_anterior_processo_id !== 24
                                                                                || (venda.status_anterior_processo_id === 11 && venda.status_anterior_processo_id !== 24)
                                                                                ? "total-progress progress-step-" + venda.status_anterior_processo_id : "total-progress progress-step-10"
                                                                        }
                                                                        >
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="step-item">
                                                                    <span className="etapa">
                                                                        {
                                                                            venda.status_anterior_processo_id === 15 && venda.status_anterior_processo_id !== 24
                                                                                || (venda.status_anterior_processo_id === 11 && venda.status_anterior_processo_id !== 24)
                                                                                ? 1
                                                                                : (venda.status_anterior_processo_id >= 16 && venda.status_anterior_processo_id < 20)
                                                                                    ? 2
                                                                                    : venda.status_anterior_processo_id === 20
                                                                                        ? <TickCircleIcon className="step-check" /> : 0
                                                                        }
                                                                    </span>{venda.status_anterior_processo_id === 20 ? '' : '/3'}
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </Table.TextCell>

                                                <Table.TextCell
                                                    flexBasis={200} flexShrink={0} flexGrow={0}>
                                                    {!venda.verificar_status_imovel ?

                                                        <Tooltip content="Não é possível restaurar a venda, o imóvel já está em outra negociação." position={'left'}>
                                                            <Button
                                                                appearance="primary"
                                                                style={{
                                                                    opacity: "0.5",
                                                                    cursor: 'not-allowed',
                                                                }}
                                                            >
                                                                <ResetIcon size={12} color="white" marginRight={5} /> Restaurar
                                                            </Button>
                                                        </Tooltip>
                                                        :
                                                        <Button
                                                            onClick={() => handleRemoveAndRestore(venda.processo_id, "Restore")}
                                                            appearance="primary"
                                                        >
                                                            <ResetIcon size={12} color="white" marginRight={5} /> Restaurar
                                                        </Button>

                                                    }
                                                </Table.TextCell>

                                            </Table.Row>
                                        )}
                                    </div>

                                    :
                                    // Se não tiver nenhum documento exibe esse bloco
                                    loading === true &&
                                    <Table.Row key={1}>
                                        <div className="mensagem-processo">
                                            Você ainda não possui nenhuma venda movida para lixeira :) {/*<a href onClick={(e) => dialogTipoVenda(e)} title="Para adicionar o primeiro, clique aqui.">Para cadastrar a primeira venda, acesse aqui.</a>*/}
                                        </div>
                                    </Table.Row>
                            }
                        </Table.Body>
                }
            </Table.Body>

            {/* <DialogConfirmacaoEscritura />  */}

            <DialogRemover recarregarVendas={recarregarVendas} />
            <DialogRestore recarregarVendas={recarregarVendas} />
            {
                dadosVenda.pre_venda === null
                    ?
                    <Dialog
                        width={592}
                        isShown={isShowEntregarVenda}
                        title=""
                        onCloseComplete={() => setIsShowEntregarVenda(false)}
                        confirmLabel=""
                        containerProps={{
                            className: 'form-dialog-props'
                        }}
                        hasFooter={false}
                        preventBodyScrolling={true}
                        shouldCloseOnOverlayClick={false}
                    >
                        <Pane>
                            <div className="form-dialog mb40">
                                <h4 style={{ marginTop: '15px' }}>
                                    {dadosVenda.status_processo === 22 ? 'A venda devolvida foi revisada?' : 'Tem certeza que deseja prosseguir?'}
                                </h4>
                                <p className="fw400 mt15 mb16">
                                    {dadosVenda.status_processo === 22
                                        ? 'Entregue a venda somente se as correções solicitadas pela Equipe de pós-venda tenham sido realizadas. Caso contrário, o pós-venda poderá devolver a venda novamente. Atenção: Os prazos de vendas devolvidas só voltam ao normal, após as correções solicitadas. '
                                        : 'Caso tenha certeza que tudo foi preenchido corretamente, clique em Entregar venda. Depois do envio para a Equipe de pós-venda, as informações não poderão sofrer alterações. Revise atentamente.'}
                                </p>
                                {isInutilDay &&
                                    <div>
                                        <span className='fw600'>
                                            Atenção: A Equipe de Pós-Venda dará início às tratativas do processo somente no próximo dia útil.
                                        </span>
                                        <Checkbox
                                            label="Estou de acordo e desejo prosseguir com o envio."
                                            className='checkbox'
                                            checked={checkDiaUtil}
                                            onChange={e => setCheckDiaUtil(e.target.checked)}
                                        />
                                    </div>
                                }
                            </div>

                            <div className="mb25" >
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button appearance="default" style={{ marginRight: '10px' }} onClick={(e) => setIsShowEntregarVenda(false)}>
                                        Cancelar
                                    </Button>

                                    <Tooltip content="Clique no check se estiver de acordo." isShown={(isInutilDay && !checkDiaUtil) ? "" : false}>
                                        <Button
                                            marginY={0}
                                            marginRight={12}
                                            onClick={(e) => confirmarEntregaVenda(e, dadosVenda)}
                                            intent="success"
                                            appearance='primary'
                                            className={(isInutilDay && !checkDiaUtil) ? 'btnGreenDisable' : ""}
                                            iconBefore={<ShareIcon color="white" />}
                                        >
                                            Entregar venda
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Pane>
                    </Dialog>

                    :
                    <DialogEntregarVenda recarregarVendas={recarregarVendas} />
            }

            {/*<Dialog
                width={592}
                isShown={isShowEntregarVenda}
                title=""
                onCloseComplete={() => setIsShowEntregarVenda(false)}
                confirmLabel=""
                containerProps={{
                    className: 'form-dialog-props'
                }}
                hasFooter={false}
                preventBodyScrolling={true}
                shouldCloseOnOverlayClick={false}
            >
                <Pane>
                    <div className="form-dialog mb40">
                        <h4 style={{ marginTop: '15px' }}>
                            {dadosVenda.status_processo === 22 ? 'A venda devolvida foi revisada?' : 'Tem certeza que deseja prosseguir?'}
                        </h4>
                        <p className="fw400 mt15 mb16">
                            {dadosVenda.status_processo === 22
                                ? 'Entregue a venda somente se as correções solicitadas pela Equipe de pós-venda tenham sido realizadas. Caso contrário, o pós-venda poderá devolver a venda novamente. Atenção: Os prazos de vendas devolvidas só voltam ao normal, após as correções solicitadas. '
                                : 'Caso tenha certeza que tudo foi preenchido corretamente, clique em Entregar venda. Depois do envio para a Equipe de pós-venda, as informações não poderão sofrer alterações. Revise atentamente.'}
                        </p>
                        {isInutilDay &&
                            <div>
                                <span className='fw600'>
                                    Atenção: A Equipe de Pós-Venda dará início às tratativas do processo somente no próximo dia útil.
                                </span>
                                <Checkbox
                                    label="Estou de acordo e desejo prosseguir com o envio."
                                    className='checkbox'
                                    checked={checkDiaUtil}
                                    onChange={e => setCheckDiaUtil(e.target.checked)}
                                />
                            </div>
                        }
                    </div>

                    <div className="mb25" >
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button appearance="default" style={{ marginRight: '10px' }} onClick={(e) => setIsShowEntregarVenda(false)}>
                                Cancelar
                            </Button>

                            <Tooltip content="Clique no check se estiver de acordo." isShown={(isInutilDay && !checkDiaUtil) ? "" : false}>
                                <Button
                                    marginY={0}
                                    marginRight={12}
                                    onClick={(e) => confirmarEntregaVenda(e, dadosVenda)}
                                    intent="success"
                                    appearance='primary'
                                    className={(isInutilDay && !checkDiaUtil) ? 'btnGreenDisable' : ""}
                                    iconBefore={<ShareIcon color="white" />}
                                >
                                    Entregar venda
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </Pane>
            </Dialog>*/}
            {/*<CornerDialog/>*/}
        </Pane>
    )
}