import React, { useContext, useEffect} from 'react';
import {
    Pane,
} from 'evergreen-ui';

//import { useHistory } from 'react-router-dom';

import './index.css';

import VendasContext from '../../../../context/VendasContext';

//Components
// import Vendedor from './components/BoletimDeVendas/Vendedor_Orig_Multi';
import Vendedor from './components/BoletimDeVendas/Vendedor';
import Informacao from './components/BoletimDeVendas/Informacao';
import Comprador from './components/BoletimDeVendas/Comprador';
import Imovel from './components/BoletimDeVendas/Imovel';
import Comissao from './components/BoletimDeVendas/Comissao/';

import RevisaoUploadRecibo from './components/BoletimDeVendas/RevisaoUploadRecibo';

export default function FormBoletimDeVenda() {

    const {
        dadosVenda,
        posicaoBoletimDeVenda,
        setMultiDocs,
        multiDocs
    } = useContext(VendasContext);

    const refreshFormValues = () => {
        //Setar valor para formValues e o MultiDocs que será usado na Aba Imovel, Vendedores e Compradores        
        if (dadosVenda) {
            const compradores = dadosVenda.compradores.data;
            const vendedores = dadosVenda.vendedores.data;
            const imovel = dadosVenda.documentos.data;
            console.log("vendedores: ", dadosVenda);

            const returnPessoa = (data_pessoa, type) => {
                const pessoa = data_pessoa.documentos.data.filter(
                    (doc) => doc.identifica_documento === type).map(
                        (doc) => ({
                            'file': doc.arquivo,
                            'id': doc.id,
                            'item': doc.tipos_multiplos_documentos.map((tipo) => ({ 'id': tipo.id, 'value': tipo.tipo_documento_id }))
                        }))
                return pessoa;
            };

            setMultiDocs({
                'imovel': imovel.filter((doc) => (doc.identifica_documento === "imovel" || doc.identifica_documento === "imóvel")).map((doc) => ({ 
                    'file': doc.arquivo, 
                    'id': doc.id,
                    'item': doc.tipos_multiplos_documentos.map((tipo) => ({ 'id': tipo.id, 'value': tipo.tipo_documento_id }))
                })),
                'vendedores': vendedores.map((vendedor) => {
                    if (vendedor.tipo_pessoa === 0) {
                        const fisica = returnPessoa(vendedor, "pessoa_fisica");
                        return { fisica };

                    } else {
                        const juridica = returnPessoa(vendedor, "pessoa_juridica");
                        const representante_socios = vendedor.representante_socios.data.map(
                            representante => returnPessoa(representante, "pessoa_fisica")
                        );
                        return { juridica, representante_socios };
                    }
                }),
                'compradores': compradores.map((comprador) => {
                    if (comprador.tipo_pessoa === 0) {
                        const fisica = returnPessoa(comprador, "pessoa_fisica");
                        return { fisica };

                    } else {
                        const juridica = returnPessoa(comprador, "pessoa_juridica");
                        const representante_socios = comprador.representante_socios.data.map(
                            representante => returnPessoa(representante, "pessoa_fisica")
                        );
                        return { juridica, representante_socios };
                    }
                }),
            });

        };
    };

    useEffect(() => {
        refreshFormValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dadosVenda]);

    return (
        <Pane className="dialogFormBoletimVenda">
            {posicaoBoletimDeVenda === 0 && dadosVenda.status_processo === 22 ? <RevisaoUploadRecibo /> : '' /*<Informacao />*/}
            {posicaoBoletimDeVenda === 1 && <Imovel />}
            {posicaoBoletimDeVenda === 2 && <Vendedor />}
            {posicaoBoletimDeVenda === 3 && <Comprador />}
            {posicaoBoletimDeVenda === 4 && <Comissao />}
        </Pane>
    )
}