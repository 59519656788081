import Axios from 'axios';
import Serve from '../app/serve';

async function getOpcionistas() {
  const app = Serve();
  let data;

  await Axios.get(app + 'lista_opcionista', {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
  }).then(res => {
    if (res !== undefined) {
      if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
        data = false;
      } else {
        data = res.data;
      }
    }
  })

  return data;
};

export default getOpcionistas;