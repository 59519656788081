import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Pane,
  ChevronRightIcon,
  Spinner,
  TextInputField,
  Button,
  UploadIcon,
  Label,
  FilePicker, toaster, ErrorIcon,
  Alert,
  Textarea,
} from 'evergreen-ui';

//Context
import VendasContext from '../../../../../context/VendasContext';

//Components
import Axios from 'axios';
import dateMask from '../../../../../components/dateMask';
import Serve from '../../../../../app/serve';
import ShowDocument from '../../../../../app/showDocument';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

export default function UploadRecibo({ handleVerificarRevisao }) {
  //Estados Laudemio e Formas de Pagamento estão sendo executados em VendasAdicionadas em formVenda;
  const modeDev = process.env.REACT_APP_DEV === 'true';

  const {
    alteracaoRevisao,
    setCountMenu,
    setIsShowFormBoletimDeVenda,
    setPosicaoBoletimDeVenda,
    loading,
    setItemAdicionado, setLoading,
    dadosVenda,
    setDadosVenda,
    setIsShowFormUploadRecibo,
    setValidateUploadReciboDataAssinatura,
    setValidateUploadReciboDocAssinado,
    docAssinado, setDocAssinado,
    setIsShowCornerDialog,
    setModelCornerDialog,
  } = useContext(VendasContext);

  const { register, handleSubmit, setValue, clearErrors, setError, watch, formState: { errors } } = useForm({
    defaultValues: {
      file: dadosVenda.informacao.recibo,
      dataAssinatura: dadosVenda.informacao.data_assinatura || '',
      observacao: dadosVenda.informacao.observacao || ''
    }
  });
  // console.log('WATCH: ' , watch());
  // console.log('Errors: ' , errors);

  modeDev && console.log("dadosVenda: ", dadosVenda);

  modeDev && console.log("Errors", errors);
  const msgErrorInvalid = 'Campo obrigatório';

  const [btnUploadDocumento, setBtnUploadDocumento] = useState(false);
  const [disabledBtnUploadDocumento, setDisabledBtnUploadDocumento] = useState(false);

  const app = Serve();
  const history = useHistory();
  const [token] = useState(localStorage.getItem('token'));
  const [gerente_id] = useState(localStorage.getItem('usuario_id'));
  //console.log(assinaturaMaior60Dias)

  const dataHoje = dayjs().format('YYYY-MM-DD');
  const [erroAssinatura, setErroAssinatura] = useState('');
  const [msgErroAssinatura, setMsgErroAssinatura] = useState('');
  const [msgAssinatura, setMsgAssinatura] = useState('');

  const inputGreen = {
    borderColor: '#429777',
    background: '#F5FBF8'
  };
  const inputDefault = {
      borderColor: '#ADC2FF',
      background: '#fff'
  };

  const inputError = {
      borderColor: '#FF0000',
      background: '#fff'
  };


  const returnVerificaData = (data) => {
    const dataAssinatura = data || dadosVenda.informacao.data_assinatura;
    // console.log('Verificando data ' + data)

    const splitData = dataAssinatura.split('/');
    const newData = splitData[2] + '-' + splitData[1] + '-' + splitData[0];
    const dataRecibo = dayjs(newData);

    const reciboDiferenca =  dayjs(dataRecibo).diff(dataHoje, 'day');
    console.log('Diferença de ' + reciboDiferenca + ' dias');

    // Maior que o dia atual
    if(reciboDiferenca > 0){
      setErroAssinatura(1);
      setMsgErroAssinatura('Não é possível inserir uma data que passe do dia atual. Revise o campo com atenção.');
      setMsgAssinatura('');
    }

    // Menor que 60 dias
    else if(reciboDiferenca < -60){
      setErroAssinatura(2);
      setMsgErroAssinatura('Há uma diferença maior de 60 dias após as assinaturas do recibo de sinal. Revise o campo com atenção.');
      setMsgAssinatura('');
    }

    // Entre 5 e 60 dias
    else if(reciboDiferenca <= -5 && reciboDiferenca >= -60){
      setMsgAssinatura('Você está entregando uma venda ' + Math.abs(reciboDiferenca) + ' dia' + (reciboDiferenca !== 1 ? 's' : '') + ' após as assinaturas do recibo de sinal. Justifique o atraso da entrega:')
      setErroAssinatura('');
      setMsgErroAssinatura('');
    }
    
    else{
      setErroAssinatura('');
      setMsgErroAssinatura('');
      setMsgAssinatura('');
    }
  }

  useEffect(() => {
    returnVerificaData();
  }, [])

  const handleDataAssinatura = (e) => {
    if (dadosVenda.status_processo === 22) handleVerificarRevisao(e);
    //let dataAssinatura = document.querySelector('.upload-recibo-data-assinatura-1');
    let dataAssinatura = dateMask(e.target.value);

    if (dataAssinatura.length === 10){
      setValue('dataAssinatura', dataAssinatura);
      clearErrors('dataAssinatura');

      const data = dateMask(e.target.value);
      returnVerificaData(data);
      // const splitData = data.split('/');
      // const newData = splitData[2] + '-' + splitData[1] + '-' + splitData[0];
      // const dataRecibo = dayjs(newData);
      // const reciboDiferenca =  dayjs(dataRecibo).diff(dataHoje, 'day');
      // console.log(reciboDiferenca + ' dias');
      
      // Maior que o dia atual
      // if(reciboDiferenca > 0){
      //   setErroAssinatura(1);
      //   setMsgErroAssinatura('A data informada passou ' + reciboDiferenca + ' dia' + (reciboDiferenca !== 1 ? 's' : '') + ' da data de hoje');
      //   setMsgAssinatura('');
      // }

      // // Menor que 60 dias
      // else if(reciboDiferenca < -60){
      //   setErroAssinatura(2);
      //   setMsgErroAssinatura('A data informada é maior que 60 dias');
      //   setMsgAssinatura('');
      // }

      // // Entre 30 e 60 dias
      // else if(reciboDiferenca <= -15 && reciboDiferenca >= -60){
      //   setMsgAssinatura('Você está entregando uma venda com mais de ' + Math.abs(reciboDiferenca) + ' dia' + (reciboDiferenca !== 1 ? 's' : '') + ' de diferença')
      //   setErroAssinatura('');
      //   setMsgErroAssinatura('');
      // }
      
      // else{
      //   setErroAssinatura('');
      //   setMsgErroAssinatura('');
      //   setMsgAssinatura('');
      // }
    }
    else{
      setError('dataAssinatura', {message: 'Campo obrigatório'});
      setErroAssinatura('');
      setMsgErroAssinatura('');
      setMsgAssinatura('');
    }
  };

  const handleObservacao = (e) => {
    console.log(e.target.value);
  }

  const handleReciboAssinado = (e) => {    
    if (dadosVenda.status_processo === 22) handleVerificarRevisao(e);
    const file = e[0];
    let tipo = file.name.split('.');
    let documentosPermitidos = ['doc', 'docx', 'pdf'];
    if (documentosPermitidos.includes(tipo[tipo.length - 1])) {
      setDocAssinado(file);
      setValue('file', file.name);
      clearErrors('file')
    }
    else {
      document.querySelector('.upload-recibo-doc-1 input[type="file"]').value = '';
      setDocAssinado([]);
      setValidateUploadReciboDocAssinado(false);
      console.log("documento não permitido");
      toaster.danger('Somente permitido arquivos nos formatos: .doc, .docx ou .pdf', {
        duration: 10
      });
    }

  };

  const handleBtnAvancar = (e) => {
    modeDev && console.log(e);
    setLoading(false);

    let dataAssinatura = e.dataAssinatura;
    let observacao = e.observacao;
    let fileUpload = docAssinado;
    let informacao_imovel_id = dadosVenda.informacao.id;
    let imovel_id = dadosVenda.id;

    const data = new FormData();

    data.append('arquivo_recibo', fileUpload);
    data.append('data_assinatura', dataAssinatura);
    data.append('observacao', observacao);
    data.append('informacao_imovel_id', informacao_imovel_id);
    data.append('imovel_id', imovel_id);
    data.append('usuario_id_logado', gerente_id);
    data.append('processo_id', dadosVenda.processo_id);
    if (dadosVenda.status_processo === 22) {
      data.append('revisao', 1);
      data.append('revisao_id', dadosVenda.revisao.id)
    }
    Axios.post(app + 'salvar_recibo', data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        if (res.data.status && res.data.status === (401 || 498)) {
          localStorage.clear();
          history.push('/');
        } else {
          recarregarVenda(dadosVenda.processo_id);
          //alert(mensagem); 
          //Se tiver resposta do servidor que os dados foram salvos
          //setTimeout(function () { setLoading(true); }, 1000);
        }
      })
      .catch(function (error) {
        // handle error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          let resposta = error.response.data;
          let erros = '';
          if (resposta.errors) {
            for (let [value] of Object.entries(resposta.errors)) {
              erros += value + "\n";
            }
          }

          if (resposta.error) {
            erros = resposta.error;
          }

          alert(erros);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      })
  };

  function recarregarVenda(idProcessoNovo) {

    //Exibe a listagem atualizada de acordo
    console.log('retorna imovél');
    Axios.post(app + 'retorno_processos_painel_gerentes', {
      "usuario_id": gerente_id,
      'tipo_processos': 'adicionados',
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    })
      .then(res => {
        if (res.data.status && res.data.status === (401 || 498)) {
          localStorage.clear();
          history.push('/');
        } else {
          const processos = res.data.processos.data;
          modeDev && console.log("Processos: ", processos);
          setItemAdicionado(processos);
          processos.forEach(element => {
            console.log(typeof (idProcessoNovo) + " -- " + typeof (element.processo_id));

            if (Number(idProcessoNovo) === element.processo_id) {
              setBtnUploadDocumento(true);
              setDisabledBtnUploadDocumento(true);
              setDadosVenda(element);

              if (dadosVenda.status_processo === 22) {
                if (dadosVenda.revisao.valores_documentos_imovel === 1) {
                  setCountMenu(1);
                  setPosicaoBoletimDeVenda(1);
                }
                else if (dadosVenda.revisao.vendedores === 1) {
                  setCountMenu(2);
                  setPosicaoBoletimDeVenda(2);
                }
                else if (dadosVenda.revisao.compradores === 1) {
                  setCountMenu(3);
                  setPosicaoBoletimDeVenda(3);
                }
                else if (dadosVenda.revisao.comissao === 1) {
                  setCountMenu(4);
                  setPosicaoBoletimDeVenda(4);
                }
                else {
                  setIsShowCornerDialog(true);
                  setModelCornerDialog(3);
                  setIsShowFormBoletimDeVenda(false);
                }
              } else {
                setTimeout(() => {
                  setIsShowFormUploadRecibo(false);
                  setIsShowCornerDialog(true);
                  setModelCornerDialog(2);
                  setBtnUploadDocumento(false);
                  setDisabledBtnUploadDocumento(false);
                  setValidateUploadReciboDataAssinatura(false);
                  setValidateUploadReciboDocAssinado(false);
                }, 1500);
              }
              setLoading(true);
            }
          });
        }
      })
  };

  return (
    <Pane>
      {loading === false
        ?
        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
          <Spinner size={80} />
        </Pane>
        :
        <div className="form-dialog">

          <div className="formGroup formAdd">
            <form id="formGroup" encType="multipart/form-data">
              <Pane>
                <input id="imovel_id-1" type="hidden" value={dadosVenda.length === 0 ? '' : dadosVenda.id} />
                <input id="processo_id-1" type="hidden" value={dadosVenda.length === 0 ? '' : dadosVenda.processo_id} />
                <div className="rowForm">
                  <TextInputField
                    width={160}
                    label="Data da assinatura"
                    description=""
                    placeholder="01/01/2000"
                    defaultValue={dadosVenda.length === 0 ? '' : dadosVenda.informacao.data_assinatura}
                    id={"upload-recibo-data-assinatura-1"}
                    className={watch('dataAssinatura') && erroAssinatura === '' ? 'inputGreenSelect' :
                      errors.dataAssinatura || !!erroAssinatura ? 'inputRedSelect' : ''
                    }
                    {...register("dataAssinatura", {
                      required: true,
                      minLength: 10,
                      validate: () => {
                        if(erroAssinatura === 1 || erroAssinatura === 2){
                          return msgErroAssinatura;
                        }
                      },
                      onChange: e => handleDataAssinatura(e)
                    })}
                    //style={dadosVenda.length === 0 ? '' : dadosVenda.informacao.data_assinatura !== '' ? inputGreen : inputDefault}
                    data-campo="data-assinatura"
                    //onChange={(e) => handleDataAssinatura(e)}
                    //required
                    maxLength={10}
                    validationMessage={errors?.dataAssinatura?.message === '' ? (errors.dataAssinatura && msgErrorInvalid) : false}
                    //validationMessage={errors.dataAssinatura && errors.dataAssinatura.message}
                  />
                </div>
                {
                  // Erro diferença mais de 60 dias
                  errors?.dataAssinatura?.message !== '' &&
                  <div style={{position: 'relative', top: '-14px'}}>
                    {msgErroAssinatura &&
                      <div className="error-genero" style={{marginBottom: '12px'}}>
                        <span>
                          <ErrorIcon />  {msgErroAssinatura}
                        </span>
                      </div>
                    }
                  </div>
                }

                {
                  // Observação quando diferença de 15 a 60 dias
                  !!msgAssinatura && 
                  <div>
                    <Label style={{marginBottom: '10px', display: 'block'}}>
                      {msgAssinatura}
                    </Label>
                    <Textarea 
                      //name="textarea-1" 
                      placeholder="Ex.: Precisei me afastar do trabalho por motivos de saúde" 
                      marginBottom={25}
                      {...register("observacao", {
                        required: true,
                        onChange: e => handleObservacao(e)
                      })}
                      style={
                        watch('observacao') !== ''
                        ? inputGreen 
                        : errors.observacao && msgErrorInvalid
                          ? inputError 
                          : inputDefault
                      }
                    />

                    {
                      errors.observacao && 
                      <div style={{position: 'relative', top: '-14px'}}>
                        <div className="error-genero" style={{marginBottom: '12px'}}>
                          <span>
                            <ErrorIcon />  {msgErrorInvalid}
                          </span>
                        </div>
                      </div>
                    }
                  </div>
                }

                <Label style={{ marginBottom: '10px', display: 'block' }}>Recibo de Sinal (assinado) *</Label>
                <div className="rowForm">
                  <FilePicker
                    accept=".pdf, .doc, .docx"
                    label=""
                    description=""
                    placeholder={
                      dadosVenda.length !== 0
                        ? dadosVenda.informacao.recibo === null ? 'recibofinal.doc'
                          : dadosVenda.informacao.recibo
                        : 'recibofinal.doc'
                    }
                    defaultValue={dadosVenda.length === 0 ? 'recibofinal.doc' : dadosVenda.informacao.recibo}
                    id={"upload-recibo-doc-1"}
                    className={watch('file') ? 'inputGreenSelect' :
                      errors.file ? 'inputRedSelect' : ''
                    }
                    data-campo="data-assinatura"
                    onChange={(e) => handleReciboAssinado(e)}
                  />
                  <input type="hidden"  {...register("file", { required: true })} />
                  {dadosVenda.informacao.recibo &&
                    <Button
                      appearance="primary"
                      intent="none"
                      onClick={(e) => [ShowDocument((dadosVenda.informacao !== undefined ? dadosVenda.informacao.imovel_id : ''), 'recibo', token), e.preventDefault()]}
                    >Ver
                    </Button>
                  }
                </div>

                <div>
                  {errors.file &&
                    <div className="error-genero" style={{position: 'relative', top: '9px'}}>
                      <span>
                        <ErrorIcon />  {msgErrorInvalid}
                      </span>
                    </div>
                  }
                </div>

                <div className="mensagem-campos msg-obrigatorio" style={{marginTop: '25px'}}>*Campos obrigatórios</div>
                <div className="footer-form">
                  <div></div>
                  <div>
                    <Button
                      type="submit"
                      appearance="primary"
                      /*  disabled={validarSubmit() === 'enabled' ? false : true}  */
                      marginLeft={15}
                      className={disabledBtnUploadDocumento === false ? 'enabled-upload' : 'disabled-upload'}
                      onClick={
                        handleSubmit(e => dadosVenda.status_processo !== 22
                          ? handleBtnAvancar(e)
                          : alteracaoRevisao === true ? handleBtnAvancar(e) : handleVerificarRevisao())
                      }
                    /* onClick={(e) => handleBtnAvancar(e, 9) && validarSubmit()} */
                    >
                      {dadosVenda.status_processo !== 22 && disabledBtnUploadDocumento === false && <UploadIcon className="icon-upload" />}
                      {dadosVenda.status_processo === 22
                        ? dadosVenda.revisao.vendedores === 1
                          ? 'Próxima correção'
                          : dadosVenda.revisao.compradores === 1
                            ? 'Próxima correção'
                            : dadosVenda.revisao.comissao === 1
                              ? 'Próxima correção'
                              : dadosVenda.revisao.valores_documentos_imovel === 1
                                ? 'Próxima correção'
                                : 'Salvar correções'

                        : btnUploadDocumento === false ? 'Fazer upload do Recibo de Sinal' : 'Fazendo upload do Recibo de Sinal...'
                      }
                      {dadosVenda.status_processo === 22 && <ChevronRightIcon className="icon-save" />}
                    </Button>
                  </div>
                </div>
              </Pane>
            </form>
          </div>
        </div>
      }

    </Pane>
  )
}