import React, {useState, useContext} from 'react';
import { Pane, Tablist, Tab, Card, LockIcon} from 'evergreen-ui';

import DirecaoContext from '../../../../context/DirecaoContext';

import TabImovel from './Tabs/BoletimDeVenda/Imovel';
import TabComissao from './Tabs/BoletimDeVenda/Comissao';
import DocPessoas from './Tabs/BoletimDeVenda/DocPessoas';
import PagamentoComissao from './Tabs/BoletimDeVenda/PagamentoComissao';

export default function BoletimDeVenda(){
    const {
        selectedIndexBoletimDeVenda, 
        setSelectedIndexBoletimDeVenda,
        dadosVenda,
        showTabsBoletim
    } = useContext(DirecaoContext);

    
    const [tabsRascunho] = useState(['Imóvel', 'Vendedor', 'Comprador', 'Comissão', 'Pagamento da comissão']);

    const verificarTabs = async () => {
        if(dadosVenda.status_processo >= 1 && dadosVenda.status_processo <= 8){
            showTabsBoletim[1] = true;
            showTabsBoletim[2] = true;
            showTabsBoletim[3] = true;
            showTabsBoletim[4] = true;
        }
        else{
            if(dadosVenda.status_processo >= 18){
                showTabsBoletim[1] = true;
            }

            if(dadosVenda.status_processo >= 19){
                showTabsBoletim[2] = true;
            }

            if(dadosVenda.status_processo >= 20){
                showTabsBoletim[3] = true;
                showTabsBoletim[4] = true;
            }
        }
    }
    verificarTabs();

    return(
        <Pane>
            <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
                <Pane display="flex" padding={8}>
                    <Tablist>
                        {tabsRascunho.map((tabBoletim, index) => (
                            <Tab
                                key={tabBoletim}
                                isSelected={selectedIndexBoletimDeVenda === index}
                                onSelect={() => setSelectedIndexBoletimDeVenda(index)}
                                aria-controls={`panel-${index}`}
                                aria-selected={selectedIndexBoletimDeVenda === index ? true : false}
                                //className='tab-enable'
                                className={
                                    showTabsBoletim[index] === true ? 'tab-enable' : 'tab-disable'
                                }
                                style={selectedIndexBoletimDeVenda === index ? { boxShadow: '0 0 0 2px #d6e0ff' } : { boxShadow: 'none' }}
                            >
                                {tabBoletim} {showTabsBoletim[index] === true ? '' : <LockIcon size={12} marginLeft={10}/>}
                            </Tab>
                        ))}
                    </Tablist>
                </Pane>
            </Pane>
            <Pane flex="1" overflowY="scroll" background="tint1" >
                {tabsRascunho.map((tabBoletim, index) => (
                    <Card
                        key={tabBoletim}
                        id={`panel-${tabBoletim}`}
                        role="tabpanel"
                        aria-labelledby={tabBoletim}
                        aria-hidden={index !== selectedIndexBoletimDeVenda}
                        display={index === selectedIndexBoletimDeVenda ? 'block' : 'none'}
                    >
                        {/*Id do processo - sideId*/}
                        {tabBoletim === 'Imóvel' && <TabImovel />}
                        {tabBoletim === 'Vendedor' && <DocPessoas type="Vendedor" />}
                        {tabBoletim === 'Comprador' && <DocPessoas type="Comprador" />}
                        {tabBoletim === 'Comissão' && <TabComissao />}
                        {tabBoletim === 'Pagamento da comissão' && <PagamentoComissao />}
                    </Card>
                ))}
            </Pane>
        </Pane>
    )   
}