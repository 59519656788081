import Axios from 'axios';
import Serve from '../app/serve';

async function getDocumentsList() {
  const token = localStorage.getItem('token');
  const app = Serve();
  let data;

  await Axios.get(app + 'tipodocumentos', {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
    .then(res => {
      if (res !== undefined) {
        if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
          localStorage.clear();
        } else {

          data = res.data.data;
          // data.map(e => e.have_validade = e.validade_dias ? true : false)          
        }
      }
    })
    console.log("DATA: ", data);

  return data;
};

export default getDocumentsList;