import React, {useContext} from 'react'
import LogoDna from '../../../../../images/logo-dna-2.svg';
import {Button, LogOutIcon, MapMarkerIcon} from 'evergreen-ui';
import { useHistory } from 'react-router-dom';
import AcessoTemporarioContext from '../../../../../context/AcessoTemporarioContext';

export default function Header() {
    const history = useHistory();

    const {
        logradouro,
        resolucao,
    } = useContext(AcessoTemporarioContext);

    const handleSair = (e) => {
        history.push('/acesso?cod=' + localStorage.getItem('codigo_acesso'));
        localStorage.setItem('codigo_acesso', '');
        localStorage.setItem('cpf', '');
        localStorage.setItem('processo_id', '');
    }

    return (
        <>
            <div className="header-temp">
                <nav>
                    <div className="logo">
                        <img src={LogoDna} alt="dna" />
                    </div>

                    <Button className="btn-sair" onClick={(e) => handleSair(e)}>
                        <LogOutIcon size={12} color={'#8F95B2'} marginRight={8} /> Sair
                    </Button>
                </nav>                

                {
                    resolucao < 600 &&
                    <div className="mobile-address">
                        <div className="row">
                            <div className="coll">
                                <div className="info">
                                    <div className="imovel">
                                        <MapMarkerIcon color={'#8F95B2'} className="icon-imovel-map"/> {logradouro}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}
