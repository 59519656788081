import React, { useState, useContext, useEffect } from 'react';
import { Pane, TextInputField, Button, SelectField, DeleteIcon, Badge, PlusIcon } from 'evergreen-ui';
import cepMask from '../../../../components/cepMask';
import somenteNumero from '../../../../components/somenteNumero';
import dateMask from '../../../../components/dateMask';
import ripLaudemioMask from '../../../../components/ripLaudemioMask';
import NegociacaoContext from '../../../../context/NegociacaoContext';
import Axios from 'axios';
import Serve from '../../../../app/serve';
import { useHistory } from 'react-router-dom';
import { ResetIcon, TickIcon } from 'evergreen-ui'
import MultiUploadDoc from '../../../../components/MultiUploadDoc';

export default function Imovel({ refreshProcess }) {
    const history = useHistory();
    const [token] = useState(localStorage.getItem('token'));
    const [pos_id] = useState(localStorage.getItem('usuario_id'));

    const [erroCep] = useState();

    //Documentos
    const [formValues, setFormValues] = useState([])

    //Laudêmio
    const [minutaVendaLaudemio, setMinutaVendaLaudemio] = useState([]);
    const [primeiroLaudemio, setPrimeiroLaudemio] = useState(true);

    const app = Serve();
    const {
        setLoading,
        processSelect,
        processoId,        
        listaLaudemios,
        listEscrituras,
        multiDocs,
    } = useContext(NegociacaoContext);

    const imovelProcesso = processSelect?.imovel;
    const laudemioIgreja = listaLaudemios?.filter(item => item.tipo_laudemio_id === 4);
    const laudemioFamilia = listaLaudemios?.filter(item => item.tipo_laudemio_id === 3);

    const [buttonSave] = useState({
        opacity: 'inherit',
        pointerEvents: 'normal',
    });

    useEffect(() => {
        const retornaDocumentos = async () => {
            // console.log(imovelProcesso);
            if (imovelProcesso.informacao !== null) {
                const mapa = imovelProcesso.documentos.data.filter((docs) => docs.identifica_documento.includes("imóvel")).map(doc => ({ "documento_id": doc.id, "documento": doc.tipo_documento_id, "arquivo": doc.arquivo }))
                // console.log(mapa)

                setFormValues(mapa);
            }
        }
        retornaDocumentos();

        const retornaLaudemios = () => {
            // Retorna os Laudemios do Form de Venda
            if (imovelProcesso.length !== 0) {
                let mapa = imovelProcesso.laudemios.map(laudemio => ({ "tipo_laudemio": laudemio.tipo_laudemio, "valor_laudemio": laudemio.valor_laudemio }));
                setMinutaVendaLaudemio(mapa);
            }
        }
        retornaLaudemios();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleClear(e) {
        e.preventDefault();
        document.querySelector('#codigo-imovel-1').value = "";
        document.querySelector('#cep-1').value = "";
        document.querySelector('#logradouro-1').value = "";
        document.querySelector('#numero-1').value = "";
        document.querySelector('#unidade-1').value = "";
        document.querySelector('#complemento-1').value = "";
        document.querySelector('#bairro-1').value = "";
        document.querySelector('#escritura-1').value = "0";
        document.querySelector('#lavrada-1').value = "";
        document.querySelector('#livro-1').value = "";
        document.querySelector('#folha-1').value = "";
        document.querySelector('#ato-1').value = "";
        document.querySelector('#cartorio-1').value = "";
        document.querySelector('#registrado-1').value = "";
        document.querySelector('#matricula-1').value = "";
        document.querySelector('#inscricao-municipal-1').value = "";
    }


    async function handleSave(e) {        
        e.preventDefault();
        setLoading(true);
        const attrBtn = 1;

        const data = new FormData();
        data.append('codImovel', document.querySelector('#codigo-imovel-' + attrBtn).value)
        data.append('cep', document.querySelector('#cep-' + attrBtn).value)
        data.append('bairro', document.querySelector('#bairro-' + attrBtn).value)
        data.append('logradouro', document.querySelector('#logradouro-' + attrBtn).value)
        data.append('cidade', document.querySelector('#cidade-' + attrBtn).value)
        data.append('estado', document.querySelector('#estado-' + attrBtn).value)
        data.append('numero', document.querySelector('#numero-' + attrBtn).value)
        data.append('unidade', document.querySelector('#unidade-' + attrBtn).value)
        data.append('complemento', document.querySelector('#complemento-' + attrBtn).value)
        data.append('vagas', document.querySelector('#vaga-' + attrBtn).value)
        data.append('escritura', document.querySelector('#escritura-' + attrBtn).value)
        data.append('lavrada', document.querySelector('#lavrada-' + attrBtn).value)
        data.append('livro', document.querySelector('#livro-' + attrBtn).value)
        data.append('folha', document.querySelector('#folha-' + attrBtn).value)
        data.append('ato', document.querySelector('#ato-' + attrBtn).value)
        data.append('cartorio', document.querySelector('#cartorio-' + attrBtn).value)
        data.append('rgi', document.querySelector('#registrado-' + attrBtn).value)
        data.append('matricula', document.querySelector('#matricula-' + attrBtn).value)
        data.append('inscricaoMunicipal', document.querySelector('#inscricao-municipal-' + attrBtn).value)
        data.append('laudemio', JSON.stringify(minutaVendaLaudemio))
        
        data.append('informacao_imovel_id', imovelProcesso.informacao === null ? '' : imovelProcesso.informacao.id)
        data.append('processo_id', document.querySelector('#processo_id-' + attrBtn).value)
        
        multiDocs.imovel?.forEach((doc, index_Doc) => {
            if(doc.file) {
                data.append(`documentos_ids[${index_Doc}]`, doc.id)
                doc.item.forEach((item, index_Item) => {
                    data.append(`multiplo_documento_id[${index_Doc}][${index_Item}]`, item.id ? item.id : "");
                    data.append(`data_emissao[${index_Doc}][${index_Item}]`, item.emissao || '');  // DATA INICIAL
                    data.append(`validade_dias[${index_Doc}][${index_Item}]`, item.validade || ''); // DIAS
                    data.append(`data_vencimento[${index_Doc}][${index_Item}]`, item.vencimento || '');  // DATA FINAL
                })
            }
        });

        const imovel_id = document.querySelector('#imovel_id-1').value;

        await Axios.post(app + 'salvar_imovel_posvenda/' + imovel_id, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    refreshProcess(processoId, '', attrBtn);
                    setLoading(false);
                }

            })
            .catch(function (error) {
                console.log(error);
            })
    };

    async function handleEdit(e) {        
        e.preventDefault();
        setLoading(true);

        const attrBtn = 1;
        const imovel_id = document.querySelector('#imovel_id-1').value;
        const data = new FormData();
        data.append('informacao_imovel_id', imovelProcesso.informacao.id)
        data.append('processo_id', document.querySelector('#processo_id-' + attrBtn).value)
        data.append('codImovel', document.querySelector('#codigo-imovel-' + attrBtn).value)
        data.append('cep', document.querySelector('#cep-' + attrBtn).value)
        data.append('bairro', document.querySelector('#bairro-' + attrBtn).value)
        data.append('logradouro', document.querySelector('#logradouro-' + attrBtn).value)
        data.append('cidade', document.querySelector('#cidade-' + attrBtn).value)
        data.append('estado', document.querySelector('#estado-' + attrBtn).value)
        data.append('numero', document.querySelector('#numero-' + attrBtn).value)
        data.append('unidade', document.querySelector('#unidade-' + attrBtn).value)
        data.append('complemento', document.querySelector('#complemento-' + attrBtn).value)
        data.append('vagas', document.querySelector('#vaga-' + attrBtn).value)
        data.append('escritura', document.querySelector('#escritura-' + attrBtn).value)
        data.append('lavrada', document.querySelector('#lavrada-' + attrBtn).value)
        data.append('livro', document.querySelector('#livro-' + attrBtn).value)
        data.append('folha', document.querySelector('#folha-' + attrBtn).value)
        data.append('ato', document.querySelector('#ato-' + attrBtn).value)
        data.append('cartorio', document.querySelector('#cartorio-' + attrBtn).value)
        data.append('rgi', document.querySelector('#registrado-' + attrBtn).value)
        data.append('matricula', document.querySelector('#matricula-' + attrBtn).value)
        data.append('inscricaoMunicipal', document.querySelector('#inscricao-municipal-' + attrBtn).value)
        data.append('laudemio', JSON.stringify(minutaVendaLaudemio))
        data.append('usuario_id', pos_id)        
        
        multiDocs.imovel?.forEach((doc, index_Doc) => {
            if(doc.file) {
                data.append(`documentos_ids[${index_Doc}]`, doc.id)
                doc.item.forEach((item, index_Item) => {
                    data.append(`multiplo_documento_id[${index_Doc}][${index_Item}]`, item.id ? item.id : "");
                    data.append(`tipo_documento_ids[${index_Doc}][${index_Item}]`, item.value || "");
                    data.append(`data_emissao[${index_Doc}][${index_Item}]`, item.emissao || '');  // DATA INICIAL
                    data.append(`validade_dias[${index_Doc}][${index_Item}]`, item.validade || ''); // DIAS
                    data.append(`data_vencimento[${index_Doc}][${index_Item}]`, item.vencimento || '');  // DATA FINAL
                })
            }
        });

        await Axios.post(app + 'salvar_imovel_posvenda/' + imovel_id, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    refreshProcess(processoId, '', attrBtn);
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    //Para campos que só aceitam valores númericos
    const handleSomenteNumero = (e) => {
        let dataCampo = e.target.getAttribute('data-campo');
        let campo = document.querySelector('#' + dataCampo + '-1');
        if (campo.value.length > 0) {
            somenteNumero(campo.value);
            campo.value = somenteNumero(campo.value);
        }
    }

    const handleDataLavrada = () => {
        let dataLavrada = document.querySelector('#lavrada-1');
        if (dataLavrada.value.length >= 8) {
            dateMask(dataLavrada.value);
            dataLavrada.value = dateMask(dataLavrada.value);
        }
    }

    function handlechangeCep(e) {
        e.preventDefault();
        let attrBtn = 1;
        let cep = document.querySelector('#cep-' + attrBtn);

        if (cep.value.length) {
            cepMask(cep.value);
            cep.value = cepMask(cep.value);
        }

        let cep_validar = cep.value.replace(/\D/g, '')

        //Verifica se campo cep possui valor informado.
        if (cep_validar !== "") {
            //Expressão regular para validar o CEP.
            let validacep = /^[0-9]{8}$/;

            //Valida o formato do CEP.
            if (validacep.test(cep_validar)) {

                //Preenche os campos com "..." enquanto consulta webservice.
                document.querySelector('#logradouro-' + attrBtn).value = '...';
                document.querySelector('#bairro-' + attrBtn).value = '...';
                document.querySelector('#cidade-' + attrBtn).value = '...';
                document.querySelector('#estado-' + attrBtn).value = '...';

                //Consulta o webservice viacep.com.br/
                Axios.get("https://viacep.com.br/ws/" + cep_validar + "/json/")
                    .then(res => {
                        if (!res.data.erro) {
                            //Atualiza os campos com os valores da consulta.
                            document.querySelector('#logradouro-' + attrBtn).value = res.data.logradouro;
                            document.querySelector('#bairro-' + attrBtn).value = res.data.bairro;
                            document.querySelector('#complemento-' + attrBtn).value = res.data.complemento;
                            document.querySelector('#cidade-' + attrBtn).value = res.data.localidade;
                            document.querySelector('#estado-' + attrBtn).value = res.data.uf;

                        } //end if.
                        else {
                            //CEP pesquisado não foi encontrado.
                            limpa_formulário_cep(attrBtn);
                            alert("CEP não encontrado.");

                        }

                    })
            } //end if.
            else {
                //cep é inválido.
                limpa_formulário_cep(attrBtn);
                //alert("Formato de CEP inválido.");
            }
        } //end if.
    };

    function limpa_formulário_cep(attrBtn) {
        document.querySelector('#logradouro-' + attrBtn).value = '';
        document.querySelector('#bairro-' + attrBtn).value = '';
        document.querySelector('#complemento-' + attrBtn).value = '';
        document.querySelector('#cidade-' + attrBtn).value = '';
        document.querySelector('#estado-' + attrBtn).value = '';
    };

    //Adcionando e removendo campos de Documento
    const handleRipLaudemio = (e, i) => {
        let campo = document.querySelector('.minuta-venda-laudemio-valor-' + i);
        if (campo.value.length > 0) {
            ripLaudemioMask(campo.value);
            campo.value = ripLaudemioMask(campo.value);
        }
    };

    const handleChangeLaudemio = (i, e) => {
        const value = e.target.value;
        const name = e.target.name;
        if (name === 'tipo_laudemio') minutaVendaLaudemio[i].valor_laudemio = '';
        minutaVendaLaudemio[i][name] = value;
        setMinutaVendaLaudemio([...minutaVendaLaudemio]);
    };

    const addFormFieldsLaudemio = (e) => {
        setPrimeiroLaudemio(true);
        setMinutaVendaLaudemio([...minutaVendaLaudemio, { tipo_laudemio: "", valor_laudemio: "" }]);
    };

    const removeFormFieldsLaudemio = (i) => {
        let newFormValues = [...minutaVendaLaudemio];
        newFormValues.splice(i, 1);
        setMinutaVendaLaudemio(newFormValues);
    };

    return (
        <div>
            <div className="formGroup formAdd">
                <form id="formGroup">
                    <Pane>
                        <input id="imovel_id-1" type="hidden" value={imovelProcesso !== undefined ? imovelProcesso.id : ''} />
                        <input id="processo_id-1" type="hidden" value={processoId !== undefined ? processoId : ''} />

                        <div className="rowForm">
                            <TextInputField
                                label="CEP"
                                description=""
                                placeholder="Ex: 00000-000"
                                defaultValue={imovelProcesso !== undefined ? imovelProcesso.cep : ''}
                                id={"cep-1"}
                                maxLength="9"
                                onChange={(e) => handlechangeCep(e)}
                                required
                                validationMessage={erroCep}
                            />

                            <TextInputField
                                width={160}
                                label="Código do Imóvel"
                                description=""
                                placeholder="Ex: DNA123456"
                                defaultValue={imovelProcesso !== undefined ? imovelProcesso.codigo : ''}
                                id={"codigo-imovel-1"}
                                data-campo="cod-imovel"
                                onChange={(e) => /*handleCodImovel*/(e)}
                            />
                        </div>

                        <div className="rowForm">
                            <TextInputField
                                width={215}
                                label="Bairro"
                                description=""
                                placeholder="---"
                                defaultValue={imovelProcesso !== undefined ? imovelProcesso.bairro : ''}
                                id={"bairro-1"}
                                data-campo="bairro-imovel"
                                onChange={(e) => (e)}
                                readOnly
                                disabled
                            />

                            <TextInputField
                                width={250}
                                marginBottom={0}
                                marginLeft={20}
                                label="Logradouro"
                                description=""
                                placeholder="---"
                                defaultValue={imovelProcesso !== undefined ? imovelProcesso.logradouro : ''}
                                id={"logradouro-1"}
                                data-campo="logradouro-imovel"
                                onChange={(e) => (e)}
                                readOnly
                                disabled
                            />
                        </div>

                        <div className="rowForm">
                            <TextInputField
                                width={100}
                                label="Número"
                                description=""
                                placeholder="Ex: 456"
                                defaultValue={imovelProcesso !== undefined ? imovelProcesso.numero : ''}
                                id={"numero-1"}
                                data-campo="numero"
                                onChange={(e) => handleSomenteNumero(e)}
                                required
                            />

                            <TextInputField
                                width={200}
                                label="Unidade"
                                description=""
                                placeholder="Número do apartamento"
                                defaultValue={imovelProcesso !== undefined ? imovelProcesso.unidade : ''}
                                id={"unidade-1"}
                                onChange={(e) => /*handleUnidade*/(e)}
                            />
                        </div>

                        <div className="rowForm">
                            <TextInputField
                                width={330}
                                label="Complemento"
                                description=""
                                placeholder="Bloco, lote ou condomínio"
                                defaultValue={imovelProcesso !== undefined ? imovelProcesso.complemento : ''}
                                id={"complemento-1"}
                                data-campo="complemento"
                            />
                        </div>

                        <div className='rowForm'>
                            <TextInputField
                                width={215}
                                label="Cidade"
                                description=""
                                placeholder="---"
                                defaultValue={imovelProcesso !== undefined ? imovelProcesso.cidade : ''}
                                id={"cidade-1"}
                                data-campo="cidade-imovel"
                                onChange={(e) => (e)}
                                readOnly
                                disabled
                            />

                            <TextInputField
                                width={250}
                                marginBottom={0}
                                marginLeft={20}
                                label="Estado"
                                description=""
                                placeholder="---"
                                defaultValue={imovelProcesso !== undefined ? imovelProcesso.uf : ''}
                                id={"estado-1"}
                                data-campo="estado-imovel"
                                onChange={(e) => (e)}
                                readOnly
                                disabled
                            />
                        </div>

                        <div className="rowForm">
                            <TextInputField
                                width={160}
                                label="Vagas na garagem (escrituradas)"
                                description=""
                                placeholder="Ex: 1"
                                defaultValue={imovelProcesso.length === 0 ? '' : imovelProcesso.informacao.vaga}
                                id={"vaga-1"}
                                className={"minuta-venda minuta-venda-vagas-1"}
                                data-campo="vaga"
                                onChange={(e) => handleSomenteNumero(e)}
                            />
                        </div>

                        <div className="row-recibo">
                            <div className="subtitle">Registro e Escritura</div>
                            <div className="rowForm">
                                <SelectField
                                    onChange={e => /*handleChangeEscritura*/(e)}
                                    defaultValue={imovelProcesso.length === 0 ? '' : imovelProcesso.informacao.escritura}
                                    id={"escritura-1"}
                                    className={"minuta-venda minuta-venda-escritura-1 bgWhite"}
                                    data-campo="escritura"
                                    marginBottom={5}
                                    label="Escritura*"
                                    style={{ marginBottom: '15px' }}
                                >
                                    <option value="0" hidden>Selecione</option>
                                    {listEscrituras.map((value) => (
                                        <option value={value.id} key={value.id}>{value.escritura}</option>
                                    ))}

                                </SelectField>
                            </div>

                            <div className="rowForm">
                                <TextInputField
                                    width={280}
                                    label="Lavrada em"
                                    description=""
                                    placeholder="Ex: DD/MM/AAAA"
                                    defaultValue={imovelProcesso.length !== 0 ? imovelProcesso.informacao.lavrada : ''}
                                    id={"lavrada-1"}
                                    className={"minuta-venda minuta-venda-lavrada-1"}
                                    data-campo="lavrada"
                                    onChange={(e) => handleDataLavrada(e)}
                                    maxLength={10}
                                />

                                <TextInputField
                                    width={280}
                                    label="Livro"
                                    description=""
                                    placeholder="Ex: SC-345"
                                    defaultValue={imovelProcesso.length !== 0 ? imovelProcesso.informacao.livro : ''}
                                    id={"livro-1"}
                                    className={"minuta-venda minuta-venda-livro-1"}
                                    data-campo="livro"
                                    onChange={(e) => /*verificaInput*/(e)}
                                />
                            </div>

                            <div className="rowForm">
                                <TextInputField
                                    width={280}
                                    label="Folha"
                                    description=""
                                    placeholder="Ex: 25"
                                    defaultValue={imovelProcesso.length !== 0 ? imovelProcesso.informacao.folha : ''}
                                    id={"folha-1"}
                                    className={"minuta-venda minuta-venda-folha-1"}
                                    data-campo="folha"
                                    onChange={(e) => /*verificaInput*/(e)}
                                    maxLength={10}
                                />

                                <TextInputField
                                    width={280}
                                    label="Ato"
                                    description=""
                                    placeholder="Ex: 695433"
                                    defaultValue={imovelProcesso.length !== 0 ? imovelProcesso.informacao.ato : ''}
                                    id={"ato-1"}
                                    className={"minuta-venda minuta-venda-ato-1"}
                                    data-campo="ato"
                                    onChange={(e) => handleSomenteNumero(e)}
                                />
                            </div>

                            <div className="rowForm">
                                <TextInputField
                                    width={280}
                                    label="Cartório"
                                    description=""
                                    placeholder="Ex: 17"
                                    defaultValue={imovelProcesso.length !== 0 ? imovelProcesso.informacao.cartorio : ''}
                                    id={"cartorio-1"}
                                    className={"minuta-venda minuta-venda-cartorio-1"}
                                    data-campo="cartorio"
                                    onChange={(e) => handleSomenteNumero(e)}
                                />

                                <TextInputField
                                    width={280}
                                    label="Registrado no RGI"
                                    description=""
                                    placeholder="Ex: 25"
                                    defaultValue={imovelProcesso.length !== 0 ? imovelProcesso.informacao.rgi : ''}
                                    id={"registrado-1"}
                                    className={"minuta-venda minuta-venda-registrado-1"}
                                    onChange={(e) => handleSomenteNumero(e)}
                                    data-campo="registrado"
                                />
                            </div>

                            <div className="rowForm">
                                <TextInputField
                                    width={280}
                                    label="Matrícula nº"
                                    description=""
                                    placeholder="Ex: 17655566"
                                    defaultValue={imovelProcesso.length !== 0 ? imovelProcesso.informacao.matricula : ''}
                                    id={"matricula-1"}
                                    className={"minuta-venda-matricula-1"}
                                    data-campo="matricula"
                                    onChange={(e) => handleSomenteNumero(e)}
                                    //maxLength={10}
                                    required
                                />

                                <TextInputField
                                    width={280}
                                    label="Inscrição Municipal"
                                    description=""
                                    placeholder="Ex: 12345678"
                                    defaultValue={imovelProcesso.length !== 0 ? imovelProcesso.informacao.inscricaoMunicipal : ''}
                                    id={"inscricao-municipal-1"}
                                    className={"minuta-venda-inscricao-municipal-1"}
                                    data-campo="inscricao-municipal"
                                    maxLength={8}
                                    onChange={(e) => handleSomenteNumero(e)}
                                    required
                                />
                            </div>

                            <div style={{ margin: '0 0 30px 0' }}>
                                <div className="subtitle" style={{ marginBottom: '15px' }}>Laudêmio</div>
                                {
                                    minutaVendaLaudemio.map((element, index) => (
                                        <div className={"minuta-venda-laudemio-" + index + "form-inline"} key={index}>
                                            {primeiroLaudemio === true ?
                                                <div className="novo-laudemio" style={{ marginBottom: '10px' }}>
                                                    <div className="rowForm">
                                                        <SelectField
                                                            value={element.tipo_laudemio || ""}
                                                            onChange={e => handleChangeLaudemio(index, e)}
                                                            name="tipo_laudemio"
                                                            className={'bgWhite minuta-venda-laudemio minuta-venda-laudemio-novo-' + index}
                                                            id={'minuta-venda-laudemio-novo-' + index}
                                                            width={216}
                                                            marginBottom={5}
                                                            label="Tipo de Laudêmio *"
                                                        >
                                                            <option value="0" hidden>Selecione</option>
                                                            <option value="1">União</option>
                                                            <option value="2">Prefeitura</option>
                                                            <option value="3">Familia do imóvel</option>
                                                            <option value="4">Igreja</option>

                                                        </SelectField>

                                                        {
                                                            element.tipo_laudemio === ''
                                                                || element.tipo_laudemio === '0'
                                                                || element.tipo_laudemio === '2'
                                                                || element.tipo_laudemio === undefined

                                                                ?
                                                                null

                                                                :
                                                                element.tipo_laudemio !== ''
                                                                    || element.tipo_laudemio !== '0'
                                                                    || element.tipo_laudemio !== '2' ?

                                                                    element.tipo_laudemio === '3' || element.tipo_laudemio === '4'
                                                                        ? <SelectField
                                                                            value={element.valor_laudemio || ""}
                                                                            onChange={e => handleChangeLaudemio(index, e)}
                                                                            name="valor_laudemio"
                                                                            className={'bgWhite link-imovel minuta-venda-laudemio-novo-' + index + ' minuta-venda-laudemio-valor-' + index}
                                                                            id={'minuta-venda-laudemio-valor-' + index}
                                                                            width={216}
                                                                            marginBottom={5}
                                                                            label={element.tipo_laudemio === '3' ? 'Nome da Família *' :
                                                                                element.tipo_laudemio === '4' ? 'Nome da Igreja *' : ''}
                                                                        >
                                                                            <option value="0" hidden>Selecione</option>
                                                                            {(element.tipo_laudemio === '3' ? laudemioFamilia : element.tipo_laudemio === '4' ? laudemioIgreja : '').map((laudemio, index) => (
                                                                                <option value={laudemio.id} key={index}>{laudemio.nome}</option>
                                                                            ))}
                                                                        </SelectField>

                                                                        :
                                                                        <TextInputField
                                                                            value={element.valor_laudemio || ""}
                                                                            name="valor_laudemio"
                                                                            className={'link-imovel minuta-venda-laudemio-novo-' + index + ' minuta-venda-laudemio-valor-' + index}
                                                                            id={'minuta-venda-laudemio-valor-' + index}
                                                                            data-documento={'minuta-venda-laudemio-novo-' + index}
                                                                            width={237}
                                                                            marginBottom={5}
                                                                            label={
                                                                                element.tipo_laudemio === '1' ? 'RIP nº *' :
                                                                                    element.tipo_laudemio === '3' ? 'Nome da Família *' :
                                                                                        element.tipo_laudemio === '4' ? 'Nome da Igreja *' : ''
                                                                            }
                                                                            maxLength={
                                                                                element.tipo_laudemio === '1' ? 15 : ''
                                                                            }
                                                                            placeholder={
                                                                                element.tipo_laudemio === '1' ? 'Ex: 9999.9999.999-9' :
                                                                                    element.tipo_laudemio === '3' ?
                                                                                        'Ex: Ramo de Petrópolis' :
                                                                                        element.tipo_laudemio === '4'
                                                                                            ? 'Ex: Nome da Igreja Católica' : ''}
                                                                            onChange={e =>
                                                                                element.tipo_laudemio === '1'
                                                                                    ?
                                                                                    [handleRipLaudemio(e, index), handleChangeLaudemio(index, e)]
                                                                                    :
                                                                                    handleChangeLaudemio(index, e)
                                                                            }
                                                                        />
                                                                    :
                                                                    ''
                                                        }

                                                        <Button
                                                            marginRight={16}
                                                            appearance="primary"
                                                            intent="danger"
                                                            className="btn-deletar-documento button remove"
                                                            onClick={() => removeFormFieldsLaudemio(index)}
                                                            type="button"
                                                        >
                                                            <DeleteIcon
                                                                size={10}
                                                                style={{ 'marginRight': '10px', 'fill': '#fff' }}
                                                            /> Excluir
                                                        </Button>

                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    ))
                                }

                                <Button
                                    disabled={minutaVendaLaudemio.length > 3 ? true : false}
                                    marginRight={16}
                                    className={minutaVendaLaudemio.length > 3 ? "btn-adicionar button add btn-add-laudemio-disabled" : "btn-adicionar button add"}
                                    onClick={() => addFormFieldsLaudemio()}
                                    type="button"
                                    style={{ 'color': '#696F8C', 'border-color': '#D8DAE5' }}
                                >
                                    <PlusIcon
                                        width={10}
                                        style={{ 'margin-right': '10px', 'fill': '#8F95B2' }}
                                    />
                                    Adicionar Laudemio
                                </Button>
                            </div>
                        </div>

                        <div className="row-documento">
                            <div className="subtitle" style={{ marginBottom: '15px' }}>Documentos do Imóvel
                                {formValues.length === 0
                                    ? null
                                    : <Badge
                                        display="inline-flex"
                                        margin={8}
                                        style={{ 'background-color': '#D6E0FF', 'color': '#2952CC' }}
                                    >
                                        {formValues.length}
                                    </Badge >}
                            </div>

                            <MultiUploadDoc pessoa={'imovel'} type="imóvel" context={NegociacaoContext} refreshProcess={refreshProcess} idDonoDocumento={imovelProcesso.id} />
                            
                        </div>
                        <Pane display="flex" paddingBottom={20}>
                            <Pane flex={1}>
                                {/* Botão Excluir */}
                            </Pane>
                            <Button marginRight={16} intent="none" onClick={(e) => handleClear(e)}>
                                <ResetIcon
                                    size={10}
                                    style={{ 'margin-right': '10px', 'fill': '#8F95B2' }} /> Limpar tudo
                            </Button>
                            <Button appearance="primary" intent="none" onClick={(e) => { document.querySelector('#imovel_id-1').value !== "" ? handleEdit(e) : handleSave(e) }} style={buttonSave}>
                                <TickIcon
                                    size={10}
                                    style={{ 'margin-right': '10px', 'fill': '#fff' }} /> Salvar
                            </Button>
                        </Pane>
                    </Pane>
                </form>
            </div>
        </div>
    )
}