import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Pane, Select, Table, Avatar, Spinner } from 'evergreen-ui';
import getDashboardDiretor from '../../../apis/getDashboardDiretor';

export default function TablePanel() {
    const [filtro, setFiltro] = useState({
        mes: new Date().getMonth() + 1,
        ano: new Date().getFullYear()
    });
    const [items, setItems] = useState({ total_processos_entregues: 0, lojas: [] });
    const [loading, setLoading] = useState(false);

    const handleSelect = async (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const newFiltro = { ...filtro, [name]: value }
        setFiltro(newFiltro);
        getItems(newFiltro);
    };

    const tenhoToc = () => {
        let numberMax = 0;
        console.log("items:", items);
        items.lojas.forEach((loja) => {
            numberMax = numberMax < loja.gerentes.length ? numberMax = loja.gerentes.length : numberMax;
        });
        items.lojas.forEach((loja, index) => {
            const addLine = () => {
                if (loja.gerentes.length < numberMax) {
                    loja.gerentes.push({});
                    addLine();
                } else {
                    return ''
                }
            }
            return addLine();
        });
        setItems({ ...items });
        console.log("items:", items);
    };

    const getItems = async (filtro) => {
        setLoading(true);
        const data = await getDashboardDiretor(filtro);
        setItems({...data});
        setLoading(false)
    };

    useEffect(() => {
        getItems(filtro);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.altKey && event.code === "KeyT") tenhoToc();
        };
        document.body.addEventListener('keydown', handleKeyDown);
        return () => {
            document.body.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Pane>
            <div className="flex itemsCenter gap20">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.99 0.98999H1.98999C1.43999 0.98999 0.98999 1.43999 0.98999 1.98999C0.98999 2.26999 1.09999 2.51999 1.27999 2.69999L5.98999 7.40999V14C5.98999 14.55 6.43999 15 6.98999 15C7.26999 15 7.51999 14.89 7.69999 14.71L9.69999 12.71C9.87999 12.53 9.98999 12.28 9.98999 12V7.40999L14.7 2.69999C14.88 2.51999 14.99 2.26999 14.99 1.98999C14.99 1.43999 14.54 0.98999 13.99 0.98999Z" fill="#8F95B2" />
                    </svg>
                    <span className='ml10'>Filtrar:</span>
                </div>
                <div>
                    <Select
                        label=""
                        name='mes'
                        value={filtro.mes}
                        onChange={(e) => handleSelect(e)}
                        width={145}
                        id="filtro-mes"
                        disabled={loading}
                        className={filtro.mes === "" ? "filtro-select bgWhite color-select-filter-hidden" : "filtro-select bgWhite color-select-filter"}
                    >
                        <option value="" hidden>Mês de cadastro</option>
                        <option value={"vazio"}>Todos</option>
                        <option value={1}>Janeiro</option>
                        <option value={2}>Fevereiro</option>
                        <option value={3}>Março</option>
                        <option value={4}>Abril</option>
                        <option value={5}>Maio</option>
                        <option value={6}>Junho</option>
                        <option value={7}>Julho</option>
                        <option value={8}>Agosto</option>
                        <option value={9}>Setembro</option>
                        <option value={10}>Outubro</option>
                        <option value={11}>Novembro</option>
                        <option value={12}>Dezembro</option>
                    </Select>
                </div>
                <div>
                    <Select
                        label=""
                        name='ano'
                        value={filtro.ano}
                        onChange={(e) => handleSelect(e)}
                        width={145}
                        id="filtro-ano"
                        disabled={loading}
                        className={filtro.ano === "" ? "filtro-select bgWhite color-select-filter-hidden" : "filtro-select bgWhite color-select-filter"}
                    >
                        <option value={'vazio'}>Todos</option>                        
                        <option value={2022}>2022</option>
                        <option value={2023}>2023</option>
                    </Select>
                </div>
                {loading && <Spinner size={20} />}
            </div>
            <div className='whiteBox'>
                <span>TOTAL DE VENDAS ENTREGUES</span>
                <span className='count'>{items.total_processos_entregues}</span>
            </div>

            <div className='ft16 mb29 flex gap20'>
                Total por loja
            </div>
            {items.mes_vazio ?
                <div>
                    {items.mes_vazio}
                </div>
                :
                <Pane display="grid" gridTemplateColumns='repeat(auto-fit, 420px)' gap='20px'>
                    {items.lojas.map((loja, loja_index) => (
                        <Pane key={loja_index} width={420}>
                            <div className='mb8'>{loja.nome_loja}</div>
                            <div className="Rectangle35 Blue"></div>
                            <Table>
                                <Table.Head padding={0}>
                                    <Table.TextHeaderCell flexBasis={235} className='table'>
                                        Gerente
                                    </Table.TextHeaderCell>

                                    <Table.TextHeaderCell flexBasis={90} className='table dc_dash_table'>
                                        Entregue
                                    </Table.TextHeaderCell>

                                    <Table.TextHeaderCell flexBasis={90} className='table dc_dash_table'>
                                        Rascunho
                                    </Table.TextHeaderCell>
                                </Table.Head>
                                <Table.Body >
                                    {loja.gerentes.map((gerente, g_index) => (
                                        <Table.Row height={40} key={gerente.nome_gerente || g_index} className='cursorDefault' isSelectable onSelect={() => console.log(gerente.nome_gerente)}>
                                            <Table.Cell flexBasis={235} className='table'>
                                                {gerente.nome_gerente && <Avatar hashValue={gerente.nome_gerente} name={gerente.nome_gerente} size={24} marginRight={10} />}
                                                <span className='ft12'> {gerente.nome_gerente} </span>
                                            </Table.Cell>

                                            <Table.TextCell flexBasis={90} className='table dc_dash_table'>{gerente.total_entregues}</Table.TextCell>
                                            <Table.TextCell flexBasis={90} className='table dc_dash_table'>{gerente.total_rascunhos}</Table.TextCell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>

                            </Table>
                        </Pane>
                    ))}
                </Pane>
            }
        </Pane >
    )
}