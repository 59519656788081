import React, {useContext, useState, useEffect} from 'react'
import {Pane, Dialog, Button, ArrowRightIcon, Textarea, ErrorIcon, Label} from 'evergreen-ui';
import AcessoTemporarioContext from '../../../../../../context/AcessoTemporarioContext';
import fimFeedbackImg from '../../../../../../images/end_feedback.png';
import { useForm } from "react-hook-form";
import Axios from 'axios';
import Serve from '../../../../../../app/serve_login';
import { Rating } from 'react-simple-star-rating'
import {StarIcon} from 'evergreen-ui';

export default function Feedback() {
    const app = Serve();

    //Hook Form
    const { register, handleSubmit, clearErrors, setValue, watch, formState: { errors } } = useForm();
    //const onSubmit = data => console.log(data);
    //console.log(watch("notas")); // watch input value by passing the name of it
    const [nota, setNota] = useState('');
    //const [fimFeedback, setFimFeedback] = useState(false);
    const[ratingPessoa, setRatingPessoa] = useState({avaliacao: []})
    const [legendaOpcionista, setLegendaOpcionista] = useState('');
    const [legendaVendedor, setLegendaVendedor] = useState('');
    const [legendaGerente, setLegendaGerente] = useState('');
    const [legendaPosNegociacao, setLegendaPosNegociacao] = useState('');
    const [activeItems, setActiveItems] = useState({ corretor_opcionista: [], corretor_vendedor: [], gerente: [], pos_negociacao: [] });

    const {
        usuario,
        usuarioId,
        isShowFeedback, setIsShowFeedback,
        fimFeedback, setFimFeedback,
        ratingValue,
        perguntasOpcionista,
        perguntasVendedor,
        perguntasGerente,
        perguntasPosNegociacao,
        responsavelProcesso,
        gerenteProcesso,
        vendedorProcesso,
        opcionistaProcesso,
    } = useContext(AcessoTemporarioContext);
    //console.log(fimFeedback)

    const handleClose = (e) => {
        setIsShowFeedback(false);
        setNota('');
        clearErrors('notas');
        setValue('notas', '');
        setValue('opniao', '');
        //setFimFeedback(false);
        
        setRatingPessoa({
            avaliacao: []
        });
        setActiveItems({ corretor_opcionista: [], corretor_vendedor: [], gerente: [], pos_negociacao: [] })
        setLegendaOpcionista('');
        setLegendaVendedor('');
        setLegendaGerente('');
        setLegendaPosNegociacao('');
    }

    // Usado para as estrelas da avaliação de experiência
    function legendaEstrelaExperiência(){
        let legenda = '';

        switch (ratingValue) {
            case 1:
                legenda = 'Muito ruim'        
                break;

            case 2:
                legenda = 'Ruim'        
                break;

            case 3:
                legenda = 'Razoável'        
                break;

            case 4:
                legenda = 'Boa'       
                break;

            case 5:
                legenda = 'Excelente'        
                break;

            default:
                break;
        }

        return legenda;
    }

    const handleRatingPeople = (rate, type) => {

        const userArr = ratingPessoa.avaliacao.find(avaliacao => avaliacao.tipo === type);
        
        if(userArr){
            userArr.estrelas = rate;

            if(userArr.estrelas === 5){
                console.log('teste')
                userArr.respostas = []; // Limpa as respostas
                activeItems[type] = []; // Zera o estado das perguntas
            }
        }
        else{
            ratingPessoa.avaliacao.push({
                tipo: type,
                usuario_id: type === 'corretor_opcionista' ? opcionistaProcesso.usuario_id : type === 'corretor_vendedor' ? vendedorProcesso.usuario_id : type === 'gerente' ? gerenteProcesso[0].id : responsavelProcesso[0].id, 
                estrelas: rate,
                respostas: []
            })
        }
        
        // Legendas
        let legenda = "";
        if(rate === 1) legenda = 'Muito ruim';
        if(rate === 2) legenda = 'Ruim';
        if(rate === 3) legenda = 'Razoável'; 
        if(rate === 4) legenda = 'Boa';
        if(rate === 5) legenda = 'Excelente';

        if(type === 'corretor_opcionista') setLegendaOpcionista(legenda);
        if(type === 'corretor_vendedor') setLegendaVendedor(legenda);
        if(type === 'gerente') setLegendaGerente(legenda);
        if(type === 'pos_negociacao') setLegendaPosNegociacao(legenda);
    }

    const handleResposta = async (id, type) => {
        setActiveItems((prevActiveItems) => {
            const updatedItems = { ...prevActiveItems };
            if (updatedItems[type].includes(id)) {
                // Se o item já estiver ativo, remova-o
                updatedItems[type] = updatedItems[type].filter((item) => item !== id);
            } else {
                // Se o item não estiver ativo, adicione-o
                updatedItems[type] = [...updatedItems[type], id];
            }
            return updatedItems;
        });

        const userArr = ratingPessoa.avaliacao.find(avaliacao => avaliacao.tipo === type);               
        if(userArr){
            const respostaArr = userArr.respostas.find(resposta => resposta === id); 
            if(respostaArr){
                let indice = userArr.respostas.indexOf(id);
                userArr.respostas.splice(indice, 1)
                console.log(userArr.respostas)
            }
            else{
                userArr.respostas.push(id)
                console.log(userArr)
            }
        }
        else{
            ratingPessoa.avaliacao.push({
                tipo: type,
                estrelas: 0,
                respostas: [id]
            })
        }
    }

    const handleSalvarFeedback = (e) => {
        Axios.post(app + 'salvar_feedback', {
            'usuario_id': usuarioId,
            'processo_id': parseInt(localStorage.getItem('processo_id')),
            'experiencia': ratingValue,
            'avaliacao': ratingPessoa.avaliacao,
            'observacao': e.opniao,
        })
        .then(res => {
            setValue('notas', '');
            setValue('opniao', '');
            setFimFeedback(true);
        })
        .catch(error => {
            console.log('erro');
            setFimFeedback(false);
        })
    }

    return (
        <Pane>
            <Dialog
                isShown={isShowFeedback}
                title={fimFeedback === false ? <h2>Como você avalia o serviço da DNA Imóveis?</h2> : ''}
                onCloseComplete={() => handleClose()}
                hasFooter={false}
                width={360}
                containerProps={{
                    className: 'avaliacao-feedback-dialog'
                }}
                topOffset={30}
                preventBodyScrolling={false}
                shouldCloseOnOverlayClick={false}
            >
                {
                    fimFeedback === false
                    ?
                    <form>
                        <div className="row">
                            <div className="rating-star">                            
                                <Rating
                                    //onClick={handleRating}
                                    allowTitleTag={false}
                                    customIcons={[
                                        { icon: <StarIcon size={33} /> },
                                        { icon: <StarIcon size={33} /> },
                                        { icon: <StarIcon size={33} /> },
                                        { icon: <StarIcon size={33} /> },
                                        { icon: <StarIcon size={33} /> },                                            
                                    ]}
                                    initialValue={ratingValue}
                                    onClick={function noRefCheck(){}}
                                    readonly
                                />

                                <div className="star-title">{legendaEstrelaExperiência()}</div>
                                <div className="rating-border"></div>
                            </div>
                            
                            <div className='feedback-h3'>Nos conte mais sobre a venda.<br/> Avalie cada profissional DNA<br/> Imóveis que te atendeu. </div>

                            {
                                usuario.perfil === 'Vendedor' &&
                                <div className="rating-people">
                                    <div className="p">Sua nota para {opcionistaProcesso.name}, que avaliou o imóvel</div>
                                    <div className="rating-star">                            
                                        <Rating
                                            onClick={(e) => handleRatingPeople(e, 'corretor_opcionista')}
                                            allowTitleTag={false}
                                            customIcons={[
                                                { icon: <StarIcon size={33} /> },
                                                { icon: <StarIcon size={33} /> },
                                                { icon: <StarIcon size={33} /> },
                                                { icon: <StarIcon size={33} /> },
                                                { icon: <StarIcon size={33} /> },                                           
                                            ]}
                                        />

                                        <div className="star-title">{legendaOpcionista || 'Dê sua nota'}</div>
                                        
                                        {
                                            legendaOpcionista === 'Excelente' ||  legendaOpcionista === '' ?
                                            ''
                                            :
                                            <div className="perguntas" style={{marginBottom: '20px'}}>
                                                {perguntasOpcionista.respostas.map(pergunta => <div 
                                                    className={`item  ${activeItems.corretor_opcionista.includes(pergunta.id) ? 'active' : ''}`} 
                                                    onClick={e => handleResposta(pergunta.id, 'corretor_opcionista')}
                                                >
                                                    {pergunta.nome_tipo}
                                                </div>)}
                                            </div>
                                        }

                                        <div className="rating-border"></div>
                                    </div>
                                </div>
                            }
                            
                            <div className="rating-people">
                                <div className="p">Sua nota para {vendedorProcesso.name}, que vendeu o imóvel</div>
                                <div className="rating-star">                            
                                    <Rating
                                        onClick={(e) => handleRatingPeople(e, 'corretor_vendedor')}
                                        allowTitleTag={false}
                                        customIcons={[
                                            { icon: <StarIcon size={33} /> },
                                            { icon: <StarIcon size={33} /> },
                                            { icon: <StarIcon size={33} /> },
                                            { icon: <StarIcon size={33} /> },
                                            { icon: <StarIcon size={33} /> },                                            
                                        ]}
                                    />

                                    <div className="star-title">{legendaVendedor || 'Dê sua nota'}</div>

                                    {
                                        legendaVendedor === 'Excelente' || legendaVendedor === '' ?
                                        ''
                                        :
                                        <div className="perguntas" style={{marginBottom: '20px'}}>
                                            {perguntasVendedor.respostas.map(pergunta => <div 
                                                className={`item  ${activeItems.corretor_vendedor.includes(pergunta.id) ? 'active' : ''}`} 
                                                onClick={e => handleResposta(pergunta.id, 'corretor_vendedor')}
                                            >
                                                {pergunta.nome_tipo}
                                            </div>)}
                                        </div>
                                    }

                                    <div className="rating-border"></div>
                                </div>
                            </div>

                            <div className="rating-people">
                                <div className="p">Sua nota para {gerenteProcesso[0].name}, gerente da venda</div>
                                <div className="rating-star">                            
                                    <Rating
                                        onClick={(e) => handleRatingPeople(e, 'gerente')}
                                        allowTitleTag={false}
                                        customIcons={[
                                            { icon: <StarIcon size={33} /> },
                                            { icon: <StarIcon size={33} /> },
                                            { icon: <StarIcon size={33} /> },
                                            { icon: <StarIcon size={33} /> },
                                            { icon: <StarIcon size={33} /> },                                            
                                        ]}
                                    />

                                    <div className="star-title">{legendaGerente || 'Dê sua nota'}</div>

                                    {
                                        legendaGerente === 'Excelente' || legendaGerente === '' ?
                                        ''
                                        :
                                        <div className="perguntas" style={{marginBottom: '20px'}}>
                                            {perguntasGerente.respostas.map(pergunta => <div 
                                                className={`item  ${activeItems.gerente.includes(pergunta.id) ? 'active' : ''}`} 
                                                onClick={e => handleResposta(pergunta.id, 'gerente')}
                                            >
                                                {pergunta.nome_tipo}
                                            </div>)}
                                        </div>

                                    }

                                    <div className="rating-border"></div>
                                </div>
                            </div>

                            <div className="rating-people">
                                <div className="p">Sua nota para {responsavelProcesso[0].name}, da pós-negociação</div>
                                <div className="rating-star">                            
                                    <Rating
                                        onClick={(e) => handleRatingPeople(e, 'pos_negociacao')}
                                        allowTitleTag={false}
                                        customIcons={[
                                            { icon: <StarIcon size={33} /> },
                                            { icon: <StarIcon size={33} /> },
                                            { icon: <StarIcon size={33} /> },
                                            { icon: <StarIcon size={33} /> },
                                            { icon: <StarIcon size={33} /> },                                            
                                        ]}
                                    />

                                    <div className="star-title">{legendaPosNegociacao || 'Dê sua nota'}</div>

                                    {
                                        legendaPosNegociacao === 'Excelente' || legendaPosNegociacao === '' ?
                                        ''
                                        :
                                        <div className="perguntas" style={{marginBottom: '20px'}}>
                                            {perguntasPosNegociacao.respostas.map(pergunta => <div 
                                                className={`item  ${activeItems.pos_negociacao.includes(pergunta.id) ? 'active' : ''}`} 
                                                onClick={e => handleResposta(pergunta.id, 'pos_negociacao')}
                                            >
                                                {pergunta.nome_tipo}
                                            </div>)}
                                        </div>
                                    }
                                    
                                    <div className="rating-border"></div>
                                </div>
                            </div>
                            

                            <Label className="feedback-label">Sinta-se livre para deixar sugestões de melhorias:</Label>
                            <Textarea 
                                rows="5" 
                                style={{margin: '10px 0 25px 0'}} 
                                {...register('opniao', {required: false})} 
                                placeholder="Escreva sua sugestão" 
                            />

                            {/* <Button 
                                onClick={handleSubmit((e) => {
                                    handleSalvarFeedback(e)
                                })}
                                //className="btn-feedback btn-dna-green"
                            > */}
                            <Button 
                                className={`btn-feedback ${ratingValue > 0 ? 'btn-feedback-enable' : 'btn-feedback-disable'}`} 
                                onClick={handleSubmit((e) => {
                                    handleSalvarFeedback(e)
                                })}
                            >
                                    Enviar avaliação <ArrowRightIcon />
                            </Button>
                        </div>
                    </form>

                    : 
                    <div className="end-feedback">
                        <img src={fimFeedbackImg} alt="end_feedback" />
                        <p>
                            Obrigada por<br/> ajudar a DNA Imóveis<br/> a evoluir :)
                        </p>
                    </div>
                }
            </Dialog>
        </Pane>
    )
}
