import React, { useContext, useState, useEffect } from 'react';
import {
  Pane,
  Dialog,
  TabNavigation,
  Tab,
  Tablist,
} from 'evergreen-ui';

//Context
import VendasContext from '../../../../../../../context/VendasContext';

//Components
import Axios from 'axios';
import Serve from '../../../../../../../app/serve';
import { useHistory } from 'react-router-dom';
import Steps from '../Steps';
import RevisaoPosVenda from '../components/RevisaoPosVenda';
import Empresa from './Empresa';
import Corretores from './Corretores';

export default function Comissao() {
  const {
    loading,
    setCountMenu,
    isShowFormBoletimDeVenda, setIsShowFormBoletimDeVenda,
    setPosicaoBoletimDeVenda,
    dadosVenda, setDadosVenda,
    setItemAdicionado, setLoading,
    setIsShowCornerDialog, setModelCornerDialog,
  } = useContext(VendasContext);

  const app = Serve();
  const history = useHistory();
  const [tabs] = useState(['Empresa', 'Corretores']);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const closeBoletimDeVenda = (e) => {
    setIsShowFormBoletimDeVenda(false);
    setPosicaoBoletimDeVenda(1);
    // reset();
    // setAlteracaoRevisao(false);
  };


  async function recarregarVenda(type) {    
    await Axios.post(app + 'retorno_processos_painel_gerentes', {
      "usuario_id": localStorage.getItem('usuario_id'),
      'tipo_processos': 'adicionados',
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    })
    .then(res => {
      if (res.data.status && res.data.status === (401 || 498)) {
        localStorage.clear();
        history.push('/');
      } else {
        const processos = res.data.processos.data;
        setItemAdicionado(processos);
        processos.forEach(element => {
          if (Number(dadosVenda.processo_id) === element.processo_id) {
            setDadosVenda(element);
            if (type === "Corretor") {
                setIsShowCornerDialog(true);
                setIsShowFormBoletimDeVenda(false);
                setCountMenu(5);
                setModelCornerDialog(6);
              }
            }
          });
        }
      })
  };

  return (
    <Pane>
      <Dialog
        width={dadosVenda.status_processo === 22 ? 950 : 808}
        isShown={isShowFormBoletimDeVenda}
        title=''
        onCloseComplete={(e) => closeBoletimDeVenda(e)}
        hasFooter={false}
        preventBodyScrolling={false}
        shouldCloseOnOverlayClick={false}
        containerProps={{
          className: 'form-dialog-props'
        }}
      >
        <div className="form-dialog-fixed-no-tabs mb">
          <Steps />
        </div>

        {
          loading === true &&
            dadosVenda.status_processo === 22
            ? dadosVenda.revisao.comissao === 1
              ? <RevisaoPosVenda
                tab="comissao"
                situacao={dadosVenda.revisao.comissao_verific_save}
                mensagem={dadosVenda.revisao.comissao_observacao !== null ? dadosVenda.revisao.comissao_observacao : ''}
              />
              : ''
            : ''
        }

        <Tablist className='mt40 mb30'>
          {tabs.map((tab, index) => {
            return (
              <Tab
                aria-controls={`panel-${tab}`}
                isSelected={index === selectedIndex}
                key={tab}
                onSelect={() => setSelectedIndex(index)}
                disabled={dadosVenda.status_processo < 20 && index === 1}
              >
                {tab}
              </Tab>
            )
          })}
        </Tablist>

        <Pane className='mb20'>
          {tabs.map((tab, index) => (
            <Pane
              aria-labelledby={tab}
              aria-hidden={index !== selectedIndex}
              display={index === selectedIndex ? 'block' : 'none'}
              key={tab}
              role="tabpanel"
            >
              {tab === 'Empresa' ? <Empresa setSelectedIndex={setSelectedIndex} recarregarVenda={recarregarVenda} /> : <Corretores setSelectedIndex={setSelectedIndex} recarregarVenda={recarregarVenda} />}
            </Pane>
          ))}
        </Pane>

      </Dialog>
    </Pane >
  )
}