import React, {useContext} from 'react'
import {Pane, Dialog} from 'evergreen-ui';
import AcessoTemporarioContext from '../../../../../../context/AcessoTemporarioContext';

export default function DialogAjuda() {

    const {
        isShown, setIsShown,
        faq,
    } = useContext(AcessoTemporarioContext);

    return (
        <Pane>
            <Dialog
                isShown={isShown}
                title={<h2>FAQ - Perguntas frequentes</h2>}
                onCloseComplete={() => setIsShown(false)}
                hasFooter={false}
                width={720}
                containerProps={{
                    className: 'ajuda-temporaria-dialog'
                }}
                /*topOffset={20}*/
                
            >
                <div>
                    <ul>
                    {
                        faq.map(resp => <li>
                            <div className="title">{resp.title}</div>
                            <div className="content">{resp.content}</div>
                        </li>)
                    }
                    </ul>
                </div>
            </Dialog>
        </Pane>
    )
}
